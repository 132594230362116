import React from 'react'

import { withStyles } from '@mui/styles'
import { CSVLink } from 'react-csv'
import Dialog from '@mui/material/Dialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import ButtonIcon from '@mui/material/IconButton'
import { CustomButton } from 'cgi-ui-components'

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '40px 16px',
    fontSize: 14,
    width: 500,
    textAlign: 'center',
    '& .fa-exclamation-triangle': {
      color: theme.palette.primary.red,
      fontSize: 48,
      marginBottom: 10,
    },
    '& .fa-trash-alt': {
      color: theme.palette.primary.red,
      fontSize: 48,
      marginBottom: 10,
    },
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    fontSize: 14,
    backgroundColor: theme.palette.primary.black,
  },
}))(MuiDialogActions)

const style = (theme) => ({
  root: {
    backgroundColor: 'rgba(243,243,243,0.8)',
  },
  paper: {},
  MuiButtonBase: {
    backgroundColor: '#224FCA',
    width: '50%',
    color: '#FFF',
    padding: '21px 0',
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#224FCA',
    },
    '& i': {
      marginRight: '5px',
    },
  },
  cancelButtonCustomClass: {
    color: '#151B26',
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'white',
    fontWeight: 500,
    marginRight: '49px',
  },
  helpinText: {
    fontSize: '12px',
    color: '#777',
  },
  mainText: {
    fontSize: '14px',
    color: '#333',
  },
  modalTitleHeader: {
    padding: '8px 10px 0 0px !important',
  },
  closeButton: {
    float: 'right',
    right: '0',
    top: 0,
    position: 'absolute',
  },
})
const decodeHtml = (text) => ({ __html: text })

const TemplateErrorConfirmationPopUp = ({
  handleClose,
  open,
  handleConfirm,
  handleDownload,
  classes,
  descriptionText,
  confirmText,
  cancelText,
  helpingText = '',
  headers = [],
  data = [],
}) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="Tamplate error confirmation dialog"
    open={open}
    PaperProps={{
      square: true,
      elevation: 3,
      classes: { root: classes.paper },
    }}
    BackdropProps={{ classes: { root: classes.root } }}
  >
    <MuiDialogTitle disableTypography className={classes.modalTitleHeader}>
      <ButtonIcon
        aria-label="Close"
        className={classes.closeButton}
        onClick={() => {
          handleClose()
        }}
      >
        <FontAwesomeIcon icon={faTimes} size="1x" />
      </ButtonIcon>
    </MuiDialogTitle>
    <DialogContent>
      <Typography>
        <FontAwesomeIcon icon={faExclamationCircle} size="2x" color="#EBC202" />
      </Typography>
      <Typography gutterBottom>
        <span
          className={classes.mainText}
          dangerouslySetInnerHTML={decodeHtml(descriptionText)}
        />
      </Typography>

      <Typography gutterBottom>
        <sup className={classes.helpinText}>*</sup>
        <span
          className={classes.helpinText}
          dangerouslySetInnerHTML={decodeHtml(helpingText)}
        />
      </Typography>
    </DialogContent>
    <DialogActions style={{ backgroundColor: 'white' }}>
      <CSVLink
        filename="Assets-Report-For-Template.csv"
        headers={headers || []}
        data={data || []}
        style={{ textDecoration: 'none' }}
      >
        <span
          className={classes.cancelButtonCustomClass}
          onClick={handleDownload}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size="1x"
            color="#EBC202"
            style={{ marginRight: '4px' }}
          />
          {'   '}
          {cancelText}
        </span>
      </CSVLink>
      <CustomButton
        onClick={handleConfirm}
        className={classes.MuiButtonBase}
        label={confirmText}
      ></CustomButton>
    </DialogActions>
  </Dialog>
)

export default withStyles(style)(TemplateErrorConfirmationPopUp)
