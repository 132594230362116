import React from 'react'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import BorderAllIcon from '@mui/icons-material/BorderAll'
import ChairIcon from '@mui/icons-material/Chair'
import RoofingIcon from '@mui/icons-material/Roofing'
import { ModeSelection } from 'cgi-ui-components'

import { SET_SCENE_MODE } from '../../store/scene/ActionType'

import {
  ASSET_MODE_ARCH,
  ASSET_MODE_INFRA,
  ASSET_MODE_TCIN,
} from '../../constants/scene'

const useStyles = makeStyles(() => ({
  iconsWrapper: {
    position: 'fixed',
    zIndex: 101,
    top: 10,
    left: 15,
  },
}))

const AssetModeSelection = ({ userHaveWriteAccess, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const togglePannel = (e, name) => {
    e.stopPropagation()
    dispatch({ type: SET_SCENE_MODE, data: name })
  }
  const data = [
    {
      name: 'Architecture',
      value: ASSET_MODE_ARCH,
      icon: BorderAllIcon,
    },
    // {
    //   name: 'Infrastructure',
    //   value: ASSET_MODE_INFRA,
    //   icon: RoofingIcon,
    // },
    {
      name: 'Products/Props',
      value: ASSET_MODE_TCIN,
      icon: ChairIcon,
    },
  ]

  return (
    <>
      <Box className={classes.iconsWrapper}>
        {userHaveWriteAccess ? (
          <ModeSelection
            data={data}
            onChange={togglePannel}
            defaultView={ASSET_MODE_TCIN}
          />
        ) : null}
      </Box>
    </>
  )
}

export default AssetModeSelection
