import React from 'react'
import { withStyles } from '@mui/styles'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { showExperienceForm } from '../../store/scene/ActionCreator'

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import { ButtonBase, CircularProgress } from '@mui/material'
import { canUserWrite } from '../../helpers/utils'
import { fontSize } from '@mui/system'

const styles = (theme) => ({
  buttonStyle: {
    '&.MuiButtonBase-root': {
      padding: '9px 9px 8px 9px',
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: 14,
      borderBottom: '2px solid rgba(0, 0, 0, 0.6)',
    },
  },
})

export class EditScene extends React.Component {
  state = {
    loadedPercentage: 0,
  }
  componentDidMount() {
    document.addEventListener('onAssetLoaded', this.calucalateLoading)
  }
  calucalateLoading = (e) => {
    const {
      detail: { total, loaded },
    } = e
    let loadedPercentage = parseInt((loaded / total) * 100)
    loadedPercentage = isNaN(loadedPercentage) ? 0 : loadedPercentage

    this.setState({
      loadedPercentage,
    })
  }
  toggleExperienceForm = (value) => {
    const { showExperienceForm } = this.props
    showExperienceForm(value)
  }

  render() {
    const {
      classes,

      experience: { experience_id, experience_name, initiator, members },
      lanId,
      saveStatus,
      assetsLoading,
      template_name = '',
      isTemplateEdit = false,
    } = this.props
    const hasWriteAccess = canUserWrite(lanId, initiator, members)
    const { loadedPercentage } = this.state
    const isEdit = experience_id !== undefined
    return (
      <ButtonBase
        onClick={
          isEdit && hasWriteAccess && !isTemplateEdit
            ? () => this.toggleExperienceForm(true)
            : null
        }
        style={{
          cursor: isEdit && hasWriteAccess ? 'pointer' : 'auto',
        }}
        className={classes.buttonStyle}
      >
        {saveStatus + ' '}
        {assetsLoading ? (
          <>
            {' '}
            <CircularProgress size={16} thickness={6} />
            &nbsp;
          </>
        ) : (
          ''
        )}
        {isTemplateEdit ? (
          <>{template_name || 'Untitled'}</>
        ) : (
          <>{experience_name || 'Untitled'}</>
        )}
        &nbsp;
        {assetsLoading ? ` ...${loadedPercentage}%` : ''}
        {isEdit && hasWriteAccess && !assetsLoading ? (
          <CreateOutlinedIcon fontSize="small" />
        ) : null}
      </ButtonBase>
    )
  }
}

const mapStateToProps = ({
  auth: { lanId },
  experiences: { experience },
  templates: {
    currentTemplateDetails: { template_name },
  },
}) => ({
  lanId,
  experience,
  template_name,
})
export const EditorSceneWithStyles = withStyles(styles)(EditScene)

export default connect(mapStateToProps, {
  showExperienceForm,
})(EditorSceneWithStyles)
