import React from 'react'
import { number } from 'prop-types'

export const PlaceHolders = ({ customClass, placeholderCount = 5 }) => {
  const placeholders = []
  let holderCount = placeholderCount
  while (holderCount >= 1) {
    placeholders.push(<div className={customClass} key={holderCount} />)
    holderCount--
  }
  return placeholders
}

PlaceHolders.propTypes = {
  placeholderCount: number,
}
