import React from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import {
  Grid,
  Slider,
  Button,
  Select,
  MenuItem,
  Popper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'

import { ZF_STEP } from '../../constants/scene'

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  CustomButton,
  CustomIconButton,
  Typography as TextDisplay,
  TextFieldComponent as FormTextField,
  Chips,
} from 'cgi-ui-components'

import {
  SMART_RENDER_PRESET_LOW_MSG,
  SMART_RENDER_PRESET_MED_MSG,
  SMART_RENDER_PRESET_HG_MSG,
  SMART_RENDER_PRESET_DEFAULT_MSG,
} from '../../constants/displayMessages'

import {
  FAST,
  STANDARD,
  HD,
  DEFAULT,
  FAST_NOISE,
  STANDARD_NOISE,
  HD_NOISE,
  DEFAULT_NOISE,
  DEFAULT_NOISE_CORONA,
  FAST_TIME,
  STANDARD_TIME,
  HD_TIME,
  DEFAULT_TIME,
  ACTIVE,
  INACTIVE,
} from '../../constants/common'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CameraThumbnails from './cameraThumbnails'
import DeleteIcon from '@mui/icons-material/Delete'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

const styles = (theme) => ({
  formRoot: {
    marginLeft: 10,
  },
  textField: {
    width: '6rem',
    margin: '10px 31px 2px 0',
  },
  axistTextField: {
    width: '5rem',
    marginRight: 5,
  },

  resize: {
    boxSizing: 'border-box',
  },
  dropDown: {
    height: 37,
    width: 205,
    boxSizing: 'border-box',
    paddingTop: 10,
  },
  helperTextRoot: {
    margin: '2px 5px',
  },
  helperText: {
    fontSize: 10,
  },
  label: {
    transform: 'translate(14px, 12px) scale(0.75)',
  },
  textFieldMarginNormal: {
    marginTop: 10,
    marginBottom: 2,
  },
  resetButton: {
    color: '#336CD9',
    float: 'right',
    marginRight: 10,
    '& i': {
      marginRight: 5,
    },
  },
  defaultViewWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '65px',
  },
  checkBox: {
    padding: '1px',
  },
  disabledAspectTitle: {
    color: theme.palette.primary.mediumGrey,
  },
  resolutionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  camerainput: {
    marginRight: 10,
    width: 175,
    verticalAlign: 'middle',
    '& .MuiSelect-outlined': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  tooltip: {
    padding: '10px  5px',
    width: 210,
    '& span': {
      fontSize: 12,
    },
    '& h2': {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 8,
    },
  },
  deleteWarn: {
    zIndex: 150,
    background: '#E7EDFA',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    width: 235,

    '& h3': {
      color: '#333333',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '15.5px',
      margin: '0 0 10px',
    },
    '& p': {
      color: '#666666',
      fontSize: 12,
      margin: '0 0 10px',
      lineHeight: '15.5px',
      textAlign: 'justify',
    },
    '& button': {
      fontSize: 12,
      textTransform: 'none',
    },
    '& div:first-child': {
      padding: '20px 20px 0px',
    },
    '& >div:last-child': {
      paddingBottom: 10,
      paddingRight: 5,
      textAlign: 'right',
    },
  },
  acceptDeleteButton: {
    color: '#224FCA',
  },
  formHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 5px 0px',
    marginLeft: -16,
    justifyContent: 'space-around',
  },
  formTextFieldRoot: {
    width: '6rem',
    marginRight: 7,
  },
  accordianSettings: {
    margin: '0px 8px 0px 0px !important',
    boxShadow: 'none !important',
    '&:before': {
      display: 'none',
    },
  },
  accordianExpanded: {
    margin: '0px 8px 0px 0px !important',
    boxShadow: 'none !important',
  },
  accordianRoot: {
    padding: '0px 5px 0px 0px !important',
  },
  accordionDetails: {
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
  },
  chipRoot: {
    margin: '0px 5px 5px !important',
  },
  resolutionChip: {
    '&.MuiChip-root': {
      margin: '0px 5px 5px',
      width: 90,
    },
  },
  msgBanner: {
    display: 'flex',
  },
  icons: {
    width: 40,
    height: 40,
    alignSelf: 'center',
  },
  formActionBar: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    width: '100%',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
    justifyContent: 'center',
  },
})
class RenderForm extends React.Component {
  state = {
    warningPopUp: false,
    smartRenderMsg: { message: '', noise: 5 },
    smartRenderOptions: [
      {
        name: FAST,
        chipState: INACTIVE,
        msg: SMART_RENDER_PRESET_LOW_MSG,
        noise: FAST_NOISE,
        time: FAST_TIME,
      },
      {
        name: STANDARD,
        chipState: INACTIVE,
        msg: SMART_RENDER_PRESET_MED_MSG,
        noise: STANDARD_NOISE,
        time: STANDARD_TIME,
      },
      {
        name: HD,
        chipState: INACTIVE,
        msg: SMART_RENDER_PRESET_HG_MSG,
        noise: HD_NOISE,
        time: HD_TIME,
      },
      {
        name: DEFAULT,
        chipState: INACTIVE,
        msg: SMART_RENDER_PRESET_DEFAULT_MSG,
        noise: DEFAULT_NOISE,
        time: DEFAULT_TIME,
      },
    ],
    resolutionpresets: [
      { name: '2400X900', width: 2400, height: 900 },
      { name: '1200X1200', width: 1200, height: 1200 },
      { name: '1200X1600', width: 1200, height: 1600 },
      { name: '1600X960', width: 1600, height: 960 },
      { name: '1200X1440', width: 1200, height: 1440 },
      { name: 'custom', width: 3000, height: 3000 },
    ],
    selectedResolution: '',
    isMultipleCamViewExpanded: false,
  }
  warnRef = React.createRef()
  handleClipNearChange = (event, value) => {
    const { setRenderFormData } = this.props
    setRenderFormData({ clipNear: value })
  }
  handleClipFarChange = (event, value) => {
    const { setRenderFormData } = this.props
    setRenderFormData({ clipFar: value })
  }

  onElementChange = (x, y, z) => {
    const { updateCoords } = this.props
    updateCoords(x, y, z)
  }

  onCameraPosChange = (x, y, z) => {
    const { updateCameraPos } = this.props
    updateCameraPos({ x, y, z }, true)
  }

  onZoomFactorChange = (event) => {
    const { setRenderFormData } = this.props
    if (event.target.value < 0 || event.target.value > 10) {
      return
    }
    setRenderFormData({ zoomFactor: parseFloat(event.target.value) })
  }
  onResolutionChange = (width, height) => {
    const { changeResolution } = this.props
    changeResolution(parseInt(width), parseInt(height))
  }

  onFocalLengthChange = (event) => {
    const { setRenderFormData } = this.props
    if (event.target.value < 1 || event.target.value > 300) {
      return
    }
    setRenderFormData({ focalLength: parseFloat(event.target.value) })
  }

  handleChange = (event) => {
    const {
      setRenderFormData,
      renderData: { maxWidth, maxHeight, resizeRatio, frameHeight, frameWidth },
      rendererType,
    } = this.props
    let invalidInput = false
    switch (event.target.name) {
      case 'passes':
        if (event.target.value > 1000) {
          invalidInput = true
        }
        break
      case 'time':
        this.setSmartRenderState('')
        if (event.target.value > 180) {
          invalidInput = true
        }
        break
      case 'noise':
        {
          this.setSmartRenderState('')
          const noiseLimit = rendererType === 'V-Ray' ? 1 : 30
          if (event.target.value > noiseLimit) {
            invalidInput = true
          }
        }
        break
      case 'clipNear':
        if (event.target.value < 0 || event.target.value > 1000) {
          invalidInput = true
        }
        break
      case 'width':
        if (
          event.target.value < 0 ||
          event.target.value / resizeRatio > frameWidth
        ) {
          invalidInput = true
        }
        break
      case 'height':
        if (
          event.target.value < 0 ||
          event.target.value / resizeRatio > frameHeight
        ) {
          invalidInput = true
        }
        break
      default:
        break
    }
    if (!invalidInput) {
      if (event.target.name === 'dnd') {
        setRenderFormData({ [event.target.name]: event.target.checked })
      } else {
        if (event.target.name === 'width' || event.target.name === 'height') {
          setRenderFormData({
            [event.target.name]: parseInt(event.target.value) / resizeRatio,
          })
        } else {
          setRenderFormData({ [event.target.name]: event.target.value })
        }
      }
    }
  }

  roundVectorObject = ({ x, y, z }) => ({
    x: parseFloat(x).toFixed(4),
    y: parseFloat(y).toFixed(4),
    z: parseFloat(z).toFixed(4),
  })

  compareCameraSettings = () => {
    let {
      sceneData: {
        currentCamera: savedCamera,
        selected_camera: selectedCameraName,
      },
      templateDefaultCamera,
      savedRenderDetails: {
        width: savedWidth,
        height: savedHeight,
        resHeight: savedResHeight,
        resWidth: savedResWidth,
        x: saved_x,
        y: saved_y,
        resizeRatio: savedResizeRatio,
        noise: savedNoise,
        passes: savedPasses,
        time: savedTime,
      },
      renderData: {
        clipNear,
        clipFar,
        focalLength,
        zoomFactor,
        width,
        height,
        resHeight,
        resWidth,
        x,
        y,
        resizeRatio,
        noise,
        passes,
        time,
      },
      currentCameraPosition: position = {},
      currentCameraTarget: target = {},
    } = this.props
    if (selectedCameraName === 'default') {
      savedCamera = templateDefaultCamera
    }

    let savedCameraSettings = {
      position: this.roundVectorObject(savedCamera.position),
      target: this.roundVectorObject(savedCamera.target),
      zoomFactor: parseFloat(savedCamera.zoomFactor).toFixed(2),
      clipNear: parseInt(savedCamera.clipNear),
      clipFar: parseInt(savedCamera.clipFar),
      width: savedWidth,
      height: savedHeight,
      resHeight: savedResHeight,
      resWidth: savedResWidth,
      x: saved_x,
      y: saved_y,
      noise: parseFloat(savedNoise),
      passes: parseFloat(savedPasses),
      time: parseFloat(savedTime),
      focalLength: parseFloat(savedCamera.fl).toFixed(2),
    }
    //resizeRatio: parseFloat(savedResizeRatio).toFixed(2),

    return isEqual(savedCameraSettings, {
      position: this.roundVectorObject(position),
      target: this.roundVectorObject(target),
      zoomFactor: parseFloat(zoomFactor).toFixed(2),
      clipNear: parseInt(clipNear),
      clipFar: parseInt(clipFar),
      width,
      height,
      resHeight,
      resWidth,
      x,
      y,
      noise: parseFloat(noise),
      passes: parseFloat(passes),
      time: parseFloat(time),
      focalLength: parseFloat(focalLength).toFixed(2),
    })
    // resizeRatio: parseFloat(resizeRatio).toFixed(2),
  }

  getTooltipContent = () => {
    const {
      classes,
      renderData: { locked },
      sceneData: { selected_camera: selectedCamera },
    } = this.props
    if (locked) {
      return (
        <div className={classes.tooltip}>
          <h2>Unlock Camera</h2>
          <span>Make changes to camera settings</span>
        </div>
      )
    } else if (!locked) {
      return (
        <div className={classes.tooltip}>
          <h2>Lock Camera</h2>
          <span>
            Restrict other users from making changes to this preferred view
          </span>
        </div>
      )
    } else if (selectedCamera === 'default') {
      return (
        <div className={classes.tooltip}>
          <h2>Camera Locked</h2>
          <span>
            Default camera cannot be unlocked, to tweek camera settings and lock
            it switch preferred view
          </span>
        </div>
      )
    }
  }

  checkIfAlreadyPopupShown() {
    if (
      document.cookie
        .split(';')
        .some((item) => item.includes('styleSaveWarnAccepted=yes'))
    ) {
      return true
    }
    return false
  }
  showWarningPopup() {
    this.setState({ warningPopUp: true }, () => {
      let topPos = this.warnRef.current.offsetTop
      this.warnRef.current.parentNode.parentNode.scrollTop = topPos
    })
  }
  renderConfirmPopup() {
    const { classes } = this.props
    return (
      <Popper
        className={classes.deleteWarn}
        anchorEl={this.warnRef.current}
        open={true}
      >
        <div>
          <h3>Save Changes?</h3>
          <p>
            Settings has been modified, we suggest saving your changes before
            locking the camera.
          </p>
        </div>
        <div>
          <Button
            onClick={() => {
              this.setState({ warningPopUp: false })
              var now = new Date()
              var time = now.getTime()
              // 8* 60* 60
              var expireTime = time + 1000 * 10 * 60 * 60 //8 hours
              now.setTime(expireTime)
              document.cookie =
                'styleSaveWarnAccepted=yes;expires=' +
                now.toUTCString() +
                ';path=/'
            }}
            className={classes.acceptDeleteButton}
          >
            Got It
          </Button>
        </div>
      </Popper>
    )
  }

  renderOpenFormHeader = () => {
    const {
      classes,
      setViewCameraSettings,
      updateCamera,
      sceneData: { selected_camera: selectedCamera },
    } = this.props
    return (
      <div className={classes.formHeader}>
        <CustomIconButton
          onClick={(e) => setViewCameraSettings(e, false)}
          size="small"
        >
          <ArrowDropDownRoundedIcon fontSize="small" />
        </CustomIconButton>
        <TextDisplay
          label="Camera Settings"
          variant="subtitle1"
          sx={{ fontWeight: 500 }}
        />
        <CustomButton
          label="Reset"
          onClick={() => {
            updateCamera(selectedCamera, true)
          }}
          color="primary"
          size="small"
          startIcon={<AutorenewRoundedIcon />}
          variant="contained"
        />
      </div>
    )
  }

  setSmartRenderState = (value) => {
    if (value === '') {
      this.setState({ smartRenderMsg: { message: '', noise: 5 } })
    }
    let updateRenderOptions = [...this.state.smartRenderOptions].map(
      (option) => {
        if (option.name === value) {
          option.chipState = ACTIVE

          this.props.setRenderFormData({
            noise: option.noise,
            time: option.time,
          })

          this.setState({
            smartRenderMsg: { message: option.msg, noise: option.noise },
          })
        } else {
          option.chipState = INACTIVE
        }
        return option
      }
    )
    this.setState({
      smartRenderOptions: updateRenderOptions,
    })
  }

  renderSmartRenderOptions = () => {
    let { smartRenderOptions, smartRenderMsg = {} } = this.state
    const { classes } = this.props
    return (
      <div>
        <div>
          <TextDisplay
            variant="body2"
            label="Smart Render Presets"
            sx={{ fontWeight: 500 }}
          />
        </div>
        <br />
        {smartRenderOptions.map((item) => (
          <>
            <Chips
              color={item.chipState === INACTIVE ? 'default' : 'primary'}
              label={item.name}
              onClick={() => this.setSmartRenderState(item.name)}
              size="small"
              className={classes.chipRoot}
            />
          </>
        ))}
        {smartRenderMsg.message !== '' && (
          <>
            <div className={classes.msgBanner}>
              <CustomIconButton
                label={`Noise Limit : ${smartRenderMsg.noise}`}
                className={classes.icons}
              >
                <InfoOutlinedIcon />
              </CustomIconButton>
              <div style={{ padding: '10px 4px 10px 0px' }}>
                {smartRenderMsg.message}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  getSelectedResolution = () => {
    const {
      renderData: { resWidth, resHeight },
    } = this.props
    const { selectedResolution, resolutionpresets } = this.state

    const filterValues = resolutionpresets.filter((item) => {
      return item.width == resWidth && item.height == resHeight
    })
    const selectedValue = filterValues.length ? filterValues[0]?.name : 'custom'
    return selectedValue || selectedResolution
  }

  renderResolutionPresets = () => {
    let { resolutionpresets } = this.state
    const {
      classes,
      sceneData: { selected_camera: selectedCamera },
    } = this.props
    const activeChip = this.getSelectedResolution()
    const isDefaultCamera = selectedCamera == 'default'
    const presetValues = resolutionpresets.filter(
      (item) => !(isDefaultCamera && item.name == 'custom')
    )
    return (
      <div>
        <div>
          <TextDisplay
            variant="body2"
            label="Resolution Presets"
            sx={{ fontWeight: 500 }}
          />
        </div>
        <br />
        {presetValues.map((item) => (
          <>
            <Chips
              color={item.name === activeChip ? 'primary' : 'default'}
              label={item.name}
              onClick={() => {
                this.setState({ selectedResolution: item.name }, () => {
                  this.onResolutionChange(item.width, item.height)
                })
              }}
              size="small"
              className={classes.resolutionChip}
            />
          </>
        ))}
        <br /> <br />
      </div>
    )
  }

  deleteCameraView = (key) => {
    const { deleteCameraView, updateCamera } = this.props
    updateCamera('default')
    deleteCameraView(key)
    this.props.savePreferredCameraSettings()
  }

  setLockState = (item) => {
    const {
      selected_camera: selectedCamera,
      preferred_render_details = {},
      updateCamera = () => {},
    } = this.props
    const isDefaultCamera = item.key === 'default'
    const renderDetailslocked =
      preferred_render_details[item.key].locked || selectedCamera == 'default'

    const disablePreferredSaveButton = this.compareCameraSettings()
    if (!isDefaultCamera) {
      if (!disablePreferredSaveButton && !this.checkIfAlreadyPopupShown()) {
        this.showWarningPopup()
        return false
      }

      this.props.setRenderFormData({ locked: !renderDetailslocked })
      if (disablePreferredSaveButton) {
        this.props.savePreferredCameraSettings()
      }
    }
  }

  handleCardActionClick = (camera) => {
    this.setState({ warningPopUp: false })
    this.props.updateCamera(camera)
  }

  renderMultipleCamera = () => {
    const {
      cameraKeys = [],
      updateCamera,
      sceneData: { selected_camera: selectedCamera },
      setNewCamera,
      classes,
      preferred_render_details,
      savingScene,
    } = this.props
    let { isMultipleCamViewExpanded } = this.state

    const selectedCamName =
      cameraKeys.filter((cam) => cam.key === selectedCamera)[0]?.name || 'none'
    const camKeysLen = cameraKeys.length
    const lastCamKey = cameraKeys[camKeysLen - 1]?.key
    const newCamIdx =
      camKeysLen === 1
        ? 1
        : Number(lastCamKey?.split('_')[lastCamKey?.split('_').length - 1]) + 1
    const disableAddNew = cameraKeys
      .map((item) => item.key)
      .includes('preferred_camera') // Disable camera for historical scenes

    return (
      <>
        <div
          style={{
            height: isMultipleCamViewExpanded ? 350 : 'auto',
            overflow: 'auto',
            marginBottom: 5,
            pointerEvents: `${savingScene ? 'none' : 'initial'}`,
          }}
        >
          <Accordion
            classes={{
              root: classes.accordianSettings,
              expanded: classes.accordianExpanded,
            }}
            onChange={(event, expanded) => {
              this.setState({
                isMultipleCamViewExpanded: expanded,
              })
            }}
            expanded={isMultipleCamViewExpanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.accordianRoot,
              }}
            >
              <TextDisplay
                label={`${selectedCamName} (selected)`}
                variant="subtitle2"
                sx={{ fontWeight: 500 }}
              ></TextDisplay>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
              <>
                {cameraKeys.map((item) => (
                  <>
                    <CameraThumbnails
                      item={item}
                      title={item.name}
                      handleCardActionClk={(camera) =>
                        this.handleCardActionClick(camera)
                      }
                      selected={selectedCamera === item.key}
                      actionIcons={[
                        {
                          name: 'Lock',
                          label: this.getTooltipContent(
                            preferred_render_details[item.key] &&
                              preferred_render_details[item.key].locked
                          ),
                          icon:
                            item.key === 'default' ? (
                              <LockRoundedIcon fontSize="small" />
                            ) : preferred_render_details[item.key] &&
                              preferred_render_details[item.key].locked ? (
                              <LockRoundedIcon fontSize="small" />
                            ) : (
                              <LockOpenRoundedIcon fontSize="small" />
                            ),
                          show: true,
                          disabled:
                            item.key === 'default' ||
                            item.key !== selectedCamera
                              ? true
                              : false,
                          onClick: () => {
                            this.setLockState(item)
                          },
                        },
                        {
                          name: 'Delete',
                          label: 'Delete',
                          icon: <DeleteIcon fontSize="small" />,
                          show: item.key === 'default' ? false : true,
                          onClick: () => {
                            this.deleteCameraView(item.key)
                          },
                        },
                      ]}
                    />
                  </>
                ))}

                {!disableAddNew && camKeysLen < 6 && (
                  <div style={{ textAlign: 'center' }}>
                    <CustomIconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      onClick={() => {
                        setNewCamera(newCamIdx)
                        this.props.savePreferredCameraSettings(true)
                      }}
                      label={'Add new Camera'}
                    >
                      <AddAPhotoIcon />
                    </CustomIconButton>
                  </div>
                )}
                {disableAddNew && (
                  <Typography variant="caption">
                    <strong>NOTE: </strong> Movable camera feature is disabled
                    for historical scenes with saved preferred view.
                  </Typography>
                )}
              </>
            </AccordionDetails>
          </Accordion>
        </div>
      </>
    )
  }

  render() {
    const {
      classes,
      renderData: {
        noise,
        clipNear,
        clipFar,
        focalLength,
        zoomFactor,
        resWidth,
        resHeight,
        locked,
        time,
      },
      position: { x, y, z } = {},
      statusBarDisabled,
      currentCameraPosition: {
        x: camPosX = 0,
        y: camPosY = 0,
        z: camPosZ = 0,
      } = {},
      currentCameraTarget: {
        x: camTargetX = 0,
        y: camTargetY = 0,
        z: camTargetZ = 0,
      } = {},
      userHaveWriteAccess,
      cameraKeys,
      sceneData: { selected_camera: selectedCamera },
      updateCamera,
      rendererType,
      preferred_render_details = {},
    } = this.props
    const disableInputs =
      preferred_render_details[selectedCamera] &&
      preferred_render_details[selectedCamera].locked &&
      selectedCamera !== 'default'
    let disablePreferredSaveButton = this.compareCameraSettings()

    const isVrayRenderer = rendererType === 'V-Ray'

    return (
      <>
        <div className={classes.formRoot}>
          {this.renderOpenFormHeader()}
          <br />
          {this.renderMultipleCamera()}
          {isVrayRenderer && this.renderSmartRenderOptions()}
          {this.renderResolutionPresets()}
          <div>
            <div className={classes.resolutionWrapper}>
              <TextDisplay
                label="Resolution"
                variant="body2"
                sx={{ fontWeight: 500 }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div className={classes.formTextFieldRoot}>
                <FormTextField
                  value={resWidth}
                  name="resWidth"
                  label="width"
                  onChange={(e) => {
                    this.onResolutionChange(e.target.value, resHeight)
                  }}
                  variant="filled"
                  type="number"
                  size="small"
                  disabled={
                    this.getSelectedResolution() !== 'custom' ||
                    selectedCamera === 'default'
                  }
                  margin="normal"
                />
              </div>
              <div className={classes.formTextFieldRoot}>
                <FormTextField
                  value={resHeight}
                  name="resHeight"
                  label="height"
                  onChange={(e) => {
                    this.onResolutionChange(resWidth, e.target.value)
                  }}
                  variant="filled"
                  type="number"
                  size="small"
                  disabled={
                    this.getSelectedResolution() !== 'custom' ||
                    selectedCamera === 'default'
                  }
                  margin="normal"
                />
              </div>
            </div>
          </div>

          <Accordion
            classes={{
              root: classes.accordianSettings,
              expanded: classes.accordianExpanded,
            }}
            defaultExpanded={!isVrayRenderer}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.accordianRoot,
              }}
            >
              <TextDisplay
                label="Advanced Settings"
                variant="subtitle1"
                sx={{ fontWeight: 500 }}
              ></TextDisplay>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {/*Commented for removal of crop area size STHB-2017 */}
              {/* <div>
                <div className={classes.resolutionWrapper}>
                  <TextDisplay
                    label="Crop Area Size"
                    variant="body2"
                    sx={{ fontWeight: 500 }}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(width * resizeRatio)}
                      name="width"
                      label="Width"
                      onChange={this.handleChange}
                      variant="filled"
                      size="small"
                      helperText={
                        <span className={classes.helperText}>
                          Max limit {parseInt(frameWidth * resizeRatio)}
                        </span>
                      }
                      type="number"
                      disabled={renderDetailslocked}
                      margin="normal"
                    />
                  </div>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(height * resizeRatio)}
                      name="height"
                      label="Height"
                      onChange={this.handleChange}
                      variant="filled"
                      size="small"
                      helperText={
                        <span className={classes.helperText}>
                          Max limit {parseInt(frameHeight * resizeRatio)}
                        </span>
                      }
                      type="number"
                      disabled={renderDetailslocked}
                      margin="normal"
                    />
                  </div>
                </div>
              </div> */}

              <div>
                <div>
                  <TextDisplay
                    label="Lens"
                    variant="body2"
                    sx={{ fontWeight: 500 }}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={focalLength && focalLength.toFixed(2)}
                      name="focalLength"
                      label="Focal Length"
                      InputProps={{
                        inputProps: { min: 1, max: 300, step: ZF_STEP },
                        classes: { input: classes.resize },
                      }}
                      onChange={this.onFocalLengthChange}
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      helperText={
                        <span className={classes.helperText}>
                          Max limit 300
                        </span>
                      }
                    />
                  </div>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={zoomFactor && zoomFactor.toFixed(2)}
                      name="zoomFactor"
                      label="Zoom Factor"
                      onChange={this.onZoomFactorChange}
                      variant="filled"
                      size="small"
                      type="number"
                      disabled={disableInputs}
                      margin="normal"
                      InputProps={{
                        inputProps: { min: 0, max: 10, step: ZF_STEP },
                        classes: { input: classes.resize },
                      }}
                      helperText={
                        <span className={classes.helperText}>Max limit 10</span>
                      }
                    />
                  </div>

                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={
                        noise != '' && noise != null
                          ? noise
                          : isVrayRenderer
                          ? DEFAULT_NOISE
                          : DEFAULT_NOISE_CORONA
                      }
                      name="noise"
                      label="Noise"
                      onChange={this.handleChange}
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: isVrayRenderer ? 1 : 30,
                          step: 0.01,
                        },
                        classes: { input: classes.resize },
                      }}
                      helperText={
                        <span className={classes.helperText}>
                          {isVrayRenderer ? 'Max limit 1' : 'Max limit 30'}
                        </span>
                      }
                    />
                  </div>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={time !== '' && time !== null ? time : DEFAULT_TIME}
                      name="time"
                      label="Time"
                      onChange={this.handleChange}
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      InputProps={{
                        inputProps: { min: 1, max: 180 },
                        classes: { input: classes.resize },
                      }}
                      helperText={
                        <span className={classes.helperText}>
                          Max limit 180
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
              <br />
              <div>
                <div>
                  <TextDisplay
                    label="Camera Position"
                    variant="body2"
                    sx={{ fontWeight: 500 }}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(camPosX)}
                      name="X-Axis"
                      label="X-Axis"
                      onChange={(e) => {
                        this.onCameraPosChange(
                          parseInt(e.currentTarget.value || ''),
                          camPosY,
                          camPosZ
                        )
                      }}
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      disabled={disableInputs}
                    />
                  </div>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(camPosY)}
                      name="Y-Axis"
                      label="Y-Axis"
                      onChange={(e) => {
                        this.onCameraPosChange(
                          camPosX,
                          parseInt(e.currentTarget.value || ''),
                          camPosZ
                        )
                      }}
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      disabled={disableInputs}
                    />
                  </div>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(camPosZ)}
                      name="Z-Axis"
                      label="Z-Axis"
                      onChange={(e) => {
                        this.onCameraPosChange(
                          camPosX,
                          camPosY,
                          parseInt(e.currentTarget.value || '')
                        )
                      }}
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      disabled={disableInputs}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div>
                <div>
                  <TextDisplay
                    label="Camera Target Position"
                    variant="body2"
                    sx={{ fontWeight: 500 }}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(camTargetX)}
                      name="X-Axis"
                      label="X-Axis"
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      disabled
                    />
                  </div>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(camTargetY)}
                      name="Y-Axis"
                      label="Y-Axis"
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      disabled
                    />
                  </div>
                  <div className={classes.formTextFieldRoot}>
                    <FormTextField
                      value={parseInt(camTargetZ)}
                      name="Z-Axis"
                      label="Z-Axis"
                      variant="filled"
                      size="small"
                      type="number"
                      margin="normal"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <br />
              <div>
                <div>
                  <TextDisplay
                    label="Clipping Planes"
                    variant="body2"
                    sx={{ fontWeight: 500 }}
                  />
                </div>
                <br />
                <div>
                  <TextDisplay label="Clip Near" variant="body2" />
                </div>
                <Grid
                  container
                  spacing={3}
                  style={{ width: '100%' }}
                  alignItems="center"
                >
                  <Grid item xs>
                    <Slider
                      defaultValue={clipNear}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      value={clipNear}
                      step={1}
                      min={1}
                      max={2000}
                      onChange={this.handleClipNearChange}
                      disabled={disableInputs}
                    />
                  </Grid>
                  <Grid item>
                    <FormTextField
                      value={clipNear}
                      label="Z"
                      name="clipNear"
                      variant="filled"
                      size="small"
                      margin="dense"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 2000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                      onChange={this.handleChange}
                      disabled={disableInputs}
                      onBlur={this.handleChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <div>
                  <TextDisplay label="Clip Far" variant="body2" />
                </div>
                <Grid
                  container
                  spacing={3}
                  style={{ width: '100%' }}
                  alignItems="center"
                >
                  <Grid item xs>
                    <Slider
                      defaultValue={clipFar}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      value={clipFar}
                      step={1}
                      min={1}
                      max={2000}
                      onChange={this.handleClipFarChange}
                      disabled={disableInputs}
                    />
                  </Grid>
                  <Grid item>
                    <FormTextField
                      value={clipFar}
                      label="Z"
                      variant="filled"
                      name="clipFar"
                      size="small"
                      margin="dense"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 2000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                      onChange={this.handleChange}
                      disabled={disableInputs}
                      onBlur={this.handleChange}
                    />
                  </Grid>
                </Grid>
              </div>
              <br />
              <br />
              {!statusBarDisabled && (
                <div>
                  <div>
                    <TextDisplay
                      label="Asset Position"
                      variant="body2"
                      sx={{ fontWeight: 500 }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div className={classes.formTextFieldRoot}>
                      <FormTextField
                        value={parseInt(x)}
                        name="X-Axis"
                        label="X-Axis"
                        variant="filled"
                        size="small"
                        type="number"
                        margin="normal"
                        onChange={(e) => {
                          this.onElementChange(
                            parseInt(e.currentTarget.value || ''),
                            y,
                            z
                          )
                        }}
                        disabled={statusBarDisabled}
                      />
                    </div>
                    <div className={classes.formTextFieldRoot}>
                      <FormTextField
                        value={parseInt(y)}
                        name="Y-Axis"
                        label="Y-Axis"
                        variant="filled"
                        size="small"
                        type="number"
                        margin="normal"
                        onChange={(e) => {
                          this.onElementChange(
                            x,
                            parseInt(e.currentTarget.value || ''),
                            z
                          )
                        }}
                        disabled={statusBarDisabled}
                      />
                    </div>
                    <div className={classes.formTextFieldRoot}>
                      <FormTextField
                        value={parseInt(z)}
                        name="Z-Axis"
                        label="Z-Axis"
                        variant="filled"
                        size="small"
                        type="number"
                        margin="normal"
                        onChange={(e) => {
                          this.onElementChange(
                            x,
                            y,
                            parseInt(e.currentTarget.value || '')
                          )
                        }}
                        disabled={statusBarDisabled}
                      />
                    </div>
                  </div>

                  <br />
                  <br />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          <Grid container />

          {this.state.warningPopUp && this.renderConfirmPopup()}
        </div>
        <div className={classes.formActionBar}>
          {selectedCamera && selectedCamera !== 'default' ? (
            <>
              <div style={{ padding: 10 }} ref={this.warnRef}>
                <CustomButton
                  label="Save changes"
                  onClick={() => {
                    this.props.savePreferredCameraSettings(true)
                  }}
                  color="primary"
                  disabled={disablePreferredSaveButton}
                  variant="contained"
                />
              </div>
              <br />
              <br />
            </>
          ) : null}

          {userHaveWriteAccess ? (
            <div style={{ padding: 10 }}>
              <CustomButton
                label="Render"
                onClick={() => {
                  this.props.checkMaxAndRender()
                }}
                color="primary"
                variant="contained"
              />
            </div>
          ) : null}
        </div>
      </>
    )
  }
}
RenderForm.propTypes = {
  setRenderFormData: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  renderData: PropTypes.object.isRequired,
}
export default withStyles(styles)(RenderForm)
