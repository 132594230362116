import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import axios from 'axios'
import { fetchRenditionImage } from '../../store/customCard/actionCreator'
import { OverlayLoader } from '../../components/Loaders'
import { showNotification } from '../../store/notification/actionCreator'
import {
  fetchRenderComments,
  saveAnnotationMode,
  saveCommentId,
} from '../../store/comments/ActionCreator'
import ImagePreview from '../ImagePreview/imagePreview'
import RenderComments from '../ImagePreview/renderComments'
import PreviewHeader from '../ImagePreview/previewHeader'
import { RENDER_HEADER_HEIGHT } from '../../constants/scene'
import Scribbler from '../scribble'
import CapsuleIconBar from '../iconsCapsuleBar'
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal'
import EditIcon from '@mui/icons-material/Edit'
import CommentIcon from '@mui/icons-material/Comment'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ArrowBack from '@mui/icons-material/ArrowBack'

import {
  LOADING_PREVIEW_RENDER_IMAGE,
  ERROR_MSG_FOR_PREVIEW_RENDER_IMAGE_FAILED,
} from '../../constants/displayMessages'

import {
  CustomIconButton,
  PageTitleBar,
  BreadcrumbComponent,
} from 'cgi-ui-components'

import { openSlackChannel } from '../../helpers/utils'

const styles = (theme) => ({
  previewImage: {
    height: 'calc(100vh - 190px)',
    maxWidth: 'calc(75vw - 160px)',
    maxHeight: 'calc(100vh - 190px - 60px)',
  },
  container: {
    top: RENDER_HEADER_HEIGHT,
    height: '100vh',
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    flexDirection: 'column',
  },
  commentsContainer: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.dark}`,
    height: '100%',
    boxSizing: 'border-box',
    borderBottom: `20px solid ${theme.palette.secondary.main}`,
    overflowX: 'hidden',
  },
  previewHeader: {
    height: 60,
    backgroundColor: 'aquamarine',
  },
  paddingContainer: {
    height: 60,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  actionIcon: { padding: 5 },
  annotaionContainer: {
    position: 'relative',
    height: 'calc(100vh - 190px)',
    margin: 'auto',
    maxWidth: 'calc(75vw - 160px)',
    maxHeight: 'calc(100vh - 190px - 60px)',
  },
  mainHeader: {
    display: 'flex',
    margin: '5px 0px',
  },
  headerPadding: {
    padding: '10px 40px',
    backgroundColor: theme.palette.secondary.main,
  },
})

class RenderImage extends React.Component {
  state = {
    loaderDisplayText: '',
    imageLoader: false,
    imageBlob: '',
    loaderErrorType: '',
    isScribbleActive: false,
    isCommentActive: false,
    isEraserActive: false,
    activeAnnotations: '',
    isIconDisabled: true,
  }

  axiosSource = axios.CancelToken.source()
  handleClose = () => {
    const { closePreview = () => {}, saveAnnotationMode = () => {} } =
      this.props

    closePreview()
    saveAnnotationMode(false)
  }
  componentDidMount() {
    const { imageData, fetchRenderComments } = this.props
    if (imageData && imageData.img) {
      this.setState({
        imageLoader: true,
        loaderErrorType: '',
        loaderDisplayText: LOADING_PREVIEW_RENDER_IMAGE,
      })
      fetchRenditionImage(
        imageData.img,
        this.onImageLoadSuccess,
        this.onImageLoadFailed,
        this.axiosSource.token
      )
    }
    if (imageData && imageData.imageDetails.render_job_id) {
      fetchRenderComments(imageData.imageDetails.render_job_id)
    }
    window.addEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = () => {
    const { aspectRatio } = this.state
    let containerAspectRatio =
      (window.innerWidth * 0.75 - 160) / (window.innerHeight - 190 - 60)

    if (aspectRatio < containerAspectRatio) {
      this.setState({
        imageWidth: (window.innerHeight - 190 - 60) * aspectRatio,
        imageHeight: window.innerHeight - 190 - 60,
      })
    } else {
      this.setState({
        imageWidth: window.innerWidth * 0.75 - 160,
        imageHeight: (window.innerWidth * 0.75 - 160) / aspectRatio,
      })
    }
  }

  onImageLoadSuccess = (response) => {
    this.setState({
      imageBlob: URL.createObjectURL(response),
      imageLoader: false,
      loaderErrorType: '',
      loaderDisplayText: '',
    })

    let that = this
    let image = new Image()

    image.src = URL.createObjectURL(response)
    image.onload = function () {
      let aspectRatio = this.width / this.height
      let containerAspectRatio =
        (window.innerWidth * 0.75 - 160) / (window.innerHeight - 190 - 60)
      if (aspectRatio < containerAspectRatio) {
        that.setState({
          imageWidth: (window.innerHeight - 190 - 60) * aspectRatio,
          imageHeight: window.innerHeight - 190 - 60,
          aspectRatio,
        })
      } else {
        that.setState({
          imageWidth: window.innerWidth * 0.75 - 160,
          imageHeight: (window.innerWidth * 0.75 - 160) / aspectRatio,
          aspectRatio,
        })
      }
    }
  }
  onImageLoadFailed = (error) => {
    this.setState({
      imageBlob: '',
      imageLoader: false,
      loaderErrorType: '',
      loaderDisplayText: '',
    })
    this.props.showNotification(
      true,
      ERROR_MSG_FOR_PREVIEW_RENDER_IMAGE_FAILED,
      'error'
    )
    this.handleClose()
  }

  toggleAnnotation = () => {
    const { saveAnnotationMode, isAnnotationMode } = this.props
    this.setState({
      isScribbleActive: false,
      isCommentActive: false,
      isEraserActive: false,
    })
    saveAnnotationMode(!isAnnotationMode)
  }

  filterAnnotationList = () => {
    const { commentsList } = this.props
    return commentsList
      .sort((a, b) => new Date(a.time_stamp) - new Date(b.time_stamp))
      .map((comment) => {
        if (comment.annotation && comment.annotation.geometry.type) {
          return {
            geometry: comment.annotation.geometry,
            data: comment.annotation.data,
            commentId: comment.render_comment_id,
          }
        }
      })
      .filter((annotation) => annotation != undefined)
      .map((annotation, index) => ({ ...annotation, count: index + 1 }))
  }

  onMouseOverAnnotation = (data) => {
    if (data.annotation_comment && data.annotation && data.annotation.data) {
      this.setState({
        activeAnnotations: [data.annotation.data.id],
      })
    }
  }

  onMouseOutAnnotation = (data) => {
    if (data.annotation_comment && data.annotation && data.annotation.data) {
      const index = this.state.activeAnnotations.indexOf(
        data.annotation.data.id
      )

      this.setState({
        activeAnnotations: [
          ...this.state.activeAnnotations.slice(0, index),
          ...this.state.activeAnnotations.slice(index + 1),
        ],
      })
    }
  }

  activateActionButtons = () => {
    this.setState({ isIconDisabled: false })
  }

  navigateTo = () => {
    this.props.history.goBack()
    this.handleClose()
  }
  renderPageHeader = () => {
    const { classes, history, match, closePreview, experience_name } =
      this.props
    const {
      params: { expId },
    } = match
    return (
      <div className={classes.headerPadding}>
        <div className={classes.mainHeader}>
          <div>
            <CustomIconButton onClick={this.navigateTo} label={'Back'}>
              <ArrowBack />
            </CustomIconButton>
          </div>
          <div style={{ marginLeft: 20 }}>
            <PageTitleBar
              title={`View shot`}
              subTitle={`Manage comments, annotate, export`}
            />
          </div>
        </div>

        <BreadcrumbComponent
          activeLast
          items={[
            {
              link: '#',
              name: 'Home',
              onClick: (e) => {
                e.preventDefault()
                history.push('/home')
              },
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },
            {
              link: '#',
              onClick: (e) => {
                e.preventDefault()
                history.push(`/experience/${expId}`)
              },
              name: `${experience_name}`,
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },
            {
              link: '#',
              onClick: (e) => {
                e.preventDefault()
                history.push(`/experience/${expId}?rendermode=true`)
              },
              name: `Render scene`,
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },

            {
              name: 'Preview render shots and exports',
              link: '#',
              onClick: (e) => {
                e.preventDefault()
                closePreview()
              },
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },
            {
              name: 'View Shot',
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },
          ]}
        />
      </div>
    )
  }

  togglePannel = (e, tab) => {
    if (tab === 'REPORT') {
      openSlackChannel()
    }
    this.setState({
      isScribbleActive: tab === 'SCRIBBLE',
      isCommentActive: tab === 'COMMENT',
      isEraserActive: tab === 'ERASE',
    })
  }

  render() {
    const {
      classes,
      imageData,
      openConfirmDialog,
      closePreview = () => {},
      currentExportingJobId = '',
      userHaveWriteAccess = true,
      externalUser = false,
      exportStatus = '',
      isAnnotationMode,
      commentsList = [],
      saveCommentId = () => {},
      lanId,
      startDownLoad,
      exportRender,
    } = this.props
    const { show, imageDetails = {} } = imageData
    const {
      imageLoader,
      imageBlob,
      loaderDisplayText,
      loaderErrorType,
      isScribbleActive = false,
      isCommentActive = false,
      isEraserActive = false,
      activeAnnotations = '',
      imageWidth,
      imageHeight,
      isIconDisabled = true,
    } = this.state

    const barPlacement = {
      left: -65,
      top: '50%',
      transform: `translateY(-50%)`,
      position: 'absolute',
      right: 'unset',
    }

    const annotations = this.filterAnnotationList()

    const iconListForCapsuleBar = [
      {
        toolTip: 'Scrible',
        name: 'SCRIBBLE',
        placement: 'left',
        icon: isScribbleActive ? (
          <EditIcon color="primary" />
        ) : (
          <EditOutlinedIcon />
        ),
      },
      {
        toolTip: 'Erase',
        name: 'ERASE',
        placement: 'left',
        icon: isEraserActive ? (
          <AutoFixNormalIcon color="primary" />
        ) : (
          <AutoFixNormalIcon />
        ),
      },
      {
        toolTip: 'Comment',
        name: 'COMMENT',
        placement: 'left',
        icon: isCommentActive ? (
          <CommentIcon color="primary" />
        ) : (
          <CommentOutlinedIcon />
        ),
      },
    ]
    const simpleIconListForCapsuleBar = [
      {
        toolTip: 'Report',
        name: 'REPORT',
        placement: 'left',
        icon: <SentimentDissatisfiedOutlinedIcon color="error" />,
        color: 'error',
        action: openSlackChannel,
      },
    ]
    return (
      <>
        <ImagePreview openForm={show}>
          <div className={classes.container}>
            {!imageLoader && imageBlob ? (
              <>
                {this.renderPageHeader()}
                <PreviewHeader
                  imageDetails={imageDetails}
                  openConfirmDialog={openConfirmDialog}
                  closePreview={closePreview}
                  currentExportingJobId={currentExportingJobId}
                  exportStatus={exportStatus}
                  userHaveWriteAccess={userHaveWriteAccess}
                  externalUser={externalUser}
                  exportRender={exportRender}
                  itemId={imageDetails.render_job_id}
                  toggleAnnotation={this.toggleAnnotation}
                  isAnnotationMode={isAnnotationMode}
                  startDownLoad={startDownLoad}
                />
                <div
                  className={classes.annotaionContainer}
                  style={{
                    width: `${imageWidth}px`,
                    height: `${imageHeight}px`,
                  }}
                >
                  <div
                    className={classes.previewImage}
                    style={{
                      width: `${imageWidth}px`,
                      height: `${imageHeight}px`,
                    }}
                  >
                    <Scribbler
                      className={classes.previewImage}
                      imgSrc={imageBlob}
                      isScribbleActive={isScribbleActive}
                      isCommentActive={isCommentActive}
                      isEraserActive={isEraserActive}
                      imageDetails={imageDetails}
                      commentsList={commentsList}
                      annotations={annotations}
                      activeAnnotations={activeAnnotations}
                      saveCommentId={saveCommentId}
                      userlanId={lanId}
                      isAnnotationMode={isAnnotationMode}
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      activateActionButtons={this.activateActionButtons}
                    />
                    {isAnnotationMode && (
                      <CapsuleIconBar
                        icons={iconListForCapsuleBar}
                        onChange={this.togglePannel}
                        barPlacement={barPlacement}
                        simpleButton={simpleIconListForCapsuleBar}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.paddingContainer} />
              </>
            ) : null}
            {imageLoader ? (
              <OverlayLoader
                displayText={loaderDisplayText}
                loaderErrorType={loaderErrorType}
              />
            ) : null}
          </div>
          <div className={classes.commentsContainer}>
            <RenderComments
              imageDetails={imageDetails}
              onCloseHandler={this.handleClose}
              onMouseOverAnnotation={this.onMouseOverAnnotation}
              onMouseOutAnnotation={this.onMouseOutAnnotation}
            />
          </div>
        </ImagePreview>
      </>
    )
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }
}
const mapStateToProps = ({
  auth: { lanId = '' },
  comments: { isAnnotationMode, commentsList },
}) => ({
  isAnnotationMode,
  commentsList,
  lanId,
})

const mapDispatchToProps = {
  showNotification,
  fetchRenderComments,
  saveAnnotationMode,
  saveCommentId,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RenderImage))
