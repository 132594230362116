import React, { useState, useEffect } from 'react'
import { BasicCheckbox } from 'cgi-ui-components'
import { makeStyles } from '@mui/styles'
import DefaultImage from '../../images/ic_default_board_img.png'
import { fetchRenditionImage } from '../../store/customCard/actionCreator'
import config from '../../config/config'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 100,
    width: 90,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  media: { height: 75 },
  action: { textAlign: 'center', height: 25, borderTop: '1px solid #E0E0E0' },
}))

const MaterialCard = ({
  url = '',
  setSelected = () => {},
  details,
  isColor = false,
}) => {
  const classes = useStyles()
  const [src, setSrc] = useState(DefaultImage)

  useEffect(() => {
    if (!isColor) fetchImage()
  }, [])

  useEffect(() => {
    if (!isColor) fetchImage()
  }, [url])

  const fetchImage = () => {
    if (url === null) {
      setSrc(DefaultImage)
      return
    }
    let renditionsUrl = `${config.renditionsUrl}/${url}`

    fetchRenditionImage(
      renditionsUrl,
      (response) => {
        setSrc(URL.createObjectURL(response))
      },
      () => {
        setSrc(DefaultImage)
      }
    )
  }
  return (
    <div
      className={classes.root}
      style={{
        border: `${
          details.selected ? '2px solid #CC0000' : '2px solid #FFFFFF'
        }`,
      }}
    >
      <div
        className={classes.media}
        style={{
          ...(!isColor && { backgroundImage: `url(${src})` }),

          ...(isColor && { backgroundColor: url }),
          backgroundSize: 'cover',
        }}
      />
      <div className={classes.action}>
        <BasicCheckbox
          style={{ padding: 'unset' }}
          checked={details.selected}
          size={'small'}
          onChange={() =>
            setSelected([{ ...details, selected: !details.selected }])
          }
        />
      </div>
    </div>
  )
}

export default MaterialCard
