import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  PageTitleBar,
  CustomIconButton,
  BasicTabs,
  CustomButton,
} from 'cgi-ui-components'
import { connect } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import ColorEditor from './ColorEditor'
import TextureEditor from './TextureEditor'
import RefreshIcon from '@mui/icons-material/Refresh'
import { COLOUR_TEXTURE_EDIT_NOTE } from '../../constants/displayMessages'
import ACTIONS from '../../constants/actions'
import {
  clearData,
  fetchSrcMaterialList,
  resetToOriginalMaterialList,
} from '../../store/materialEditor/actionCreator'
import { setActiveOperation } from '../../store/operations/actionCreator'
import { switchColorTexture, reloadAsset } from '../designSpace'

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    position: 'absolute',
    right: 90,
    top: 10,
    bottom: 50,
    background: 'white',
    width: 475,
    zIndex: 100,
    overflowX: 'hidden',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px',
    height: 40,
    boxSizing: 'border-box',
  },
  container: {
    height: `calc(100vh - 250px)`,
  },
  formActionBar: {
    display: 'flex',
    zIndex: 1,
    height: 60,
    width: '100%',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
    justifyContent: 'end',
  },
  diplayText: {
    color: '#A64C01',
    background: 'rgba(237, 108, 2, 0.08)',
    fontSize: 12,
    fontWeight: 400,
    height: 50,
    padding: 10,
    margin: 8,
    borderRadius: 4,
    lineHeight: '1rem',
  },
  footer: {
    height: 150,
    borderTop: '1px solid #E0E0E0',
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  tabs: { height: `calc(100vh - 250px)` },
  padding10: { padding: 10 },
}))
const MaterialEditorWrapper = ({
  experience_id,
  fetchSrcMaterialList = () => {},
  clearData = () => {},
  activeOperations = {},
  saveScene = () => {},
  resetToOriginalMaterialList,
  setActiveOperation,
  srcMaterialList = [],
  selectedTexture = {},
}) => {
  const classes = useStyles()
  const [isActionDisabled, setIsActionDisabled] = useState(true)

  useEffect(() => {
    fetchSrcMaterialList(
      activeOperations?.value?.name,
      experience_id,
      activeOperations?.value?.id
    )
    return () => {
      clearData(true)
    }
  }, [])
  return (
    <div className={classes.drawerRoot}>
      <div className={classes.headerTitle}>
        <PageTitleBar title={'Edit Material'} />
        <CustomIconButton
          onClick={() =>
            setActiveOperation(ACTIONS.EDIT_MATERIAL, {
              status: false,
            })
          }
          label={'Close'}
        >
          <CloseIcon />
        </CustomIconButton>
      </div>

      <div className={classes.container}>
        <BasicTabs
          className={classes.tabs}
          onTabSelect={(e, value) => {
            clearData()
          }}
          textColor="primary"
          variant="fullWidth"
          tabItems={[
            {
              label: 'Color',
              content: (
                <ColorEditor
                  activeOperations={activeOperations}
                  variant={'color'}
                  style={{ height: '100%' }}
                  enableActions={(e) => setIsActionDisabled(false)}
                />
              ),
            },
            {
              label: 'Texture',
              content: (
                <TextureEditor
                  activeOperations={activeOperations}
                  variant="texture"
                  enableActions={(e) => setIsActionDisabled(false)}
                />
              ),
            },
          ]}
        />
      </div>

      <div className={classes.footer}>
        <div
          className={classes.diplayText}
          dangerouslySetInnerHTML={{ __html: COLOUR_TEXTURE_EDIT_NOTE }}
        ></div>
        <div className={classes.formActionBar}>
          <div className={classes.padding10}>
            <CustomButton
              label="Reset"
              onClick={() => {
                setIsActionDisabled(true)
                resetToOriginalMaterialList()
                reloadAsset().then(saveScene)
              }}
              color="tertiary"
              startIcon={<RefreshIcon />}
              variant="text"
            />
          </div>
          <div className={classes.padding10}>
            <CustomButton
              label="Apply Changes"
              onClick={() => {
                switchColorTexture(srcMaterialList, selectedTexture).then(
                  saveScene
                )
              }}
              color="primary"
              variant="contained"
              disabled={isActionDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  ({
    experiences: {
      experience: { experience_id },
    },
    materialEditor: { srcMaterialList, selectedTexture },
  }) => ({ experience_id, srcMaterialList, selectedTexture }),
  {
    clearData,
    fetchSrcMaterialList,
    resetToOriginalMaterialList,
    setActiveOperation,
  }
)(MaterialEditorWrapper)
