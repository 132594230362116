import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Dialog } from '@mui/material'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import { RENDER_HEADER_HEIGHT } from '../../constants/scene'

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: '32px',
    top: '32px',
    color: theme.palette.grey[500],
  },
  closeIcon: {
    width: '1.4em',
    height: '1.0em',
    marginTop: '0.3em',
  },
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'unset',
    },
    '& .MuiDialogContent-root:first-child ': {
      padding: 'unset',
    },
  },
  layout: {
    display: 'grid',
    gridTemplateColumns: '75% 25%',
    padding: 'unset',
    backgroundColor: theme.palette.secondary.dark,
  },
})

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions)

class ImagePreview extends React.Component {
  render() {
    const {
      children,
      openForm = false,
      modalActions = '',
      classes,
    } = this.props
    return (
      <>
        <Dialog
          fullScreen
          aria-labelledby="customized-dialog-title"
          open={openForm}
          className={classes.root}
          disableBackdropClick
          disableEscapeKeyDown
          style={{
            top: RENDER_HEADER_HEIGHT,
            display: 'inline-block',
            zIndex: 2,
            height: '100vh',
          }}
        >
          <MuiDialogContent className={classes.layout}>
            {children}
          </MuiDialogContent>
          {modalActions && <DialogActions>{modalActions}</DialogActions>}
        </Dialog>
        <div />
      </>
    )
  }
}

ImagePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  openForm: PropTypes.bool,
  modalActions: PropTypes.string,
  onCloseHandler: PropTypes.func,
  children: PropTypes.node,
}
export default withStyles(styles)(ImagePreview)
