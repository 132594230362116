import React from 'react'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import RenderForm from '../renderForm'
import { RENDER_HEADER_HEIGHT } from '../../constants/scene'

const styles = (theme) => ({
  drawerPaper: {},
  root: {
    backgroundColor: theme.palette.secondary.main,
    right: 10,
    overflowY: 'auto',
    paddingBottom: 20,
    height: '100%',
  },
})

class RenderDrawer extends React.Component {
  render() {
    const {
      classes,
      setRenderFormData,
      renderData,
      currentCameraPosition,
      currentCameraTarget,
      currentCameraAngles,
      disableStatusBar,
      statusBarDisabled,
      userHaveWriteAccess,
      setViewCameraSettings,
      rendererType,
      ...rest
    } = this.props
    return (
      <div className={classes.root}>
        <RenderForm
          setRenderFormData={setRenderFormData}
          renderData={renderData}
          currentCameraPosition={currentCameraPosition}
          currentCameraTarget={currentCameraTarget}
          currentCameraAngles={currentCameraAngles}
          statusBarDisabled={disableStatusBar}
          userHaveWriteAccess={userHaveWriteAccess}
          setViewCameraSettings={setViewCameraSettings}
          rendererType={rendererType}
          {...rest}
        />
      </div>
    )
  }
}
RenderDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  setRenderFormData: PropTypes.func.isRequired,
  renderData: PropTypes.object.isRequired,
  disableStatusBar: PropTypes.bool,
}
export default withStyles(styles)(RenderDrawer)
