import {
  FETCH_ARCH_ASSETS_INITIATED,
  FETCH_ARCH_ASSETS_SUCCESS,
  FETCH_ARCH_ASSETS_FAILED,
  CLEAR_RESULTS,
  FETCH_UPLOAD_STATUS_INITIATED,
  FETCH_UPLOAD_STATUS_FAILED,
  FETCH_UPLOAD_STATUS_SUCCESS,
  SET_STATUS_WINDOW_DISPLAY,
  SAVE_ASSETS_INITIATED,
  SAVE_ASSETS_SUCCESS,
  SAVE_ASSETS_FAIL,
  DELETE_ASSETS_SUCCESS,
  EDIT_ASSETS_INITIATED,
  EDIT_ASSETS_FAIL,
  EDIT_ASSETS_SUCCESS,
  UPLOAD_STATUS_INITIATED,
  UPLOAD_STATUS_SUCCESS,
  UPLOAD_STATUS_FAIL,
  ASSET_UPLOAD_COMPLETED,
  HANDLE_SELECT_ARCHITECTURE_ASSET,
} from './actionType'

import { SUCCESS } from '../../constants/common'
import { getUploadStatus } from '../../helpers/utils'

export const initialState = {
  loading: false,
  categories: [],
  assetList: [],
  total_count: 0,
  status_response: [],
  showStatusWindow: true,
  loadingSavedAsset: false,
  loadingEditAsset: false,
  savedAssetStatusCode: 0,
  selectedAssets: [],
  selectedAssetsData: [],
}

export default function assets(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARCH_ASSETS_INITIATED:
      return { ...state, ...action.data }

    case FETCH_ARCH_ASSETS_SUCCESS: {
      const status = {
        InProgress: 1,
        Failed: 2,
        Success: 3,
      }

      let modifiedStatusResponse =
        action.data.status_response &&
        action.data.status_response.map((item) => {
          item.derivedStatus = getUploadStatus(
            item.hpa_upload_status,
            item.thumbnail_upload_status
          ).status
          return item
        })

      const resultObj = {
        loading: false,
        categories: action.data.categories
          ? [...state.categories, ...action.data.categories]
          : state.categories,
        assetList: action.data.search_response
          ? [...action.data.search_response]
          : state.assetList,
        total_count: action.data.total_count,
        status_response: modifiedStatusResponse.sort(
          (a, b) => status[a.derivedStatus] - status[b.derivedStatus]
        ),
      }
      return {
        ...state,
        ...resultObj,
      }
    }
    case HANDLE_SELECT_ARCHITECTURE_ASSET: {
      const { isSelected, data, clearData } = action.payload
      let selectedAssets = []
      let selectedAssetsData = []
      if (!clearData) {
        if (isSelected) {
          selectedAssets = [...state.selectedAssets, data.asset_id]
          selectedAssetsData = [
            ...state.selectedAssetsData,
            {
              ...data,
              asset_type: data.asset_type === 'ARCHITECTURE' ? 'ARCH' : '',
              value: data.asset_id,
            },
          ]
        } else {
          selectedAssets = state.selectedAssets.filter(
            (i) => i !== data.asset_id
          )
          selectedAssetsData = state.selectedAssetsData.filter(
            ({ value }) => value !== data.asset_id
          )
        }
      }

      return { ...state, selectedAssets, selectedAssetsData }
    }

    case FETCH_ARCH_ASSETS_FAILED:
      return { ...state, loading: false }

    case CLEAR_RESULTS:
      return { ...state, categories: [], assetList: [], total_count: 0 }

    case FETCH_UPLOAD_STATUS_INITIATED:
      return { ...state }

    case FETCH_UPLOAD_STATUS_SUCCESS: {
      const status = {
        InProgress: 1,
        Failed: 2,
        Success: 3,
      }
      let responseMap = {}
      action.data.forEach((item) => {
        responseMap[item.asset_id] = item
      })

      let responseArray = state.status_response.map((item) => {
        let newAttributes = {}

        if (
          item.hpa_upload_status !== SUCCESS &&
          item.thumbnail_upload_status !== SUCCESS &&
          responseMap[item.asset_id] &&
          responseMap[item.asset_id].hpa_upload_status === SUCCESS &&
          responseMap[item.asset_id].thumbnail_upload_status === SUCCESS
        ) {
          newAttributes.showSuccessTick = true
        }

        newAttributes.derivedStatus = responseMap[item.asset_id]
          ? getUploadStatus(
              responseMap[item.asset_id].hpa_upload_status,
              responseMap[item.asset_id].thumbnail_upload_status
            ).status
          : getUploadStatus(
              item.hpa_upload_status,
              item.thumbnail_upload_status
            ).status

        if (responseMap[item.asset_id]) {
          newAttributes.hpa_upload_status =
            responseMap[item.asset_id].hpa_upload_status
          newAttributes.thumbnail_upload_status =
            responseMap[item.asset_id].thumbnail_upload_status
          newAttributes.thumbnail_path =
            responseMap[item.asset_id].thumbnail_path
        }

        return { ...item, ...newAttributes }
      })

      responseArray.sort(
        (a, b) => status[a.derivedStatus] - status[b.derivedStatus]
      )

      return {
        ...state,
        status_response: responseArray,
        loading: false,
      }
    }

    case ASSET_UPLOAD_COMPLETED: {
      const status = {
        InProgress: 1,
        Failed: 2,
        Success: 3,
      }
      let responseArray = state.status_response.map((item) => {
        let newAttributes = {}
        if (
          item.hpa_upload_status === SUCCESS &&
          item.thumbnail_upload_status === SUCCESS
        ) {
          newAttributes.showSuccessTick = false
        }
        newAttributes.derivedStatus = getUploadStatus(
          item.hpa_upload_status,
          item.thumbnail_upload_status
        ).status
        newAttributes.thumbnail_path = item.thumbnail_path
        return { ...item, ...newAttributes }
      })
      responseArray.sort(
        (a, b) => status[a.derivedStatus] - status[b.derivedStatus]
      )
      return {
        ...state,
        status_response: responseArray,
      }
    }

    case FETCH_UPLOAD_STATUS_FAILED:
      return {
        ...state,
      }

    case SET_STATUS_WINDOW_DISPLAY:
      return {
        ...state,
        showStatusWindow: action.data,
      }

    case SAVE_ASSETS_INITIATED: {
      return {
        ...state,
        loadingSavedAsset: true,
      }
    }
    case SAVE_ASSETS_SUCCESS: {
      return {
        ...state,
        loadingSavedAsset: false,
        status_response: [{ ...action.data }, ...state.status_response],
        savedAssetStatusCode: 200,
      }
    }
    case SAVE_ASSETS_FAIL: {
      return {
        ...state,
        loadingSavedAsset: false,
        savedAssetStatusCode: action.error,
      }
    }

    case UPLOAD_STATUS_INITIATED: {
      return {
        ...state,
        loadingStatusUpload: true,
        getStatusUploadData: {},
      }
    }
    case UPLOAD_STATUS_SUCCESS: {
      return {
        ...state,
        loadingStatusUpload: false,
        getStatusUploadData: action.data,
      }
    }
    case UPLOAD_STATUS_FAIL: {
      return {
        ...state,
        loadingStatusUpload: false,
      }
    }
    case DELETE_ASSETS_SUCCESS: {
      let find = state.categories.findIndex(
        (item) => item.name === action.data.category
      )
      let data = state.categories
      if (find >= 0) {
        data[find].count = data[find].count - 1
      }
      return {
        ...state,
        assetList: state.assetList.filter(
          (item) => item.asset_id !== action.data.asset_id
        ),
        status_response: state.status_response.filter(
          (item) => item.asset_id !== action.data.asset_id
        ),
        categories: data,
        total_count: state.total_count - 1,
      }
    }
    case EDIT_ASSETS_INITIATED: {
      return {
        ...state,
        loadingEditAsset: true,
      }
    }
    case EDIT_ASSETS_FAIL: {
      return {
        ...state,
        loadingEditAsset: false,
      }
    }
    case EDIT_ASSETS_SUCCESS: {
      return {
        ...state,
        loadingEditAsset: false,
        status_response: state.status_response.map((item) => {
          if (item.asset_id === action.data.asset_id) {
            return {
              ...action.data,
            }
          } else {
            return {
              ...item,
            }
          }
        }),
      }
    }

    default:
      return state
  }
}
