import {
  SET_RENDER_DATA,
  SET_CONTROLS_POSITION,
  SET_SCENE_DATA,
  RESET_SCENE_DATA,
  SET_RENDER_FORM_DATA,
  SHOW_SCENE_EDIT_FORM,
  SET_DEFAULT_NOISE_BASED_ON_RENDERER,
  SET_SCENE_MODE,
  SET_NEW_CAMERA,
  SET_CAMERA_SNAPSHOT,
  DELETE_CAMERA_VIEW,
  SAVE_SCENE_INITIATED,
  SAVE_SCENE_COMPLETE,
} from './ActionType'
import { DEFAULT_NOISE, DEFAULT_TIME } from '../../constants/common'

export const initialState = {
  renderDetails: {
    passes: 0,
    noise: DEFAULT_NOISE,
    x: 0,
    y: 0,
    width: 300,
    height: 300,
    resizeRatio: 1,
    screenWidth: 100,
    screenHeight: 100,
    locked: false,
    time: DEFAULT_TIME,
  },

  preferred_render_details: {},
  savedRenderDetails: null,
  controls: {
    right: 30,
  },
  sceneData: {
    assets: [],
    camera: {},
    render_camera: {},
    groups: [],
    preferred_cameras: {},
    selected_camera: 'default',
    saveCustomCamera: false,
    currentCamera: {},
  },
  cameraKeys: [{ name: 'Default Camera', key: 'default', selected: 'true' }],
  sceneEditFormOpen: false,
  copyScene: false,
  mode: 'TCIN',
  savingScene: false,
}

export default function experiences(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONTROLS_POSITION: {
      return {
        ...state,
        controls: { right: action.data },
      }
    }

    case SET_SCENE_DATA: {
      const { sceneData } = state
      let newData = { ...action.data }
      if (newData.preferred_cameras === null) {
        newData.preferred_cameras = {}
      }
      let modifiedCamKeys

      if (
        newData.preferred_cameras &&
        Object.keys(newData.preferred_cameras).length !==
          state.cameraKeys.length - 1
      ) {
        const preferred_cameras = Object.keys(newData.preferred_cameras)

        let prefered_camera_keys = preferred_cameras.map((item, idx) => {
          let camKeyNameSplit = item.split('_')
          return {
            name: `Preferred View ${
              camKeyNameSplit[camKeyNameSplit.length - 1]
            }`,
            key: item,
            selected: 'false',
            imgUrl:
              newData?.preferred_cameras[item]?.cameraThumbnailLocation || '',
          }
        })

        modifiedCamKeys = [
          { name: 'Default Camera', key: 'default', selected: 'true' },
          ...prefered_camera_keys,
        ]
      }
      let savedRenderDetails = {}
      const renderData = newData.selected_camera
        ? state.preferred_render_details[newData.selected_camera]
        : undefined
      if (renderData && renderData.resize_ratio) {
        savedRenderDetails = {
          x: renderData.crop_x,
          y: renderData.crop_y,
          resizeRatio: renderData.resize_ratio,
          resHeight: renderData.scene_height,
          resWidth: renderData.scene_width,
          width: renderData.image_width,
          height: renderData.image_height,
          screenHeight: renderData.screen_height,
          screenWidth: renderData.screen_width,
          noise: renderData.noise_level,
          passes: renderData.pass_limit,
          sceneHeight: renderData.scene_height,
          sceneWidth: renderData.scene_width,
          locked: renderData.locked,
          time: renderData.time_limit_minutes,
        }
      }
      return {
        ...state,
        sceneData: {
          ...sceneData,
          ...newData,
        },
        savedRenderDetails: {
          ...state.savedRenderDetails,
          ...savedRenderDetails,
        },
        cameraKeys: modifiedCamKeys ? [...modifiedCamKeys] : state.cameraKeys,
      }
    }
    case SET_RENDER_DATA: {
      const {
        sceneData: { selected_camera },
      } = state
      const renderData = action.data[selected_camera]

      let savedRenderDetails = {}
      if (action.data[selected_camera]) {
        savedRenderDetails = {
          x: renderData.crop_x,
          y: renderData.crop_y,
          resizeRatio: renderData.resize_ratio,
          resHeight: renderData.scene_height,
          resWidth: renderData.scene_width,
          width: renderData.image_width,
          height: renderData.image_height,
          screenHeight: renderData.screen_height,
          screenWidth: renderData.screen_width,
          noise: renderData.noise_level,
          passes: renderData.pass_limit,
          sceneHeight: renderData.scene_height,
          sceneWidth: renderData.scene_width,
          locked: renderData.locked,
          time: renderData.time_limit_minutes,
        }
      }

      return {
        ...state,
        savedRenderDetails: { ...savedRenderDetails },
        preferred_render_details: {
          ...state.preferred_render_details,
          ...action.data,
        },
      }
    }

    case SET_RENDER_FORM_DATA: {
      return {
        ...state,
        renderDetails: {
          ...state.renderDetails,
          ...action.data,
        },
      }
    }

    case SHOW_SCENE_EDIT_FORM:
      return {
        ...state,
        sceneEditFormOpen: action.data.show,
        copyScene: action.data.copyScene,
      }

    case SET_DEFAULT_NOISE_BASED_ON_RENDERER: {
      return {
        ...state,
        preferred_render_details: {
          ...state.preferred_render_details,
          [action.data.key]: {
            ...state.preferred_render_details[action.data.key],
            noise_level: action.data.value,
          },
        },
      }
    }

    case SET_SCENE_MODE:
      return { ...state, mode: action.data }

    case RESET_SCENE_DATA:
      return initialState

    case SET_NEW_CAMERA: {
      return {
        ...state,
        cameraKeys: [
          ...state.cameraKeys,
          {
            name: `Preferred View ${action.data}`,
            key: `preferred_camera_${action.data}`,
            selected: 'false',
          },
        ],
        sceneData: {
          ...state.sceneData,
          selected_camera: `preferred_camera_${action.data}`,
          preferred_cameras: {
            ...state.sceneData.preferred_cameras,
            [`preferred_camera_${action.data}`]: {},
          },
        },
        preferred_render_details: {
          ...state.preferred_render_details,
          [`preferred_camera_${action.data}`]: {},
        },
      }
    }
    case DELETE_CAMERA_VIEW: {
      let deleteCamera = action.data

      let preferred_cameras_copy = { ...state.sceneData.preferred_cameras }
      let preferred_render_details_copy = { ...state.preferred_render_details }

      delete preferred_cameras_copy[deleteCamera]
      delete preferred_render_details_copy[deleteCamera]
      return {
        ...state,
        cameraKeys: [
          ...state.cameraKeys.filter((item) => item.key !== deleteCamera),
        ],
        preferred_render_details: preferred_render_details_copy,
        sceneData: {
          ...state.sceneData,
          preferred_cameras: preferred_cameras_copy,
        },
      }
    }
    case SET_CAMERA_SNAPSHOT: {
      let imgUrl = action.data
      let updatedCamKeys = state.cameraKeys.map((cKey) => {
        if (cKey.key === state.sceneData.selected_camera) {
          return {
            name: cKey.name,
            key: cKey.key,
            selected: cKey.selected,
            imgUrl,
          }
        }
        return cKey
      })
      return { ...state, cameraKeys: updatedCamKeys }
    }
    case SAVE_SCENE_INITIATED: {
      return {
        ...state,
        savingScene: true,
      }
    }
    case SAVE_SCENE_COMPLETE: {
      return {
        ...state,
        savingScene: false,
      }
    }

    default:
      return state
  }
}
