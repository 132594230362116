import React from 'react'
import { makeStyles } from '@mui/styles'
import { PageTitleBar } from 'cgi-ui-components'
import { FilterAlt } from '@mui/icons-material'

import ClearAllFilterButton from './ClearAllButton'
import SummaryList from './SummaryList'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 240,
    marginRight: 35,
  },
  SummaryListContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'Calc(100% - 320px)',
  },
  homePageSummaryListContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'Calc(100% - 340px)',
  },
  filterAssetTitle: {
    fontSize: 16,
    color: '#333333',
    fontWeight: '500',
    marginLeft: '2px',
  },

  filterAssetSearchResult: {
    color: theme.palette.primary.mediumGrey,
    fontSize: 14,
    marginTop: 5,
    fontWeight: '400',
  },

  filterBadge: {
    marginLeft: 15,
  },
}))
export const FilterAssets = ({
  handleFilters,
  clearFilters,
  filterCount = 0,
  maximizedView = false,
  displayedInHomePage = false,
  totalAssetsCount = 0,
}) => {
  const classes = styles()

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.filterAssetTitle}>
          <PageTitleBar
            title="Filter Products"
            subTitle={`${totalAssetsCount} results`}
            icon={<FilterAlt />}
          />
        </div>

        {filterCount > 0 && (
          <ClearAllFilterButton clearFilters={clearFilters} />
        )}
      </div>
      <div
        className={
          displayedInHomePage
            ? classes.homePageSummaryListContainer
            : classes.SummaryListContainer
        }
      >
        <SummaryList
          handleFilters={handleFilters}
          maximizedView={maximizedView}
        />
      </div>
    </div>
  )
}

export default FilterAssets
