export const SCALE_CONFIG = {
  MAX_SCALE: 1.99,
  MIN_SCALE: 0.01,
  STEPS: 0.01,
}

export const ART_DIRECTOR = 'Art Director'
export const SBA = 'Scene Building Artist'
export const ALL = 'All'

export const POST_TO_RENDER_COMMENTS = [
  { name: ALL, value: ALL },
  { name: SBA, value: SBA },
  { name: ART_DIRECTOR, value: ART_DIRECTOR },
]

//For Asset Uploads
export const SUCCESS = 'Success'
export const FAILED = 'Failed'
export const INPROGRESS = 'InProgress'

export const SUCCESS_TEXT = 'Upload Completed'
export const FAILED_TEXT = 'Failed'
export const INPROGRESS_TEXT = 'Processing'

export const ARCHITECTURE = 'ARCHITECTURE'

export const FAST = 'fast'
export const STANDARD = 'standard'
export const HD = 'hd'
export const DEFAULT = 'default'

export const FAST_NOISE = 0.1
export const STANDARD_NOISE = 0.04
export const HD_NOISE = 0.02
export const DEFAULT_NOISE = 0.02
export const DEFAULT_NOISE_CORONA = 5

export const FAST_TIME = 5
export const STANDARD_TIME = 20
export const HD_TIME = 60
export const DEFAULT_TIME = 30

export const ACTIVE = 'active'
export const INACTIVE = 'inactive'
