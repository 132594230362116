import React from 'react'
import Dialog from '@mui/material/Dialog'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import { withStyles } from '@mui/styles'

import { Typography, CustomButton } from 'cgi-ui-components'

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '40px 16px 10px',
    fontSize: 14,
    width: 250,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    fontSize: 14,
    backgroundColor: theme.palette.primary.black,
  },
}))(MuiDialogActions)

const style = (theme) => ({
  root: {
    backgroundColor: 'rgba(243,243,243,0.8)',
  },
  paper: {},
  MuiButtonBase: {
    backgroundColor: 'inherit',
    width: '50%',
    color: '#FFF',
    padding: '20px 0',
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& i': {
      marginRight: '5px',
    },
  },
  cancelButtonCustomClass: {
    backgroundColor: '#FFFFFF',
    color: '#D0021B',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  helpinText: {
    fontSize: '12px',
    color: '#777',
  },
  primaryTcinText: {
    fontSize: '15px',
    color: '#777',
  },
})
const decodeHtml = (text) => ({ __html: text })

const ConfirmationPopUp = ({
  handleClose,
  open,
  handleConfirm,
  handleCancel,
  classes,
  descriptionText,
  confirmText = 'Delete',
  cancelText = 'Cancel',
  isSceneDelete = false,
  helpingText = '',
}) => (
  <Dialog
    onClose={handleClose}
    open={open}
    PaperProps={{
      square: true,
      elevation: 3,
      classes: { root: classes.paper },
    }}
    BackdropProps={{ classes: { root: classes.root } }}
  >
    <DialogContent>
      <Typography
        variant="subtitle2"
        label={<span dangerouslySetInnerHTML={decodeHtml(descriptionText)} />}
      />
      {isSceneDelete && (
        <Typography
          variant="body2"
          label={
            <span
              className={classes.helpinText}
              dangerouslySetInnerHTML={decodeHtml(helpingText)}
            />
          }
        ></Typography>
      )}
    </DialogContent>
    <DialogActions>
      <div className={classes.popoverButtons}>
        <CustomButton
          label={cancelText}
          onClick={handleCancel}
          startIcon={<CloseIcon />}
          variant="text"
          color={'tertiary'}
        />
        <CustomButton
          sx={{ marginLeft: '10px' }}
          label={confirmText}
          onClick={handleConfirm}
          startIcon={isSceneDelete ? <DeleteIcon /> : <DoneIcon />}
          variant="contained"
          color={'error'}
        />
      </div>
    </DialogActions>
  </Dialog>
)

export default withStyles(style)(ConfirmationPopUp)
