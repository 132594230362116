import React from 'react'
import { connect } from 'react-redux'

import CardHeader from '@mui/material/CardHeader'
import { withStyles } from '@mui/styles'

import { CircularProgress } from '@mui/material'

import CustomAvatar from '../avatar/index'
import { readableDate } from '../../helpers/dataFormats'

import { copyToClipboardNew } from '../../helpers/copyToClipboard'
import { getCurrentOS, OS } from '../../helpers/utils'

import { EXPORTSTS } from '../../constants/status'
import ShareExperienceDialog from '../shareExperienceDialog/ShareExperienceDialog'
import { flagRendersForReview } from '../../store/experienceImages/actionCreator'

import { CustomIconButton } from 'cgi-ui-components'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import OutlinedFlagTwoToneIcon from '@mui/icons-material/OutlinedFlagTwoTone'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import { styled } from '@mui/material/styles'

const styles = (theme) => ({
  previewHeader: {
    height: 60,
    padding: '0px 0px 5px 20px',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
  },
  userDetails: {
    display: 'inline-block',
    width: '30%',
    height: '100%',
    float: 'left',
  },
  iconsBar: {
    float: 'right',
    paddingRight: 15,
    textAlign: 'center',
  },
  customClasses: {
    height: 40,
    width: 40,
    backgroundColor: theme.palette.primary.targetRed,
    color: theme.palette.primary.contrastText,
    float: 'left',
    fontSize: 15,
    marginLeft: 'unset',
  },
  cardHeaderTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 160,
  },
  cardHeader: {
    padding: 10,
  },
  iconButtonRoot: {
    padding: '2px 2px',
    '&:hover': {
      backgroundColor: 'rgba(51, 108, 217, 0.08)',
      border: '1px solid rgba(51, 108, 217, 0.08)',
    },
    margin: '15px 10px',
    border: `1px solid ${theme.palette.primary.contentLightBorder}`,
  },
  flagIconButtonRoot: {
    padding: '2px 2px',
    '&:hover': {
      backgroundColor: theme.palette.primary.errorBgColor,
      border: `1px solid ${theme.palette.primary.errorBgColor}`,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.errorBgColor,
      border: theme.palette.primary.errorBgColor,
    },
    margin: '15px 10px',
    border: `1px solid ${theme.palette.primary.contentLightBorder}`,
  },
})

const StyledCircularProgress = styled((props) => (
  <CircularProgress size="20px" {...props} />
))(({}) => ({}))
class PreviewHeader extends React.Component {
  state = {
    flagged: false,
    anchorEl: null,
    copyTooltip: 'Copy path',
    openDialog: false,
    popoverPostion: {},
    isImgDownloading: false,
  }

  /**
   * Show the secondary buttons labels and actions that can be performed based on the exportStatus and API export status
   */
  getSecondaryButtonDetails() {
    const { exportStatus, imageDetails: item } = this.props
    const { copyTooltip } = this.state
    const renderId = item.render_job_id
    const apiExportStatus = item['export_status']
    const objToReturn = {
      show: '',
      label: '',
      action: () => {},
    }

    if (!exportStatus[renderId] && apiExportStatus === EXPORTSTS.COMPLETED) {
      //CASE 1: When internal state exportStatus is empty but API export status is Complete
      objToReturn.show = 'copypath'
      objToReturn.icon = LinkRoundedIcon
      objToReturn.label = copyTooltip
      objToReturn.action = this.copyFilePath
      return objToReturn
    } else if (!exportStatus[renderId]) {
      //CASE 2: When internal state exportStatus is empty which means render is not exported
      objToReturn.show = 'export'
      objToReturn.icon = ExitToAppOutlinedIcon
      objToReturn.action = this.exportRender
      objToReturn.label = 'Export Scene'
      return objToReturn
    } else if (exportStatus[renderId] === EXPORTSTS.INPROGRESS) {
      //CASE 3: When exportStatus is set as IN_PROGRESS which means exporting is in progress
      objToReturn.show = 'loader'
      objToReturn.icon = StyledCircularProgress
      objToReturn.label = 'Exporting scene...'
      return objToReturn // Nothing can be done on clicking exporting scene button
    } else if (exportStatus[renderId] === EXPORTSTS.COMPLETED) {
      // Case 4: When exporting is completed
      objToReturn.show = 'copypath'
      objToReturn.icon = LinkRoundedIcon
      objToReturn.label = 'Exporting Completed'
      return objToReturn // Nothing can be done on clicking Exporting Completed button
    } else if (exportStatus[renderId] === EXPORTSTS.COPY_READY) {
      // Case 5: When exporting is completed and ready for copy path
      objToReturn.show = 'copypath'
      objToReturn.label = copyTooltip
      objToReturn.icon = LinkRoundedIcon
      objToReturn.action = this.copyFilePath
      return objToReturn
    } else if (exportStatus[renderId] === EXPORTSTS.FAILED) {
      //CASE 5: If Export status is failed then we should give option to user to re-export
      objToReturn.show = 'export'
      objToReturn.action = this.exportRender
      objToReturn.label = 'Export Scene'
    }
    return objToReturn
  }

  copyFilePath = (item) => {
    const os = getCurrentOS()
    if (os === OS.WINDOWS) {
      copyToClipboardNew(item['scene_windows_path'])
    } else if (os === OS.MACOS) {
      copyToClipboardNew(item['scene_mac_path'])
    } else {
      copyToClipboardNew(item['scene_mac_path'])
    }
    this.setState({ copyTooltip: 'Link copied', anchorEl: null })
    setTimeout(() => {
      this.setState({ copyTooltip: 'Copy path' })
    }, 10000)
  }
  componentDidMount() {
    this.setState({
      flagged: this.props.imageDetails.flagged,
    })
  }

  deleteRender = (e) => {
    const {
      openConfirmDialog = () => {},
      imageDetails = {},
      closePreview = () => {},
    } = this.props
    openConfirmDialog(e, imageDetails, true)
  }
  flagForReview = () => {
    const { imageDetails: item } = this.props
    this.setState({ flagged: true }, () => {
      flagRendersForReview(
        item.experience_id,
        item.render_job_id,
        this.state.flagged
      )
    })
  }
  unflagShotUnderReview = () => {
    const { imageDetails: item } = this.props
    this.setState({ flagged: false }, () => {
      flagRendersForReview(
        item.experience_id,
        item.render_job_id,
        this.state.flagged
      )
    })
  }
  exportRender = (item) => {
    this.props.exportRender(item)
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleOpenSharingExperienceDialog = (e) => {
    this.setState({
      openDialog: true,
      popoverPostion: {
        left: e.currentTarget.getBoundingClientRect().right + 130,
        top: e.currentTarget.getBoundingClientRect().top,
      },
    })
  }
  handleCloseSharingExperienceDialog = () => {
    this.setState({ openDialog: false })
  }

  handleAnnotationToggle = () => {
    const { toggleAnnotation } = this.props
    toggleAnnotation()
  }

  startDownload = (item) => {
    this.setState({ isImgDownloading: true })
    this.props.startDownLoad(item, () =>
      this.setState({ isImgDownloading: false })
    )
  }
  render() {
    const {
      classes,
      imageDetails: item,
      closePreview = () => {},
      currentExportingJobId = '',
      userHaveWriteAccess = true,
      externalUser = false,
      experienceDetail = {},
      isAnnotationMode = false,
    } = this.props

    const cardWidth = 224

    const { anchorEl, copyTooltip, flagged, openDialog, popoverPostion } =
      this.state

    const open = Boolean(anchorEl)
    const secondaryButtonObj = this.getSecondaryButtonDetails()
    const displayNameInAvatar = item.initiator_details
      ? item.initiator_details.first_name +
        ' ' +
        item.initiator_details.last_name
      : 'N A'

    const {
      initiator_details: { loginId },
    } = item

    const isExportButtonDisabled =
      !userHaveWriteAccess ||
      (currentExportingJobId &&
        currentExportingJobId !== item.render_job_id &&
        item.export_status !== EXPORTSTS.COMPLETED) ||
      externalUser

    const isDeleteDisabled =
      (currentExportingJobId && currentExportingJobId === item.render_job_id) ||
      item.export_status === EXPORTSTS.INPROGRESS

    return (
      <div className={classes.previewHeader}>
        <div className={classes.userDetails}>
          <CardHeader
            className={classes.cardHeader}
            classes={{
              title: classes.cardHeaderTitle,
            }}
            avatar={
              <CustomAvatar
                customClasses={classes.customClasses}
                data={{
                  display_name: displayNameInAvatar,
                  login_id: loginId,
                }}
              />
            }
            title={
              item.initiator_details.first_name +
              ' ' +
              item.initiator_details.last_name
            }
            subheader={readableDate(item.render_start_time)}
          />
        </div>
        <div className={classes.iconsBar}>
          <CustomIconButton
            onClick={() => this.startDownload(item)}
            label={'Download Shot'}
          >
            {this.state.isImgDownloading ? (
              <StyledCircularProgress />
            ) : (
              <DownloadOutlinedIcon />
            )}
          </CustomIconButton>
          <CustomIconButton
            onClick={this.handleAnnotationToggle}
            label={
              isAnnotationMode
                ? 'Switch off Annotation Mode'
                : 'Switch to Annotate Mode'
            }
          >
            {isAnnotationMode ? (
              <EditOutlinedIcon color="primary" />
            ) : (
              <EditOutlinedIcon />
            )}
          </CustomIconButton>
          <CustomIconButton
            onClick={this.handleOpenSharingExperienceDialog}
            label={'Share Shot'}
          >
            <ShareOutlinedIcon />
          </CustomIconButton>
          <CustomIconButton onClick={closePreview} label={'Close comment'}>
            <CommentOutlinedIcon color="primary" />
          </CustomIconButton>

          <CustomIconButton
            onClick={!flagged ? this.flagForReview : this.unflagShotUnderReview}
            label={!flagged ? 'Flag for Review' : 'Unflag Shot Under Review'}
          >
            {!flagged ? (
              <OutlinedFlagTwoToneIcon />
            ) : (
              <FlagRoundedIcon color="primary" />
            )}
          </CustomIconButton>
          {!isDeleteDisabled && (
            <CustomIconButton onClick={this.deleteRender} label={'Delete shot'}>
              <DeleteOutlineOutlinedIcon />
            </CustomIconButton>
          )}
          {!isExportButtonDisabled && (
            <CustomIconButton
              onClick={() => secondaryButtonObj.action(item)}
              label={secondaryButtonObj.label}
            >
              <secondaryButtonObj.icon />
            </CustomIconButton>
          )}

          {openDialog ? (
            <ShareExperienceDialog
              openDialog={openDialog}
              handleClose={this.handleCloseSharingExperienceDialog}
              experienceItem={experienceDetail}
              popoverPostion={popoverPostion} // Only for Popover
              isDialog={false} // Here open as popover, so isDialog will be false
              widthOfPivotElement={cardWidth} // optional, only for card and use it as Popover
            />
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  templates: state.templates,
  experienceDetail: state.experiences.experience,
})
export default connect(mapStateToProps, {})(withStyles(styles)(PreviewHeader))
