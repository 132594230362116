import React from 'react'
import { Grid, Button } from '@mui/material'

import { withStyles } from '@mui/styles'
import { CSVLink } from 'react-csv'
import { isEmpty } from 'lodash'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import {
  BulkTcinInput,
  Select,
  CustomButton,
  PageTitleBar,
  CustomIconButton,
  Alert,
} from 'cgi-ui-components'

import {
  validateTcins,
  validatePids,
  addTcinToBoard,
  removeTcinsFromBoard,
  openRightDrawer,
} from '../../store/board/actionCreator'
import { addExperienceRedux } from '../../store/experience/ActionCreator'
import { toggleTemplateModal } from '../../store/template/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import PreviewBoard from './previewBoard'
import { pluralise } from '../../helpers/utils'

const styles = (theme) => ({
  validateWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1',
  },

  dialogContent: {
    paddingTop: '10px !important',
  },
  textField: {
    margin: theme.spacing.unit,
    width: '62em',
  },
  root: {
    margin: 0,
  },
  dialogAct: {
    padding: '20px 30px !important',
    border: '1px solid #f2f2f2',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  // css for Dialog
  dialogPaper: {
    width: '66vw',
    position: 'absolute',
    height: 1000,
  },

  closeButtonTemplateStyle: {
    marginRight: 15,
  },
  footerButtons: {
    textAlign: 'right',
  },
  removeButton: {
    backgroundColor: '#CC1C00',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#CC1C00',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 20,
  },
})

class AddTcinDialog extends React.Component {
  state = {
    isVerified: false,
    validating: false,
    tcinString: '',
    tcinCount: 0,
    activeTcinCount: 0,
    error: null,
    activeTcinList: [],
    inprogressTcinList: [],
    verifyTcinObject: {},
    downloadableReport: { headers: [], data: [] },
    removing: false,
    productType: 'marketing',
    tcinEntry: [],
    pidEntry: [],
    invalidEntry: [],
    validPid: [],
    activeProducts: [],
    activePid: [],
    invalidPid: [],
    thubmnailData: {},
    galleryTcinEntry: [],
    inputIds: [],
  }

  handleChangeAddTcinArea = (event, formattedtext) => {
    const { productType } = this.state
    const inputValue = formattedtext
    const ids =
      inputValue != '' ? inputValue.replace(/,\s*$/, '').split(',') : []

    if (ids.length) {
      const { tcins, pids, otherIds } = this.segregateInputIds(ids)
      const isMarketing = productType === 'marketing'

      const stateObject = {
        tcinEntry: isMarketing ? [...tcins, ...pids] : [],
        pidEntry: isMarketing ? [] : pids,
        invalidEntry: isMarketing ? otherIds : [],
        galleryTcinEntry: isMarketing ? [] : [...tcins, ...otherIds],
        tcinCount: ids.length,
        isVerified: false,
        validating: false,
        activeTcinCount: 0,
        error: null,
        activeTcinList: [],
        activePid: [],
        inprogressTcinList: [],
        verifyTcinObject: {},
        downloadableReport: { headers: [], data: [] },
        tcinString: inputValue,
        inputIds: ids,
      }
      this.setState(stateObject)
    } else {
      const stateObject = {
        tcinEntry: [],
        pidEntry: [],
        invalidEntry: [],
        galleryTcinEntry: [],
        tcinCount: 0,
        isVerified: false,
        validating: false,
        activeTcinCount: 0,
        error: null,
        activeTcinList: [],
        activePid: [],
        inprogressTcinList: [],
        verifyTcinObject: {},
        downloadableReport: { headers: [], data: [] },
        tcinString: inputValue,
        inputIds: [],
      }
      this.setState(stateObject)
    }
  }

  render() {
    return <div>{this.renderingDialog()}</div>
  }

  changeProductType = (e) => {
    const { inputIds } = this.state

    const { tcins, pids, otherIds } = this.segregateInputIds(inputIds)

    const isMarketng = e.target.value === 'marketing'
    this.setState({
      productType: e.target.value,
      tcinEntry: isMarketng ? [...tcins, ...pids] : [],
      pidEntry: isMarketng ? [] : pids,
      galleryTcinEntry: isMarketng ? [] : [...tcins, ...otherIds],
      invalidEntry: isMarketng ? otherIds : [],
    })
  }

  segregateInputIds = (input = []) => {
    const tcins = []
    const pids = []
    const otherIds = []
    input.forEach((item) => {
      if (/^[0-9]{8}$/g.test(parseInt(item))) {
        tcins.push(item.trim())
      } else if (item.toUpperCase().includes('PID')) {
        pids.push(item.trim())
      } else {
        otherIds.push(item.trim())
      }
    })
    return {
      tcins,
      pids,
      otherIds,
    }
  }

  resetState = () => {
    this.setState({
      validating: false,
      tcinString: '',
      tcinCount: 0,
      isVerified: false,
      activeTcinCount: 0,
      error: null,
      activeTcinList: [],
      inprogressTcinList: [],
      verifyTcinObject: {},
      activeProducts: [],
      downloadableReport: { headers: [], data: [] },
    })
  }
  skipAddTcin = () => {
    const {
      handleClose,
      handleStartStyling = () => {},
      openRightDrawer = () => {},
    } = this.props

    // Skip & Start Styling
    handleStartStyling()
    this.resetState()
    handleClose()
    openRightDrawer()
  }
  validateProducts = () => {
    const { showNotification } = this.props
    const {
      tcinEntry,
      pidEntry,
      tcinCount,
      invalidEntry,
      galleryTcinEntry = [],
    } = this.state
    if (tcinCount > 0 && tcinCount <= 500) {
      //verify tcins
      this.setState({
        validating: true,
      })
      const promises = []
      if (pidEntry.length || galleryTcinEntry.length) {
        const pidPromise = validatePids({ pidEntry, galleryTcinEntry }).then(
          ({ valid, invalid }) => {
            this.setState({
              validPid: [
                ...valid.map((item) => {
                  return { PID: item }
                }),
              ],
            })
            return {
              valid,
              invalid,
              type: 'PID',
            }
          }
        )
        promises.push(pidPromise)
      }

      if (tcinEntry.length || invalidEntry.length) {
        const tcinnPromise = validateTcins([
          ...tcinEntry,
          ...invalidEntry,
        ]).then((data) => {
          return { ...data, type: 'TCIN' }
        })
        promises.push(tcinnPromise)
      }

      Promise.all(promises)
        .then((responses) => {
          const { invalidEntry } = this.state
          let activeCount = 0
          let activeList = []
          let inprogressList = []
          let activePids = []
          let tcinResponse
          let invalidProducts = []
          let invalidPid = []
          responses.forEach((response) => {
            if (response.type == 'TCIN') {
              tcinResponse = response
              for (let property in response) {
                if (property === 'active') {
                  activeCount += response[property].length
                  activeList = response[property]
                } else if (property === 'in_progress') {
                  inprogressList = response[property]
                } else if (property !== 'type') {
                  invalidProducts = [
                    ...invalidProducts,
                    ...response[property].map((item) => item.tcin),
                  ]
                }
              }
            }
            if (response.type == 'PID') {
              activePids = response.valid
              invalidProducts = [...invalidProducts, ...response.invalid]
              invalidPid = response.invalid
            }
          })

          this.setState({
            invalidEntry: [...invalidEntry, ...invalidProducts],
            activeProducts: [...activeList, ...activePids, ...inprogressList],
            verifyTcinObject: tcinResponse,
            isVerified: true,
            activeTcinCount: activeCount,
            activeTcinList: activeList,
            inprogressTcinList: inprogressList,
            activePid: activePids,
            validating: false,
            invalidPid,
          })
        })
        .catch((error) => {
          const ERROR_MSG = this.RequestMessage(
            tcinCount,
            'TCIN_VALIDATION_ERROR_MSG'
          )
          showNotification(true, ERROR_MSG, 'error')
          this.setState({
            isVerified: false,
            validating: false,
            error,
            activeTcinCount: 0,
            activeTcinList: [],
            inprogressTcinList: [],
            verifyTcinObject: {},
            activeProducts: [],
          })
        })
    }
  }

  handleAddToBoard = () => {
    const {
      addTcinToBoard,
      handleClose,
      isCreateMode,
      openRightDrawer = () => {},
      experience,
      addExperienceRedux,
    } = this.props
    const { activeTcinList, inprogressTcinList, activePid } = this.state

    this.setState({
      validating: true,
    })
    let assetsList = []
    let thubmnailData = this.state.thubmnailData
    activeTcinList.forEach((element) => {
      let tempObj = {
        asset_type: 'TCIN',
        value: element.tcin,
      }
      assetsList.push(tempObj)
    })
    inprogressTcinList.forEach((element) => {
      let tempObj = {
        asset_type: 'TCIN',
        value: element.tcin,
      }
      assetsList.push(tempObj)
    })
    activePid.forEach((element) => {
      let tempObj = {
        asset_type: 'PID',
        value: element.value,
        thumbnail_url: element.thumbnail_url,
      }
      thubmnailData[element.name] = element.thumbnail_url
      assetsList.push(tempObj)
    })

    let payload = {
      experience_id: experience.experience_id,
      assets: assetsList,
    }
    // API hit add to board
    this.setState({
      thubmnailData,
    })
    addTcinToBoard(
      payload,
      (data) => {
        if (isCreateMode) {
          this.setState({
            validating: false,
          })
          addExperienceRedux({ ...experience, board_id: data.board_id })
          this.resetState()
        } else {
          this.resetState()
          handleClose()
          openRightDrawer()
        }
      },
      (error) => {
        this.setState({
          validating: false,
        })
      }
    )
  }

  openTemplate = () => {
    const { toggleTemplateModal, handleClose } = this.props
    handleClose()
    toggleTemplateModal(true)
  }

  removeAssets = (tcins, resetTcins = false) => {
    const { boardId, removeTcinsFromBoard, showNotification } = this.props
    const deleteData = {
      board_id: boardId,
      assets: tcins,
    }
    this.setState({ removing: true })

    removeTcinsFromBoard(
      deleteData,
      () => {},
      () => {}
    )
      .then((data) => {
        this.setState({ removing: false })
        if (resetTcins) {
          // this.setState({ selectedItems: [] })
        }
        showNotification(
          true,
          "Tcin's successfully removed from board",
          'success'
        )
      })
      .catch(() => {
        this.setState({ removing: false })
        showNotification(
          true,
          "Sorry! we couldn't remove tcins from board. Try again!",
          'error'
        )
      })
  }

  getValidateButtonLabel() {
    const { tcinCount, validating, activeProducts, productType } = this.state
    const isMarketing = productType === 'marketing'

    if (tcinCount === 0 && activeProducts.length === 0) {
      return 'Validate'
    }
    if ((!isMarketing && tcinCount > 100) || tcinCount > 500) {
      return !isMarketing
        ? 'Max 100 products allowed'
        : 'Max 500 products allowed'
    }
    if (
      tcinCount > 0 &&
      ((isMarketing && tcinCount <= 500) || (!isMarketing && tcinCount <= 100))
    ) {
      if (validating) {
        return 'Validating products '
      } else {
        return `Validate ${pluralise('product', tcinCount)}`
      }
    }
  }
  getCreateLabel() {
    const { assets } = this.props

    if (assets.length) {
      return 'Style scene'
    }
    return ' Skip & style'
  }

  removeAssetsButton = () => {
    const { classes, assets } = this.props
    const { removing } = this.state
    return (
      <>
        <CustomButton
          startIcon={<DeleteForeverRoundedIcon />}
          color="error"
          onClick={() => {
            this.removeAssets(
              assets.map((item) => item.value),
              true
            )
          }}
          disableElevation
          variant="text"
          className={classes.removeButton}
          disabled={removing}
          label={removing ? 'Removing...' : 'CLEAR BOARD'}
        ></CustomButton>
      </>
    )
  }

  getDownloadReportButton = () => {
    const { assets } = this.props
    const { isVerified, downloadableReport } = this.state

    return (
      <Grid item xs={6}>
        {isVerified && (
          <CSVLink
            filename={'Product-Report.csv'}
            headers={downloadableReport.headers || []}
            data={downloadableReport.data || []}
            style={{ textDecoration: 'none' }}
          >
            <CustomButton
              color="warning"
              onClick={this.handleDownloadReport}
              startIcon={<ReportProblemIcon />}
              label={'Download Report'}
              variant="text"
              disableElevation
            ></CustomButton>
          </CSVLink>
        )}

        {assets.length > 0 && this.removeAssetsButton()}
      </Grid>
    )
  }
  loadUI() {
    const { classes, assets: boardAssets } = this.props
    const {
      tcinString,
      tcinCount = 0,
      validating,
      isVerified,
      productType,
      invalidEntry,
      activeProducts,
    } = this.state
    const isMarketing = productType === 'marketing'
    const errorMsg =
      activeProducts.length > 0
        ? `Only ${activeProducts.length} products ${
            isMarketing ? `out of ${tcinCount}` : ``
          }  will be added into the board. Download report below to know more!`
        : `There are no active products found to be added into the board. Download report below to know more !`
    let options = [
      {
        name: 'Marketing',
        value: 'marketing',
      },
      {
        name: 'Non-Marketing',
        value: 'nonMarketing',
      },
    ]
    return (
      <div>
        <div className={classes.rowContainer}>
          <div style={{ flex: 1 }}>
            <Select
              inputLabel="Type"
              value={productType}
              onChange={this.changeProductType}
              sx={{ marginBottom: 2, width: 200 }}
              size="small"
              variant="outlined"
              disabled={this.state.isVerified}
              options={options}
            />
          </div>
          <div>
            {tcinCount > 0 && isVerified && invalidEntry.length > 0 && (
              <Alert severity="warning" variant="filled">
                {errorMsg}
              </Alert>
            )}
          </div>
        </div>
        <h2></h2>
        <BulkTcinInput
          rows={4}
          value={tcinString
            .split(',')
            .map((str) => str.trim())
            .join()}
          onChange={this.handleChangeAddTcinArea}
          className={classes.textField}
          errorValues={invalidEntry}
        />
        <div className={classes.validateWrapper}>
          {!isVerified || activeProducts.length == 0 ? (
            <CustomButton
              color="tertiary"
              disabled={
                !isMarketing
                  ? tcinCount > 100
                  : tcinCount > 500 || tcinCount == 0 || validating
              }
              variant="outlined"
              onClick={this.validateProducts}
              label={this.getValidateButtonLabel()}
              disableElevation
            />
          ) : (
            <CustomButton
              color="tertiary"
              variant="outlined"
              onClick={this.handleAddToBoard}
              disabled={validating}
              label={validating ? `Adding to board` : `Add to board`}
              disableElevation
            />
          )}
        </div>
        <PreviewBoard
          removeAsset={this.removeAssets}
          thubmnailData={this.state.thubmnailData}
        />
      </div>
    )
  }

  renderingDialog() {
    const { classes, navigate, open, handleClose, isCreateMode } = this.props

    return (
      <Grid>
        <Dialog
          onClose={(ev, reason) => {
            if (reason === 'escapeKeyDown') {
              isCreateMode ? navigate('/home') : handleClose()
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={open}
          classes={{
            paper: classes.dialogPaper,
          }}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <div
            style={{
              padding: '20px 24px 0 24px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1 }}>
              <PageTitleBar title="Add Products" subTitle="TCIN, PID" />
            </div>
            <div
              style={{
                justifyContent: 'end',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <LinkComponent
                color="#3F51B5"
                name="See how it works"
                onMenuClick={() => {}}
                underline="hover"
                variant="body2"
              /> */}
              <CustomIconButton
                className={classes.closeButton}
                onClick={() => {
                  isCreateMode ? navigate('/home') : handleClose()
                }}
                label="Close"
              >
                <CloseIcon />
              </CustomIconButton>
            </div>
          </div>

          <DialogContent className={classes.dialogContent}>
            {this.loadUI()}
          </DialogContent>
          <DialogActions
            classes={{
              root: classes.dialogAct,
            }}
          >
            {isCreateMode
              ? this.renderCreateModeButtons()
              : this.renderViewModeButtons()}
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }

  renderCreateModeButtons = () => {
    const {
      classes,
      experience: { template_id: templateId },
    } = this.props

    return (
      <Grid container>
        {this.getDownloadReportButton()}

        <Grid item xs={6} className={classes.footerButtons}>
          <div style={{ marginRight: 15, display: 'inline' }}>
            <CustomButton
              color="primary"
              variant="link"
              onClick={this.openTemplate}
              className={classes.closeButtonTemplateStyle}
              startIcon={<ChevronLeftIcon />}
              label={isEmpty(templateId) ? ' Choose Layout' : ' Change Layout'}
              disableElevation
            ></CustomButton>
          </div>
          <CustomButton
            color="primary"
            label={this.getCreateLabel()}
            onClick={this.skipAddTcin}
            variant="contained"
            endIcon={<ChevronRightIcon />}
            disableElevation
          />
        </Grid>
      </Grid>
    )
  }

  renderViewModeButtons = () => {
    const { classes } = this.props
    const { isVerified, inprogressTcinList, validating, activeTcinCount } =
      this.state

    let validTcins = activeTcinCount + inprogressTcinList.length

    return (
      <Grid container>
        {this.getDownloadReportButton()}
        <Grid item xs={6} className={classes.footerButtons}>
          {!isVerified ? (
            <></>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleAddToBoard}
              disabled={validating || validTcins === 0}
            >
              {validating ? (
                <span style={{ color: 'white' }}>
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    size="1x"
                    color="white"
                  />
                  Adding {validTcins} to board
                </span>
              ) : (
                <span>
                  <i className="fab fa-trello" /> Add {validTcins} to board
                </span>
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    )
  }

  handleDownloadReport = () => {
    const { assets, isCreateMode } = this.props
    const { verifyTcinObject = {}, invalidPid, activePid } = this.state
    let data = []
    let headers

    if (isCreateMode && assets.length) {
      headers = [
        { label: 'Asset Type', key: 'asset_type' },
        { label: 'Asset Details ', key: 'value' },
        { label: 'Item Status', key: 'item_status' },
        { label: 'Production Status', key: 'available_hp' },
        { label: 'Production Status', key: 'available_lp' },
      ]
      assets.forEach((element) => {
        data.push({
          ...element,
          available_hp: element.is_hp_available
            ? 'HP Available'
            : 'HP Unavailable',
          available_lp: element.is_lp_available
            ? 'LP Available'
            : 'LP Unavailable',
        })
      })
    } else {
      for (let property in verifyTcinObject) {
        verifyTcinObject[property] !== null &&
          verifyTcinObject[property] !== 'TCIN' &&
          Array.isArray(verifyTcinObject[property]) &&
          verifyTcinObject[property].forEach((element) => {
            data.push({
              ...element,
              status:
                property === 'active'
                  ? 'Active'
                  : property === 'in_progress'
                  ? 'In Progress'
                  : property === 'in_active'
                  ? 'Inactive'
                  : property === 'high_poly_not_available'
                  ? ''
                  : 'Invalid',
              eligible_for_board_add:
                property === 'active' || property === 'in_progress'
                  ? 'Yes'
                  : 'No',
              failure_reason_add_board:
                property === 'high_poly_not_available'
                  ? 'High Poly Version Unavailable'
                  : 'NA',
            })
          })
      }
      activePid.forEach((item) => {
        data.push({
          tcin: item.name,
          product_short_description: 'Gallery Asset',
          eligible_for_board_add: 'Yes',
          status: 'Active',
        })
      })
      invalidPid.forEach((item) => {
        data.push({
          tcin: item,
          product_short_description: ' ',
          eligible_for_board_add: 'NO',
          status: 'Invalid',
        })
      })

      headers = [
        { label: 'TCIN #', key: 'tcin' },
        { label: 'DPCI #', key: 'dpci' },
        {
          label: 'PRODUCT SHORT DESCRIPTION',
          key: 'product_short_description',
        },
        { label: 'Status (Item API)', key: 'status' },
        {
          label: 'Eligible for board Addition',
          key: 'eligible_for_board_add',
        },
        {
          label: 'Failure Reason (Add to board)',
          key: 'failure_reason_add_board',
        },
      ]
    }

    const downloadableReport = { headers, data }

    this.setState({ downloadableReport })

    return downloadableReport // For testing purpose
  }

  RequestMessage = (numberOfTCIN, successOrError) => {
    var msg = ''
    var TCIN = numberOfTCIN > 1 ? 'TCINs' : 'TCIN'
    switch (successOrError) {
      case 'ADD_TCIN_SUCCESS_MESSAGE':
        msg = `Success! ${TCIN} are now added to the project & assigned to the vendors`
        break

      case 'ADD_TCIN_ERROR_MSG':
        msg = `We failed to add ${TCIN} :( Try again or please contact us using support menu.`
        break

      case 'TCIN_VALIDATION_ERROR_MSG':
        msg = `We failed to verify ${TCIN} :( Try again or please contact us using support menu.`
        break
      default:
        break
    }
    return msg
  }
}

const mapStateToProps = ({
  Board: { board_id: boardId, assets },
  experiences: { experience },
}) => ({
  boardId,
  experience,
  assets,
})

export default connect(mapStateToProps, {
  showNotification,
  toggleTemplateModal,
  addTcinToBoard,
  removeTcinsFromBoard,
  openRightDrawer,
  addExperienceRedux,
})(withStyles(styles)(AddTcinDialog))
