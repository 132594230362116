import { useLocation, useNavigate, useParams, useMatch } from 'react-router-dom'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    let match = { params: useParams() }
    let history = {
      push: (path) => {
        navigate(path)
      },
      goBack: () => navigate(-1),
    }
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        history={history}
        params={params}
        match={match}
      />
    )
  }

  return ComponentWithRouterProp
}

export default withRouter
