const SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS'
const NUID_SIGN_IN_SUCCESS = 'auth/NUID_SIGN_IN_SUCCESS'

export const signInSuccess = (payload) => ({ type: SIGN_IN_SUCCESS, payload })
export const NUIDSignInSuccess = (payload) => ({
  type: NUID_SIGN_IN_SUCCESS,
  payload,
})

export const formatUserInfo = (info = {}) => ({
  email: info.email,
  firstName: info.firstName,
  lanId: info.lanId,
  fullName: info.fullName,
  lastName: info.lastName,
  memberOf: info.memberOf,
  accessToken: info.accessToken,
  xIdToken: info.xIdToken,
  nuidTokens: {
    xIdToken: '',
    accessToken: '',
  },
})
//

export const initialState = {
  ...formatUserInfo(),
}
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        ...formatUserInfo(payload),
      }
    case NUID_SIGN_IN_SUCCESS:
      return {
        ...state,
        nuidTokens: {
          xIdToken: payload.id_token,
          accessToken: payload.access_token,
        },
      }
    default:
      return state
  }
}

export default reducer
