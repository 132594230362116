import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Typography } from '@mui/material'
import browserError from '../../images/Maintenance-amico.svg?url'
import { BROWSER_INCOMPATIBILITY_MSG } from '../../constants/displayMessages'

const styles = (theme) => ({
  imageContainer: {
    width: '55vh',
    display: 'block',
    margin: 'auto',
    paddingTop: 100,
  },
  textContainer: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  textSize: {
    fontSize: '1.25rem',
  },
})

class BrowserIncompatible extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div>
        <img
          src={browserError}
          alt="browserIncompatible"
          className={classes.imageContainer}
        />
        <div className={classes.textContainer}>
          <Typography variant="h5" classes={{ h5: classes.textSize }}>
            {BROWSER_INCOMPATIBILITY_MSG}
          </Typography>
        </div>
      </div>
    )
  }
}

BrowserIncompatible.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(BrowserIncompatible)
