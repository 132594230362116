import { SHOW_BANNER } from './actionType'

export function showBanner(isShown, message, variant) {
  return {
    type: SHOW_BANNER,
    payload: {
      isShown,
      message,
    },
  }
}
