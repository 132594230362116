import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = (theme) => ({
  notificationWrapper: {
    height: 94,
    display: 'flex',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.22)',
    marginTop: 20,
  },
  greenBar: {
    height: '100%',
    width: '2%',
    backgroundColor: theme.palette.primary.greenDark,
  },
  notificationDescription: {
    backgroundColor: theme.palette.primary.successBgColor,
    display: 'flex',
    height: '100%',
    width: '98%',
    alignItems: 'center',
  },
  notificationText: {
    width: '50%',
    color: theme.palette.primary.darkFont,
    fontFamily: 'Roboto',
    fontSize: 16,
    marginRight: 'auto',
    marginLeft: 25,
  },
  checkIcon: {
    fontSize: 30,
    color: 'green',
    marginLeft: 20,
  },
})

class EditUserNotification extends React.Component {
  render() {
    const { classes, show } = this.props
    return (
      <>
        {show && (
          <div className={classes.notificationWrapper}>
            <div className={classes.greenBar} />
            <div className={classes.notificationDescription}>
              <FontAwesomeIcon
                className={classes.checkIcon}
                icon={faCheckCircle}
              />
              <span className={classes.notificationText}>
                Posted. Tagged user now have view access. Check sharing
                preferences!
              </span>
            </div>
          </div>
        )}
      </>
    )
  }
}

EditUserNotification.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditUserNotification)
