import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { Paper } from '@mui/material'
import { PageTitleBar, CustomIconButton, AssetCard } from 'cgi-ui-components'

import LinkIcon from '@mui/icons-material/Link'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import config from '../../config/config'
import {
  fetchSimilarAssets,
  clearSimilarAssets,
} from '../../store/explorer/actionCreator'
import MaxAssetCard from '../assetExplorerCard/MaxAssetCard'
import DataList from '../DataList'
import { mapInfoForCard } from '../../helpers/utils'

const styles = (theme) => ({
  card: {
    margin: '0px 10px',
    marginBottom: '20px',
  },
  paper: {
    padding: '20px 10px',
  },
  header: { display: 'flex', flexDirection: 'row' },
  closeButton: { textAlign: 'right', flex: 1 },
  titleBar: {
    flex: 4,
    overflow: 'hidden',
    textOverflow: 'elipsis',
    whiteSpace: 'nowrap',
  },
  noResultsFound: {
    padding: 20,
    textAlign: 'center',

    width: '100%',
  },
  dataListContainer: { height: 'Calc(100vh - 250px) !important' },
})

class SimilarAssets extends React.Component {
  state = {
    loading: false,
  }
  componentDidMount() {
    this.fetchAssets()
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.tcin !== prevProps.data.tcin) {
      this.props.clearSimilarAssets()
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.fetchAssets()
    }
  }
  componentWillUnmount() {
    this.props.clearSimilarAssets()
  }
  fetchAssets() {
    const {
      fetchSimilarAssets,
      data: { tcin, asset_type },
      data,
    } = this.props

    if (tcin && tcin !== '') {
      this.setState({ loading: true })
      fetchSimilarAssets(100, 1, '', 'ALL', {}, false, {
        ...data,
        tcin: tcin,
        asset_type,
      })
        .then(() => {
          this.setState({ loading: false })
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    } else {
      this.props.clearSimilarAssets()
    }
  }

  close = () => {
    const { onClose, clearSimilarAssets } = this.props
    clearSimilarAssets()
    onClose()
  }
  interSectionCallback = () => {}
  render() {
    const {
      classes,
      data: { tcin },
      totalSimilarAssets,
      similarAssets,
      size,
      isSceneEditor = false,
      handleCartActions,
    } = this.props
    const { loading } = this.state
    return (
      <Paper className={classes.paper} elevation={2}>
        <div className={classes.header}>
          <div className={classes.titleBar}>
            <PageTitleBar
              title={`Browse similar assets matching "${tcin}"`}
              subTitle={`${totalSimilarAssets} results`}
            />
          </div>
          <div className={classes.closeButton}>
            <CustomIconButton onClick={this.close} label={'close'}>
              <CloseRoundedIcon />
            </CustomIconButton>
          </div>
        </div>
        <DataList
          showProgressBar={loading}
          interSectionCallback={this.interSectionCallback}
          containerClass={classes.dataListContainer}
        >
          <>
            {similarAssets &&
              similarAssets.map((item) => {
                const infoDetails = {
                  icon: InfoOutlinedIcon,
                  title: 'info',
                  data: mapInfoForCard(item),
                }
                const footerItems = !item.is_pure_rta_asset
                  ? [
                      {
                        icon: LinkIcon,
                        onClick: () => this.props.copyPath(item),
                        title: 'Copy path',
                      },
                    ]
                  : []

                return (
                  <div className={classes.card}>
                    {isSceneEditor ? (
                      <MaxAssetCard
                        data={item}
                        selectable
                        onSelect={(e) => {
                          this.props.selectAsset(
                            item.tcin || item.value || item.pid,
                            item,
                            e.target.checked
                          )
                        }}
                        selected={
                          this.props.selectedItems[
                            item.tcin || item.value || item.pid
                          ] !== undefined
                        }
                        handleCartActions={handleCartActions}
                      />
                    ) : (
                      <AssetCard
                        size={size}
                        thumbnail={item.thumbnail_url}
                        config={config}
                        isUrlAppendedWithImg
                        assetData={item}
                        name={
                          item.short_description ||
                          item.tcin ||
                          item.value ||
                          item.pid
                        }
                        maxWidth={230}
                        minWidth={230}
                        infoDetails={infoDetails}
                        noThumbnail={noThumbnail}
                        selectable
                        checkboxColor={'tertiary'}
                        footerItems={footerItems}
                        errorDetails={{
                          high: item.is_hp_available || true,
                          low: item.is_lp_available || true,
                        }}
                        subTitle={item.product_desc || item.tcin || ``}
                        selected={
                          this.props.selectedItems[
                            item.tcin || item.value || item.pid
                          ] !== undefined
                        }
                        onSelect={(e) => {
                          this.props.selectAsset(
                            item.tcin || item.value || item.pid,
                            item,
                            e.target.checked
                          )
                        }}
                        isRtaAsset={item.is_pure_rta_asset}
                        isHighPolyCountAsset={item.is_high_triangle_count}
                      />
                    )}
                  </div>
                )
              })}

            {!loading && !similarAssets.length ? (
              <div className={classes.noResultsFound}>No Results found</div>
            ) : null}
          </>
        </DataList>
      </Paper>
    )
  }
}

const mapStateToProps = ({
  explorer: { similarAssets, totalSimilarAssets },
}) => {
  return {
    similarAssets,
    totalSimilarAssets,
  }
}
export default connect(mapStateToProps, {
  fetchSimilarAssets,
  clearSimilarAssets,
})(withStyles(styles)(SimilarAssets))
