import React, { Component } from 'react'
import { connect } from 'react-redux'
import Annotation from 'react-image-annotation'
import { PointSelector } from 'react-image-annotation/lib/selectors'
import NewComment from '../ImagePreview/newComment'
import { mentionsParser } from '../../helpers/utils'
import { highlightComment } from '../../store/comments/ActionCreator'

class Multiple extends Component {
  state = {
    type: PointSelector.TYPE,
    annotations: [],
    annotation: {},
    activeAnnotations: [],
  }
  selectedComment = ''
  mouseOutCount = 0
  componentDidMount = () => {
    this.setState({ annotations: this.props.annotations })
  }

  onChange = (annotation) => {
    this.setState({ annotation })
  }

  onSubmit = (annotation) => {
    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat(annotation),
    })
  }

  renderEditor = (props) => {
    const { geometry } = props.annotation
    const { imageDetails } = this.props

    if (!geometry) return null

    return (
      <>
        <div
          style={{
            position: 'absolute',
            width: 50,
            height: 2,
            backgroundColor: '#FDE30C',
            top: `calc(${geometry.y + geometry.height}%)`,
            left: `calc(${geometry.x}% + 13px)`,
          }}
        />
        <div
          style={{
            background: 'white',
            borderRadius: 3,
            position: 'absolute',
            left: `calc(${geometry.x}% + 13px)`,
            top: `calc(${geometry.y + geometry.height}%)`,
            transform: `${
              geometry.y > 50
                ? 'translateY(calc(-100% + 19px))'
                : 'translateY(-19px)'
            }`,
            width: 280,
            margin: '0 0 0 40px',
          }}
        >
          <NewComment
            imageDetails={imageDetails}
            onSubmit={this.onSubmit}
            onChange={props.onChange}
            isAnnotationComment={true}
            annotation={props.annotation}
            count={this.state.annotations.length + 1}
          />
        </div>
      </>
    )
  }

  renderSelector = ({ annotation }) => {
    switch (annotation.geometry.type) {
      case PointSelector.TYPE:
        return (
          <div
            style={{
              ...annotation.style,
              border: `solid 3px #FDE30C`,
              borderRadius: '50%',
              position: 'absolute',
              boxSizing: 'border-box',
              left: `${annotation.geometry.x}%`,
              top: `${annotation.geometry.y}%`,
              height: 26,
              width: 26,
              textAlign: 'center',
              backgroundColor: 'white',
              transform: `translateY(-50%) translateX(-50%)`,
              lineHeight: '20px',
            }}
          >
            {this.state.annotations.length + 1}
          </div>
        )
      default:
        return null
    }
  }

  renderHighlight = ({ annotation, active }) => {
    switch (annotation.geometry.type) {
      case PointSelector.TYPE:
        if (active) {
          this.mouseOutCount = 0
          if (
            this.selectedComment !== annotation.commentId &&
            !this.props.activeAnnotations.includes(annotation.data.id)
          ) {
            this.selectedComment = annotation.commentId
            this.props.highlightComment(annotation.commentId)
          }
          return (
            <div
              style={{
                ...annotation.style,
                border: `solid 3px #FFF`,
                borderRadius: '50%',
                position: 'absolute',
                boxSizing: 'border-box',
                left: `${annotation.geometry.x}%`, // on enter hoverstater mouseout if less than 2
                top: `${annotation.geometry.y}%`,
                height: 32,
                width: 32,
                textAlign: 'center',
                backgroundColor: '#FDE30C',
                transform: `translateY(-50%) translateX(-50%)`,
                lineHeight: '25px',
              }}
            >
              {annotation.count}
            </div>
          )
        } else {
          this.mouseOutCount += 1
          if (this.selectedComment !== '' && this.mouseOutCount > 100) {
            this.selectedComment = ''
            this.props.highlightComment('')
          }
          return (
            <div
              style={{
                ...annotation.style,
                border: `solid 3px #FDE30C`,
                borderRadius: '50%',
                position: 'absolute',
                boxSizing: 'border-box',
                left: `${annotation.geometry.x}%`,
                top: `${annotation.geometry.y}%`,
                height: 26,
                width: 26,
                textAlign: 'center',
                backgroundColor: 'white',
                transform: `translateY(-50%) translateX(-50%)`,
                lineHeight: '20px',
              }}
            >
              {annotation.count}
            </div>
          )
        }

      default:
        return null
    }
  }
  renderComponent = ({ annotation }) => {
    let output = mentionsParser(annotation.data.text)
    return <></>
  }

  activeAnnotationComparator = (a, b) => a.data.id === b

  render() {
    const { isCommentActive = false, activeAnnotations = '' } = this.props

    return (
      <Annotation
        style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
        annotations={this.state.annotations}
        type={this.state.type}
        value={this.state.annotation}
        activeAnnotations={activeAnnotations}
        activeAnnotationComparator={this.activeAnnotationComparator}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        renderEditor={this.renderEditor}
        renderSelector={this.renderSelector}
        renderHighlight={this.renderHighlight}
        renderContent={this.renderComponent}
        disableAnnotation={!isCommentActive}
        disableOverlay={true}
      />
    )
  }
}

export default connect((state) => state, { highlightComment })(Multiple)
