import * as THREE from 'three'
import { cloneDeep } from 'lodash'
import OrbitControls from './OrbitControls'
import DRACOLoader from './DRACOLoader'
import GLTFLoader from './GLTFLoader'
import Measure from './Measure'
import { TransformControls } from './TransformControls'
import BasisTextureLoader from './BasisTextureLoader'
import { KTX2Loader } from './ktx2TextureLoader'
import TextureLoader from './TextureLoader'
import { VRButton } from './VRButton.js'
import {
  generateDuplicateAssetName,
  radToAng, //Can be replaced with Threejs MathUtils.radToDeg
  angToRad, //Can be replaced with Threejs MathUtils.degToRad
  compareObjectEquality, // can be replaced with  threejs method Vector3.equals
  getAssetUrl,
  getCurrentOS,
  getRequestHeaders,
  createCustomEvent,
} from '../helpers/utils'
import ObjectSnapper from './Snapper'
import config from '../config/config'
import {
  RENDER_RIGHT_DRAWER,
  RENDER_HEADER_HEIGHT,
  PAN_LEFT,
  PAN_RIGHT,
  PAN_UP,
  PAN_DOWN,
  TILT_LEFT,
  TILT_RIGHT,
  TILT_UP,
  TILT_DOWN,
  ZOOM_IN,
  ZOOM_OUT,
  ZF_STEP,
  TRACK_SCENE_EVENT,
} from '../constants/scene'
import { fetchGalleryPreSignedGLBLink } from '../store/customCard/actionCreator'

import ACTIONS from '../constants/actions'
import { SceneHelper } from './SceneHelper'

class ThreeScene {
  constructor() {
    this.THREE = THREE
    this.selectedGroup = []
    this.selectedObject = null
    this.assetSelectFlag = true
    this.groupSelectFlag = false
    this.initializeCamera()
    this.scene = new SceneHelper()
    this.loadingManager = new THREE.LoadingManager()
    this.renderer = {}
    if (window.WebGLRenderingContext) {
      this.renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true })
    }
    this.renderer.gammaOutput = true
    this.renderer.gammaFactor = 2.2
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(
      window.innerWidth,
      window.innerHeight - RENDER_HEADER_HEIGHT
    )
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.maxPolarAngle = Math.PI / 1.98
    this.initializeControls()
    this.scene.background = new THREE.Color(0xf0f0f0)
    this.scene.add(this.camera)
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.outlineObjects = []
    this.visibleBorders = []
    this.selectedTemplate = null
    this.isDirty = false
    this.grpArray = []
    this.prevAsset = { name: undefined }
    this.editable = true
    this.targetPos = new THREE.Vector3(0, 0, 0)
    this.currentTarget = null
    this.placeHolder = null
    this.hoverElements = []
    this.droppedPosition = {}
    this.targetplane = null
    this.renderMode = false
    this.assetLoading = []

    this.gltfLoader = new GLTFLoader(this.loadingManager)
    DRACOLoader.setDecoderPath('/js/lib/draco/gltf/')

    this.dracoLoader = new DRACOLoader()

    //this.dracoLoader.setDecoderPath('/js/lib/draco/gltf/')
    this.gltfLoader.setDRACOLoader(this.dracoLoader)

    this.basisLoader = new BasisTextureLoader()
    this.basisLoader.setTranscoderPath('/js/lib/')
    this.basisLoader.useAlpha = true
    this.basisLoader.detectSupport(this.renderer)

    this.ktxLoader = new KTX2Loader()
    this.ktxLoader.setTranscoderPath('/js/lib/')
    this.ktxLoader.useAlpha = true
    this.ktxLoader.detectSupport(this.renderer)
    this.loadingManager.addHandler(/\.ktx2$/, this.ktxLoader)
    this.loadingManager.addHandler(/\.basis$/, this.basisLoader)
    this.imageTextureLoader = new TextureLoader(this.loadingManager)
    this.loadingManager.addHandler(/\.png$/, this.imageTextureLoader)
    this.loadingManager.addHandler(/\.jpg$/, this.imageTextureLoader)

    this.gltfLoader.setKtx2Loader(this.ktxLoader)

    this.undoRedoStack = []
    this.index = -1
    this.activateUndo = false
    this.activateRedo = false
    this.controlElements = null
    this.showContextMenu = false
    this.isActiveOperation = false
    this.assetScaleOnSelection = null
    this.swapmode = false
    this.editMaterial = false
    this.scaling = false
    this.defaultFocalLength = 0
    this.failedAssets = []
    this.os = getCurrentOS()
    this.totalAssets = 0
    this.loadedAssetCount = 0
    this.failedAssetCount = 0
    this.plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0)
    this.templateScene = []
    this.sceneId = ''
    this.topView = false
    this.sceneEventTracker = []
    this.measuremode = false
    this.editType = 'TCIN'
    this.tilingmode = false
  }

  initializeLights() {
    this.ambientLight = new THREE.AmbientLight(0xcccccc, 0.8)
    this.scene.add(this.ambientLight)
    this.gridHelper = new THREE.GridHelper(4000, 600, 0xf0f0f0, 0xf0f0f0)
    const grid = new THREE.GridHelper(4000, 50)
    this.scene.add(grid)
    grid.position.set(0, -0.45, 0)
    this.gridHelper.position.set(0, -0.5, 0)
    this.scene.add(this.gridHelper)

    var geometry = new THREE.BoxGeometry(4, 4, 4, 2, 2, 2)
    var material = new THREE.MeshLambertMaterial({ color: '0xffffff' })
    material.opacity = 0.5
    material.transparent = true
    material.wireframe = true
    this.targetplane = new THREE.Mesh(geometry, material)

    this.targetplane.visible = false
    this.scene.add(this.targetplane)

    this.createBoxObject()
    this.scene.add(this.plane)
    this.animate()
  }

  initializeCamera() {
    this.camera = new THREE.PerspectiveCamera(
      25,
      window.innerWidth / window.innerHeight,
      1,
      20000
    )
    this.camera.position.set(0, 200, 100)
    this.camera.lookAt(0, 0, 0)
    this.pointLight = new THREE.PointLight(0xffffff, 0.2)
    this.camera.add(this.pointLight)
    this.defaultFocalLength = this.camera.getFocalLength()
    this.camera.zoom = 0.2
  }

  initializeControls() {
    this.controls.screenSpacePanning = true
    this.controls.zoomSpeed = 0.9
    this.controls.rotateSpeed = 0.7
    this.controls.maxDistance = 3000
    this.controls.keys = {
      LEFT: 37, //left arrow
      UP: 38, // up arrow
      RIGHT: 39, // right arrow
      BOTTOM: 40, // down arrow
      ALTLEFT: 65, //A
      ALTUP: 87, //W
      ALTRIGHT: 68, //D
      ALTBOTTOM: 83, //S
    }
    this.controls.update()
  }
  initializeTransformControls() {
    this.transformControls = new TransformControls(
      this.camera,
      this.renderer.domElement
    )

    this.transformControls.addEventListener('change', () => {
      if (this.transformControls.object) {
        const boxH = this.scene.getObjectByName(
          this.transformControls.object.name + '_boxHelper'
        )
        if (boxH) {
          boxH.update()
        }
      }
      this.updateControls()
      this.sceneRender()
    })
    this.transformControls.addEventListener('dragging-changed', (event) => {
      this.controls.enabled = !event.value
      this.isDirty = true
      this.removeMouseMove()
    })
    this.transformControls.addEventListener('objectChange', (event) => {
      this.isDirty = true
    })
    this.transformControls.addEventListener('mouseUp', (event) => {
      setTimeout(this.addMouseMove, 500)
      const asset = event.target.object
      if (asset.parent instanceof THREE.Group) {
        let positionShift = this.updateGroupPosition(asset.parent)
        let updatedPrevPosition = new THREE.Vector3(
          this.prevState.position.x,
          this.prevState.position.y,
          this.prevState.position.z
        ).sub(positionShift)
        this.prevState.position = { ...updatedPrevPosition }
      }
      this.currentState = {
        position: { ...asset.position },
        rotation: { ...asset.rotation },
        scale: { ...asset.scale },
      }
      if (
        !(
          compareObjectEquality(
            this.prevState.position,
            this.currentState.position
          ) &&
          compareObjectEquality(
            this.prevState.rotation,
            this.currentState.rotation
          )
        )
      ) {
        this.addToStack('MOVE_ASSET', {
          assetName: asset.name,
          prevState: this.prevState,
          currState: this.currentState,
        })
        this.processSceneEventData({
          event_type: 'MOVE_ASSET',
          event_data: {
            previous_state: {
              asset_id: asset.assetId,
              name: asset.name,
              position: { ...this.prevState.position },
              rotation: {
                _x: radToAng(this.prevState.rotation._x),
                _y: radToAng(this.prevState.rotation._y),
                _z: radToAng(this.prevState.rotation._z),
              },
              scale: { ...this.prevState.scale },
            },
            current_state: {
              asset_id: asset.assetId,
              name: asset.name,
              position: { ...this.currentState.position },
              rotation: {
                _x: radToAng(this.currentState.rotation._x),
                _y: radToAng(this.currentState.rotation._y),
                _z: radToAng(this.currentState.rotation._z),
              },
              scale: { ...this.currentState.scale },
            },
          },
        })
      }
    })
    this.transformControls.addEventListener('mouseDown', (event) => {
      const asset = event.target.object
      this.prevState = {
        position: { ...asset.position },
        rotation: { ...asset.rotation },
        scale: { ...asset.scale },
      }
    })
    this.controls.addEventListener('start', this.removeMouseMove)
    this.controls.addEventListener('end', this.addMouseMove)
    this.controls.addEventListener('change', () => {
      let { x, y, z } = this.controls.getTarget()
      this.targetplane.position.set(x, y, z)
      this.updateControls()
    })

    this.scene.add(this.transformControls)

    this.snapper = new ObjectSnapper({
      camera: this.camera,
      renderer: this.renderer.domElement,
      objects: this.scene.getObjectsByType('ALL'),
    })
    this.snapper.addEventListener('snapped', ({ prevPosition, selected }) => {
      if (!compareObjectEquality(selected.position, prevPosition)) {
        this.addToStack('SNAP_ASSET', {
          assetName: selected.name,
          prevState: {
            position: { ...prevPosition },
            rotation: selected.rotation,
          },
          currState: {
            position: { ...selected.position },
            rotation: selected.rotation,
          },
        })
        let selectedAsset = { ...selected }
        this.processSceneEventData({
          event_type: 'SNAP_ASSET',
          event_data: {
            previous_state: {
              name: selected.name,
              asset_id: selected.assetId,
              position: { ...prevPosition },
              rotation: {
                _x: radToAng(selectedAsset.rotation._x),
                _y: radToAng(selectedAsset.rotation._y),
                _z: radToAng(selectedAsset.rotation._z),
              },
              scale: { ...selectedAsset.scale },
            },
            current_state: {
              name: selected.name,
              asset_id: selected.assetId,
              position: { ...selectedAsset.position },
              rotation: {
                _x: radToAng(selectedAsset.rotation._x),
                _y: radToAng(selectedAsset.rotation._y),
                _z: radToAng(selectedAsset.rotation._z),
              },
              scale: { ...selectedAsset.scale },
            },
          },
        })
      }

      this.animate()
    })
    this.scene.add(this.snapper)

    this.measurer = new Measure({
      scene: this.scene,
      camera: this.camera,
      renderer: this.renderer,
      objects: this.scene.getObjectsByType('ALL'),
    })
    this.measurer.addEventListener('MeasureUpdate', () => {
      this.animate()
    })
    this.scene.add(this.measurer)
  }

  addMouseMove = () => {
    this.domElement.addEventListener(
      'mousemove',
      this.onDocumentMouseMove,
      false
    )
  }

  removeMouseMove = () => {
    this.domElement.removeEventListener(
      'mousemove',
      this.onDocumentMouseMove,
      false
    )
  }

  initializeRenderer(app, sceneId) {
    this.clearScene()
    this.domElement = app
    this.initializeTransformControls()
    app.appendChild(this.renderer.domElement)
    this.scene.add(this.camera)
    this.animate()
    window.addEventListener('resize', this.onWindowResize.bind(this), false)

    app.addEventListener('mousemove', this.onDocumentMouseMove)
    this.assetNameList = []
    this.controlElements = document.getElementById('assetControls')
    this.sceneId = sceneId
    this.initializeLights()
    /*** Just to validate the VR  */
    this.renderer.xr.enabled = true
    app.appendChild(VRButton.createButton(this.renderer))
    this.renderer.setAnimationLoop(() => {
      this.renderer.render(this.scene, this.camera)
    })
    /*** End of the VR */
  }

  setTargetPosition = (event, updatePosition = false) => {
    let x, y

    let rect = this.renderer.domElement.getBoundingClientRect()
    x = (event.clientX / rect.width) * 2 - 1
    y = -((event.clientY - rect.top) / rect.height) * 2 + 1
    this.mouse.set(x, y)
    if (!this.marker) {
      this.marker = new THREE.Vector3()
    }
    this.raycaster.setFromCamera(this.mouse, this.camera)
    let objects = []
    if (this.currentTarget) {
      objects = this.scene
        .getObjectsByType('ALL')
        .filter(({ name }) => name !== this.currentTarget.name)
    }
    const intersections = this.raycaster.intersectObjects(
      [...this.templateScene, ...objects],
      true
    )
    if (intersections && intersections[0]) {
      this.targetPos = this.snapper.getTemplateSnappingPoint(
        this.currentTarget,
        intersections[0]
      )
    } else {
      this.raycaster.ray.intersectPlane(this.plane, this.marker)
      this.targetPos = this.marker
    }

    if (this.currentTarget && updatePosition) {
      this.currentTarget.position.set(
        this.targetPos.x,
        this.targetPos.y,
        this.targetPos.z
      )
      this.animate()
    }

    this.targetPos.event = { x: event.clientX, y: event.clientY }
    return this.targetPos
  }

  createBoxObject() {
    const geometry = new THREE.BoxGeometry(1, 1, 1, 4, 4, 4)
    this.placeHolder = new THREE.Mesh(
      geometry,
      new THREE.MeshLambertMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.9,
      })
    )
    /* Dont Remove   new THREE.MeshBasicMaterial({ wireframe: true,  }) */
    this.placeHolder.visible = false
    this.scene.add(this.placeHolder)
  }

  onWindowResize() {
    let width = window.innerWidth
    let height = window.innerHeight - RENDER_HEADER_HEIGHT

    this.resizeScene(width, height)
  }

  resizeScene = (width, height) => {
    this.camera.aspect = width / height
    this.renderer.setSize(width, height)
    this.animate()
    this.camera.updateProjectionMatrix()
  }
  onProgress = (xhr) => {
    if (xhr.lengthComputable) {
      this.percentComplete = (xhr.loaded / xhr.total) * 100
    }
  }

  addAssets = async (params = {}) => {
    const {
      url: tUrl = '',
      tcin = 'CustomAsset',
      position = { x: 0, y: 0, z: 0 },
      rotation = { _x: 0, _y: 0, _z: 0 },
      scale = { x: 1, y: 1, z: 1 },
      isGrouped = false,
      assetType = 'TCIN',
      visibility = true,
      isDragged = false,
      uid = '',
      assetId = '',
      cartOnly = false,
      name = '',
      addToStackFlag = false,
      locked = false,
      applyWorldRotationOnAssets = false,
      subAssetType = null,
      isTemplateEdited = false,
      isFlipped = false,
      isLoadingFromJson = false,
      generateSignedUrl = true,
      materialEditMetadata = [],
    } = params
    let url = tUrl
    let assetUrl =
      tUrl.includes('gallery') || url.includes('Amz')
        ? tUrl
        : `${config.renditionsUrl}${tUrl}`

    // this.isDirty = true
    let sceneId = this.sceneId
    return new Promise(async (resolve, reject) => {
      if (assetType == 'PID' && generateSignedUrl) {
        try {
          assetUrl = await fetchGalleryPreSignedGLBLink(assetId)
          url = assetUrl

          if (url === '') {
            createCustomEvent(TRACK_SCENE_EVENT, {
              type: 'AssetLoadingFailure',
              assetId: assetId,
              e: 'LOD profile is not available',
            })
          }
        } catch (e) {
          this.failedAssets.push({ ...params })
          createCustomEvent(TRACK_SCENE_EVENT, {
            type: 'AssetLoadingFailure',
            assetId: assetId,
            e: 'failed to fetch LOD url from gallery',
          })

          reject(e)
          return
        }
      }

      const requestHeaders = getRequestHeaders(config, url, assetType)
      this.gltfLoader.load(
        assetUrl,
        ({ scene }) => {
          if (sceneId !== this.sceneId) {
            reject({ error: 'Scene has been changed' })
            return
          }

          let asset = new THREE.Object3D()
          asset.add(scene)
          asset.wrapperId = 'wrapperObject'

          asset.assetType =
            assetType !== null && assetType !== 'custom' ? assetType : 'TCIN'
          asset.url = url
          asset.assetId = assetId && assetId !== null ? assetId : tcin
          asset.name = name ? name : this.generateName(tcin)
          this.assetNameList.push(asset.name)
          let removeFromScene = false
          if (isDragged) {
            let { x, y, z, event } = this.droppedPosition[uid]
              ? this.droppedPosition[uid]
              : this.targetPos
            asset.position.set(x, y, z)
            this.placeHolder.visible = false
            this.currentTarget = asset
            if (event && event.x < 650) {
              if (
                this.droppedPosition[uid] &&
                this.droppedPosition[uid].event.x < 650
              ) {
                removeFromScene = true
              }
              delete this.droppedPosition[uid]
            }
          } else {
            asset.position.set(position.x, position.y, position.z)
          }
          if (applyWorldRotationOnAssets && !isTemplateEdited) {
            asset.rotateOnWorldAxis(
              new THREE.Vector3(1, 0, 0),
              angToRad(rotation._x)
            )
            asset.rotateOnWorldAxis(
              new THREE.Vector3(0, 0, 1),
              angToRad(rotation._z)
            )
            asset.rotateOnWorldAxis(
              new THREE.Vector3(0, 1, 0),
              angToRad(rotation._y)
            )
          } else {
            asset.rotation.set(
              angToRad(rotation._x),
              angToRad(rotation._y),
              angToRad(rotation._z)
            )
          }
          if (scale !== null) {
            asset.scale.set(scale.x, scale.y, scale.z)
          }
          asset.isGrouped = isGrouped
          asset.visible = visibility && !cartOnly
          asset.cartOnly = cartOnly
          asset.locked = locked
          asset.subAssetType = subAssetType
          if (!removeFromScene) {
            this.scene.addObjectsByType(asset)

            if (!isGrouped) {
              this.scene.add(asset)
            }
            this.addBoxHelper(asset)
            if (addToStackFlag) {
              this.addToStack('ADD_ASSET', { asset: { ...asset } })
            }
            if (!isLoadingFromJson) {
              this.processSceneEventData({
                event_type: 'ADD_ASSET',
                event_data: {
                  previous_state: {},
                  current_state: {
                    name: asset.name,
                    asset_id: asset.assetId,
                    position: { ...asset.position },
                    rotation: {
                      _x: radToAng(asset.rotation._x),
                      _y: radToAng(asset.rotation._y),
                      _z: radToAng(asset.rotation._z),
                    },
                    scale: { ...asset.scale },
                    is_grouped: asset.isGrouped,
                  },
                },
              })
            }
            asset.isFlipped = isFlipped
            this.setDoubleSided(asset)
            if (isFlipped) {
              this.flipHorizontal(asset, false, isLoadingFromJson)
            }
            asset.materialEditMetadata = materialEditMetadata

            if (materialEditMetadata.length) {
              const { color, swatch_thumbnail_url } =
                materialEditMetadata[0]?.updatedMaterialMetadata

              const materialMetaDataList =
                materialEditMetadata[0]?.sourceMaterialMetadataList.map(
                  (item) => {
                    return {
                      ...item,
                      updated: true,
                      color: color,
                      swatch_thumbnail_url: swatch_thumbnail_url,
                    }
                  }
                )
              this.switchColorTexture(
                asset,
                materialMetaDataList,
                materialEditMetadata[0]?.updatedMaterialMetadata
              )
            }

            resolve(asset)
            this.triggerSceneChangeEvent()
            this.animate()
          }
        },
        this.onProgress,
        (e) => {
          this.failedAssets.push({ ...params })
          if (isDragged) {
            this.placeHolder.visible = false
          }

          if (e.type !== 'abort') {
            console.log('Something went wrong in loading asset', { e })
            createCustomEvent(TRACK_SCENE_EVENT, {
              type: 'AssetLoadingFailure',
              assetId: assetId,
              e: e.toString(),
            })
            reject(e)
          }
        },
        requestHeaders
      )
    })
  }

  addBoxHelper(asset) {
    const box = new THREE.BoxHelper(asset, 0xff0000)
    box.name = asset.name + '_boxHelper'
    box.visible = false
    this.scene.add(box)
  }

  addTemplate(url, id, replaceTemplate) {
    this.templateScene = []
    let templateLoadUrl = `${config.renditionsUrl}${url}`
    this.templateUrl = url
    this.templateId = id
    let sceneId = this.sceneId
    if (url) {
      return new Promise((resolve, reject) => {
        const requestHeaders = getRequestHeaders(config, templateLoadUrl)
        this.gltfLoader.load(
          templateLoadUrl,
          ({ scene: template }) => {
            if (sceneId !== this.sceneId) {
              reject({ error: 'Scene has been changed' })
              return
            }

            if (replaceTemplate) {
              let existingTemplate = this.scene.getObjectByName('template')
              if (existingTemplate) {
                this.scene.remove(existingTemplate)
              }
            }
            template.name = 'template'
            this.scene.add(template)
            this.templateScene.push(template)
            this.snapper.setTemplate(template)
            this.animate()
            resolve()
          },
          this.onProgress,
          (e) => {
            console.log(e)
            reject(e)
          },
          requestHeaders
        )
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve()
      })
    }
  }

  initGroup(assets, groupName, addToStackFlag = true) {
    if ((assets || []).length > 1) this.grpArray = assets

    let assetsArray = [...this.grpArray]
    let group = groupName ? this.scene.getObjectByName(groupName) : false

    if (this.grpArray.length <= 1 || this.scene.containsGroup(this.grpArray)) {
      /* stopping the group creation in case single asset  is selected */
      console.error(
        'Cannot create group on single asset'
      ) /*or GrpArray already has a Group, Needs to be updated when rename group is coming  */
      return { status: groupStatus, assets: assets, groupName: group.name }
    }
    if (!group && this.grpArray.length) {
      group = new THREE.Group()
      group.name = groupName ? groupName : this.generateName('Group')
      group.visible = true
      this.scene.add(group)
      this.addBoxHelper(group)
      // let newPos = this.calculateGroupPositions(this.grpArray)
      // group.position.set(newPos.x, newPos.y, newPos.z)
    }
    const groupStatus = this.groupAssets(this.grpArray, group)
    this.updateGroupPosition(group)

    if (addToStackFlag) {
      this.addToStack('GROUP_ASSETS', {
        groupName: group.name,
        assets: assetsArray,
      })
    }
    let box = new THREE.Box3().setFromObject(group)
    this.processSceneEventData({
      event_type: 'GROUP_ASSETS',
      event_data: {
        previous_state: { assets: assetsArray },
        current_state: {
          name: group.name,
          bounding_box: box,
          position: group.position,
          rotation: {
            _x: radToAng(group.rotation._x),
            _y: radToAng(group.rotation._y),
            _z: radToAng(group.rotation._z),
          },
        },
      },
    })
    this.triggerSceneChangeEvent()
    return { status: groupStatus, assets: assets, groupName: group.name }
  }
  calculateGroupPositions(elements) {
    //Deprecated, using updateGroupPosition instead, keeping it for reference
    let x = 0,
      y = 0,
      z = 0
    this.scene.updateWorldMatrix()
    elements.forEach((name, index) => {
      let mesh = this.scene.getObjectByName(name)
      y = index === 0 ? mesh.position.y : y
      if (mesh && mesh instanceof THREE.Object3D) {
        mesh.updateWorldMatrix()
        let newPosition = new THREE.Vector3()
        mesh.getWorldPosition(newPosition)
        x += newPosition.x
        z += newPosition.z
        y = Math.min(newPosition.y, y)
      }
    })
    return { x: 0, y: 0, z: 0 }
  }

  groupAssets(grpArray, targetGroup) {
    try {
      if (grpArray.length) {
        grpArray.forEach((name) => {
          let mesh = this.resetAssetObject(this.scene.getObjectByName(name))
          if (mesh && mesh instanceof THREE.Object3D && !mesh.isGrouped) {
            this.addToGroup(mesh, targetGroup)
          }
        })
        this.grpArray = []
        if (targetGroup) {
          this.selectedObject = targetGroup
          this.outlineObjects = [targetGroup.name]
          const groupBox = this.scene.getObjectByName(
            targetGroup.name + '_boxHelper'
          )
          groupBox.update()
          this.attachTransFormControls(targetGroup)
        }
        this.triggerSceneChangeEvent()
        this.animate()
        this.isDirty = true
      }
      return true
    } catch (err) {
      return false
    }
  }
  selectAssetForGroup = (event) => {
    const assetsToBeSelectedFrom = this.scene.getObjectsByType(this.editType)

    const intersect = this.resetAssetObject(
      this.getIntersectedObject(event, assetsToBeSelectedFrom)
    )

    if (event.metaKey || event.ctrlKey) {
      let index = intersect ? this.grpArray.indexOf(intersect.name) : -1
      if (intersect && index !== -1) {
        this.grpArray.splice(index, 1)
      } else if (
        intersect &&
        !intersect.isGrouped &&
        !this.scene.containsGroup(this.grpArray)
      ) {
        /*stopping users multi selection if group is selected previously */
        this.grpArray.push(intersect.name)
      }
      this.outlineObjects = this.grpArray
      this.transformControls.detach()
    } else if (intersect) {
      this.selectedObject = intersect
      if (intersect.parent && intersect.parent.type === 'Group') {
        this.selectedObject = intersect.parent
      }

      if (this.selectedObject) {
        if (this.editable) {
          this.attachTransFormControls(this.selectedObject)
        }
        this.grpArray = [this.selectedObject.name]
      }
      this.outlineObjects = this.grpArray
    } else {
      this.transformControls.detach()
      this.outlineObjects = []
      this.grpArray = []
      this.selectedObject = null
    }

    this.isDirty = true
    this.triggerSceneChangeEvent()
    this.animate()

    return this.grpArray
  }

  addToGroup = (asset, targetGroup, updatePosition = true) => {
    asset.isGrouped = true

    let oldParent = asset.parent
    oldParent.remove(asset)
    targetGroup.add(asset)
    let { x, y, z } = asset.parent.position
    if (updatePosition) {
      asset.position.set(
        asset.position.x - x,
        asset.position.y - y,
        asset.position.z - z
      )
    }

    this.triggerSceneChangeEvent()
  }

  removeFromGroup = (asset, removeGroup = false) => {
    asset.isGrouped = false

    let group = asset.parent
    let { _x, _y, _z } = group.rotation

    group.updateWorldMatrix()
    this.scene.updateWorldMatrix()
    asset.updateWorldMatrix()
    let newPosition = new THREE.Vector3()
    asset.getWorldPosition(newPosition)

    let quaternion = new THREE.Quaternion()
    asset.getWorldQuaternion(quaternion)

    let assetRotation = new THREE.Euler()
    assetRotation.setFromQuaternion(quaternion)

    group.remove(asset)
    asset.position.copy(newPosition)
    // asset.applyQuaternion(quaternion)

    asset.rotation.set(
      parseFloat(assetRotation._x),
      parseFloat(assetRotation._y),
      parseFloat(assetRotation._z)
    )

    asset.scale.copy(
      new THREE.Vector3().copy(asset.scale).multiply(group.scale)
    )
    this.scene.updateWorldMatrix()
    asset.updateWorldMatrix()
    this.scene.add(asset)
    if (removeGroup) {
      if (group.children.length === 0) {
        const groupBoxHelper = this.scene.getObjectByName(
          group.name + '_boxHelper'
        )
        if (groupBoxHelper) {
          this.scene.remove(groupBoxHelper)
        }
        this.scene.remove(group)
      } else {
        this.updateGroupPosition(group)
      }
    }
    this.triggerSceneChangeEvent()
  }

  updateGroupPosition(group) {
    group.updateWorldMatrix()
    let box = new THREE.Box3().setFromObject(group)
    const center = new THREE.Vector3()
    box.getCenter(center)
    let newPositionForGroup = new THREE.Vector3(center.x, box.min.y, center.z)
    let shiftedMatrix = newPositionForGroup.clone().sub(group.position)
    group.position.copy(newPositionForGroup)
    group.children.map((item) => {
      if (item instanceof THREE.Object3D) {
        item.position.copy(item.position.clone().sub(shiftedMatrix))
      }
    })
    this.animate()
    return shiftedMatrix
  }

  groupDiscard(groupName = null, addToStackFlag = true) {
    let childrenMap = []
    let group =
      groupName === null
        ? this.selectedObject
        : this.scene.getObjectByName(groupName)
    if (group instanceof THREE.Group) {
      this.transformControls.detach()

      if (group) {
        const groupBoxHelper = this.scene.getObjectByName(
          group.name + '_boxHelper'
        )
        if (groupBoxHelper) {
          this.scene.remove(groupBoxHelper)
        }
        let groupChildren = group.children
        childrenMap = groupChildren.map((item) => item.name)
        childrenMap.forEach((item) => {
          let child = this.scene.getObjectByName(item)
          this.removeFromGroup(child)
        })
        this.scene.remove(group)
      }
      this.outlineObjects = []
      this.selectedObject = null
      this.animate()
    } else {
      this.grpArray.forEach((item) => {
        let child = this.scene.getObjectByName(item)
        this.removeFromGroup(child)
      })
    }
    if (addToStackFlag) {
      this.addToStack('UNGROUP_ASSETS', {
        groupName: group && group.name,
        assets: childrenMap,
      })
    }
    this.processSceneEventData({
      event_type: 'UNGROUP_ASSETS',
      event_data: {
        previous_state: { name: group && group.name },
        current_state: { assets: childrenMap },
      },
    })
    this.triggerSceneChangeEvent()
  }

  getIntersectedObject = (event, source, onlyObject = true) => {
    let x, y
    let rect = this.renderer.domElement.getBoundingClientRect()
    x = (event.clientX / rect.width) * 2 - 1
    y = -((event.clientY - rect.top) / rect.height) * 2 + 1
    this.mouse.set(x, y)
    this.raycaster.setFromCamera(this.mouse, this.camera)
    let intersects = this.raycaster.intersectObjects(source, true)
    let intersect
    if (intersects.length > 0) {
      if (onlyObject) {
        intersect = intersects[0].object
      } else {
        intersect = intersects[0]
      }
    }
    return intersect
  }

  onDocumentMouseMove = (event) => {
    const assetsTobeSelected = this.scene.getObjectsByType(this.editType)
    let asset = this.getIntersectedObject(event, assetsTobeSelected) //need to rework this.editType
    //this.setTargetPosition(event)
    this.hoverElements = []

    if (asset) {
      asset = this.resetAssetObject(asset)
      if (asset.parent && asset.parent.type === 'Group') {
        asset = asset.parent
      }
      this.hoverElements = [asset.name]
    } else {
      this.prevAsset = { name: undefined }
      asset = { name: null }
    }

    if (asset.name !== this.prevAsset.name) {
      this.prevAsset = asset
    }
    this.animate()
  }

  //onDocumentMouseDown method is not used
  // onDocumentMouseDown = (event) => {
  //   let asset = this.getIntersectedObject(event, this.scene.getObjectsByType(this.objects, this.editType)) //need to work [this.editType]
  //   if (asset && !event.metaKey) {
  //     this.selectedObject = asset
  //     if (asset.parent.type === 'Group') {
  //       this.selectedObject = asset.parent
  //     }
  //     if (this.selectedObject) {
  //       this.attachTransFormControls(this.selectedObject)
  //       this.grpArray = [asset.name]
  //       this.animate()
  //     }
  //     return asset
  //   }
  //   return null
  // }
  onError() {
    return null
  }
  selectAsset = (name) => {
    if (this.editable) {
      this.selectedObject = this.scene.getObjectByName(name)
      if (this.selectedObject && this.selectedObject.visible) {
        this.attachTransFormControls(this.selectedObject)
        this.animate()
      }
    }
  }
  /** loadScene method is unused */
  // loadScene(url) {
  //   return new Promise((resolve, reject) => {
  //     this.gltfLoader.load(
  //       url,
  //       (gltf) => {
  //         this.objects = {TCIN:[], ARCH:[], INFRA:[], PID: [], PROP:[]}

  //         gltf.scene.traverse((child) => {
  //           if (
  //             (child instanceof THREE.Mesh ||
  //               child instanceof THREE.Object3D) &&
  //             child.parent instanceof THREE.Scene
  //           ) {
  //             if (child.name === 'Group') {
  //               this.group = new THREE.Group()
  //               this.group.name = 'Group'
  //               this.group.children = child.children
  //               this.scene.add(this.group)
  //               this.group.children.map((mesh) => {
  //                 mesh.parent = this.group
  //                 this.objects.push(mesh)
  //                 this.objects[mesh.assetType].push(mesh)
  //                 this.selectedGroup.push(mesh)
  //               })
  //             } else {
  //               let object = child.clone()
  //               if (child instanceof THREE.Mesh) {
  //                 this.objects.push(object)
  //                 this.assetNameList.push(object.name)
  //               } else {
  //                 //  this.exportedScene.add(object.clone())
  //                 //this.selectedTemplate = object.clone()
  //               }
  //               this.scene.add(object)
  //             }
  //           }
  //         })
  //         this.animate()
  //         resolve()
  //       },
  //       function (xhr) {
  //         console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
  //       },
  //       (e) => {
  //         console.log(e)
  //         reject()
  //       }
  //     )
  //   })
  // }
  exportSceneAsJson(addCameraSettings) {
    this.camera.updateProjectionMatrix()
    let sceneJSON = {
      template_url: this.templateUrl,
      template_id: this.templateId,
      scene_data: {
        assets: [],
        groups: [],
        camera: addCameraSettings
          ? {
              position: this.camera.position,
              target: this.controls.getTarget(),
              fov: this.camera.getEffectiveFOV(),
              fl: this.camera.getFocalLength(),
              clipNear: this.camera.near,
              clipFar: this.camera.far,
              renderWidth: this.renderer.domElement.offsetWidth,
              renderHeight: this.renderer.domElement.offsetHeight,
              zoomFactor:
                (this.renderer.domElement.offsetWidth /
                  this.renderer.domElement.offsetHeight) *
                this.camera.zoom,
              sensorWidth: this.camera.filmGauge,
              aspectRatio: this.camera.aspect,
            }
          : null,
      },
    }

    //this.isDirty = false
    this.scene.traverse((child) => {
      if (
        child instanceof THREE.Object3D &&
        child.wrapperId === 'wrapperObject' &&
        child.parent instanceof THREE.Scene &&
        (child.assetType === 'custom' ||
          child.assetType === 'TCIN' ||
          child.assetType === 'PID' ||
          child.assetType === 'ARCHITECTURE' ||
          child.assetType === 'INFRASTRUCTURE' ||
          child.assetType === 'PROP')
      ) {
        let asset = {}
        asset.id = child.uuid
        asset.name = child.name
        asset.position = child.position
        asset.rotation = {
          _x: radToAng(child.rotation._x),
          _y: radToAng(child.rotation._y),
          _z: radToAng(child.rotation._z),
        }
        asset.scale = child.scale
        asset.isGrouped = child.isGrouped
        asset.cartOnly = child.cartOnly
        asset.url = child.url
        asset.assetType = child.assetType
        asset.visible = child.visible
        asset.assetId = child.assetId
        asset.locked = child.locked
        asset.subAssetType = child.subAssetType
        asset.selected = this.outlineObjects.includes(asset.name)
        asset.isFlipped = child.isFlipped
        asset.materialEditMetadata = child.materialEditMetadata
        sceneJSON.scene_data.assets.push(asset)
      }
      if (
        child instanceof THREE.Group &&
        child.parent instanceof THREE.Scene &&
        child.children.length
      ) {
        // to check if group has assets
        let group = {
          assets: [],
        }
        group.id = child.uuid
        group.name = child.name
        group.position = child.position
        //group.rotation = child.rotation
        group.rotation = {
          _x: radToAng(child.rotation._x),
          _y: radToAng(child.rotation._y),
          _z: radToAng(child.rotation._z),
        }
        group.scale = child.scale
        group.locked = child.locked
        group.selected = this.outlineObjects.includes(child.name)
        group.visible = child.visible
        group.isFlipped = child.isFlipped
        for (let obj of child.children) {
          let asset = {}
          asset.id = obj.uuid
          asset.name = obj.name
          asset.position = obj.position
          asset.rotation = {
            _x: radToAng(obj.rotation._x),
            _y: radToAng(obj.rotation._y),
            _z: radToAng(obj.rotation._z),
          }
          asset.scale = obj.scale
          asset.isGrouped = obj.isGrouped
          asset.url = obj.url
          asset.assetType = obj.assetType
          asset.visible = obj.visible
          asset.assetId = obj.assetId
          asset.cartOnly = obj.cartOnly
          asset.selected = this.outlineObjects.includes(asset.name)
          asset.locked = obj.locked
          asset.subAssetType = obj.subAssetType
          asset.isFlipped = obj.isFlipped
          asset.materialEditMetadata = obj.materialEditMetadata
          group.assets.push(asset)
        }
        sceneJSON.scene_data.groups.push(group)
      }
    })
    return sceneJSON
  }
  getZoomValue() {
    return this.camera.zoom
  }

  /** Unused exportSceneAsFile method */

  loadGroupAssets = (data, uid) => {
    this.totalAssets = 0
    this.loadedAssetCount = 0
    this.failedAssetCount = 0
    this.assetLoading = []
    let grp = cloneDeep(data)
    let group = new THREE.Group()
    group.uuid = grp.id
    group.name = this.generateName(grp.short_description)
    this.scene.add(group)
    this.addBoxHelper(group)
    grp.assets.map((item) => {
      item.parent = group
      let newAsset = this.addAssets({
        url: item.url,
        tcin: item.name,
        position: item.position,
        rotation: item.rotation,
        scale: item.scale,
        isGrouped: item.grouped,
        assetType: item.assetType,
        visibility: item.visible,
        assetId: item.assetId,
        cartOnly: item.cartOnly,
        locked: item.locked,
        subAssetType: item.subAssetType,
        groupName: group.name,
        materialEditMetadata: item.materialEditMetadata,
      }).then((asset) => {
        this.loadedAssetCount++
        group.add(asset)
        this.animate()
        this.triggerSceneChangeEvent()
      })
      this.totalAssets++

      this.assetLoading.push(newAsset)
    })
    return new Promise((resolve, reject) => {
      if (this.assetLoading.length) {
        Promise.allSettled(this.assetLoading).then((resAry) => {
          resAry.forEach((res) => {
            if (res.status === 'rejected') {
              this.failedAssetCount++
              reject()
            }
            if (this.failedAssetCount === 0) {
              let { x, y, z, event } = this.droppedPosition[uid]
                ? this.droppedPosition[uid]
                : this.targetPos
              group.position.set(x, y, z)
              this.placeHolder.visible = false
              this.currentTarget = group
            }
          })
          resolve()
          this.animate()
        })
      } else {
        resolve()
      }
    })
  }

  triggerSceneChangeEvent = () => {
    let sceneJson = this.exportSceneAsJson()
    let event = new CustomEvent('onSceneChange', {
      detail: sceneJson,
    })
    document.dispatchEvent(event)
  }
  addAssetToGroup = (groupObj) => {
    if (groupObj.groupName !== 'DroPpABle') {
      let selectedGroup = this.scene.getObjectByName(groupObj.groupName)
      this.groupAssets(groupObj.selectedAssets, selectedGroup)
      this.updateGroupPosition(selectedGroup)
    }
  }

  renameGroup = (groupObj) => {
    const groupTobeRenamed = this.scene.getObjectByName(groupObj.oldName)
    if (groupTobeRenamed.type === 'Group') {
      groupTobeRenamed.name = groupObj.newName
      this.assetNameList.push(groupObj.newName)
      const helper = this.scene.getObjectByName(groupObj.oldName + '_boxHelper')
      if (helper) {
        helper.name = groupObj.newName + '_boxHelper'
      } else {
        this.addBoxHelper(groupTobeRenamed)
      }
    }
    this.triggerSceneChangeEvent()
  }
  loadSceneFromJson = (
    input,
    applyWorldRotationOnAssets = false,
    isTemplateEdited = false
  ) => {
    let {
      scene_data: { assets = [], groups = [], camera: sceneCamera = {} } = {},
      template_default: { camera: templateCamera } = {},
      isTemplateScene = false,
    } = { ...input, template_default: { ...input.template_default } }

    let camera =
      sceneCamera !== null &&
      sceneCamera.position &&
      sceneCamera.position !== null
        ? { ...sceneCamera }
        : { ...templateCamera }

    if (camera.position) {
      this.applyCameraSettings(camera, true) //always set clipnear on page load, to solve backface culling
    }
    this.totalAssets = 0
    this.loadedAssetCount = 0
    this.failedAssetCount = 0
    this.assetLoading = []

    this.postLoadingData(isTemplateScene)
    if (assets && assets.length) {
      assets.map((item) => {
        if (item.url !== null) {
          let newAsset = this.addAssets({
            isFlipped: item.flipped,
            url: item.url,
            tcin: item.name,
            position: item.position,
            rotation: item.rotation,
            isGrouped: item.grouped,
            assetType: item.assetType,
            visibility: item.visible,
            assetId: item.assetId,
            cartOnly: item.cartOnly,
            scale: item.scale,
            locked: item.locked,
            subAssetType: item.subAssetType,
            applyWorldRotationOnAssets: applyWorldRotationOnAssets,
            isTemplateEdited,
            isLoadingFromJson: true,
            generateSignedUrl: true,
            materialEditMetadata: item.materialEditMetadata,
          }).then(() => {
            this.loadedAssetCount++
            this.postLoadingData(isTemplateScene)
          })
          this.totalAssets++

          this.assetLoading.push(newAsset)
        }
      })
    }
    if (groups && groups.length) {
      groups.forEach((grp) => {
        let group = new THREE.Group()
        group.uuid = grp.id
        group.name = this.generateName(grp.name)
        group.position.set(grp.position.x, grp.position.y, grp.position.z)
        // group.rotation.set(grp.rotation._x, grp.rotation._y, grp.rotation._z)
        group.rotation.set(
          angToRad(grp.rotation._x),
          angToRad(grp.rotation._y),
          angToRad(grp.rotation._z)
        )
        group.locked = grp.locked
        group.visible = grp.visible !== undefined ? grp.visible : true
        if (grp.scale) {
          group.scale.set(grp.scale.x, grp.scale.y, grp.scale.z) // TODO: check with backend team why scale values for groups not populating
        }
        group.isFlipped = grp.flipped
        this.scene.add(group)
        this.addBoxHelper(group)
        grp.assets.map((item) => {
          item.parent = group
          let newAsset = this.addAssets({
            isFlipped: item.flipped,
            url: item.url,
            tcin: item.name,
            position: item.position,
            rotation: item.rotation,
            scale: item.scale,
            isGrouped: item.grouped,
            assetType: item.assetType,
            visibility: item.visible,
            assetId: item.assetId,
            cartOnly: item.cartOnly,
            locked: item.locked,
            subAssetType: item.subAssetType,
            groupName: group.name,
            isLoadingFromJson: true,
            generateSignedUrl: true,
            materialEditMetadata: item.materialEditMetadata,
          }).then((asset) => {
            this.loadedAssetCount++
            group.add(asset)
            if (group.isFlipped) {
              this.flipHorizontal(asset, false)
            }
            this.postLoadingData(isTemplateScene)
            this.animate()
            this.triggerSceneChangeEvent()
            if (!asset.visible) {
              group.visible = false //should be removed after api changes are available
            }
          })
          this.totalAssets++

          this.assetLoading.push(newAsset)
        })
        this.postLoadingData(isTemplateScene)
        this.group = group
      })
    }

    return new Promise((resolve, reject) => {
      if (this.assetLoading.length) {
        Promise.allSettled(this.assetLoading).then((resAry) => {
          resAry.forEach((res) => {
            if (res.status === 'rejected') {
              this.failedAssetCount++
            }
          })
          if (this.failedAssetCount === 0) {
            resolve()
          } else {
            reject({ e: 'error in loading Assets' })
          }
          this.postLoadingData(isTemplateScene)
          this.animate()
        })
      } else {
        resolve()
      }
    })
  }
  postLoadingData = (isTemplateScene = false) => {
    document.dispatchEvent(
      new CustomEvent('onAssetLoaded', {
        detail: {
          total: this.totalAssets,
          loaded: this.loadedAssetCount,
          failed: this.failedAssetCount,
          failedAssets: this.failedAssets,
          isTemplateScene,
        },
      })
    )
  }
  reloadFailedData(isTemplateScene = false) {
    const reloadData = [...this.failedAssets]
    this.failedAssets = []
    this.assetLoading = []
    this.totalAssets = reloadData.length
    this.loadedAssetCount = 0
    this.failedAssetCount = 0
    this.postLoadingData()
    reloadData.map((item) => {
      let newAsset = this.addAssets(item).then((asset) => {
        this.loadedAssetCount++
        if (item.isGrouped && item.groupName !== '') {
          let group = this.scene.getObjectByName(item.groupName)
          group.add(asset)
        }
        this.animate()
        this.triggerSceneChangeEvent()
        this.postLoadingData(isTemplateScene)
      })
      this.assetLoading.push(newAsset)
    })

    return new Promise((resolve, reject) => {
      Promise.allSettled(this.assetLoading).then((resAry) => {
        resAry.forEach((res) => {
          if (res.status === 'rejected') {
            this.failedAssetCount++
          }
        })
        if (this.failedAssetCount === 0) {
          resolve()
        } else {
          reject({ e: 'error in loading Assets' })
        }

        this.postLoadingData(isTemplateScene)
        this.animate()
      })
    })
  }
  applyCameraSettings(settings, setClipNear = false) {
    const {
      position: { x: cameraX = 300, y: cameraY = 300, z: cameraZ = 300 } = {},
      target: { x: targetX = 0, y: targetY = 0, z: targetZ = 0 } = {},
      clipFar,
      clipNear,
      fl,
      fov,
      sensorWidth = 36,
      zoomFactor = 1.0,
    } = settings
    const prevCameraPosition = this.camera.position.clone()
    this.camera.position.set(cameraX, cameraY, cameraZ)
    this.camera.lookAt(targetX, targetY, targetZ)
    this.controls.setTarget(targetX, targetY, targetZ)
    this.camera.zoom =
      (this.renderer.domElement.offsetHeight /
        this.renderer.domElement.offsetWidth) *
      zoomFactor

    this.camera.updateProjectionMatrix()
    this.camera.setFocalLength(fl)
    this.camera.updateProjectionMatrix()
    this.camera.fov = fov
    this.camera.updateProjectionMatrix()
    this.camera.filmGauge = sensorWidth
    this.camera.updateProjectionMatrix()

    if (this.renderMode || setClipNear) {
      //To fix backface culling issue
      let clipNearValue = parseFloat(clipNear)
      this.camera.near = clipNearValue > 0 ? clipNearValue : 0.1
      if (this.renderMode) {
        this.camera.far = parseFloat(clipFar)
      }
    } else {
      this.camera.near = 0.1
      this.camera.far = 20000
    }

    this.camera.updateProjectionMatrix()

    this.camera.setFocalLength(fl)
    this.camera.updateProjectionMatrix()
    this.controls.update()
    this.animate()

    if (!this.prevFl || this.prevFl !== fl) {
      this.adjustScreen()
    }
    this.prevFl = fl
  }

  adjustScreen() {
    this.resizeScene(
      window.innerWidth - 10,
      window.innerHeight - RENDER_HEADER_HEIGHT - 20
    )
    setTimeout(() => {
      this.resizeScene(
        window.innerWidth,
        window.innerHeight - RENDER_HEADER_HEIGHT
      )
    }, 500)
  }
  resetCameraSettings() {
    this.camera.position.set(0, 200, 100)
    this.camera.lookAt(0, 0, 0)
    this.controls.setTarget(0, 0, 0)
    this.camera.zoom = 0.2
    this.camera.setFocalLength(this.defaultFocalLength)
    this.camera.fov = 25
    this.camera.filmGauge = 35
    this.camera.far = 20000
    this.camera.near = 0.1
  }
  clearScene() {
    this.gltfLoader.cancellPreviousPendingRequests()
    this.sceneId = ''
    this.scene.traverse((child) => {
      if (child instanceof THREE.Mesh && child.parent.type == 'Scene') {
        child.geometry.dispose()
        child.material.dispose()
        if (child === this.transformControls.object) {
          this.transformControls.detach()
        }
      }
      this.scene.remove.apply(this.scene, this.scene.children)
    })

    this.scene.clearObjectsByType()
    this.outlineObjects = []
    this.visibleBorders = []
    this.resetCameraSettings()
    this.triggerSceneChangeEvent()
    this.failedAssets = []
    this.editable = true
    this.templateUrl = ''
    this.templateId = ''
  }

  animate() {
    if (this.editable) {
      this.highlightItems()
    }
    if (
      this.controlElements !== null &&
      this.showContextMenu &&
      this.renderMode === false
    ) {
      this.controlElements.style.display = this.selectedObject
        ? 'none'
        : 'block'
    }
    this.camera.updateProjectionMatrix()
    this.updateControls()
    this.sceneRender()
  }

  highlightItems() {
    if (this.visibleBorders.length) {
      this.visibleBorders.forEach((item) => {
        item.visible = false
      })
    }
    this.visibleBorders = []
    const highlightElements = [...this.hoverElements, ...this.outlineObjects]

    highlightElements.forEach((item) => {
      const boxH = this.scene.getObjectByName(item + '_boxHelper')
      const boxasset = this.scene.getObjectByName(item)
      if (boxH && boxasset && boxasset.visible) {
        boxH.visible = true
        boxH.update()
        this.visibleBorders.push(boxH)
      }
    })
  }

  getSnapShot() {
    return new Promise((resolve, reject) => {
      this.renderer.domElement.toBlob(
        (res) => {
          resolve(res)
          this.isDirty = false
        },
        'image/jpeg',
        0.25
      )
    })
  }

  sceneRender() {
    this.renderer.render(this.scene, this.camera)
  }
  generateName(name) {
    return generateDuplicateAssetName(name, this.assetNameList)
  }
  isSceneDirty() {
    return this.isDirty
  }
  deleteAsset(asset, addToStackFlag = true) {
    if (this.editable) {
      let assetPosition = { ...asset.position }
      let groupName = ''
      let isGrouped = false
      if (asset.parent instanceof THREE.Group) {
        groupName = asset.parent.name
        isGrouped = true
        this.removeFromGroup(asset)
      }

      const deleteAsset = this.scene.getObjectByName(asset.name)
      const assetBox = this.scene.getObjectByName(asset.name + '_boxHelper')
      let index = this.scene.children.indexOf(deleteAsset)
      if (index !== -1) {
        if (deleteAsset === this.transformControls.object) {
          this.transformControls.detach()
        }
        if (deleteAsset.type === 'Group') {
          deleteAsset.children.forEach((child) => {
            const objects = this.scene.getObjectsByType(child.assetType, true)
            if (objects.indexOf(child) > -1) {
              objects.splice(objects.indexOf(child), 1) //need to rework here
            }
          })
        }
        const objects = this.scene.getObjectsByType(deleteAsset.assetType, true)
        if (objects.indexOf(deleteAsset) > -1) {
          objects.splice(objects.indexOf(deleteAsset), 1) //need to validate here
        }

        this.scene.remove(deleteAsset)
        this.scene.remove(assetBox)
        this.assetNameList = this.assetNameList.filter(
          (item) => item !== deleteAsset.name
        )
        if (addToStackFlag) {
          const dAsset = {
            ...asset,
            position: { ...assetPosition },
            groupName,
            isGrouped,
          }
          dAsset.rotation = {
            _x: radToAng(deleteAsset.rotation._x),
            _y: radToAng(deleteAsset.rotation._y),
            _z: radToAng(deleteAsset.rotation._z),
          }
          if (asset instanceof THREE.Group) {
            let groupchildren = []
            for (let childAsset of asset.children) {
              this.scene.remove(
                this.scene.getObjectByName(childAsset.name + '_boxHelper')
              )
              let child = { ...childAsset }
              child.id = childAsset.uuid
              child.rotation = {
                _x: radToAng(childAsset.rotation._x),
                _y: radToAng(childAsset.rotation._y),
                _z: radToAng(childAsset.rotation._z),
              }
              groupchildren.push({ ...child })
            }
            dAsset.children = groupchildren
          }

          this.addToStack('DELETE_ASSET', { asset: dAsset })
        }
        this.processSceneEventData({
          event_type: 'DELETE_ASSET',
          event_data: {
            previous_state: {
              name: deleteAsset.name,
              is_grouped: deleteAsset.isGrouped,
              position: { ...deleteAsset.position },
              rotation: {
                _x: radToAng(deleteAsset.rotation._x),
                _y: radToAng(deleteAsset.rotation._y),
                _z: radToAng(deleteAsset.rotation._z),
              },
              scale: { ...deleteAsset.scale },
            },
            current_state: {},
          },
        })
      }
      this.isDirty = true
      this.triggerSceneChangeEvent()
      this.animate()
    }
  }

  duplicateGroup(group, groupname, addToStackFlag, newposition) {
    const duplicateGroup = group.clone()
    duplicateGroup.name = groupname ? groupname : this.generateName(group.name)
    duplicateGroup.position.set(
      group.position.x + newposition,
      group.position.y,
      group.position.z + newposition
    )
    //this.objects.push(duplicateGroup) //need to work here, i think we dont need this because assets will be pushed to objects in duplicateasset method,
    //and parent group will be selected while selecting the asset for controls
    duplicateGroup.children = []
    group.traverse((child) => {
      if (
        child &&
        child instanceof THREE.Object3D &&
        child.wrapperId == 'wrapperObject'
      ) {
        this.duplicateAsset(child, undefined, false, 0, duplicateGroup)
      }
    })
    this.scene.add(duplicateGroup)
    this.attachTransFormControls(duplicateGroup)
    this.selectedObject = duplicateGroup
    this.grpArray = [this.selectedObject.name]
    this.outlineObjects = [this.selectedObject.name]
    this.addBoxHelper(duplicateGroup)
    this.hoverElements = [this.selectedObject.name]

    if (addToStackFlag) {
      this.addToStack('DUPLICATE_GROUP', {
        group: { ...group },
        duplicateGroup: { ...duplicateGroup },
      })
    }
    let box = new THREE.Box3().setFromObject(group)
    let dBox = new THREE.Box3().setFromObject(duplicateGroup)
    this.processSceneEventData({
      event_type: 'DUPLICATE_GROUP',
      event_data: {
        previous_state: {
          name: group && group.name,
          position: group && group.position,
          rotation: {
            _x: group && radToAng(group.rotation._x),
            _y: group && radToAng(group.rotation._y),
            _z: group && radToAng(group.rotation._z),
          },
          scale: group && group.scale,
          bounding_box: box,
        },
        current_state: {
          name: duplicateGroup.name,
          position: { ...duplicateGroup.position },
          rotation: {
            _x: radToAng(duplicateGroup.rotation._x),
            _y: radToAng(duplicateGroup.rotation._y),
            _z: radToAng(duplicateGroup.rotation._z),
          },
          scale: { ...duplicateGroup.scale },
          bounding_box: dBox,
        },
      },
    })
    this.animate()
    this.triggerSceneChangeEvent()
  }

  //duplicateAsset is reused for tileAsset functionality by passing additional params - 'direction(right|bottom|left|right)',
  //'onTilingErrorOrWarning(callback Fn)','onSuccess(callback Fn)' .
  //TODO: Refactor duplicate Asset - remove direction, refactor newPosition, include action(DUPLICATE_ASSET/TILE_ASSET).

  duplicateAsset(
    asset,
    assetName,
    addToStackFlag = true,
    newposition = 10,
    group,
    direction,
    onTilingErrorOrWarning = () => {},
    onSuccess = () => {}
  ) {
    const originalAsset = this.scene.getObjectByName(asset.name)
    if (this.editable && originalAsset instanceof THREE.Group && !direction) {
      this.duplicateGroup(originalAsset, assetName, addToStackFlag, newposition)
    } else if (this.editable && originalAsset instanceof THREE.Object3D) {
      const duplicateAsset = originalAsset.clone()
      duplicateAsset.wrapperId = 'wrapperObject'
      duplicateAsset.name = assetName
        ? assetName
        : this.generateName(asset.name)
      duplicateAsset.assetType = originalAsset.assetType
      duplicateAsset.url = originalAsset.url
      duplicateAsset.isGrouped = originalAsset.isGrouped
      duplicateAsset.assetId = originalAsset.assetId
      duplicateAsset.cartOnly = originalAsset.cartOnly
      duplicateAsset.locked = originalAsset.cartOnly
      duplicateAsset.subAssetType = originalAsset.subAssetType
      duplicateAsset.materialEditMetadata = originalAsset.materialEditMetadata

      //For asset Tiling functionality
      if (direction) {
        let tilingStatus = this.scene.tileAssetToDirection(
          direction,
          duplicateAsset,
          asset,
          newposition
        )
        if (tilingStatus.collided) {
          if (tilingStatus?.intersects[duplicateAsset.assetId]) {
            tilingStatus = { ...tilingStatus, status: 'error' }
          } else {
            tilingStatus = { ...tilingStatus, status: 'warning' }
          }
          onTilingErrorOrWarning(tilingStatus)
          if (tilingStatus?.intersects[duplicateAsset.assetId]) {
            return
          }
        } else {
          onSuccess({ ...tilingStatus, status: 'success' })
        }
      } else {
        duplicateAsset.position.set(
          asset.position.x + newposition,
          asset.position.y,
          asset.position.z + newposition
        )
      }
      this.scene.addObjectsByType(duplicateAsset)
      if (originalAsset.parent instanceof THREE.Group) {
        if (group) {
          group.add(duplicateAsset)
        } else {
          originalAsset.parent.add(duplicateAsset)
        }
      } else {
        this.scene.add(duplicateAsset)
      }
      if (!group) {
        this.attachTransFormControls(duplicateAsset)
      }
      this.selectedObject = duplicateAsset
      this.grpArray = [this.selectedObject.name]
      this.outlineObjects = [this.selectedObject.name]
      this.addBoxHelper(duplicateAsset)
      this.hoverElements = [this.selectedObject.name]
      if (addToStackFlag) {
        this.addToStack('DUPLICATE_ASSET', {
          asset: { ...asset },
          duplicateAsset: { ...duplicateAsset },
        })
      }
      this.processSceneEventData({
        event_type: direction ? 'TILE_ASSET' : 'DUPLICATE_ASSET',
        event_data: {
          previous_state: {
            name: asset.name,
            position: { ...asset.position },
            rotation: {
              _x: radToAng(asset.rotation._x),
              _y: radToAng(asset.rotation._y),
              _z: radToAng(asset.rotation._z),
            },
            scale: { ...asset.scale },
          },
          current_state: {
            name: duplicateAsset.name,
            position: { ...duplicateAsset.position },
            rotation: {
              _x: radToAng(duplicateAsset.rotation._x),
              _y: radToAng(duplicateAsset.rotation._y),
              _z: radToAng(duplicateAsset.rotation._z),
            },
            scale: { ...duplicateAsset.scale },
          },
        },
      })
      this.animate()
      this.triggerSceneChangeEvent()
      return { name: duplicateAsset.name }
    }
  }
  attachTransFormControls(asset) {
    if (!asset.locked) {
      let baseAsset = this.resetAssetObject(asset)
      this.attachSnappingHelpers(baseAsset)
      this.transformControls.attach(baseAsset)
    } else {
      this.transformControls.detach()
    }
    this.triggerSceneChangeEvent()
  }
  setAssetVisibility(assets, visibility = true, addToStackFlag = true) {
    if (this.editable) {
      assets.forEach((assetName) => {
        const selectedAsset = this.scene.getObjectByName(assetName)
        selectedAsset.visible = visibility
        if (selectedAsset.type === 'Group') {
          selectedAsset.visible = visibility
          selectedAsset.children.forEach((child) => {
            child.visible = visibility
          })
        }
        if (!visibility) {
          if (
            this.selectedObject !== null &&
            this.selectedObject.name === assetName
          ) {
            this.transformControls.detach()
            this.outlineObjects = this.outlineObjects.filter(
              (i) => i != assetName
            )
          }
          if (selectedAsset.parent instanceof THREE.Group) {
            this.attachTransFormControls(selectedAsset.parent)
            this.outlineObjects = [selectedAsset.parent.name]
          }
        }
      })
    }

    if (addToStackFlag) {
      this.addToStack('HIDE_ASSET', { assets: assets })
    }
    this.processSceneEventData({
      event_type: 'HIDE_ASSET',
      event_data: {
        previous_state: { name: assets[0], assets: assets, hidden: visibility },
        current_state: { name: assets[0], assets: assets, hidden: !visibility },
      },
    })

    this.triggerSceneChangeEvent()
    this.animate()
  }
  showAllAssets() {
    if (this.editable) {
      this.scene.traverse((child) => {
        if (
          child instanceof THREE.Object3D &&
          child.wrapperId === 'wrapperObject' &&
          (child.assetType === 'custom' ||
            child.assetType === 'TCIN' ||
            child.assetType === 'ARCHITECTURE' ||
            child.assetType === 'INFRASTRUCTURE' ||
            child.assetType === 'PID' ||
            child.assetType === 'PROP')
        ) {
          child.visible = true
          if (child.parent.type === 'Group') {
            child.parent.visible = true
          }
        }
        this.animate()
      })
    }
    this.triggerSceneChangeEvent()
  }
  setEditable(editable = false) {
    this.editable = editable
    if (!this.editable) {
      this.transformControls.detach()
      this.outlineObjects = []
      this.hoverElements = []
      this.highlightItems()
      this.animate()
    }
  }

  lockAsset(asset, locked = false, addToStackFlag = true) {
    asset.locked = locked
    if (locked) {
      this.transformControls.detach()
      this.outlineObjects = []
      this.hoverElements = []
      this.highlightItems()
    }
    this.animate()
    if (addToStackFlag) {
      this.addToStack('LOCK_ASSET', {
        asset: asset,
        locked: locked,
      })
    }
    this.processSceneEventData({
      event_type: 'LOCK_ASSET',
      event_data: {
        previous_state: { name: asset.name },
        current_state: { name: asset.name },
      },
    })
    this.triggerSceneChangeEvent()
  }

  changeCameraPosition(
    cameraPosition = { x: 0, y: 200, z: 0 },
    lockPosition = false,
    cameraTarget = {}
  ) {
    if (lockPosition) {
      try {
        let size
        if (this.scene.getObjectByName('template')) {
          let template = this.scene.getObjectByName('template')
          let box = new THREE.Box3().setFromObject(template)
          size = box.getSize()
        } else {
          size = this.computeTemplateSize()
        }

        let diagonal = Math.sqrt(size.x ** 2.2 + size.z ** 2.2)
        let diff = size.x / size.z
        let sensor =
          diff >= 1.2 ? this.camera.getFilmWidth() : this.camera.getFilmHeight()
        let distanceSensorToLens = this.camera.getFocalLength() / sensor
        let dHeight = distanceSensorToLens * diagonal
        // (diagonal / 2) / Math.tan((this.camera.fov/2) * Math.PI / 180) - formula 1
        // (1.5 * size.z * this.camera.getFocalLength()) /
        //   this.camera.getFilmHeight() - formula 2
        this.camera.position.set(
          cameraPosition.x,
          dHeight + size.y,
          cameraPosition.z
        )
        this.camera.zoom = diff > 1.2 ? 0.7 : 1
        this.camera.lookAt(new THREE.Vector3(0, 0, -15))
        this.controls.setTarget(0, 0, -15)
        this.controls.enableRotate = false
        this.setupControlsForTopView()
        this.camera.updateProjectionMatrix()
      } catch (e) {
        // console.log('an error occured', e)
      }
    } else {
      this.camera.position.set(
        cameraPosition.x,
        cameraPosition.y,
        cameraPosition.z
      )
      this.controls.enableRotate = true
      this.camera.lookAt(cameraTarget.x, cameraTarget.y, cameraTarget.z)
      this.controls.setTarget(cameraTarget.x, cameraTarget.y, cameraTarget.z)
      this.transformControls.enableAxis(true, true, true)
      this.transformControls.setTopView(false)
      this.camera.updateProjectionMatrix()
    }
    this.topView = lockPosition
    this.animate()
  }
  lockTemplateWalls = () => {
    let templateScene = this.scene.getObjectByName('template')
    if (templateScene) {
      templateScene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.side = THREE.DoubleSide
          child.material.side = THREE.DoubleSide
        }
      })
    }
    this.transformControls.detach()
    this.outlineObjects = []
    this.hoverElements = []
    this.highlightItems()
    this.renderMode = true
    this.targetplane.visible = true
    if (this.measuremode) {
      this.disableMeasurement()
    }
    //this.resizeScene(width, height)
    //this.animate()
  }
  unlockTemplateWalls = (setClipNear) => {
    let templateScene = this.scene.getObjectByName('template')
    if (templateScene) {
      templateScene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.side = THREE.FrontSide
          child.material.side = THREE.FrontSide
        }
      })
    }
    if (!setClipNear) {
      this.camera.near = 0.1
    }
    this.camera.far = 20000
    this.camera.updateProjectionMatrix()
    this.controls.update()
    this.transformControls.detach()
    this.outlineObjects = []
    this.hoverElements = []
    this.highlightItems()

    this.targetplane.visible = false
    this.renderMode = false
    //this.resizeScene(width, height)
  }

  addToStack = (type, data) => {
    const currentData = {
      type,
      data,
    }
    this.index++
    this.undoRedoStack.splice(this.index)
    this.undoRedoStack.push(currentData)
    this.setUndoRedoButtonStatus()
  }

  moveAsset = (assetName, position, rotation) => {
    let asset = this.scene.getObjectByName(assetName)
    let oldPosition = { ...asset.position }
    let oldRotation = { ...asset.rotation }
    let oldScale = { ...asset.scale }
    let { x, y, z } = position
    let { _x, _y, _z } = rotation
    asset && asset.position.set(x, y, z)
    asset && asset.rotation.set(_x, _y, _z)
    this.processSceneEventData({
      event_type: 'MOVE_ASSET',
      event_data: {
        previous_state: {
          asset_id: asset.assetId,
          name: asset.name,
          position: oldPosition,
          rotation: {
            _x: radToAng(oldRotation._x),
            _y: radToAng(oldRotation._y),
            _z: radToAng(oldRotation._z),
          },
          scale: oldScale,
        },
        current_state: {
          asset_id: asset.assetId,
          name: asset.name,
          position: { ...asset.position },
          rotation: {
            _x: radToAng(asset.rotation._x),
            _y: radToAng(asset.rotation._y),
            _z: radToAng(asset.rotation._z),
          },
          scale: { ...asset.scale },
        },
      },
    })
    this.animate()
  }

  scaleAsset = (scale = {}, assetName) => {
    let asset = null
    if (assetName) {
      asset = this.scene.getObjectByName(assetName)
    } else {
      if (!this.selectedObject) {
        return
      }
      asset = this.selectedObject
    }
    let prevScale = { ...asset.scale }
    this.processSceneEventData({
      event_type: 'SCALE_ASSET',
      event_data: {
        previous_state: {
          asset_id: asset.assetId,
          name: asset.name,
          scale: prevScale,
        },
        current_state: {
          asset_id: asset.assetId,
          name: asset.name,
          scale: { ...scale },
        },
      },
    })

    let { x = 1, y = 1, z = 1 } = scale
    asset.scale.set(x, y, z)
    this.animate()
  }

  prepareDataForAssetRecreation(data, group) {
    let {
      url,
      name,
      position,
      rotation,
      isGrouped,
      assetType,
      visible,
      assetId,
      subAssetType,
      scale,
      locked,
      isFlipped,
      materialEditMetadata,
    } = data
    this.addAssets({
      isFlipped,
      url,
      tcin: name,

      position,
      rotation,
      isGrouped,
      assetType,
      visibility: visible,
      assetId,
      name,
      subAssetType,
      scale,
      locked,
      materialEditMetadata,
    }).then((addedAsset) => {
      if (group) {
        group.add(addedAsset)
      }
      this.triggerSceneChangeEvent()
      this.triggerAssetAddedEvent()
      this.animate()
    })
  }

  doCmd = ({ type, data }) => {
    switch (type) {
      case 'MOVE_ASSET':
        data && this.moveAsset(data.assetName, data.position, data.rotation)
        this.transformControls.dispatchEvent({ type: 'change' })
        break
      case 'SNAP_ASSET':
        data && this.moveAsset(data.assetName, data.position, data.rotation)
        break
      case 'ADD_ASSET':
        {
          if (data.asset.type == 'Group') {
            let group = new THREE.Group()
            let grp = data.asset
            group.name = this.generateName(grp.name)
            group.position.set(grp.position.x, grp.position.y, grp.position.z)
            group.rotation.set(
              angToRad(grp.rotation._x),
              angToRad(grp.rotation._y),
              angToRad(grp.rotation._z)
            )
            group.scale.set(grp.scale.x, grp.scale.y, grp.scale.z)
            if (data.asset.locked) {
              group.locked = data.asset.locked
            }
            this.scene.add(group)
            this.addBoxHelper(group)
            grp.children.map((item) => {
              this.prepareDataForAssetRecreation(item, group)
            })
          } else {
            let { groupName } = data.asset
            let group = groupName ? this.scene.getObjectByName(groupName) : ''
            this.prepareDataForAssetRecreation(data.asset, group)
          }
          this.isDirty = true
        }
        break
      case 'DELETE_ASSET': {
        let asset = this.scene.getObjectByName(data.asset.name)
        asset && this.deleteAsset(asset, false)
        break
      }
      case 'SHOW_ASSET': {
        this.setAssetVisibility(data.assets, true, false)
        break
      }
      case 'HIDE_ASSET': {
        this.setAssetVisibility(data.assets, false, false)
        break
      }
      case 'DUPLICATE_ASSET': {
        this.duplicateAsset(data.asset, data.duplicateAsset.name, false)
        break
      }
      case 'UNGROUP_ASSETS': {
        this.groupDiscard(data.groupName, false)
        break
      }
      case 'GROUP_ASSETS': {
        this.initGroup(data.assets, data.groupName, false)
        break
      }
      case 'REPLACE_ASSET': {
        const { action } = data
        if (action === 'undo') {
          this.selectedObject = this.scene.getObjectByName(data.newAssetName)
          this.replaceAsset(data.oldAsset, this.selectedObject)
        } else if (action === 'redo') {
          this.selectedObject = this.scene.getObjectByName(data.oldAsset.name)
          this.replaceAsset(data.newAsset)
        }
        break
      }
      case 'SCALE_ASSET': {
        this.scaleAsset(data.scale, data.assetName)
        break
      }
      case 'FLIP_HORIZONTAL': {
        data && this.flipHorizontal(data, false)
        this.transformControls.dispatchEvent({ type: 'change' })
        break
      }
      case 'LOCK_ASSET': {
        this.lockAsset(data.asset, !data.locked, false)
      }
    }
  }

  undoCmd = () => {
    this.activateUndo = false
    if (this.index > -1) {
      let action = this.undoRedoStack[this.index]
      let type = ACTIONS[action.type]
      let data

      switch (action.type) {
        case 'MOVE_ASSET': {
          data = {
            assetName: action.data.assetName,
            position: action.data.prevState.position,
            rotation: action.data.prevState.rotation,
          }
          break
        }
        case 'SNAP_ASSET': {
          data = {
            assetName: action.data.assetName,
            position: action.data.prevState.position,
            rotation: action.data.prevState.rotation,
          }
          break
        }
        case 'DUPLICATE_ASSET': {
          data = { asset: action.data.duplicateAsset }
          break
        }
        case 'DUPLICATE_GROUP': {
          data = { asset: action.data.duplicateGroup }
          break
        }
        case 'REPLACE_ASSET': {
          data = {
            ...action.data,
            ...{ action: 'undo' },
          }
          break
        }
        case 'SCALE_ASSET': {
          data = {
            assetName: action.data.assetName,
            scale: action.data.prevState.scale,
          }
          break
        }

        case 'LOCK_ASSET': {
          data = {
            ...action.data,
          }
          break
        }
        case 'FLIP_HORIZONTAL': {
          data = {
            ...action.data,
          }
          break
        }
        default:
          data = action.data
          break
      }
      this.doCmd({ type, data })
      this.index = this.index - 1
      this.setUndoRedoButtonStatus()
    }
  }
  redoCmd = () => {
    this.activateRedo = false
    if (this.index < this.undoRedoStack.length - 1) {
      this.index = this.index + 1
      let action = this.undoRedoStack[this.index]
      let type = action.type
      let data
      switch (action.type) {
        case 'MOVE_ASSET': {
          data = {
            assetName: action.data.assetName,
            position: action.data.currState.position,
            rotation: action.data.currState.rotation,
          }
          break
        }
        case 'SNAP_ASSET': {
          data = {
            assetName: action.data.assetName,
            position: action.data.currState.position,
            rotation: action.data.currState.rotation,
          }
          break
        }
        case 'REPLACE_ASSET': {
          data = {
            ...action.data,
            ...{ action: 'redo' },
          }
          break
        }
        case 'SCALE_ASSET': {
          data = {
            assetName: action.data.assetName,
            scale: action.data.currState.scale,
          }
          break
        }
        case 'LOCK_ASSET': {
          data = {
            ...action.data,
          }
          break
        }
        case 'FLIP_HORIZONTAL': {
          data = {
            ...action.data,
          }
          break
        }
        default:
          data = action.data
          break
      }
      this.doCmd({ type, data })
      this.setUndoRedoButtonStatus()
    }
  }

  setUndoRedoButtonStatus = () => {
    this.activateUndo = this.index > -1 ? true : false
    this.activateRedo =
      this.index < this.undoRedoStack.length - 1 ? true : false
    this.triggerSceneChangeEvent()
  }

  removeAssetByTcin = (tcin) => {
    let Assets = []
    this.scene.traverse((child) => {
      if (
        child instanceof THREE.Object3D &&
        child.wrapperId === 'wrapperObject' &&
        (child.assetType === 'custom' ||
          child.assetType === 'TCIN' ||
          child.assetType === 'PID' ||
          child.assetType === 'PROP') &&
        child.assetId === tcin
      ) {
        Assets.push(child)
      }
    })
    Assets.map((asset) => this.deleteAsset(asset, false))
  }
  updateControls = (assetName) => {
    try {
      if (this.controlElements !== null && this.renderMode === false) {
        // const asset = this.scene.getObjectByName(assetName)

        const lockLink = this.controlElements.querySelector('li.lock')
        const hiddenElems = []

        let baseObject = this.selectedObject
        if (!this.selectedObject && this.grpArray.length) {
          baseObject = this.scene.getObjectByName(this.grpArray[0])
        }

        if (baseObject) {
          var elems = this.controlElements.querySelectorAll('li')
          elems.forEach(function (value) {
            value.style.display = 'flex'
          })

          if (baseObject instanceof THREE.Group) {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li.hideforgroup')
            )
          } else {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li.ungroup')
            )
          }

          if (this.transformControls.mode === 'translate') {
            hiddenElems.push(...this.controlElements.querySelectorAll('li.move'))
          } else {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li.rotate')
            )
          }
          if (this.swapmode) {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li:not(.cancelReplace)')
            )
          } else {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li.cancelReplace')
            )
          }

          if (this.scaling) {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li:not(.cancelScale)')
            )
          } else {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li.cancelScale')
            )
          }
          if (this.grpArray.length > 1) {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li:not(.multiMode)')
            )
          } else {
            hiddenElems.push(...this.controlElements.querySelectorAll('li.group'))
          }

          if (baseObject.visible) {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li.unhide')
            )
          } else {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li:not(.unhide)')
            )
          }

          if (this.grpArray.length !== 2) {
            hiddenElems.push(...this.controlElements.querySelectorAll('li.snap'))
          }

          let assetLocked = false

          if (this.grpArray.length > 1) {
            let lockedAsset = 0
            let unlockedAsset = 0

            this.grpArray.map((i) => {
              if (this.scene.getObjectByName(i)?.locked) {
                lockedAsset++
              } else {
                unlockedAsset++
              }
            })
            if (lockedAsset > 0 && unlockedAsset > 0) {
              assetLocked = 'indeterminate'
            } else if (lockedAsset > 0) {
              assetLocked = true
            } else if (unlockedAsset > 0) {
              assetLocked = false
            }
          } else {
            assetLocked = baseObject.locked
          }

          if (assetLocked == 'indeterminate') {
            hiddenElems.push(
              ...this.controlElements.querySelectorAll('li:not(.delete)')
            )
          }
          if (assetLocked === true) {
            lockLink.querySelector('span.MuiListItemText-primary').innerHTML =
              'Unlock'
            lockLink.querySelector('p').innerHTML =
              this.os == 'Mac' ? '&#8984;+&#8679;+l' : 'Ctrl+&#8679;+l'
            hiddenElems.push(
              ...this.controlElements.querySelectorAll(
                'li:not(.lock):not(.delete)'
              )
            )
          } else if (assetLocked === false) {
            lockLink.querySelector('span.MuiListItemText-primary').innerHTML =
              'Lock'
            lockLink.querySelector('p').innerHTML =
              this.os == 'Mac' ? '&#8984;+l' : 'Ctrl+l'
          }

          if (!baseObject.name || !baseObject.name.toLowerCase().includes('wallpanel')) {
            hiddenElems.push(...this.controlElements.querySelectorAll('li.tile'))

          }
          if (hiddenElems.length) {
            hiddenElems.forEach(function (value) {
              value.style.display = 'none'
            })
          }
        }

        if (baseObject && this.showContextMenu) {
          const tempV = new THREE.Vector3()
          const boxS = new THREE.Box3().expandByObject(baseObject)
          tempV.copy(boxS.getCenter()).project(this.camera)
          let x = (tempV.x * 0.5 + 0.5) * this.renderer.domElement.clientWidth
          let y = (tempV.y * -0.5 + 0.5) * this.renderer.domElement.clientHeight
          let windowWidth = window.innerWidth
          let windowHeight = window.innerHeight
          this.controlElements.style.display = 'block'
          this.controlElements.style.zIndex = 999
          let menuHeight = this.controlElements.offsetHeight + 30
          if (windowWidth - x < 250) {
            x = windowWidth - 250
          }
          if (this.scaling) {
            this.controlElements.style.display = 'none'
          }

          if (windowHeight - y < menuHeight) {
            y = windowHeight - menuHeight
          }
          this.controlElements.style.transform = `translate(0%, 0%) translate(${x + 12
            }px,${y - 30}px)`
        } else {
          this.controlElements.style.display = 'none'
        }
      }
    } catch (e) {
      console.log("on context menu", e)
    }
  }

  focusAsset(asset) {
    if (!asset) {
      return
    }
    const fitOffset = 1.5
    const box = new THREE.Box3()

    box.expandByObject(asset)

    const size = box.getSize(new THREE.Vector3())
    const center = box.getCenter(new THREE.Vector3())

    const maxSize = Math.max(size.x, size.y, size.z)
    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * this.camera.fov) / 360))
    const fitWidthDistance = fitHeightDistance / this.camera.aspect
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance)

    const direction = this.controls.target
      .clone()
      .sub(this.camera.position)
      .normalize()
      .multiplyScalar(distance)

    //this.controls.maxDistance = distance * 10;
    this.controls.target.copy(center)
    this.camera.lookAt(center)
    this.camera.zoom = 1
    this.camera.updateProjectionMatrix()

    this.camera.position.copy(this.controls.target).sub(direction)

    this.controls.update()
    this.animate()
  }

  replaceAsset = (assetData, currentObj) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      const selectedObj = currentObj || this.selectedObject
      //Add the new object
      const uid = new Date().valueOf()

      let tcinUrl = currentObj ? assetData.url : ''
      if (tcinUrl == '') {
        if (assetData.asset_type == 'PID') {
          let eventMessage
          try {
            tcinUrl = await fetchGalleryPreSignedGLBLink(assetData.asset_id)
            if (!tcinUrl || tcinUrl == '') {
              eventMessage = 'Lod profile is not available'
            }
          } catch (e) {
            eventMessage = 'failed to fetch LOD url from gallery'
          }
          if (eventMessage !== undefined) {
            createCustomEvent(TRACK_SCENE_EVENT, {
              type: 'AssetLoadingFailure',
              assetId: assetId,
              e: eventMessage,
            })
          }
        } else {
          tcinUrl = getAssetUrl(assetData.variations, 'low').tcinUrl
        }
      }

      const { _x, _y, _z, ...rest } = selectedObj.rotation
      //Add new asset to Group
      this.addAssets({
        url: tcinUrl,
        tcin: assetData.tcin || assetData.assetId,
        position: { ...selectedObj.position },
        rotation: {
          _x: radToAng(_x),
          _y: radToAng(_y),
          _z: radToAng(_z),
          ...rest,
        },
        uid,
        assetType: assetData.assetType || assetData.asset_type,
        addToStackFlag: false,
        name: assetData.name,
        subAssetType: assetData.subAssetType || assetData.sub_asset_type,
      })
        .then((res) => {
          createCustomEvent(TRACK_SCENE_EVENT, {
            type: 'ASSET_SWAP',
            assetId: `selected Asset - ${selectedObj.assetId} & new Asset ${assetData.asset_id}`,
            e: 'Action from scene editor',
          })
          if (selectedObj.parent instanceof this.THREE.Group) {
            // Meaning it is a grouped asset
            const replaceAsset = this.scene.getObjectByName(res.name)
            //First add the new asset to the group
            this.addToGroup(replaceAsset, selectedObj.parent, false)
            //Second remove the selected asset from the group

            this.removeFromGroup(selectedObj)
            //Third Delete the selected object
            !currentObj &&
              this.addToStack('REPLACE_ASSET', {
                newAssetName: res.name,
                oldAsset: { ...selectedObj, tcin: selectedObj.assetId },
                newAsset: { ...assetData },
              })

            this.deleteAsset(selectedObj, false)
          } else {
            !currentObj &&
              this.addToStack('REPLACE_ASSET', {
                newAssetName: res.name,
                oldAsset: { ...selectedObj, tcin: selectedObj.assetId },
                newAsset: { ...assetData },
              })
            //Delete the selected object
            this.deleteAsset(selectedObj, false)
          }

          this.processSceneEventData({
            event_type: 'REPLACE_ASSET',
            event_data: {
              previous_state: {
                name: selectedObj.name,
                asset_id: selectedObj.assetId,
              },
              current_state: {
                name: assetData.name || assetData.tcin,
                asset_id: assetData.assetId || assetData.tcin,
              },
            },
          })
          this.swapmode = false
          this.showContextMenu = false
          this.updateControls()
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  resetAssetObject = (asset) => {
    if (asset) {
      asset.traverseAncestors((parent) => {
        if (parent.wrapperId && parent.wrapperId === 'wrapperObject') {
          asset = parent
        }
      })
      return asset
    }
  }

  changeLocalWorldSpace = (space) => {
    this.transformControls.space = space
  }
  updateCameraMode = (value) => {
    let zoom = this.camera.zoom
    const PAN =
      zoom < 0.5 ? 10 : zoom < 1 ? 5 : zoom < 1.5 ? 3 : zoom < 2 ? 1 : 0.5
    const TILT = zoom < 1 ? 4 : zoom < 2 ? 3 : 2

    switch (value) {
      case PAN_LEFT:
        this.controls.pan(PAN, 0)
        break
      case PAN_RIGHT:
        this.controls.pan(-PAN, 0)
        break
      case PAN_UP:
        this.controls.pan(0, PAN)
        break
      case PAN_DOWN:
        this.controls.pan(0, -PAN)
        break
      case TILT_LEFT:
        this.controls.rotateHorizontally((-(2 * Math.PI) / 60 / 60) * TILT)
        break
      case TILT_RIGHT:
        this.controls.rotateHorizontally(((2 * Math.PI) / 60 / 60) * TILT)

        break
      case TILT_UP:
        this.controls.rotateVertically(((2 * Math.PI) / 60 / 60) * TILT)
        break
      case TILT_DOWN:
        this.controls.rotateVertically((-(2 * Math.PI) / 60 / 60) * TILT)
        break
      case ZOOM_IN:
        this.camera.zoom += ZF_STEP
        this.camera.updateProjectionMatrix()
        this.animate()
        break
      case ZOOM_OUT:
        if (this.camera.zoom - ZF_STEP > 0.01) {
          this.camera.zoom -= ZF_STEP
          this.camera.updateProjectionMatrix()
          this.animate()
        }
        break
      default:
        break
    }
  }

  attachSnappingHelpers(baseAsset) {
    this.snapper.setAssets(this.scene.getObjectsByType('ALL'))
    this.snapper.attach(baseAsset)
    document.addEventListener('keydown', this.keydownListenerforSnapper)
    document.addEventListener('keyup', this.keyupListenerforSnapper)
  }
  updateSnappingHelpers() {
    this.snapper.setAssets(this.scene.getObjectsByType('ALL'))
    this.snapper.update(this.resetAssetObject(this.selectedObject))
    this.sceneRender()
  }

  removeSnappingHelpers() {
    this.snapper.detach()
    this.snapper.enableDetection = false
    document.removeEventListener('keydown', this.keydownListenerforSnapper)
    document.removeEventListener('keyup', this.keyupListenerforSnapper)
    this.sceneRender()
  }
  keydownListenerforSnapper = (e) => {
    if (e.shiftKey) {
      this.snapper.enableDetection = true
      this.updateSnappingHelpers()
    }
  }
  keyupListenerforSnapper = (e) => {
    if (e.key === 'Shift') {
      this.snapper.enableDetection = false
      this.snapper.hidePlanes()
      this.sceneRender()
    }
  }

  snapOnAsset() {
    if (this.grpArray.length === 2) {
      const targetAsset = this.scene.getObjectByName(this.grpArray[1])
      const selecteAsset = this.scene.getObjectByName(this.grpArray[0])
      let targetBoundingBox = new THREE.Box3().setFromObject(targetAsset)
      let selectedBoundingBox = new THREE.Box3().setFromObject(selecteAsset)
      const { x: width, z: length } = targetBoundingBox.getSize()
      const prevPosition = new THREE.Vector3().copy(selecteAsset.position)
      const newPosition = new THREE.Vector3(
        targetBoundingBox.min.x + width / 2,
        targetBoundingBox.max.y +
          0.01 +
          (selecteAsset.position.y - selectedBoundingBox.min.y),
        targetBoundingBox.min.z + length / 2
      )
      selecteAsset.position.copy(newPosition)
      this.addToStack('SNAP_ASSET', {
        assetName: selecteAsset.name,
        prevState: { position: prevPosition, rotation: selecteAsset.rotation },
        currState: {
          position: newPosition,
          rotation: selecteAsset.rotation,
        },
      })
      this.processSceneEventData({
        event_type: 'SNAP_ASSET',
        event_data: {
          previous_state: {
            name: selecteAsset.name,
            asset_id: selecteAsset.assetId,
            position: { ...prevPosition },
            rotation: {
              _x: radToAng(selecteAsset.rotation._x),
              _y: radToAng(selecteAsset.rotation._y),
              _z: radToAng(selecteAsset.rotation._z),
            },
            scale: { ...selecteAsset.scale },
          },
          current_state: {
            name: selecteAsset.name,
            assetId: selecteAsset.assetId,
            position: { ...newPosition.position },
            rotation: {
              _x: radToAng(selecteAsset.rotation._x),
              _y: radToAng(selecteAsset.rotation._y),
              _z: radToAng(selecteAsset.rotation._z),
            },
            scale: { ...selecteAsset.scale },
          },
        },
      })
      this.animate()
    }
  }
  triggerAssetAddedEvent = () => {
    let event = new CustomEvent('addedAsset')
    document.dispatchEvent(event)
  }

  setupControlsForTopView = () => {
    // if (this.transformControls.getMode() === 'translate') {
    //   this.transformControls.enableAxis(true, false, true)
    // } else if (this.transformControls.getMode() === 'rotate') {
    //   this.transformControls.enableAxis(false, true, false)
    // }
    this.transformControls.setTopView(true)
  }
  flipHorizontal(asset, addToStackFlag = true, isLoadingFromJson = false) {
    createCustomEvent(TRACK_SCENE_EVENT, {
      type: 'ASSET_FLIP',
      assetId: asset.assetId,
      e: `Asset flip `,
    })
    let originalAsset = this.scene.getObjectByName(asset.name) || asset

    let prevScale = { ...originalAsset.scale }
    let prevFlip = originalAsset.isFlipped
    if (originalAsset) {
      originalAsset.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          let meshScale = child.scale
          let xAxis = meshScale.x * -1
          meshScale.set(xAxis, meshScale.y, meshScale.z)
          child.updateMatrixWorld()
        }
      })
      if (addToStackFlag) {
        originalAsset.isFlipped = !originalAsset.isFlipped
        this.addToStack('FLIP_HORIZONTAL', {
          ...originalAsset,
        })
      }
      if (!isLoadingFromJson) {
        this.processSceneEventData({
          event_type: 'FLIP_HORIZONTAL',
          event_data: {
            previous_state: {
              name: originalAsset.name,
              position: { ...originalAsset.position },
              rotation: {
                _x: radToAng(originalAsset.rotation._x),
                _y: radToAng(originalAsset.rotation._y),
                _z: radToAng(originalAsset.rotation._z),
              },
              scale: { ...prevScale },
              flipped: prevFlip,
            },
            current_state: {
              name: originalAsset.name,
              position: { ...originalAsset.position },
              rotation: {
                _x: radToAng(originalAsset.rotation._x),
                _y: radToAng(originalAsset.rotation._y),
                _z: radToAng(originalAsset.rotation._z),
              },
              scale: { ...originalAsset.scale },
              flipped: originalAsset.isFlipped,
            },
          },
        })
      }

      this.animate()
      this.triggerSceneChangeEvent()
    }
  }

  processSceneEventData = ({ event_type = '', event_data = {} }) => {
    event_data.time_stamp = new Date()
    this.sceneEventTracker.push({
      event_type,
      event_data,
    })
  }
  disableMeasurement = () => {
    this.measuremode = false
    this.controls.enabled = true
    this.addMouseMove()
    this.measurer.detach()
    this.triggerSceneChangeEvent()
  }
  enableMeasurement = () => {
    this.measuremode = true
    this.removeMouseMove()
    this.transformControls.detach()
    this.controls.enabled = false
    this.measurer.updateObjects([
      ...this.scene.getObjectsByType('ALL'),
      ...this.templateScene,
    ])
    createCustomEvent(TRACK_SCENE_EVENT, {
      type: 'MEASUREMENT_MODE',
      e: `Measure mode enable`,
    })
    this.measurer.attach()
    this.triggerSceneChangeEvent()
  }
  setEditType = (value) => {
    this.editType = value
    this.clearUndoRedoStack() //For clearing the UNDO/REDO action stack
    this.transformControls.detach()
    this.outlineObjects = []
    this.grpArray = []
    this.selectedObject = null
    this.animate()
  }

  clearUndoRedoStack = () => {
    this.undoRedoStack = []
    this.index = -1
    this.setUndoRedoButtonStatus()
  }

  switchColorTexture(asset, materialList, selectedTexture) {
    return new Promise((resolve, reject) => {
      const updatedMaterials = []
      materialList.forEach(({ updated, selected, ...material_data }) => {
        if (updated) {
          asset.traverse((child) => {
            if (child instanceof THREE.Mesh && child.name.includes('opq')) {
              this.applyColorTextureOnAsset(asset, child.material, {
                updated,
                ...material_data,
                ...selectedTexture,
              })
              updatedMaterials.push(material_data)
            }
          })
        }
      })
      if (
        !Array.isArray(asset.materialEditMetadata) ||
        !asset.materialEditMetadata.length
      ) {
        asset.materialEditMetadata = []
      }
      let targetMaterial = { ...updatedMaterials[0] }
      if (
        targetMaterial.color &&
        (targetMaterial.color !== '' || targetMaterial.color !== null)
      ) {
        targetMaterial.material_id = null
        targetMaterial.material_name = null
        targetMaterial.material_type = null
        targetMaterial.swatch_thumbnail_url = null
      } else {
        targetMaterial = selectedTexture
      }
      asset.materialEditMetadata[0] = {
        sourceMaterialMetadataList: [...updatedMaterials],
        updatedMaterialMetadata: targetMaterial,
        lowPolyMaterialType: 'OPAQUE',
      }
      resolve()
    })
  }

  applyColorTextureOnAsset(asset, material, material_data) {
    const { color, swatch_thumbnail_url } = material_data
    return new Promise((resolve, reject) => {
      // SOLID Color
      if (color && (color !== '' || color !== null)) {
        material.map = null
        material.color.set(new THREE.Color(color).convertGammaToLinear(2.2))
        material.needsUpdate = true

        this.animate()
        createCustomEvent(TRACK_SCENE_EVENT, {
          type: 'AssetColorTextureEdit',
          assetId: asset.assetId,
          e: `successfully applied color - ${color}`,
        })
        resolve()
      } else if (swatch_thumbnail_url && swatch_thumbnail_url !== '') {
        const url = `${config.renditionsUrl}/${swatch_thumbnail_url}`

        let loader = this.imageTextureLoader

        loader.setRequestHeaders(getRequestHeaders(config, url))
        loader.load(
          url,
          (texture) => {
            texture.encoding = THREE.sRGBEncoding
            material.map = texture
            material.color.set(new THREE.Color(1, 1, 1))
            material.needsUpdate = true
            this.animate()
            createCustomEvent(TRACK_SCENE_EVENT, {
              type: 'AssetColorTextureEdit',
              assetId: asset.assetId,
              e: `successfully applied texture - ${swatch_thumbnail_url}`,
            })
            resolve()
          },
          undefined,
          (error) => {
            createCustomEvent(TRACK_SCENE_EVENT, {
              type: 'AssetColorTextureEdit',
              assetId: asset.assetId,
              e: `failed to apply texture - ${swatch_thumbnail_url}, ${error.toString()}`,
            })
            console.log('error for texture', error)
            reject(error)
          }
        )
      }
    })
  }
  reloadAsset(asset) {
    return new Promise((resolve, reject) => {
      const assetData = this.scene.getAsset(asset)
      this.deleteAsset(asset, false)
      assetData.materialEditMetadata = []
      this.addAssets(assetData)
        .then((asset) => {
          this.selectAsset(asset.name)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
  setDoubleSided = (asset) => {
    asset.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.side = THREE.DoubleSide
        child.material.side = THREE.DoubleSide
      }
    })
  }

  computeTemplateSize() {
    let xList = [],
      yList = [],
      zList = []
    this.scene.traverse((child) => {
      if (
        child instanceof THREE.Object3D &&
        child.wrapperId === 'wrapperObject' &&
        child.parent instanceof THREE.Scene &&
        child.assetType === 'INFRASTRUCTURE'
      ) {
        let box = new THREE.Box3().setFromObject(child)

        let size = box.getSize()
        xList.push(size.x)
        yList.push(size.y)
        zList.push(size.z)
      }
    })

    let minX = Math.min(...xList)
    let maxX = Math.max(...xList)
    let minY = Math.min(...yList)
    let maxY = Math.max(...yList)
    let minZ = Math.min(...zList)
    let maxZ = Math.max(...zList)

    return {
      x: Math.abs(minX - maxX),
      y: Math.abs(minY - maxY),
      z: Math.abs(minZ - maxZ),
    }
  }
  setTilingMode(value = false) {
    this.tilingmode = value
  }
  tileAsset = (
    asset,
    direction = 'right',
    onErrorOrWarning = () => {},
    onSuccess = () => {}
  ) => {
    const selectedAssetBox = new THREE.Box3().setFromObject(asset)
    const {
      x: sizeX,
      y: sizeY,
      z: sizeZ,
    } = selectedAssetBox.getSize(new THREE.Vector3())
    let assetPlane = this.scene.findTilePlane(asset)
    let newPosition =
      direction === 'left' || direction === 'right'
        ? assetPlane === 'XY'
          ? sizeX
          : assetPlane === 'YZ'
          ? sizeZ
          : sizeY
        : sizeY

    this.duplicateAsset(
      asset,
      undefined,
      true,
      newPosition,
      false,
      direction,
      onErrorOrWarning,
      onSuccess
    )
  }
}

export default ThreeScene