import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual, isEmpty } from 'lodash'
import { withStyles } from '@mui/styles'
import { AssetCard } from 'cgi-ui-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileAlt,
  faSave,
  faShareSquare,
  faFolder,
  faCopy,
} from '@fortawesome/free-regular-svg-icons'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import {
  faAngleDown,
  faTrashAlt,
  faDownload,
  faSyncAlt,
  faPlusSquare,
} from '@fortawesome/free-solid-svg-icons'
import IconButton from '../button/iconButton'
import Template from '../../components/template'
import ShareExperienceDialog from '../shareExperienceDialog/ShareExperienceDialog'
import AddTcinDialog from '../addTcinDialog/AddTcinDialog'
import { showNotification } from '../../store/notification/actionCreator'
import { NotificationToast } from '../../components/Notifications/Notifications'

import {
  toggleTemplateModal,
  selectTemplate,
} from '../../store/template/actionCreator'

import { deleteScene } from '../../store/experience/ActionCreator'

import config from '../../config/config'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'

import { showBulkTcinDialog } from '../../store/board/actionCreator'
import { showExperienceForm } from '../../store/scene/ActionCreator'
import ConfirmationPopUp from '../modal/ConfirmationPopUp'
import ExperienceForm from './experienceForm'
import BrushIcon from '@mui/icons-material/Brush'
import ReplayIcon from '@mui/icons-material/Replay'
import RefreshIcon from '@mui/icons-material/Refresh'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import MenuPopover from '../MenuPopover/MenuPopover'
import { canUserWrite, isExternalUser } from '../../helpers/utils'
import HelpContent from '../HelpContent/HelpContent'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  Typography,
} from '@mui/material'

const styles = (theme) => ({
  root: {
    position: 'fixed',
    top: 5,
    backgroundColor: '#FFF',
    zIndex: 101,
    width: 500,
    left: 'Calc((100vw - 500px)/2)',
    borderRadius: 50,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  toolBar: {
    textAlign: 'center',
  },
  icon: {
    display: 'inline-block',
    padding: '0px 15px',
    '&.clearProjectName': {
      top: 16,
      right: 0,
      position: 'absolute',
      padding: '16px 12px',
      height: 24,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.sideNavHover,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.sideNavHover,
    },
  },
  renderInactive: {
    color: theme.palette.primary.grayLight,
  },
  activeIcon: {
    display: 'inline-block',
    padding: '0px 15px',
    backgroundColor: theme.palette.primary.sideNavHover,
  },
  iconsBar: {
    paddingTop: 11,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  exportText: {
    whiteSpace: 'nowrap',
  },
})
class EditorHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expName: '',
      saveExpDisabled: false,
      isEdit: false,
      openDialog: false,
      openDesignMenu: false,
      anchorEl: null,
      isOpenHelp: null,
      isEditFromTemplate: false,
      isCreateMode: false,
      openConfirm: false,
      experienceId: '',
      changeTemplate: false,
      loadedPercentage: 0,
      loadingMessage: '',
      showLoaderToast: false,
      isNotificationHidden: false,
      openCameraMenu: false,
      openRenderMenu: false,
      renderType: '',
      openRtaWarning: false,
      rtaAssets: [],
    }
    document.addEventListener('keyup', this.handleMultiKeyPress, false)
  }
  componentWillUnmount() {
    clearInterval(this.saveInterval)
    clearInterval(this.saveJsonInterval)
    document.removeEventListener('onAssetLoaded', this.showLoader)
    document.removeEventListener('keyup', this.handleMultiKeyPress, false)
  }

  handleMultiKeyPress = (event) => {
    const pressedKey = (event.key || '').toLowerCase()
    const ShiftKey = event.shiftKey
    if (ShiftKey && pressedKey === 'r') {
      event.preventDefault()
      this.handleResetCamera('default')
    }
    if (ShiftKey && pressedKey === 'f') {
      event.preventDefault()
      this.handleRenderFrame()
    }
  }

  handleResetCamera = (cameraName = 'default') => {
    const { selectCamera } = this.props
    selectCamera(cameraName, false, false)
    this.handleCloseMenu()
  }
  handleRenderFrame = () => {
    const { setRenderFrame } = this.props
    setRenderFrame()
    this.handleCloseMenu()
  }
  handleCamera = (e) => {
    this.setState({
      openCameraMenu: true,
      anchorEl: e.currentTarget,
    })
  }

  handleRenderMenu = (e) => {
    this.setState({
      openRenderMenu: true,
      anchorEl: e.currentTarget,
    })
  }

  componentDidMount = () => {
    const {
      sceneId,
      experienceDetail = {},
      selectTemplate = () => {},
      showExperienceForm,
    } = this.props
    this.setState({ isEdit: sceneId !== 'new' })
    if (sceneId === 'new') {
      showExperienceForm(true)

      this.setState({ isCreateMode: true })
    } else {
      this.setState({ expName: experienceDetail.experience_name })
    }
    selectTemplate({ templateId: '' })
    this.saveInterval = setInterval(() => {
      this.callSaveScene()
    }, config.autoSaveTime * (60 * 1000))

    document.addEventListener('onAssetLoaded', this.showLoader)
    document.addEventListener('keyup', this.handleMultiKeyPress, false)
  }

  showLoader = (e) => {
    const {
      detail: { total, loaded, failed },
    } = e
    let loadedPercentage = parseInt((loaded / total) * 100)
    loadedPercentage = isNaN(loadedPercentage) ? 0 : loadedPercentage
    let showLoaderToast = false
    if (loaded + failed !== total) {
      showLoaderToast = true
    }
    this.setState({
      loadedPercentage,
      loadingMessage: `loading scene... ${loadedPercentage}%`,
      showLoaderToast,
    })

    if (loadedPercentage === 100) {
      setTimeout(() => {
        this.setState({ showLoaderToast: false })
      }, 7000)
    }
  }

  hideNotification = () => {
    this.setState({ isNotificationHidden: true })
  }

  componentDidUpdate = (prevProps) => {
    const {
      sceneId,
      experienceDetail = {},
      shouldChangeTemplate = false,
    } = this.props

    if (shouldChangeTemplate) {
      this.handleChangeTemplate()
    }
    if (!isEqual(prevProps.experienceDetail, experienceDetail)) {
      this.setState({ isEdit: sceneId !== 'new' })
      this.setState({ expName: experienceDetail.experience_name })
    }
  }

  callSaveScene = () => {
    const { sceneId, saveScene, assetsLoading } = this.props
    const { saveExpDisabled: isSaving } = this.state
    if (sceneId && !isSaving && !assetsLoading) {
      this.setState({
        saveExpDisabled: true,
      })
      saveScene({ autosave: true })
        .then((res) => {
          this.setState({
            saveExpDisabled: false,
          })
        })
        .catch((e) => {
          this.setState({
            saveExpDisabled: false,
          })
        })
    }
  }

  handleNewExperience = () => {
    window.open('/experience/new')
  }

  handleRenderFrame = () => {
    const { setRenderFrame } = this.props
    setRenderFrame()
    this.handleCloseMenu()
  }
  handleSaveAs = () => {
    const { sceneId } = this.props
    this.handleCloseMenu()
    this.props.showExperienceForm(true, true)
  }

  handleOpenExperience = () => {
    window.open('/home/scenes')
  }

  handleDeleteExperience = () => {
    const { experienceDetail } = this.props
    const { experience_name = '', experience_id = '' } = experienceDetail
    this.setState({
      openConfirm: true,
      experienceId: experience_id,
    })
  }

  handleChangeTemplate = () => {
    const { toggleTemplateModal, toggleView } = this.props
    this.setState({ changeTemplate: true }, () => {
      this.handleCloseMenu()
      toggleView('3D')()
      toggleTemplateModal(true)
    })
  }

  toggleExperienceFormModal = (value) => {
    const { navigate, showExperienceForm } = this.props
    const { isEditFromTemplate, isEdit } = this.state

    if (!value && (!isEdit || isEditFromTemplate)) {
      navigate('/home/scenes')
    }
    showExperienceForm(value)
    this.setState({
      isEditFromTemplate: false,
    })
  }

  toggleExperienceForm = (value) => {
    this.props.showExperienceForm(value)
  }

  openHelp = (event) => {
    this.setState({
      isOpenHelp: event.currentTarget,
    })
  }

  showAddTcinDialog = (value) => {
    this.props.showBulkTcinDialog(value)
  }

  closeHelp = () => {
    this.setState({ isOpenHelp: null })
  }

  handleDesign = (e) => {
    this.setState({
      openDesignMenu: true,
      anchorEl: e.currentTarget,
    })
  }
  handleCamera = (e) => {
    this.setState({
      openCameraMenu: true,
      anchorEl: e.currentTarget,
    })
  }

  handleOpenSharingExperienceDialog = () => {
    this.setState({
      openDialog: true,
      openDesignMenu: false,
      openCameraMenu: false,
      openRenderMenu: false,
    })
  }
  handleMaxExportFile = () => {
    this.props.setRenderMode(true, 'images')
  }

  handleCloseSharingExperienceDialog = () => {
    this.setState({ openDialog: false })
  }

  handleCloseAddTcinDialog = () => {
    this.props.showBulkTcinDialog(false)
  }

  handleCloseMenu = () => {
    this.setState({
      openDesignMenu: false,
      openCameraMenu: false,
      openRenderMenu: false,
    })
  }

  handleTemplateBack = () => {
    this.toggleExperienceFormModal(true)
    this.setState({ isEdit: true, isEditFromTemplate: true })
  }

  loadTemplateToScene = (replaceTemplate = false) => {
    const {
      experience: { template_id: tempId, template_default: tempDef } = {},
      templateList,
      loadTemplate,
    } = this.props
    this.setState({ isCreateMode: false })
    const templateData = templateList.filter(
      ({ template_id }) => template_id === tempId
    )[0]
    if (templateData !== undefined) {
      const {
        template_draco_loc: templateUrl = '',
        scene_data: sceneData,
        high_low_poly_available: isHighLowAvailable,
        is_updated: isTemplateEdited,
      } = templateData
      const applyWorldRotationOnAssets = true
      if (templateUrl !== '') {
        loadTemplate(
          tempId,
          templateUrl,
          { scene_data: sceneData, template_default: tempDef },
          replaceTemplate,
          isHighLowAvailable,
          applyWorldRotationOnAssets,
          isTemplateEdited
        )
      }
    }
  }

  closeConfirmDialog = () => {
    this.setState({ openConfirm: false, experienceId: '' })
  }
  deleteSceneHandler = () => {
    const { experienceId } = this.state
    const { deleteScene, showNotification, navigate } = this.props
    deleteScene(experienceId)
      .then((response) => {
        showNotification(true, 'Scene has been deleted successfully', 'success')
        navigate('/home/scenes')
      })
      .catch(() => {
        showNotification(true, 'Deletion of scene failed', 'error')
      })
  }

  handleRender = (value) => {
    const {
      cartData: { scene_data: { assets = [] } = {}, allTcinMap = {} },
      Board: { assetObjects: boardAssetObjects },
    } = this.props
    let rtaAssets = []
    assets.forEach(({ assetId }) => {
      const cartAsset = allTcinMap[assetId]
      if (cartAsset) {
        const assetData = boardAssetObjects[cartAsset.originalAssetId]
        if (assetData && assetData.is_pure_rta_asset) {
          rtaAssets.push(assetData)
        }
      }
    })
    if (rtaAssets.length > 0 && value !== 'preview') {
      this.setState({
        openRtaWarning: true,
        renderType: value,
        rtaAssets: [...rtaAssets],
      })
    } else {
      this.handleRtaAssetWarningDialog(value)
    }
  }

  handleRtaAssetWarningDialog = (value) => {
    const {
      setRenderMode,
      history,
      experienceDetail,
      initiateFastRender = () => {},
    } = this.props

    if (value === 'render') {
      setRenderMode(true)
    } else if (value === 'preview') {
      history.push('/render/' + experienceDetail.experience_id)
    } else if (value === 'fastRender') {
      initiateFastRender()
    }
    this.handleCloseMenu()
    this.onRtaAssetWarningClose()
  }

  onRtaAssetWarningClose = () => {
    this.setState({
      openRtaWarning: false,
      rendererType: '',
      rtaAssets: [],
    })
  }

  isRenderActive() {
    const { experience: { template_id: sceneTemplate } = {} } = this.props
    return !isEmpty(sceneTemplate) && !this.state.saveExpDisabled
  }

  render() {
    const {
      classes,
      auth = {},
      experienceDetail = {},

      assetsLoading = false,
      undoAction = () => {},
      redoAction = () => {},
      Board,
      navigate = () => {},

      undoRedoButtonStatus: { activateUndo = false, activateRedo = false } = {},
      experience: { template_id: sceneTemplate } = {},
      rendererType,
      showRenderFrame,
      sceneId,
      sceneEditFormOpen,
      clipNearEnabled,
      toggleClipNearConfig,
      cameraKeys = [],
    } = this.props
    const {
      saveExpDisabled,

      isEdit,

      isCreateMode,
      openDesignMenu,
      openCameraMenu,
      openDialog,
      anchorEl,
      isEditFromTemplate,
      isOpenHelp,
      openConfirm,
      changeTemplate,
      showLoaderToast,
      loadingMessage,
      isNotificationHidden,
      openRenderMenu = false,
      openRtaWarning = false,
      renderType,
      rtaAssets,
    } = this.state

    const hasWriteAccess = canUserWrite(
      auth.lanId,
      experienceDetail.initiator,
      experienceDetail.members
    )
    const externalUser = isExternalUser(auth.memberOf)
    const preferedCamsFromStore = cameraKeys.filter(
      (item) => item.name !== 'Default Camera'
    )

    let prefMenuOptions = preferedCamsFromStore.map((item) => ({
      nameOfMenuItem: `${item.name}`,
      keyboardCmd: ``,
      onClickItem: () => this.handleResetCamera(`${item.key}`),
    }))

    let designMenuItemArray = [
      {
        icon: faFileAlt,
        nameOfMenuItem: 'New Scene',

        onClickItem: this.handleNewExperience,
      },
      {
        icon: faFolder,
        nameOfMenuItem: 'Open Scene',

        onClickItem: this.handleOpenExperience,
      },
      {
        icon: faTrashAlt,
        nameOfMenuItem: 'Delete Scene',
        onClickItem: this.handleDeleteExperience,
        hide: !hasWriteAccess,
      },
      {
        icon: faSave,
        nameOfMenuItem: 'Save Scene',

        onClickItem: this.callSaveScene,
        hide: !(isEdit && hasWriteAccess),
      },
      {
        icon: faCopy,
        nameOfMenuItem: 'Save as',

        onClickItem: this.handleSaveAs,
        hide: !(isEdit && hasWriteAccess),
      },
      {
        icon:
          sceneTemplate !== '' && sceneTemplate !== null
            ? faSyncAlt
            : faPlusSquare,
        nameOfMenuItem:
          sceneTemplate !== '' && sceneTemplate !== null
            ? 'Change Template'
            : 'Add a Template',
        onClickItem: this.handleChangeTemplate,
        hide: !(isEdit && hasWriteAccess),
      },
      {
        icon: faShareSquare,
        nameOfMenuItem: 'Share Scene',
        onClickItem: this.handleOpenSharingExperienceDialog,
        hide: !(isEdit && hasWriteAccess),
      },
      {
        icon: faDownload,
        nameOfMenuItem: 'Export Scene to .max file',
        onClickItem: this.handleMaxExportFile,
        textStyle: classes.exportText,
        hide: !hasWriteAccess,
        disabled: externalUser,
      },
    ]

    let designMenuCameraItemArray = [
      {
        nameOfMenuItem: 'Default Camera',
        keyboardCmd: `&#8679; + R`,
        onClickItem: () => {
          this.handleResetCamera('default')
        },
      },
      ...prefMenuOptions,
      {
        nameOfMenuItem: `${showRenderFrame ? 'Hide' : 'Show'} Render Frame`,
        keyboardCmd: `&#8679; + F`,
        onClickItem: this.handleRenderFrame,
      },
      {
        nameOfMenuItem: `${clipNearEnabled ? 'Disable' : 'Enable'} Clip Near`,
        keyboardCmd: ``,
        onClickItem: () => toggleClipNearConfig(!clipNearEnabled),
      },
    ]

    let designMenuRenderItemArray = [
      ...(rendererType === 'V-Ray'
        ? [
            {
              nameOfMenuItem: 'Fast Render',
              keyboardCmd: ``,
              onClickItem: () => {
                this.handleRender('fastRender')
              },
            },
          ]
        : []),
      {
        nameOfMenuItem: 'Take Render Shots',
        keyboardCmd: ``,
        onClickItem: () => this.handleRender('render'),
      },
      {
        nameOfMenuItem: `Preview Render Shots`,
        keyboardCmd: ``,
        onClickItem: () => this.handleRender('preview'),
      },
    ]

    const isRenderButtonActive = this.isRenderActive() && hasWriteAccess

    return (
      <div>
        <div className={classes.root} color="default">
          <div className={classes.headerWrapper}>
            <div className={classes.toolBar}>
              <div className={classes.iconsBar}>
                <div
                  className={openDesignMenu ? classes.activeIcon : classes.icon}
                >
                  <IconButton
                    icon={<BrushIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                    secondaryIcon={
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        size="1x"
                        color="grey"
                      />
                    }
                    text="SCENE"
                    onClick={this.handleDesign}
                  />
                </div>
                {hasWriteAccess && (
                  <>
                    <div className={classes.icon}>
                      <IconButton
                        disabled={!activateUndo}
                        icon={<ReplayIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                        text="UNDO"
                        onClick={undoAction}
                      />
                    </div>
                    <div className={classes.icon}>
                      <IconButton
                        disabled={!activateRedo}
                        icon={<RefreshIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                        text="REDO"
                        onClick={redoAction}
                      />
                    </div>
                  </>
                )}

                <div
                  className={
                    saveExpDisabled ? classes.activeIcon : classes.icon
                  }
                >
                  <IconButton
                    disabled={saveExpDisabled || assetsLoading}
                    icon={<SaveOutlinedIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                    text="SAVE"
                    onClick={this.callSaveScene}
                  />
                </div>
                <div className={classes.icon} tabIndex="3">
                  <IconButton
                    disabled={assetsLoading}
                    icon={<ImageOutlinedIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                    secondaryIcon={
                      isRenderButtonActive && (
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          size="1x"
                          color="grey"
                        />
                      )
                    }
                    text="RENDER"
                    className={
                      isRenderButtonActive ? '' : classes.renderInactive
                    }
                    onClick={this.handleRenderMenu}
                  />
                </div>
                <div className={classes.icon} tabIndex="4">
                  <IconButton
                    icon={
                      <CameraAltOutlinedIcon htmlColor="rgba(0, 0, 0, 0.6)" />
                    }
                    secondaryIcon={
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        size="1x"
                        color="grey"
                      />
                    }
                    text="CAMERA"
                    onClick={this.handleCamera}
                  />
                </div>

                <div className={classes.icon} tabIndex="5">
                  <IconButton
                    icon={
                      <HelpOutlineOutlinedIcon htmlColor="rgba(0, 0, 0, 0.6)" />
                    }
                    text="HELP"
                    onClick={this.openHelp}
                  />
                </div>

                <Template
                  onBack={this.handleTemplateBack}
                  onTemplateSelect={this.showAddTcinDialog}
                  navigate={navigate}
                  onUpdateTemplate={this.loadTemplateToScene}
                  isCreateMode={isCreateMode}
                  changeTemplate={changeTemplate}
                  sceneTemplate={sceneTemplate}
                />
              </div>
            </div>
          </div>
        </div>
        <HelpContent anchorEl={isOpenHelp} closeHelp={this.closeHelp} />

        {openDesignMenu ? (
          <MenuPopover
            openMenu={openDesignMenu}
            anchorEl={anchorEl}
            handleCloseMenu={this.handleCloseMenu}
            designMenuItemArray={designMenuItemArray}
          />
        ) : (
          ''
        )}
        {openCameraMenu ? (
          <MenuPopover
            openMenu={openCameraMenu}
            anchorEl={anchorEl}
            handleCloseMenu={this.handleCloseMenu}
            designMenuItemArray={designMenuCameraItemArray}
          />
        ) : (
          ''
        )}
        {isRenderButtonActive && openRenderMenu ? (
          <MenuPopover
            openMenu={openRenderMenu}
            anchorEl={anchorEl}
            handleCloseMenu={this.handleCloseMenu}
            designMenuItemArray={designMenuRenderItemArray}
          />
        ) : (
          ''
        )}
        {openDialog ? (
          <ShareExperienceDialog
            openDialog={openDialog}
            isDialog
            handleClose={this.handleCloseSharingExperienceDialog}
            experienceItem={experienceDetail}
          />
        ) : (
          ''
        )}
        {openConfirm ? (
          <ConfirmationPopUp
            handleCancel={this.closeConfirmDialog}
            handleClose={this.closeConfirmDialog}
            handleConfirm={this.deleteSceneHandler}
            open={openConfirm}
            descriptionText={`Confirm delete?`}
            helpingText="Action can't be undone"
            confirmText="Delete"
            cancelText="Cancel"
            isSceneDelete
          />
        ) : null}

        <AddTcinDialog
          open={Board.bulkTcinDialogVisible}
          handleClose={this.handleCloseAddTcinDialog}
          addedTcinDetailsToBoard={Board}
          navigate={navigate}
          handleStartStyling={this.loadTemplateToScene}
          isCreateMode={isCreateMode}
        />

        {sceneEditFormOpen && (
          <ExperienceForm
            open={sceneEditFormOpen}
            sceneId={sceneId}
            onClose={(ev, reason) => {
              reason == 'escapeKeyDown' && this.toggleExperienceFormModal(false)
            }}
            isEditFromTemplate={isEditFromTemplate}
            toggleForm={this.toggleExperienceForm}
          />
        )}

        {!isNotificationHidden && (
          <NotificationToast
            notificationIsShown={showLoaderToast}
            notificationVariant="info"
            showNotification={this.hideNotification}
            notificationMessage={loadingMessage}
          />
        )}
        <Dialog open={openRtaWarning} onClose={this.onRtaAssetWarningClose}>
          <DialogTitle>
            <Typography variant="h6">Warning!</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              There are a few assets present in the scene which don't have high
              poly (RTA Assets), do you want to continue?
            </Typography>

            <ImageList cols={4} rowHeight={130} sx={{ marginTop: '20px' }}>
              {(rtaAssets || []).map((asset) => {
                const {
                  product_description: {
                    title = '',
                    short_description = '',
                  } = {},
                } = asset
                const infoDetails = {
                  icon: InfoOutlinedIcon,
                  title: 'info',
                  data: [
                    {
                      label: 'Asset Name',
                      value:
                        short_description ||
                        asset.tcin ||
                        asset.value ||
                        asset.pid,
                    },
                    {
                      label: 'Product Type',
                      value: asset.product_type,
                    },
                    {
                      label: 'Description',
                      value: short_description,
                    },
                    { label: 'Brand', value: asset.brand },
                  ],
                }
                return (
                  <AssetCard
                    thumbnail={asset.thumbnail_url}
                    config={config}
                    name={
                      short_description ||
                      asset.tcin ||
                      asset.value ||
                      asset.pid
                    }
                    maxWidth={130}
                    loginId={auth.lanId}
                    minWidth={130}
                    noThumbnail={noThumbnail}
                    infoDetails={infoDetails}
                    isUrlAppendedWithImg
                    assetData={asset}
                    footerItems={[]}
                    errorDetails={{
                      high: asset.hp_available || true,
                      low: asset.lp_available || true,
                    }}
                    selectable={false}
                    isRtaAsset={asset.is_pure_rta_asset}
                    size={'small'}
                    isHighPolyCountAsset={asset.is_high_triangle_count}
                  />
                )
              })}
            </ImageList>
          </DialogContent>
          <DialogActions>
            <Button type="primary" onClick={this.onRtaAssetWarningClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => this.handleRtaAssetWarningDialog(renderType)}
            >
              {renderType === 'render' ? 'Take Render Shots' : 'Fast Render'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
const mapStateToProps = ({
  auth,
  Board,
  templates: { templateList, shouldChangeTemplate },
  experiences: { experience },
  scene: { sceneEditFormOpen, cameraKeys },
  cart,
}) => ({
  lanId: auth.lanId,
  email: auth.email,
  accessToken: auth.accessToken,
  Board,
  experience,
  templateList,
  shouldChangeTemplate,
  sceneEditFormOpen,
  cartData: cart,
  cameraKeys,
})

export const EditorHeaderWithStyles = withStyles(styles)(EditorHeader)
export default connect(mapStateToProps, {
  showNotification,
  toggleTemplateModal,
  showBulkTcinDialog,
  selectTemplate,
  deleteScene,
  showExperienceForm,
})(EditorHeaderWithStyles)
