import React, { useRef, useEffect } from 'react'

const IntersectionDetector = ({ callback }) => {
  const contentEndRef = useRef()
  useEffect(() => {
    if (!contentEndRef.current) return
    const observer = new IntersectionObserver(callback, {
      threshold: [0.1],
    })
    observer.observe(contentEndRef.current)
  }, [])

  return <div ref={contentEndRef} />
}
export default IntersectionDetector
