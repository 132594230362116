import * as React from 'react'
import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import AddIcon from '@mui/icons-material/Add'

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 352,
  margin: '0 15px',
  display: 'block',
  backgroundColor: theme.palette.primary.main,
  border: '1px solid #333333',
  color: theme.palette.secondary.main,
  borderRadius: 5,
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
  },
}))

export default function OutlinedCard({ onClick = () => {} }) {
  return (
    <Box>
      <StyledButton
        focusRipple
        style={{
          width: 250,
        }}
        onClick={onClick}
      >
        <AddIcon />
        <Typography variant="subtitle2" color="inherit">
          New Template
        </Typography>
      </StyledButton>
    </Box>
  )
}
