import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import DialogContentText from '@mui/material/DialogContentText'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import { connect } from 'react-redux'
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'
import ErrorSummary from './Summary'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CustomIconButton } from 'cgi-ui-components'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import BlockIcon from '@mui/icons-material/Block'
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined'
import { downloadTemplate } from '../../store/template/actionCreator'
import { readableDateAndTimeDigitFormat } from '../../helpers/dataFormats'

const useStyles = makeStyles(() => ({
  status: {
    whiteSpace: 'pre-wrap',
  },
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#CC0000',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const INGESTION_STATUS_DISPLAY_MAP = {
  'Upload failed': {
    backgroundColor: '#FCDEC5',
    Icon: CancelOutlinedIcon,
    iconColor: '#AE570F',
    descTxtColor: '#AE570F',
    colorCode: 'error',
  },
  'Uploaded with errors': {
    backgroundColor: '#FEFCE6',
    Icon: ErrorOutlineOutlinedIcon,
    iconColor: '#EDB508',
    descTxtColor: '#C38A04',
    colorCode: 'error',
  },
  'Upload in progress': {
    backgroundColor: '',
    Icon: AccessTimeIcon,
    iconColor: '#3D70D6',
    descTxtColor: '3D70D6',
    colorCode: 'info',
  },
  'Upload successful': {
    backgroundColor: '',
    Icon: CheckCircleOutlineOutlinedIcon,
    iconColor: '#32864E',
    descTxtColor: '32864E',
    colorCode: 'success',
  },
  other: {
    backgroundColor: '',
    Icon: BlockIcon,
    iconColor: '#CC0000',
    descTxtColor: '#CC0000',
    colorCode: 'info',
  },
}

export const VALID_STATUS = [
  'Upload failed',
  'Uploaded with errors',
  'Upload in progress',
  'Upload successful',
]
function MyUploads({
  loading,
  fullName,
  tableList = [],
  totalCount,
  handleUpdateFromListView = () => {},
  perPage,
  currentPage,
  downloadTemplate = () => {},
  openTemplateRefresh = () => {},
  openErrorSummary = () => {},
}) {
  const [page, setPage] = useState(currentPage)
  const [rowsPerPage, setRowsPerPage] = useState(perPage)
  const [searchVal, setSearchVal] = useState('')

  const classes = useStyles()

  useEffect(() => {
    setPage(currentPage - 1)
  }, [currentPage])

  const noSearchContent = (loading) => {
    return (
      <DialogContentText
        sx={{
          padding: 20,
          textAlign: 'center',
          fontSize: '1.4rem',
          fontWeight: 300,
          margin: 'auto',
        }}
      >
        {!loading && (
          <SearchOffRoundedIcon
            sx={{
              width: '120px',
              height: '120px',
              margin: 'auto',
              display: 'block',
            }}
          />
        )}
        <Typography variant={'h6'}>
          {loading ? 'Loading...' : 'No templates found'}
        </Typography>
        <Typography variant={'body2'}>
          {loading
            ? 'Please wait while your list is loading'
            : 'Please make sure you have Ingested/Refresh templates.'}
        </Typography>
      </DialogContentText>
    )
  }

  const handleChangePage = (event, newPage) => {
    if (rowsPerPage * (newPage + 1) > tableList.length) {
      handleUpdateFromListView({ type: 'pageUpdate', value: newPage + 1 })
    } else {
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    handleUpdateFromListView({
      type: 'perPageCount',
      value: parseInt(event.target.value, 10),
    })
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleTemplateRefresh = (template) => {
    openTemplateRefresh(template)
  }

  const constructStatusDisplay = (template) => {
    let { ingestion_status_description, ingestion_status } = template

    let ingestion_status_list = VALID_STATUS.includes(ingestion_status)
      ? ingestion_status
      : 'other'

    let { backgroundColor, iconColor, Icon, descTxtColor } =
      INGESTION_STATUS_DISPLAY_MAP[ingestion_status_list]

    return (
      <StyledTableCell
        className={classes.status}
        style={{
          backgroundColor,
        }}
      >
        <div style={{ width: 200 }}>
          <div style={{ display: 'flex' }}>
            <Icon fontSize="small" sx={{ color: iconColor }} />
            <span style={{ marginLeft: 10 }}>{ingestion_status || 'NA'}</span>
          </div>
          <p style={{ color: descTxtColor }}>{ingestion_status_description}</p>
        </div>
      </StyledTableCell>
    )
  }

  return (
    <>
      {tableList && tableList.length ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Template Name</StyledTableCell>
                  <StyledTableCell>New / Refresh Template</StyledTableCell>
                  <StyledTableCell>Upload Initiated By</StyledTableCell>
                  <StyledTableCell>Template QA Plugin status</StyledTableCell>
                  <StyledTableCell>Template Status</StyledTableCell>
                  <StyledTableCell>Uploaded Date & Time (CDT)</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((template) => (
                    <StyledTableRow key={template.name}>
                      <StyledTableCell component="th" scope="row">
                        {template.template_name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {template.new_ingestion
                          ? 'New Template'
                          : 'Template Refresh'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {template.initiator_display_name}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor:
                            template.qa_plugin_status === 'Not Run'
                              ? '#FCDEC5'
                              : '',
                        }}
                      >
                        {template.qa_plugin_status === 'Not Run'
                          ? 'NOT run'
                          : `Last ${template.qa_plugin_status.toLowerCase()} -
                        ${readableDateAndTimeDigitFormat(
                          template?.qa_plugin_last_run_date
                        )}`}
                      </StyledTableCell>

                      {constructStatusDisplay(template)}

                      <StyledTableCell>
                        {readableDateAndTimeDigitFormat(
                          template.ingestion_timestamp
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: 110 }}>
                        <CustomIconButton
                          size="Small"
                          color="primary"
                          label="Upload Summary"
                          onClick={() => {
                            openErrorSummary(template)
                          }}
                        >
                          <DescriptionOutlinedIcon />
                        </CustomIconButton>
                        <CustomIconButton
                          size="small"
                          label="Refresh"
                          onClick={(e) => handleTemplateRefresh(template)}
                        >
                          <SyncOutlinedIcon />
                        </CustomIconButton>
                        <CustomIconButton
                          size="small"
                          label="Download"
                          onClick={(e) =>
                            downloadTemplate(
                              template.template_name,
                              template.template_thumbnail_url
                            )
                          }
                        >
                          <FileDownloadOutlinedIcon />
                        </CustomIconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        noSearchContent(loading)
      )}
    </>
  )
}

const mapStateToProps = ({
  auth: { fullName = '', lanId = '' },
  uploadTemplate: {
    templateDetails: { templatesList, loading },
  },
} = {}) => {
  return {
    lanId,
    templatesList,
    fullName,
    loading,
  }
}

export default connect(mapStateToProps, { downloadTemplate })(MyUploads)
