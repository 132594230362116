import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import {
  fetchTemplates,
  toggleTemplateModal,
  selectTemplate,
} from '../../store/template/actionCreator'
import { updateExperience } from '../../store/experience/ActionCreator'
import { removeTcinsFromBoard } from '../../store/board/actionCreator'

import TemplateDialog from '../../components/template/templateDialog'
import { OverlayLoader } from '../../components/Loaders'
import { canUserWrite } from '../../helpers/utils'
import { removeMultipleAssets } from '../designSpace'
import TemplateAssetErrorOverlay from '../Overlay/TemplateAssetErrorOverlay'
import { ERROR_MSG_FOR_SELECTING_TEMPLATE } from '../../constants/displayMessages'

const styles = (theme) => ({})

class Template extends React.Component {
  state = {
    showOverlayLoader: false,
    loaderDisplayText: '',
    templateExperienceData: {},
    openErrorPopup: false,
    assetErrorDetails: {},
  }
  toggleTemplateModal = (bool) => {
    this.props.toggleTemplateModal(bool)
    if (bool) {
      this.props.fetchTemplates()
    }
  }
  setLoader = (loaderStatus) => {
    this.setState({
      ...loaderStatus,
    })
  }
  onSelectingTemplate = ({
    experienceData,
    templateErrors,
    assetErrorDetails,
    ...rest
  }) => {
    if (templateErrors != null && templateErrors.length) {
      this.setState({
        templateExperienceData: experienceData,
        errorDetails: templateErrors,
        openErrorPopup: true,
        assetErrorDetails,
      })
    }
  }

  saveTemplateForExperience = () => {
    const {
      changeTemplate,
      updateExperience,
      onUpdateTemplate,
      onTemplateSelect,
      removeTcinsFromBoard,
      boardId,
      allBoardAssets,
    } = this.props

    const { templateExperienceData } = this.state
    updateExperience(
      templateExperienceData,
      () => {
        this.setState({
          loading: false,
          openErrorPopup: false,
          templateExperienceData: {},
          errorDetails: [],
          assetErrorDetails: {},
        })

        toggleTemplateModal(false)

        if (changeTemplate) {
          const toRemoveFromBoard = allBoardAssets
            .filter((item) => item.sub_asset_type === 'TEMPLATE')
            .map((item) => item.value)
          const deleteData = {
            board_id: boardId,
            assets: [...toRemoveFromBoard],
          }
          removeTcinsFromBoard(deleteData)
            .then(() => {
              removeMultipleAssets([...toRemoveFromBoard])
              onUpdateTemplate(true)
            })
            .catch((e) => {
              //console.log(e)
            })
        } else {
          onTemplateSelect(true)
        }
      },
      (error) => {
        this.setState({ loading: false })
        this.props.showNotification(
          true,
          ERROR_MSG_FOR_SELECTING_TEMPLATE,
          'error'
        )
      }
    )
  }

  onCancel = () => {
    const { toggleTemplateModal } = this.props
    this.setState({ openErrorPopup: false }, () => {
      toggleTemplateModal(true)
    })
  }

  render() {
    const {
      templates = {},
      experience,
      lanId,
      onBack,
      onTemplateSelect,
      navigate,
      ...rest
    } = this.props
    const {
      showOverlayLoader = false,
      loaderDisplayText = 'Loading',
      openErrorPopup,
      errorDetails,
      assetErrorDetails,
      loading,
    } = this.state
    const hasWriteAccess = canUserWrite(
      lanId,
      experience.initiator,
      experience.members ? experience.members : []
    )
    return (
      <>
        {templates.templateModalOpen && (
          <TemplateDialog
            onBack={onBack}
            templateModalOpen={hasWriteAccess && templates.templateModalOpen}
            toggleTemplateModal={this.toggleTemplateModal}
            templateData={templates}
            experience={experience}
            onSelectTemplate={this.props.updateExperience}
            onSelectErrorTemplate={this.onSelectingTemplate}
            setLoader={this.setLoader}
            onTemplateSelect={onTemplateSelect}
            navigate={navigate}
            {...rest}
          />
        )}
        {showOverlayLoader ? (
          <OverlayLoader displayText={loaderDisplayText} />
        ) : null}

        <TemplateAssetErrorOverlay
          isOpen={openErrorPopup}
          errors={errorDetails}
          assetErrorDetails={assetErrorDetails}
          isLoading={loading}
          onCancel={() => this.onCancel()}
          onContinue={() => this.saveTemplateForExperience()}
        />
      </>
    )
  }
}

// const mapStateToProps = ({
//   templates: { selectedTemplate, businessDepts },
//   Board: { assets, board_id: boardId },
//   experiences: { experience },
// }) => ({
//   selectedTemplateData: { ...selectedTemplate },
//   boardAssets: assets ? assets.map((item) => item.value) : [],
//   experience,
//   boardId,
//   allBoardAssets: assets,
//   businessDepts,
// })

const mapStateToProps = ({
  templates,
  experiences: { experience },
  auth,
  Board: { assets, board_id: boardId },
}) => ({
  templates,
  lanId: auth.lanId,
  experience,
  boardId,
  allBoardAssets: assets,
  boardAssets: assets ? assets.map((item) => item.value) : [],
})

const mapDispatchToProps = {
  fetchTemplates,
  updateExperience,
  selectTemplate,
  toggleTemplateModal,
  removeTcinsFromBoard,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Template))
