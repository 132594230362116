import React, { useEffect, useState, useRef } from 'react'

import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'

import { Box, CircularProgress } from '@mui/material'
import _, { debounce } from 'lodash'
import { AssetCard, Typography } from 'cgi-ui-components'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import LinkIcon from '@mui/icons-material/Link'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded'
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'

import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { getCurrentOS, OS } from '../../helpers/utils'
import { copyToClipboard } from '../../helpers/copyToClipboard'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DataList from '../DataList'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import {
  fetchAssets,
  clearAssets,
  handlefilterChange,
  showSimilarSearchResults,
  clearSimilarAssets,
  showVariationAssets,
} from '../../store/explorer/actionCreator'
import StyledAssets from './StyledList'
import SimilarAssets from './SimilarAssets'
import ExplorerFooter from './Footer'
import config from '../../config/config'

import ProductsAndPropsFilters from '../ProductsAndPropsFilters'
import FilterHeaderWrapper from '../ProductsAndPropsFilters/FilterHeaderWrapper'
import SummaryList from '../Filter/SummaryList'
import MaxAssetCard from '../assetExplorerCard/MaxAssetCard'
import { mapInfoForCard } from '../../helpers/utils'

const styles = (theme) => ({
  card: {
    margin: '0px 10px',
    marginBottom: '20px',
  },
  filters: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: 200,
  },
  listcontainer: { height: 'Calc(100% - 15px)' },
  noSearchResultContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  noSearchResultContent: {
    width: 'auto',
    textAlign: 'center',
  },
})

class AssetExplorer extends React.Component {
  state = {
    isCopied: false,
    loading: false,
    pageNum: this.props.isSceneEditor ? this.props.pageNum : 0,
    perPage: 20,
    searchVal: this.props.isSceneEditor ? this.props.searchVal : '',
    subAssetId: '',
    subAssetType: '',
    assetType: '',

    selectedAssets: {},
    search_by: this.props.isSceneEditor ? this.props.search_by : 'All',
    filterApplied: this.props.isSceneEditor ? this.props.filterApplied : 0,
    excludeHighPoly: this.props.isSceneEditor
      ? this.props.excludeHighPoly
      : true,
    excludeRTA: this.props.isSceneEditor ? this.props.excludeRTA : true,
    sortByArray: [
      {
        name: 'Recently launched TCINs',
        value: JSON.stringify({ launch_time: 'DESC' }),
      },
      {
        name: 'Older launched TCINs',
        value: JSON.stringify({ launch_time: 'ASC' }),
      },
      {
        name: 'Recently ingested assets',
        value: JSON.stringify({ time_stamp: 'DESC' }),
      },
      {
        name: 'Older ingested assets',
        value: JSON.stringify({ time_stamp: 'ASC' }),
      },
    ],
    sort_fields: this.props.isSceneEditor
      ? this.props.sortBy
      : JSON.stringify({ time_stamp: 'DESC' }),
  }
  cancelController = new AbortController()

  componentDidMount() {
    const { clearAssets = () => {}, setDeselectAssets = () => {} } = this.props
    if (!this.props.isSceneEditor) {
      clearAssets()
      this.setState(
        {
          pageNum: 1,
          searchVal: '',
        },
        () => {
          this.getAssets(true)
        }
      )
    }
    setDeselectAssets(this.deSelectAssets)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.sceneMode !== this.props.sceneMode &&
      this.props.isSceneEditor
    ) {
      const { clearAssets = () => {}, setDeselectAssets = () => {} } =
        this.props

      clearAssets()
      this.setState(
        {
          pageNum: 1,
          searchVal: '',
          search_by:
            this.props.sceneMode === 'ARCHITECTURE' ? 'ARCHITECTURE' : 'All',
        },
        () => {
          this.getAssets(true)
        }
      )
      setDeselectAssets(this.deSelectAssets)
    }
  }

  interSectionCallback = (entries, observer) => {
    const { totalAssetsCount, assetList } = this.props

    const { pageNum, loading } = this.state
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (!loading && assetList.length < totalAssetsCount) {
          this.setState({ pageNum: pageNum + 1 }, () => this.getAssets())
        }
      }
    })
  }

  getAssets = (getFilters = false, skipLoading = false) => {
    const {
      fetchAssets,
      totalAssetsCount,
      assetList,
      filterLists,
      isSceneEditor = false,
      filterType,
      isArchAssets,
    } = this.props

    const {
      pageNum = 1,
      search_by = '',
      searchVal = '',
      perPage,
      loading,
      excludeHighPoly,
      excludeRTA,
      sort_fields,
    } = this.state

    if (!loading || skipLoading) {
      this.setState({ loading: true })
      let filters = {}
      if (filterType === search_by) {
        Object.keys(filterLists).map((filterKey) => {
          const filter = filterLists[filterKey]
          for (let index = 0; index < filter.length; index++) {
            const facet = filter[index]
            if (facet.isSelected) {
              const existingValues = filters[filterKey]
                ? filters[filterKey]
                : []
              filters[filterKey] = [...existingValues, facet.name]
            }
          }
        })
      }

      this.cancelController = new AbortController()
      fetchAssets(
        perPage,
        pageNum,
        searchVal,
        isArchAssets ? 'ARCHITECTURE' : search_by,
        filters,
        isSceneEditor ? false : getFilters,
        excludeHighPoly,
        {},
        this.cancelController,
        sort_fields,
        excludeRTA,
        isArchAssets
      )
        .then((resp) => {
          if (resp?.code !== 'ERR_CANCELED') {
            this.setState({ loading: false })
          }
        })
        .catch((e) => {
          this.setState({ loading: false })
        })
    }
  }

  searchAssets = debounce((searchVal) => {
    this.cancelController.abort()
    this.setState({ pageNum: 1, searchVal }, () => {
      this.props.clearAssets()
      if (this.props.isSceneEditor) {
        this.props.setSearchVal(searchVal)
      }
      this.closeSubAssetView()
      this.getAssets(false, true)
    })
  }, 300)

  clearSearch = () => {
    this.cancelController.abort()
    this.setState({ pageNum: 1, searchVal: '' }, () => {
      this.props.clearAssets()
      this.getAssets(false, true)
    })
  }

  copyPath = (option) => {
    const os = getCurrentOS()
    if (os === OS.WINDOWS) {
      copyToClipboard(option.windows_hp_path)
    } else if (os === OS.MACOS) {
      copyToClipboard(option.mac_hp_path)
    } else {
      copyToClipboard(option.mac_hp_path)
    }
    this.setState({ isCopied: true })
    setTimeout(() => {
      this.setState({ isCopied: false })
    }, 10000)
  }

  handleFilters = (filterkey, index, name, checked) => {
    let filterApplied = this.state.filterApplied
    if (checked) {
      filterApplied = filterApplied + 1
    } else {
      filterApplied = filterApplied - 1
    }

    this.cancelController.abort()

    this.setState({ pageNum: 1, filterApplied }, () => {
      this.props.clearAssets()
      this.closeSubAssetView()
      this.getAssets(false, true)
    })
  }

  handelAssetChange = (assetType) => {
    // Re compute sortBy field when PROPs are displayed
    let reComputedSortBy =
      assetType === 'PROP'
        ? JSON.stringify({ time_stamp: 'DESC' })
        : this.state.sort_fields

    this.setState(
      {
        pageNum: 1,
        search_by: assetType,
        sort_fields: reComputedSortBy,
      },
      () => {
        if (this.props.isSceneEditor) {
          this.props.setSearchBy(assetType)
        }
        this.closeSubAssetView()
        this.props.clearAssets()
        this.getAssets(false)
      }
    )
  }

  setSubAsset = (asset, variant) => {
    this.props.clearSimilarAssets()
    this.props.showSimilarSearchResults(false, {})
    this.props.showVariationAssets(true, { ...asset, variant })
  }
  closeSubAssetView = () => {
    this.props.showVariationAssets(false, {})
    this.props.clearSimilarAssets()
    this.props.showSimilarSearchResults(false, {})
  }
  searchSimilarAssets = (data) => {
    this.props.showVariationAssets(false, {})

    this.props.showSimilarSearchResults(true, data)
  }

  selectAsset = (key, value, add) => {
    const { selectedAssets } = this.state
    const newValue = { ...selectedAssets }
    if (add) {
      newValue[key] = value
    } else {
      delete newValue[key]
    }
    this.setState({ selectedAssets: { ...newValue } })
  }

  deSelectAssets = () => {
    this.setState({ selectedAssets: {} })
  }

  removeFilter = (filterKey, id) => {
    this.props.handlefilterChange(filterKey, id, false).then(() => {
      this.handleFilters()
    })
  }

  handleExcluded = (e, type) => {
    this.cancelController.abort()
    if (type === 'HP') {
      this.setState({ pageNum: 1, excludeHighPoly: e.target.checked }, () => {
        this.handleExcludedCallback(e, type)
      })
    } else if (type === 'RTA') {
      this.setState({ pageNum: 1, excludeRTA: e.target.checked }, () => {
        this.handleExcludedCallback(e, type)
      })
    }
  }

  handleExcludedCallback = (e, type) => {
    if (this.props.isSceneEditor) {
      type === 'HP' && this.props.setExcludeHighPoly(e.target.checked)
      type === 'RTA' && this.props.setExcludeRTA(e.target.checked)
    }
    this.props.clearAssets()
    this.getAssets(false, true)
    this.closeSubAssetView()
  }

  noSearchContent = () => {
    const { classes } = this.props
    const { searchVal } = this.state
    return (
      <div className={classes.noSearchResultContainer}>
        <div className={classes.noSearchResultContent}>
          <SearchOffRoundedIcon
            sx={{
              width: '120px',
              height: '120px',
              margin: 'auto',
              display: 'block',
            }}
          />
          <Typography
            variant={'h6'}
            label={
              <span>
                No results found
                {searchVal !== '' ? (
                  <>
                    for
                    <span className={classes.resultKeyword}>
                      " {searchVal}"
                    </span>
                  </>
                ) : (
                  ''
                )}
              </span>
            }
          />
          {searchVal !== '' ? (
            <Typography
              variant={'body2'}
              label={`Please make sure your words are spelled correctly or use different keywords.`}
            />
          ) : null}
        </div>
      </div>
    )
  }

  handleSort = (e) => {
    this.setState({ pageNum: 1, sort_fields: e.target.value }, () => {
      this.props.clearAssets()
      this.getAssets(false, true)
    })
  }

  render() {
    const {
      classes,
      assetList,
      size = 'medium',
      footerOptions = {
        buttonType: 'simple',
        buttonLabel: 'Add',
        buttonAction: () => {},
        options: [{ name: '', action: () => {} }],
      },
      libraryAssetsCount,
      showSimilarSearch = true,
      showVariations = true,
      showCopyPath = false,
      showAddRemoveIcon = true,
      addIconLabel = 'Add',
      removeIconLabel = 'Remove',
      addCallback = () => {},
      removeCallback = () => {},
      checkboxColor = 'tertiary',
      addedAssets = {},
      filterLists,
      onModalClose,
      isDialogDisplay = false,
      onMinimize,
      isSceneEditor = false,
      openSimilarSearch,
      similarAssetParent,
      showVariationView,
      variationTcin,
      handleCartActions,
      filterType,
      hideGalleryOption = false,
      isSubmitting = false,
      currentProduct = '',
      isArchAssets = false,
    } = this.props
    const {
      loading,

      assetType,

      selectedAssets,
      filters,
      isCopied = false,
      filterApplied,
      excludeHighPoly,
      searchVal,
      search_by,
      sortByArray = [],
      sort_fields,
      excludeRTA,
    } = this.state

    let subAssetId = ''
    if (openSimilarSearch && similarAssetParent.tcin) {
      subAssetId = similarAssetParent.tcin
    }
    if (showVariationView) {
      subAssetId = variationTcin.tcin
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <FilterHeaderWrapper
          totalAssets={libraryAssetsCount}
          marginBottom={10}
          handelAssetChange={this.handelAssetChange}
          handleFilters={this.handleFilters}
          handleSort={(e) => this.handleSort(e)}
          onModalClose={onModalClose}
          onMinimize={onMinimize}
          onSearch={this.searchAssets}
          searchVal={searchVal}
          clearSearch={this.clearSearch}
          selectedAsset={search_by}
          hideGalleryOption={hideGalleryOption}
          sortByArray={sortByArray}
          sortBy={sort_fields}
          isArchAssets={isArchAssets}
        />
        <div style={{ flexGrow: 1, overflow: 'hidden' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'stretch',
              flexDirection: 'row',
              height: isDialogDisplay ? `calc(100vh - 180px)` : '100%',
            }}
          >
            <div className={classes.filters}>
              {filterType === search_by && (
                <ProductsAndPropsFilters
                  handleFilters={this.handleFilters}
                  selectedFilters={filters}
                  handleExcluded={this.handleExcluded}
                  excludeHighPoly={excludeHighPoly}
                  excludeRTA={excludeRTA}
                  searchBy={search_by}
                />
              )}
            </div>

            <div
              style={{
                marginTop: 16,
                display: 'flex',
                flexDirection: 'column',
                width:
                  showVariationView || openSimilarSearch
                    ? 'Calc(100% - 760px)'
                    : 'Calc(100% - 200px)',
              }}
            >
              {filterApplied > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '75%' }}>
                    <SummaryList
                      filterLists={filterLists}
                      handleFilters={this.removeFilter}
                    />
                  </div>
                </Box>
              )}
              <Box
                sx={{
                  marginTop: filterApplied > 0 ? '20px' : '0',
                  flexGrow: 1,
                  overflow: 'hidden',
                }}
              >
                <DataList
                  showProgressBar={loading}
                  interSectionCallback={this.interSectionCallback}
                  containerClass={classes.listcontainer}
                >
                  {assetList &&
                    assetList.map((item) => {
                      const infoDetails = {
                        icon: InfoOutlinedIcon,
                        title: 'info',
                        data: mapInfoForCard(item),
                      }
                      const footerItems = []

                      if (showAddRemoveIcon) {
                        if (!addedAssets[item.tcin || item.value || item.pid]) {
                          footerItems.push({
                            icon:
                              isSubmitting && currentProduct === item.tcin
                                ? CircularProgress
                                : AddCircleOutlineRoundedIcon,
                            onClick: () => addCallback(item),
                            title: addIconLabel,
                          })
                        } else {
                          footerItems.push({
                            icon:
                              isSubmitting && currentProduct === item.tcin
                                ? CircularProgress
                                : RemoveCircleOutlineIcon,
                            onClick: () => removeCallback(item),
                            title: removeIconLabel,
                          })
                        }
                      }
                      if (
                        showCopyPath &&
                        item.asset_type !== 'PID' &&
                        !item.is_pure_rta_asset
                      ) {
                        footerItems.push({
                          icon: LinkIcon,
                          onClick: () => this.copyPath(item),
                          title: isCopied ? 'Link Copied' : 'Copy path',
                        })
                      }
                      if (item.has_styled_assets && showVariations) {
                        footerItems.push({
                          icon: StyleOutlinedIcon,
                          onClick: () => {
                            this.setSubAsset(item, 'STYLED')
                          },
                          title: 'Show styled versions',
                        })
                      }
                      if (item.has_unbundled_assets && showVariations) {
                        footerItems.push({
                          icon: LayersOutlinedIcon,
                          onClick: () => {
                            this.setSubAsset(item, 'UNBUNDLED')
                          },

                          title: 'Show unbundled versions',
                        })
                      }
                      if (
                        showSimilarSearch &&
                        item.asset_type != 'ARCHITECTURE'
                      ) {
                        footerItems.push({
                          icon: ImageSearchRoundedIcon,
                          onClick: () => {
                            if (item.asset_type !== 'PID') {
                              this.searchSimilarAssets(item)
                            }
                          },

                          title: 'Search similar assets',
                        })
                      }
                      return (
                        <div className={classes.card}>
                          {isSceneEditor ? (
                            <MaxAssetCard
                              data={item}
                              selectable
                              onSelect={(e) => {
                                this.selectAsset(
                                  item.tcin ||
                                    item.value ||
                                    item.pid ||
                                    item.asset_id,
                                  item,
                                  e.target.checked
                                )
                              }}
                              selected={
                                selectedAssets[
                                  item.tcin ||
                                    item.value ||
                                    item.pid ||
                                    item.asset_id
                                ] !== undefined
                              }
                              highLight={
                                subAssetId === item.tcin ||
                                subAssetId === item.value
                              }
                              showSubAsset
                              showSimilarAsset
                              openSimilarAssets={this.searchSimilarAssets}
                              openSubAssets={this.setSubAsset}
                              handleCartActions={handleCartActions}
                            />
                          ) : (
                            <AssetCard
                              size={size}
                              thumbnail={item.thumbnail_url}
                              config={config}
                              name={
                                item.short_description ||
                                item.tcin ||
                                item.value ||
                                item.pid ||
                                item.asset_name ||
                                item.asset_id
                              }
                              isUrlAppendedWithImg={item.asset_type !== 'PID'}
                              assetData={item}
                              isPropAsset={item.asset_type === 'PROP'}
                              maxWidth={230}
                              minWidth={230}
                              infoDetails={infoDetails}
                              noThumbnail={noThumbnail}
                              selectable={
                                !addedAssets[
                                  item.tcin ||
                                    item.value ||
                                    item.pid ||
                                    item.asset_id
                                ]
                              }
                              checkboxColor={checkboxColor}
                              footerItems={footerItems}
                              errorDetails={{
                                high: item.is_hp_available || false,
                                low: item.is_lp_available || false,
                              }}
                              subTitle={item.product_desc || ``}
                              highLight={
                                subAssetId === item.tcin ||
                                subAssetId === item.value
                              }
                              selected={
                                selectedAssets[
                                  item.tcin ||
                                    item.value ||
                                    item.pid ||
                                    item.asset_id
                                ] !== undefined
                              }
                              onSelect={(e) => {
                                this.selectAsset(
                                  item.tcin ||
                                    item.value ||
                                    item.pid ||
                                    item.asset_id,
                                  item,
                                  e.target.checked
                                )
                              }}
                              isRtaAsset={item.is_pure_rta_asset}
                              isHighPolyCountAsset={item.is_high_triangle_count}
                            />
                          )}
                        </div>
                      )
                    })}
                  {!loading && assetList.length === 0
                    ? this.noSearchContent()
                    : null}
                </DataList>
              </Box>
            </div>
            {showVariationView ? (
              <div style={{ width: 560 }}>
                <StyledAssets
                  size={size}
                  data={variationTcin}
                  onClose={this.closeSubAssetView}
                  selectedItems={selectedAssets}
                  selectAsset={this.selectAsset}
                  isSceneEditor={isSceneEditor}
                  handleCartActions={handleCartActions}
                  copyPath={this.copyPath}
                />
              </div>
            ) : null}

            {openSimilarSearch ? (
              <div style={{ width: 560 }}>
                <SimilarAssets
                  size={size}
                  data={similarAssetParent}
                  assetType={assetType}
                  onClose={this.closeSubAssetView}
                  selectedItems={selectedAssets}
                  selectAsset={this.selectAsset}
                  isSceneEditor={isSceneEditor}
                  handleCartActions={handleCartActions}
                  copyPath={this.copyPath}
                />
              </div>
            ) : null}
          </div>
        </div>
        {Object.keys(selectedAssets).length > 0 ? (
          <ExplorerFooter
            footerOptions={footerOptions}
            deSelectAssets={this.deSelectAssets}
            selectedItems={selectedAssets}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = ({
  explorer: {
    totalAssetsCount = 10,
    assetList = [],
    libraryAssetsCount = 0,
    filterLists,
    showSimilarAsset,
    search_similar_asset: similarAssetParent,
    showVariations: showVariationView,
    variationTcin,
    filterType,
    sortBy,
  },
  scene,
}) => {
  const { mode } = scene
  return {
    totalAssetsCount,
    assetList,
    libraryAssetsCount,
    filterLists,
    openSimilarSearch: showSimilarAsset,
    similarAssetParent,
    showVariationView,
    variationTcin,
    filterType,
    sortBy,
    sceneMode: mode,
  }
}

export default connect(mapStateToProps, {
  fetchAssets,
  clearAssets,
  handlefilterChange,
  showSimilarSearchResults,
  clearSimilarAssets,
  showVariationAssets,
})(withStyles(styles)(AssetExplorer))
