import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import PageNotFoundImg from '../../images/pageNotFound.png'

const styles = (theme) => ({
  defaultThumnnail: {
    position: 'absolute',
    top: '25%',
    left: '25%',
  },
})

class PageNotFound extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <img
        src={PageNotFoundImg}
        className={classes.defaultThumnnail}
        alt="pageNotFound"
      />
    )
  }
}

PageNotFound.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(PageNotFound)
