import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'
import ImageListItem from '@mui/material/ImageListItem'
import DialogContentText from '@mui/material/DialogContentText'
import ImageList from '@mui/material/ImageList'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'

import {
  SearchComponent,
  CustomButton,
  Select,
  TemplateCard,
  Dialog,
  PageTitleBar,
  Typography,
  CustomIconButton,
} from 'cgi-ui-components'

import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'
import BrandLogo from '../../images/assetIcons/default/brandLogo.svg?url'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import ClearIcon from '@mui/icons-material/Clear'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { debounce } from 'lodash'
import { formatMembers } from '../../helpers/dataFormats'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import { ERROR_MSG_FOR_SELECTING_TEMPLATE } from '../../constants/displayMessages'
import {
  searchTemplate,
  selectTemplate,
  fetchTemplates,
  clearTemplateThumbnailData,
  downloadTemplate,
  clearTemplateList,
  fetchBusinessDepartments,
} from '../../store/template/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import { removeTcinsFromBoard } from '../../store/board/actionCreator'
import { removeMultipleAssets } from '../designSpace'
import { formatErrorTable } from '../../components/TemplateUpload/ErrorTable'

import config from '../../config/config'

const styles = (theme) => ({
  modalTitleHeader: {
    border: '1px solid ' + theme.palette.primary.grayLight,
    borderWidth: '1px 1px 0 1px',
    padding: '30px 30px 15px 30px !important',
  },
  modalContentContainer: {
    border: '1px solid ' + theme.palette.primary.grayLight,
    borderWidth: '0 1px 1px 1px',
    height: 1000,
  },
  btnClass: {
    zIndex: 99999,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 30,
    top: 40,
    cursor: 'pointer',
  },
  gridList: {
    width: '100%',
  },
  templateTitle: {
    color: theme.palette.primary.gray,
    fontSize: '1.0rem',
    fontWeight: 500,
    float: 'left',
    margin: '8px 50px 0 0',
  },
  searchResultContext: {
    padding: 20,
  },
  noSearchResultContext: {
    padding: 20,
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 300,
    marginTop: '220px !important',
  },
  resultKeyword: {
    fontWeight: 1000,
  },

  noResultSubtext: {
    display: 'block',
    fontSize: '0.8rem',
    margin: 10,
  },
  customMedia: {
    height: '170px',
  },
  dispFlex: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalFooter: {
    padding: '20px 30px',
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  backButton: {
    marginRight: 15,
    paddingLeft: 0,
  },
  rightIconButton: {
    paddingRight: 5,
  },
  closeIcon: {
    width: '1.1em',
    height: '1.0em',
    marginTop: '0.3em',
  },
  dialogTitleHeader: {
    display: 'flex',
    flexDirection: 'column !important',
    lineHeight: 0,
  },
  contentEnd: {
    height: 10,
  },
  gridLiStyle: {
    maxWidth: '220px',
    width: 220,
    margin: '15px 0px',
    '&:hover': {
      borderRadius: '4px',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },
  header: {
    marginBottom: 30,
  },
})

const RenderInfoPopup = ({ errorText }) => {
  return (
    <>
      <p
        style={{
          color: '#000',
          fontSize: 12,
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: 15,
          fontWeight: '500',
        }}
      >
        Error Details
      </p>
      {errorText.map((error) => {
        return (
          <p
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: 12,
              paddingLeft: 10,
              paddingRight: 10,
              marginBottom: 5,
            }}
          >
            {error}
          </p>
        )
      })}
      <p style={{ padding: 0, marginBottom: 0 }}>&nbsp;</p>
    </>
  )
}

class TemplateDialog extends React.Component {
  constructor(props) {
    super(props)
    this.contentEndRef = React.createRef()
    this.state = {
      searchQuery: '',
      showOverlayLoader: false,
      loaderDisplayText: '',
      enableClose: true,
      sceneData: {},
      templateUrl: '',
      loading: false,
      team: [],
      pubslished: 'desc',
      compatibilty: 'V-Ray',
      downloadableReport: { headers: [], data: [] },
      pageNum: 1,
      size: 20,
      infiniteScroll: true,
      templateErrors: [],
      assetErrorDetails: {},
    }
  }
  downloadTemplate = (template_name, template_high_res_url) => {
    const { downloadTemplate } = this.props
    downloadTemplate(template_name, template_high_res_url)
  }

  componentDidMount() {
    const {
      selectTemplate,
      experience: { template_id: templateId, teams },
      fetchTemplates,
      businessDepts = [],
      fetchBusinessDepartments,
      clearTemplateList,
    } = this.props

    clearTemplateList()
    if (!businessDepts.length) {
      fetchBusinessDepartments()
    }
    let { compatibilty } = this.state

    this.setState(
      {
        team: teams,
      },
      () => {
        fetchTemplates(1, 20, '', this.state.team, compatibilty, 'DESC')
        if (!this.contentEndRef.current) return
        this.observer = new IntersectionObserver(this.interSectionCallback, {
          threshold: [0.1],
        })
        this.observer.observe(this.contentEndRef.current)
      }
    )

    if (templateId != null && templateId !== '') {
      selectTemplate({ templateId })
    }
  }

  handleClose = () =>
    this.state.enableClose ? this.props.toggleTemplateModal(false) : false

  chooseTemplate = () => {
    const {
      toggleTemplateModal,
      experience,
      onSelectTemplate,
      selectTemplate,
      selectedTemplateData: { templateId: selectedTemplate },
      onTemplateSelect,
      changeTemplate = false,
      onUpdateTemplate = () => {},
      clearTemplateThumbnailData = () => {},
      removeTcinsFromBoard = () => {},
      allBoardAssets = [],
      boardId,
      onSelectErrorTemplate,
    } = this.props
    const { sceneData, templateUrl, templateErrors, assetErrorDetails } =
      this.state
    const members = formatMembers(experience.members)
    const experienceData = {
      ...experience,
      members: members,
      template_id: selectedTemplate,
      error_details: '',
    }
    delete experienceData.projects

    if (templateErrors.length) {
      onSelectErrorTemplate({
        sceneData,
        templateUrl,
        templateErrors,
        selectedTemplate,
        assetErrorDetails,
        experienceData,
      })

      selectTemplate({ templateId: '' })

      toggleTemplateModal(false)
      return
    }

    this.setState({ loading: true })
    clearTemplateThumbnailData()

    onSelectTemplate(
      experienceData,
      () => {
        this.setState({ loading: false })
        if (selectedTemplate !== '' && selectedTemplate !== null) {
          selectTemplate({ selectedTemplate, templateUrl, sceneData })
        } else {
          selectTemplate({ templateId: '' })
        }
        toggleTemplateModal(false)
        if (changeTemplate) {
          const toRemoveFromBoard = allBoardAssets
            .filter((item) => item.sub_asset_type === 'TEMPLATE')
            .map((item) => item.value)
          const deleteData = {
            board_id: boardId,
            assets: [...toRemoveFromBoard],
          }
          removeTcinsFromBoard(deleteData)
            .then(() => {
              removeMultipleAssets([...toRemoveFromBoard])
              onUpdateTemplate(true)
            })
            .catch((e) => {
              //console.log(e)
            })
        } else {
          onTemplateSelect(true)
        }
      },
      (error) => {
        this.setState({ loading: false })
        this.props.showNotification(
          true,
          ERROR_MSG_FOR_SELECTING_TEMPLATE,
          'error'
        )
      }
    )
  }
  getTags = (tagArr) => (tagArr.length ? tagArr.join(', ') : false)

  templateSearch = (searchQuery = '') => {
    const { fetchTemplates, clearTemplateList } = this.props
    const { pageNum, team, compatibilty, size } = this.state
    clearTemplateList()
    this.setState({ haveSearchQuery: searchQuery, pageNum: 1 }, () => {
      fetchTemplates(1, size, searchQuery, team, compatibilty, 'DESC')
    })
  }

  clearSearch = () => {
    this.templateSearch('')
  }

  interSectionCallback = (entries, observer) => {
    const { templateData, fetchTemplates } = this.props
    const { haveSearchQuery, team, compatibilty, pageNum, size, loading } =
      this.state
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (
          !templateData.loading &&
          templateData.templateList.length < templateData.total_count
        ) {
          fetchTemplates(
            pageNum + 1,
            size,
            haveSearchQuery,
            team,
            compatibilty,
            'DESC',
            () => {
              this.setState({
                pageNum: this.state.pageNum + 1,
              })
            },
            () => {}
          )
        }
      }
    })
  }

  noSearchContent = () => {
    const { classes } = this.props
    const { team, compatibilty, haveSearchQuery } = this.state
    return (
      <DialogContentText className={classes.noSearchResultContext}>
        <SearchOffRoundedIcon
          sx={{
            width: '120px',
            height: '120px',
            margin: 'auto',
            display: 'block',
          }}
        />
        <Typography
          variant={'h6'}
          // label={`Sorry we couldn't find any matches for "${this.state.haveSearchQuery || ''}"`}
          label={
            <span>
              Sorry we couldn't find any matches for{' '}
              <span className={classes.resultKeyword}>{`"${
                this.state.haveSearchQuery || ''
              }"`}</span>
            </span>
          }
        />
        <Typography
          variant={'body2'}
          label={`May be your search was too specific, please try searching with another
          term.`}
        />
      </DialogContentText>
    )
  }

  handleSelect = (ev, value, returnObj, errorText) => {
    const {
      template_id: templateId,
      template_draco_loc: templateUrl,
      scene_data: sceneData,
      template_name: templateName,
      asset_error_details: assetErrorDetails,
    } = returnObj

    const { selectTemplate } = this.props

    if (value) {
      selectTemplate({
        templateId,
        templateUrl: '',
        sceneData: {},
        templateName,
      })
      this.setState({
        sceneData,
        templateUrl,
        templateErrors: errorText,
        assetErrorDetails,
      })
    } else {
      selectTemplate({ templateId: '', templateUrl: '', sceneData: {} })
      this.setState({
        sceneData,
        templateUrl,
        templateErrors: [],
      })
    }
  }

  componentWillUnmount() {
    const { toggleTemplateModal } = this.props
    toggleTemplateModal(false)
  }

  handleDownloadReport = (templateDetails) => {
    let data = []
    let headers
    let assetsList =
      templateDetails &&
      templateDetails.scene_data &&
      templateDetails.scene_data !== null &&
      templateDetails.scene_data.assets &&
      templateDetails.scene_data.assets !== null
        ? templateDetails.scene_data.assets
        : []
    if (assetsList.length) {
      headers = [
        { label: 'TCIN / Purchased Asset', key: 'assetId' },
        { label: 'Asset Type', key: 'assetType' },
        { label: 'High Poly Available', key: 'hp' },
        { label: 'Low Poly Available', key: 'lp' },
      ]
      assetsList.forEach((element) => {
        data.push({
          assetId: element.assetId,
          assetType: element.assetType,
          hp: element.hpAvailable ? 'TRUE' : 'FALSE',
          lp: element.lpAvailable ? 'TRUE' : 'FALSE',
        })
      })
    }

    const downloadableReport = { headers, data }

    this.setState({ downloadableReport })

    return downloadableReport
  }

  handleSelectChangeRender = (e) => {
    const { fetchTemplates, clearTemplateList } = this.props
    const { team, haveSearchQuery } = this.state
    clearTemplateList()
    this.setState(
      {
        compatibilty: e.target.value,
        pageNum: 1,
      },
      () => {
        const { size } = this.state
        fetchTemplates(1, size, haveSearchQuery, team, e.target.value, 'DESC')
      }
    )
  }
  handleSelectChangeTeam = (e) => {
    const { fetchTemplates, clearTemplateList } = this.props
    const { compatibilty, haveSearchQuery } = this.state
    clearTemplateList()
    this.setState(
      {
        team: e.target.value,
        pageNum: 1,
      },
      () => {
        const { size } = this.state
        fetchTemplates(
          1,
          size,
          haveSearchQuery,
          e.target.value.join(),
          compatibilty,
          'DESC'
        )
      }
    )
  }
  render() {
    const {
      classes,
      templateData,
      templateModalOpen,
      onBack,
      navigate,
      selectedTemplateData: {
        templateId: selectedTemplate,
        templateName: selectedTemplateName,
      },
      boardAssets,
      changeTemplate = false,
      sceneTemplate = '',
      businessDepts,
    } = this.props
    const {
      enableClose,
      loading,
      downloadableReport,
      team,
      compatibilty,
      pubslished,
    } = this.state
    const templateList = templateData.isFiltered
      ? templateData.filteredList
      : templateData.templateList
    let templateSelected = {}
    const layoutsList = businessDepts.map(({ team, team_display_value }) => {
      return { name: team_display_value, value: team }
    })
    return (
      <Dialog
        maxWidth="lg"
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown') {
            if (!changeTemplate) {
              navigate('/home/experiences')
            } else {
              this.handleClose()
            }
          }
        }}
        fullWidth={true}
        aria-labelledby="customized-dialog-title"
        open={templateModalOpen}
        onEnter={this.onTemplateOpen}
        disableEnforceFocus
      >
        <DialogTitle className={classes.modalTitleHeader}>
          <Grid container alignItems="center" className={classes.header}>
            <Grid item xs="3" className={classes.dialogTitleHeader}>
              <PageTitleBar
                title="Choose a layout"
                subTitle={'Brand templates, Architecture, Store layouts'}
              />
            </Grid>

            <Grid item xs="9">
              <SearchComponent
                brandLogo={BrandLogo}
                height={40}
                onSearch={debounce(this.templateSearch, 500)}
                onSearchButtonClick={this.clearSearch}
                placeholder="Search in layouts.."
                searchVal=""
                width={374}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ marginTop: 10 }}>
            <Grid item xs="1">
              <Typography variant={'subtitle2'} label="Layout Filters" />
            </Grid>
            <Grid item xs="2" style={{ marginLeft: 10 }}>
              <Select
                variant="filled"
                color="primary"
                size={'small'}
                multiple
                value={team && Array.isArray(team) ? team : []}
                displayCheckBox
                renderValue={(selected) => selected.join(', ')}
                inputLabel="Business department"
                onChange={this.handleSelectChangeTeam}
                options={layoutsList}
              />
            </Grid>
            <Grid item xs="2" style={{ marginLeft: 20 }}>
              <Select
                variant="filled"
                value={compatibilty}
                size={'small'}
                color="primary"
                inputLabel="Render compatibilty"
                onChange={this.handleSelectChangeRender}
                options={[
                  {
                    name: 'V-Ray',
                    value: 'V-Ray',
                  },
                  {
                    name: 'Corona',
                    value: 'Corona',
                  },
                ]}
              />
            </Grid>
            <Grid item xs="2" style={{ marginLeft: 20 }}>
              <Select
                variant="filled"
                value={pubslished}
                color="primary"
                onChange={() => {}}
                size={'small'}
                inputLabel="Published date"
                options={[
                  {
                    name: 'Newer First',
                    value: 'desc',
                  },
                ]}
              />
            </Grid>
          </Grid>

          {enableClose ? (
            <CustomIconButton
              aria-label="close"
              label="close"
              onClick={(e) => {
                if (!changeTemplate) {
                  navigate('/home')
                } else {
                  this.handleClose()
                }
              }}
              sx={{
                position: 'absolute',
                right: 30,
                top: 40,
              }}
            >
              <ClearIcon />
            </CustomIconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.modalContentContainer}>
          {!this.props.templateData.templateList.length &&
            !templateData.loading &&
            this.noSearchContent()}
          {Object.keys(this.props.templateData).length &&
            Object.keys(this.props.templateData.templateList.length) && (
              <ImageList
                sx={{
                  width: '100%',
                  gridTemplateColumns: 'repeat(4, 250px) !important',
                  justifyContent: 'center',
                  placeItems: 'center',
                }}
                cols={4}
              >
                {templateList.map((cardContent, key) => {
                  if (selectedTemplate === cardContent.template_id) {
                    templateSelected = cardContent
                  }

                  let errorText = []
                  if (
                    cardContent.asset_error_details &&
                    cardContent.asset_error_details != null &&
                    Object.keys(cardContent.asset_error_details).length
                  ) {
                    const errordata = formatErrorTable(
                      cardContent.asset_error_details
                    )

                    Object.keys(errordata).forEach((key) => {
                      if (errordata[key].length) {
                        errorText.push(
                          `${errordata[key].length} ${key}(s) with errors`
                        )
                      }
                    })
                  }

                  return (
                    <ImageListItem key={key} className={classes.gridLiStyle}>
                      <TemplateCard
                        maxWidth={250}
                        raised={false}
                        imgHeight={200}
                        thumbnail={cardContent.template_thumbnail_url}
                        config={config}
                        selectable={true}
                        onSelect={(e) =>
                          this.handleSelect(
                            e,
                            e.target.checked,
                            cardContent,
                            errorText
                          )
                        }
                        hideSubtitle
                        footerItems={[
                          {
                            icon: FileDownloadOutlinedIcon,
                            onClick: () => {
                              this.downloadTemplate(
                                cardContent.template_name,
                                cardContent.template_thumbnail_url
                              )
                            },
                            title: 'Download Thumbnail',
                          },
                        ]}
                        assetError={!cardContent.high_low_poly_available}
                        templateError={!cardContent.low_poly_template_available}
                        name={cardContent.template_name}
                        selected={selectedTemplate === cardContent.template_id}
                        noThumbnail={noThumbnail}
                        infoDetails={{
                          icon: InfoOutlinedIcon,
                          color: '#AE570F',
                          title: 'info',
                          data:
                            errorText && errorText.length ? (
                              <RenderInfoPopup errorText={errorText} />
                            ) : (
                              ''
                            ),
                        }}
                      />
                    </ImageListItem>
                  )
                })}
              </ImageList>
            )}
          <div ref={this.contentEndRef} className={classes.contentEnd} />
        </DialogContent>

        <div>{templateData.loading && <LinearProgress />}</div>
        <Paper elevation={3} className={classes.modalFooter}>
          <Grid container alignItems="center">
            {templateSelected && templateSelected.high_low_poly_available ? (
              <Grid item xs="6">
                {selectedTemplateName !== '' ? (
                  <div>You've selected {selectedTemplateName}</div>
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              <Grid item xs="6">
                {selectedTemplate !== '' ? (
                  <div>
                    <CSVLink
                      filename="Assets-Report-For-Template.csv"
                      headers={downloadableReport.headers || []}
                      data={downloadableReport.data || []}
                      style={{ textDecoration: 'none', marginRight: 15 }}
                    >
                      <CustomButton
                        color="warning"
                        onClick={() =>
                          this.handleDownloadReport(templateSelected)
                        }
                        startIcon={<ReportProblemIcon />}
                        label={'Download Report'}
                        variant="text"
                        disableElevation
                      ></CustomButton>
                    </CSVLink>
                    You've selected{' '}
                    {selectedTemplateName &&
                      selectedTemplateName.replace(/_/g, ' ')}
                  </div>
                ) : (
                  ''
                )}
              </Grid>
            )}

            {changeTemplate ? (
              <Grid
                item
                xs="6"
                alignItems="center"
                justify="flex-end"
                className={classes.dispFlex}
              >
                <div className={classes.backButton}>
                  <CustomButton
                    variant="contained"
                    disabled={loading}
                    label={'Cancel'}
                    onClick={this.handleClose}
                    disableElevation
                  />
                </div>
                <CustomButton
                  variant="contained"
                  disableElevation
                  disabled={
                    loading ||
                    selectedTemplate === '' ||
                    selectedTemplate === sceneTemplate
                  }
                  label={
                    loading
                      ? sceneTemplate !== '' && sceneTemplate !== null
                        ? 'Updating...'
                        : 'Adding...'
                      : sceneTemplate !== '' && sceneTemplate !== null
                      ? 'Update Template'
                      : 'Add Template'
                  }
                  onClick={this.chooseTemplate}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs="6"
                alignItems="center"
                className={classes.dispFlex}
              >
                <div className={classes.backButton}>
                  <CustomButton
                    variant="contained"
                    color="secondary"
                    startIcon={<KeyboardArrowLeftIcon />}
                    disabled={loading}
                    disableElevation
                    label={'Edit details'}
                    onClick={() => {
                      this.handleClose()
                      onBack()
                    }}
                  />
                </div>
                {selectedTemplate !== '' ? (
                  <div className={classes.rightIconButton}>
                    <CustomButton
                      variant="contained"
                      disableElevation
                      endIcon={<KeyboardArrowRightIcon />}
                      disabled={loading}
                      label={
                        loading
                          ? 'Saving...'
                          : boardAssets.length
                          ? 'Go to Board'
                          : 'Add Products'
                      }
                      onClick={this.chooseTemplate}
                    />
                  </div>
                ) : (
                  <CustomButton
                    endIcon={<KeyboardArrowRightIcon />}
                    label={
                      loading
                        ? 'Skipping ...'
                        : 'Skip and ' +
                          (boardAssets.length ? 'Go to Board' : 'add products')
                    }
                    onClick={this.chooseTemplate}
                    disableElevation
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Dialog>
    )
  }
}
const mapStateToProps = ({
  templates: { selectedTemplate, businessDepts },
  Board: { assets, board_id: boardId },
  experiences: { experience },
}) => ({
  selectedTemplateData: { ...selectedTemplate },
  boardAssets: assets ? assets.map((item) => item.value) : [],
  experience,
  boardId,
  allBoardAssets: assets,
  businessDepts,
})
const mapDispatchToProps = {
  searchTemplate,
  showNotification,
  selectTemplate,
  fetchTemplates,
  clearTemplateThumbnailData,
  downloadTemplate,
  clearTemplateList,
  removeTcinsFromBoard,
  fetchBusinessDepartments,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TemplateDialog))
