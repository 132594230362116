import React from 'react'
import { withStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import TargetLogo from '../../images/SH_Logo_WhiteOnRed.svg?url'
import Denied from '../../images/ic_project_access_denied.svg?url'

const styles = (theme) => ({
  root: {
    display: 'block',
    height: '100vh',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    visibility: 'visible',
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: 9999,
    opacity: 0.9,
  },
  circularLoaderWrapper: {
    zIndex: 9999,
    height: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    textAlign: 'center',
  },
  circularWrapper: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 7px',
    backgroundSize: '45px 45px',
    backgroundImage: `url(${TargetLogo})`,
  },
  progress: {
    color: theme.palette.primary.targetRed,
  },
  displayText: {
    color: theme.palette.primary.darkFont,
    margin: 30,
  },
  errorTypeBlock: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 0px',
    backgroundSize: '150px 150px',
    backgroundImage: `url(${Denied})`,
  },
  errorTypeBlockInner: {
    width: '150px',
    height: '150px',
  },
})

const loaderWithOverlay = (props) => {
  const { classes, displayText, loaderErrorType = null } = props
  return (
    <div className={classes.root}>
      <div className={classes.overlay} />
      <div className={classes.circularLoaderWrapper}>
        {loaderErrorType === 'failed' ? (
          <div className={classes.errorTypeBlock}>
            <div className={classes.errorTypeBlockInner} />
          </div>
        ) : (
          <div className={classes.circularWrapper}>
            <CircularProgress
              className={classes.progress}
              size={60}
              thickness={2.6}
            />
          </div>
        )}
        {displayText ? (
          <div className={classes.displayText}>{displayText}</div>
        ) : null}
      </div>
    </div>
  )
}

const plainCircularLoader = (props) => {
  const { classes, displayText, size = 60 } = props
  return (
    <div className={classes.plainCircularWrapper}>
      <CircularProgress
        className={classes.plainProgress}
        size={size}
        thickness={2.6}
      />
      {displayText ? (
        <div className={classes.displayText}>{displayText}</div>
      ) : null}
    </div>
  )
}

const OverlayLoader = withStyles(styles, { withTheme: true })(loaderWithOverlay)
const PlainCircularLoader = withStyles(styles, { withTheme: true })(
  plainCircularLoader
)

export { OverlayLoader, PlainCircularLoader }
