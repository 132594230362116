import React from 'react'
import { withStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { InputBase, Grid, MenuItem, Select, IconButton } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import Close from '@mui/icons-material/Close'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
    fontSize: 14,
    marginRight: 10,
  },
  input: {
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    borderRadius: 2,
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 2,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    width: 105,
    height: 18,
  },
}))(InputBase)

const IconBtn = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.secondaryText,
  fontSize: 14,
  borderRadius: 0,
  '&:hover': {
    background: 'none',
  },
}))

const styles = (theme) => ({
  root: {
    clear: 'both',
    margin: '15px 12px 15px 0',
    borderRadius: '4px',
    border: `1px solid #000`,
  },

  inputWrapperMin: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 5px',
    justifyContent: 'space-evenly',
  },

  input: {
    width: '100%',
  },

  searchWidth: {
    width: '80%',
  },
  inputText: {
    fontSize: 14,
    padding: '8px 0',
  },
})

class ExplorerSearch extends React.Component {
  state = {
    isSelectorOpen: false,
    focussed: false,
    searchVal: this.props.searchVal,
  }
  onInputClick = (e) => {
    e.preventDefault()
    const { isSelectorOpen } = this.state
    this.setState({ isSelectorOpen: !isSelectorOpen })
  }
  handleOnFocus(status) {
    this.setState({ focussed: status })
  }
  handleInputChange = (e) => {
    const { handleSearchChange = () => {} } = this.props
    const searchVal = e.target.value
    this.setState({ searchVal })
    handleSearchChange(searchVal)
  }
  handleClose = (e) => {
    e.preventDefault()
    const { handleSearchChange = () => {} } = this.props

    this.setState({ searchVal: '' })
    handleSearchChange('')
  }
  componentDidUpdate(prevprops) {
    if (prevprops.searchVal !== this.props.searchVal) {
      this.setState({ searchVal: this.props.searchVal })
    }
  }

  render() {
    const { isSelectorOpen, focussed, searchVal = '' } = this.state
    const {
      classes,
      type = '',
      handleTypeChange = () => {},
      options = [],

      placeholder = 'Search assets',
      handleSearchChange = () => {},
      disabled,
      onClose = () => {},
      showTypeDropDown = true,
    } = this.props

    const searchWrapperClass =
      classes.root + ' ' + (focussed ? classes.focussed : '')
    return (
      <>
        <div className={searchWrapperClass}>
          <div className={classes.inputWrapperMin}>
            {showTypeDropDown && type !== 'ARCHITECTURE' ? (
              <Select
                value={type}
                open={isSelectorOpen}
                onChange={handleTypeChange}
                onClose={(e) => {
                  e.preventDefault()
                }}
                input={<BootstrapInput onClick={this.onInputClick} />}
              >
                {options.map((item) => {
                  return <MenuItem value={item.value}>{item.name}</MenuItem>
                })}
              </Select>
            ) : (
              <></>
            )}
            <InputBase
              className={classes.input}
              classes={{
                root: classes.searchWidth,
                input: classes.inputText,
              }}
              value={searchVal}
              placeholder={placeholder}
              onFocus={(e) => {
                this.handleOnFocus(true)
              }}
              onBlur={(e) => {
                this.handleOnFocus(false)
              }}
              onChange={this.handleInputChange}
              disabled={disabled}
              type="text"
            />
            <IconBtn onClick={this.handleClose} aria-label="Search">
              {searchVal !== '' ? (
                <Close style={{ height: 20 }} />
              ) : (
                <SearchIcon style={{ height: 20 }} />
              )}
            </IconBtn>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(styles)(ExplorerSearch)
