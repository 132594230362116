import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { loadCSS } from 'fg-loadcss'
import { isEmpty, cloneDeep, filter } from 'lodash'
import Notifications from '../../components/Notifications/Notifications'
import { showNotification } from '../../store/notification/actionCreator'
import EditorHeader from '../../components/editorHeader'
import { OverlayLoader } from '../../components/Loaders'
import ErrorOverlay from '../../components/Overlay/ErrorOverlay'
import ReportIssueModal from '../../components/reportAnIssue/reportAnIssueModal'
import withRouter from '../../components/HOC/withRouterHOC'
import MaterialEditorWrapper from '../../components/materialEditor'

import DesignSpace, {
  importScene,
  addTemplate,
  performActionOnScene,
  saveScene,
  setSceneCanEdit,
  getCameraPositions,
  getCameraTarget,
  getZoomFactor,
  setZoomFactor,
  updateAssetCoords,
  setCameraClipNear,
  setCameraClipFar,
  changeTemplateWalls,
  getCameraClipNear,
  getCameraClipFar,
  setCameraFocalLength,
  getCameraFocalLength,
  getUndoRedoButtonStatus,
  loadScene,
  updateAssetScale,
  applyCameraSettings,
  updateCameraPosInScene,
  reloadFailedData,
  changeLocalWorldSpace,
  resetTransControls,
  getSceneDataForRender,
  getZoomValue,
} from '../../components/designSpace'
import {
  toggleTemplateModal,
  fetchTemplates,
  fetchTemplateDetails,
  clearTemplateThumbnailData,
} from '../../store/template/actionCreator'

import {
  fetchExperienceDetail,
  clearExperience,
} from '../../store/experience/ActionCreator'
import DrawerRight from '../../components/drawerRight'
import apiConfig from '../../config/config'
import { clearCartData, addCartDataRedux } from '../../store/cart/ActionCreator'
import {
  clearBoardData,
  getBoard,
  addTcinToBoard,
} from '../../store/board/actionCreator'
import {
  setRenderDetails,
  setControlsPosition,
  setSceneData,
  getRenderPageCamera,
  resetSceneData,
  getRenderDetails,
  setRenderFormData as storeRenderFormData,
  setDefaultPrefNoise,
  saveSceneComplete,
} from '../../store/scene/ActionCreator'

import {
  fetchExperienceImages,
  renderJson,
} from '../../store/experienceImages/actionCreator'

import { getActionByKey } from '../../helpers/keyboard'
import {
  SCENE_SAVE,
  SCENE_SAVE_AS,
  SCENE_ESCAPE,
  SCENE_MOVE,
  SCENE_ROTATE,
  SCENE_DELETE,
  SCENE_DUPLICATE,
  SCENE_HIDE,
  SCENE_SHOW,
  SCENE_SHOW_ALL,
  SCENE_GROUP,
  SCENE_UNGROUP,
  SCENE_SELECT,
  SCENE_UPDATE_GROUP,
  SCENE_GROUP_HIDE_SHOW,
  SCENE_NEW,
  SCENE_OPEN,
  SCENE_VIEW_2D,
  SCENE_VIEW_3D,
  RENDER_RIGHT_DRAWER,
  RENDER_HEADER_HEIGHT,
  SCENE_UNDO,
  SCENE_REDO,
  REPLACE_ASSET,
  SCALE_ASSET,
  FOCUS_ASSET,
  LOCK_ASSET,
  GROUP_RENAME,
  FLIP_HORIZONTAL,
  SCENE_GROUP_ADD_ASSETS,
} from '../../constants/scene'

import {
  LOADING_TEMPLATE_TO_SCENE,
  ERROR_MSG_FOR_LOADING_TEMPLATE_TO_SCENE,
  ERR_MSG_FOR_MAX_RENDER_INPROGRESS,
  ERR_MSG_FOR_CHECKING_MAX_RENDER_INPROGRESS,
  ERROR_MSG_FOR_RENDER_FAILED,
  INITIATE_RENDER_SUCCESS_MSG,
} from '../../constants/displayMessages'
import {
  canUserWrite,
  radToAng,
  getDefaultNoiseBasedOnRenderer,
  openSlackChannel,
  deepCompareSceneJSON,
  waitUntil,
} from '../../helpers/utils'
import StatusBar from '../../components/statusBar'
import RenderHeader from '../../components/renderHeader'
import RenderFrame from '../../components/renderHeader/renderFrame'

import TemplateErrorConfirmationPopUp from '../../components/modal/TemplateErrorConfirmationPopUp'
import OverrideSceneConfirmation from '../../components/modal/OverrideSceneConfirmation'
import {
  FAST_TIME,
  FAST_NOISE,
  DEFAULT_NOISE,
  DEFAULT_TIME,
} from '../../constants/common'
import SideMenu from '../../components/drawer/SideMenu'
import AssetModeSelection from '../../components/AssetModeSelection'
import axios from 'axios'
import { ASSET_MODE_TCIN } from '../../constants/scene'
import { setSceneMode } from '../../store/scene/ActionCreator'
import ACTIONS from '../../constants/actions'

export class Editor extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: { params: { id = 'new' } = {} } = {},
      location: { search } = {},
    } = props
    const qParams = new URLSearchParams(search)

    const expId = qParams.get('expId')
    const isRenderMode = qParams.get('rendermode')
    const isPreviewMode = qParams.get('preview')
    const renderId = qParams.get('renderId')
    const renderTab = qParams.get('tabId')

    this.state = {
      sceneId: id,
      showOverlayLoader: false,
      saveStatus: '',
      userHaveWriteAccess: null,
      viewMode: '3D',
      currentCameraPosition: {},
      currentCameraTarget: {},
      currentAssetPosition: { x: 0, y: 0, z: 0 },
      currentAssetScale: { x: 0, y: 0, z: 0 },
      statusBarDisabled: true,
      isRenderMode: isRenderMode,
      activeTabId: renderTab !== null ? renderTab : 'render',

      loaderErrorType: '',
      loaderDisplayText: '',
      currentCameraAngles: {},
      assetsLoading: false,
      undoRedoButtonStatus: {
        activateUndo: false,
        activateRedo: false,
      },
      settingsDisabled: true,
      isHighLowAvail: true,
      showWarningDialog: false,
      sceneDataReport: {},
      downloadableReport: { headers: [], data: [] },
      firstRender: 1,
      isRightCartOpen: false,
      isPreviewPage: false,
      sceneCamera: {},
      addCameraSettings: false,
      sceneLoadingFailed: false,
      retryCount: 0,
      openSupportPage: false,
      current3DAxis: 'world',
      isPreviewMode: isPreviewMode,
      renderId: renderId,
      showRenderFrame: false,
      sceneMode: 'translate',
      defaultView: true,
      updateDnd: false,
      showSaveConfirm: false,
      updater_details: {},
      clipNearEnabled: true,
      clipNear: 0.1,
      scene_diff: [],
    }
    // Bind keydown and keyup event to class function
    document.addEventListener('keydown', this.handleMultiKeyPress, false)
    document.addEventListener('addedAsset', this.onAddorDelete, false)
    window.addEventListener('resize', this.onWindowResize)

    if (id === 'new') {
      props.clearExperience()
    }
    this.resizeElem = React.createRef()
    this.interactable = null
  }

  onWindowResize = () => {
    let { isRenderMode, defaultView, showRenderFrame, isPreviewPage } =
      this.state

    const {
      selectedCamera,
      renderData: { resWidth, resHeight },
    } = this.props

    if (!isPreviewPage && (isRenderMode || showRenderFrame)) {
      if (selectedCamera !== 'default') {
        this.showScreenChangeNotification()
      }
      this.changeResolution(resWidth, resHeight)
    }
  }

  onAddorDelete = () => {
    this.performAction(SCENE_SAVE)
  }

  updateCoordState = (x, y, z, update = true, disabled = false, mode = '') => {
    this.setState({
      currentAssetPosition: { x, y, z },
      statusBarDisabled: disabled,
      sceneMode: mode,
    })
    if (update) {
      updateAssetCoords(x || 0, y || 0, z || 0)
    }
  }
  updateScaleState = (
    scale = {},
    update = true,
    disabled = false,
    addToStack = false
  ) => {
    const { x = 1, y = 1, z = 1 } = scale
    this.setState(
      {
        currentAssetScale: { x, y, z },
      },
      () => {
        if (update) {
          updateAssetScale(x, y, z, addToStack)
        }
      }
    )
  }
  updateCameraPos = (position, update = false) => {
    const { isRenderMode } = this.state
    if (isRenderMode) {
      this.setState(
        {
          currentCameraPosition: position,
        },
        () => {
          if (update) {
            updateCameraPosInScene(position, update)
          }
        }
      )
    }
  }

  updateCameraTarget = (position, update) => {
    const { isRenderMode } = this.state
    if (isRenderMode) {
      this.setState({
        currentCameraTarget: position,
      })
    }
  }
  updateZoomFactor = (zoomFactor, update) => {
    this.setRenderFormData({ zoomFactor }, update)
  }
  updateCameraAngles = (value) => {
    const { isRenderMode } = this.state
    if (isRenderMode) {
      this.setState({
        currentCameraAngles: {
          _x: radToAng(value._x),
          _y: radToAng(value._y),
          _z: radToAng(value._z),
        },
      })
    }
  }

  updateCameraFocalLength = (value, update) => {
    const { isRenderMode } = this.state
    if (isRenderMode) {
      this.setRenderFormData({ focalLength: value }, update)
    }
  }
  update3DAxis = (axis) => {
    this.setState({ current3DAxis: axis })
  }

  resetTransformControls = () => resetTransControls()

  componentDidUpdate = (prevProps) => {
    const {
      match: { params: { id = 'new' } = {} } = {},
      experienceDetail: { initiator, members, template_id },
      location: { search },
      fetchTemplateDetails = () => {},
    } = this.props
    if (template_id !== prevProps.experienceDetail.template_id) {
      fetchTemplateDetails(template_id, (data) => {
        this.setRenderFormData({
          noise: getDefaultNoiseBasedOnRenderer(data.renderer_type),
        })
      })
    }
    if (
      prevProps.location.search.includes('expId') && // load the scene after duplicating/save as new scene
      search !== prevProps.location.search
    ) {
      this.props.resetSceneData()
      this.setState({ sceneId: id })
      this.loadScenedata(id)
      return
    }

    if (id !== prevProps.match.params.id) {
      this.props.resetSceneData()
      this.setState({ sceneId: id })
      this.setWriteAccess(initiator, members)
    }
  }

  setWriteAccess = (initiator, members) => {
    const userHaveWriteAccess = canUserWrite(
      this.props.auth.lanId,
      initiator,
      members
    )
    setSceneCanEdit(userHaveWriteAccess)
    this.setState({ userHaveWriteAccess })
  }

  componentWillUnmount() {
    const {
      clearCartData,
      clearBoardData,
      clearTemplateThumbnailData,
      resetSceneData,
    } = this.props
    // Unbind keydown and keyup event to class function
    document.removeEventListener('keydown', this.handleMultiKeyPress, false)
    // document.removeEventListener('keyup', this.handleMultiKeyPress, false)
    document.removeEventListener('addedAsset', this.onAddorDelete, false)
    clearCartData()
    clearBoardData() //clear board reducer
    clearTemplateThumbnailData()
    resetSceneData()
  }

  componentDidMount() {
    const { sceneId } = this.state
    this.setState({ zoomFactor: getZoomFactor() })

    loadCSS(
      apiConfig.fontAwesomeUrl,
      document.querySelector('#font-awesome-css')
    )

    const maxHeight = window.innerHeight - RENDER_HEADER_HEIGHT
    const maxWidth = window.innerWidth - RENDER_RIGHT_DRAWER
    const size = Math.min(maxHeight, maxWidth)
    this.props.storeRenderFormData({
      passes: 0,
      noise: DEFAULT_NOISE,
      frameX: (maxWidth - size) / 2,
      frameY: (maxHeight - size) / 2,
      frameWidth: size,
      frameHeight: size,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      clipNear: getCameraClipNear(),
      clipFar: getCameraClipFar(),
      zoomFactor: 1,
      focalLength: getCameraFocalLength(),
      dnd: true,
      resizeRatio: 1,
      templateRenderWidth: maxHeight,
      templateRenderHeight: maxWidth,
      resHeight: maxHeight,
      resWidth: maxWidth,
      width: size,
      height: size,
      x: 0,
      y: 0,
      screenHeight: window.innerHeight - RENDER_HEADER_HEIGHT,
      screenWidth: window.innerWidth,
      time: DEFAULT_TIME,
    })

    if (sceneId !== 'new' && sceneId !== ':id') {
      this.loadScenedata(sceneId)
    } else if (sceneId === ':id') {
      window.location.href = '/'
    }

    document.addEventListener(
      'onSceneChange',
      (event) => {
        this.props.addCartDataRedux(event.detail)
        this.setState({
          undoRedoButtonStatus: getUndoRedoButtonStatus(),
        })
      },
      false
    )
  }

  loadScenedata = (sceneId) => {
    const {
      fetchExperienceDetail,
      getBoard,
      fetchTemplateDetails,
      setRenderDetails,
      setSceneData,
      renderCamera,
    } = this.props
    const { isRenderMode } = this.state
    fetchExperienceDetail(
      sceneId,
      async ({
        template_id: templateId,
        template_draco_loc: templateDracoLoc,
        board_id: boardId,
        initiator,
        members,
        preferred_render_details,
        scene_data,
        template_default = {},
        default_view,
      }) => {
        this.setState({ defaultView: default_view })

        var tempRenderData = {}
        if (!isEmpty(preferred_render_details)) {
          setRenderDetails(preferred_render_details)
        }

        let selectedCamera = { selected_camera: 'default' }
        let render_details = {
          passes: 0,
          noise: DEFAULT_NOISE,
          locked: true,
          time: DEFAULT_TIME,
        }

        if (scene_data !== null) {
          selectedCamera = {
            selected_camera: scene_data.selected_camera,
          }
          if (scene_data.selected_camera === null) {
            selectedCamera.selected_camera = 'default'
          }
          setSceneData({
            ...cloneDeep(scene_data),
            ...selectedCamera,
          })

          if (preferred_render_details !== null) {
            render_details = getRenderDetails(
              preferred_render_details || {},
              scene_data.selected_camera || '',
              template_default || {}
            )
          }
        }
        let templateCamera =
          !isEmpty(template_default) && !isEmpty(template_default.camera)
            ? template_default.camera
            : {}
        let camera = templateCamera
        if (
          scene_data &&
          scene_data !== null &&
          render_details &&
          render_details !== null
        ) {
          camera =
            !isEmpty(renderCamera) && !isEmpty(renderCamera.position)
              ? { ...renderCamera }
              : { ...templateCamera }

          if (camera !== null) {
            const { clipNear = 0, clipFar, fl, zoomFactor } = camera
            const { passes, noise, locked, time } = render_details
            tempRenderData = {
              passes: passes != null ? passes : 0,
              noise: noise != null && noise > 0 ? noise : DEFAULT_NOISE,
              clipNear: clipNear,
              clipFar: clipFar,
              focalLength: fl,
              zoomFactor: zoomFactor,
              locked,
              time: time != null ? time : DEFAULT_TIME,
            }
          }
        }

        const {
          renderHeight: templateRenderHeight = 3000,
          renderWidth: templateRenderWidth = 3000,
        } = templateCamera
        tempRenderData.templateRenderWidth = templateRenderWidth
        tempRenderData.templateRenderHeight = templateRenderHeight
        tempRenderData.resWidth = templateRenderWidth
        tempRenderData.resHeight = templateRenderHeight

        this.setRenderFormData(tempRenderData, true)

        this.setWriteAccess(initiator, members)

        if (boardId !== null && boardId !== '') {
          getBoard(boardId)
        }

        this.setState({
          assetsLoading: true,
        })
        if (templateId) {
          fetchTemplateDetails(templateId)
          this.setState({
            showOverlayLoader: true,
            loaderDisplayText: LOADING_TEMPLATE_TO_SCENE,
          })
          try {
            await addTemplate(templateDracoLoc, templateId)
            this.setState({ showOverlayLoader: false })
          } catch (e) {
            this.setState({
              showOverlayLoader: true,
              loaderErrorType: 'failed',
              loaderDisplayText: ERROR_MSG_FOR_LOADING_TEMPLATE_TO_SCENE,
            })
          }
        }

        let url = `${apiConfig.experience.savedJSONScene}/${sceneId}`
        importScene(url)
          .then((data) => {
            if (isRenderMode) {
              this.setRenderMode(true)
            }
          })
          .catch(({ type }) => {
            if (type === 'assetError') {
              this.setState({ sceneLoadingFailed: true })
            } else if (templateId) {
              /** load scene from template for below mentioned scenario
               *  User exits from scene page after selecting template and before saving the scene,
               *  so we will have templateID in scene data but dont have scene_data.
               */
              let templateSceneUrl = `${apiConfig.template.templateList}/${templateId}`
              return importScene(templateSceneUrl, true).catch(({ type }) => {
                if (type === 'assetError') {
                  this.setState({
                    sceneLoadingFailed: true,
                  })
                }
              })
            }
          })
          .finally(() => {
            this.setState({
              assetsLoading: false,
            })
          })
      }
    )
  }

  loadTemplate = async (
    templateId,
    templateDracoLoc,
    scene,
    replaceTemplate = false,
    isHighLowAvailable = true,
    applyWorldRotationOnAssets = false,
    isTemplateEdited = false
  ) => {
    const { preferredRenderDetails, setDefaultPrefNoise, rendererType } =
      this.props
    if (templateId) {
      this.setState({
        showOverlayLoader: true,
        loaderDisplayText: LOADING_TEMPLATE_TO_SCENE,
        assetsLoading: false,
        sceneDataReport: scene.scene_data,
      })

      try {
        await addTemplate(templateDracoLoc, templateId, replaceTemplate)
      } catch (e) {
        this.setState({
          showOverlayLoader: true,
          loaderErrorType: 'failed',
          loaderDisplayText: ERROR_MSG_FOR_LOADING_TEMPLATE_TO_SCENE,
        })
      }

      this.setState({
        showOverlayLoader: false,
      })

      if (scene.scene_data && scene.scene_data.assets) {
        const { sceneId } = this.state
        let payload = {
          experience_id: sceneId,
          assets: scene.scene_data.assets.map(
            ({
              assetType = 'TCIN',
              name = '',
              assetId = '',
              subAssetType = null,
            }) => ({
              asset_type: assetType === 'custom' ? 'TCIN' : assetType,
              value: assetId !== '' ? assetId : name,
              sub_asset_type: subAssetType,
            })
          ),
        }
        this.props.addTcinToBoard(payload)

        if (scene.scene_data.camera) {
          let tempRenderData = {}
          let { camera: templateCamera = {} } = scene.scene_data

          const {
            renderHeight: templateRenderHeight = 1000,
            renderWidth: templateRenderWidth = 1000,
            clipFar,
            clipNear,
            renderHeight,
            renderWidth,
            fl,
            zoomFactor,
          } = templateCamera

          tempRenderData = {
            templateRenderWidth,
            templateRenderHeight,
            clipFar,
            clipNear,
            renderHeight,
            renderWidth,
            fl,
            zoomFactor,
          }

          if (replaceTemplate) {
            let defaultNoise = getDefaultNoiseBasedOnRenderer(rendererType)
            if (!isEmpty(preferredRenderDetails)) {
              if (preferredRenderDetails['preferred_camera']) {
                setDefaultPrefNoise({
                  key: 'preferred_camera',
                  value: defaultNoise,
                })
              }
              setDefaultPrefNoise({
                key: 'default',
                value: defaultNoise,
              })
            }
            tempRenderData.noise_level = defaultNoise
          }

          this.setRenderFormData(tempRenderData, true)
          this.props.setSceneData({
            camera: templateCamera,
            render_camera: templateCamera,
          })
        }
      }
      this.setState({
        isHighLowAvail: isHighLowAvailable,
      })
      scene.isTemplateScene = true // setting this flag to notify user that scenedata is from template
      loadScene(scene, applyWorldRotationOnAssets, isTemplateEdited)
        .then(() => {
          this.performAction(SCENE_SAVE)
          this.setState({ showWarningDialog: true })
        })
        .catch(({ type }) => {
          if (type == 'assetError') {
            this.setState({ sceneLoadingFailed: true })
          }
        })
    }
  }

  handleMultiKeyPress = (event) => {
    let canPerformAction =
      (event.target || {}).type !== 'text' &&
      (event.target || {}).type !== 'textarea'
    if ((event.target || {}).type === 'number') {
      var letters = /^[a-z]$/i
      let inputtxt = event.key || ''
      canPerformAction = inputtxt.match(letters) ? true : false
    }
    if (canPerformAction) {
      const sceneAction = getActionByKey(event)
      if (sceneAction) {
        event.preventDefault()
        this.performAction(sceneAction)
      }
    }
  }

  performAction = (
    sceneAction,
    callback = () => {},
    currentCameraPosition = {},
    assets,
    groupObj,
    currentCameraTarget = {},
    autosave,
    capture,
    cameraSnapshotClbk
  ) => {
    const { sceneId } = this.state

    const {
      cartData: {},
    } = this.props

    switch (sceneAction) {
      case SCENE_MOVE:
      case SCENE_ROTATE:
      case SCENE_SELECT:
      case SCENE_ESCAPE:
      case SCENE_DELETE:
      case SCENE_GROUP:
      case SCENE_UNGROUP:
      case SCENE_UPDATE_GROUP:
      case SCENE_DUPLICATE:
      case FLIP_HORIZONTAL:
      case SCENE_HIDE:
      case SCENE_SHOW:
      case REPLACE_ASSET:
      case FOCUS_ASSET:
      case LOCK_ASSET:
      case SCENE_GROUP_HIDE_SHOW:
      case SCALE_ASSET:
      case GROUP_RENAME:
      case SCENE_GROUP_ADD_ASSETS:
        performActionOnScene(
          { sceneAction: sceneAction },
          assets,
          () => {},
          groupObj
        )
        break
      case SCENE_SHOW_ALL:
      case SCENE_UNDO:
      case SCENE_REDO:
        performActionOnScene({ sceneAction: sceneAction })
        break
      case SCENE_SAVE:
        return this.initiateSceneSave({ autosave, capture, cameraSnapshotClbk })
        break
      case SCENE_VIEW_3D:
      case SCENE_VIEW_2D:
        performActionOnScene({
          sceneAction: sceneAction,
          cameraPosition: currentCameraPosition,
          cameraTarget: currentCameraTarget,
        })
        break
      case SCENE_NEW:
        window.open('/experience/new')
        break
      case SCENE_OPEN:
        window.open('/home')
        break
      case SCENE_SAVE_AS:
        window.open(`/experience/new?expId=${sceneId}`)
        break
      default:
        break
    }
    callback()
  }

  initiateSceneSave = async ({ autosave, capture, cameraSnapshotClbk }) => {
    await waitUntil((_) => !this.saveSceneIntiated)

    const { sceneId, userHaveWriteAccess, assetsLoading, sceneLoadingFailed } =
      this.state
    const { savedSceneData: previouslySavedChanges } = this.props
    if (userHaveWriteAccess && !assetsLoading && !sceneLoadingFailed) {
      this.saveSceneIntiated = true
      let url = `${apiConfig.experience.savedJSONScene}/${sceneId}`
      try {
        const dataInServer = await axios.get(url)

        const data_diff = deepCompareSceneJSON(
          previouslySavedChanges,
          dataInServer.data.scene_data
        )
        if (data_diff.equals) {
          return this.sceneSaveAction({ autosave, capture, cameraSnapshotClbk })
        } else {
          this.setState({
            showSaveConfirm: true,
            updater_details: dataInServer.data.updater_details,
            scene_diff: data_diff.diff,
          })
        }
      } catch (e) {
        if (e?.response?.status === 404) {
          return this.sceneSaveAction({ autosave })
        }
        console.log('Error in validating the data sync', e)
      }
    }
  }

  sceneSaveAction = ({
    autosave = false,
    capture = false,
    cameraSnapshotClbk = () => {},
  }) => {
    const {
      sceneId,
      userHaveWriteAccess,

      isRenderMode,
      assetsLoading,
      defaultView,
      showRenderFrame,
    } = this.state

    const {
      cartData: {},
      setRenderDetails,
      setSceneData,
      selectedCamera,
      savedSceneData,
      templateDefaultCamera,
      renderData,
      preferredRenderDetails,
      experienceDetail: { template_id: templateId },
      saveSceneComplete = () => {},
    } = this.props
    const {
      auth: { accessToken = '', email = '', lanId = '' },
    } = this.props

    if (userHaveWriteAccess && !assetsLoading) {
      this.setState({ saveStatus: 'Saving ' })
      const onBeforeSave = (sceneData, preRenderDetails) => {
        setSceneData(sceneData)
        if (!isEmpty(preRenderDetails)) {
          setRenderDetails(preRenderDetails)
        }
      }
      return new Promise((resolve, reject) => {
        saveScene({
          sceneId,
          accessToken,
          lanId,
          email,
          renderData,
          defaultView,
          isRenderPage: isRenderMode || showRenderFrame,
          selectedCamera,
          previousSceneData: savedSceneData,
          preferredRenderDetails,
          onBeforeSave,
          autosave,
          templateId,
          capture,
          cameraSnapshotClbk,
        })
          .then(
            ({
              data,
              data: {
                scene_data: { camera = {}, render_camera: rCamera = {} },
              },
            }) => {
              saveSceneComplete()
              if (data.render_details.resize_ratio !== null) {
                // setRenderDetails(data.render_details)
              }
              const currentCamera = getRenderPageCamera({
                ...data.scene_data,
                selected_camera: selectedCamera,
                templateDefaultCamera,
                capture,
              })

              setSceneData({
                ...cloneDeep(data.scene_data),
                currentCamera: { ...currentCamera },
              })
              this.setState({
                saveStatus: 'Saved ',
                sceneCamera: { ...camera },
                addCameraSettings: false,
              })
              setTimeout(() => {
                this.setState({ saveStatus: '' })
              }, 3000)
              resolve()
            }
          )
          .catch((e) => {
            this.setState({ saveStatus: 'Saved ' })
            setTimeout(() => {
              this.setState({ saveStatus: '' })
            }, 3000)
            reject(e)
          })
          .finally(() => {
            setTimeout(() => {
              this.saveSceneIntiated = false
            }, 300)
          })
      })
    }
  }

  storeRenderDetails = () => {
    const { renderData, defaultView, isRenderMode } = this.state
    if (isRenderMode && !defaultView) {
      const {
        passes,
        noise,
        width,
        height,
        resHeight,
        resWidth,
        x = 0,
        y = 0,
        resizeRatio,
        time,
      } = renderData

      const render_details = {
        pass_limit: passes !== null ? passes : 0,
        noise_level: noise !== null && noise > 0 ? noise : DEFAULT_NOISE,
        crop_x: x,
        crop_y: y,
        image_height: height,
        image_width: width,
        scene_width: resWidth,
        scene_height: resHeight,
        resize_ratio: resizeRatio,
        screen_height: window.innerHeight - RENDER_HEADER_HEIGHT,
        screen_width: window.innerWidth,
        time_limit_minutes: time != null ? time : DEFAULT_TIME,
      }
      // this.props.setRenderDetails(render_details)
    }
  }

  navigate = (url) => {
    this.props.history.push(url)
  }

  toggleView =
    (view = '3D') =>
    () => {
      const currentCameraPosition = getCameraPositions()
      const currentCameraTarget = getCameraTarget()
      this.setState((state) => {
        this.performAction(
          view === '3D' ? SCENE_VIEW_3D : SCENE_VIEW_2D,
          () => {},
          state.currentCameraPosition,
          undefined,
          {},
          state.currentCameraTarget
        )
        return {
          viewMode: view,
          currentCameraPosition: currentCameraPosition,
          currentCameraTarget: currentCameraTarget,
        }
      })
    }

  setPreviewMode = (value) => {
    this.setState({ isPreviewMode: value, viewMode: '3D' })
  }

  setRenderMode = (value) => {
    let {
      location: { pathname },
      savedRenderDetails,
      selectedCamera,
      experienceDetail: { template_default: templateDefault },

      renderData: {
        passes,
        noise,
        templateRenderWidth,
        templateRenderHeight,
        time,
      },
      preferredRenderDetails,
      setSceneMode,
    } = this.props

    let {
      activeTabId,
      isPreviewMode = false,

      defaultView,
      isPreviewPage,
      clipNearEnabled,
    } = this.state

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight - RENDER_HEADER_HEIGHT
    const size = Math.min(windowWidth, windowHeight)

    let x = 0
    let y = 0

    let renderWidth = size
    let renderHeight = size

    let resWidth = templateRenderWidth,
      resHeight = templateRenderHeight

    if (
      savedRenderDetails !== null &&
      savedRenderDetails.height &&
      savedRenderDetails.height !== null
    ) {
      //take it from reducer, which is autosaved
      x = savedRenderDetails.x
      y = savedRenderDetails.y
      renderHeight = savedRenderDetails.height
      renderWidth = savedRenderDetails.width
      passes =
        savedRenderDetails.passes !== null ? savedRenderDetails.passes : 0
      noise =
        savedRenderDetails.noise !== null && savedRenderDetails.noise > 0
          ? savedRenderDetails.noise
          : DEFAULT_NOISE
      resWidth = savedRenderDetails.sceneWidth
      resHeight = savedRenderDetails.sceneHeight
      time =
        savedRenderDetails.time != null ? savedRenderDetails.time : DEFAULT_TIME
    }

    const { frameX, frameY, frameWidth, frameHeight, width, height } =
      this.changeResolution(resWidth, resHeight, false)

    if (defaultView) {
      renderWidth = frameWidth
      renderHeight = frameHeight
    }
    this.props.storeRenderFormData({
      x,
      y,
      width: renderWidth,
      height: renderHeight,
      maxWidth: windowWidth,
      maxHeight: windowHeight,
      frameX,
      frameY,
      frameWidth,
      frameHeight,
      passes,
      noise,
      resWidth,
      resHeight,
      time,
    })
    this.setState(
      {
        showRenderFrame: false,
        isRenderMode: value,
        viewMode: '3D',
      },
      () => {
        if (value) {
          this.selectRenderPageCamera(selectedCamera)

          if (
            !isPreviewPage &&
            selectedCamera !== 'default' &&
            savedRenderDetails !== null &&
            savedRenderDetails.screenHeight !== null &&
            (savedRenderDetails.screenHeight !== windowHeight ||
              savedRenderDetails.screenWidth !== windowWidth)
          ) {
            this.showScreenChangeNotification()
          }

          !isPreviewMode && this.navigate(`${pathname}?rendermode=true`)
          setSceneMode(ASSET_MODE_TCIN)
        } else {
          this.navigate(`${pathname}`)
          this.setState({ activeTabId: 'render' })
        }
        this.resetTransformControls()
        changeTemplateWalls(!value, clipNearEnabled)
        this.performAction(SCENE_SAVE)
      }
    )
  }
  setRenderFrame = () => {
    const { showRenderFrame } = this.state

    this.setState({ showRenderFrame: !showRenderFrame }, () => {
      const { showRenderFrame } = this.state
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight - RENDER_HEADER_HEIGHT
      if (showRenderFrame) {
        const {
          savedRenderDetails: {
            x,
            y,
            width,
            height,
            screenWidth: savedWidth,
            screenHeight: savedHeight,
            sceneHeight: resHeight,
            sceneWidth: resWidth,
          },
          selectedCamera,
        } = this.props
        this.selectScenePageCamera(selectedCamera)
        const data = this.changeResolution(resWidth, resHeight, false)
        this.props.storeRenderFormData({
          x: x,
          y: y,
          width: width,
          height: height,
          resHeight,
          resWidth,
          frameWidth: data.frameWidth,
          frameHeight: data.frameHeight,
          frameX: data.frameX,
          frameY: data.frameY,
        })
        if (savedWidth !== windowWidth || savedHeight !== windowHeight) {
          this.showScreenChangeNotification()
        }
      }
    })
  }
  setRenderFormData = (renderData, update = true) => {
    const { isRenderMode } = this.state
    this.props.storeRenderFormData(renderData)
    setTimeout(() => {
      if (isRenderMode && update) {
        this.updateCameraData()
      }
    }, 100)
  }

  updateCameraData(updateFl = true) {
    const { renderData: { clipNear, clipFar, focalLength, zoomFactor } = {} } =
      this.props

    if (clipNear) {
      setCameraClipNear(clipNear)
    }
    if (clipFar) {
      setCameraClipFar(clipFar)
    }
    if (focalLength && updateFl) {
      setCameraFocalLength(focalLength)
    }
    if (zoomFactor) {
      setZoomFactor(zoomFactor)
    }
  }

  updateRenderCount = (value = 0) => {
    this.setState({ firstRender: this.state.firstRender + value })
  }

  run = true
  updateSelectionArea = (x, y, height, width) => {
    if (this.run) {
      setTimeout(() => {
        this.props.storeRenderFormData({ width, height, x, y })

        this.run = true
      }, 50)
      this.run = false
    }
  }

  disableCameraSettings = (val) => {
    this.setState({ settingsDisabled: val })
  }

  closeDeleteDialog = () => {
    this.setState({
      isHighLowAvail: true,
      sceneDataReport: {},
    })
  }

  handleDowloadReport = (e) => {
    const { sceneDataReport } = this.state
    let data = []
    let headers
    let assetsList =
      sceneDataReport &&
      sceneDataReport !== null &&
      sceneDataReport.assets &&
      sceneDataReport.assets !== null
        ? sceneDataReport.assets
        : []
    if (assetsList.length) {
      headers = [
        { label: 'TCIN / Purchased Asset', key: 'assetId' },
        { label: 'Asset Type', key: 'assetType' },
        { label: 'High Poly Available', key: 'hp' },
        { label: 'Low Poly Available', key: 'lp' },
      ]
      assetsList.forEach((element) => {
        data.push({
          assetId: element.assetId,
          assetType: element.assetType,
          hp: element.hpAvailable ? 'TRUE' : 'FALSE',
          lp: element.lpAvailable ? 'TRUE' : 'FALSE',
        })
      })
    }

    const downloadableReport = { headers, data }

    this.setState({ downloadableReport })

    return downloadableReport
  }
  setIsRightCartOpen = (value) => {
    const { setControlsPosition } = this.props

    this.setState({ isRightCartOpen: value }, () => {
      setControlsPosition(value ? 390 : 30)
    })
  }
  setIsPreviewPage = (value) => {
    this.setState({ isPreviewPage: value })
    if (value) {
      this.props.showNotification(false)
    } else {
      const { defaultView } = this.state
      const {
        renderData: { screenWidth: savedWidth, screenHeight: savedHeight },
      } = this.props

      if (
        !defaultView &&
        (savedWidth !== window.innerWidth ||
          savedHeight !== window.innerHeight - RENDER_HEADER_HEIGHT)
      ) {
        this.showScreenChangeNotification()
      }
    }
  }

  resetCameraSettings = (templateDefaultValue = true) => {
    const { sceneCamera, clipNearEnabled } = this.state
    this.setState({
      viewMode: '3D',
    })
    let defaultCameraSettings = {}
    if (this.props.experienceDetail.template_default !== null) {
      defaultCameraSettings =
        this.props.experienceDetail.template_default.camera
    }

    const cameraSettings = templateDefaultValue
      ? defaultCameraSettings
      : sceneCamera

    if (
      cameraSettings !== null &&
      cameraSettings.position &&
      cameraSettings.position !== null
    ) {
      applyCameraSettings(cameraSettings, clipNearEnabled)
      this.resetTransformControls()
      const { clipFar, clipNear, fl: focalLength, zoomFactor } = cameraSettings

      this.setRenderFormData({
        clipNear,
        clipFar,
        focalLength,
        zoomFactor,
      })
    }
  }
  manageSceneError = (isTemplateScene = false) => {
    const { retryCount, isHighLowAvail } = this.state
    if (retryCount < 3) {
      this.setState({
        retryCount: retryCount + 1,
        assetsLoading: true,
        sceneLoadingFailed: false,
      })
      reloadFailedData(isTemplateScene)
        .then(() => {
          this.setState({
            assetsLoading: false,
            showWarningDialog: !isHighLowAvail,
          })
        })
        .catch(({ type }) => {
          if (type === 'assetError') {
            this.setState({ sceneLoadingFailed: true })
          }
        })
    } else {
      openSlackChannel()
      this.setState({
        sceneLoadingFailed: true,
      })
    }
  }
  skipLoadingErrorMessage = () => {
    this.setState({
      sceneLoadingFailed: false,
      assetsLoading: false,
    })
  }

  closeSupportForm = () => {
    this.setState({
      openSupportPage: false,
    })
  }

  handle3DSpaceChange = (space) => {
    changeLocalWorldSpace(space)
  }
  toggleDefaultView = (defaultView) => {
    // this.storeRenderDetails()
    if (defaultView) {
      const {
        renderData: { resizeRatio, templateRenderWidth, templateRenderHeight },
        renderData,
      } = this.props
      const data = this.changeResolution(
        templateRenderWidth,
        templateRenderHeight,
        false
      )

      this.setRenderFormData({
        frameX: data.frameX,
        frameY: data.frameY,
        frameWidth: data.frameWidth,
        frameHeight: data.frameHeight,
        resHeight: templateRenderHeight,
        resWidth: templateRenderWidth,
        width: data.frameWidth,
        height: data.frameHeight,
        x: 0,
        y: 0,
      })
    } else {
      const { savedRenderDetails } = this.props
      if (savedRenderDetails.height !== null) {
        //take it from reducer, which is autosaved

        const data = this.changeResolution(
          savedRenderDetails.sceneWidth,
          savedRenderDetails.sceneHeight,
          false
        )
        this.setRenderFormData({
          passes: savedRenderDetails.passes,
          noise: savedRenderDetails.noise,
          resHeight: savedRenderDetails.sceneHeight,
          resWidth: savedRenderDetails.sceneWidth,
          frameX: data.frameX,
          frameY: data.frameY,
          frameWidth: data.frameWidth,
          frameHeight: data.frameHeight,
          width: savedRenderDetails.width,
          height: savedRenderDetails.height,
          x: savedRenderDetails.x,
          y: savedRenderDetails.y,
          time: savedRenderDetails.time,
        })
      }
    }
    this.setState({
      defaultView,
    })
  }

  changeResolution = (width, height, setValues = true) => {
    const maxWidth = window.innerWidth,
      maxHeight = window.innerHeight - RENDER_HEADER_HEIGHT
    let imageWidth = maxWidth
    let imageheight = maxHeight
    if (width > height) {
      imageheight = imageWidth / (width / height)
      if (imageheight > maxHeight) {
        //Cant fit the frame in window, since height  is more than available height
        let reduceRatio = imageheight / maxHeight
        imageheight = imageheight / reduceRatio
        imageWidth = imageWidth / reduceRatio
      } else {
        // let increaseRatio = imageheight / maxHeight
        // imageheight = imageheight * increaseRatio
        // imageWidth = imageWidth * increaseRatio
      }
    }
    if (height > width) {
      imageWidth = imageheight / (height / width)
    }
    if (height === width) {
      imageWidth = imageheight = Math.min(maxWidth, maxHeight)
    }
    const frameX = (maxWidth - imageWidth) / 2
    const frameY = (maxHeight - imageheight) / 2

    if (setValues) {
      this.setRenderFormData({
        resHeight: height,
        resWidth: width,
        frameX,
        frameY,
        width: imageWidth,
        height: imageheight,
        frameWidth: imageWidth,
        frameHeight: imageheight,
        x: 0,
        y: 0,
        maxWidth,
        maxHeight,
      })
      this.setState({ updateDnd: !this.state.updateDnd })
    }
    return {
      frameX,
      frameY,
      frameWidth: imageWidth,
      frameHeight: imageheight,
      width,
      height,
      maxWidth,
      maxHeight,
    }
  }

  showScreenChangeNotification() {
    //Commented for removal of crop area size STHB-2017
    // this.props.showNotification(
    //   true,
    //   'you may need to readjust the camera render frame under the render settings due to shift  in new monitor resolution',
    //   'info'
    // )
  }
  selectScenePageCamera = (cameraName) => {
    const { savedSceneData: sceneData, templateDefaultCamera } = this.props
    const { clipNearEnabled } = this.state
    let cameraSettings = getRenderPageCamera({
      ...sceneData,
      selected_camera: cameraName,
      templateDefaultCamera,
    })

    if (!isEmpty(cameraSettings)) {
      applyCameraSettings(cameraSettings, clipNearEnabled)
    } else {
      applyCameraSettings(templateDefaultCamera, clipNearEnabled)
    }
  }
  selectRenderPageCamera = (selectedCameraName = 'default', reset = false) => {
    const {
      savedSceneData: sceneData,
      setSceneData,
      templateDefaultCamera,
      preferredRenderDetails,
      storeRenderFormData,
    } = this.props
    let cameraSettings = {}
    let selectedCameraDetails = preferredRenderDetails[selectedCameraName]
    if (selectedCameraName == 'default' && reset) {
      cameraSettings = { ...templateDefaultCamera }
    } else {
      cameraSettings = getRenderPageCamera({
        ...sceneData,
        selected_camera: selectedCameraName,
        templateDefaultCamera,
      })
    }

    setSceneData({
      currentCamera: { ...cameraSettings },
      selected_camera: selectedCameraName,
    })

    setTimeout(() => {
      const renderDetails = getRenderDetails(
        preferredRenderDetails,
        selectedCameraName,
        templateDefaultCamera
      )
      if (!isEmpty(cameraSettings)) {
        applyCameraSettings(cameraSettings)
        const {
          clipFar,
          clipNear,
          zoomFactor,
          fl,
          position: currentCameraPosition,
          target: currentCameraTarget,
        } = cameraSettings
        this.setState({ currentCameraPosition, currentCameraTarget })
        this.setRenderFormData({
          clipNear,
          clipFar,
          zoomFactor: zoomFactor,
          focalLength: fl,
          locked: selectedCameraDetails.locked || false,
        })

        if (!isEmpty(renderDetails)) {
          const {
            x,
            y,
            resWidth,
            resHeight,
            width,
            height,
            resizeRatio,
            noise,
            passes,
            time,
          } = renderDetails

          const data = this.changeResolution(resWidth, resHeight, false)
          storeRenderFormData({
            x,
            y,
            width,
            height,
            resHeight,
            resWidth,
            frameWidth: data.frameWidth,
            frameHeight: data.frameHeight,
            frameX: data.frameX,
            frameY: data.frameY,
            resizeRatio,
            noise,
            passes,
            time,
          })
        }
      }
    }, 50)

    return cameraSettings
  }

  calculateResizeRatioForFastRender = (
    templateRenderWidth,
    templateRenderHeight,
    maxWidth,
    maxHeight
  ) => {
    this.resizeRatio = 1

    if (templateRenderWidth > maxWidth || templateRenderHeight > maxHeight) {
      if (maxWidth > maxHeight) {
        if (templateRenderHeight > maxHeight) {
          this.resizeRatio = templateRenderHeight / maxHeight
          if (this.resizeRatio * maxWidth < templateRenderWidth) {
            this.resizeRatio = templateRenderWidth / maxWidth
          }
        } else if (templateRenderWidth > maxWidth) {
          this.resizeRatio = templateRenderWidth / maxWidth
        }
      } else if (maxWidth < maxHeight) {
        this.resizeRatio = templateRenderWidth / maxWidth
      } else if (maxWidth === maxHeight) {
        this.resizeRatio = templateRenderHeight / maxHeight
      }
    }
    return this.resizeRatio
  }

  initiateFastRender = () => {
    fetchExperienceImages(
      this.state.sceneId,
      (response) => {
        const prevInRendering = filter(response.unflagged_renders, {
          render_status: 'RENDERING',
        }).length
        if (prevInRendering >= apiConfig.renderConfig.maxRenderThreshold) {
          this.props.showNotification(
            true,
            ERR_MSG_FOR_MAX_RENDER_INPROGRESS,
            'error'
          )
        } else {
          this.setRenderMode(false)
          setTimeout(() => {
            this.prepareRenderJsonForFastRender()
          }, 300)
        }
      },
      (error) => {
        this.props.showNotification(
          true,
          ERR_MSG_FOR_CHECKING_MAX_RENDER_INPROGRESS,
          'error'
        )
      }
    )
  }

  prepareRenderJsonForFastRender() {
    const {
      renderData: {
        passes,
        noise,
        width,
        height,
        x = 0,
        y = 0,
        maxHeight,
        maxWidth,
        frameX = 0,
        frameY = 0,
        time,
      },
      updateRenderCount = () => {},
      templateDefaultCamera: { clipNear, clipFar, renderWidth, renderHeight },
    } = this.props

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight - RENDER_HEADER_HEIGHT
    let data = getSceneDataForRender()
    let resizeRatio = this.calculateResizeRatioForFastRender(
      renderWidth,
      renderHeight,
      windowWidth,
      windowHeight
    )

    data.experience_id = this.state.sceneId
    data.scene_data.camera.zoomFactor =
      (data.scene_data.camera.zoomFactor *
        data.scene_data.camera.renderHeight) /
      data.scene_data.camera.renderWidth
    data.scene_data.camera.zoomFactor = getZoomValue()
    data.scene_data.camera.clipNear = clipNear
    data.scene_data.camera.clipFar = clipFar
    data.render_details = {
      pass_limit: passes !== null ? passes : 0,
      noise_level: FAST_NOISE,
      image_width: width * resizeRatio,
      image_height: height * resizeRatio,
      scene_width: maxWidth * resizeRatio,
      scene_height: maxHeight * resizeRatio,
      crop_x: (x + frameX) * resizeRatio,
      crop_y: (y + frameY) * resizeRatio,
      clipNear,
      time_limit_minutes: FAST_TIME,
    }
    this.performAction(SCENE_SAVE)
    this.updateRenderCount(1)
    this.props.showNotification(true, INITIATE_RENDER_SUCCESS_MSG, 'success')
    renderJson(
      data,
      () => {},
      () => {
        this.props.showNotification(true, ERROR_MSG_FOR_RENDER_FAILED, 'error')
      }
    )
  }
  calculateScenePolyCount = () => {
    const {
      cartData: { allTcinMap = {} },
      boardAssetObjects = {},
      currentTemplateDetails,
    } = this.props
    const tcinList = Object.values(allTcinMap).map(
      (item) => item.originalAssetId
    )
    let tcinPolyCountSum = 0
    const template_triangle_count = currentTemplateDetails?.triangle_count ?? 0

    tcinList.forEach((tcin) => {
      tcinPolyCountSum += boardAssetObjects[tcin]?.triangle_count ?? 0
    })

    return template_triangle_count + tcinPolyCountSum
  }

  toggleClipNearConfig = (value) => {
    let { clipNear } = this.state
    if (!value) {
      clipNear = getCameraClipNear()
    }
    this.setState({ clipNearEnabled: value, clipNear }, () => {
      if (!value) {
        setCameraClipNear(0.1)
      } else {
        setCameraClipNear(clipNear)
      }
    })
  }

  render() {
    const {
      auth,
      experienceDetail,
      experienceDetail: { experience_name: sceneName, template_id: templateId },
      match,
      history,
      isRightDrawerOpen,
      activeOperations,
      location,
      renderData,
      cartData: { toggleReportIssueModal = false },
      rendererType,
    } = this.props
    const {
      sceneId,
      showOverlayLoader,
      saveStatus,
      viewMode,
      currentAssetPosition,
      statusBarDisabled,
      userHaveWriteAccess,
      isRenderMode,

      loaderErrorType,
      loaderDisplayText = LOADING_TEMPLATE_TO_SCENE,
      currentCameraPosition,
      currentCameraTarget,
      currentCameraAngles,
      assetsLoading,
      undoRedoButtonStatus,
      settingsDisabled,
      currentAssetScale,
      isHighLowAvail,
      showWarningDialog,
      downloadableReport,
      isRightCartOpen,
      isPreviewPage,
      sceneLoadingFailed,
      retryCount,
      openSupportPage,
      current3DAxis,
      isPreviewMode,
      renderId,
      showRenderFrame,
      sceneMode,
      defaultView,
      updateDnd,
      showSaveConfirm = false,
      updater_details = {},
      clipNearEnabled,
      scene_diff,
    } = this.state
    const title = experienceDetail.experience_name
      ? `${experienceDetail.experience_name} - StyleHub`
      : 'StyleHub'
    const scenePolyCount = this.calculateScenePolyCount()
    return (
      <>
        <Helmet defaultTitle={title} titleTemplate="%s - StyleHub" />
        <Notifications />
        {!isRenderMode ? (
          <>
            <EditorHeader
              auth={auth}
              match={match}
              history={history}
              sceneId={sceneId}
              experienceDetail={experienceDetail}
              saveScene={({ autosave }) =>
                this.performAction(
                  SCENE_SAVE,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  autosave
                )
              }
              viewMode={viewMode}
              toggleView={this.toggleView}
              setRenderMode={this.setRenderMode}
              assetsLoading={assetsLoading}
              undoAction={() => this.performAction(SCENE_UNDO)}
              redoAction={() => this.performAction(SCENE_REDO)}
              undoRedoButtonStatus={undoRedoButtonStatus}
              loadTemplate={this.loadTemplate}
              navigate={this.navigate}
              resetCamera={this.resetCameraSettings}
              setRenderFrame={this.setRenderFrame}
              showRenderFrame={showRenderFrame}
              selectCamera={this.selectScenePageCamera}
              initiateFastRender={this.initiateFastRender}
              rendererType={rendererType}
              clipNearEnabled={clipNearEnabled}
              toggleClipNearConfig={this.toggleClipNearConfig}
            />
            <DrawerRight
              handleCartActions={this.performAction}
              userHaveWriteAccess={userHaveWriteAccess}
              isRightDrawerOpen={isRightDrawerOpen}
              activeOperations={activeOperations}
              initialScale={{ ...currentAssetScale }}
              updateScaleState={this.updateScaleState}
              setIsRightCartOpen={this.setIsRightCartOpen}
            />
            {activeOperations[ACTIONS.EDIT_MATERIAL] && (
              <MaterialEditorWrapper
                activeOperations={activeOperations}
                saveScene={() => this.performAction(SCENE_SAVE)}
              />
            )}

            <SideMenu
              navigate={this.navigate}
              userHaveWriteAccess={userHaveWriteAccess}
              handleCartActions={this.performAction}
            />
            <AssetModeSelection userHaveWriteAccess={userHaveWriteAccess} />
            {showRenderFrame && <RenderFrame renderData={renderData} />}

            <StatusBar
              position={currentAssetPosition}
              updateCoords={this.updateCoordState}
              disabled={statusBarDisabled}
              handle3DSpaceChange={this.handle3DSpaceChange}
              current3DAxis={current3DAxis}
              isRightCartOpen={isRightCartOpen}
              viewMode={viewMode}
              sceneMode={sceneMode}
              assetsLoading={assetsLoading}
              saveStatus={saveStatus}
              scenePolyCount={scenePolyCount}
            />

            {showOverlayLoader ? (
              <OverlayLoader
                displayText={loaderDisplayText}
                loaderErrorType={loaderErrorType}
              />
            ) : null}
            <ErrorOverlay
              isOpen={sceneLoadingFailed}
              retryCount={retryCount}
              navigate={this.navigate}
              sceneName={sceneName}
              buttonAction={this.manageSceneError}
              skipError={this.skipLoadingErrorMessage}
            />
          </>
        ) : (
          <>
            <RenderHeader
              sceneId={sceneId}
              setRenderMode={this.setRenderMode}
              renderData={renderData}
              userHaveWriteAccess={userHaveWriteAccess}
              updateSelectionArea={this.updateSelectionArea}
              activeTabId={this.state.activeTabId}
              disableCameraSettings={this.disableCameraSettings}
              saveScene={({ autosave, capture, cameraSnapshotClbk }) =>
                this.performAction(
                  SCENE_SAVE,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  autosave,
                  capture,
                  cameraSnapshotClbk
                )
              }
              // saveScene={({ autosave }) => this.performAction(SCENE_SAVE)}
              match={match}
              location={location}
              history={history}
              setIsPreviewPage={this.setIsPreviewPage}
              isPreviewMode={isPreviewMode}
              renderId={renderId}
              setPreviewMode={this.setPreviewMode}
              disableStatusBar={statusBarDisabled}
              updateCameraPos={this.updateCameraPos}
              updateZoomFactor={this.updateZoomFactor}
              resetCameraSettings={this.resetCameraSettings}
              updateCoords={this.updateCoordState}
              position={currentAssetPosition}
              setRenderFormData={this.setRenderFormData}
              currentCameraPosition={currentCameraPosition}
              currentCameraTarget={currentCameraTarget}
              currentCameraAngles={currentCameraAngles}
              settingsDisabled={settingsDisabled}
              defaultView={defaultView}
              toggleDefaultView={this.toggleDefaultView}
              updateRenderCount={this.updateRenderCount}
              assetsLoading={assetsLoading}
              changeResolution={this.changeResolution}
              updateDnd={updateDnd}
              selectCamera={this.selectRenderPageCamera}
              rendererType={rendererType}
              saveStatus={saveStatus}
            />
          </>
        )}
        <DesignSpace
          sceneId={sceneId}
          handleSceneAction={this.performAction}
          updateCoords={this.updateCoordState}
          updateScale={this.updateScaleState}
          updateCameraPos={this.updateCameraPos}
          updateCameraTarget={this.updateCameraTarget}
          updateZoomFactor={this.updateZoomFactor}
          updateCameraAngles={this.updateCameraAngles}
          updateCameraFocalLength={this.updateCameraFocalLength}
          isRenderMode={isRenderMode}
          isRightCartOpen={isRightCartOpen}
          isPreviewPage={isPreviewPage}
          update3DAxis={this.update3DAxis}
          defaultView={defaultView}
          viewMode={viewMode}
          toggleView={this.toggleView}
          templateId={templateId}
        />
        {!isHighLowAvail && showWarningDialog && (
          <TemplateErrorConfirmationPopUp
            handleDownload={this.handleDowloadReport}
            handleClose={this.closeDeleteDialog}
            handleConfirm={this.closeDeleteDialog}
            open={!isHighLowAvail}
            descriptionText="One or more assets in the selected template have high or low poly missing and will not be available for styling"
            helpingText="Download report to know more"
            confirmText="CONTINUE"
            cancelText="DOWNLOAD REPORT"
            headers={downloadableReport.headers || []}
            data={downloadableReport.data || []}
          />
        )}
        <OverrideSceneConfirmation
          open={showSaveConfirm}
          user={updater_details}
          handleOverWrite={() => {
            this.setState({ showSaveConfirm: false }, () => {
              this.sceneSaveAction({})
            })
          }}
          handleClose={() => {
            this.setState({ showSaveConfirm: false })
          }}
          scene_diff={scene_diff}
        />
        <ReportIssueModal show={toggleReportIssueModal} />
      </>
    )
  }
}

Editor.propTypes = {
  auth: PropTypes.object,
  experienceDetail: PropTypes.object,
  templateList: PropTypes.array,
  cartData: PropTypes.object,
  clearCartData: PropTypes.func,
  clearExperience: PropTypes.func,
  fetchExperienceDetail: PropTypes.func,
  toggleTemplateModal: PropTypes.func,
  fetchTemplates: PropTypes.func,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  experienceDetail: state.experiences.experience,
  templateList: state.templates.templateList,
  cartData: state.cart,
  isRightDrawerOpen: state.Board.isRightDrawerOpen,
  activeOperations: state.activeOperations,
  renderData: state.scene.renderDetails,
  sceneCamera: state.scene.sceneData.camera,
  renderCamera: state.scene.sceneData.render_camera,
  savedSceneData: state.scene.sceneData,
  savedRenderDetails: state.scene.savedRenderDetails,
  selectedCamera: state.scene.sceneData.selected_camera,
  preferredRenderDetails: state.scene.preferred_render_details,
  templateDefaultCamera: state.experiences.experience?.template_default?.camera
    ? state.experiences.experience?.template_default?.camera
    : {},
  rendererType: state.templates.currentTemplateDetails.renderer_type,
  currentTemplateDetails: state.templates.currentTemplateDetails,
  boardAssetObjects: state.Board.assetObjects,
})

export default connect(mapStateToProps, {
  fetchExperienceDetail,
  toggleTemplateModal,
  fetchTemplates,
  showNotification,
  clearCartData,
  clearBoardData,
  clearExperience,
  addCartDataRedux,
  getBoard,
  addTcinToBoard,
  fetchTemplateDetails,
  clearTemplateThumbnailData,
  setRenderDetails,
  setControlsPosition,
  setSceneData,
  getRenderPageCamera,
  resetSceneData,
  storeRenderFormData,
  setDefaultPrefNoise,
  setSceneMode,
  saveSceneComplete,
})(withRouter(Editor))
