import { cloneDeep } from 'lodash'

import {
  ADD_TCIN_TO_BOARD,
  CLEAR_BOARD_DATA,
  SHOW_BULK_TCIN_DIALOG,
  OPEN_RIGHT_DRAWER,
  CLOSE_RIGHT_DRAWER,
  ADD_BOARD_ERROR_LIST,
} from './actionType'

export const initialState = {
  assets: [],
  bulkTcinDialogVisible: false,
  isRightDrawerOpen: false,
  isExplorerOpen: false,
  primary_tcins: [],
  assetObjects: {},
  boardError: [],
}

export default function Board(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_TCIN_TO_BOARD: {
      const payload = cloneDeep(action.payload)
      let assetObjects = {}
      if (payload.assets && payload.assets.length) {
        payload.assets.forEach((element) => {
          assetObjects[element.tcin || element.value || element.pid] = element
        })
      }
      return {
        ...state,
        ...payload,
        assetObjects,
      }
    }
    case CLEAR_BOARD_DATA: {
      return {
        ...initialState,
      }
    }
    case SHOW_BULK_TCIN_DIALOG: {
      return {
        ...state,
        bulkTcinDialogVisible: action.payload,
      }
    }
    case OPEN_RIGHT_DRAWER: {
      return {
        ...state,
        isRightDrawerOpen: true,
      }
    }
    case CLOSE_RIGHT_DRAWER: {
      return {
        ...state,
        isRightDrawerOpen: false,
      }
    }
    case ADD_BOARD_ERROR_LIST: {
      return { ...state, boardError: action.payload }
    }

    default:
      return state
  }
}
