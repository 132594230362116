import React from 'react'
import { uniqBy, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { withStyles } from '@mui/styles'
import config from '../../../config/config'
import { Grid, Button } from '@mui/material'
import { clearAssets } from '../../../store/explorer/actionCreator'
import {
  addTcinToBoard,
  removeTcinsFromBoard,
} from '../../../store/board/actionCreator'
import { removeMultipleAssets } from '../../designSpace'
import { showNotification } from '../../../store/notification/actionCreator'
import DraggableAssetCard from '../../assetExplorerCard/DraggableAssetCard'
import MaxAssetCard from '../../assetExplorerCard/MaxAssetCard'

const styles = (theme) => ({
  cardWrapper: {
    margin: '0 0 10px',
  },
  imgDimension: {
    height: '97px',
    width: '103px',
  },
  noResultText: {
    color: theme.palette.primary.darkFont,
    fontSize: '14px',
    textAlign: 'center',
  },
  subText: {
    color: theme.palette.primary.darkFont,
    fontSize: '12px',
    textAlign: 'center',
  },
  emptyResult: {
    margin: '140px auto',
  },
  emptySearchIcon: {
    margin: '0 150px',
    color: theme.palette.primary.contentLight,
  },

  maxCardWrapper: {
    height: '219px',
    width: '224px',
    border: '1px solid ' + theme.palette.primary.grayLight,
    borderRadius: '2px',
    backgroundColor: theme.palette.primary.light,
    margin: '5px 17px 41px 0',
  },
  customMedia: {
    height: '168px',
  },
  contentEnd: {
    margin: '0 auto',
  },
  cardWrapperMax: {
    width: 250,
    height: 348,
    borderRadius: 6,
    border: '1px solid ' + theme.palette.primary.veryLightGray,
    boxSizing: 'border-box',

    float: 'left',
    margin: '65px 15px 15px 0',
    '&:hover': {
      border: '1px solid ' + theme.palette.primary.dark,
    },
  },
  maxContentEnd: {
    margin: '20px auto',
  },
  menuItem: {
    margin: '30px 0 0 0',
    padding: '20px 17px',
  },
  assetsText: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 500,

    marginTop: '8px',
  },
  footerButtons: {
    textAlign: 'right',
  },
  cancelButton: {
    marginRight: 15,
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
  },
  panelBottom: {
    position: 'fixed',
    bottom: 50,
    boxShadow: '1px -3px 10px 1px rgb(0 0 0 / 20%)',
    right: 10,
    left: 85,
    backgroundColor: '#FFF',
    opacity: 1,
    zIndex: 99,
    padding: '10px 20px',
  },
  collectionCardItem: {
    width: 180,
  },
  noresultcontent: {
    textAlign: 'center',
    marginTop: 40,
  },
  card: {
    margin: '0 20px 20px 0px',
  },
})

class FavouriteAssets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItems: [],
      isAdding: false,
    }
  }

  addAssetsToBoard = (payload) => {
    this.props.addTcinToBoard(
      payload,
      (data) => {
        this.setState({ isAdding: false, selectedItems: [] })
        this.props.showNotification(true, 'Added Successfully', 'success')
      },
      (error) => {
        this.setState({ isAdding: false, selectedItems: [] })
        this.props.showNotification(
          true,
          'Getting some error, try after sometimes !!!',
          'error'
        )
      }
    )
  }

  deleteAssetCallBack = (
    tcin,
    success = () => {},
    failure = () => {},
    e,
    f,
    assetId
  ) => {
    const { removeTcinsFromBoard, boardId, showNotification } = this.props
    const deleteData = {
      board_id: boardId,
      assets: [tcin],
    }

    removeTcinsFromBoard(deleteData)
      .then(() => {
        removeMultipleAssets([assetId])
        success()
      })
      .catch(() => {
        failure()
        showNotification(
          true,
          "Sorry! we couldn't remove tcin from board. Try again!",
          'error'
        )
      })
  }

  clearSelectedItems = (e) => {
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ selectedItems: [] })
  }
  addMultipleAssetsToBoard = (e) => {
    e.nativeEvent.stopImmediatePropagation()
    const { selectedItems } = this.state
    const { sceneId } = this.props
    let assetsList = []
    selectedItems.forEach((element) => {
      let tempObj = {
        asset_type: 'TCIN',
        value: element,
      }
      assetsList.push(tempObj)
    })
    let payload = {
      experience_id: sceneId,
      assets: assetsList,
    }
    this.setState({ isAdding: true })
    this.addAssetsToBoard(payload)
  }

  handleSelectCheckBox = (e, isChecked, data) => {
    const { tcin } = data
    const { selectedItems } = this.state

    if (isChecked) {
      this.setState({
        selectedItems: [...selectedItems, tcin],
      })
    } else {
      let newTcinItems = selectedItems.filter((i) => i !== tcin)
      this.setState({
        selectedItems: [...newTcinItems],
      })
    }
  }

  renderBottomPanel = () => {
    const { selectedItems, isAdding } = this.state
    const { classes } = this.props
    const assetText =
      selectedItems.length === 0
        ? ''
        : selectedItems.length === 1
        ? '1 asset selected'
        : `${selectedItems.length} Assets selected`
    return (
      <div container className={classes.panelBottom}>
        <Grid container>
          <Grid item xs={6}>
            <div className={classes.assetsText}>{assetText}</div>
          </Grid>
          <Grid item xs={6} className={classes.footerButtons}>
            <Button
              className={classes.cancelButton}
              onClick={this.clearSelectedItems}
              disabled={isAdding}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={this.addMultipleAssetsToBoard}
              variant="contained"
              disabled={isAdding}
            >
              {isAdding ? 'Adding...' : 'Add Selected Asset'}
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderMinAssets = () => {
    const {
      classes,

      collectionList,
      collectionId,
      collectionAssets,
      handleCartActions,
    } = this.props

    let groupsList = collectionList.find((item) => item.id === collectionId)
    let listGroups = []
    try {
      listGroups =
        groupsList.groups === null ? [] : uniqBy(groupsList.groups, 'name')
    } catch (e) {
      console.log(e)
    }

    let assetsCollection = collectionAssets === null ? [] : collectionAssets
    let sortedList = [...listGroups, ...assetsCollection].map((item) => ({
      ...item,
      is_hp_available: item.is_hp_available || item.hp_available,
      is_lp_available: item.is_lp_available || item.lp_available,
      lodProfile: item.lod_available,
    }))

    return (
      sortedList && (
        <div
          style={{
            paddingTop: 0,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {sortedList.map((item, key) => {
            return (
              <div className={classes.cardWrapper} key={key}>
                <DraggableAssetCard
                  data={{
                    ...item,
                    short_description: item.product_description
                      ? item.product_description.short_description
                      : item.name
                      ? item.name
                      : '',
                  }}
                  maxWidth={130}
                  minWidth={130}
                  isGroup={item.hasOwnProperty('id') ? true : false}
                  handleCartActions={handleCartActions}
                />
              </div>
            )
          })}
          <div style={{ width: 120 }} className={classes.cardWrapper}></div>
          <div style={{ width: 120 }} className={classes.cardWrapper}></div>
        </div>
      )
    )
  }

  renderMaxAssets = () => {
    const {
      classes,

      collectionList,
      collectionId,
      collectionAssets,
      handleCartActions,
    } = this.props
    const { selectedItems } = this.state
    let groupsList = collectionList.find((item) => item.id === collectionId)
    let listGroups = []
    try {
      listGroups =
        groupsList.groups === null ? [] : uniqBy(groupsList.groups, 'name')
    } catch (e) {
      console.log(e)
    }

    let assetsCollection = collectionAssets === null ? [] : collectionAssets
    let sortedList = [...listGroups, ...assetsCollection].map((item) => ({
      ...item,
      is_hp_available: item.is_hp_available || item.hp_available,
      is_lp_available: item.is_lp_available || item.lp_available,
    }))
    return (
      sortedList &&
      sortedList.map((item, key) => {
        return (
          <div className={classes.card}>
            <MaxAssetCard
              selectable={item.hasOwnProperty('id') ? false : true}
              data={{
                ...item,
                short_description: item.product_description
                  ? item.product_description.short_description
                  : item.name
                  ? item.name
                  : '',
              }}
              selected={selectedItems.includes(item.tcin)}
              onSelect={this.handleSelectCheckBox}
              isGroup={item.hasOwnProperty('id') ? true : false}
              handleCartActions={handleCartActions}
            />
          </div>
        )
      })
    )
  }

  render() {
    const {
      collectionAssets,
      classes,
      loading,
      searchVal,
      maxExplorer,
      totalAssetsCount,
      collectionList,
      collectionId,
    } = this.props
    const { selectedItems } = this.state
    let groupList = collectionList.find((item) => item.id === collectionId)
    let totalGroupLength = 0
    try {
      totalGroupLength =
        groupList && groupList.groups ? groupList.groups.length : 0
    } catch (e) {
      console.log(e)
    }

    return (
      <>
        {!loading &&
          collectionAssets.length === 0 &&
          totalGroupLength === 0 &&
          (searchVal !== '' ? (
            <Grid item xs={12} className={classes.noresultsFoundContainer}>
              <div className={classes.noresultcontent}>
                <FontAwesomeIcon
                  icon={faSearch}
                  size="4x"
                  className={classes.emptySearchIcon}
                />
                <p className={classes.noResultText}>
                  Sorry we couldn't find any matches for "{searchVal}"
                  <br />
                  <span className={classes.subText}>
                    Maybe your search was too specific, please try searching
                    with another term{' '}
                  </span>
                </p>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.noresultsFoundContainer}>
              <p className={classes.noresultcontent}>
                <span className={classes.noResultText}>
                  No asset added to this collection
                </span>
              </p>
            </Grid>
          ))}
        {!loading && searchVal !== '' && collectionAssets.length ? (
          <Grid item xs={12} className={classes.searchResult}>
            We only found {totalAssetsCount} asset
            {totalAssetsCount > 1 ? 's' : ''} with your search keyword "
            {searchVal}"....
          </Grid>
        ) : (
          ''
        )}
        {maxExplorer ? this.renderMaxAssets() : this.renderMinAssets()}
        {maxExplorer && selectedItems.length > 0 && this.renderBottomPanel()}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  const {
    explorer,
    Board,
    collectionData,
    experiences: {
      experience: { experience_id },
    },
  } = state
  const {
    collectionList,
    totalCollectionCount,
    assetListSpecificCollection: collectionAssets,
    totalAssetsCount,
  } = collectionData

  const { assetList } = explorer
  const { board_id: boardId, isExplorerOpen } = Board
  return {
    assetList,
    totalAssetsCount,
    boardId,
    collectionList,
    isExplorerOpen,
    totalCollectionCount,
    collectionAssets,
    sceneId: experience_id,
  }
}

export default connect(mapStateToProps, {
  clearAssets,
  showNotification,
  addTcinToBoard,
  removeTcinsFromBoard,
})(withStyles(styles)(FavouriteAssets))
