import React, { useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import SearchIcon from '@mui/icons-material/Search'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import { ButtonGroup } from 'cgi-ui-components'
import SH_Logo_WhiteOnRed from '../../images/SH_Logo_WhiteOnRed.svg?url'
import { openSlackChannel } from '../../helpers/utils'
import AssetExplorer from './AssetExplorer'
import Favourites from './Favourites/index'
import {
  OPEN_EXPLORER,
  CLOSE_EXPLORER,
  CLEAR_SUB_VIEW,
} from '../../store/explorer/actionType'
import { ASSET_MODE_ARCH } from '../../constants/scene'

const useStyles = makeStyles(() => ({
  iconsWrapper: {
    position: 'fixed',
    zIndex: 101,
    top: 'Calc((100vh - 250px)/2)',
    left: 15,
  },
  img: {
    width: 24.01,
  },
  menuWrapper: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  errorIcons: {
    color: '#F88078',
  },
}))

const SideMenu = ({ navigate, userHaveWriteAccess, ...props }) => {
  const [active, setActive] = useState('')
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isExplorerOpen } = useSelector(({ explorer }) => {
    return {
      isExplorerOpen: explorer.isExplorerOpen,
    }
  })

  const { mode } = useSelector(({ scene }) => scene)

  useEffect(() => {
    if (isExplorerOpen) {
      setActive('Explorer')
    } else if (active == 'Explorer') {
      setActive('')
    }
    return () => {
      dispatch({ type: CLEAR_SUB_VIEW })
    }
  }, [isExplorerOpen])

  const togglePannel = (e, tab) => {
    e.stopPropagation()
    if (tab === 'Support' || (active === 'Support' && !tab)) {
      openSlackChannel()
      setActive(tab)
    } else if (tab === 'Home') {
      setActive(active)
      navigate('/home')
    } else {
      setActive(tab)
    }
    if (tab === 'Explorer') {
      dispatch({ type: OPEN_EXPLORER })
    } else {
      dispatch({ type: CLOSE_EXPLORER })
    }
  }
  const renderIcons = () => {
    const iconListForCapsuleBar = [
      {
        toolTip: 'Home',
        name: 'Home',
        placement: 'right',
        icon: (
          <img src={SH_Logo_WhiteOnRed} alt="logo" className={classes.img} />
        ),
      },
    ]
    if (userHaveWriteAccess) {
      iconListForCapsuleBar.push({
        toolTip: 'View assets',
        name: 'Explorer',
        placement: 'right',
        icon: <SearchIcon />,
      })
      iconListForCapsuleBar.push({
        toolTip: 'View favourites',
        name: 'Favourite',
        placement: 'right',
        icon: <FavoriteBorderIcon />,
      })
    }

    iconListForCapsuleBar.push({
      toolTip: 'Support',
      name: 'Support',
      placement: 'right',
      icon: <SentimentVeryDissatisfiedIcon color="primary" />,
    })

    return (
      <>
        <ButtonGroup
          data={iconListForCapsuleBar}
          onChange={togglePannel}
          orientation="vertical"
          defaultView={active}
        />
      </>
    )
  }

  return (
    <>
      <Box className={classes.iconsWrapper}>{renderIcons()}</Box>
      {active == 'Explorer' && (
        <AssetExplorer
          closeExplorer={() => {
            dispatch({ type: CLOSE_EXPLORER })
          }}
          isArchAssets={mode === ASSET_MODE_ARCH}
          {...props}
        />
      )}
      {active == 'Favourite' && (
        <Favourites
          closeFavDrawer={() => {
            if (active == 'Favourite') {
              setActive('')
            }
          }}
          {...props}
        />
      )}
    </>
  )
}

export default SideMenu
