import React from 'react'
import { withStyles } from '@mui/styles'

import Close from '@mui/icons-material/Close'
import { CustomIconButton } from 'cgi-ui-components'

const styles = (theme) => ({
  main: {
    padding: '5px 10px 10px',
  },
  details: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    padding: '0px 4px 22px',
    columnGap: 10,
  },
  heading: {
    fontWeight: 500,
    display: 'flex',
    width: '100%',
    height: '24px',
    position: 'relative',
  },
  subSection: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  closePlacement: {
    '&.MuiIconButton-root': {
      top: '-6px',
      right: '-14px',
      position: 'absolute',
      padding: 4,
    },
  },
})

class SearchAssetInfo extends React.Component {
  render() {
    const {
      classes,
      data = [],
      title = '',
      handleClickAction = () => {},
      specifyHeight = 0,
      cardSize = 'lrg',
    } = this.props

    return (
      <div className={classes.main}>
        <div className={classes.heading}>
          <div>{title}</div>
          <CustomIconButton
            className={classes.closePlacement}
            onClick={handleClickAction}
            label={'Close similar search'}
          >
            <Close />
          </CustomIconButton>
        </div>
        <div
          className={classes.details}
          style={{
            gridTemplateRows: specifyHeight
              ? `repeat(${data.length}, ${specifyHeight}px)`
              : 'auto',
          }}
        >
          {data.map((item) => (
            <>
              <span
                className={classes.subSection}
                style={{ fontSize: cardSize === 'lrg' ? 14 : 12 }}
              >
                {item.key}:
              </span>
              <span
                className={classes.subSection}
                style={{ fontSize: cardSize === 'lrg' ? 14 : 12 }}
              >
                {item.value}
              </span>
            </>
          ))}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(SearchAssetInfo)
