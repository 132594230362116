import { SET_ACTIVE_OPERATION } from './actionType'

export const initialState = {}

export default function oprPerformedReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ACTIVE_OPERATION: {
      const { payload } = action
      const newState = {
        ...state,
        ...payload,
      }
      return newState
    }
    default:
      return state
  }
}
