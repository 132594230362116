import * as THREE from 'three'
import { CSS2DRenderer, CSS2DObject } from './CSS2DRenderer'

const lineColor = 'blue'
const textColor = 'blue'
const lineSize = 0.1

export default class Measure extends THREE.Object3D {
  constructor({ scene, camera, renderer, objects }) {
    super()
    this.scene = scene
    this.camera = camera
    this.renderer = renderer
    this.objects = objects
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2(0, 0)
    this.drawingLine = false
    this.line = undefined
    this.lineId = 0
    this.measurementLabels = {}
    this.intersects = []
    this.labelRenderer = new CSS2DRenderer()
    this.labelRenderer.setSize(window.innerWidth, window.innerHeight)
    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = '0px'
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    document.body.appendChild(this.labelRenderer.domElement)
    this.cylinder = undefined
    this.startPosition = new THREE.Vector3()
    this.lines = []
    window.addEventListener('resize', this.windowresize, false)
  }
  windowresize = () => {
    this.labelRenderer.setSize(window.innerWidth, window.innerHeight)
  }
  updateObjects(objects) {
    this.objects = [...objects]
  }
  attach() {
    this.renderer.domElement.addEventListener('mousedown', this.onClick, false)
    document.addEventListener('mousemove', this.onDocumentMouseMove, false)
    this.renderer.domElement.addEventListener('mouseup', this.onmouseup, false)
    this.renderer.domElement.style.cursor = 'crosshair'
  }

  onClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    this.raycaster.setFromCamera(this.mouse, this.camera)
    this.intersects = this.raycaster.intersectObjects(this.objects, true)
    if (this.intersects.length > 0) {
      let intersects = this.intersects
      if (!this.drawingLine) {
        this.lineId++
        this.startPosition.copy(intersects[0].point)
        this.createText(this.startPosition)
        this.drawingLine = true
      }
    }
  }

  onmouseup = (event) => {
    event.preventDefault()
    this.raycaster.setFromCamera(this.mouse, this.camera)
    this.intersects = this.raycaster.intersectObjects(this.objects, true)
    if (this.intersects.length > 0) {
      if (this.drawingLine) {
        this.drawingLine = false
      }
    }
  }

  onDocumentMouseMove = (event) => {
    event.preventDefault()
    event.stopPropagation()

    let x, y

    let rect = this.renderer.domElement.getBoundingClientRect()
    x = (event.clientX / rect.width) * 2 - 1
    y = -((event.clientY - rect.top) / rect.height) * 2 + 1
    this.mouse.set(x, y)

    if (this.drawingLine) {
      this.raycaster.setFromCamera(this.mouse, this.camera)
      this.intersects = this.raycaster.intersectObjects(this.objects, true)

      if (this.intersects.length > 0) {
        const v0 = this.startPosition
        const v1 = new THREE.Vector3(
          this.intersects[0].point.x,
          this.intersects[0].point.y,
          this.intersects[0].point.z
        )
        const distance = v0.distanceTo(v1)
        this.measurementLabels[this.lineId].element.innerText =
          distance.toFixed(2) + 'in'
        this.measurementLabels[this.lineId].position.lerpVectors(v0, v1, 0.5)
        this.drawline(v0, v1)
      }
    }

    this.labelRenderer.render(this.scene, this.camera)
  }

  drawline(vstart, vend) {
    var cylLength = new THREE.Vector3().subVectors(vend, vstart).length()
    var cylGeom = new THREE.CylinderGeometry(lineSize, lineSize, cylLength, 16)
    cylGeom.translate(0, cylLength / 2, 0)
    cylGeom.rotateX(Math.PI / 2)
    var material = new THREE.MeshLambertMaterial({ color: lineColor })
    this.scene.remove(this.lines[this.lineId])
    this.lines[this.lineId] = new THREE.Mesh(cylGeom, material)
    this.lines[this.lineId].position.copy(vstart)
    this.lines[this.lineId].lookAt(vend) // and do the trick with orienation
    this.scene.add(this.lines[this.lineId])
    this.dispatchEvent({
      type: 'MeasureUpdate',
      test: {},
    })
  }
  createText(position) {
    const measurementDiv = document.createElement('div')
    measurementDiv.style.color = textColor
    measurementDiv.style.background = '#CCC'
    measurementDiv.style.border = '1px solid #AAA'
    measurementDiv.style.zIndex = 999
    measurementDiv.style.fontSize = '14px'
    const measurementLabel = new CSS2DObject(measurementDiv)
    measurementLabel.position.copy(position)
    this.measurementLabels[this.lineId] = measurementLabel
    this.scene.add(this.measurementLabels[this.lineId])
    this.dispatchEvent({
      type: 'MeasureUpdate',
      test: {},
    })
    this.labelRenderer.render(this.scene, this.camera)
  }

  detach() {
    this.renderer.domElement.removeEventListener('mousedown', this.onClick)
    document.removeEventListener('mousemove', this.onDocumentMouseMove)
    this.renderer.domElement.removeEventListener(
      'mouseup',
      this.onmouseup,
      false
    )
    this.renderer.domElement.style.cursor = 'default'
    //clear all measurement lines and text
    for (let i = 0; i <= this.lineId; i++) {
      if (this.lines[i]) {
        this.scene.remove(this.lines[i])
      }
      if (this.measurementLabels[i]) {
        this.scene.remove(this.measurementLabels[i])
      }
    }
    this.lineId = 0
    this.dispatchEvent({
      type: 'MeasureUpdate',
      test: {},
    })
    this.labelRenderer.render(this.scene, this.camera)
  }
}
