import React from 'react'
import { makeStyles } from '@mui/styles'
import { ColorPicker, Typography } from 'cgi-ui-components'
import TextureComponent from './TextureComponent'

import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  upperSection: {
    borderBottom: `1px solid #E0E0E0`,
  },
  spacing: {
    margin: '3px 0px 5px',
    padding: '0px 8px',
  },
}))

export const EditorUpperRightPanel = ({
  selected,
  favouriteList = [],
  handleFavsUpdate = () => {},
  handleError = () => {},
  variant = 'color',
  onColorPickerChange = () => {},
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.upperSection)}>
      <div className={classes.spacing}>
        <>
          <Typography
            variant="caption"
            label={
              variant === 'color'
                ? 'Select from color picker'
                : 'Texture Preview'
            }
            style={{ margin: '5px 0px', fontWeight: 500 }}
          />

          {variant === 'color' ? (
            <ColorPicker
              width={220}
              color={selected}
              favourites={favouriteList.map((item) =>
                item.color_hexcode.toUpperCase()
              )}
              handleFavsUpdate={handleFavsUpdate}
              handleError={handleError}
              maxFavourites={25}
              onColorPickerChange={onColorPickerChange}
            />
          ) : (
            <TextureComponent
              selectedTexture={selected?.texture_url}
              favouriteList={favouriteList}
              handleFavsUpdate={handleFavsUpdate}
              handleError={handleError}
              maxFavourites={25}
            />
          )}
        </>
      </div>
    </div>
  )
}

export default EditorUpperRightPanel
