import axios from 'axios'

import {
  FETCH_ARCH_ASSETS_INITIATED,
  FETCH_ARCH_ASSETS_SUCCESS,
  FETCH_ARCH_ASSETS_FAILED,
  CLEAR_RESULTS,
  FETCH_UPLOAD_STATUS_INITIATED,
  FETCH_UPLOAD_STATUS_FAILED,
  FETCH_UPLOAD_STATUS_SUCCESS,
  SET_STATUS_WINDOW_DISPLAY,
  SAVE_ASSETS_INITIATED,
  SAVE_ASSETS_SUCCESS,
  SAVE_ASSETS_FAIL,
  DELETE_ASSETS_INITIATED,
  DELETE_ASSETS_SUCCESS,
  DELETE_ASSETS_FAIL,
  EDIT_ASSETS_INITIATED,
  EDIT_ASSETS_SUCCESS,
  EDIT_ASSETS_FAIL,
  UPLOAD_STATUS_INITIATED,
  UPLOAD_STATUS_SUCCESS,
  UPLOAD_STATUS_FAIL,
  ASSET_UPLOAD_COMPLETED,
  HANDLE_SELECT_ARCHITECTURE_ASSET,
} from './actionType'

import { showNotification } from '../notification/actionCreator'
import apiConfig from '../../config/config'
import { getPerPageCount } from '../../helpers/utils'

let axiosCancelToken = axios.CancelToken
let axiosSource = axiosCancelToken.source()

export function fetchArchAssets(
  searchVal = '',
  pageNum = 1,
  category = '',
  search_by = 'category'
) {
  const payload = {
    query: searchVal,
    page: pageNum,
    count: getPerPageCount(),
    strict_search: true,
    search_by,
    sort_fields: {
      uploaded_timestamp: 'DESC',
    },
    category,
  }

  return function (dispatch) {
    dispatch(
      apiCallInitiated({
        type: FETCH_ARCH_ASSETS_INITIATED,
        data: { loading: true },
      })
    )
    if (pageNum === 1 && axiosSource) {
      axiosSource.cancel('manual')
    }

    axiosCancelToken = axios.CancelToken
    axiosSource = axiosCancelToken.source()

    return axios
      .post(`${apiConfig.assets.fetchArchitecturalAssets}`, payload, {
        cancelToken: axiosSource.token,
      })
      .then((response) => {
        if (search_by === 'asset') {
          response.data.categories = []
          response.data.search_response = [
            ...response.data.search_response.map((res) => ({
              ...res,
              thumbnail_path: res.thumbnail_url,
            })),
          ]
        }
        dispatch(apiCallSuccess(response.data))
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          showNotification(
            true,
            'Failed to fetch Architechtural Assets',
            'error'
          )
          dispatch(apiCallFailed())
        }
      })
  }
}

export function fetchUploadStatus(assetIds) {
  return function (dispatch) {
    let url = `${apiConfig.uploadAssets.getUploadStatus}`
    dispatch(
      apiCallInitiated({
        type: FETCH_UPLOAD_STATUS_INITIATED,
        data: {},
      })
    )
    return axios
      .post(url, assetIds)
      .then((response) => {
        dispatch(fetchUploadStatusSuccess(response.data))

        setTimeout(() => {
          dispatch(updateAssetUploadCompleted(response.data))
        }, 2000)
      })
      .catch((error) => {
        dispatch(fetchUploadStatusFail(error))
      })
  }
}
export function apiCallInitiated({ type, data }) {
  return {
    type,
    data,
  }
}

export function apiCallSuccess(data) {
  return {
    type: FETCH_ARCH_ASSETS_SUCCESS,
    data,
  }
}

export function apiCallFailed() {
  return {
    type: FETCH_ARCH_ASSETS_FAILED,
    data: [],
  }
}

export function clearResults() {
  return {
    type: CLEAR_RESULTS,
    data: [],
  }
}

export function fetchUploadStatusSuccess(data) {
  return {
    type: FETCH_UPLOAD_STATUS_SUCCESS,
    data,
  }
}

export function fetchUploadStatusFail() {
  return {
    type: FETCH_UPLOAD_STATUS_FAILED,
    data: {},
  }
}

export function displayProcessStatusWindow(showDisplay) {
  return {
    type: SET_STATUS_WINDOW_DISPLAY,
    data: showDisplay,
  }
}

export function saveAsset(
  title,
  description,
  category,
  assetTags,
  thumbpath,
  callback
) {
  return function (dispatch) {
    let url = `${apiConfig.uploadAssets.saveAsset}`
    dispatch(
      apiCallInitiated({
        type: SAVE_ASSETS_INITIATED,
        data: {},
      })
    )
    let params = {
      asset_name: title,
      asset_description: description,
      asset_type: 'ARCHITECTURE',
      category: category,
      tags: assetTags,
      thumbnail_path: thumbpath,
      business_high_poly_path: null,
      upload_status: null,
      is_lp_available: null,
      attached_files: null,
    }
    return axios
      .post(url, params)
      .then((response) => {
        let data = response.data
        if (data.hasOwnProperty('tcin')) {
          data['asset_name'] = data.tcin
          data.asset_id = data.tcin
        }
        dispatch(saveAssetSuccess(data))
        callback(response.data)
      })
      .catch((error) => {
        dispatch(saveAssetFail(error.response.status))
        showNotification(
          true,
          'Error in saving asset, Please try after sometime',
          'error'
        )
      })
  }
}

export function editAsset(
  title,
  description,
  category,
  assetTags,
  thumbpath,
  asset_id,
  is_high_poly_updated,
  is_thumbnail_updated,
  callback
) {
  return function (dispatch) {
    let url = `${apiConfig.uploadAssets.editAsset}`
    dispatch(
      apiCallInitiated({
        type: EDIT_ASSETS_INITIATED,
        data: {},
      })
    )
    let params = {
      asset_name: title,
      asset_id,
      asset_description: description,
      asset_type: 'ARCHITECTURE',
      category: category,
      tags: assetTags,
      thumbnail_path: thumbpath,
      business_high_poly_path: null,
      upload_status: null,
      is_lp_available: null,
      attached_files: null,
      is_high_poly_updated,
      is_thumbnail_updated,
    }
    return axios
      .post(url, params)
      .then((response) => {
        dispatch(editAssetSuccess(response.data))
        callback(response.data)
      })
      .catch((error) => {
        dispatch(editAssetFail(error))
        showNotification(
          true,
          'Error in saving asset, Please try after sometime',
          'error'
        )
      })
  }
}

export function deleteAsset(assetId) {
  return function (dispatch) {
    let payload = {
      headers: { 'Content-Type': 'application/json' },
    }
    let url = `${apiConfig.uploadAssets.deleteAsset}${assetId}`
    dispatch(
      apiCallInitiated({
        type: DELETE_ASSETS_INITIATED,
        data: {},
      })
    )
    return axios
      .delete(url, { data: payload })
      .then((response) => {
        dispatch(deleteAssetSuccess(response.data))
      })
      .catch((error) => {
        dispatch(deleteAssetFail(error))
        showNotification(
          true,
          'Error in deleting asset, Please try after sometime',
          'error'
        )
      })
  }
}

export function handleSelectArchitectureAsset(
  isSelected,
  data,
  clearData = false
) {
  return function (dispatch) {
    dispatch({
      type: HANDLE_SELECT_ARCHITECTURE_ASSET,
      payload: { isSelected, data, clearData },
    })
  }
}

export function UploadStatus(assetId) {
  return function (dispatch) {
    let url = `${apiConfig.uploadAssets.UploadStatus}${assetId}`
    dispatch(
      apiCallInitiated({
        type: UPLOAD_STATUS_INITIATED,
        data: {},
      })
    )
    return axios
      .get(url)
      .then((response) => {
        dispatch(uploadAssetsStatusSuccess(response.data))
      })
      .catch((error) => {
        dispatch(uploadAssetsStatusFail(error.response.status))
      })
  }
}

export function saveAssetSuccess(data) {
  return {
    type: SAVE_ASSETS_SUCCESS,
    data,
  }
}

export function saveAssetFail(error) {
  return {
    type: SAVE_ASSETS_FAIL,
    error,
    data: {},
  }
}

export function deleteAssetSuccess(data) {
  return {
    type: DELETE_ASSETS_SUCCESS,
    data,
  }
}

export function deleteAssetFail(error) {
  return {
    type: DELETE_ASSETS_FAIL,
    error,
    data: {},
  }
}

export function editAssetSuccess(data) {
  return {
    type: EDIT_ASSETS_SUCCESS,
    data,
  }
}

export function editAssetFail(error) {
  return {
    type: EDIT_ASSETS_FAIL,
    error,
    data: {},
  }
}

export function uploadAssetsStatusSuccess(data) {
  return {
    type: UPLOAD_STATUS_SUCCESS,
    data,
  }
}

export function uploadAssetsStatusFail(error) {
  return {
    type: UPLOAD_STATUS_FAIL,
    error,
    data: [],
  }
}
export function updateAssetUploadCompleted(data) {
  return {
    type: ASSET_UPLOAD_COMPLETED,
    data,
  }
}
