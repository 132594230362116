export const DETECT_OBJECT_INITIATED = 'DETECT_OBJECT_INITIATED'
export const DETECT_OBJECT_SUCCESS = 'DETECT_OBJECT_SUCCESS'
export const DETECT_OBJECT_FAIL = 'DETECT_OBJECT_FAIL'

export const FETCH_IMAGE_LABELS_INITIATED = 'FETCH_IMAGE_LABELS_INITIATED'
export const FETCH_IMAGE_LABELS_SUCCESS = 'FETCH_IMAGE_LABELS_SUCCESS'
export const FETCH_IMAGE_LABELS_FAIL = 'FETCH_IMAGE_LABELS_FAIL'

export const UPLOAD_ASSETS_INITIATED = 'UPLOAD_ASSETS_INITIATED'
export const UPLOAD_ASSETS_SUCCESS = 'UPLOAD_ASSETS_SUCCESS'
export const UPLOAD_ASSETS_FAIL = 'UPLOAD_ASSETS_FAIL'
