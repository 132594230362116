import React from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@mui/styles'

import axios from 'axios'
import apiconfig from '../../../config/config'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { showNotification } from '../../../store/notification/actionCreator'
import PdfDocument from '../../../components/Board/PdfDocument'
import { isExternalUser } from '../../../helpers/utils'
import { clearSubAssetsView } from '../../../store/explorer/actionCreator'

import AssetExplorer from '../../../components/AssetExplorer'
const assetsPerRequest = 3
const styles = (theme) => ({
  root: {
    height: 'Calc(100vh - 130px)',
    backgroundColor: '#FFF',
  },
})

export const downloadFile = (response, filename) => {
  const type = response.headers['content-type']
  const blob = new Blob([response.data], {
    type: type,
    encoding: 'UTF-8',
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
  link.remove()
}

class Assets extends React.Component {
  constructor(props) {
    super(props)
    this.assetData = []
    this.state = {
      downloadingAssets: false,
      downloadingExcel: false,
      downloadingPDF: false,
    }
  }

  downLoad3DContent = (assets) => {
    axios
      .post(apiconfig.explorer.downloadAssets, [...assets], {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const fileName = assets.map((item) => item.asset_id).join('_')
        downloadFile(response, `${fileName}_3D_assets.zip`)
        if (!this.assetData.length) {
          this.setState({ downloadingAssets: false })
          this.deSelectAllAssets()
          this.props.showNotification(
            true,
            'Successfully downloaded 3D assets',
            'success'
          )
        } else {
          if (this.assetData.length > assetsPerRequest) {
            this.downLoad3DContent(this.assetData.splice(0, assetsPerRequest))
          } else {
            this.downLoad3DContent(
              this.assetData.splice(0, this.assetData.length)
            )
          }
        }
      })
      .catch((e) => {
        console.log(e)
        this.setState({ downloadingAssets: false })
        this.props.showNotification(
          true,
          'Error in downloading 3D assets, please try after sometime',
          'error'
        )
      })
  }

  prepare3Dcontent = (data) => {
    this.setState({ downloadingAssets: true })
    //closeMenu()
    this.props.showNotification(true, 'Downloading! 3D assets started!', 'info')

    this.assetData = data.map((item) => ({
      asset_id: item.tcin,
      asset_type: item.asset_type === 'ARCH' ? 'ARCHITECTURE' : item.asset_type,
      is_sub_asset:
        item.sub_asset_type === 'STYLED' || item.sub_asset_type === 'UNBUNDLED',
      sub_asset_type: item.sub_asset_type,
    }))

    if (this.assetData.length > assetsPerRequest) {
      this.downLoad3DContent(this.assetData.splice(0, assetsPerRequest))
    } else {
      this.downLoad3DContent(this.assetData.splice(0, this.assetData.length))
    }
  }

  prepareExcelReport = (data) => {
    this.setState({ downloadingExcel: true })

    //closeMenu()
    this.props.showNotification(
      true,
      'Downloading! Asset Report started!',
      'info'
    )
    axios
      .post(
        apiconfig.explorer.downloadExcel,
        { assets: [...data] },
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        downloadFile(response, `AssetReport.xlsx`)
        this.setState({ downloadingExcel: false })
        this.deSelectAllAssets()
        this.props.showNotification(
          true,
          'Successfully downloaded Asset Report',
          'success'
        )
      })
      .catch((e) => {
        console.log(e)
        this.setState({ downloadingExcel: false })
        this.props.showNotification(
          true,
          'Error in exporting xls file, please try after sometime',
          'error'
        )
      })
  }

  generatePdfDocument = async (assets) => {
    const { memberOf, showNotification } = this.props
    const isExtUser = isExternalUser(memberOf)
    this.setState({ downloadingPDF: true })
    //  closeMenu()
    showNotification(true, 'Downloading! Asset Report started!', 'info')
    const pdfReport = {
      comments: [],
      assets,
      isExtUser,
    }
    await pdf(<PdfDocument data={pdfReport} />)
      .toBlob()
      .then((response) => {
        saveAs(response, `Asset Report.pdf`)
        this.setState({ downloadingPDF: false })
        this.deSelectAllAssets()
        showNotification(
          true,
          'Successfully downloaded Asset Report',
          'success'
        )
      })
  }

  setDeselectAssets = (method = () => {}) => {
    this.deSelectAllAssets = method
  }

  componentWillUnmount() {
    this.props.clearSubAssetsView()
  }

  render() {
    const { downloadingAssets, downloadingExcel, downloadingPDF } = this.state
    const { classes, memberOf, isArchAssets = false } = this.props
    const isExtUser = isExternalUser(memberOf)

    return (
      <div className={classes.root}>
        <AssetExplorer
          isArchAssets={isArchAssets}
          showAddRemoveIcon={false}
          showCopyPath
          setDeselectAssets={this.setDeselectAssets}
          footerOptions={{
            buttonType: 'menu',
            buttonLabel: 'Download',
            options: [
              {
                name: 'Download selected 3D assets',
                action: this.prepare3Dcontent,
                disabled: isExtUser || downloadingAssets,
              },
              {
                name: 'Asset Report.xlsx',
                action: this.prepareExcelReport,
                disabled: downloadingExcel,
              },
              {
                name: 'Asset Report.pdf',
                action: this.generatePdfDocument,
                disabled: downloadingPDF,
              },
            ],
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ auth: { memberOf } }) => ({ memberOf })

export default connect(mapStateToProps, {
  clearSubAssetsView,
  showNotification,
})(withStyles(styles)(Assets))
