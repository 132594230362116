import React from 'react'
import { shape, bool, string, func } from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Snackbar from '@mui/material/Snackbar'
import { withStyles } from '@mui/styles'
import { AlertComponent, CustomButton } from 'cgi-ui-components'
import { showNotification } from '../../store/notification/actionCreator'

const styles = (theme) => ({
  snackbar: {
    margin: theme.spacing.unit,
  },
  success: {
    backgroundColor: theme.palette.primary.successBgColor,
    color: theme.palette.primary.darkFont,
    fontSize: '14px',
    borderLeft: '16px solid ' + theme.palette.primary.greenDark,
    paddingLeft: 0,
  },
  error: {
    backgroundColor: theme.palette.primary.errorBgColor,
    color: theme.palette.primary.darkFont,
    fontSize: '14px',
    borderLeft: '16px solid ' + theme.palette.primary.targetRed,
    paddingLeft: 0,
  },
  info: {
    backgroundColor: theme.palette.primary.lightBlueBackground,
    color: theme.palette.primary.darkFont,
    fontSize: '14px',
    borderLeft: '16px solid ' + theme.palette.primary.dark,
    paddingLeft: 0,
  },
  warning: {
    backgroundColor: theme.palette.primary.warningBgColor,
    color: theme.palette.primary.darkFont,
    fontSize: '14px',
    borderLeft: '16px solid ' + theme.palette.primary.warningColor,
    paddingLeft: 0,
  },
  iconSuccess: {
    fontSize: '2em',
    color: theme.palette.primary.greenDark,
    padding: '6px 10px',
  },
  iconWarning: {
    fontSize: '2em',
    color: theme.palette.primary.warningColor,
    padding: '6px 10px',
  },
  iconError: {
    fontSize: '2em',
    color: theme.palette.primary.targetRed,
    padding: '6px 10px',
  },
  iconInfo: {
    fontSize: '2em',
    color: theme.palette.primary.dark,
    padding: '6px 10px',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2px',
    width: '100%',
    maxWidth: 780,
  },
  fullWidthMessage: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2px',
    width: '100%',
  },
  closeIcon: {
    fontSize: '0.7em',
    marginTop: '14px',
    color: theme.palette.primary.mediumGrey,
  },
  messageWrapper: {
    paddingLeft: 10,
  },
  icon: {
    fontSize: 20,
  },
})

function CustomSnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    isFullWidth = false,
    description,
    ...other
  } = props
  return (
    <AlertComponent severity={variant} title={message} onClose={onClose}>
      {description ? description : null}
    </AlertComponent>
  )
}

export const SnackbarContentWrapper = withStyles(styles)(CustomSnackbarContent)

const styles2 = (theme) => ({
  margin: {
    marginTop: '60px',
  },
})
export class Notifications extends React.Component {
  static propTypes = {
    notificationActions: shape({
      showNotification: func,
    }),
    notificationIsShown: bool,
    notificationMessage: string,
    notificationVariant: string,
    notificationDescription: string,
  }

  constructor(props) {
    super(props)
    this.state = {
      message: undefined, // Store message in state so text doesn't disappear during animation
      vertical: 'top',
      horizontal: 'right',
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notificationMessage) {
      this.setState({
        message: nextProps.notificationMessage,
        variant: nextProps.notificationVariant,
        description: nextProps.notificationDescription,
      })
    }
  }

  render() {
    const { notificationIsShown, classes } = this.props
    const { message, variant, description } = this.state
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={notificationIsShown}
        onClose={this.handleRequestClose}
        autoHideDuration={variant === 'info' ? Math.Infinity : 7000}
        className={classes.margin}
      >
        <div>
          <SnackbarContentWrapper
            onClose={this.handleRequestClose}
            variant={variant}
            message={message}
            description={description}
          />
        </div>
      </Snackbar>
    )
  }

  handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    event && event.nativeEvent && event.nativeEvent.preventDefault() // Added in order to prevent any other click handler being called. for example of AssetExplorer
    this.props.showNotification(false)
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showNotification }, dispatch)

const mapStateToProps = (state) => {
  const { notification } = state
  const { isShown, message, variant, description } = notification
  return {
    notificationIsShown: isShown,
    notificationMessage: message,
    notificationVariant: variant,
    notificationDescription: description,
  }
}
export const NotificationToast = withStyles(styles2)(Notifications)
export default connect(mapStateToProps, mapDispatchToProps)(NotificationToast)
