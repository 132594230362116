import filter from 'lodash/filter'
import React, { Fragment } from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import ButtonGroup from '@mui/material/ButtonGroup'
import PropTypes from 'prop-types'
// import { Rnd } from 'react-rnd'
import RenderList from '../renderList'
import Banner from '../Banner'
import {
  getSceneDataForRender,
  removeControls,
  getZoomValue,
} from '../designSpace'
import {
  renderJson,
  fetchExperienceImages,
} from '../../store/experienceImages/actionCreator'
import { closeRightDrawer } from '../../store/board/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import {
  setControlsPosition,
  setSceneData,
  setNewCamera,
  setCameraSnapshot,
  deleteCameraView,
  saveSceneInitiated,
} from '../../store/scene/ActionCreator'
import { saveAnnotationMode } from '../../store/comments/ActionCreator'

import {
  ERROR_MSG_FOR_RENDER_FAILED,
  MSG_FOR_INITIATE_RENDER,
  CHECKING_MAX_RENDER,
  ERR_MSG_FOR_MAX_RENDER_INPROGRESS,
  ERR_MSG_FOR_CHECKING_MAX_RENDER_INPROGRESS,
} from '../../constants/displayMessages'
import { OverlayLoader } from '../../components/Loaders'
import config from '../../config/config'
import {
  RENDER_RIGHT_DRAWER,
  RENDER_HEADER_HEIGHT,
} from '../../constants/scene'
import RenderDrawer from '../../components/renderDrawer'
import RenderFrame from './renderFrame'
import { DEFAULT_NOISE, DEFAULT_TIME } from '../../constants/common'
import { CustomButton, CustomIconButton, Typography } from 'cgi-ui-components'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp'
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'

const styles = (theme) => ({
  backButtonRoot: {
    width: 240,
    marginLeft: 10,
    display: 'block',
    position: 'absolute',
    zIndex: 50,
    top: 20,
  },
  preview: {
    width: 265,
    marginLeft: 10,
    display: 'block',
    position: 'absolute',
    zIndex: 50,
    top: 20,
    right: 20,
  },
  formClosed: {
    margin: '10px 0px',
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
  switchButtons: {
    position: 'fixed',
    background: '#FFF',
    width: 40,
    height: 150,
    display: 'flex',
    flexFlow: 'column',
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 50,
    '& span': {
      margin: '10px 0',
      borderRadius: '50%',
      padding: 7,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#E7EDFA',
      },
      '&.selected': {
        backgroundColor: '#E7EDFA',
      },
    },
  },
  tooltip: {
    padding: '10px  5px',
    width: 210,
    '& span': {
      fontSize: 12,
    },
    '& h2': {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 8,
    },
  },
  bannerContainer: {
    position: 'relative',
    top: RENDER_HEADER_HEIGHT, // Note this height depends upon the height of AppBar used in this components. This needs to be changed if height of AppBar changed
    zIndex: 10,
  },
})

class RenderHeader extends React.Component {
  constructor(props) {
    super(props)
    this.tabs = [
      { id: 'render', label: 'Render Scene' },
      { id: 'images', label: 'Preview & Scene Export' },
    ]
    this.state = {
      pageActive:
        props.activeTabId ||
        (props.userHaveWriteAccess ? this.tabs[0].id : this.tabs[1].id),
      showOverlayLoader: false,
      ovellayLoaderText: '',
      dnd: false,
      isSaving: false,
      viewCameraSettings: false,
      renderFormPosition: {},
    }
    this.resizeRatio = 1
  }
  timer = undefined
  componentDidMount = () => {
    const {
      setIsPreviewPage = () => {},
      defaultView,
      selectedCamera,
    } = this.props
    const { pageActive } = this.state
    this.updateControlsPosition(selectedCamera === 'default')
    setIsPreviewPage(pageActive === 'images')
    this.saveInterval = setInterval(() => {
      this.callSaveScene()
    }, config.jsonAutoSaveTime * (60 * 1000))
  }
  componentDidUpdate(prevProps) {
    if (this.props.selectedCamera !== prevProps.selectedCamera) {
      this.updateControlsPosition(this.props.selectedCamera == 'default')
    }
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval)

    document.removeEventListener('onAssetLoaded', this.showLoader)
    document.removeEventListener('keyup', this.handleMultiKeyPress, false)
  }

  callSaveScene = () => {
    const { sceneId, saveScene, assetsLoading } = this.props
    const { isSaving } = this.state
    if (sceneId && !isSaving && !assetsLoading) {
      this.setState({
        isSaving: true,
      })
      saveScene({ autosave: true })
        .then((res) => {
          this.setState({
            isSaving: false,
          })
        })
        .catch((e) => {
          this.setState({
            isSaving: false,
          })
        })
    }
  }

  switchPage = (value) => {
    const {
      setIsPreviewPage = () => {},
      history,
      match: { url },
      saveAnnotationMode = () => {},
    } = this.props
    this.tabs.filter((tab) => {
      if (tab.id === value) {
        this.setState(
          {
            pageActive: tab.id,
          },
          () => {
            history.push(`?rendermode=true`)
          }
        )
        setIsPreviewPage(value === 'images')
        saveAnnotationMode(false)
      }
    })
  }
  checkMaxAndRender = () => {
    this.setState({
      showOverlayLoader: true,
      ovellayLoaderText: CHECKING_MAX_RENDER,
    })
    fetchExperienceImages(
      this.props.sceneId,
      (response) => {
        const prevInRendering = filter(response.unflagged_renders, {
          render_status: 'RENDERING',
        }).length
        if (prevInRendering >= config.renderConfig.maxRenderThreshold) {
          this.setState({
            showOverlayLoader: false,
            ovellayLoaderText: '',
          })
          this.props.showNotification(
            true,
            ERR_MSG_FOR_MAX_RENDER_INPROGRESS,
            'error'
          )
        } else {
          this.prepareRenderJson()
        }
      },
      (error) => {
        console.log(error)
        this.setState({
          showOverlayLoader: false,
          ovellayLoaderText: '',
        })
        this.props.showNotification(
          true,
          ERR_MSG_FOR_CHECKING_MAX_RENDER_INPROGRESS,
          'error'
        )
      }
    )
  }
  prepareRenderJson = () => {
    this.setState({
      showOverlayLoader: true,
      ovellayLoaderText: MSG_FOR_INITIATE_RENDER,
    })
    const {
      renderData: {
        passes,
        noise,
        width,
        height,
        clipNear,
        x = 0,
        y = 0,
        maxHeight,
        maxWidth,
        resizeRatio,
        frameX = 0,
        frameY = 0,
        time,
      },
      sceneId,
      saveScene,
      updateRenderCount = () => {},
    } = this.props

    let data = getSceneDataForRender()

    data.experience_id = sceneId
    data.scene_data.camera.zoomFactor =
      (data.scene_data.camera.zoomFactor *
        data.scene_data.camera.renderHeight) /
      data.scene_data.camera.renderWidth
    data.scene_data.camera.zoomFactor = getZoomValue()
    data.render_details = {
      pass_limit: passes !== null ? passes : 0,
      noise_level: noise !== null && noise > 0 ? noise : DEFAULT_NOISE,
      image_width: width * resizeRatio,
      image_height: height * resizeRatio,
      scene_width: maxWidth * resizeRatio,
      scene_height: maxHeight * resizeRatio,
      crop_x: (x + frameX) * resizeRatio,
      crop_y: (y + frameY) * resizeRatio,
      clipNear,
      time_limit_minutes: time !== null ? time : DEFAULT_TIME,
    }
    saveScene({})
    updateRenderCount(1)
    renderJson(
      data,
      () => {
        this.switchPage('images')
        this.props.history.push(`/render/${sceneId}`)

        this.setState({ showOverlayLoader: false, ovellayLoaderText: '' })
      },
      this.renderFailed
    )
  }
  renderFailed = (error) => {
    this.setState({ showOverlayLoader: false, ovellayLoaderText: '' })
    this.props.showNotification(true, ERROR_MSG_FOR_RENDER_FAILED, 'error')
    this.switchPage('render')
  }
  switchDnd = (dnd) => {
    const { disableCameraSettings } = this.props
    this.setState({ dnd }, () => {
      removeControls()
      disableCameraSettings(!dnd)
    })
  }

  calculateResizeRatio(
    templateRenderWidth,
    templateRenderHeight,
    maxWidth,
    maxHeight
  ) {
    const {
      setRenderFormData = () => {},
      renderData: { resizeRatio },
    } = this.props
    this.resizeRatio = 1

    if (templateRenderWidth > maxWidth || templateRenderHeight > maxHeight) {
      if (maxWidth > maxHeight) {
        if (templateRenderHeight > maxHeight) {
          this.resizeRatio = templateRenderHeight / maxHeight
          if (this.resizeRatio * maxWidth < templateRenderWidth) {
            this.resizeRatio = templateRenderWidth / maxWidth
          }
        } else if (templateRenderWidth > maxWidth) {
          this.resizeRatio = templateRenderWidth / maxWidth
        }
      } else if (maxWidth < maxHeight) {
        this.resizeRatio = templateRenderWidth / maxWidth
      } else if (maxWidth === maxHeight) {
        this.resizeRatio = templateRenderHeight / maxHeight
      }
    } else if (
      templateRenderWidth < maxWidth &&
      templateRenderHeight < maxHeight
    ) {
      if (maxWidth > maxHeight) {
        if (templateRenderHeight < templateRenderWidth) {
          this.resizeRatio = maxHeight / templateRenderHeight //increase  based on width
        } else if (templateRenderWidth < templateRenderHeight) {
          this.resizeRatio = maxWidth / templateRenderWidth
        } else {
          this.resizeRatio = 1
        }
        //console.log("in render   header",this.resizeRatio)
      } else if (maxWidth < maxHeight) {
        this.resizeRatio = templateRenderWidth / maxWidth
      } else if (maxWidth === maxHeight) {
        this.resizeRatio = templateRenderHeight / maxHeight
      }
    }

    if (resizeRatio !== this.resizeRatio) {
      setRenderFormData({ resizeRatio: this.resizeRatio })
    }
  }

  setViewCameraSettings = (e, value) => {
    const { selectedCamera } = this.props
    this.setState(
      {
        viewCameraSettings: value,
        renderFormPosition: {
          height: `Calc(100vh - ${
            e.currentTarget.getBoundingClientRect().top
          }px)`,
        },
      },
      () => {
        this.updateControlsPosition(selectedCamera == 'default')
      }
    )
  }

  updateCamera = (value, reset = false) => {
    const { selectCamera, selectedCamera } = this.props
    selectCamera(value, reset)
    this.updateControlsPosition(selectedCamera == 'default')
  }

  updateControlsPosition = (defaultView) => {
    const { setControlsPosition } = this.props
    const { viewCameraSettings } = this.state
    let width = defaultView ? 40 : 0
    setControlsPosition(viewCameraSettings ? 370 - width : 70 - width)
  }

  savePreferredCameraSettings = (capture) => {
    const { setSceneData, saveScene, setCameraSnapshot, saveSceneInitiated } =
      this.props
    saveSceneInitiated()
    setSceneData({ saveCustomCamera: true })
    setTimeout(() => {
      saveScene({
        capture,
        cameraSnapshotClbk: (cameraKey, response) => {
          const {
            scene_data: { preferred_cameras = {} },
          } = response
          setCameraSnapshot(
            preferred_cameras[cameraKey].cameraThumbnailLocation
          )
          setSceneData({
            preferred_cameras: response.scene_data.preferred_cameras,
          })
        },
      })
    }, 500)
  }
  render() {
    const { updateSelectionArea } = this.props
    let renderRightDrawer = RENDER_RIGHT_DRAWER
    let renderHeaderHeight = RENDER_HEADER_HEIGHT
    const {
      classes: { button, activeTab, ...classes },
      setRenderMode,
      renderData: {
        height: cHeight = window.innerHeight - renderHeaderHeight,
        width: cWidth = window.innerWidth - renderRightDrawer,
        x = 0,
        y: top = 0,
        maxWidth,
        maxHeight,
        resWidth,
        resHeight,
        frameHeight,
        frameWidth,
        frameX,
        frameY,
        locked,
      },
      sceneId,
      userHaveWriteAccess,
      saveScene,
      history,
      isPreviewMode = false,
      renderId,
      setPreviewMode = () => {},
      setRenderFormData,
      renderData,
      currentCameraPosition,
      currentCameraTarget,
      currentCameraAngles,
      position,
      updateCoords,
      settingsDisabled,
      disableStatusBar,
      updateCameraPos,
      updateZoomFactor,
      resetCameraSettings,
      match,
      setIsPreviewPage,
      defaultView,

      saveAnnotationMode = () => {},
      closeRightDrawer = () => {},
      selectedCamera,
      rendererType,
      assetsLoading = true,
      setNewCamera,
      ...rest
    } = this.props
    const y = top < 0 ? 0 : top
    const {
      pageActive,
      showOverlayLoader,
      ovellayLoaderText,
      dnd,
      viewCameraSettings,
    } = this.state
    let height = parseInt(cHeight) + y < maxHeight ? cHeight : maxHeight - y
    let width = parseInt(cWidth) + x < maxWidth ? cWidth : maxWidth - x

    this.calculateResizeRatio(resWidth, resHeight, maxWidth, maxHeight)
    const showCameraControls = !locked
    return (
      <Fragment>
        <>
          <div className={classes.backButtonRoot}>
            <CustomButton
              label="Back to scene"
              onClick={() => {
                saveScene({})
                setRenderMode(false)
                setPreviewMode(false)
                saveAnnotationMode(false)
                closeRightDrawer()
              }}
              color="secondary"
              startIcon={<ArrowBackSharpIcon />}
              variant="contained"
            />
          </div>
          <div className={classes.preview}>
            {userHaveWriteAccess && (
              <div>
                <CustomButton
                  label="Preview render shots and export"
                  onClick={() => {
                    history.push(`/render/${sceneId}`)
                  }}
                  color="secondary"
                  endIcon={<ArrowForwardSharpIcon />}
                  variant="contained"
                />
              </div>
            )}
            {!assetsLoading && !viewCameraSettings ? (
              <div className={classes.formClosed}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 5px',
                    justifyContent: 'space-around',
                  }}
                >
                  <CustomIconButton
                    onClick={(e) => this.setViewCameraSettings(e, true)}
                    size="small"
                  >
                    <ArrowRightRoundedIcon fontSize="small" />
                  </CustomIconButton>
                  <Typography
                    variant="body2"
                    label={'Camera Settings'}
                    sx={{ fontWeight: 500 }}
                  />
                  <CustomButton
                    label="Render"
                    onClick={() => {
                      this.checkMaxAndRender()
                    }}
                    color="primary"
                    size="small"
                    variant="contained"
                  />
                </div>
              </div>
            ) : (
              !assetsLoading && (
                <div
                  style={{
                    height: this.state.renderFormPosition.height,
                    overflowY: 'auto',
                    marginTop: 10,
                    borderRadius: 5,
                  }}
                >
                  <RenderDrawer
                    sceneId={sceneId}
                    setRenderFormData={setRenderFormData}
                    renderData={{ ...renderData }}
                    currentCameraPosition={currentCameraPosition}
                    currentCameraTarget={currentCameraTarget}
                    currentCameraAngles={currentCameraAngles}
                    position={position}
                    updateCoords={updateCoords}
                    settingsDisabled={settingsDisabled}
                    disableStatusBar={disableStatusBar}
                    updateCameraPos={updateCameraPos}
                    updateZoomFactor={updateZoomFactor}
                    resetCameraSettings={resetCameraSettings}
                    userHaveWriteAccess={userHaveWriteAccess}
                    saveScene={saveScene}
                    match={match}
                    history={history}
                    setIsPreviewPage={setIsPreviewPage}
                    isPreviewMode={isPreviewMode}
                    activeTabId={this.state.activeTabId}
                    checkMaxAndRender={this.checkMaxAndRender}
                    defaultView={defaultView}
                    toggleDefaultView={this.setDefaultView}
                    savePreferredCameraSettings={
                      this.savePreferredCameraSettings
                    }
                    updateCamera={this.updateCamera}
                    rendererType={rendererType}
                    setViewCameraSettings={(e) =>
                      this.setViewCameraSettings(e, false)
                    }
                    setNewCamera={setNewCamera}
                    {...rest}
                  />
                </div>
              )
            )}
          </div>
        </>

        {pageActive === 'images' ? (
          <>
            <div className={classes.bannerContainer}>
              <Banner />
            </div>
            <RenderList
              sceneId={sceneId}
              userHaveWriteAccess={userHaveWriteAccess}
              history={history}
              isPreviewMode={isPreviewMode}
              renderId={renderId}
              setPreviewMode={setPreviewMode}
            />
          </>
        ) : (
          <Fragment>
            {/*Commented for removal of crop area size STHB-2017*/}
            {/* {selectedCamera !== 'default' && showCameraControls && (
              <ButtonGroup
                className={classes.switchButtons}
                style={{
                  right: viewCameraSettings ? 310 : 10,
                  bottom: 48,
                }}
                aria-label="outlined secondary button group"
              >
                <ActionButton
                  icon={axis}
                  hoverIcon={axis_h}
                  width={25}
                  height={25}
                  selected={!dnd}
                  onClick={() => {
                    this.switchDnd(false)
                  }}
                  placement="left"
                  toolTip={
                    <div className={classes.tooltip}>
                      <h2> Take Control of the assets</h2>{' '}
                      <span>
                        Select an asset to move or <br />
                        rotate in render area{' '}
                      </span>
                    </div>
                  }
                />
                <ActionButton
                  icon={frame_resize}
                  hoverIcon={frame_resize_h}
                  placement="left"
                  selected={dnd}
                  toolTip={
                    <div className={classes.tooltip}>
                      <h2>Resize Crop Area</h2>
                      <span>
                        Drag or Adjust sides of the red <br />
                        bounding box to resize
                      </span>
                    </div>
                  }
                  onClick={() => {
                    this.switchDnd(true)
                  }}
                />
              </ButtonGroup>
            )} */}
            <RenderFrame
              renderData={this.props.renderData}
              showCropFrame={
                !dnd || (dnd && locked) || selectedCamera == 'default'
              }
            />

            {/* {dnd && selectedCamera !== 'default' && !locked && (
              <div
                style={{
                  height: maxHeight,
                  width: maxWidth,
                  top: RENDER_HEADER_HEIGHT,
                  left: 0,
                  position: 'fixed',
                  bottom: 2,
                  zIndex: 20,
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    height: frameHeight,
                    width: frameWidth,
                    top: frameY,
                    left: frameX,
                    position: 'absolute',
                  }}
                >
                  <Rnd
                    style={{ border: '1px solid red' }}
                    onResize={(e, dir, elem, delta, { x, y }) => {
                      updateSelectionArea(
                        x,
                        y > 0 ? y : 0,
                        elem.offsetHeight,
                        elem.offsetWidth
                      )
                    }}
                    bounds="parent"
                    onDrag={(e, { x, y, node: elem }) => {
                      updateSelectionArea(
                        x,
                        y,
                        elem.offsetHeight,
                        elem.offsetWidth
                      )
                    }}
                    default={{
                      bounds: 'parent',
                      x,
                      y,
                      maxHeight: width,
                      maxWidth: height,
                      minWidth: 100,
                      minHeight: 100,
                    }}
                    size={{ width, height }}
                  />
                </div>
              </div>
            )} */}
          </Fragment>
        )}
        {showOverlayLoader ? (
          <OverlayLoader displayText={ovellayLoaderText} />
        ) : null}
      </Fragment>
    )
  }
}

RenderHeader.propTypes = {
  renderData: PropTypes.object.isRequired,
  sceneId: PropTypes.string.isRequired,
}

const mapStateToProps = ({
  scene: {
    renderDetails,
    cameraKeys,
    sceneData,
    sceneData: { selected_camera: selectedCamera },
    savedRenderDetails,
    preferred_render_details,
    savingScene,
  },
  experiences: {
    experience: {
      template_default: { camera },
    },
  },
}) => ({
  renderDetails,
  cameraKeys,
  sceneData,
  templateDefaultCamera: camera,
  selectedCamera,
  savedRenderDetails,
  preferred_render_details,
  savingScene,
})
export default connect(mapStateToProps, {
  setSceneData,
  showNotification,
  saveAnnotationMode,
  setControlsPosition,
  closeRightDrawer,
  setNewCamera,
  setCameraSnapshot,
  deleteCameraView,
  saveSceneInitiated,
})(withStyles(styles)(RenderHeader))
