import * as THREE from 'three'

const planeColor = 0x666666
const minimumDistance = 36 //Distance in inches to show snapping helpers in horizontal axis
export default class ObjectSnapper extends THREE.Object3D {
  constructor({ camera, renderer, objects }) {
    super()
    this.interSectionRayDirection = new THREE.Vector3(0.0, -1, 0)
    this.intersectionCaster = new THREE.Raycaster(
      new THREE.Vector3(),
      this.interSectionRayDirection
    )
    this.caster = new THREE.Raycaster()
    this.secondBB = new THREE.Box3()
    this.selectedBB = new THREE.Box3()
    this.groundLevelPlane = null //helper plane lowest posible position, probably on ground
    this.yPlane = null //helper plane  next posible position on y axis (Vertical direction)
   // this.yCollisionPlane = null //helper plane to show on top of colliding asset on y axis
    this.mouseArgs = new THREE.Vector2()
    this.groundPlaneMaterial = new THREE.MeshBasicMaterial({
      color: planeColor,
      side: THREE.DoubleSide,
    })
    this.assetPlaneMaterial = new THREE.MeshBasicMaterial({
      color: planeColor,
      side: THREE.DoubleSide,
    })
    this.assetPlaneMaterial2 = new THREE.MeshBasicMaterial({
      color: planeColor,
      side: THREE.DoubleSide,
    })
    this.camera = camera
    this.renderer = renderer
    this.sceneObjects = objects
    this.objects = []
    this.selected = undefined
    this.tempVector = new THREE.Vector3()

    this.groundPosition = 0.01
    this.assetBox = new THREE.Box3()
    this.templateBox = new THREE.Box3()
    this.enableDetection = false

    this.horizontalPlanes = { x: null, nx: null, z: null, nz: null }
    this.horizontalShift = {
      x: -1,
      nx: +1,
      z: -1,
      nz: +1,
    }
  }

  setAssets = (objects) => {
    this.sceneObjects = objects
  }

  getvector(x, y, z) {
    this.tempVector.set(x, y, z)
    return this.tempVector
  }
  attach(object) {
    this.selected = object
    this.selectedBB.setFromObject(this.selected)
    this.objects = this.sceneObjects.filter(
      ({ name }) => name !== this.selected.name
    )
    this.createPlanes()
    this.update()
    this.renderer.addEventListener('mousedown', this.mouseDownListener)
    this.dispatchEvent({ type: 'attach', object: this.selected })
  }

  setTemplate(template) {
    this.template = template
    this.templateBox.setFromObject(template)
    // this.sceneObjects.push(template)
    // console.log(this.objects)
  }

  detach() {
    this.removePlanes()
    this.renderer.removeEventListener('mousedown', this.mouseDownListener)
    this.selected = undefined
  }
  update(object) {
    if (!object || !this.enableDetection) {
      return
    }
    if (object && this.selected && object.name !== this.selected.name) {
      this.attach(object)
    }
    this.selectedBB.setFromObject(this.selected)
    this.groundLevelPlane.visible = false
    this.yPlane.visible = false
   //this.yCollisionPlane.visible = false
    if (this.selectedBB.min.y.toFixed(2) > this.groundPosition.toFixed(2)) {
      this.groundLevelPlane.position.copy(
        this.getvector(
          this.selected.position.x,
          this.groundPosition,
          this.selected.position.z
        )
      )
      this.groundLevelPlane.visible = true
    } else {
      this.groundLevelPlane.visible = false
    }
    this.detectcollision()
  }

  removePlanes() {
    if (this.yPlane !== null) {
      this.remove(this.yPlane)
      this.remove(this.groundLevelPlane)
      // this.remove(this.yCollisionPlane)
    }

    if (this.horizontalPlanes['x']) {
      this.remove(this.horizontalPlanes['x'])
      this.horizontalPlanes['x'] = null
    }
    if (this.horizontalPlanes['nx']) {
      this.remove(this.horizontalPlanes['nx'])
      this.horizontalPlanes['nx'] = null
    }
    if (this.horizontalPlanes['z']) {
      this.remove(this.horizontalPlanes['z'])
      this.horizontalPlanes['z'] = null
    }
    if (this.horizontalPlanes['nz']) {
      this.remove(this.horizontalPlanes['nz'])
      this.horizontalPlanes['nz'] = null
    }
    this.groundLevelPlane = null
    this.yPlane = null
   // this.yCollisionPlane = null
  }
  hidePlanes() {
    if (this.groundLevelPlane !== null) {
      this.groundLevelPlane.visible = false
    }
    if (this.yPlane !== null) {
      this.yPlane.visible = false
    }
    // if (this.yCollisionPlane !== null) {
    //   this.yCollisionPlane.visible = false
    // }
    if (this.horizontalPlanes['x']) {
      this.horizontalPlanes['x'].visible = false
    }
    if (this.horizontalPlanes['nx']) {
      this.horizontalPlanes['nx'].visible = false
    }
    if (this.horizontalPlanes['z']) {
      this.horizontalPlanes['z'].visible = false
    }
    if (this.horizontalPlanes['nz']) {
      this.horizontalPlanes['nz'].visible = false
    }
  }

  createPlanes() {
    this.removePlanes()

    this.groundLevelPlane = this.createSquareObjects(this.groundPlaneMaterial)
    this.yPlane = this.createSquareObjects(this.assetPlaneMaterial)
   // this.yCollisionPlane = this.createSquareObjects(this.assetPlaneMaterial2)

    this.groundLevelPlane.visible = false
    this.yPlane.visible = false
  //  this.yCollisionPlane.visible = false
    this.add(this.groundLevelPlane)
    this.add(this.yPlane)
  //  this.add(this.yCollisionPlane)
  }

  createSquareObjects(material) {
    let wrapper = new THREE.Object3D()
    const { x, z } = this.selectedBB.getSize()

    const geometryx = new THREE.PlaneGeometry(2, z + 4, 32)
    const geometryz = new THREE.PlaneGeometry(x + 4, 2, 32)
    let x1 = new THREE.Mesh(geometryx, material)
    let x2 = new THREE.Mesh(geometryx, material)
    let z1 = new THREE.Mesh(geometryz, material)
    let z2 = new THREE.Mesh(geometryz, material)
    wrapper.add(x1)
    wrapper.add(x2)
    wrapper.add(z1)
    wrapper.add(z2)
    x1.position.x = x / 2 + 1
    x2.position.x = -(x / 2) - 1
    z1.position.y = z / 2 + 1
    z2.position.y = -(z / 2) - 1

    wrapper.rotation.x = Math.PI * -0.5
    wrapper.position.x =
      this.selectedBB.getCenter().x - this.selected.position.x
    wrapper.position.z =
      this.selectedBB.getCenter().z - this.selected.position.z
    wrapper.name = 'y'

    return wrapper
  }

  detectcollision() {
    let maxPosition = this.groundPosition

    // for (let i = 0; i < this.objects.length; i++) {
    //   if (
    //     this.selected.name !== this.objects[i].name &&
    //     this.objects[i].parent !== null &&
    //     this.selected.name !== this.objects[i].parent.name
    //   ) {
    //     this.secondBB.setFromObject(this.objects[i])
    //     if (this.selectedBB.intersectsBox(this.secondBB)) {
    //       maxPosition = this.getVerticalSnappingPoint(this.secondBB.max.y) // get the snapping pont from top of intersecting object
    //       this.yCollisionPlane.position.copy(
    //         this.getvector(
    //           this.selected.position.x,
    //           maxPosition,
    //           this.selected.position.z
    //         )
    //       )
    //       this.yCollisionPlane.visible =
    //         maxPosition.toFixed(2) > this.groundPosition.toFixed(2) &&
    //         maxPosition.toFixed(2) !== this.selectedBB.min.y.toFixed(2)
    //     }
    //   }
    // }
    if (this.selectedBB.min.y > this.groundPosition) {
      let innerPoint = this.getVerticalSnappingPoint(this.selectedBB.min.y) // get the snapping pont from base of object
      this.yPlane.position.copy(
        this.getvector(
          this.selected.position.x,
          innerPoint,
          this.selected.position.z
        )
      )

      this.yPlane.visible =
        innerPoint.toFixed(2) > this.groundPosition.toFixed(2) &&
        innerPoint.toFixed(2) !== maxPosition.toFixed(2) &&
        innerPoint.toFixed(2) !== this.selectedBB.min.y.toFixed(2)
    }
    this.getHoriZontalSnappingPoints()
  }

  getVerticalSnappingPoint(maxY, direction = this.interSectionRayDirection) {
    let gAY = this.getInterSectPoint(
      this.selectedBB.min.x,
      maxY,
      this.selectedBB.min.z,
      direction
    )
    let gBY = this.getInterSectPoint(
      this.selectedBB.max.x,
      maxY,
      this.selectedBB.min.z,
      direction
    )
    let gCY = this.getInterSectPoint(
      this.selectedBB.max.x,
      maxY,
      this.selectedBB.max.z,
      direction
    )
    let gDY = this.getInterSectPoint(
      this.selectedBB.min.x,
      maxY,
      this.selectedBB.max.z,
      direction
    )
    let gCenterY = this.getInterSectPoint(
      this.selected.position.x,
      maxY,
      this.selected.position.z,
      direction
    )
    let maxPosition = Math.max(gAY, gBY, gCY, gDY, gCenterY) // to get the first intersection
    return maxPosition + 0.01
  }

  getHoriZontalSnappingPoints() {
    if (!this.selected || !this.selectedBB) return
    //four faces +x, -x, +z, -z
    const intersectionCaster = new THREE.Raycaster(   
      new THREE.Vector3(),
      new THREE.Vector3(0, 0, -1),
      0.02,                  // near distance
      minimumDistance       //Far distance,  
    )
    const {
      max: { x: maxX, y: maxY, z: maxZ },
      min: { x: minX, y: minY, z: minZ },
    } = this.selectedBB
    const center = new THREE.Vector3()
    this.selectedBB.getCenter(center)

    const p1 = [minX, minY, minZ],    //bottom front left corner of asset's bounding box
      p2 = [minX, maxY, minZ],        //top front left corner of asset's bounding box
      p3 = [minX, maxY, maxZ],         //top rear left corner of asset's bounding box
      p4 = [maxX, maxY, maxZ],         //top rear right corner of asset's bounding box
      p5 = [maxX, minY, maxZ],        //bottom rear right corner of asset's bounding box
      p6 = [maxX, minY, minZ],       //bottom front right corner of asset's bounding box
      p7 = [maxX, maxY, minZ],      //top front right corner of asset's bounding box
      p8 = [minX, minY, maxZ],      //bottom rear left corner of asset's bounding box
      m1 = [center.x, center.y, minZ],
      m2 = [minX, center.y, center.z],
      m3 = [center.x, center.y, maxZ],
      m4 = [maxX, center.y, center.z]
    const origin_direction_map = [
      {
        f: 'nz',
        o: [p1, p2, p6, p7, m1],   // Four corner positions and center point of z facing surface
        d: new THREE.Vector3(0, 0, -1),
        intersections: [],
      },
      {
        f: 'z',
        o: [p4, p5, p3, p8, m3],
        d: new THREE.Vector3(0, 0, 1),
        intersections: [],
      },
      {
        f: 'x',
        o: [p4, p5, p6, p7, m4],
        d: new THREE.Vector3(1, 0, 0),
        intersections: [],
      },
      {
        f: 'nx',
        o: [p1, p2, p3, p8, m2],
        d: new THREE.Vector3(-1, 0, 0),
        intersections: [],
      },
    ]

    origin_direction_map.forEach((i) => {
      const { o: origins, d: direction, f: face } = i
      const intersections = []
      origins.forEach((origin) => {
        intersectionCaster.set(new THREE.Vector3(...origin), direction)   // casting ray from origin and direction with far and rear,
        const results = intersectionCaster.intersectObjects(
          this.template ? [...this.objects, this.template] : this.objects,
          true
        )
        intersections.push(...results)
      })
      intersections.sort((a, b) => a.distance - b.distance)   //to get the closest intersection

      i.intersections = intersections[0]
      if (intersections[0]) {
        this.showHorizontalSnapHelper(intersections[0].point, face)
      } else {
        this.removeHorizontalSnapper(face)
      }
    })
  }

  showHorizontalSnapHelper(position, face) {
    const helperPlane = this.getHorizontalPlane(face)
    if (face == 'z' || face == 'nz') {
      helperPlane.position.set(
        this.selectedBB.getCenter().x,
        this.selectedBB.getCenter().y,
        position.z + this.horizontalShift[face] * 0.01
      )
    }
    if (face == 'x' || face == 'nx') {
      helperPlane.position.set(
        position.x + this.horizontalShift[face] * 0.01,
        this.selectedBB.getCenter().y,
        this.selectedBB.getCenter().z
      )
    }
    helperPlane.visible = true
  }
  getHorizontalPlane(face) {
    if (this.horizontalPlanes[face] !== null) {
      return this.horizontalPlanes[face]
    }
    const { x, z, y } = this.selectedBB.getSize()
    let width = 0,
      height = y
    if (face == 'z' || face == 'nz') {
      width = x
    }
    if (face == 'x' || face == 'nx') {
      width = z
    }

    let wrapper = new THREE.Object3D()

    const geometryW = new THREE.PlaneGeometry(width + 4, 2, 32)
    const geometryY = new THREE.PlaneGeometry(2, height + 4, 32)
    const material = new THREE.MeshBasicMaterial({
      color: planeColor,
      side: THREE.DoubleSide,
    })

    let x1 = new THREE.Mesh(geometryW, material)
    let x2 = new THREE.Mesh(geometryW, material)
    let z1 = new THREE.Mesh(geometryY, material)
    let z2 = new THREE.Mesh(geometryY, material)
    wrapper.add(x1)
    wrapper.add(x2)
    wrapper.add(z1)
    wrapper.add(z2)
    x1.position.y = height / 2 + 1
    x2.position.y = -(height / 2) - 1
    z1.position.x = width / 2 + 1
    z2.position.x = -(width / 2) - 1
    if (face == 'x' || face == 'nx') {
      wrapper.rotation.y = Math.PI / 2
    }
    wrapper.name = face
    this.horizontalPlanes[face] = wrapper
    this.add(wrapper)
    return wrapper
  }
  removeHorizontalSnapper(face) {
    const helperPlane = this.getHorizontalPlane(face)
    if (helperPlane) {
      helperPlane.visible = false
    }
  }

  getInterSectPoint(x, y, z, direction) {
    var results = []
    var yPos = 0
    this.intersectionCaster.set(new THREE.Vector3(x, y, z), direction)
    results = this.intersectionCaster.intersectObjects(
      this.template ? [...this.objects, this.template] : this.objects,
      true
    )
    if (results.length) {
      yPos = results[0].point.y
      if (this.template) {
        this.groundPosition = results[results.length - 1].point.y + 0.01
      }
    }

    return yPos
  }
  mouseDownListener = (event) => {
    let prevPosition = new THREE.Vector3().copy(this.selected.position)
    let rect = this.renderer.getBoundingClientRect()

    this.mouseArgs.set(
      (event.clientX / rect.width) * 2 - 1,
      -((event.clientY - rect.top) / rect.height) * 2 + 1
    )
    this.caster.setFromCamera(this.mouseArgs, this.camera)
    let intersects = this.caster.intersectObjects([this], true)
    let intersect
    if (intersects.length > 0 && (event.ctrlKey || event.metaKey)) {
      event.stopImmediatePropagation()
      intersect = intersects[0].point

      const parentObject = intersects[0].object.parent
      if (!parentObject) return
      if (parentObject.name == 'y') {
        this.selected.position.y =
          intersect.y + (this.selected.position.y - this.selectedBB.min.y)  // to shift the asset up by its difference of pivot and bottom surface,
      } else {
        if (parentObject.name == 'x') {
          this.selected.position.x =                                            // to shift the asset left by its difference of pivot and  right surface,
            intersect.x + (this.selected.position.x - this.selectedBB.max.x)    // so the bounding box of asset touches the targeted position
        }
        if (parentObject.name == 'nx') {
          this.selected.position.x =
            intersect.x + (this.selected.position.x - this.selectedBB.min.x)  // to shift the asset right by its difference of pivot and  left surface,
        }
        if (parentObject.name == 'z') {
          this.selected.position.z =
            intersect.z + (this.selected.position.z - this.selectedBB.max.z)   // to shift the asset forward by its difference of pivot and surface,
        }
        if (parentObject.name == 'nz') {
          this.selected.position.z =
            intersect.z + (this.selected.position.z - this.selectedBB.min.z)  //// to shift the asset backward by its difference of pivot and surface,
        }
      }

      this.selectedBB.setFromObject(this.selected)

      this.hidePlanes()

      this.dispatchEvent({
        type: 'snapped',
        prevPosition,
        selected: this.selected,
      })
    }
  }
  getTemplateSnappingPoint(asset, intersection) {
    if (asset) {
      this.assetBox.setFromObject(asset)
    }
    let assetBox = this.assetBox
    let templateBox = this.templateBox

    let x1 = assetBox.max.x - asset.position.x
    let x2 = asset.position.x - assetBox.min.x
    let z1 = assetBox.max.z - asset.position.z
    let z2 = asset.position.z - assetBox.min.z

    let point = this.getvector(
      intersection.point.x,
      intersection.point.y,
      intersection.point.z
    )
    let faceNormal = intersection.face.normal
    if (Math.round(faceNormal.x) === 1) {
      point.x = point.x + x2
    }

    if (Math.round(faceNormal.x) === -1) {
      point.x = point.x - x1
    }

    if (Math.round(faceNormal.z) === 1) {
      point.z = point.z + z2
    }

    if (Math.round(faceNormal.z) === -1) {
      point.z = point.z - z1
    }

    if (
      (Math.round(faceNormal.y) === 1 || Math.round(faceNormal.y) === -1) &&
      point.x >= templateBox.min.x &&
      point.x <= templateBox.max.x
    ) {
      if (point.x + x1 >= templateBox.max.x) {
        point.x = point.x - (point.x + x1 - templateBox.max.x)
      }

      if (point.x - x2 <= templateBox.min.x) {
        point.x = point.x - (templateBox.min.x - point.x - x2)
      }
    }

    if (
      (Math.round(faceNormal.y === 1) || Math.round(faceNormal.y) === -1) &&
      point.z >= templateBox.min.z &&
      point.z <= templateBox.max.z
    ) {
      if (point.z + z1 >= templateBox.max.z) {
        point.z = point.z - (point.z + z1 - templateBox.max.z)
      }

      if (point.z - z2 <= templateBox.min.z) {
        point.z = point.z - (templateBox.min.z - point.z - z2)
      }
    }
    point.y = point.y + (asset.position.y - assetBox.min.y) + 0.01

    return point
  }
}
