import {
  API_CALL_INITIATED,
  API_CALL_FAIL,
  CLEAR_ASSETS,
  ADD_LIST_PAGE_META,
  NO_ASSETS_FOUND,
  FETCH_RECORDS_SUCCESS,
  ADD_COMMENTS,
  COMMENTS_SUCCESS,
  COMMENTS_FAILURE,
  SHOW_COMMENTS,
  SHOW_COMMENTS_SUCCESS,
  SHOW_COMMENTS_FAILURE,
  FETCH_RECORDS_SUCCESS_UNGROUP,
  CLEAR_ASSETS_UNGROUP,
  FETCH_STYLED_ASSETS_SUCCESS,
  CLEAR_STYLED_ASSETS,
  FILTER_LISTS_REQUEST,
  FILTER_LISTS_FAILURE,
  FILTER_LISTS_SUCCESS,
  FILTER_LISTS_CHANGE,
  FILTER_COUNT_CHANGE,
  CLEAR_ALL_FILTERS,
  TOTAL_COUNT_SUCCESS,
  FETCH_SIMILAR_ASSETS_SUCCESS,
  CLEAR_SIMILAR_ASSETS,
  SHOW_SIMILAR_ASSETS,
  FETCH_GALLERY_RECORD_SUCCESS,
  OPEN_EXPLORER,
  CLOSE_EXPLORER,
  SHOW_VARIATIONS,
  CLEAR_SUB_VIEW,
  SET_SORTBY,
  SET_EXCLUDE_HP,
  SET_EXCLUDE_RTA,
  SET_MINI_EXPLORER_MODE,
} from './actionType'
import apiConfig from '../../config/config'

export const assetsState = {
  assetList: [],
  assetListObject: {},
  assetCount: 0,
  assetListloading: false,
  submitStatus: {
    loading: false,
    status: '',
    message: '',
  },
  listPageMeta: {},
  noResultsFound: false,
  tcin_comments: [],
  show_comments: [],
  assetListUngroup: [],
  totalAssetsCountUngroup: 0,
  styledAssets: [],
  totalStyledAssets: 0,
  filterLists: {},
  selectedFilterCount: 0,
  libraryAssetsCount: 0,
  similarAssets: [],
  primary_tcin_detail: {},
  showSimilarAsset: false,
  totalSimilarAssets: 0,
  search_similar_asset: {},
  isExplorerOpen: false,
  showVariations: false,
  variationTcin: {},
  filterType: '',
  sortBy: JSON.stringify({ time_stamp: 'DESC' }),
  excludeRTA: true,
  excludeHighPoly: true,
  miniExplorerMode: '',
}

export default function projects(state = assetsState, action = {}) {
  let commentsDetails = {}
  let submitStatus

  switch (action.type) {
    case SHOW_COMMENTS:
      return { ...state, loading: false }

    case SHOW_COMMENTS_SUCCESS:
      return { ...state, show_comments: action.payload, loading: true }

    case SHOW_COMMENTS_FAILURE:
      return { ...state, comments_failure: 'error', loading: true }

    case ADD_COMMENTS:
      return { ...state, loading: true }

    case COMMENTS_SUCCESS:
      return { ...state, tcin_comments: action.payload.comment, loading: true }

    case COMMENTS_FAILURE:
      commentsDetails = {
        statusMessage:
          'Opps! We’re sorry, but we’re experiencing a problem on our end',
      }
      return { ...state, commentsDetails, loading: true }

    case API_CALL_INITIATED:
      return {
        ...state,
        assetListloading: true,
      }

    case API_CALL_FAIL:
      return {
        ...state,
        apiError: action.error,
        assetListloading: false,
      }
    case CLEAR_ASSETS: {
      const emptyList = {
        assetList: [],
        noResultsFound: false,
      }
      return { ...state, ...emptyList }
    }
    case FETCH_RECORDS_SUCCESS: {
      const { data: result } = action
      const {
        search_response: data,
        total_count: totalRecords,
        assetType,
      } = result
      submitStatus = {
        loading: false,
        status: '',
        message: '',
      }

      const assetList =
        assetType === 'ARCHITECTURE' ? mapArchAssets(data) : data
      const resultObj = {
        apiError: action.success,
        assetList: [...state.assetList, ...assetList],
        totalAssetsCount: totalRecords,
        submitStatus,
        assetListloading: false,
        noResultsFound: false,
      }
      return { ...state, ...resultObj }
    }
    case FETCH_RECORDS_SUCCESS_UNGROUP: {
      const { data: result } = action
      const { search_response: listUngroup, total_count: totalRecords } = result
      submitStatus = {
        loading: false,
        status: '',
        message: '',
      }
      const resultObj = {
        apiError: action.success,
        assetListUngroup: [...state.assetListUngroup, ...listUngroup],
        totalAssetsCountUngroup: totalRecords,
        submitStatus,
        assetListloading: false,
        noResultsFound: false,
      }
      return { ...state, ...resultObj }
    }
    case CLEAR_ASSETS_UNGROUP: {
      const emptyList = {
        assetListUngroup: [],
        totalAssetsCountUngroup: 0,
        noResultsFound: false,
      }
      return { ...state, ...emptyList }
    }
    case FETCH_STYLED_ASSETS_SUCCESS: {
      const { data: result } = action
      const { search_response: styledAssets, total_count: totalRecords } =
        result
      submitStatus = {
        loading: false,
        status: '',
        message: '',
      }
      const resultObj = {
        styledAssets: [...state.styledAssets, ...styledAssets],
        totalStyledAssets: totalRecords,
        submitStatus,
        assetListloading: false,
        noResultsFound: false,
      }
      return { ...state, ...resultObj }
    }

    case CLEAR_STYLED_ASSETS: {
      const emptyList = {
        styledAssets: [],
        totalStyledAssets: 0,
        noResultsFound: false,
      }
      return { ...state, ...emptyList }
    }

    case ADD_LIST_PAGE_META:
      return { ...state, listPageMeta: { ...action.data } }

    case NO_ASSETS_FOUND:
      return {
        ...state,
        noResultsFound: true,
        assetListloading: false,
        assetList: [],
      }
    case FILTER_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FILTER_LISTS_SUCCESS: {
      let filters = {}
      let filterid = 1
      const { filterType, payload: filterData } = action

      Object.keys(filterData).forEach((item) => {
        filters[item] = filterData[item].map((it) => ({
          ...it,
          isSelected: false,
          id: filterid++,
        }))
      })

      if (filterType !== 'GALLERY') {
        filters = {
          ...{
            Asset_Contains: [
              { name: 'Styled Variations', isSelected: false, id: filterid++ },
              { name: 'UnBundled Version', isSelected: false, id: filterid++ },
            ],
          },
          ...filters,
        }
      } else {
        filters = {
          ...{
            item_identifiers: [
              { name: 'DPCI', isSelected: false, id: filterid++ },
              { name: 'TCIN', isSelected: false, id: filterid++ },
              { name: 'PID', isSelected: false, id: filterid++ },
            ],
            renderer: [
              { name: 'VRay', isSelected: false, id: filterid++ },
              { name: 'Corona', isSelected: false, id: filterid++ },
            ],
          },
          ...filters,
        }
      }
      if (state.filterType !== filterType) {
        return {
          ...state,
          filterLists: { ...filters },
          loading: false,
          selectedFilterCount: 0,
          filterType,
        }
      } else {
        return state
      }
    }
    case FILTER_LISTS_CHANGE: {
      const { filterkey, id } = action.payload
      let newfilterValue = [...state.filterLists[filterkey]].map(
        ({ ...item }) => {
          if (id === item.id) {
            item.isSelected = !item.isSelected
          }
          return { ...item }
        }
      )

      return {
        ...state,
        filterLists: {
          ...state.filterLists,
          ...{ [filterkey]: [...newfilterValue] },
        },
      }
    }
    case FILTER_LISTS_FAILURE:
      return {
        ...state,
        filterLists: action.error,
        loading: false,
      }

    case FILTER_COUNT_CHANGE:
      return {
        ...state,
        selectedFilterCount: state.selectedFilterCount + action.payload,
      }

    case CLEAR_ALL_FILTERS: {
      let filters = {}
      const { filterLists = {} } = state
      Object.keys(filterLists).forEach((item) => {
        filters[item] = filterLists[item].map((it) => ({
          ...it,
          isSelected: false,
        }))
      })
      return {
        ...state,
        filterLists: filters,
        loading: false,
        selectedFilterCount: 0,
      }
    }
    case TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        libraryAssetsCount: action.data,
      }

    case FETCH_SIMILAR_ASSETS_SUCCESS: {
      const { data: result } = action
      const {
        search_response: similarAssets,
        total_count: totalRecords,
        primary_tcin_detail,
      } = result
      const resultObj = {
        similarAssets: [...state.similarAssets, ...similarAssets],
        totalSimilarAssets: totalRecords,
        assetListloading: false,
        noResultsFound: false,
        search_similar_asset: primary_tcin_detail,
      }
      return { ...state, ...resultObj }
    }

    case CLEAR_SIMILAR_ASSETS: {
      const emptyList = {
        similarAssets: [],
        totalSimilarAssets: 0,
        noResultsFound: false,
      }
      return { ...state, ...emptyList }
    }
    case SHOW_SIMILAR_ASSETS: {
      const resultObj = {
        showSimilarAsset: action.payload.show,
        search_similar_asset: action.payload.data,
      }
      return { ...state, ...resultObj }
    }
    case SHOW_VARIATIONS: {
      const { asset, show } = action.data
      return { ...state, showVariations: show, variationTcin: asset }
    }
    case FETCH_GALLERY_RECORD_SUCCESS: {
      const { data: result } = action
      const { data, total_items: totalRecords } = result
      submitStatus = {
        loading: false,
        status: '',
        message: '',
      }

      const gallerydata = data.map((item) => mapGalleryData(item))

      const resultObj = {
        apiError: action.success,
        assetList: [...state.assetList, ...gallerydata],
        //  assetListObject: assetListObject,
        totalAssetsCount: totalRecords,
        submitStatus,
        assetListloading: false,
        noResultsFound: false,
      }
      return { ...state, ...resultObj }
    }

    case OPEN_EXPLORER: {
      return {
        ...state,
        isExplorerOpen: true,
      }
    }
    case CLOSE_EXPLORER: {
      return {
        ...state,
        isExplorerOpen: false,
      }
    }
    case CLEAR_SUB_VIEW: {
      return {
        ...state,
        showVariations: false,
        variationTcin: {},
        search_similar_asset: {},
        showSimilarAsset: false,
      }
    }
    case SET_SORTBY: {
      return { ...state, sortBy: action.payload }
    }

    case SET_EXCLUDE_HP: {
      return { ...state, excludeHighPoly: action.payload }
    }

    case SET_EXCLUDE_RTA: {
      return { ...state, excludeRTA: action.payload }
    }

    case SET_MINI_EXPLORER_MODE: {
      return { ...state, miniExplorerMode: action.payload }
    }

    default:
      return state
  }
}

export const mapGalleryData = (data, isFavourites) => {
  let thumbanailURL = ''

  if (isFavourites) {
    thumbanailURL = (data.files || []).filter(
      ({ file_sources }) =>
        file_sources[0].file_name.includes('thumbnail') &&
        file_sources[0].access_url !== ''
    )[0]?.file_sources[0]?.access_url
  } else {
    thumbanailURL = (data.files || []).filter(
      ({ sources }) =>
        sources[0].file_classification.toLowerCase().includes('thumbnail') &&
        sources[0].access_url !== ''
    )[0]?.sources[0].access_url
  }

  const AssetTags = {}
  const lodAvailable = true

  if (isFavourites) {
    ;(data.asset_tags || [{ tag: { key: '' } }]).forEach(
      ({ tag: { key, value } }) => {
        if (AssetTags[key]) {
          AssetTags[key] = AssetTags[key] + ', ' + value
        } else {
          AssetTags[key] = value
        }
      }
    )
  } else {
    ;(data.asset_tags || [{ tag: { key: '' } }]).forEach(({ key, value }) => {
      if (AssetTags[key]) {
        AssetTags[key] = AssetTags[key] + ', ' + value
      } else {
        AssetTags[key] = value
      }
    })
  }

  return {
    added_in_favourite: null,
    asset_description: null,
    asset_id: data.id,
    asset_type: 'PID',
    asset_type_name: null,
    brand: AssetTags['brand'] || ' ',
    build_type: null,
    bullet_description: ' ',
    category: null,
    product_type: AssetTags['category_name'] || ' ',
    class_name: AssetTags['class_name'] || ' ',
    custom_tags: AssetTags['custom_tags'] || ' ',
    department_name: AssetTags['department_name'] || ' ',
    dimensions: ' ',
    division_name: AssetTags['division_name'] || ' ',
    dpci: AssetTags['dpci'] || ' ',
    group_name: ' ',
    groups: null,
    has_styled_assets: false,
    has_unbundled_assets: false,
    hpa_upload_status: 'Success',
    is_hp_available: true,
    is_lp_available: true,
    lodProfile: lodAvailable,
    lod_available: lodAvailable,
    item_status: 'Active',
    launch_time: null,
    mac_hp_path: '',
    master_tcin: null,
    materials: '',
    parent_file_name: null,
    parent_tcin: null,
    product_color: 'Blue',
    product_desc: data.name,
    relationship_type: '',
    relationship_type_code: 'SA',
    renderer: 'Gallery',
    short_description: data.name,
    product_description: { short_description: data.name },
    sub_asset_type: null,
    sub_category: null,
    sub_group_name: ' ',
    subclass: ' ',
    tags: null,
    tcin: data.id,
    thumbnail_upload_status: 'Success',
    thumbnail_url: thumbanailURL,
    time_stamp: '2021-09-17T17:55:00.241+0000',
    type: null,
    uploaded_by: null,
    tcinIds: AssetTags['tcin'],
  }
}

export const mapArchAssets = (data) => {
  return data.map((asset) => {
    const { asset_id: tcin } = asset
    return { ...asset, tcin }
  })
}
