import { SET_ACTIVE_OPERATION } from './actionType'

export function setActiveOperation(oprType, { value = {}, status }) {
  return {
    type: SET_ACTIVE_OPERATION,
    payload: {
      [oprType]: status,
      value,
    },
  }
}
