import React from 'react'

import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import config from '../../config/config'
import { EXPORTSTS } from '../../constants/status'
import { styled } from '@mui/material/styles'

import { StatusCard, RenderCard } from 'cgi-ui-components'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import CircularProgress from '@mui/material/CircularProgress'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { OS, getCurrentOS } from '../../helpers/utils'
import copyToClipboard from '../../helpers/copyToClipboard'

import { readableDateAndTime } from '../../helpers/dataFormats'

const StyledCircularProgress = styled((props) => (
  <CircularProgress size="20px" {...props} />
))(({}) => ({}))

const styles = (theme) => ({})

class RenderCardDisplay extends React.Component {
  state = {
    isRenderedNowTriggered: false,
    copyTooltip: 'Copy path',
    isImgDownloading: false,
  }
  openPreview = (item, obj, render_job_id) => {
    this.props.openPreview(item, obj, render_job_id)
  }
  exportRender = (item) => {
    this.props.exportRender(item)
  }
  startDownLoad = (obj = {}) => {
    this.setState({ isImgDownloading: true })
    this.props.startDownLoad(obj, () =>
      this.setState({ isImgDownloading: false })
    )
  }

  openConfirmDialog = (e, obj = {}) => {
    this.props.openConfirmDialog(e, obj)
  }

  copyFilePath = (item) => {
    const os = getCurrentOS()
    if (os === OS.WINDOWS) {
      copyToClipboard(item['scene_windows_path'])
    } else if (os === OS.MACOS) {
      copyToClipboard(item['scene_mac_path'])
    } else {
      copyToClipboard(item['scene_mac_path'])
    }
    this.setState({ copyTooltip: 'Link copied', anchorEl: null })
    setTimeout(() => {
      this.setState({ copyTooltip: 'Copy path' })
    }, 10000)
  }

  /**
   * Show the secondary buttons labels and actions that can be performed based on the exportStatus and API export status
   */
  getSecondaryButtonDetails() {
    const { exportStatus, item } = this.props
    const { copyTooltip } = this.state
    const renderId = item.render_job_id
    const apiExportStatus = item['export_status']
    const objToReturn = {
      show: '',
      label: '',
      action: () => {},
    }

    if (!exportStatus[renderId] && apiExportStatus === EXPORTSTS.COMPLETED) {
      //CASE 1: When internal state exportStatus is empty but API export status is Complete
      objToReturn.show = 'copypath'
      objToReturn.icon = LinkRoundedIcon
      objToReturn.label = copyTooltip
      objToReturn.action = this.copyFilePath
      return objToReturn
    } else if (!exportStatus[renderId]) {
      //CASE 2: When internal state exportStatus is empty which means render is not exported
      objToReturn.show = 'export'
      objToReturn.icon = ExitToAppOutlinedIcon
      objToReturn.action = this.exportRender
      objToReturn.label = 'Export Scene'
      return objToReturn
    } else if (exportStatus[renderId] === EXPORTSTS.INPROGRESS) {
      //CASE 3: When exportStatus is set as IN_PROGRESS which means exporting is in progress
      objToReturn.show = 'loader'
      objToReturn.icon = StyledCircularProgress
      objToReturn.label = 'Exporting scene...'
      return objToReturn // Nothing can be done on clicking exporting scene button
    } else if (exportStatus[renderId] === EXPORTSTS.COMPLETED) {
      // Case 4: When exporting is completed
      objToReturn.show = 'copypath'
      objToReturn.icon = LinkRoundedIcon
      objToReturn.label = 'Exporting Completed'
      return objToReturn // Nothing can be done on clicking Exporting Completed button
    } else if (exportStatus[renderId] === EXPORTSTS.COPY_READY) {
      // Case 5: When exporting is completed and ready for copy path
      objToReturn.show = 'copypath'
      objToReturn.label = copyTooltip
      objToReturn.icon = LinkRoundedIcon
      objToReturn.action = this.copyFilePath
      return objToReturn
    } else if (exportStatus[renderId] === EXPORTSTS.FAILED) {
      //CASE 5: If Export status is failed then we should give option to user to re-export
      objToReturn.show = 'export'
      objToReturn.action = this.exportRender
      objToReturn.icon = ExitToAppOutlinedIcon
      objToReturn.label = 'Export Scene'
    }
    return objToReturn
  }

  renderCard = () => {
    const { item = {}, isRenderedNow = false, flagged } = this.props
    const { isRenderedNowTriggered = false } = this.state

    if (flagged) {
      return this.renderRenderCard(item, flagged)
    } else {
      if (isRenderedNow && !isRenderedNowTriggered) {
        setTimeout(() => {
          this.setState({ isRenderedNowTriggered: true })
        }, 3000)
        return this.renderStatuscard({
          item,
          imageIcon: (
            <CheckCircleRoundedIcon
              color="primary"
              sx={{
                fontSize: 40,
              }}
            />
          ),
          imageText: 'Render Completed',
        })
      } else {
        if (item.render_status === 'RENDERING') {
          return this.renderStatuscard({
            item,
            imageIcon: <CircularProgress />,
            imageText: 'Render In Progress',
          })
        } else if (item.render_status === 'COMPLETED') {
          return this.renderRenderCard(item, flagged)
        } else if (item.render_status === 'FAILED') {
          return this.renderStatuscard({
            item,
            imageIcon: (
              <WarningRoundedIcon
                color="primary"
                sx={{
                  fontSize: 40,
                }}
              />
            ),
            imageText: 'Render Failed',
            bannerMsg: item.failure_reason && item.failure_reason,
          })
        }
      }
    }
  }

  renderRenderCard = (item, flagged) => {
    const {
      experience_name,
      userHaveWriteAccess,
      currentExportingJobId,
      externalUser,
    } = this.props

    const img = item.render_jpg_filepath
      ? config.renditionsUrl + item.render_jpg_filepath
      : null

    const {
      render_start_time,
      render_end_time,
      render_details: {
        image_height,
        image_width,
        noise_level,
        time_limit_minutes,
      } = {},
      camera: {
        position: { x: posX, y: posY, z: posZ } = {},
        fl,
        clipNear,
        clipFar,
        zoomFactor,
      } = {},
    } = item

    const renderInfo = [
      {
        label: 'Render Time(minutes)',
        value: this.calculateRenderTime(render_start_time, render_end_time),
      },
      { label: 'Noise', value: noise_level },

      { label: 'Time(minutes)', value: time_limit_minutes },

      { label: 'Resolution', value: `${image_width}(W) x ${image_height}(H)` },
      {
        label: 'Camera Position',
        value: `X: ${parseInt(posX)}, Y: ${parseInt(posY)}, Z: ${parseInt(
          posZ
        )} `,
      },

      {
        label: 'Focal Length',
        value: fl,
      },
      { label: 'Clip Near', value: clipNear },
      { label: 'Clip Far', value: clipFar },
      { label: 'Zoom Factor', value: zoomFactor },
    ]

    const secondaryButtonObj = this.getSecondaryButtonDetails()

    const isExportButtonDisabled =
      !userHaveWriteAccess ||
      (currentExportingJobId &&
        currentExportingJobId !== item.render_job_id &&
        item.export_status !== EXPORTSTS.COMPLETED) ||
      externalUser
    const isDeleteDisabled =
      (currentExportingJobId && currentExportingJobId === item.render_job_id) ||
      item.export_status === EXPORTSTS.INPROGRESS

    const footerItems = [
      {
        icon: ImageOutlinedIcon,
        onClick: () => this.openPreview(item, img, item.render_job_id),
        title: 'View Shot',
      },
      {
        icon: this.state.isImgDownloading
          ? StyledCircularProgress
          : DownloadOutlinedIcon,
        onClick: () => {
          this.startDownLoad(item)
        },
        title: 'Download Shot',
      },
      {
        icon: CommentOutlinedIcon,
        onClick: () => {
          this.openPreview(item, img, item.render_job_id)
        },
        title: 'View Comments',
      },
    ]
    if (!isDeleteDisabled) {
      footerItems.push({
        icon: DeleteOutlineOutlinedIcon,
        onClick: (e) => {
          this.openConfirmDialog(e, item)
        },
        title: 'Delete Shot',
      })
    }
    if (!isExportButtonDisabled) {
      footerItems.push({
        // alignRight: true,
        icon: secondaryButtonObj.icon,
        onClick: () => {
          secondaryButtonObj.action(item)
        },
        title: secondaryButtonObj.label,
      })
    }

    return (
      <RenderCard
        item={item}
        key={item.render_job_id}
        avatarBackgroundColor={'#E0E0E0'}
        avtarTextColor={'#333'}
        date={readableDateAndTime(item.render_start_time)}
        loginId={item.initiator_details.login_id}
        displayName={item?.initiator_details?.display_name}
        maxWidth={250}
        minWidth={250}
        imgHeight={250}
        thumbnail={item.thumbnail_path}
        isUrlAppendedWithImg={true}
        config={config}
        noThumbnail={noThumbnail}
        name={experience_name}
        footerItems={footerItems}
        infoDetails={{ icon: InfoOutlinedIcon, data: renderInfo }}
        showFlagIcon={flagged}
        {...(item.latest_exported_render && {
          messageBanner: {
            text: '*Latest exported render',
            icon: ExitToAppOutlinedIcon,
          },
        })}
      />
    )
  }

  renderStatuscard = ({ item, imageIcon, imageText, bannerMsg }) => {
    return (
      <StatusCard
        maxWidth={250}
        minWidth={250}
        imgHeight={250}
        footerItems={[]}
        name={item.render_job_id}
        date={readableDateAndTime(item.render_start_time)}
        loginId={item.initiator_details.login_id}
        statusIcon={imageIcon}
        statusText={imageText}
        minFooterHeight={40}
        avatarBackgroundColor={'#E0E0E0'}
        avtarTextColor={'#333'}
        bannerMsg={bannerMsg}
      />
    )
  }
  calculateRenderTime(start, end) {
    let startTime = new Date(start)
    let endTime = new Date(end)
    let diff = (endTime - startTime) / (1000 * 60) //calculate difference in minutes
    return Math.ceil(diff)
  }
  render() {
    const { item = {} } = this.props
    return <div key={item.render_job_id}>{this.renderCard()}</div>
  }
}
const mapStateToProps = (state) => ({})
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(RenderCardDisplay))
