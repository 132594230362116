import React from 'react'
import { withStyles } from '@mui/styles'
import { Typography, Link } from '@mui/material'

const styles = (theme) => ({
  fontsize: {
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
    width: '35ch',
    textOverflow: 'elipses',
    overflow: 'hidden',
  },
})

class DownloadLink extends React.Component {
  state = {
    isDownloading: false,
  }
  downloadImage = (file) => {
    const { downloadImage = () => {} } = this.props
    this.setState({
      isDownloading: true,
    })
    downloadImage(file).then(() => {
      this.setState({
        isDownloading: false,
      })
    })
  }
  render() {
    const { file = {}, classes } = this.props
    return (
      <>
        {!this.state.isDownloading ? (
          <Link
            key={file.fileId}
            component="button"
            style={{ textAlign: 'start' }}
            onClick={(e) => this.downloadImage(file)}
            className={classes.fontsize}
          >
            {file.file_name}
          </Link>
        ) : (
          <Typography classes={{ body1: classes.fontsize }}>
            {'Downloading...'}
          </Typography>
        )}
      </>
    )
  }
}

export default withStyles(styles)(DownloadLink)
