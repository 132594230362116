import React from 'react'
import { Popover, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { debounce } from 'lodash'
import {
  HeaderComponent,
  MenuLinkComponent,
  SearchComponent,
  CustomButton,
  CustomIconButton,
  ImageAvatar,
} from 'cgi-ui-components'

import { isMemberOfAppArtAdmin } from '../../helpers/utils'
import Logo from '../../images/enterprise/logo.svg?url'
import { LogoutButton } from '../../auth'

const links = [
  // { label: 'Home', url: '/home' },
  { label: 'Scenes', url: '/home/scenes', selected: false },
  { label: 'Projects', url: '/home/projects', selected: false },
  { label: 'Favourites', url: '/home/favourites', selected: false },
  { label: 'Templates', url: '/home/templates', selected: false },
  { label: 'Product and Props', url: '/home/assets', selected: false },
]

const getPageLabel = ({ pathname }) => {
  const item = links.filter(({ url }) => url == pathname)
  let label = 'Scenes'
  if (item[0]) {
    label = item[0].label
  }
  return label
}

const useStyles = makeStyles((theme) => ({
  notifyicon: {
    color: theme.palette.secondary.main,
    display: 'none',
  },
  header: {
    display: 'flex !important',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 15,
  },
  avatar: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    // paddingTop: 20,
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
  headerContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    justifyContent: 'space-between',
  },
  leftContainer: {
    width: '10%',
    display: 'flex',
    justifyContent: 'end',
  },
  rightContainer: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'row',
    columnGap: 10,
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  uploadButton: { marginLeft: '20px' },
}))

const Header = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { lanId, memberOf } = useSelector(({ auth }) => auth)
  const isAppArtAdmin = isMemberOfAppArtAdmin(memberOf)

  const [searchVal, setSearchVal] = React.useState('')
  const [openLogout, setOpenLogout] = React.useState(false)

  const history = useNavigate()
  let label = getPageLabel(useLocation())
  label = label == 'Home' ? 'Scenes' : label

  let updatedLinks = links.map((link) => {
    link.selected = false
    if (link.label === label) {
      link.selected = true
    }
    return link
  })

  let headerLinks = updatedLinks
  // if (!isAppArtAdmin) {
  //   headerLinks = headerLinks.filter((item) => item.label !== 'Templates')
  // }

  const toggleLogout = () => {
    setOpenLogout((open) => !open)
  }

  return (
    <HeaderComponent className={classes.header} height="70px" width="auto">
      <div className={classes.leftContainer}>
        <img src={Logo} alt="Stylehub Enterprise" />
      </div>
      <div className={classes.headerContainer}>
        <div style={{ marginTop: 5 }}>
          <MenuLinkComponent
            links={headerLinks}
            onMenuClick={(e, label, { href }) => {
              setSearchVal('')
              history(href)
            }}
            showIndicator={true}
          />
        </div>
        {/* {label !== 'Favourites' && (
          <SearchComponent
            height={40}
            onSearch={searchExperiences}
            onSearchButtonClick={onCross}
            placeholder={`Search in ${label}`}
            searchVal={searchVal}
            width={320}
            brandLogo={searchLogo}
          />
        )} */}
        <div className={classes.buttonContainer}>
          <div className={classes.buttonWrapper}>
            <CustomButton
              label={'New Scene'}
              startIcon={<AddIcon fontSize="15px" />}
              color="secondary"
              onClick={() => {
                history('/experience/new')
              }}
              disableElevation
            />
            {/* <a href="#" className={classes.link}>
              <Typography variant="caption">See how it works?</Typography>
            </a> */}
          </div>
          {/* <div className={classes.uploadButton}>
            <CustomButton
              label={'Uploads'}
              color="secondary"
              onClick={() => {
                setSearchVal('')
                history('/home/upload')
              }}
              disableElevation
            />
          </div> */}
        </div>
      </div>
      <div className={classes.rightContainer}>
        {/* <CustomIconButton label="Notification">
          <RssFeedIcon className={classes.notifyicon} />
        </CustomIconButton> */}
        <ImageAvatar
          loginId={lanId}
          className={classes.avatar}
          onClick={toggleLogout}
        />
        {openLogout && <LogoutButton />}
      </div>
    </HeaderComponent>
  )
}

export default Header
