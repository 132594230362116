import React, { useEffect } from 'react'
import { makeStyles, styled } from '@mui/styles'
import Card from '@mui/material/Card'

import CloseIcon from '@mui/icons-material/Close'

import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'
import { CustomIconButton } from 'cgi-ui-components'
import IconButton from '@mui/material/IconButton'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid lightgrey',
    padding: '0 5px 10px 10px',
  },
  content: {
    padding: 0,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    // paddingLeft: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },

  closeIcon: {
    position: 'relative',
    left: 22,
    top: 4,
    width: '1.2rem',
    height: '1.2rem',
    paddingTop: 4,
  },
  leftArrow: {
    transform: `scaleX(1) rotate(180deg)`,
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    fontSize: '1.5rem',
  },
  rightArrow: {
    // transform: `rotate(-90deg)`,
    gridColumn: '3 / 4',
    gridRow: '2 / 3',
    fontSize: '1.5rem',
  },

  upArrow: {
    transform: `rotate(-90deg)`,
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
    fontSize: '1.5rem',
  },
  downArrow: {
    transform: `rotate(90deg)`,
    gridColumn: '2 / 3',
    gridRow: '3 / 4',
    fontSize: '1.5rem',
  },

  iconsContainer: {
    height: '6rem',
    width: '6rem',
    display: 'grid',
    gridTemplateColumns: '33% 33% 33%',
    gridTemplateRows: '33% 33% 33%',
    margin: '0 20px',
  },

  zoomPanelContainer: {
    textAlign: 'right',
    flexGrow: 1,
  },

  iconButton: {
    padding: 'unset',
    '&.MuiIconButton-root': {
      color: '#333333',
    },
    '&:hover': { backgroundColor: 'unset' },
  },

  closeButton: {
    '&.MuiIconButton-root': {
      color: '#333333',
      padding: 4,
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    '&> div:first-child': {
      paddingTop: 10,
    },
  },
}))

export default function MediaControlCard(props) {
  const classes = useStyles()
  const { handleTileControls, toggleView } = props

  return (
    <Card>
      <div className={classes.root}>
        <div className={classes.header}>
          <div>Tile Asset</div>
          <div className={classes.zoomPanelContainer}>
            <CustomIconButton
              label="Collapse"
              classes={{ root: classes.closeButton }}
              size="small"
              onClick={(e) => toggleView(e, false)}
              data-testid="close_tile"
            >
              <CloseIcon />
            </CustomIconButton>
          </div>
        </div>
        <div className={classes.iconsContainer}>
          <IconButton
            disableRipple
            classes={{ root: classes.iconButton }}
            className={classes.leftArrow}
            onMouseDown={(e) => handleTileControls(e, 'left', true)}
            data-testid="tile_left"
          >
            <KeyboardTabIcon />
          </IconButton>
          <IconButton
            disableRipple
            classes={{ root: classes.iconButton }}
            className={classes.upArrow}
            onMouseDown={(e) => handleTileControls(e, 'top', true)}
            data-testid="tile_top"
          >
            <KeyboardTabIcon />
          </IconButton>
          <IconButton
            disableRipple
            classes={{ root: classes.iconButton }}
            className={classes.rightArrow}
            onMouseDown={(e) => handleTileControls(e, 'right', true)}
            data-testid="tile_right"
          >
            <KeyboardTabIcon />
          </IconButton>
          <IconButton
            disableRipple
            classes={{ root: classes.iconButton }}
            className={classes.downArrow}
            onMouseDown={(e) => handleTileControls(e, 'bottom', true)}
            data-testid="tile_bottom"
          >
            <KeyboardTabIcon />
          </IconButton>
        </div>
      </div>
    </Card>
  )
}
