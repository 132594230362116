import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import Notifications from '../../components/Notifications/Notifications'
import { Helmet } from 'react-helmet'
import Header from './header'

import ScenePage from './pages/scene'
import ProjectPage from './pages/projects'
import Layouts from './pages/layout'
import Favourites from './pages/favourites'
import { isMemberOfAppArtAdmin } from '../../helpers/utils'
import ProductAndProps from './pages/productAndProps'
import TemplateUploads from './pages/templateUpload'
import Statistics from '../../components/statistics'
import AppFeedback from '../../components/feedback'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1560,
    width: '90%',
    margin: '0 auto',
  },
  root: {
    backgroundColor: '#FFF',
  },
}))

const Home = () => {
  const classes = useStyles()
  const { memberOf } = useSelector(({ auth: { memberOf } }) => ({ memberOf }))
  const isAppArtAdmin = isMemberOfAppArtAdmin(memberOf)

  return (
    <>
      <Helmet defaultTitle={'Home - Stylehub'} titleTemplate="%s - StyleHub" />

      <Header />
      <Notifications />
      <div className={classes.container}>
        <Routes>
          <Route path="/" exact element={<ScenePage />} />
          <Route path="/home" exact element={<ScenePage />} />
          <Route path="/home/scenes" exact element={<ScenePage />} />
          <Route exact path="/home/templates" element={<Layouts />} />

          <Route path="/home/assets" element={<ProductAndProps />} />
          <Route path="/home/projects/*" element={<ProjectPage />} />
          <Route
            path="/home/favourites"
            element={<Favourites showDialog={false} />}
          />
          <Route
            path="/home/favourites/new"
            element={<Favourites showDialog={true} />}
          />
          <Route path="/home/upload" element={<TemplateUploads />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Statistics />
      <AppFeedback />
    </>
  )
}

export default Home
