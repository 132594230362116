import React from 'react'
import { makeStyles } from '@mui/styles'
import { Select } from 'cgi-ui-components'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

const axisValues = [
  {
    value: 'world',
    name: 'Rotate axis - World',
  },
  {
    value: 'local',
    name: 'Rotate axis - Local',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: 200,
    padding: '6px 30px',
    '& .MuiInputBase-root': {
      marginTop: 0,
      '&:before': {
        borderBottom: 'none',
      },
      '&:hover:before': {
        borderBottom: 'none',
      },
    },
  },
}))

export default function SpaceController(props) {
  const classes = useStyles()
  const [axis, setAxis] = React.useState('world')

  const handleChange = (event) => {
    setAxis(event.target.value)
    props.handle3DSpaceChange(event.target.value)
  }
  return (
    <div className={classes.root}>
      <Select
        options={[...axisValues]}
        value={props.current3DAxis}
        onChange={handleChange}
        size="small"
        variant="standard"
      />
    </div>
  )
}
