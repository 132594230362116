import axios from 'axios'
import api from '../../config/config'

export function fetchExperienceImages(
  sceneId,
  successCallback = () => {},
  failureCallback = () => {}
) {
  return axios
    .get(`${api.experience.renderList}/${sceneId}`)
    .then((response) => {
      successCallback(response.data)
    })
    .catch((error) => {
      failureCallback(error)
      throw new Error('failed to fetch renderList')
    })
}

export function flagRendersForReview(
  experienceId,
  renderJobId,
  flagged,
  successCallback = () => {},
  failureCallback = () => {}
) {
  return axios
    .put(
      `${api.experience.renderList}/${experienceId}/${renderJobId}?renderReviewFlag=${flagged}`
    )
    .then((response) => {
      successCallback(response.data)
    })
    .catch((error) => {
      failureCallback(error)
      throw new Error('failed to mark flagged render')
    })
}

export function renderJson(
  payload,
  successCallback = () => {},
  failureCallback = () => {}
) {
  return axios
    .post(`${api.experience.renderScene}?key=${api.apiKey}`, payload)
    .then((response) => {
      successCallback(response.data)
    })
    .catch((e) => {
      failureCallback(e)
      throw new Error('failed to post render scene')
    })
}
