import React from 'react'
import { makeStyles } from '@mui/styles'
import { CSVLink } from 'react-csv'

import { Button, Typography, Box, Dialog } from '@mui/material'
import FullScreenDialog from '../FullScreenDialog/FullScreenDialog'

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    border: `1px solid rgba(204, 0, 0, 0.5)`,
    borderRadius: 5,
    padding: '9px 12px',
    fontWeight: 500,
    marginRight: 10,
    display: 'inline-flex',
    color: '#CC0000',
    textDecoration: 'none',
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'rgba(204, 0, 0, 0.04)',
      border: '1px solid #CC0000',
    },

    '& i': {
      color: theme.palette.primary.red,
      margin: '0 10px 0 0px',
    },
  },
}))

const TemplateAssetErrorOverlay = ({
  isOpen,
  errors = [],
  assetErrorDetails = {},
  onCancel = () => {},
  onContinue = () => {},
  isLoading,
}) => {
  const styles = useStyles()

  const headers = [
    { label: 'Asset Name / TCIN', key: 'assetId' },
    { label: 'Asset Type', key: 'asset_type' },
    { label: 'Error Level', key: 'level' },
    { label: 'Description', key: 'description' },
    { label: 'Support Owner', key: 'type' },
  ]
  let download_data = []
  if (assetErrorDetails && assetErrorDetails !== null) {
    download_data = Object.keys(assetErrorDetails).map((key) => {
      return { ...assetErrorDetails[key], assetId: key }
    })
  }

  return (
    <>
      <FullScreenDialog
        fullScreen={false}
        openForm={isOpen}
        modalActions={
          <>
            <CSVLink
              filename="Assets-Report-For-Template.csv"
              data={download_data}
              headers={headers || []}
              className={styles.downloadButton}
            >
              <i className="fas fa-exclamation" />
              Download Report
            </CSVLink>

            <Button variant="outlined" color="primary" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              variant="contained"
              disabled={isLoading}
              color="primary"
              onClick={onContinue}
            >
              {isLoading ? 'Updating...' : 'Ignore and Style'}
            </Button>
          </>
        }
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Template QA Error
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          The template you're about to use for styling has below unresolved
          error(s)
        </Typography>
        <Box
          sx={{
            bgcolor: '#F0F2F4',
            color: '#AE570F',
            borderRadius: 1,
            padding: 1,
          }}
        >
          {errors.map((err, key) => {
            return (
              <Typography key={key} variant="body2">
                {err}
              </Typography>
            )
          })}
        </Box>
      </FullScreenDialog>
    </>
  )
}

export default TemplateAssetErrorOverlay
