import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { CSVLink } from 'react-csv'

import { Grid, Button } from '@mui/material'
import FullScreenDialog from '../FullScreenDialog/FullScreenDialog'

const styles = (theme) => ({
  lowerGrid: {
    paddingLeft: '14%',
    paddingRight: '14%',
    height: '100%',
    paddingTop: 36,
  },
  dialogTitle: {
    display: 'block',
    paddingLeft: '14%',
    paddingRight: '14%',
    paddingTop: '5%',
  },
  iconButton: {
    color: theme.palette.primary.red,
    marginRight: '15px !important',
    paddingLeft: 0,
    '& i': {
      margin: '0 10px 0 0px',
    },
  },
  reloadButton: {
    backgroundColor: theme.palette.primary.red,
    paddingLeft: 0,
    marginRight: '15px !important',
    '& i': {
      margin: '0 10px 0 0px',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.darkRed,
    },
  },
  downloadButton: {
    border: `1px solid rgba(204, 0, 0, 0.5)`,
    borderRadius: 5,
    padding: '9px 12px',
    fontWeight: 500,
    marginRight: 10,
    display: 'inline-flex',
    color: '#CC0000',
    textDecoration: 'none',
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'rgba(204, 0, 0, 0.04)',
      border: '1px solid #CC0000',
    },

    '& i': {
      color: theme.palette.primary.red,
      margin: '0 10px 0 0px',
    },
  },
  textAlignC: {
    textAlign: 'center',
    marginBottom: '15px !important',
  },
  link: {
    color: theme.palette.primary.linkColor,
  },
  largeFont: {
    fontSize: '1.5em',
    marginBottom: 5,
  },
  buttonContainer: {
    marginTop: 10,
  },
  supportimage: {
    maxWidth: 500,
    margin: '0 auto',
  },
  helperText: {
    fontSize: '12px',
    color: '#777',
  },
})

class ErrorOverlay extends React.Component {
  state = {
    total: 0,
    loaded: 0,
    failed: 0,
    failedAssets: [],
    isTemplateScene: false,
    reportData: [],
  }
  componentDidMount() {
    document.addEventListener('onAssetLoaded', this.showLoader)
  }
  componentWillMount() {
    document.removeEventListener('onAssetLoaded', this.showLoader)
  }

  showLoader = (e) => {
    const {
      detail: { total, loaded, failed, failedAssets, isTemplateScene },
    } = e
    const data = []
    if (failedAssets.length) {
      failedAssets.forEach((element) => {
        data.push({
          assetId: element.assetId,
          assetType: element.assetType,
          hp: element.hpAvailable ? 'TRUE' : 'FALSE',
          lp: element.lpAvailable ? 'TRUE' : 'FALSE',
          status: 'FAILED',
        })
      })
    }
    this.setState({
      total,
      loaded,
      failed,
      failedAssets,
      isTemplateScene, //scene from template
      reportData: data,
    })
  }
  headers = [
    { label: 'TCIN / Purchased Asset', key: 'assetId' },
    { label: 'Asset Type', key: 'assetType' },
    { label: 'High Poly Available', key: 'hp' },
    { label: 'Low Poly Available', key: 'lp' },
    { label: 'Loading', key: 'status' },
  ]

  renderTemplateSceneErrorModal = () => {
    const { classes, retryCount, buttonAction, skipError } = this.props
    const { total, failed, isTemplateScene, reportData } = this.state

    return (
      <>
        <Grid
          item
          xs={12}
          className={classes.textAlignC + ' ' + classes.largeFont}
        >
          {retryCount >= 3
            ? `Sorry, we are experiencing a problem in our end`
            : `Oops! ${failed} out of ${total} assets could not be loaded
          `}

          {/* */}
        </Grid>
        <Grid item xs={12} className={classes.textAlignC}>
          <p>
            {retryCount >= 3
              ? `Please reach out to our support team or continue styling`
              : `Try reloading or continue styling your scene
          `}
          </p>
          <p className={classes.helperText}>
            * Download report to know more about failed assets
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.textAlignC + ' ' + classes.buttonContainer}
        >
          <CSVLink
            filename="Assets-Report-For-Template.csv"
            headers={this.headers || []}
            data={reportData || []}
            className={classes.downloadButton}
          >
            <i className="fas fa-exclamation" />
            Download Report
          </CSVLink>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => buttonAction(true)}
            className={classes.reloadButton}
          >
            {retryCount >= 3 ? (
              <>
                <i className="far fa-futbol" />
                Support
              </>
            ) : (
              <>
                <i className="fas fa-redo" />
                Try Reloading{' '}
              </>
            )}
          </Button>
          {isTemplateScene && (
            <Button
              variant="contained"
              color="primary"
              onClick={skipError}
              className={classes.reloadButton}
            >
              <i className="fas fa-check" />
              Continue Styling
            </Button>
          )}
        </Grid>
      </>
    )
  }
  renderSceneErrorModal = () => {
    const {
      classes,
      navigate = () => {},
      retryCount,
      buttonAction,
      sceneName = '',
    } = this.props
    const { total, failed, reportData } = this.state
    return (
      <>
        <Grid
          item
          xs={12}
          className={classes.textAlignC + ' ' + classes.largeFont}
        >
          {retryCount >= 3
            ? `Sorry, we are experiencing a problem in our end`
            : `${failed} out of ${total} assets in your scene ${sceneName} could not be loaded`}

          {/* */}
        </Grid>
        <Grid item xs={12} className={classes.textAlignC}>
          <p>
            {retryCount >= 3
              ? `Please reach out to our support team`
              : `Please try reloading again!`}
          </p>
          <p className={classes.helperText}>
            * Download report to know more about failed assets
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.textAlignC + ' ' + classes.buttonContainer}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.iconButton}
            onClick={() => {
              navigate('/home')
            }}
          >
            <i className="fas fa-home" />
            Back To Home
          </Button>
          <CSVLink
            filename="Assets-Report-For-Template.csv"
            headers={this.headers || []}
            data={reportData || []}
            className={classes.downloadButton}
          >
            <i className="fas fa-exclamation" />
            Download Report
          </CSVLink>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => buttonAction(false)}
            className={classes.reloadButton}
          >
            {retryCount >= 3 ? (
              <>
                <i className="far fa-futbol" />
                Support
              </>
            ) : (
              <>
                <i className="fas fa-redo" />
                Try Reloading
              </>
            )}
          </Button>
        </Grid>
      </>
    )
  }

  render() {
    const { isOpen = false } = this.props
    const { isTemplateScene } = this.state

    return (
      <FullScreenDialog fullScreen={false} openForm={isOpen}>
        {isTemplateScene
          ? this.renderTemplateSceneErrorModal()
          : this.renderSceneErrorModal()}
      </FullScreenDialog>
    )
  }
}

ErrorOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  closeHelp: PropTypes.func,
}

export default withStyles(styles)(ErrorOverlay)
