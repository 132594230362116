export const GET_EXPERIENCE_LIST = 'GET_EXPERIENCE_LIST'
export const API_CALL_INITIATED = 'API_CALL_INITIATED'
export const ADD_LIST_PAGE_META = 'ADD_EXPERIENCE_LIST_PAGE_META'
export const API_CALL_FAIL = 'API_CALL_FAIL'
export const FETCH_EXPERIENCE_SUCCESS = 'FETCH_EXPERIENCE_SUCCESS'
export const CLEAR_EXPERIENCES = 'CLEAR_EXPERIENCES'
export const NO_PROJECTS_FOUND = 'NO_PROJECTS_FOUND'
export const GET_UPDATED_EXPERIENCE_ITEM = 'GET_UPDATED_EXPERIENCE_ITEM'
export const REMOVE_EXPERIENCE = 'REMOVE_EXPERIENCE'
export const SET_INITIATOR_LIST = 'SET_INITIATOR_LIST'
export const START_UPDATE_EXPERIENCE = 'START_UPDATE_EXPERIENCE'
export const SUCCESS_UPDATE_EXPERIENCE = 'SUCCESS_UPDATE_EXPERIENCE'
export const FAILURE_UPDATE_EXPERIENCE = 'FAILURE_UPDATE_EXPERIENCE'
export const ADD_EXPERIENCE = 'ADD_EXPERIENCE'
export const FETCH_EXPERIENCE_DETAIL_SUCCESS = 'FETCH_EXPERIENCE_DETAIL_SUCCESS'
export const CLEAR_EXPERIENCE_DETAIL = 'CLEAR_EXPERIENCE_DETAIL'
