import axios from 'axios'
import config from '../../config/config'

export function fetchRenditionImage(
  imgUrl,
  onSuccess = () => {},
  onError = () => {},
  sourceToken
) {
  return axios
    .get(imgUrl, { responseType: 'blob', cancelToken: sourceToken })
    .then((response) => {
      onSuccess(response.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        onError(error)
      }
    })
}

export function cancelRenditionImageCalls(axiosSource) {
  return axiosSource.cancel('Operation canceled by User')
}
/**
 * API call to export the render image.
 * @param {string} renderJobId Render ID needs to be deleted
 * @param {Function} onSuccess Callback on successful delete
 * @param {function} onError Callback on error
 * @param {string} sourceToken Axios source token in case of cancelling the request
 */
export function exportScene(renderJobId, onSuccess, onError, sourceToken) {
  const URL = `${config.exportSceneUrl}?render_job_id=${renderJobId}`
  return axios
    .post(URL, { cancelToken: sourceToken })
    .then((response) => {
      onSuccess(response.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        onError(error)
      }
    })
}
export function cancelExportSceneCalls(axiosSource) {
  return axiosSource.cancel('Export Operation canceled by User!')
}

/**
 * API call to delete render image.
 * @param {string} renderJobId Render ID needs to be deleted
 * @param {Function} onSuccess Callback on successful delete
 * @param {function} onError Callback on error
 * @param {string} sourceToken Axios source token in case of cancelling the request
 */
export function deleteRenderImage(
  renderJobId,
  onSuccess,
  onError,
  sourceToken
) {
  const URL = `${config.experience.renderList}/${renderJobId}`
  return axios
    .delete(URL, { data: {}, cancelToken: sourceToken })
    .then((response) => {
      onSuccess(response.data)
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        onError(error)
      }
    })
}

export function fetchGalleryPreSignedGLBLink(assetId) {
  const url = `${config.gallery.assetDetails}/${assetId}`
  return axios.get(`${url}`).then(({ data: { data: respdata } }) => {
    const urls = {}
    respdata.files.forEach(({ classification, file_sources, file_tags }) => {
      if (classification === 'LOD') {
        file_tags.forEach(({ tag: { key, value } }) => {
          if (
            (key === 'lod_profile_type' || key === 'lod_profile') &&
            file_sources[0].access_url
          ) {
            urls[value] = file_sources[0].access_url
          }
        })
      }
    })
    return urls['LOD4'] ?? urls['LOD3'] ?? ''
  })
}
