import React from 'react'
import { connect } from 'react-redux'
import { forEach, isEmpty, map, isEqual, remove } from 'lodash'
import { withStyles } from '@mui/styles'
import { styled } from '@mui/styles'
import {
  Menu,
  ListItemSecondaryAction,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { InfoCard, CustomIconButton, PageTitleBar } from 'cgi-ui-components'

import {
  multiAssetSelection,
  toggleReportIssueModal,
} from '../../store/cart/ActionCreator'
import Collapsible from '../Collapsible/Collapsible'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import HideSourceOutlinedIcon from '@mui/icons-material/HideSourceOutlined'

import ungroup_context from '../../images/assetIcons/default/mc_ungroup.svg?url'
import group_context from '../../images/assetIcons/default/mc_group.svg?url'
import imgDefault from '../../images/ic_default_board_img.png'

import { getCurrentOS } from '../../helpers/utils'
import AddToCollectionModal from './AddToCollection'
import CartThumbnail from './CartThumbnail'

import {
  SCENE_DELETE,
  SCENE_DUPLICATE,
  SCENE_HIDE,
  SCENE_GROUP,
  SCENE_SHOW,
  SCENE_SELECT,
  SCENE_UPDATE_GROUP,
  SCENE_UNGROUP,
  SCENE_GROUP_HIDE_SHOW,
  LOCK_ASSET,
  GROUP_RENAME,
  SCENE_SAVE,
  FLIP_HORIZONTAL,
  SCENE_GROUP_ADD_ASSETS,
} from '../../constants/scene'
import config from '../../config/config'

import { filterAssetsByType } from '../../helpers/utils'

const OS = getCurrentOS()
const cntrl = OS === 'Mac' ? <>&#8984;</> : `Ctrl`

const styles = (theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    background: 'white',
    width: 290,
    padding: 10,
    boxSizing: 'border-box',
  },
  tcinSelected: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
  },
  eyeEl: {
    visibility: 'hidden',
  },
  itemList: {
    display: 'flex',
    paddingBottom: 5,
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
    justifyContent: 'flex-start',
    '&:hover': {
      '& $eyeEl': {
        visibility: 'inherit !important',
      },
    },
  },

  contextMenu: {
    width: 320,
  },

  hideIconButton: {
    verticalAlign: 'middle',
  },
  infoIconButton: {
    padding: 5,
    verticalAlign: 'middle',
    display: 'inline-flex',
    boxSizing: 'border-box',
  },
  assetName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 130,
    textOverflow: 'ellipsis',
  },
  imageMargin: {
    marginRight: '5px !important',
  },

  assetButtonContainer: {
    marginTop: '10em',
  },
  textAddAsset: {
    fontSize: '10px',
    marginLeft: '40px',
    color: 'grey',
  },
  thumbnailMini: {
    borderRadius: '50%',
    height: 40,
    width: 40,
    marginRight: 8,
  },
  imageMarginEight: {
    marginRight: '8px !important',
  },
  disabledmenuitem: {
    opacity: '0.38',
    cursor: 'pointer',
    PointerEvent: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 16px',
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
  },
})

const HIDE = 'hide'
const SHOW = 'show'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor ? backgroundColor : '#ffffff',
    maxWidth: '100%',
    padding: 0,
    border: '1px solid #dadde9',
    width: 340,
  },
}))

const ErrorTooltip = withStyles((theme) => ({
  tooltip: {
    background: 'transparent',
    width: 200,
    padding: 0,
    margin: 0,
  },
  popper: {
    background: 'transparent',
    padding: 0,
    margin: 0,
  },
}))(Tooltip)

const RedTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#F9E0E0',
    color: '#333333',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)
class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contextMenuOpen: null,
      contextMenuOpenByGroupedAssets: false,
      cartDataState: {},
      tcinCounts: 0,
      selectedAssets: [],
      contextMenuShowHide: null,
      groupContextMenuOpen: null,
      hoverAsset: null,
      renameText: '',
      groupName: '',
      isError: false,
      collectionModal: false,
      disableHoverIcons: false,
    }
    this.trackSelected = []
  }

  componentDidMount = () => {
    this.createCartData()
  }

  createCartData = () => {
    const {
      cartData,
      mode,
      cartData: { allTcinMap },
    } = this.props
    const groupState = {}
    const unGroupedAssets = []
    let tcinCounts = 0
    let contextMenuShowHide = null

    //recompute allTcinMap based on mode selected
    let updatedAllTcinMap = {}
    let combinedDataArr = Object.entries(allTcinMap).map(([key, value]) => ({
      key,
      ...value,
    })) //combine allTcinMap key and value into one object

    let filteredAssets = filterAssetsByType(combinedDataArr, mode)
    filteredAssets.forEach((item) => {
      updatedAllTcinMap[item.key] = item
    }) //regenerate key value pair

    forEach(updatedAllTcinMap, (tcinObj, tcin) => {
      if (!isEmpty(tcinObj.group)) {
        if (tcinObj.group.name in groupState) {
          groupState[tcinObj.group.name].group_items.push(tcin)
        } else {
          groupState[tcinObj.group.name] = {
            ...tcinObj.group,
            group_items: [tcin],
          }
        }
      } else {
        unGroupedAssets.push(tcin)
      }

      if (tcinObj.hidden && tcinObj.selected) {
        if (contextMenuShowHide === SHOW) {
          contextMenuShowHide = null
        } else {
          contextMenuShowHide = HIDE
        }
      }
      if (!tcinObj.hidden && tcinObj.selected) {
        if (contextMenuShowHide === HIDE) {
          contextMenuShowHide = null
        } else {
          contextMenuShowHide = SHOW
        }
      }

      if (!tcinObj.deleted) tcinCounts++
    })
    this.setState({
      cartDataState: {
        ...cartData,
        groups: groupState,
        assets: unGroupedAssets,
      },
      tcinCounts,
      contextMenuShowHide,
    })
  }

  componentDidUpdate = (prevProps) => {
    const {
      cartData: { allTcinMap },
      mode,
    } = this.props
    const {
      cartData: { allTcinMap: prevAllTcinMap = {} },
      mode: prevMode,
    } = prevProps

    if (!isEqual(prevAllTcinMap, allTcinMap) || !isEqual(prevMode, mode)) {
      this.createCartData()
    }
  }

  onAssetClick = async (event, asset, byGrouped = false) => {
    const { handleCartActions } = this.props

    if (!(event.metaKey || event.ctrlKey)) {
      await this.setState({ selectedAssets: [] })
    }
    if (this.state.selectedAssets.indexOf(asset) === -1) {
      await this.setState({
        selectedAssets: [...this.state.selectedAssets, asset],
      })
    } else {
      const newSelectedAssets = [...this.state.selectedAssets]
      remove(newSelectedAssets, (o) => o === asset)
      await this.setState({ selectedAssets: newSelectedAssets })
    }

    this.setState(
      {
        contextMenuOpenByGroupedAssets: byGrouped,
      },
      () => {
        handleCartActions(
          SCENE_SELECT,
          this.handleContextMenuClose,
          {},
          this.state.selectedAssets
        )
      }
    )
  }

  onAssetRightClick = (event, asset, byGrouped = false) => {
    const { cartData, handleCartActions } = this.props
    event.preventDefault()
    let multiSelected = 0
    forEach(cartData.allTcinMap, (tcinObj) => {
      if (tcinObj.selected) multiSelected = multiSelected + 1
    })

    if (multiSelected <= 1) {
      this.setState({
        selectedAssets: [asset],
      })
    }

    this.setState(
      {
        contextMenuOpen: event.currentTarget,
        contextMenuOpenByGroupedAssets: byGrouped,
      },
      () => {
        handleCartActions(SCENE_SELECT, () => {}, {}, this.state.selectedAssets)
      }
    )
  }

  handleContextMenuClose = () => {
    this.setState({ contextMenuOpen: null, groupContextMenuOpen: null })
  }

  deleteAssetHandler = (assets) => {
    const { handleCartActions } = this.props
    handleCartActions(SCENE_DELETE, this.handleContextMenuClose)
  }

  hideAssetHandler = (assets) => {
    const { handleCartActions } = this.props
    handleCartActions(SCENE_HIDE, this.handleContextMenuClose, {}, assets)
  }
  reportAndIssue = () => {
    const { toggleReportIssueModal } = this.props
    toggleReportIssueModal(true)
    this.handleContextMenuClose()
  }

  showAssetHandler = (assets) => {
    const { handleCartActions } = this.props
    handleCartActions(SCENE_SHOW, this.handleContextMenuClose, {}, assets)
  }

  groupAssetHandler = (assets) => {
    const { handleCartActions } = this.props
    handleCartActions(SCENE_GROUP, this.handleContextMenuClose)
  }

  unGroupAssetHandler = (assets) => {
    const { handleCartActions } = this.props
    handleCartActions(
      SCENE_UPDATE_GROUP,
      this.handleContextMenuClose,
      {},
      assets
    )
  }

  unGroupAllAssetsHandler = () => {
    const { handleCartActions } = this.props
    handleCartActions(SCENE_UNGROUP, this.handleContextMenuClose)
  }

  deleteGroup = () => {
    const { handleCartActions } = this.props
    handleCartActions(SCENE_DELETE, this.handleContextMenuClose)
  }

  duplicateAssetHandler = (assets) => {
    const { handleCartActions } = this.props
    handleCartActions(SCENE_DUPLICATE, this.handleContextMenuClose)
  }
  flipHorizontalAsset = (assets) => {
    const { handleCartActions } = this.props
    handleCartActions(FLIP_HORIZONTAL, this.handleContextMenuClose)
  }
  lockGroup = () => {
    const { handleCartActions } = this.props
    handleCartActions(LOCK_ASSET, this.handleContextMenuClose)
    handleCartActions(SCENE_SELECT, () => {}, {}, [])
  }

  selectGroupHandler = async (select) => {
    const { open } = select
    const { handleCartActions } = this.props
    handleCartActions(
      SCENE_SELECT,
      () => {},
      {},
      open ? [select.data.name] : []
    )
  }

  renameGroup = (e, data, isError = false) => {
    const { cartDataState } = this.state
    let cartData = cartDataState
    cartData.groups[data[0]]['isRename'] = true
    this.setState({
      cartDataState: cartData,
      groupName: data[0],
      renameText: data[0],
      isError: isError,
    })
    this.handleContextMenuClose()
  }

  handleRename = (e) => {
    this.setState({
      renameText: e.target.value,
    })
  }
  handleAddToCollection = () => {
    const { selectedAssets } = this.state
    this.renameGroup(null, selectedAssets, true)
  }
  onEnterRename = (e) => {
    const { handleCartActions } = this.props
    const { cartDataState, renameText, groupName, selectedAssets } = this.state
    if (e.key === 'Enter') {
      let findDuplicate = Object.keys(cartDataState.groups).findIndex(
        (item) => item.toLowerCase() === renameText.toLowerCase()
      )
      if (groupName === renameText) {
        let cartData = cartDataState
        cartData.groups[selectedAssets[0]]['isRename'] = false
        this.setState({
          cartDataState: cartData,
          isError: false,
        })
        return
      }
      if (findDuplicate >= 0) {
        this.setState({
          isError: true,
        })
      } else {
        this.setState({
          isError: false,
          groupName: renameText,
          selectedAssets: [renameText],
        })
        handleCartActions(
          GROUP_RENAME,
          () => {
            handleCartActions(SCENE_SAVE)
            handleCartActions(SCENE_SELECT)
          },
          {},
          [],
          {
            oldName: groupName,
            newName: renameText,
          }
        )
      }
    }
  }

  onGroupRightClick = async (event, group) => {
    const { handleCartActions } = this.props
    event.preventDefault()
    this.setState({
      groupContextMenuOpen: event.currentTarget,
      selectedAssets: [group.name],
    })

    handleCartActions(SCENE_SELECT, () => {}, {}, [group.name])
  }

  toggleHideGroupHandler = (selectedAssets) => {
    const { handleCartActions } = this.props
    handleCartActions(
      SCENE_GROUP_HIDE_SHOW,
      this.handleContextMenuClose,
      {},
      selectedAssets
    )
  }
  addToCollection = () => {
    const { collectionModal, selectedAssets } = this.state
    this.setState({
      collectionModal: !collectionModal,
      groupName: selectedAssets[0],
      renameText: '',
    })
    this.handleContextMenuClose()
  }
  handleOnEyeClick = async (assetName, assetObj) => {
    const sceneAction = assetObj.hidden ? SCENE_SHOW : SCENE_HIDE
    await this.props.handleCartActions(
      sceneAction,
      this.handleContextMenuClose,
      {},
      [assetName]
    )
  }
  handleDots = (event, asset) => {
    event.stopPropagation()
    this.onAssetRightClick(event, asset)
  }
  /**
 * 
 * @returns 
 * <Tooltip
        placement={'top'}
        title={'Please remove the template version of asset from group'}
      
      >
 */
  renderDisabledATC = () => {
    const { classes } = this.props
    return (
      <>
        <Tooltip
          placement={'top'}
          title={'Please remove the template version of asset from group'}
        >
          <li
            className={
              'MuiMenuItem-root MuiMenuItem-gutters MuiButtonBase-root  ' +
              classes.disabledmenuitem
            }
            disabled={true}
          >
            <ListItemIcon>
              <FavoriteBorderOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add to Collection</ListItemText>
            <Typography variant="body2" color="text.secondary">
              T
            </Typography>
          </li>
        </Tooltip>
      </>
    )
  }

  handleClose = (event) => {
    event && event.nativeEvent && event.nativeEvent.preventDefault()
  }

  handleNewCollection = () => {
    const { isCreateCollectionDialogOpened, collectionModal } = this.state
    this.setState({
      isCreateCollectionDialogOpened: !isCreateCollectionDialogOpened,
      collectionModal: !collectionModal,
    })
  }

  onDragStart = () => {
    this.setState({
      disableHoverIcons: true,
    })
  }

  onDragEnd = (result) => {
    const { source, destination } = result
    const { handleCartActions } = this.props
    const { cartDataState } = this.state
    this.setState({
      disableHoverIcons: false,
    })
    if (destination) {
      let groupObj = {
        selectedAssets: [cartDataState.assets[source.index]],
        groupName: destination.droppableId,
      }
      handleCartActions(SCENE_GROUP_ADD_ASSETS, () => {}, {}, [], groupObj)
    }
  }

  onDragUpdate = (result) => {
    // const { cartDataState = {} } = this.state
    // const { source, destination } = result
    // let data = {
    //   open: true,
    //   data: cartDataState.groups[destination && destination.droppableId],
    // }
    // if (destination && destination.droppableId !== 'DroPpABle') {
    //   this.selectGroupHandler(data)
    // } else {
    //   data = {
    //     open: false,
    //     data: cartDataState.groups[destination && destination.droppableId],
    //   }
    //   this.selectGroupHandler(data)
    // }
  }

  getListStyle = (isDraggingOver) => ({
    border: !isDraggingOver ? 'unset' : '1px dotted black',
  })

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    cursor: isDragging ? 'grab' : 'pointer',
    ...draggableStyle,
  })

  render() {
    const {
      classes,
      cartData: { scene_data: { assets = [] } = {} } = {},
      Board: { assetObjects: boardAssetObjects },
    } = this.props
    const {
      contextMenuOpen,
      cartDataState,
      tcinCounts,
      contextMenuOpenByGroupedAssets,
      contextMenuShowHide,
      groupContextMenuOpen,
      selectedAssets,
      renameText,
      isError,
      collectionModal,
      isCreateCollectionDialogOpened,
      groupName,
      boardData: boardDataList = [],
      disableHoverIcons,
    } = this.state
    let data =
      (cartDataState &&
        cartDataState.scene_data &&
        cartDataState.scene_data.groups) ||
      []

    let grpHasTemplateAssetVersion = false
    let isLocked = false
    let isGroupHide = false
    if (
      selectedAssets[0] &&
      cartDataState.groups &&
      cartDataState.groups[selectedAssets[0]] &&
      cartDataState.scene_data &&
      cartDataState.scene_data.groups
    ) {
      grpHasTemplateAssetVersion = cartDataState.scene_data.groups
        .find((item) => item.name === selectedAssets[0])
        .assets.find((item) => item.subAssetType === 'TEMPLATE')

      isLocked = cartDataState.scene_data.groups.find(
        (item) => item.name === selectedAssets[0]
      ).locked
        ? false
        : true

      isGroupHide = cartDataState.groups[selectedAssets[0]].hidden
        ? true
        : false
    }

    const assetsRender = (cartDataState.assets || [])
      .sort((a, b) => (a > b ? 1 : -1))
      .map((assetName, index) => {
        const cartAsset = cartDataState.allTcinMap[assetName]
        const assetData = boardAssetObjects[cartAsset.originalAssetId]

        return cartAsset.deleted ? null : (
          <Draggable key={assetName} draggableId={assetName} index={index}>
            {(provided, snapshot) => (
              <ListItem
                key={assetName}
                selected={cartAsset.selected}
                classes={{
                  selected: classes.tcinSelected,
                  root: classes.itemList,
                }}
                button
                onClick={(event) => {
                  this.onAssetClick(event, assetName)
                }}
                onContextMenu={(event) =>
                  this.onAssetRightClick(event, assetName)
                }
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={this.getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <CartThumbnail
                  data={assetData}
                  className={classes.thumbnailMini}
                />
                <span
                  style={{ color: cartAsset.hidden && '#aaa' }}
                  className={classes.assetName}
                >
                  {assetName}
                </span>
                <div
                  className={classes.eyeEl}
                  style={{
                    visibility: cartAsset.hidden ? 'inherit' : 'hidden',
                  }}
                >
                  <ListItemSecondaryAction>
                    <CustomIconButton
                      onClick={() =>
                        this.handleOnEyeClick(assetName, cartAsset)
                      }
                      size="small"
                      aria-label="Hide"
                      label={cartAsset.hidden ? 'Show' : 'Hide'}
                      className={classes.hideIconButton}
                    >
                      {cartAsset.hidden ? (
                        <CircleOutlinedIcon fontSize="small" />
                      ) : (
                        <HideSourceOutlinedIcon fontSize="small" />
                      )}
                    </CustomIconButton>

                    <HtmlTooltip
                      interactive
                      placement="left"
                      title={
                        <React.Fragment>
                          <InfoCard
                            name="Info"
                            config={config}
                            thumbnail={assetData?.thumbnail_url}
                            isUrlAppendedWithImg={
                              assetData?.asset_type !== 'PID'
                            }
                            noThumbnail={imgDefault}
                            infoData={[
                              {
                                label: 'Asset Type',
                                value: assetData?.asset_type,
                              },
                              {
                                label: 'value',
                                value: assetData?.value || assetData?.pid,
                              },
                              { label: 'Dpci', value: assetData?.dpci },
                              {
                                label: 'Product type',
                                value: assetData?.product_type,
                              },
                              {
                                label: 'Dimensions',
                                value: assetData?.dimensions,
                              },
                              { label: 'Brand', value: assetData?.brand_name },
                              {
                                label: 'Materials',
                                value: assetData?.materials,
                              },
                              {
                                label: 'Title',
                                value: assetData?.product_desc,
                              },
                              {
                                label: 'Short Description',
                                value: assetData?.short_description,
                              },
                            ]}
                          />
                        </React.Fragment>
                      }
                    >
                      <span className={classes.infoIconButton}>
                        <InfoOutlinedIcon fontSize="small" />
                      </span>
                    </HtmlTooltip>

                    <CustomIconButton
                      className={classes.hideIconButton}
                      onClick={(event) => this.handleDots(event, assetName)}
                      size="small"
                      label="More"
                    >
                      <MoreVertOutlinedIcon fontSize="small" />
                    </CustomIconButton>
                  </ListItemSecondaryAction>
                </div>
              </ListItem>
            )}
          </Draggable>
        )
      })

    const groupsRender = map(cartDataState.groups, (group) => (
      <Droppable droppableId={group.name} ignoreContainerClipping={true}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={this.getListStyle(snapshot.isDraggingOver)}
          >
            <Collapsible
              onClick={this.selectGroupHandler}
              selected={group.selected}
              disableHoverIcons={disableHoverIcons}
              key={group.name}
              data={group}
              onClickListItem={this.onAssetClick}
              contextMenuHandler={this.onAssetRightClick}
              selectedData={cartDataState.allTcinMap}
              onContextMenu={(event) => this.onGroupRightClick(event, group)}
              handleOnEyeClick={this.handleOnEyeClick}
              displayData={cartDataState}
              groupName={renameText}
              handleRename={this.handleRename}
              onEnterRename={this.onEnterRename}
              isError={isError}
              boardDataList={boardDataList}
              boardAssetObjects={boardAssetObjects}
            />
            {/* {provided.placeholder} */}
          </div>
        )}
      </Droppable>
    ))

    return (
      <>
        <div className={classes.root}>
          <PageTitleBar
            title="Scene Explorer"
            subTitle={
              tcinCounts > 0
                ? `${tcinCounts} asset${tcinCounts > 1 ? 's' : ''} in the scene`
                : ` The scene explorer is empty`
            }
          />

          <section className={classes.padding}>
            {tcinCounts > 0 ? null : (
              <div className={classes.assetButtonContainer}>
                <span style={{ fontSize: '54px' }}>
                  <i
                    className="fas fa-paint-brush"
                    aria-hidden="true"
                    style={{
                      color: '#D3D3D3',
                      marginLeft: '85px',
                      marginBottom: '8px',
                    }}
                  />
                </span>
                <div className={classes.textAddAsset}>
                  Styling environment is empty !
                </div>
              </div>
            )}
            <DragDropContext
              onDragEnd={this.onDragEnd}
              onDragUpdate={this.onDragUpdate}
              onDragStart={this.onDragStart}
            >
              <Droppable droppableId="DroPpABle">
                {(provided, snapshot) => (
                  <List
                    component="div"
                    disablePadding
                    ref={provided.innerRef}
                    style={{ height: (cartDataState.assets || []).length * 56 }}
                  >
                    {assetsRender}
                  </List>
                )}
              </Droppable>
              {groupsRender}
            </DragDropContext>
          </section>
        </div>

        <Menu
          anchorEl={contextMenuOpen}
          open={Boolean(contextMenuOpen)}
          onClose={this.handleContextMenuClose}
          MenuListProps={{ dense: true, className: classes.contextMenu }}
        >
          {selectedAssets.length === 1 ? (
            <MenuItem
              onClick={() => this.duplicateAssetHandler(selectedAssets)}
            >
              <ListItemIcon>
                <CopyAllOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Duplicate</ListItemText>
              <Typography variant="body2" color="text.secondary">
                &#8984; + D
              </Typography>
            </MenuItem>
          ) : null}
          {selectedAssets.length === 1 ? (
            <MenuItem onClick={() => this.deleteAssetHandler(selectedAssets)}>
              <ListItemIcon>
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
              <Typography variant="body2" color="text.secondary">
                DELETE
              </Typography>
            </MenuItem>
          ) : null}
          {contextMenuShowHide === SHOW && !contextMenuOpenByGroupedAssets ? (
            <MenuItem onClick={() => this.hideAssetHandler(selectedAssets)}>
              <ListItemIcon>
                <HideSourceOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {' '}
                {selectedAssets.length <= 1 ? 'Hide' : 'Hide All'}
              </ListItemText>
              <Typography variant="body2" color="text.secondary">
                &#8984; + H
              </Typography>
            </MenuItem>
          ) : null}
          {contextMenuShowHide === HIDE ? (
            <MenuItem onClick={() => this.showAssetHandler(selectedAssets)}>
              <ListItemIcon>
                <CircleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {' '}
                {selectedAssets.length <= 1 ? 'Unhide' : 'Unhide All'}
              </ListItemText>
              <Typography variant="body2" color="text.secondary">
                {/* &#8984; + Shift + H */}
              </Typography>
            </MenuItem>
          ) : null}
          {selectedAssets.length === 1 ? (
            <MenuItem onClick={() => this.flipHorizontalAsset(selectedAssets)}>
              <ListItemIcon>
                <FlipOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText> Flip Horizontal</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {/* &#8984; + Shift + H */}
              </Typography>
            </MenuItem>
          ) : null}
          {!contextMenuOpenByGroupedAssets && selectedAssets.length > 1 ? (
            <MenuItem onClick={() => this.groupAssetHandler(selectedAssets)}>
              <ListItemIcon>
                <img
                  className={classes.imageMarginEight}
                  src={group_context}
                  alt="group asset icon"
                />
              </ListItemIcon>
              <ListItemText>Group Selected Assets</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {/* &#8984; + Shift + H */}
              </Typography>
            </MenuItem>
          ) : null}
          {contextMenuOpenByGroupedAssets ? (
            <MenuItem onClick={() => this.unGroupAssetHandler(selectedAssets)}>
              <ListItemIcon>
                <img
                  className={classes.imageMarginEight}
                  src={ungroup_context}
                  alt="ungroup asset icon"
                />
              </ListItemIcon>
              <ListItemText> Un-group Selected Assets</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {/* &#8984; + Shift + H */}
              </Typography>
            </MenuItem>
          ) : null}
          {contextMenuShowHide === SHOW && selectedAssets.length === 1 ? (
            <MenuItem onClick={this.reportAndIssue}>
              <ListItemIcon>
                <BugReportOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Report an Issue</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {/* &#8984; + Shift + H */}
              </Typography>
            </MenuItem>
          ) : null}
        </Menu>

        <Menu
          anchorEl={groupContextMenuOpen}
          open={Boolean(groupContextMenuOpen)}
          onClose={this.handleContextMenuClose}
          MenuListProps={{ dense: true, className: classes.contextMenu }}
        >
          {isLocked ? (
            <>
              <MenuItem onClick={(e) => this.renameGroup(e, selectedAssets)}>
                <ListItemIcon>
                  <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rename</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {/* &#8984; + Shift + H */}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => this.unGroupAllAssetsHandler()}>
                <ListItemIcon>
                  <img
                    style={{ marginRight: 10 }}
                    src={ungroup_context}
                    alt="ungroup asset icon"
                  />
                </ListItemIcon>
                <ListItemText> Un-group</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+&#8679;+G
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => this.duplicateAssetHandler()}>
                <ListItemIcon>
                  <CopyAllOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+d
                </Typography>
              </MenuItem>
            </>
          ) : null}
          <MenuItem onClick={() => this.lockGroup()}>
            <ListItemIcon>
              {isLocked ? (
                <LockOutlinedIcon fontSize="small" />
              ) : (
                <LockOpenOutlinedIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText> {isLocked ? 'Lock' : 'Unlock'}</ListItemText>
            <Typography variant="body2" color="text.secondary">
              {cntrl}+l
            </Typography>
          </MenuItem>
          {isLocked ? (
            <>
              <MenuItem
                onClick={() => this.toggleHideGroupHandler(selectedAssets)}
              >
                <ListItemIcon>
                  {!isGroupHide ? (
                    <HideSourceOutlinedIcon fontSize="small" />
                  ) : (
                    <CircleOutlinedIcon fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText> {!isGroupHide ? 'Hide' : 'Unhide'}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+h
                </Typography>
              </MenuItem>
              {grpHasTemplateAssetVersion ? (
                this.renderDisabledATC()
              ) : (
                <MenuItem onClick={() => this.addToCollection(selectedAssets)}>
                  <ListItemIcon>
                    <FavoriteBorderOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText> Add to Collection</ListItemText>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  ></Typography>
                </MenuItem>
              )}
            </>
          ) : null}
          <MenuItem onClick={() => this.deleteGroup()}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText> Delete group</ListItemText>
            <Typography variant="body2" color="text.secondary">
              delete
            </Typography>
          </MenuItem>
        </Menu>

        <AddToCollectionModal
          isOpen={collectionModal}
          handleClose={this.addToCollection}
          handleNewCollection={this.handleNewCollection}
          isCreateCollectionDialogOpened={isCreateCollectionDialogOpened}
          handleAddToCollection={this.handleAddToCollection}
          groupName={renameText || groupName}
          isError={isError}
          data={data}
        />
      </>
    )
  }
}

const mapStateToProps = ({ cart, Board, scene: { mode } }) => ({
  cartData: cart,
  Board,
  mode,
})

export default connect(mapStateToProps, {
  multiAssetSelection,
  toggleReportIssueModal,
})(withStyles(styles)(Cart))
