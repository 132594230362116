import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Slider from '@mui/material/Slider'
import TextField from '@mui/material/TextField'
import { SCALE_CONFIG } from '../../constants/common'

export class AssetScaleContent extends Component {
  render() {
    const {
      classes,
      scale,
      handleSliderChange,
      handleInputChange,
      handleBlur,
    } = this.props
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>W</Grid>
            <Grid item xs>
              <Slider
                color={'tertiary'}
                value={typeof scale.x === 'number' ? scale.x : 0}
                onChange={handleSliderChange('x', 'slider')}
                aria-labelledby="input-x-slider"
                min={SCALE_CONFIG.MIN_SCALE}
                step={SCALE_CONFIG.STEPS}
                max={SCALE_CONFIG.MAX_SCALE}
              />
            </Grid>
            <Grid item>
              <TextField
                value={scale.x}
                margin="dense"
                onChange={handleInputChange('x', 'input')}
                onBlur={handleBlur('x')}
                variant="outlined"
                size="small"
                inputProps={{
                  step: SCALE_CONFIG.STEPS,
                  min: SCALE_CONFIG.MIN_SCALE,
                  max: SCALE_CONFIG.MAX_SCALE,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>L</Grid>
            <Grid item xs>
              <Slider
                value={typeof scale.y === 'number' ? scale.y : 0}
                onChange={handleSliderChange('y', 'slider')}
                aria-labelledby="input-y-slider"
                min={SCALE_CONFIG.MIN_SCALE}
                step={SCALE_CONFIG.STEPS}
                max={SCALE_CONFIG.MAX_SCALE}
                color={'tertiary'}
              />
            </Grid>
            <Grid item>
              <TextField
                value={scale.y}
                margin="dense"
                onChange={handleInputChange('y', 'input')}
                onBlur={handleBlur('y')}
                variant="outlined"
                size="small"
                inputProps={{
                  step: SCALE_CONFIG.STEPS,
                  min: SCALE_CONFIG.MIN_SCALE,
                  max: SCALE_CONFIG.MAX_SCALE,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>H</Grid>
            <Grid item xs>
              <Slider
                value={typeof scale.z === 'number' ? scale.z : 0}
                onChange={handleSliderChange('z', 'slider')}
                aria-labelledby="input-z-slider"
                min={SCALE_CONFIG.MIN_SCALE}
                step={SCALE_CONFIG.STEPS}
                max={SCALE_CONFIG.MAX_SCALE}
                color={'tertiary'}
              />
            </Grid>
            <Grid item>
              <TextField
                value={scale.z}
                size="small"
                margin="dense"
                onChange={handleInputChange('z', 'input')}
                onBlur={handleBlur('z')}
                variant="outlined"
                inputProps={{
                  step: SCALE_CONFIG.STEPS,
                  min: SCALE_CONFIG.MIN_SCALE,
                  max: SCALE_CONFIG.MAX_SCALE,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

AssetScaleContent.propTypes = {
  classes: PropTypes.object.isRequired,
  scale: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
  handleInputChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSliderChange: PropTypes.func.isRequired,
}
const NOOP = () => {}
AssetScaleContent.defaultProp = {
  scale: {
    x: 1,
    y: 1,
    z: 1,
  },
  handleInputChange: NOOP,
  handleBlur: NOOP,
  handleSliderChange: NOOP,
}

export default AssetScaleContent
