import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import axios from 'axios'
import { isEmpty, cloneDeep } from 'lodash'
import { MenuItem, Typography, MenuList, Tooltip, Box } from '@mui/material'
import { CustomIconButton } from 'cgi-ui-components'
import { withAnalytics } from '@praxis/component-analytics'

import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import RadarOutlinedIcon from '@mui/icons-material/RadarOutlined'
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined'

import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import HideSourceOutlinedIcon from '@mui/icons-material/HideSourceOutlined'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import classNames from 'classnames'
import ThreeScene from '../../lib/threeScene'
import config from '../../config/config'
import ungroup_context from '../../images/assetIcons/default/mc_ungroup.svg?url'

import group_context from '../../images/assetIcons/default/mc_group.svg?url'

import snap_context from '../../images/assetIcons/default/mc_snap.svg?url'
import SelectAllIcon from '@mui/icons-material/SelectAll'

import { showNotification } from '../../store/notification/actionCreator'
import { setRenderDetails } from '../../store/scene/ActionCreator'
import TextureOutlinedIcon from '@mui/icons-material/TextureOutlined'

import {
  radToAng,
  angToRad,
  compareObjectEquality,
  getCurrentOS,
  createCustomEvent,
} from '../../helpers/utils'
import { TRACK_SCENE_EVENT } from '../../constants/scene'
import {
  SCENE_ESCAPE,
  SCENE_MOVE,
  SCENE_ROTATE,
  SCENE_DELETE,
  SCENE_DUPLICATE,
  SCENE_GROUP,
  SCENE_UNGROUP,
  SCENE_HIDE,
  SCENE_SHOW,
  SCENE_SHOW_ALL,
  SCENE_SELECT,
  SCENE_UPDATE_GROUP,
  SCENE_VIEW_3D,
  SCENE_VIEW_2D,
  SCENE_UNDO,
  SCENE_REDO,
  REPLACE_ASSET,
  SCALE_ASSET,
  FOCUS_ASSET,
  LOCK_ASSET,
  SCENE_GROUP_HIDE_SHOW,
  ZOOM_IN,
  ZOOM_OUT,
  GROUP_RENAME,
  FLIP_HORIZONTAL,
  RENDER_HEADER_HEIGHT,
  SCENE_GROUP_ADD_ASSETS,
  EDIT_MATERIAL,
} from '../../constants/scene'

import {
  multiAssetSelection,
  toggleReportIssueModal,
} from '../../store/cart/ActionCreator'
import { setActiveOperation } from '../../store/operations/actionCreator'
import {
  handleOpenExplorer,
  handleCloseExplorer,
} from '../../store/explorer/actionCreator'
import {
  fetchSimilarAssets,
  showSimilarSearchResults,
  clearSimilarAssets,
} from '../../store/explorer/actionCreator'
import ControlsPanel from '../ControlsPanel/ControlsPanel'
import TilingControls from '../ControlsPanel/TilingControls'
import EventTracker from './EventTracker'

let Scene = null
if (window.WebGLRenderingContext) {
  Scene = new ThreeScene()
}

const OS = getCurrentOS()
const cntrl = OS === 'Mac' ? <>&#8984;</> : `Ctrl`

const style = (theme) => ({
  root: {
    position: 'absolute',
    top: RENDER_HEADER_HEIGHT,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
  },
  toolWrapper: {
    width: 320,
    zIndex: 25,
    position: 'absolute',
    display: 'none',
    padding: '10px 0',
    backgroundColor: '#FFF',
    boxShadow: '4px 1px 7px #888',
    '& i': {
      fontSize: 14,
    },

    '& .active-mode-icon': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      cursor: 'pointer',
      color: 'white',
      backgroundColor: '#bd1800',
      borderRadius: '50%',
      border: `1px solid white`,
    },
  },
  toolMenu: {
    padding: 0,
    margin: 0,
  },
  toolMenuItem: {
    minHeight: 20,
    '&.cancelReplace': {
      backgroundColor: theme.palette.primary.linkColor,
      '& a': {
        color: '#FFF',
      },
    },
    '&.cancelScale': {
      backgroundColor: theme.palette.primary.linkColor,
      '& a': {
        color: '#FFF',
      },
    },
  },
  controlsContainer: {
    display: 'block',
    position: 'absolute',
    bottom: 45,
    right: 25,
    zIndex: 20,
  },
  rightCartOpenStyle: {
    right: '390px !important',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
  },

  hideControlPanel: {
    display: 'none',
  },
  expandIcon: {
    backgroundColor: theme.palette.secondary.main,
    transform: 'rotateZ(45deg)',
    borderRadius: '50%',
    position: 'fixed',
    bottom: 45,
    right: 25,
    zIndex: 20,
  },
  snapImg: {
    marginRight: 5,
    verticalAlign: 'text-top',
  },
  measureIcon: {
    '&.MuiIconButton-root': {
      position: 'fixed',
      background: '#FFF',
      top: 240,
      right: 19,
      zIndex: 999,
      padding: 15,
      height: 52,
      boxShadow: '0 4px 8px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 15%)',
      '& i': {
        fontSize: 18,
      },
      '&:hover i': {
        color: theme.palette.primary.main,
      },
    },
  },
})
let userCanWrite = true
const checkIfAnyOprActive = (activeOperations) => {
  const oprs = Object.keys(activeOperations)
  const isAnyOprActive = oprs.filter((op) => activeOperations[op]).length > 0
  if (Scene !== null) {
    if (isAnyOprActive) {
      Scene.isActiveOperation = true
    } else {
      Scene.isActiveOperation = false
    }
  }

  return isAnyOprActive
}

class DesignSpace extends React.PureComponent {
  element = createRef()
  state = {
    mouseX: null,
    mouseY: null,
    isControlsPanelOpen: true,
    tilingmode: false,
  }
  toggleControlsPanel = () => {
    const { isControlsPanelOpen = true } = this.state
    this.setState({ isControlsPanelOpen: !isControlsPanelOpen })
  }
  reportAndIssue = () => {
    const { toggleReportIssueModal } = this.props
    toggleReportIssueModal(true)
    hideContextMenu()
  }
  render() {
    const {
      classes,
      activeOperations = [],
      isRightCartOpen,
      isRenderMode,
      isPreviewPage,
      defaultView,
      controlPanelRight,
      renderData: { locked },
      selectedCamera,
      viewMode,
      toggleView = () => {},
      editTemplateMode = false,
      sceneMode,
    } = this.props
    const { isControlsPanelOpen = true, tilingmode } = this.state
    let showControlPanel = true

    if (isPreviewPage) {
      showControlPanel = false
    }
    if (isRenderMode && selectedCamera !== 'default' && locked) {
      showControlPanel = false
    }
    return (
      <>
        <div className={this.props.classes.root} ref={this.element} />
        <div
          id="assetControls"
          className={classes.toolWrapper}
          onContextMenu={this.preventDefault}
        >
          {!checkIfAnyOprActive(activeOperations) && (
            <MenuList dense className={classes.toolMenu}>
              <MenuItem
                className={classes.toolMenuItem + ' move hideforgroup'}
                onMouseDown={this.enableTranslate}
              >
                <ListItemIcon>
                  <OpenWithOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Move</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  T
                </Typography>
              </MenuItem>

              <MenuItem
                className={classes.toolMenuItem + ' rotate hideforgroup'}
                onMouseDown={this.enableRotation}
              >
                <ListItemIcon>
                  <CachedOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rotate</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  R
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' hideforgroup'}
                onMouseDown={this.focusAsset}
              >
                <ListItemIcon>
                  <RadarOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Focus into asset</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  F
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem}
                onMouseDown={this.duplicateAsset}
              >
                <ListItemIcon>
                  <CopyAllOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Duplicate</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+d
                </Typography>
              </MenuItem>
              <MenuItem
                onMouseDown={this.scaleAsset}
                className={classes.toolMenuItem}
              >
                <ListItemIcon>
                  <StraightenOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Scale</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' hideforgroup tile'}
                onMouseDown={(e) => {
                  this.enableTiling(e, true)
                }}
              >
                <ListItemIcon>
                  <SelectAllIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Tile Asset</ListItemText>
              </MenuItem>

              {sceneMode === 'ARCHITECTURE' && (
                <MenuItem
                  onMouseDown={this.openMaterialEditor}
                  className={classes.toolMenuItem + ' hideforgroup'}
                >
                  <ListItemIcon>
                    <TextureOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Material</ListItemText>
                </MenuItem>
              )}

              <MenuItem
                onClick={this.replaceAsset}
                className={classes.toolMenuItem + ' hideforgroup'}
              >
                <ListItemIcon>
                  <SwapHorizOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Swap with other assets</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={this.showSimilarAssets}
                className={classes.toolMenuItem + ' hideforgroup'}
              >
                <ListItemIcon>
                  <ImageSearchOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Find Similar Asset</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' cancelReplace hideforgroup'}
              >
                <ListItemIcon>
                  <SwapHorizOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Swapping</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  <i
                    onMouseDown={this.deactivateReplaceMode}
                    className="fa fa-times"
                  />
                </Typography>
              </MenuItem>
              <MenuItem
                onMouseDown={this.unGroup}
                className={classes.toolMenuItem + ' ungroup'}
              >
                <ListItemIcon>
                  <img alt="ungroup" src={ungroup_context} />
                </ListItemIcon>
                <ListItemText>Un-Group</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+&#8679;+G
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' group multiMode'}
                onMouseDown={this.groupAssets}
              >
                <ListItemIcon>
                  <img alt="group" src={group_context} />{' '}
                </ListItemIcon>
                <ListItemText>Group</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+G
                </Typography>
              </MenuItem>

              <MenuItem
                onMouseDown={this.lockAsset}
                className={classes.toolMenuItem + ' multiMode lock'}
              >
                <ListItemIcon>
                  <LockOutlinedIcon fontSize="small" />{' '}
                </ListItemIcon>
                <ListItemText>Lock</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+l
                </Typography>
              </MenuItem>

              <MenuItem
                className={classes.toolMenuItem + ' multiMode'}
                onMouseDown={this.hideAsset}
              >
                <ListItemIcon>
                  <HideSourceOutlinedIcon fontSize="small" />{' '}
                </ListItemIcon>
                <ListItemText>Hide</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {cntrl}+h
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' unhide'}
                onMouseDown={this.unHideAsset}
              >
                <ListItemIcon>
                  <CircleOutlinedIcon fontSize="small" />{' '}
                </ListItemIcon>
                <ListItemText> Un Hide</ListItemText>
                <Typography variant="body2" color="text.secondary"></Typography>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' hideforgroup'}
                onMouseDown={this.flipHorizontal}
              >
                <ListItemIcon>
                  <FlipOutlinedIcon fontSize="small" />{' '}
                </ListItemIcon>
                <ListItemText> Flip Horizontal</ListItemText>
                <Typography variant="body2" color="text.secondary"></Typography>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' delete multiMode'}
                onMouseDown={this.deleteAsset}
              >
                <ListItemIcon>
                  <DeleteOutlineOutlinedIcon fontSize="small" />{' '}
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  delete
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' report hideforgroup'}
                onMouseDown={this.reportAndIssue}
              >
                <ListItemIcon>
                  <BugReportOutlinedIcon fontSize="small" />{' '}
                </ListItemIcon>
                <ListItemText> Report an Issue</ListItemText>
              </MenuItem>
              <MenuItem
                className={classes.toolMenuItem + ' snap multiMode'}
                onMouseDown={this.snapAsset}
              >
                <ListItemIcon>
                  <img
                    alt="snap"
                    className={classes.snapImg}
                    src={snap_context}
                  />{' '}
                </ListItemIcon>
                <ListItemText>Snap</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  delete
                </Typography>
              </MenuItem>
            </MenuList>
          )}
        </div>
        {showControlPanel && (
          <Box
            className={classes.controlsContainer}
            style={{
              right: controlPanelRight,
              display: isControlsPanelOpen ? 'block' : 'none',
            }}
          >
            <ControlsPanel
              viewMode={viewMode}
              toggleView={toggleView}
              showViewButtons={!isRenderMode && !editTemplateMode}
              handleCameraControls={this.handleCameraControls}
              toggleControlsPanel={this.toggleControlsPanel}
            />
          </Box>
        )}
        {tilingmode && (
          <Box
            className={classes.controlsContainer}
            style={{
              right: controlPanelRight + (isControlsPanelOpen ? 200 : 50),
              display: 'block',
            }}
          >
            <TilingControls
              viewMode={viewMode}
              toggleView={this.enableTiling}
              showViewButtons={!isRenderMode && !editTemplateMode}
              handleTileControls={this.handleTileControls}
            />
          </Box>
        )}

        {showControlPanel && !isControlsPanelOpen && (
          <div
            className={
              isRenderMode && isPreviewPage
                ? classes.hideControlPanel
                : isRenderMode
                ? classNames(classes.expandIcon, classes.renderScreen)
                : isRightCartOpen
                ? classNames(classes.expandIcon, classes.rightCartOpenStyle)
                : classes.expandIcon
            }
            style={{
              right: isRenderMode ? controlPanelRight : 30,
            }}
          >
            <CustomIconButton onClick={this.toggleControlsPanel}>
              <i className="fa fa-arrows-alt" aria-hidden="true" />
            </CustomIconButton>
          </div>
        )}
        {/* {!isRenderMode && !editTemplateMode && (
          // <ModeSelectionButtons viewMode={viewMode} toggleView={toggleView} />
        )} */}
        {!isRenderMode && (
          <CustomIconButton
            label="Toggle measurement mode"
            onClick={this.toggleMeasureMentMode}
            className={classes.measureIcon}
          >
            <i className="fas fa-ruler" />
          </CustomIconButton>
        )}
        <EventTracker />
      </>
    )
  }

  componentDidMount() {
    const {
      multiAssetSelection,
      updateCoords,
      updateCameraFocalLength,
      updateCameraPos,
      updateCameraTarget,
      updateZoomFactor,
      update3DAxis,
      sceneId,
      sceneMode,
    } = this.props

    if (Scene !== null) {
      Scene.initializeRenderer(this.element.current, sceneId)

      //userCanWrite = canUserWrite(lanId, initiator, members)
      Scene.transformControls.addEventListener('change', (e) => {
        if (Scene.selectedObject) {
          if (Scene.sceneEventTracker && Scene.sceneEventTracker.length > 50) {
            //Report user activity when actions count has crossed 50
            let auditUrl = config.audit.saveAuditReport
            let payload = {
              experience_id: sceneId,
              template_id: this.props.templateId,
              event_list: Scene.sceneEventTracker,
            }
            axios
              .post(auditUrl, payload)
              .then((response) => {
                if (response.status === 201) {
                  Scene.sceneEventTracker = []
                }
              })
              .catch((e) => {
                //console.log('Tracking failed', e)
              })
          }
          if (Scene.transformControls.getMode() === 'translate') {
            let { x, y, z } = Scene.selectedObject.position
            updateCoords(x, y, z, false, undefined, 'translate')
          } else {
            let { _x: x, _y: y, _z: z } = Scene.selectedObject.rotation
            updateCoords(
              radToAng(x),
              radToAng(y),
              radToAng(z),
              false,
              undefined,
              'rotate'
            )
          }
          update3DAxis(Scene.transformControls.space)
          Scene.updateSnappingHelpers()
        }
        if (Scene.topView) {
          Scene.setupControlsForTopView()
        }
      })

      Scene.transformControls.addEventListener('detached', (e) => {
        updateCoords(0, 0, 0, false, true)
        Scene.removeSnappingHelpers()
      })
      Scene.controls.addEventListener('change', () => {
        // Scene.isDirty = true
        Scene.sceneRender()
        updateCameraPos(getCameraPositions(), false)
        updateCameraTarget(getCameraTarget(), false)
        updateCameraFocalLength(getCameraFocalLength(), false)
      })
      Scene.controls.addEventListener('zoom', () => {
        Scene.isDirty = true
        Scene.sceneRender()
        updateZoomFactor(getZoomFactor(), false)
      })
      Scene.setEditType(sceneMode)
    }
    this.element.current.addEventListener(
      'mousedown',
      (e) => {
        //const { updateScale = () => {}, activeOperations = {} } = this.props
        e.preventDefault()
        e.stopPropagation()
        this.deactivateScaleMode(e)
        this.deactivateMaterialEdit(e)
        this.deactivateReplaceMode(e)
        if (userCanWrite && !Scene.measuremode) {
          Scene.showContextMenu = false
          if (e.button === 2) {
            if (Scene.grpArray.length > 1) {
              //console.log("group menu")
            } else {
              selectAsset(e).then((assets) => {
                if (!isEmpty(assets) && assets[0] === 'Group') {
                  multiAssetSelection({ tcins: [] })
                } else {
                  multiAssetSelection({ tcins: assets })
                }
                this.enableTiling(undefined, false)
              })
            }

            Scene.showContextMenu = true
            Scene.updateControls()
          } else {
            selectAsset(e).then((assets) => {
              if (!isEmpty(assets) && assets[0] === 'Group') {
                multiAssetSelection({ tcins: [] })
              } else {
                multiAssetSelection({ tcins: assets })
              }

              this.disableTiling(assets)
            })
          }
        }
      },
      false
    )

    // Scene.controls.addEventListener('end', () => {
    //   // updateCameraPos(getCameraPositions())
    //   // updateCameraTarget(getCameraTarget())
    // })
  }

  componentDidUpdate({
    renderData: { locked: pLocked, selectedCamera: pSelectedCamera },
    sceneMode: pSceneMode,
  }) {
    const {
      renderData: { locked },
      selectedCamera,
      isRenderMode,
      sceneMode,
    } = this.props

    if (
      isRenderMode &&
      (pLocked !== locked || pSelectedCamera !== selectedCamera)
    ) {
      if (Scene && locked && selectedCamera !== 'default') {
        Scene.controls.enabled = false
        Scene.setEditable(false)
      } else if (userCanWrite) {
        Scene.controls.enabled = true
        Scene.setEditable(userCanWrite)
      }
    } else {
      if (!Scene.measuremode) {
        Scene.controls.enabled = true
      }
      Scene.setEditable(userCanWrite)
    }
    if (sceneMode !== pSceneMode) {
      Scene.setEditType(sceneMode)
    }
  }
  componentWillUnmount() {
    if (Scene !== null) {
      Scene.clearScene()
      this.deactivateMaterialEdit()
    }
  }

  preventDefault = (e) => {
    e.preventDefault()
  }

  enableRotation = (e) => {
    e.preventDefault()
    if (Scene.transformControls) {
      Scene.transformControls.setMode('rotate')
      hideContextMenu()
    }
  }

  enableTranslate = (e) => {
    e.preventDefault()
    if (Scene.transformControls) {
      Scene.transformControls.setMode('translate')
      hideContextMenu()
    }
  }

  focusAsset = () => {
    Scene.focusAsset(Scene.selectedObject)
    hideContextMenu()
  }
  duplicateAsset = () => {
    Scene.duplicateAsset(Scene.selectedObject)
    hideContextMenu()
  }
  deleteAsset = () => {
    if (Scene.grpArray.length) {
      Scene.grpArray.forEach((i) => {
        Scene.deleteAsset(Scene.scene.getObjectByName(i))
      })
    } else {
      Scene.deleteAsset(Scene.selectedObject)
    }

    hideContextMenu()
  }
  hideAsset = () => {
    Scene.setAssetVisibility([...Scene.grpArray], false)
    hideContextMenu()
    Scene.grpArray = []
    Scene.outlineObjects = []
    Scene.highlightItems()
    Scene.animate()
  }

  unHideAsset = () => {
    Scene.setAssetVisibility([...Scene.grpArray], true)
    hideContextMenu()
    Scene.grpArray = []
    Scene.outlineObjects = []
    Scene.highlightItems()
    Scene.animate()
  }
  flipHorizontal = () => {
    Scene.flipHorizontal(Scene.selectedObject)
    hideContextMenu()
  }
  /**
   * Function called when clicked on the replace/exchange icon from object control
   */
  replaceAsset = (e) => {
    e.preventDefault() // Event prevention is required to make sure explorer does not close on click handler
    e.stopPropagation()
    Scene.swapmode = true
    this.props.setActiveOperation(REPLACE_ASSET, { status: true }) // Set Replace Mode active
    this.props.handleOpenExplorer() // Set Replace Mode active
    Scene.animate()
  }

  showSimilarAssets = (e) => {
    e.preventDefault() // Event prevention is required to make sure explorer does not close on click handler
    e.stopPropagation()
    let search_similar_asset = {
      tcin: Scene.selectedObject.assetId,
      asset_type: Scene.selectedObject.assetType,
    }
    createCustomEvent(TRACK_SCENE_EVENT, {
      type: 'SimilarSearch',
      assetId: Scene.selectedObject.assetId,
      e: 'Action from scence editor',
    })
    this.props.handleOpenExplorer() // Set Replace Mode active
    setTimeout(() => {
      this.props.showSimilarSearchResults(true, search_similar_asset)
    }, 50)

    hideContextMenu()
  }
  deactivateReplaceMode = (e) => {
    e.preventDefault() // Event prevention is required to make sure explorer does not close on click handler
    if (Scene.swapmode) {
      Scene.swapmode = false
      this.props.setActiveOperation(REPLACE_ASSET, { status: false }) // Set Replace Mode active
      this.props.handleCloseExplorer()
      hideContextMenu()
    }
  }

  scaleAsset = (e) => {
    try {
      const eventData = {
        event: {
          type: 'asset-scaling',
          name: `scaling, id: ${Scene.selectedObject.assetId} Name: ${Scene.selectedObject.name}`,
        },
      }
      this.props.trackEvent(eventData)
      // this.props.trackCustomEvent('test', 'test1', 'test2', 'test3')
    } catch (e) {
      console.error('Error in logging events', e)
    }

    this.props.setActiveOperation(SCALE_ASSET, {
      value: { ...Scene.selectedObject.scale },
      status: true,
    }) // Set Replace Mode active
    Scene.scaling = true
    Scene.assetScaleOnSelection = { ...Scene.selectedObject.scale }
    // updateScale({ ...Scene.selectedObject.scale }, false)
    Scene.animate()
  }
  deactivateScaleMode = (e) => {
    if (Scene.scaling) {
      this.props.setActiveOperation(SCALE_ASSET, {
        status: false,
        value: { x: 1, y: 1, z: 1 },
      }) // Reset Replace Mode to false
      const previousState = {
        scale: Scene.assetScaleOnSelection
          ? { ...Scene.assetScaleOnSelection }
          : { ...Scene.selectedObject.scale },
      }
      const currentState = {
        scale: { ...Scene.selectedObject.scale },
      }
      if (!compareObjectEquality(previousState, currentState)) {
        Scene.addToStack('SCALE_ASSET', {
          assetName: Scene.selectedObject.name,
          prevState: previousState,
          currState: currentState,
        })
      }
      Scene.processSceneEventData({
        event_type: 'SCALE_ASSET',
        event_data: {
          previous_state: {
            asset_id: Scene.selectedObject.assetId,
            name: Scene.selectedObject.name,
            scale: previousState.scale,
          },
          current_state: {
            asset_id: Scene.selectedObject.assetId,
            name: Scene.selectedObject.name,
            scale: currentState.scale,
          },
        },
      })

      Scene.scaling = false
    }

    hideContextMenu()
  }

  lockAsset = (e) => {
    Scene.showContextMenu = false
    Scene.updateControls()
    if (Scene.grpArray.length > 1) {
      Scene.grpArray.forEach((i) => {
        //Scene.deleteAsset()
        let sceneObject = Scene.scene.getObjectByName(i)
        Scene.lockAsset(sceneObject, !sceneObject.locked)
      })
    } else {
      Scene.lockAsset(Scene.selectedObject, !Scene.selectedObject.locked)
    }
  }
  unGroup = (e) => {
    Scene.showContextMenu = false
    Scene.updateControls()
    Scene.groupDiscard()
  }
  groupAssets = (e) => {
    if (Scene.grpArray.length) {
      Scene.initGroup(Scene.grpArray)
    }
    Scene.showContextMenu = false
    Scene.updateControls()
  }

  snapAsset = (e) => {
    Scene.snapOnAsset()
    Scene.showContextMenu = false
    Scene.updateControls()
  }
  handleCameraControls = (e, value, mousedown = false) => {
    const interval = value === ZOOM_OUT || value === ZOOM_IN ? 50 : 25
    if (mousedown) {
      this.cameraControlsAction(value)
      this.intervalId = setInterval(() => {
        this.cameraControlsAction(value)
      }, interval)
    } else {
      clearInterval(this.intervalId)
    }
  }

  cameraControlsAction(value) {
    const { updateZoomFactor } = this.props
    Scene.updateCameraMode(value)
    if (value === ZOOM_OUT || value === ZOOM_IN) {
      updateZoomFactor(getZoomFactor(), false)
    }
  }

  toggleMeasureMentMode(e) {
    const icon = e.currentTarget.querySelector('i')
    if (Scene.measuremode) {
      Scene.disableMeasurement()
      icon.style.color = 'rgba(0,0,0,0.4)'
    } else {
      Scene.enableMeasurement()
      icon.style.color = '#CC0000'
    }
  }
  openMaterialEditor = (e) => {
    Scene.editMaterial = true
    this.props.setActiveOperation(EDIT_MATERIAL, {
      value: {
        name: Scene.selectedObject.name,
        id: Scene.selectedObject.assetId,
      },

      status: true,
    })
    Scene.showContextMenu = false
    Scene.animate()
  }

  deactivateMaterialEdit = (e) => {
    if (Scene.editMaterial) {
      this.props.setActiveOperation(EDIT_MATERIAL, {
        status: false,
      })
    }
  }
  enableTiling = (e, value = true) => {
    Scene.setTilingMode(value)

    this.setState({
      tilingmode: value,
      tilingObject: Scene.selectedObject?.name,
    })
    if (value) {
      hideContextMenu()
    }
  }
  handleTileControls = (e, direction) => {
    this.preventDefault(e)
    if (Scene.selectedObject) {
      Scene.tileAsset(
        Scene.selectedObject,
        direction,
        (response) => {
          const { status, intersects, assetName, collided } = response
          if (status !== 'error') {
            this.setState({ tilingObject: assetName })
          }
          if (collided) {
            let message = `${Scene.selectedObject.name} may overlap with another asset when tiled to the ${direction}`
            if (status == 'error') {
              message = `${Scene.selectedObject.name} CANNOT tile to the ${direction} as there already a duplicate of the same asset present`
            }
            this.props.showNotification(true, message, status)
          }
        },
        ({ assetName }) => {
          this.setState({ tilingObject: assetName })
        }
      )
    }
  }
  disableTiling(assets) {
    let tilingmode = false
    const { tilingObject } = this.state

    if (assets.length == 1 && tilingObject == assets[0]) {
      tilingmode = true
    }
    if (!tilingmode) {
      this.enableTiling(undefined, tilingmode)
    }
  }
}

const fnMove = (e) => {
  e.stopPropagation()
  Scene.setTargetPosition(e, true)
}
const fnEnd = (e) => {
  Scene.droppedPosition[Scene.currentTarget.uid] = {
    ...Scene.setTargetPosition(e),
  }
  Scene.currentTarget = {}
  Scene.currentTarget = null
  Scene.domElement.addEventListener(
    'mousemove',
    Scene.onDocumentMouseMove,
    false
  )
  document.removeEventListener('mousemove', fnMove, false)
  document.removeEventListener('mouseup', fnEnd, false)
}

export const addElementOnDragOver = (e, assetData, onError = () => {}) => {
  if (userCanWrite) {
    Scene.domElement.removeEventListener(
      'mousemove',
      Scene.onDocumentMouseMove,
      false
    )
    const distance = Scene.camera.position.distanceTo(
      Scene.targetplane.position
    )
    let scaleFactor = distance / Scene.camera.zoom / 90
    scaleFactor = scaleFactor < 35 ? scaleFactor : 35
    Scene.placeHolder.visible = true
    Scene.currentTarget = Scene.placeHolder
    Scene.placeHolder.scale.x = scaleFactor
    Scene.placeHolder.scale.y = scaleFactor
    Scene.placeHolder.scale.z = scaleFactor
    const uid = new Date().valueOf()
    Scene.currentTarget.uid = uid
    Scene.setTargetPosition(e, true)
    if (assetData.isGroup) {
      Scene.loadGroupAssets(assetData.sceneData, uid).then(
        (res) => {
          let data = assetData.sceneData.assets.map((item) => ({
            asset_type: item.assetType,
            value: item.name,
            sub_asset_type: item.subAssetType,
          }))
          let payload = {
            experience_id: assetData.sceneId,
            assets: data,
          }
          assetData.onAddSuccess(payload)
          triggerAssetAddedEvent()
        },
        (e) => {
          onError(e)
        }
      )
    } else {
      addAssets({
        url: assetData.url,
        tcin: assetData.name,
        isDragged: true,
        uid,
        assetType: assetData.assetType,
        addToStackFlag: true,
        subAssetType: assetData.subAssetType,
        position: assetData.position,
        rotation: assetData.rotation,
        assetId: assetData.assetId,
        generateSignedUrl: false,
      }).then(
        (res) => {
          let payload = {
            experience_id: assetData.sceneId,
            assets: [
              {
                asset_type: assetData.assetType,
                value: assetData.name,
                sub_asset_type: assetData.subAssetType,
              },
            ],
          }
          assetData.onAddSuccess(payload)
          triggerAssetAddedEvent()
        },
        (e) => {
          onError(e)
        }
      )
    }
  }
  document.addEventListener('mousemove', fnMove, false)
  document.addEventListener('mouseup', fnEnd, false)
}

export const addTemplate = (url, id, replaceTemplate = false) => {
  if (Scene !== null) {
    return new Promise((resolve, reject) => {
      Scene.addTemplate(url, id, replaceTemplate)
        .then(() => {
          resolve()
          //stop showing the loader.
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}

export const selectAsset = (event) => {
  if (Scene !== null) {
    return new Promise((resolve, reject) => {
      try {
        const assets = Scene.selectAssetForGroup(event)
        if (assets) {
          resolve(assets)
        }
      } catch (err) {
        reject(err)
      }
    })
  }
}

export const addAssets = (params) => {
  if (Scene !== null) {
    return new Promise((resolve, reject) => {
      Scene.addAssets(params)
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}

/** unused method getSceneFile  */
export const loadScene = (
  data,
  applyWorldRotationOnAssets = false,
  isTemplateEdited = false
) => {
  const loadscenepromise = new Promise((resolve, reject) => {
    Scene.loadSceneFromJson(data, applyWorldRotationOnAssets, isTemplateEdited)
      .then(() => {
        resolve()
      })
      .catch((e) => {
        reject({ error: e, type: 'assetError' })
      })
  })
  return loadscenepromise
}

export const reloadFailedData = (isTemplateScene) => {
  const loadscenepromise = new Promise((resolve, reject) => {
    Scene.reloadFailedData(isTemplateScene)
      .then(() => {
        resolve()
      })
      .catch((e) => {
        reject({ error: e, type: 'assetError' })
      })
  })
  return loadscenepromise
}

export const importScene = (url, isTemplateScene = false) => {
  if (Scene !== null) {
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(({ data }) => {
          if (data) {
            if (
              data.low_poly_template_available !== undefined &&
              !data.low_poly_template_available
            ) {
              data.scene_data = {}
            }
            data.isTemplateScene = isTemplateScene
            loadScene(data)
              .then(() => {
                resolve(data)
              })
              .catch((e) => {
                console.log(e)
                reject({ error: e, type: 'assetError' })
              })
          } else {
            reject({ error: undefined, type: 'dataError' })
          }
        })
        .catch((e) => {
          reject({ error: e, type: 'apiError' })
        })
    })
  }
}

export const getSnapshot = () => {
  if (Scene !== null) {
    return new Promise((resolve, reject) => {
      Scene.getSnapShot()
        .then((res) => {
          resolve(res)
        })
        .catch(() => {
          reject()
        })
    })
  }
}

export const isSceneDirty = () => {
  if (Scene !== null) {
    return Scene.isSceneDirty()
  }
}

export const saveSceneAsFile = ({
  sceneId = '',
  capture = false,
  selectedCamera,
  cameraSnapshotClbk,
}) =>
  new Promise((resolve, reject) => {
    let url = !capture
      ? `${config.experience.uploadSnapShot}${sceneId}`
      : `${config.experience.saveScene}/${sceneId}/camera_thumbnail/${selectedCamera}`
    if (sceneId) {
      getSnapshot()
        .then((res) => {
          const formData = new window.FormData()
          formData.append(
            'file',
            new File([res], `${sceneId}${new Date().valueOf()}.jpg`, {
              type: 'image/jpeg',
            })
          )
          formData.append('file_size', res.size)
          return axios
            .put(url, formData)
            .then((response) => {
              if (capture) {
                cameraSnapshotClbk(selectedCamera, response.data)
              }
            })
            .catch((error) => {
              // console.log(error)
            })
        })
        .catch(() => {
          reject('Error in Fetching Screenshot file')
        })
    } else {
      reject('Scene is needed or scene is not dirty')
    }
  })

export const saveScene = ({
  sceneId = 'new',
  renderData,
  defaultView,
  isRenderPage,
  selectedCamera,
  previousSceneData,
  preferredRenderDetails,
  onBeforeSave: beforeSave = () => {},
  autosave,
  templateId,
  capture,
  cameraSnapshotClbk = () => {},
}) => {
  let url = config.experience.saveScene
  return new Promise((resolve, reject) => {
    if (userCanWrite) {
      sceneId =
        sceneId === 'new'
          ? window.location.href.split('experience/')[1]
          : sceneId

      const isSceneDirty = Scene.isSceneDirty()
      // if (isSceneDirty) {
      //   saveJsonScene(url, sceneId, resolve, reject, renderData)
      // }
      saveJsonScene({
        url,
        sceneId,
        resolve,
        reject,
        renderData,
        defaultView,
        isRenderPage,
        selectedCamera,
        previousSceneData,
        preferredRenderDetails,
        beforeSave,
        autosave,
        templateId,
      })
      if (isSceneDirty || capture) {
        saveSceneAsFile({
          sceneId,
          capture,
          selectedCamera,
          cameraSnapshotClbk,
        })
      }

      // if (!isSceneDirty) {
      //   reject('Scene has no changes')
      // }
    } else {
      reject('user dont have permission to save the scene')
    }
  })
}

export const saveJsonScene = ({
  url,
  sceneId,
  resolve,
  reject,
  renderData,
  defaultView,
  isRenderPage,
  selectedCamera,
  previousSceneData,
  preferredRenderDetails,
  beforeSave = () => {},
  autosave,
  templateId,
}) => {
  const {
    passes,
    noise,
    width,
    height,
    clipNear,
    clipFar,
    maxWidth,
    maxHeight,
    x = 0,
    y = 0,
    resizeRatio,
    resHeight,
    resWidth,
    locked,
    time,
  } = renderData

  const preparedRenderData = {
    pass_limit: passes,
    noise_level: noise,
    crop_x: x,
    crop_y: y,
    image_height: height,
    image_width: width,
    scene_width: resWidth,
    scene_height: resHeight,
    resize_ratio: resizeRatio,
    screen_height: window.innerHeight - RENDER_HEADER_HEIGHT,
    screen_width: window.innerWidth,
    locked: locked && (selectedCamera !== 'default' || selectedCamera !== ''),
    time_limit_minutes: time,
  }

  if (sceneId) {
    const data = Scene.exportSceneAsJson(true) //always save camera position.
    data.experience_id = sceneId

    data.scene_data.camera.clipNear = parseInt(clipNear)
    data.scene_data.camera.clipFar = parseInt(clipFar)
    data.scene_data.camera.renderWidth = parseInt(width)
    data.scene_data.camera.renderHeight = parseInt(height)

    const currentSceneCamera = { ...data.scene_data.camera }
    data.preferred_render_details = { ...preferredRenderDetails }

    if (isRenderPage) {
      data.scene_data.preferred_cameras =
        cloneDeep(previousSceneData.preferred_cameras) || {}
      data.scene_data.render_camera = { ...previousSceneData.render_camera }

      if (isEmpty(selectedCamera) || selectedCamera === 'default') {
        data.scene_data.render_camera = currentSceneCamera
        data.preferred_render_details['default'] = preparedRenderData
      } else if (previousSceneData.saveCustomCamera) {
        data.scene_data.preferred_cameras[selectedCamera] = currentSceneCamera
        data.preferred_render_details[selectedCamera] = preparedRenderData
      }
      data.scene_data.camera = { ...previousSceneData.camera }
    } else {
      data.scene_data.render_camera = previousSceneData.render_camera
      data.scene_data.preferred_cameras = cloneDeep(
        previousSceneData.preferred_cameras
      )
    }
    data.scene_data.selected_camera = selectedCamera

    data.default_view = defaultView

    data.render_details = {}

    data.auto_save = false

    beforeSave(
      { ...cloneDeep(data.scene_data), saveCustomCamera: false },
      data.preferred_render_details
    )

    axios
      .post(url, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {})
    if (autosave && Scene.sceneEventTracker.length) {
      let auditUrl = config.audit.saveAuditReport
      let payload = {
        experience_id: sceneId,
        template_id: templateId,
        event_list: Scene.sceneEventTracker,
      }
      axios
        .post(auditUrl, payload)
        .then((response) => {
          if (response.status === 201) {
            Scene.sceneEventTracker = []
          }
        })
        .catch((e) => {
          // console.log('Tracking failed', e)
        })
    }
  } else {
    reject('scene id missing or scene is not dirty')
  }
}

export const SaveAsNewScene = (
  experienceData,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  const data = Scene.exportSceneAsJson(true) //always save camera position.

  experienceData.scene_data = data.scene_data
  return axios
    .post(config.experience.copyScene, experienceData)
    .then((response) => {
      successCallback(response.data)
    })
    .catch((error) => {
      errorCallback(error)
    })
}

export const saveTemplate = () => {
  const isSceneDirty = Scene.isSceneDirty()
  let url = config.template.saveTemplate
  const data = Scene.exportSceneAsJson()

  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((response) => {
        // Scene.isDirty = false
        resolve(response)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}

export const getSceneDataForRender = () => Scene.exportSceneAsJson(true)
export const getZoomValue = () => Scene.getZoomValue()
export const updateAssetCoords = (x = 0, y = 0, z = 0) => {
  if (Scene.selectedObject) {
    const previousState = {
      position: { ...Scene.selectedObject.position },
      rotation: { ...Scene.selectedObject.rotation },
      scale: { ...Scene.selectedObject.scale },
    }
    if (Scene.transformControls.getMode() === 'translate') {
      Scene.selectedObject.position.set(x, y, z)
    } else {
      Scene.selectedObject.rotation.set(angToRad(x), angToRad(y), angToRad(z))
    }
    const currentState = {
      position: { ...Scene.selectedObject.position },
      rotation: { ...Scene.selectedObject.rotation },
      scale: { ...Scene.selectedObject.scale },
    }
    Scene.addToStack('MOVE_ASSET', {
      assetName: Scene.selectedObject.name,
      prevState: previousState,
      currState: currentState,
    })
    Scene.processSceneEventData({
      event_type: 'MOVE_ASSET',
      event_data: {
        previous_state: {
          asset_id: Scene.selectedObject.assetId,
          name: Scene.selectedObject.name,
          position: { ...previousState.position },
          rotation: {
            _x: radToAng(previousState.rotation._x),
            _y: radToAng(previousState.rotation._y),
            _z: radToAng(previousState.rotation._z),
          },
          scale: { ...previousState.scale },
        },
        current_state: {
          asset_id: Scene.selectedObject.assetId,
          name: Scene.selectedObject.name,
          position: { ...currentState.position },
          rotation: {
            _x: radToAng(currentState.rotation._x),
            _y: radToAng(currentState.rotation._y),
            _z: radToAng(currentState.rotation._z),
          },
          scale: { ...currentState.scale },
        },
      },
    })
  }
  Scene.animate()
}

export const updateCameraPosInScene = ({ x, y, z }, updateControls = true) => {
  Scene.camera.position.set(x, y, z)
  Scene.camera.updateProjectionMatrix()
  if (updateControls) {
    Scene.controls.update()
  }
  Scene.animate()
}

export const updateZoomFactorInScene = (factor) => {
  Scene.camera.zoom = factor
  Scene.camera.updateProjectionMatrix()
  Scene.controls.update()
  Scene.animate()
}

export const updateAssetScale = (x = 1, y = 1, z = 1, addToStack = false) => {
  if (Scene.selectedObject) {
    Scene.selectedObject.scale.set(x, y, z)
    if (addToStack) {
      const previousState = {
        scale: Scene.assetScaleOnSelection
          ? { ...Scene.assetScaleOnSelection }
          : { x, y, z },
      }
      const currentState = {
        scale: { ...Scene.selectedObject.scale },
      }
      Scene.addToStack('SCALE_ASSET', {
        assetName: Scene.selectedObject.name,
        prevState: previousState,
        currState: currentState,
      })
      Scene.processSceneEventData({
        event_type: 'SCALE_ASSET',
        event_data: {
          previous_state: {
            asset_id: Scene.selectedObject.assetId,
            name: Scene.selectedObject.name,
            scale: { ...previousState.scale },
          },
          current_state: {
            asset_id: Scene.selectedObject.assetId,
            name: Scene.selectedObject.name,
            scale: { ...currentState.scale },
          },
        },
      })
    }
  }
  Scene.animate()
}

export const removeMultipleAssets = (tcins) => {
  tcins.map((tcin) => Scene.removeAssetByTcin(tcin))
  triggerAssetAddedEvent()
}

const triggerAssetAddedEvent = () => {
  let event = new CustomEvent('addedAsset')
  document.dispatchEvent(event)
}

export const hideContextMenu = () => {
  Scene.showContextMenu = false
  Scene.updateControls()
}

export const getSceneTranformationMode = () =>
  Scene.transformControls && Scene.transformControls.getMode()

export const performActionOnScene = (
  { sceneAction = '', cameraPosition = {}, cameraTarget = {} },
  assets = [],
  callback = () => {},
  groupObj
) => {
  let firstAsset = assets[0] || null
  switch (sceneAction) {
    case SCENE_SELECT:
      Scene.transformControls.detach()
      Scene.outlineObjects = assets
      Scene.grpArray = assets
      if (assets.length === 1) {
        Scene.selectAsset(firstAsset)
      } else {
        Scene.triggerSceneChangeEvent()
      }

      //Scene.assetSelectFlag = false

      Scene.animate()
      break
    case SCENE_MOVE:
      if (Scene.transformControls) {
        Scene.transformControls.setMode('translate')
        hideContextMenu()
      }
      break
    case SCENE_ROTATE:
      if (Scene.transformControls) {
        Scene.transformControls.setMode('rotate')
      }
      break
    case SCENE_DELETE:
      if (assets.length > 1) break
      if (Scene.selectedObject) {
        Scene.deleteAsset(Scene.selectedObject)
        hideContextMenu()
        triggerAssetAddedEvent()
      }
      break
    case REPLACE_ASSET:
      if (Scene.selectedObject && assets.length) {
        // Get the position of the currently selected object
        const assetData = assets[1]

        Scene.replaceAsset(assetData)
      }
      break
    case SCALE_ASSET:
      if (Scene.selectedObject && assets.length) {
        // Get the scale object passed
        const scaleObj = assets[0] || {}
        const { scale } = scaleObj
        Scene.scaleAsset(scale)
      }
      break
    case SCENE_ESCAPE:
      Scene.transformControls.detach()
      Scene.showContextMenu = false
      Scene.updateControls()
      // Scene.assetSelectFlag = true
      Scene.outlineObjects = []
      Scene.grpArray = []
      Scene.animate()
      break
    case SCENE_GROUP:
      return new Promise((resolve, reject) => {
        let result = Scene.initGroup(assets)
        if (result.status === true) {
          resolve(result)
        } else {
          reject('error in grouping assets')
        }
      })

    case SCENE_UPDATE_GROUP:
      assets.forEach((assetName) => {
        Scene.removeFromGroup(Scene.scene.getObjectByName(assetName), true)
      })
      break
    case SCENE_UNGROUP:
      //  Scene.groupSelectFlag = false
      //  Scene.assetSelectFlag = true
      //  Scene.outlinePass.selectedObjects = []
      Scene.groupDiscard()
      //  Scene.animate()
      break
    case SCENE_DUPLICATE:
      if (Scene.selectedObject) {
        const assetData = Scene.duplicateAsset(Scene.selectedObject)
        callback(assetData)
        triggerAssetAddedEvent()
        hideContextMenu()
      }
      break
    case SCENE_HIDE:
      Scene.setAssetVisibility(
        assets.length ? assets : [Scene.selectedObject.name],
        false
      )
      hideContextMenu()
      break
    case SCENE_SHOW:
      Scene.setAssetVisibility(assets, true)
      break
    case SCENE_SHOW_ALL:
      Scene.showAllAssets()
      hideContextMenu()
      break
    case SCENE_VIEW_3D:
      if (Object.keys(cameraPosition).length) {
        Scene.changeCameraPosition(cameraPosition, undefined, cameraTarget)
      }
      break
    case SCENE_VIEW_2D:
      Scene.changeCameraPosition({ x: 0, y: 600, z: 0 }, true)
      break
    case SCENE_UNDO:
      Scene.undoCmd()
      break
    case SCENE_REDO:
      Scene.redoCmd()
      break
    case FOCUS_ASSET:
      Scene.focusAsset(Scene.selectedObject)
      break
    case LOCK_ASSET:
      Scene.lockAsset(Scene.selectedObject, !Scene.selectedObject.locked)
      hideContextMenu()
      break
    case SCENE_GROUP_HIDE_SHOW:
      if (firstAsset) {
        Scene.setAssetVisibility(
          assets,
          !Scene.scene.getObjectByName(firstAsset).visible
        )
      }
      break
    case GROUP_RENAME:
      Scene.renameGroup(groupObj)
      break
    case FLIP_HORIZONTAL:
      Scene.flipHorizontal(Scene.selectedObject)
      break
    case SCENE_GROUP_ADD_ASSETS:
      Scene.addAssetToGroup(groupObj)
      break
    default:
      break
  }
}

export const getCameraPositions = () => Object.assign({}, Scene.camera.position)

export const getCameraTarget = () =>
  Object.assign({}, Scene.controls.getTarget())

export const getCameraAngles = () => Scene.camera.rotation

export const setSceneCanEdit = (editable = false) => {
  Scene.setEditable(editable)
  userCanWrite = editable
}

export const setCameraClipNear = (near) => {
  let clipnearVal = parseFloat(near)
  Scene.camera.near = clipnearVal > 0 ? clipnearVal : 0.1
  Scene.camera.updateProjectionMatrix()
  Scene.controls.update()
  Scene.animate()
}

export const setCameraClipFar = (near) => {
  Scene.camera.far = parseFloat(near)
  Scene.controls.update()
  Scene.camera.updateProjectionMatrix()
  Scene.animate()
}

export const setCameraFocalLength = (value) => {
  Scene.camera.setFocalLength(value)
  Scene.camera.updateProjectionMatrix()
  Scene.controls.update()
  Scene.animate()
}

export const setZoomFactor = (factor) => {
  Scene.camera.zoom =
    (Scene.renderer.domElement.offsetHeight /
      Scene.renderer.domElement.offsetWidth) *
    factor

  Scene.camera.updateProjectionMatrix()
  Scene.controls.update()
  Scene.animate()
}

export const getCameraFocalLength = () =>
  ((Scene || {}).camera || { getFocalLength: () => {} }).getFocalLength()

export const getCameraClipNear = () => ((Scene || {}).camera || {}).near

export const getCameraClipFar = () => ((Scene || {}).camera || {}).far

export const getZoomFactor = () => {
  if (Scene !== null) {
    return (
      ((Scene || {}).camera || {}).zoom *
      (Scene.renderer.domElement.offsetWidth /
        Scene.renderer.domElement.offsetHeight)
    )
  } else {
    return 0.1
  }
}

export const changeTemplateWalls = (nonRenderMode, setClipNear) => {
  // Scene.domElement.style.width = width
  // Scene.domElement.style.height = height  //not used anymore

  hideContextMenu()
  if (nonRenderMode) {
    Scene.unlockTemplateWalls(setClipNear)
  } else {
    Scene.lockTemplateWalls()
  }
}

export const resizeScene = (width, height) => {
  Scene.resizeScene()
}

export const getUndoRedoButtonStatus = () => {
  if (Scene.measuremode) {
    return {
      activateUndo: false,
      activateRedo: false,
    }
  } else {
    return {
      activateUndo: Scene.activateUndo,
      activateRedo: Scene.activateRedo,
    }
  }
}

export const removeControls = () => {
  Scene.transformControls.detach()
  Scene.outlineObjects = []
  Scene.hoverElements = []
  Scene.highlightItems()
  Scene.animate()
}

export const applyCameraSettings = (settings, isRenderMode = true) => {
  Scene.applyCameraSettings(settings, isRenderMode)
}

export const changeLocalWorldSpace = (spaceVal) =>
  Scene.changeLocalWorldSpace(spaceVal)

export const resetTransControls = () => {
  Scene.topView = false
  Scene.controls.enableRotate = true
  Scene.transformControls.enableAxis(true, true, true)
  Scene.transformControls.setTopView(false)
}

export const switchColorTexture = (materialList = [], selectedTexture) => {
  return new Promise((resolve, reject) => {
    let asset = Scene.selectedObject

    if (!asset) {
      console.log('unable to select asset')
      reject('unable to select asset')
    }
    const { texture_name, texture_type, texture_url } = selectedTexture
    const targetMaterial = {
      material_name: texture_name,
      material_type: texture_type,
      swatch_thumbnail_url: texture_url,
    }
    Scene.switchColorTexture(asset, materialList, targetMaterial)
      .then(() => {
        // saveScene()
        resolve()
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const reloadAsset = () => {
  return new Promise((resolve, reject) => {
    let asset = Scene.selectedObject

    if (!asset) {
      console.log('unable to select asset')
      reject('unable to select asset')
    }

    Scene.reloadAsset(asset)
      .then(() => {
        resolve()
      })
      .catch((e) => {
        reject(e)
      })
  })
}

const mapStateToProps = (state) => ({
  controlPanelRight: state.scene.controls.right,
  renderData: state.scene.renderDetails,
  selectedCamera: state.scene.sceneData.selected_camera,
  editTemplateMode: state.templates.editTemplateMode,
  sceneMode: state.scene.mode,
})

export default connect(mapStateToProps, {
  multiAssetSelection,
  setActiveOperation,
  handleOpenExplorer,
  handleCloseExplorer,
  setRenderDetails,
  showNotification,
  toggleReportIssueModal,
  fetchSimilarAssets,
  showSimilarSearchResults,
  clearSimilarAssets,
})(withStyles(style)(withAnalytics()(DesignSpace)))
