import React from 'react'
import { withStyles } from '@mui/styles'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import {} from '@fortawesome/free-solid-svg-icons'

import TabContainer from '../tabContainer'
import Cart from '../../components/Cart/Cart'
import Board from '../Board/index'
import AssetScaleBox from '../../components/AssetScaleBox/AssetScaleBox'
import ACTIONS from '../../constants/actions'

import CapsuleIconBar from '../iconsCapsuleBar/'

const styles = (theme) => ({
  drawerRoot: {
    position: 'absolute',
    right: 90,
    top: 10,
    bottom: 50,
    background: 'white',
    width: 290,
    // borderLeft: '2px solid #ccc',
    zIndex: 100,
    overflowX: 'hidden',
  },
  showPannel: {
    top: 135,
    position: 'fixed',
    color: theme.palette.primary.mediumGrey,
    padding: '30px 8px',
    borderRight: '32px solid #ccc',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    zIndex: 11,
  },
  cartButton: {
    top: 223,
    position: 'fixed',
    color: theme.palette.primary.mediumGrey,
    padding: '30px 5px',
    borderRight: '32px solid #ccc',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    fontSize: '8px',
    zIndex: 10,
  },
  cartButtonActive: {
    top: 223,
    color: theme.palette.primary.light,
    position: 'fixed',
    padding: '30px 5px',
    borderRight: '32px solid #0d469f',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    fontSize: '8px',
    zIndex: 10,
  },

  cardActionCustom: {
    left: '11%',
  },
  iconStyle: {
    margin: '-5px -42px -7px 0px',
  },

  // for tabs
  tabsRoot: {
    height: 40,
    paddingLeft: 6,
    minHeight: 30,
    marginTop: 10,
  },
  tabsFlexContainer: {
    height: 37,
    width: 201, //Adding width to squeeze the spacing between the tabs as per UX advice
  },
  tabsIndicator: {
    height: 2,
  },
  marginInTab: {
    marginLeft: 110,
  },
  tabRoot: {
    minWidth: 60,
    fontWeight: 500,
    fontSize: 14,
    // opacity: 0.8,
  },
  tabImage: {
    marginRight: 6,
    marginTop: 6,
  },
  tabSelected: {},
  labelContainer: {
    padding: '6px 2px',
  },
  tabWrapper: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: '35px',
    },
  },
  fixed: {
    overflowY: 'hidden',
  },
  scaleWrapper: {
    position: 'fixed',
    bottom: 50,
    width: 400,
    height: 250,
    zIndex: 25,
  },
  actionIcon: { padding: 5 },
  capsule: {
    top: 135,
    position: 'fixed',
    backgroundColor: theme.palette.primary.light,
    padding: '12px 8px',
    zIndex: 11,
    borderRadius: 30,
    display: 'flex',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.15)',
    flexDirection: 'column',
  },
})
class DrawerRight extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cartVisible: false,
      activeCart: true,
      defaultTab: '',
      isTabShow: true,
    }
    this.myRef = React.createRef()
  }

  componentDidMount() {
    const {
      isRightDrawerOpen = false,

      setIsRightCartOpen = () => {},
    } = this.props
    this.setState({
      cartVisible: isRightDrawerOpen,
    })
    setIsRightCartOpen(isRightDrawerOpen)
  }

  togglePannel = (e, tab, isDrawer = false) => {
    const { setIsRightCartOpen } = this.props
    this.setState(
      {
        cartVisible: tab !== null,
        defaultTab: tab,
      },
      () => {
        setIsRightCartOpen(tab !== null)
      }
    )
  }

  handleTabShow = (isShow) => {
    this.setState({ isTabShow: isShow })
  }

  handleScrollToTop = () => {
    const node = this.myRef.current
    node.scrollTop = 0
  }
  render() {
    const {
      classes,
      handleSelectedTcins,
      handleCartActions,
      userHaveWriteAccess,
      activeOperations,
      updateScaleState = () => {},
      initialScale,
      templatePage,
    } = this.props
    const { cartVisible, defaultTab } = this.state

    const iconListForCapsuleBar = [
      {
        toolTip: 'View Styling Board',
        name: 'BOARD',
        placement: 'left',
        icon: <GridViewOutlinedIcon />,
      },
      {
        toolTip: 'View Scene Explorer',
        name: 'SCENE',
        placement: 'left',
        icon: <LayersOutlinedIcon />,
      },
    ]

    return (
      <>
        {userHaveWriteAccess && (
          <CapsuleIconBar
            icons={iconListForCapsuleBar}
            onChange={this.togglePannel}
            disabled={activeOperations[ACTIONS.EDIT_MATERIAL]}
          />
        )}
        <div
          style={{ display: cartVisible ? 'block' : 'none' }}
          className={classes.drawerRoot}
          ref={this.myRef}
        >
          <TabContainer>
            <>
              {defaultTab === 'BOARD' && (
                <Board
                  handleTabShow={this.handleTabShow}
                  handleScrollToTop={this.handleScrollToTop}
                  handleCartActions={handleCartActions}
                  templatePage={templatePage}
                />
              )}
              {defaultTab === 'SCENE' && (
                <Cart handleCartActions={handleCartActions} />
              )}
            </>
          </TabContainer>
        </div>

        {activeOperations[ACTIONS.SCALE_ASSET] && (
          <div style={{ right: 275 }} className={classes.scaleWrapper}>
            <AssetScaleBox
              initialScale={
                activeOperations['value']
                  ? activeOperations['value']
                  : initialScale
              }
              onChange={updateScaleState}
            />
          </div>
        )}
      </>
    )
  }
}

export default withStyles(styles)(DrawerRight)
