export default {
  MOVE_ASSET: 'MOVE_ASSET',
  DELETE_ASSET: 'ADD_ASSET',
  ADD_ASSET: 'DELETE_ASSET',
  HIDE_ASSET: 'SHOW_ASSET',
  SHOW_ASSET: 'HIDE_ASSET',
  GROUP_ASSETS: 'UNGROUP_ASSETS',
  UNGROUP_ASSETS: 'GROUP_ASSETS',
  DUPLICATE_ASSET: 'DELETE_ASSET',
  REPLACE_ASSET: 'REPLACE_ASSET',
  SCALE_ASSET: 'SCALE_ASSET',
  DUPLICATE_GROUP: 'DELETE_ASSET',
  LOCK_ASSET: 'LOCK_ASSET',
  SNAP_ASSET: 'SNAP_ASSET',
  FLIP_HORIZONTAL: 'FLIP_HORIZONTAL',
  EDIT_MATERIAL: 'EDIT_MATERIAL',
}
