const ErrorSummaryStyles = (theme) => ({
  /*** New Styles */
  headerTitle: {
    padding: '0 20px',
    marginBottom: 15,
  },
  headerContent: {
    padding: '0 20px 20px',
    fontSize: 13,
    '&>div': {
      marginBottom: 12,
    },
    '& label': {
      paddingRight: 5,
      display: 'inline-block',
      fontWeight: 500,
    },
  },
  mainContainer: {
    overflow: 'auto',
  },
  closeIcon: {
    float: 'right',
    cursor: 'pointer',
    fontSize: '2rem',
    marginRight: 20,
    marginTop: -35,
  },
  leftContainer: {
    borderRight: '1px solid #ddd',
    padding: 20,
  },
  rightContainer: {
    padding: 20,
  },
})

export default ErrorSummaryStyles
