import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import searchIcon from '../../images/assetIcons/default/search.svg?url'
import crossIcon from '../../images/assetIcons/default/cross.svg?url'

const styles = (theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: 10,
    lineHeight: '20px',
    width: 'Calc(100% - 52px)',
  },

  iconButton: {
    color: theme.palette.primary.secondaryText,
    fontSize: 14,
    margin: '11px 14px 11px 0',
    padding: '3px 0 3px 14px',
    borderRadius: 0,
    borderLeft: `1px solid ${theme.palette.primary.veryLightGray}`,
    '&:hover': {
      background: 'none',
    },
  },
  inputWrapper: {
    border: `1px solid ${theme.palette.primary.grayLight}`,
    height: 42,
    display: 'inline-block',
    marginRight: '23px',
    boxSizing: 'border-box',
    borderRadius: 3,
    width: '100%',
    background: theme.palette.primary.light,
  },
  focussed: {
    border: `1px solid ${theme.palette.primary.dark}`,
  },
})

class SearchInput extends React.Component {
  constructor(props) {
    super(props)
    const { searchVal = '' } = props
    this.state = {
      searchValue: searchVal,
      focussed: false,
    }
  }
  componentDidMount = () => {
    this.setState({ searchValue: this.props.searchVal })
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.searchVal !== this.props.searchVal) {
      this.setState({ searchValue: this.props.searchVal })
    }
  }

  handleChange = (event) => {
    this.setState({ searchValue: event.target.value }, () => {
      const { searchValue } = this.state
      this.props.onSearch(searchValue)
    })
  }
  handleButtonClick = (event) => {
    const { onSearchButtonClick = () => {} } = this.props
    event.preventDefault()
    this.setState({ searchValue: '' })
    onSearchButtonClick('')
  }
  handleOnFocus(status) {
    const { onFocus = () => {} } = this.props
    this.setState({ focussed: status })
    onFocus(status)
  }
  handleKeyDownSearch = (e) => {
    const { onEnter = () => {} } = this.props
    const { searchValue } = this.state
    if (e.key === 'Enter') {
      onEnter(searchValue)
    }
  }

  render() {
    const {
      classes,
      placeholder = '',
      width,
      focusEffect = true,
      disabled = false,
      textSize = '14px',
      customStyles = {},
    } = this.props
    const { searchValue, focussed } = this.state
    const searchWrapperClass =
      (focussed && focusEffect ? classes.focussed : '') +
      ' ' +
      classes.inputWrapper

    return (
      <div
        className={classNames(searchWrapperClass, customStyles.searchWrapper)}
        style={{ width: width }}
      >
        <InputBase
          className={classes.input}
          placeholder={placeholder}
          onChange={this.handleChange}
          value={searchValue}
          onFocus={(e) => {
            this.handleOnFocus(true)
          }}
          onBlur={(e) => {
            this.handleOnFocus(false)
          }}
          disabled={disabled}
          onKeyUp={this.handleKeyDownSearch}
          style={{ fontSize: textSize }}
        />
        <IconButton
          onClick={this.handleButtonClick}
          className={classes.iconButton}
          aria-label="Search"
          data-testid="search-button"
        >
          {searchValue !== '' ? (
            <img src={crossIcon} style={{ height: 12 }} />
          ) : (
            <img src={searchIcon} style={{ height: 12 }} />
          )}
        </IconButton>
      </div>
    )
  }
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  onSearchButtonClick: PropTypes.func,
  customStyles: PropTypes.shape({
    searchWrapper: PropTypes.object,
  }),
}

export default withStyles(styles)(SearchInput)
