import {
  FETCH_COMMENTS_INITIATED,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAIL,
  ADD_COMMENT_INITIATED,
  ADD_COMMENT_SUCCESSFUL,
  EDIT_COMMENT_SUCCESSFULL,
  SAVE_ANNOTATION_MODE,
  SELECTED_COMMENT_ID,
  HIGHLIGHT_COMMENT_ID,
} from './ActionType'

export const initialState = {
  commentsList: [],
  loadingComments: false,
  savingComment: false,
  clearCommentForm: false,
  isAnnotationMode: false,
  selectCommentId: null,
  hoveredCommentId: '',
}

export default function renderComments(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMENTS_INITIATED: {
      return {
        ...state,
        loadingComments: true,
      }
    }
    case FETCH_COMMENTS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        loadingComments: false,
        commentsList: data || [],
      }
    }
    case FETCH_COMMENTS_FAIL: {
      return {
        ...state,
        loadingComments: false,
      }
    }
    case ADD_COMMENT_INITIATED: {
      return {
        ...state,
        savingComment: true,
      }
    }
    case ADD_COMMENT_SUCCESSFUL: {
      return {
        ...state,
        commentsList: [action.payload, ...state.commentsList],
        savingComment: false,
      }
    }
    case EDIT_COMMENT_SUCCESSFULL: {
      const { payload } = action
      let updatedCommentList = state.commentsList.map((comment) => {
        if (comment.render_comment_id === payload.render_comment_id) {
          return payload
        } else {
          return comment
        }
      })
      return {
        ...state,
        commentsList: [...updatedCommentList],
        savingComment: false,
      }
    }
    case SAVE_ANNOTATION_MODE: {
      const { payload } = action
      return {
        ...state,
        isAnnotationMode: payload,
      }
    }
    case SELECTED_COMMENT_ID: {
      const { payload } = action
      return {
        ...state,
        selectCommentId: payload,
      }
    }
    case HIGHLIGHT_COMMENT_ID: {
      const { payload } = action
      if (payload !== state.hoveredCommentId) {
        return {
          ...state,
          hoveredCommentId: payload,
        }
      } else {
        return state
      }
    }
    default:
      return state
  }
}
