export const FETCH_BUSINESS_DEPARTMENTS = 'FETCH_BUSINESS_DEPARTMENTS'
export const FETCH_BUSINESS_DEPARTMENTS_SUCCESS =
  'FETCH_BUSINESS_DEPARTMENTS_SUCCESS'
export const FETCH_BUSINESS_DEPARTMENTS_FAIL = 'FETCH_BUSINESS_DEPARTMENTS_FAIL'
export const FETCH_FILE_PATH_VALIDATION = 'FETCH_FILE_PATH_VALIDATION'
export const FETCH_FILE_PATH_VALIDATION_SUCCESS =
  'FETCH_FILE_PATH_VALIDATION_SUCCESS'
export const FETCH_FILE_PATH_VALIDATION_FAIL = 'FETCH_FILE_PATH_VALIDATION_FAIL'

export const SAVE_TEMPLATE_INITIATE = 'SAVE_TEMPLATE_INITIATE'
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS'
export const SAVE_TEMPLATE_FAILED = 'SAVE_TEMPLATE_FAILED'

export const FETCH_PROJECT_TYPE = 'FETCH_PROJECT_TYPE'
export const FETCH_PROJECT_TYPE_SUCCESS = 'FETCH_PROJECT_TYPE_SUCCESS'
export const FETCH_PROJECT_TYPE_FAIL = 'FETCH_PROJECT_TYPE_FAIL'

export const FETCH_MY_UPLOADS = 'FETCH_MY_UPLOADS'
export const FETCH_MY_UPLOADS_SUCCESS = 'FETCH_MY_UPLOADS_SUCCESS'
export const FETCH_MY_UPLOADS_FAIL = 'FETCH_MY_UPLOADS_FAIL'

export const INGEST_TEMPLATE_INITIATE = 'INGEST_TEMPLATE_INITIATE'
export const INGEST_TEMPLATE_SUCCESS = 'INGEST_TEMPLATE_SUCCESS'
export const INGEST_TEMPLATE_FAILED = 'INGEST_TEMPLATE_FAILED'
