import {
  FETCH_BUSINESS_DEPARTMENTS,
  FETCH_BUSINESS_DEPARTMENTS_SUCCESS,
  FETCH_BUSINESS_DEPARTMENTS_FAIL,
  FETCH_FILE_PATH_VALIDATION,
  FETCH_FILE_PATH_VALIDATION_SUCCESS,
  FETCH_FILE_PATH_VALIDATION_FAIL,
  SAVE_TEMPLATE_INITIATE,
  SAVE_TEMPLATE_FAILED,
  SAVE_TEMPLATE_SUCCESS,
  FETCH_PROJECT_TYPE,
  FETCH_PROJECT_TYPE_SUCCESS,
  FETCH_PROJECT_TYPE_FAIL,
  FETCH_MY_UPLOADS,
  FETCH_MY_UPLOADS_SUCCESS,
  FETCH_MY_UPLOADS_FAIL,
  INGEST_TEMPLATE_INITIATE,
  INGEST_TEMPLATE_SUCCESS,
  INGEST_TEMPLATE_FAILED,
} from './ActionType'

export const initialStateObjectDetection = {
  businessDepartments: [],
  fetchingBusinessDepartment: false,
  businessDepartmentsError: {},
  fetchingFileValidation: false,
  isFilePathValid: false,
  pathFetchingError: {},
  saveTemplateDetails: {
    success: {},
    fail: {},
    loading: false,
  },
  projectTypesDetails: {
    projectTypes: [],
    fetchingProjectTypes: false,
    projectTypeError: {},
  },
  templateDetails: {
    templatesList: [],
    loading: false,
    error: {},
  },
  ingestTemplateDetails: {
    success: {},
    fail: {},
    loading: false,
  },
}

export default function uploadTemplate(
  state = initialStateObjectDetection,
  action
) {
  switch (action.type) {
    case FETCH_BUSINESS_DEPARTMENTS: {
      return {
        ...state,
        fetchingBusinessDepartment: true,
        businessDepartments: [],
        businessDepartmentsError: {},
      }
    }
    case FETCH_BUSINESS_DEPARTMENTS_SUCCESS: {
      let departments = []
      for (let index = 0; index < action.data.length; index++) {
        const dept = action.data[index]
        departments.push({ value: dept.value, name: dept.display_value })
      }
      return {
        ...state,
        fetchingBusinessDepartment: false,
        businessDepartments: departments,
        businessDepartmentsError: {},
      }
    }
    case FETCH_BUSINESS_DEPARTMENTS_FAIL: {
      return {
        ...state,
        fetchingBusinessDepartment: false,
        businessDepartments: action.data,
        businessDepartmentsError: action.error,
      }
    }
    case FETCH_FILE_PATH_VALIDATION: {
      return {
        ...state,
        fetchingFileValidation: true,
        isFilePathValid: false,
        pathFetchingError: {},
      }
    }
    case FETCH_FILE_PATH_VALIDATION_SUCCESS: {
      return {
        ...state,
        fetchingFileValidation: false,
        isFilePathValid: action.data.valid,
        pathFetchingError: {},
      }
    }
    case FETCH_FILE_PATH_VALIDATION_FAIL: {
      return {
        ...state,
        fetchingFileValidation: false,
        isFilePathValid: action.data,
        pathFetchingError: action.error,
      }
    }
    case SAVE_TEMPLATE_INITIATE: {
      return {
        ...state,
        saveTemplateDetails: {
          loading: true,
          success: {},
          error: {},
        },
      }
    }
    case SAVE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        saveTemplateDetails: {
          loading: false,
          success: action.data,
          error: {},
        },
      }
    }
    case SAVE_TEMPLATE_FAILED: {
      return {
        ...state,
        saveTemplateDetails: {
          loading: false,
          success: action.data,
          error: action.error,
        },
      }
    }
    case INGEST_TEMPLATE_INITIATE: {
      return {
        ...state,
        ingestTemplateDetails: {
          loading: true,
          success: {},
          error: {},
        },
      }
    }
    case INGEST_TEMPLATE_SUCCESS: {
      return {
        ...state,
        ingestTemplateDetails: {
          loading: false,
          success: action.data,
          error: {},
        },
      }
    }
    case INGEST_TEMPLATE_FAILED: {
      return {
        ...state,
        ingestTemplateDetails: {
          loading: false,
          success: action.data,
          error: action.error,
        },
      }
    }
    case FETCH_PROJECT_TYPE: {
      return {
        ...state,
        projectTypesDetails: {
          loading: true,
          projectTypes: [],
          projectTypeError: {},
        },
      }
    }
    case FETCH_PROJECT_TYPE_SUCCESS: {
      let projectTypes = []
      for (let index = 0; index < action.data.length; index++) {
        const pt = action.data[index]
        projectTypes.push({ value: pt.projectTypeId, name: pt.projectType })
      }
      return {
        ...state,
        projectTypesDetails: {
          loading: false,
          projectTypes: projectTypes,
          projectTypeError: {},
        },
      }
    }
    case FETCH_PROJECT_TYPE_FAIL: {
      return {
        ...state,
        projectTypesDetails: {
          loading: false,
          projectTypes: action.data,
          projectTypeError: action.error,
        },
      }
    }
    case FETCH_MY_UPLOADS: {
      return {
        ...state,
        templateDetails: {
          loading: true,
          templatesList: [],
          error: {},
        },
      }
    }
    case FETCH_MY_UPLOADS_SUCCESS: {
      return {
        ...state,
        templateDetails: {
          loading: false,
          templatesList: action.data,
          error: {},
        },
      }
    }
    case FETCH_MY_UPLOADS_FAIL: {
      return {
        ...state,
        templateDetails: {
          loading: false,
          templatesList: action.data,
          error: action.error,
        },
      }
    }
    default:
      return state
  }
}
