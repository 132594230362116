import React from 'react'
import { Box } from '@mui/material'
import { BasicTabs } from 'cgi-ui-components'
import NewTemplate from '../../../components/TemplateUpload/NewTemplate'
import MyUploads from '../../../components/TemplateUpload/MyUploads'
import TemplateRefresh from '../../../components/TemplateUpload/TemplateRefresh'

class TemplateUploads extends React.Component {
  state = {
    defaultTab: 1,
  }
  render() {
    return (
      <Box>
        <BasicTabs
          onTabSelect={(e, value) => {
            this.setState({ defaultTab: value })
          }}
          value={this.state.defaultTab}
          tabAria="template uploads"
          tabItems={[
            {
              content: <MyUploads />,
              label: 'My Uploads',
            },
            {
              content: <NewTemplate />,
              label: 'New Template',
            },
            {
              content: <TemplateRefresh />,
              label: 'Template Refresh',
            },
          ]}
        />
      </Box>
    )
  }
}

export default TemplateUploads
