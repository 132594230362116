import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, styled } from '@mui/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import imgDefault from '../../images/ic_default_board_img.png'

import { forEach } from 'lodash'
import TextField from '@mui/material/TextField'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Collapse,
} from '@mui/material'
import { InfoCard, CustomIconButton } from 'cgi-ui-components'

import folder_closed from '../../images/assetIcons/default/folder_closed.svg?url'
import folder_open from '../../images/assetIcons/default/folder_open.svg?url'
import caret_right_white from '../../images/assetIcons/default/caret_right_white.svg?url'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import HideSourceOutlinedIcon from '@mui/icons-material/HideSourceOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import CartThumbnail from '../Cart/CartThumbnail'
import config from '../../config/config'

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 2,
    paddingBottom: 2,
    '&:hover': {
      '& $eyeIcon': {
        visibility: 'inherit !important',
      },
    },
  },
  eyeIcon: {
    visibility: 'hidden',
  },
  nested: {
    paddingLeft: 40,
  },
  tcinSelected: {
    // backgroundColor: `${theme.palette.primary.lightBlue} !important`,
    // color: 'white',
  },
  itemList: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 5,
    paddingBottom: 5,
    '&:hover': {
      '& $eyeEl': {
        visibility: 'inherit !important',
      },
    },
  },
  hideIconButton: {
    padding: 7,
    fontSize: '1.2rem',
  },
  mildlySelected: {
    backgroundColor: `white !important`,
    paddingLeft: 0,
    paddingBottom: `12px !important`,
    paddingTop: `12px !important`,
  },
  mildlySelectedGroup: {
    backgroundColor: `${theme.palette.secondary.light} !important`,
    paddingLeft: 0,
    paddingBottom: `12px !important`,
    paddingTop: `12px !important`,
    color: '#FFF',
  },
  thumbnailMini: {
    borderRadius: '50%',
    height: 40,
    width: 40,
    marginRight: 8,
  },
  groupIcon: {
    height: 20,
    width: 20,
  },

  caretWhite: {
    height: 10,
    width: 10,
  },

  groupName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 150,
    textOverflow: 'ellipsis',
  },
  groupItemName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 130,
    textOverflow: 'ellipsis',
  },
  eyeEl: {
    visibility: 'hidden',
  },
  inputBox: {
    height: 25,
    width: '90%',
    '& .MuiInputBase-input': {
      height: '10px !important',
    },
  },
  infoIconButton: {
    padding: 5,
    verticalAlign: 'middle',
    display: 'inline-flex',
    boxSizing: 'border-box',
  },
})

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor ? backgroundColor : '#ffffff',
    maxWidth: '100%',
    padding: 0,
    border: '1px solid #dadde9',
    width: 340,
  },
}))

class Collapsible extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      hoverAsset: null,
    }
    this.inputRef = React.createRef()
  }
  componentDidUpdate() {
    if (this.props.data.isRename) {
      this.inputRef.current.focus()
    }
  }

  handleClick = () => {
    const { data } = this.props
    this.props.onClick({ open: !this.props.selected, data })
  }

  handleFocus = (event) => {
    const { groupName } = this.props
    event.preventDefault()
    const { target } = event
    target.focus()
    target.setSelectionRange(0, groupName.length)
  }
  render() {
    const {
      classes,
      data = {},

      selectedData,
      selected,
      onContextMenu,
      handleOnEyeClick,
      displayData: { allTcinMap = {} },
      handleRename,
      onEnterRename,
      groupName,
      isError,
      disableHoverIcons = false,
      boardAssetObjects = {},
    } = this.props
    let assetSelected = false
    forEach(selectedData, (tcinObj) => {
      if (
        tcinObj.group &&
        tcinObj.selected &&
        data.name === tcinObj.group.name
      ) {
        assetSelected = true
      }
    })
    return (
      <List component="nav" className={classes.root}>
        {data.isRename ? (
          <TextField
            error={isError}
            helperText={isError ? 'Name already exists. Try another.' : ''}
            id="outlined-margin-dense"
            data-testid="textfield-dense"
            inputProps={{ 'data-testid': 'textfield-dense-input' }}
            className={classes.inputBox}
            margin="dense"
            name="assetName"
            variant="outlined"
            value={groupName}
            onKeyDown={onEnterRename}
            onChange={handleRename}
            autoFocus={true}
            inputRef={this.inputRef}
            onFocus={this.handleFocus}
          />
        ) : (
          <ListItem
            classes={{
              root: !selected
                ? classes.mildlySelected
                : classes.mildlySelectedGroup,
            }}
            selected={selected || assetSelected}
            button
            onClick={this.handleClick}
            onContextMenu={onContextMenu}
            data-testid="menu-list"
          >
            <img
              alt="fw_arrow"
              src={caret_right_white}
              style={{
                transform:
                  selected || assetSelected ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
              className={classes.caretWhite}
            />
            &nbsp;&nbsp;
            <ListItemIcon style={{ minWidth: 25, margin: 0 }}>
              <img
                alt="folder_icon"
                src={selected || assetSelected ? folder_open : folder_closed}
                className={classes.groupIcon}
              />
            </ListItemIcon>
            <span
              style={{ color: data.hidden && '#aaa' }}
              className={classes.groupName}
            >
              {data.name}
            </span>
            <ListItemSecondaryAction
              className={classes.eyeIcon}
              style={{
                visibility: data.hidden ? 'inherit' : 'hidden',
                display: disableHoverIcons ? 'none' : 'block',
              }}
            >
              <CustomIconButton
                onClick={() => handleOnEyeClick(data.name, data)}
                size="small"
                aria-label="Hide"
                data-testid="eye-icon"
                label={data.hidden ? 'Show' : 'Hide'}
                className={classes.hideIconButton}
              >
                {data.hidden ? (
                  <CircleOutlinedIcon fontSize="small" />
                ) : (
                  <HideSourceOutlinedIcon fontSize="small" />
                )}
              </CustomIconButton>

              <CustomIconButton
                className={classes.hideIconButton}
                onClick={onContextMenu}
                label="More"
              >
                <MoreVertOutlinedIcon fontSize="small" />
              </CustomIconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}

        <Collapse in={selected || assetSelected} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            style={{ marginTop: isError ? 20 : 0 }}
          >
            {data.group_items.map((item) => {
              const assetData =
                boardAssetObjects[allTcinMap[item].originalAssetId]
              return selectedData[item].deleted ? null : (
                <ListItem
                  key={item}
                  button
                  //Temprary Code Comment to disable asset within group selection as not implemented in BE
                  // onClick={event => {
                  //   onClickListItem(event, item, true)
                  // }}
                  // onContextMenu={event => contextMenuHandler(event, item, true)}
                  selected={selectedData[item].selected}
                  classes={{
                    selected: classes.tcinSelected,
                    root: classes.itemList,
                    container: classes.listHeader,
                  }}
                  className={classes.nested}
                >
                  <CartThumbnail
                    className={classes.thumbnailMini}
                    data={assetData}
                  />

                  <span
                    className={classes.groupItemName}
                    style={{ color: selectedData[item].hidden && '#aaa' }}
                  >
                    {item}
                  </span>
                  <div className={classes.eyeEl}>
                    <ListItemSecondaryAction>
                      <HtmlTooltip
                        interactive
                        placement="left"
                        title={
                          <React.Fragment>
                            <InfoCard
                              name="Info"
                              config={config}
                              isUrlAppendedWithImg={
                                assetData?.asset_type !== 'PID'
                              }
                              thumbnail={assetData?.thumbnail_url || imgDefault}
                              noThumbnail={imgDefault}
                              infoData={[
                                {
                                  label: 'Asset Type',
                                  value:
                                    assetData?.asset_type ||
                                    assetData?.sub_asset_type,
                                },
                                {
                                  label: 'value',
                                  value: assetData?.value || assetData?.pid,
                                },
                                { label: 'Dpci', value: assetData?.dpci },
                                {
                                  label: 'Product type',
                                  value: assetData?.product_type,
                                },
                                {
                                  label: 'Dimensions',
                                  value: assetData?.dimensions,
                                },
                                {
                                  label: 'Brand',
                                  value: assetData?.brand_name,
                                },
                                {
                                  label: 'Materials',
                                  value: assetData?.materials,
                                },
                                {
                                  label: 'Title',
                                  value: assetData?.product_desc,
                                },
                                {
                                  label: 'Short Description',
                                  value: assetData?.short_description,
                                },
                              ]}
                            />
                          </React.Fragment>
                        }
                      >
                        <span className={classes.infoIconButton}>
                          <InfoOutlinedIcon fontSize="small" />
                        </span>
                      </HtmlTooltip>

                      {/* 
                        Temprary Code Comment to disable asset within group selection as not implemented in BE      
                        <IconButton
                        className={classes.hideIconButton}
                        onClick={event => {
                          event.stopPropagation()
                          contextMenuHandler(event, item, true)
                        }}
                        style={{ color: selected && 'white' }}
                        size="small"
                        aria-label="Hide"
                      >
                        <img src={sx_threedots} />
                      </IconButton> */}
                    </ListItemSecondaryAction>
                  </div>
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      </List>
    )
  }
}

Collapsible.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Collapsible)
