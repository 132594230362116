import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { Button, Paper, Popper } from '@mui/material'
import {
  isEmpty,
  toLower,
  trim,
  forEach,
  isNull,
  isUndefined,
  cloneDeep,
} from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Typography, CustomButton } from 'cgi-ui-components'

import {
  removeTcinsFromBoard,
  addTcinToBoard,
} from '../../store/board/actionCreator'
import {
  handleOpenExplorer,
  handleCloseExplorer,
} from '../../store/explorer/actionCreator'
import { removeMultipleAssets } from '../designSpace'
import { showNotification } from '../../store/notification/actionCreator'
import DraggableAssetCard from '../assetExplorerCard/DraggableAssetCard'
import { filterAssetsByType } from '../../helpers/utils'

const styles = (theme) => ({
  cardWrapper: {
    width: '120px',
    borderRadius: '7px',
    marginBottom: 15,
  },
  primeCardWrapper: {
    zIndex: 5,
    overflow: 'auto',
    marginTop: 10,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },

  imgDimension: {
    height: '97px',
    width: '103px',
  },
  assetSearchContainer: {
    marginTop: 40,
    marginLeft: '1em',
  },
  textAddAsset: {
    fontSize: '10px',
    textAlign: 'center',
    color: 'grey',
    padding: 15,
  },

  buttonAddAssets: {
    margin: '10px auto',
  },

  emptySearchIcon: {
    margin: '0 120px',
    color: theme.palette.primary.contentLight,
  },
  searchNoFound: {
    fontSize: '14px',
    textAlign: 'center',
    color: theme.palette.primary.darkFont,
  },
  bottomBar: {
    position: 'sticky',
    bottom: 0,

    right: 0,
    left: 0,
    zIndex: 1000,
    padding: '10px 10px',
    margin: '0 -10px',
  },
  bottomBarButtons: {
    textAlign: 'center',
    paddingTop: 15,
  },

  removeButton: {
    backgroundColor: theme.palette.primary.brightRed,
    color: theme.palette.primary.light,
    width: '100%',
    marginBottom: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.brightRed,
    },
  },

  warnigMsg: {
    opacity: '0.9',
    color: theme.palette.primary.darkFont,
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: 0,
    lineHeight: '15.5px',
    marginTop: '1px',
  },

  horizontalDots: {
    fontSize: '1em',
    border: '1px solid ' + theme.palette.primary.lightGray,
    borderRadius: '11px',
    backgroundColor: 'white',
    //color: theme.palette.primary.lightGray,
  },
  confirmBtn: {
    width: '100%',
    marginBottom: 5,
  },

  deleteWarn: {
    zIndex: 150,
    background: '#E7EDFA',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    width: 235,

    '& h3': {
      color: '#333333',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '15.5px',
      margin: '0 0 10px',
    },
    '& p': {
      color: '#666666',
      fontSize: 12,
      margin: '0 0 10px',
      lineHeight: '15.5px',
      textAlign: 'justify',
    },
    '& button': {
      fontSize: 12,
      textTransform: 'none',
    },
    '& div:first-child': {
      padding: '20px 20px 0px',
    },
    '& >div:last-child': {
      paddingBottom: 10,
      paddingRight: 5,
      textAlign: 'right',
    },
  },
  acceptDeleteButton: {
    color: '#224FCA',
  },
  cancelDeleteButton: {
    color: '#AC0000',
  },
})

class BoardAssets extends React.Component {
  state = {
    anchorEl: null,
    secondaryAnchorEl: null,
    selectedItems: [],
    boardAssetsList: [],
    openConfirm: false,
    deleteRef: null,
    assetsToDelete: {},
  }

  componentDidMount = () => {
    const { boardAssets = [] } = this.props

    if (boardAssets.length > 0) {
      this.setState({
        boardAssetsList: cloneDeep(boardAssets),
      })
    } else {
      this.setState({
        boardAssetsList: cloneDeep(boardAssets),
      })
    }
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.searchVal !== prevProps.searchVal) {
      this.templateSearch(this.props.searchVal)
    }
    if (this.props.boardAssets !== prevProps.boardAssets) {
      if (this.props.boardAssets.length > 0) {
        this.setState({
          boardAssetsList: cloneDeep(this.props.boardAssets),
        })
      } else {
        this.setState({
          boardAssetsList: cloneDeep(this.props.boardAssets),
        })
      }
    }
  }

  deleteAssetCallBack = (
    tcin,
    success = () => {},
    failure = () => {},
    e,
    f,
    assetId
  ) => {
    const { removeTcinsFromBoard, boardId, showNotification } = this.props
    const deleteData = {
      board_id: boardId,
      assets: [tcin],
    }

    removeTcinsFromBoard(deleteData)
      .then(() => {
        removeMultipleAssets([assetId])
        success()
      })
      .catch(() => {
        failure()
        showNotification(
          true,
          "Sorry! we couldn't remove tcin from board. Try again!",
          'error'
        )
      })
  }

  removeAssetsFromBoard = () => {
    const { removeTcinsFromBoard, boardId, showNotification } = this.props
    const { selectedItems } = this.state
    const deleteData = {
      board_id: boardId,
      assets: [...selectedItems],
    }
    this.setState({ removing: true })
    removeTcinsFromBoard(deleteData)
      .then(() => {
        removeMultipleAssets([...selectedItems])
        this.setState({ selectedItems: [], removing: false })
      })
      .catch(() => {
        this.setState({ removing: false })
        showNotification(
          true,
          "Sorry! we couldn't remove tcin from board. Try again!",
          'error'
        )
      })
  }

  cancelSelectedItems = () => {
    this.setState({ selectedItems: [] })
  }

  handleAddAsset = (e) => {
    this.props.handleOpenExplorer()
    e.nativeEvent.stopImmediatePropagation()
  }

  handleSelectCheckbox = (e, checked, data) => {
    const { value: tcin } = data
    const { selectedItems } = this.state

    if (checked) {
      this.setState({
        selectedItems: [...selectedItems, tcin],
      })
    } else {
      let newTcinItems = selectedItems.filter((i) => i !== tcin)
      this.setState({
        selectedItems: [...newTcinItems],
      })
    }
  }

  // Determines if filterInput is found in any property of assetGroup
  filterQuery(value, localVal = '') {
    if (isNull(value) || isUndefined(value) || value === '') {
      return false
    }
    return (
      value
        .toString()
        .toLocaleLowerCase()
        .indexOf(localVal.toString().toLocaleLowerCase()) > -1
    )
  }

  clearSearch = () => {
    this.templateSearch('')
  }

  templateSearch = (val = '') => {
    const _this = this
    const { boardAssets = [] } = _this.props
    var searchTerm = val && (toLower(trim(val)) || '')
    var tcinListMatching = []
    if (searchTerm !== '') {
      forEach(boardAssets, function (element) {
        if (
          _this.filterQuery(element.value, searchTerm) ||
          _this.filterQuery(element.asset_type, searchTerm) ||
          _this.filterQuery(element.brand, searchTerm) ||
          _this.filterQuery(element.dpci, searchTerm) ||
          _this.filterQuery(element.product_type, searchTerm) ||
          _this.filterQuery(element.materials, searchTerm) ||
          _this.filterQuery(
            element.product_description &&
              element.product_description.short_description,
            searchTerm
          )
        ) {
          // match found, add to results array
          tcinListMatching.push(element)
        }
      })
      _this.setState({
        boardAssetsList: tcinListMatching,
        selectedItems: [],
      })
    } else {
      _this.setState({
        boardAssetsList: cloneDeep(boardAssets),
        selectedItems: [],
      })
    }
  }

  handleAssetDelete = (tcin, suc, fail, e, ggaStatus, assetId) => {
    if (ggaStatus && ggaStatus !== '' && ggaStatus !== null) {
      this.setState({
        deleteRef: e.currentTarget,
        assetsToDelete: { tcin, assetId },
      })
    } else {
      this.deleteAssetCallBack(tcin, suc, fail, '', '', assetId)
    }
  }

  renderdeleteWarning = () => {
    const { classes } = this.props
    const { deleteRef, assetsToDelete } = this.state
    return (
      <Popper
        className={classes.deleteWarn}
        open={Boolean(deleteRef)}
        anchorEl={deleteRef}
      >
        <div>
          <h3>Your action can’t be undone</h3>
          <p>
            The asset is unavailable in explorer until its high poly version is
            available. Please re-add it using bulk add feature.
          </p>
        </div>
        <div>
          <Button
            onClick={() => {
              this.deleteAssetCallBack(
                assetsToDelete.tcin,
                () => {
                  this.setState({ deleteRef: null, assetsToDelete: {} })
                },
                () => {
                  this.setState({ deleteRef: null, assetsToDelete: {} })
                },
                '',
                '',
                assetsToDelete.assetId
              )
            }}
            className={classes.acceptDeleteButton}
          >
            Remove from Board
          </Button>
          <Button
            onClick={() => {
              this.setState({ deleteRef: null, assetsToDelete: {} })
            }}
            className={classes.cancelDeleteButton}
          >
            Cancel
          </Button>
        </div>
      </Popper>
    )
  }
  render() {
    const {
      classes,
      isExplorerOpen,
      boardAssets,
      activeOperations,
      searchVal,
      templatePage = false,
      handleCartActions,
    } = this.props
    const { selectedItems, boardAssetsList, removing } = this.state
    return (
      <>
        {isEmpty(boardAssetsList) ? (
          !boardAssets.length ? (
            <div className={classes.assetSearchContainer}>
              <div style={{ textAlign: 'center', padding: '10px 0' }}>
                <i
                  className="fab fa-trello fa-5x"
                  aria-hidden="true"
                  style={{
                    color: '#D3D3D3',
                    margin: '0 auto',
                  }}
                />
                <div className={classes.textAddAsset}>
                  Shortlist your assets. Add them from explorer.
                </div>
                <CustomButton
                  color="primary"
                  className={classes.buttonAddAssets}
                  onClick={this.handleAddAsset}
                  disabled={isExplorerOpen}
                  label={'Add assets'}
                />
              </div>
            </div>
          ) : (
            <div className={classes.assetSearchContainer}>
              <FontAwesomeIcon
                icon={faSearch}
                size="2x"
                className={classes.emptySearchIcon}
              />
              <p className={classes.searchNoFound}>
                Sorry we couldn't find any matches <br />
                for "{searchVal}"
              </p>
            </div>
          )
        ) : (
          <div className={classes.primeCardWrapper}>
            {boardAssetsList.map((element, i) => (
              <div className={classes.cardWrapper} key={i}>
                <DraggableAssetCard
                  data={{
                    ...element,
                    tcin: element.value,
                    short_description:
                      element.product_description &&
                      element.product_description.short_description,
                  }}
                  handleCartActions={handleCartActions}
                  selectable={!templatePage}
                  selected={selectedItems.includes(element.value)}
                  onSelect={this.handleSelectCheckbox}
                />
              </div>
            ))}
            <div className={classes.cardWrapper}></div>
          </div>
        )}
        {selectedItems.length > 0 && !templatePage && (
          <Paper className={classes.bottomBar} elevation={3}>
            <Typography
              label={`Total ${selectedItems.length} Asset
              ${selectedItems.length > 1 ? 's' : ''} selected`}
            />

            <div className={classes.bottomBarButtons}>
              <CustomButton
                color="primary"
                onClick={this.removeAssetsFromBoard}
                variant="contained"
                className={classes.removeButton}
                disabled={removing}
                label="Remove From Board"
              />

              <CustomButton
                className={classes.cancelButton}
                onClick={this.cancelSelectedItems}
                disabled={removing}
                label="Cancel"
                color="tertiary"
                variant="text"
              />
            </div>
          </Paper>
        )}

        {this.renderdeleteWarning()}
      </>
    )
  }
}

BoardAssets.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = ({
  Board: { assets: boardAssets, board_id: boardId, isExplorerOpen },
  explorer: { tcin_comments, show_comments },
  activeOperations,
  cart,
  experiences: { experience },
  scene: { mode },
}) => {
  return {
    boardId,
    boardAssets: filterAssetsByType(boardAssets, mode),
    isExplorerOpen,
    activeOperations,
    cart,
    experience,
    tcin_comments,
    show_comments,
  }
}
export default connect(mapStateToProps, {
  removeTcinsFromBoard,
  showNotification,
  handleOpenExplorer,
  addTcinToBoard,
  handleCloseExplorer,

  showNotification,
})(withStyles(styles)(BoardAssets))
