import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'

import { CustomIconButton } from 'cgi-ui-components'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import TextureDefImg from '../../images/textureDefImg.svg?url'
import { fetchRenditionImage } from '../../store/customCard/actionCreator'
import config from '../../config/config'

const useStyles = makeStyles(() => ({
  textureDisplayMain: {
    display: 'flex',
    width: 220,
    padding: `15px 5px`,
  },
  defaultTexture: {
    height: 50,
    flexGrow: 1,
    border: '1px solid black',
    backgroundSize: 'cover',
  },
  icon: { alignSelf: 'center', padding: 2, margin: 2 },
}))

const TextureComponent = ({
  selectedTexture = null,
  favouriteList = [],
  maxFavourites,
  handleError = () => {},
  handleFavsUpdate = () => {},
}) => {
  const [src, setSrc] = useState(selectedTexture)

  useEffect(() => {
    fetchImage()
  }, [])

  useEffect(() => {
    fetchImage()
  }, [selectedTexture])

  const fetchImage = () => {
    if (selectedTexture === null) {
      setSrc(TextureDefImg)
      return
    }
    let renditionsUrl = `${config.renditionsUrl}/${selectedTexture}`

    fetchRenditionImage(
      renditionsUrl,
      (response) => {
        setSrc(URL.createObjectURL(response))
      },
      () => {
        setSrc(TextureDefImg)
      }
    )
  }
  const isFavourite = favouriteList.find(
    (item) => item.texture_url === selectedTexture
  )
  const classes = useStyles()
  const setFavourites = ({ action, value }) => {
    if (
      maxFavourites &&
      favouriteList.length >= maxFavourites &&
      action === 'add'
    ) {
      handleError('max-limit-error')
      return
    }

    handleFavsUpdate({ action, value })
  }
  return (
    <div className={classes.textureDisplayMain}>
      <div
        className={classes.defaultTexture}
        style={{ backgroundImage: `url(${src})`, backgroundSize: 'cover' }}
      ></div>
      <div className={classes.icon}>
        {isFavourite ? (
          <CustomIconButton
            data-testid="delete-favorite-button"
            onClick={() =>
              setFavourites({ action: 'delete', value: selectedTexture })
            }
            color="primary"
          >
            <FavoriteIcon size="small" />
          </CustomIconButton>
        ) : (
          <CustomIconButton
            disabled={selectedTexture === '' || selectedTexture === null}
            data-testid="add-favorite-button"
            onClick={() =>
              setFavourites({ action: 'add', value: selectedTexture })
            }
          >
            <FavoriteBorderIcon size="small" />
          </CustomIconButton>
        )}
      </div>
    </div>
  )
}

export default TextureComponent
