import { useEffect, useRef, useState } from 'react'
import { isEqual, cloneDeep } from 'lodash'
import { Vector3 } from 'three'
import { DEFAULT_NOISE, DEFAULT_NOISE_CORONA } from '../constants/common'
import {
  ASSET_MODE_ARCH,
  ASSET_MODE_GALLERY,
  ASSET_MODE_INFRA,
  ASSET_MODE_PROP,
  ASSET_MODE_TCIN,
} from '../constants/scene'

export const generateDuplicateAssetName = function (name, assetNameList) {
  if (assetNameList.indexOf(name) < 0) {
    assetNameList.push(name)
    return name
  } else {
    let new_name = name
    if (name.includes('_')) {
      //if name has _
      const name_elements = name.split('_')
      let numeric_val = name_elements.pop()
      if (parseInt(numeric_val)) {
        //If last charecter splitted _ is numeric value
        new_name = get_next_valid_name(
          assetNameList,
          name_elements.join('_'),
          parseInt(numeric_val) + 1
        )
      } else {
        // if last charecter splitted by _  is non numeric value
        name_elements.push(numeric_val)
        new_name = get_next_valid_name(
          assetNameList,
          name_elements.join('_'),
          1
        )
      }
    } else {
      //if name done have any underscore
      new_name = get_next_valid_name(assetNameList, name, 1)
    }

    assetNameList.push(new_name)
    return new_name
  }
}

const get_next_valid_name = (assetNameList, str_val, num_val = 1) => {
  let name = str_val + '_' + num_val.toString()
  while (assetNameList.indexOf(name) > -1) {
    name = str_val + '_' + num_val.toString()
    num_val = parseInt(num_val) + 1
  }

  return name
}
export const convertToInch = function (valueInMeter) {
  const METER_TO_INCH = 39.3701
  return valueInMeter * METER_TO_INCH
}
export const convertToMeter = function (valueInMeter) {
  const METER_TO_INCH = 39.3701
  return valueInMeter / METER_TO_INCH
}

export const convertCordinatesToInch = ({ x, y, z }) => ({
  x: convertToInch(x),
  y: convertToInch(y),
  z: convertToInch(z),
})
export const convertCordinatesToMeter = ({ x, y, z }) => ({
  x: convertToMeter(x),
  y: convertToMeter(y),
  z: convertToMeter(z),
})

export const canUserWrite = (lanId = '', initiator = '', members = []) => {
  if (initiator === lanId) {
    return true
  }
  let canwrite = false
  members &&
    members.forEach((user = { user: {} }) => {
      if (
        user.access_level === 'Write' &&
        user.user &&
        user.user.login_id === lanId
      ) {
        canwrite = true
      }
    })

  return canwrite
}

export const isExternalUser = (memberGroup = []) => {
  const externalUserGroupId = 'APP-ExART-Users'
  return memberGroup.includes(externalUserGroupId)
}

export const isMemberOfAppArtAdmin = (memberGroup = []) => {
  const AppArtAdminID = 'APP-ART-Admin'
  return (
    memberGroup.includes(AppArtAdminID) ||
    memberGroup.includes(AppArtAdminID.toUpperCase())
  )
}

export const isMemberOfArtUser = (memberGroup = []) => {
  const externalGroup = 'APP-ExART-Users'
  const internalArtUserGroup = 'APP-ART-USERS'
  return (
    memberGroup.includes(internalArtUserGroup) ||
    memberGroup.includes(externalGroup)
  )
}

export const radToAng = (r) => parseFloat(r * (180 / Math.PI)).toFixed(15)
export const angToRad = (r) => parseFloat(r * (Math.PI / 180))

export const compareObjectEquality = (a, b) => {
  let aProps = Object.getOwnPropertyNames(a)
  let bProps = Object.getOwnPropertyNames(b)
  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i in aProps) {
    let propName = aProps[i]
    if (a[propName] !== b[propName]) {
      return false
    }
  }
  return true
}

export const OS = {
  WINDOWS: 'Win',
  MACOS: 'Mac',
  UNIX: 'X11',
  LINUX: 'Linux',
}
export const getCurrentOS = () => {
  let os = null
  if (navigator.appVersion.indexOf('Win') !== -1) os = OS.WINDOWS
  if (navigator.appVersion.indexOf('Mac') !== -1) os = OS.MACOS
  if (navigator.appVersion.indexOf('X11') !== -1) os = OS.UNIX
  if (navigator.appVersion.indexOf('Linux') !== -1) os = OS.LINUX
  return os
}
export const getAssetUrl = (data, variation) => {
  let urls = {}
  if (data[variation] !== undefined) {
    for (const key of Object.keys(data[variation])) {
      if (key.toLowerCase().endsWith('draco.gltf')) {
        urls.ktxUrl = data[variation][key]
      } else if (key.toLowerCase().endsWith('draco.glb')) {
        urls.tcinUrl = data[variation][key]
      }
    }
  }

  if (urls.ktxUrl !== undefined) {
    urls.tcinUrl = urls.ktxUrl
  }

  return urls
}

//Asset upload helpers
export const getUploadStatus = (hpaStatus = 'failed', thumbnail = 'failed') => {
  if (hpaStatus === 'Success' && thumbnail === 'Success') {
    return {
      status: 'Success',
      reason: '',
    }
  } else if (hpaStatus === 'InProgress' || thumbnail === 'InProgress') {
    return {
      status: 'InProgress',
      reason: '',
    }
  } else if (hpaStatus === 'Failed' || thumbnail === 'Failed') {
    return {
      status: 'Failed',
      reason:
        hpaStatus === 'Failed' && thumbnail === 'Failed'
          ? 'both'
          : hpaStatus === 'Failed'
          ? 'hpa'
          : 'thumbnail',
    }
  } else {
    return {
      status: 'Failed',
      reason: '',
    }
  }
}

export function getPerPageCount() {
  let screenWidth = window.innerWidth
  if (screenWidth >= 1280 && screenWidth < 1920) {
    return 45
  }
  return 65
}

export const mentionsParser = (comment, mentionedUsers = false) => {
  let comments = comment
  let regex = /@\[.+?\]\(.+?\)/gm
  let displayRegex = /@\[.+?\]/g
  let idRegex = /\(.+?\)/g
  let matches = comments.match(regex)
  let arr = []
  let mentioned_users = []
  matches &&
    matches.forEach((m) => {
      let id = m.match(idRegex)[0].replace('(', '').replace(')', '')
      let display = m.match(displayRegex)[0].replace('@[', '').replace(']', '')

      arr.push({ id: id, display: display })
    })
  let newComment = comments.split(regex)
  let output = ''

  for (let i = 0; i < newComment.length; i++) {
    const c = newComment[i]
    if (i === newComment.length - 1) {
      output += c
    } else {
      output += c + `<span style='color:#366CD9;'>${arr[i].display}</span>`
      mentioned_users = [...mentioned_users, arr[i].display]
    }
  }
  if (mentionedUsers) {
    return mentioned_users.map((item) => item + '@target.com')
  } else {
    return output
  }
}

// method isExpiredS3Url not required anymore

export const pluralise = (word, count) => {
  return `${parseInt(count)} ${word}${count > 1 ? 's' : ''}`
}

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num)
}

export const openSlackChannel = (isBusiness = false) => {
  window.open(
    `https://target.slack.com/archives/${
      isBusiness ? 'C03TH5WMLTW' : 'CH4JXP2HL'
    }`
  )
}

export const useCustomState = (init) => {
  const [state, setState] = useState(init)
  const cbRef = useRef()

  const setCustomState = (newState, callback) => {
    cbRef.current = callback
    setState(newState)
  }

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state)
    }
    cbRef.current = undefined
  }, [state])

  return [state, setCustomState]
}

export const mapInfoForCard = (item) => {
  let data = [
    { label: 'Asset Type', value: item.asset_type },
    {
      label: 'value',
      value: item.tcin || item.value || item.pid || item.asset_id,
    },
    { label: 'Dpci', value: item.dpci },
    { label: 'Product type', value: item.product_type },
    { label: 'Dimensions', value: item.dimensions },
    { label: 'Brand', value: item.brand },
    { label: 'Materials', value: item.materials },
    {
      label: 'Title',
      value:
        item?.product_description?.title ||
        item?.product_desc ||
        item?.asset_name,
    },
    {
      label: 'Short Description',
      value:
        item?.product_description?.short_description ||
        item?.short_description ||
        item?.asset_description,
    },
    {
      label: 'IDS Status',
      value: item?.item_status,
    },
    {
      label: 'Render Support',
      value: item?.renderer,
    },
    {
      label: 'Launch Date',
      value: item?.launch_time
        ? new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(new Date(item?.launch_time))
        : 'NA',
    },
    {
      label: 'Polygon Count',
      value: item?.triangle_count || 'NA',
    },
  ]
  if (item?.asset_type === 'PID') {
    data.splice(2, 0, { label: 'Tcin', value: item?.tcinIds })
  }
  if (item?.asset_type === 'ARCHITECTURE') {
    data.splice(11, 0, {
      label: 'Uploaded date',
      value: item?.uploaded_timestamp
        ? new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(new Date(item?.uploaded_timestamp))
        : 'NA',
    })
  }

  return data
}

export const getDefaultNoiseBasedOnRenderer = (renderer) =>
  renderer === 'V-Ray' ? DEFAULT_NOISE : DEFAULT_NOISE_CORONA

export const FAVOURITE_DROPDOWN_VALUES = [
  'My Favourites',
  'Shared with me',
  // 'Gallery',
]

export const compareSceneJSON = (data1, data2) => {
  const equals = isEqual(
    { assets: data1.assets, groups: data1.groups },
    { assets: data2.assets, groups: data2.groups }
  )
  return equals
}
export const waitUntil = (conditionFunction) => {
  const poll = (resolve) => {
    if (conditionFunction()) resolve()
    else setTimeout((_) => poll(resolve), 300)
  }
  return new Promise(poll)
}

export const getAssetType = (value) => {
  const isTcin = !value.includes('-')
  if (isTcin) return 'tcin'
  else return 'pid'
}

export const getRequestHeaders = (config, url) => {
  var accessToken = window.localStorage.getItem('access_token')
  var idToken = window.localStorage.getItem('id_token')
  var xApiKey = config.apiKey
  let reqHeaders = {}
  if (!url.includes('Amz') && !url.includes('AWS')) {
    if (url.includes('gallery')) {
      reqHeaders = {
        Authorization: accessToken,
      }
    } else {
      reqHeaders = {
        Authorization: accessToken,
        'x-api-key': xApiKey,
        'x-id-token': idToken,
      }
    }
  }

  return reqHeaders
}

export const filterAssetsByType = (assets = [], type) => {
  if (type) {
    const filteredAssets = assets.filter((item) => {
      const { asset_type = '', assetType = '' } = item
      const assetDataType = asset_type || assetType
      if (
        type == ASSET_MODE_TCIN &&
        (assetDataType === ASSET_MODE_TCIN ||
          assetDataType === ASSET_MODE_PROP ||
          assetDataType === ASSET_MODE_GALLERY)
      ) {
        return true
      }
      if (type === ASSET_MODE_ARCH && assetDataType === ASSET_MODE_ARCH) {
        return true
      }
      if (type === ASSET_MODE_INFRA && assetDataType === ASSET_MODE_INFRA) {
        return true
      }
      return false
    })
    return filteredAssets
  }
  return assets
}

export const sortListByDate = (list, dateFieldName) => {
  const sorter = (a, b) => {
    return (
      new Date(b[dateFieldName]).getTime() -
      new Date(a[dateFieldName]).getTime()
    )
  }
  list.sort(sorter)
}

const getAsset = ({
  assetId,
  flipped,
  grouped,
  locked,
  position,
  rotation,
  scale,
  url,
  visible,
}) => {
  // attributes required to compare
  return {
    assetId,
    flipped,
    grouped,
    locked,
    position: new Vector3(position.x, position.y, position.z),
    rotation: new Vector3(rotation._x, rotation._y, rotation._z),
    scale: new Vector3(scale.x, scale.y, scale.z),
    url,
    visible,
  }
}
const getGroup = ({ flipped, position, rotation, scale, visible }) => {
  // attributes required to compare
  return {
    flipped,
    position: new Vector3(position.x, position.y, position.z),
    rotation: new Vector3(rotation._x, rotation._y, rotation._z),
    scale: new Vector3(scale.x, scale.y, scale.z),
    visible,
    isGroup: true,
  }
}
const getKeyValPair = ({ assets = [], groups = [] }) => {
  //assuming data = {assets:[], groups:[]}
  const dataObject = {}
  for (const asset of assets) {
    //Assuming name is unique
    dataObject[asset.name] = getAsset(asset)
  }
  for (const group of groups) {
    //Assuming name is unique
    dataObject[group.name] = getGroup(group)
    const { assets: groupAssets = [] } = group
    for (const asset of groupAssets) {
      dataObject[asset.name] = getAsset(asset)
    }
  }
  return dataObject
}

const compareAssets = (asset_x, asset_y, name, assetDifferrences = []) => {
  if (!asset_x.position.equals(asset_y.position)) {
    assetDifferrences.push({ type: 'moved', asset: asset_y, name: name })
  }

  if (!asset_x.rotation.equals(asset_y.rotation)) {
    assetDifferrences.push({ type: 'rotated', asset: asset_y, name })
  }
  if (!asset_x.scale.equals(asset_y.scale)) {
    assetDifferrences.push({ type: 'scaled', asset: asset_y, name })
  }
  if (asset_x.locked !== asset_y.locked) {
    assetDifferrences.push({ type: 'locked', asset: asset_y, name })
  }
  if (asset_x.visible !== asset_y.visible) {
    assetDifferrences.push({ type: 'visibility', asset: asset_y, name })
  }
}

const findDifference = (x, y) => {
  const data_x = getKeyValPair(x)
  const data_y = getKeyValPair(y)

  const assetDifferrences = []

  Object.keys(data_x).forEach((assetName) => {
    if (!data_y[assetName]) {
      assetDifferrences.push({
        type: 'deleted',
        asset: data_x[assetName],
        name: assetName,
      })
    } else {
      const asset_x = data_x[assetName]
      const asset_y = data_y[assetName]
      compareAssets(asset_x, asset_y, assetName, assetDifferrences)
    }
  })
  Object.keys(data_y).forEach((assetName) => {
    if (!data_x[assetName]) {
      assetDifferrences.push({
        type: 'added',
        asset: data_y[assetName],
        name: assetName,
      })
    }
  })
  return assetDifferrences
}

export const deepCompareSceneJSON = (data1, data2) => {
  const equals = isEqual(
    { assets: data1.assets, groups: data1.groups },
    { assets: data2.assets, groups: data2.groups }
  )
  let diff = []
  if (!equals) {
    diff = findDifference(
      { assets: cloneDeep(data1.assets), groups: cloneDeep(data1.groups) },
      { assets: cloneDeep(data2.assets), groups: cloneDeep(data2.groups) }
    )
  }
  return {
    equals: equals,
    diff: diff,
  }
}

export const createCustomEvent = (eventName, data = {}) => {
  //trackSceneEvent
  let event = new CustomEvent(eventName, {
    detail: { ...data },
  })
  document.dispatchEvent(event)
}
