import React, { useRef, useEffect, useState } from 'react'
import { Stage, Layer, Line, Text, Rect } from 'react-konva'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import axios from 'axios'
import apiConfig from '../../config/config'
import EraserCursor from '../../images/assetIcons/default/Eraser_ic.svg?url'
import ScribbleCursor from '../../images/assetIcons/default/pencil_ic.svg?url'
import { getActionByKey } from '../../helpers/keyboard'

import Annotator from '../annotation'

const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'calc(100vh - 190px)',
    position: 'relative',
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 190px - 60px)',
  },
  scribbleCursor: {
    cursor: `url(${ScribbleCursor}) -2 15 ,auto`,
  },
  eraserCursor: {
    cursor: `url(${EraserCursor}) -2 15 ,auto`,
  },
  commentCursor: {
    cursor: 'crosshair',
  },
}))

export default function Scribbler({
  imgSrc = '',
  isScribbleActive = false,
  isCommentActive = false,
  isEraserActive = false,
  imageDetails = {},
  commentsList = [],
  annotations = [],
  activeAnnotations = '',
  userlanId,
  saveCommentId = () => {},
  imageWidth,
  imageHeight,
  isAnnotationMode = false,
  activateActionButtons = () => {},
}) {
  const divElem = useRef(null)
  const classes = styles()
  const [scaleWidth, setWidthScale] = useState(1)
  const [scaleHeight, setHeightScale] = useState(1)
  const [parentHeight, setParentHeight] = useState(100)
  const [parentWidth, setParentWidth] = useState(100)
  const [tool, setTool] = useState('pen')

  const [undo, setUndo] = useState([])
  const [redo, setRedo] = useState([])

  const [lines, setLines] = useState([])
  const isDrawing = useRef(false)

  let initialHeight, initialWidth

  useEffect(function () {
    initialHeight = 397
    initialWidth = 397
    setParentHeight(divElem.current.offsetHeight)
    setParentWidth(divElem.current.offsetWidth)
    setWidthScale(divElem.current.offsetHeight / initialWidth)
    setHeightScale(divElem.current.offsetHeight / initialHeight)

    const resizeListener = () => {
      setWidthScale(divElem.current.offsetHeight / initialWidth)
      setHeightScale(divElem.current.offsetHeight / initialHeight)
      setParentHeight(divElem.current.offsetHeight)
      setParentWidth(divElem.current.offsetWidth)
    }

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(
    function () {
      setTool(isScribbleActive ? 'pen' : isEraserActive ? 'eraser' : '')
    },
    [isScribbleActive, isEraserActive]
  )
  useEffect(
    function () {
      const { render_job_id, experience_id } = imageDetails
      const url = `${apiConfig.comments.getScribble}/${experience_id}/${render_job_id}`
      axios.get(url).then((response) => {
        setLines(response.data.length ? response.data : [])
        activateActionButtons()
      })
    },
    [activateActionButtons, imageDetails]
  )
  useEffect(() => {
    document.addEventListener('keydown', handleMultiKeyPress, true)
    return () => {
      document.removeEventListener('keydown', handleMultiKeyPress, true)
    }
  })

  const handleMultiKeyPress = (event) => {
    event.stopPropagation()
    if (isAnnotationMode) {
      const sceneAction = getActionByKey(event)
      if (sceneAction === 'SCENE_UNDO') {
        if (undo.length) {
          setLines(lines.slice(0, -1))
        }
        setUndo(undo.slice(0, -1))
        setRedo([...redo, ...undo.slice(-1)])
      }
      if (sceneAction === 'SCENE_REDO') {
        setLines([...lines, ...redo.slice(-1)])
        setRedo(redo.slice(0, -1))
        setUndo([...undo, ...redo.slice(-1)])
      }
    }
  }

  useEffect(() => {
    if (undo.length || redo.length) {
      saveScribble()
    }
  }, [undo.length, redo.length])
  const handleMouseDown = (e) => {
    isDrawing.current = true
    const pos = e.target.getStage().getRelativePointerPosition()
    setLines([...lines, { tool, points: [pos.x, pos.y] }])
  }

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getRelativePointerPosition()
    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])
    lastLine.created_by = userlanId
    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleMouseUp = () => {
    isDrawing.current = false
    setUndo([...undo, lines[lines.length - 1]])
  }
  const saveScribble = () => {
    const { render_job_id, experience_id } = imageDetails
    const payload = {
      render_job_id,
      experience_id,
      scribble_data: lines,
    }
    const url = `${apiConfig.comments.saveScribble}`
    axios.post(url, payload).then((response) => {
      // console.log('saved response', response.data)
    })
  }

  const modifiedlines = isEraserActive
    ? lines && lines.filter((item) => item && item.created_by === userlanId)
    : lines

  const components = [
    <Stage
      width={imageWidth}
      height={imageHeight}
      onMouseDown={handleMouseDown}
      onMousemove={handleMouseMove}
      onMouseup={handleMouseUp}
      scaleX={scaleWidth}
      scaleY={scaleHeight}
    >
      <Layer>
        {modifiedlines &&
          modifiedlines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke="black"
              strokeWidth={line.tool === 'eraser' ? 5 : 2}
              tension={0.2}
              lineCap="round"
              globalCompositeOperation={
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          ))}
      </Layer>
    </Stage>,
    <Annotator
      parentHeight={parentHeight}
      imgSrc={imgSrc}
      isCommentActive={isCommentActive}
      isScribbleActive={isScribbleActive}
      imageDetails={imageDetails}
      commentsList={commentsList}
      annotations={annotations}
      activeAnnotations={activeAnnotations}
      saveCommentId={saveCommentId}
    />,
  ]
  return (
    <div
      className={classNames(
        classes.container,
        isScribbleActive
          ? classes.scribbleCursor
          : isEraserActive
          ? classes.eraserCursor
          : isCommentActive
          ? classes.commentCursor
          : ''
      )}
      ref={divElem}
      style={{
        backgroundImage: `url(${imgSrc})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: `${imageWidth}px`,
        height: `${imageHeight}px`,
        margin: 'auto',
      }}
    >
      {isAnnotationMode &&
        (isScribbleActive || isEraserActive
          ? components.reverse()
          : components)}
    </div>
  )
}
