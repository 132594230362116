import * as THREE from 'three'
import {
  ASSET_MODE_ARCH,
  ASSET_MODE_INFRA,
  ASSET_MODE_GALLERY,
  ASSET_MODE_PROP,
  ASSET_MODE_TCIN,
} from '../constants/scene'

import {
  radToAng, //Can be replaced with Threejs MathUtils.radToDeg
  angToRad, //Can be replaced with Threejs MathUtils.degToRad
} from '../helpers/utils'
export class SceneHelper extends THREE.Scene {
  constructor() {
    super()
    this.objects = {
      [ASSET_MODE_TCIN]: [],
      [ASSET_MODE_PROP]: [],
      [ASSET_MODE_GALLERY]: [],
      [ASSET_MODE_INFRA]: [],
      [ASSET_MODE_ARCH]: [],
    }
    THREE.Mesh.prototype.clone = function (object) {
      if (object === undefined) {
        object = new THREE.Mesh(this.geometry, this.material.clone())
        object.name = this.material.name
      }
      THREE.Object3D.prototype.clone.call(this, object)
      return object
    }
  }
  containsGroup(assetNames = []) {
    return assetNames.filter((name) => this.getObjectByName(name).isGroup)
      .length
  }
  addObjectsByType(asset) {
    const assetType = asset.assetType
    if (
      !assetType ||
      ![
        ASSET_MODE_TCIN,
        ASSET_MODE_PROP,
        ASSET_MODE_GALLERY,
        ASSET_MODE_INFRA,
        ASSET_MODE_ARCH,
      ].includes(assetType)
    ) {
      console.error(
        'adding asset objects is failed due to invalid assetType ',
        assetType
      )
      return false
    }
    this.objects[assetType].push(asset)

    return true
  }
  getObjectsByType(type = ASSET_MODE_TCIN, strictMode = false) {
    if (type === ASSET_MODE_TCIN && !strictMode) {
      return [
        ...this.objects[ASSET_MODE_TCIN],
        ...this.objects[ASSET_MODE_PROP],
        ...this.objects[ASSET_MODE_GALLERY],
      ]
    } else if (type === 'ALL') {
      return [
        ...this.objects[ASSET_MODE_TCIN],
        ...this.objects[ASSET_MODE_PROP],
        ...this.objects[ASSET_MODE_GALLERY],
        ...this.objects[ASSET_MODE_INFRA],
        ...this.objects[ASSET_MODE_ARCH],
      ] //should optimise this line
    } else {
      return this.objects[type]
    }
  }
  clearObjectsByType() {
    this.objects = {
      [ASSET_MODE_TCIN]: [],
      [ASSET_MODE_PROP]: [],
      [ASSET_MODE_GALLERY]: [],
      [ASSET_MODE_INFRA]: [],
      [ASSET_MODE_ARCH]: [],
    }
  }
  getAsset(asset) {
    return {
      id: asset.uuid,
      name: asset.name,
      position: asset.position,
      rotation: {
        _x: radToAng(asset.rotation._x),
        _y: radToAng(asset.rotation._y),
        _z: radToAng(asset.rotation._z),
      },
      scale: asset.scale,
      isGrouped: asset.isGrouped,
      url: asset.url,
      assetType: asset.assetType,
      visible: asset.visible,
      assetId: asset.assetId,
      locked: asset.locked,
      subAssetType: asset.subAssetType,
      isFlipped: asset.isFlipped,
      materialEditMetadata: asset.materialEditMetadata,
    }
  }

  checkCollision = (asset, direction) => {
    const DIFF_FACTOR = 0.5
    let selectBB = new THREE.Box3().setFromObject(asset)
    let boundingBoxArr = []

    let assets = [
      ...this.objects['TCIN'],
      ...this.objects['PROP'],
      ...this.objects['PID'],
      ...this.objects['ARCHITECTURE'],
    ].filter((item) => item.name !== asset.name)

    boundingBoxArr = assets.map((item) => ({
      box: new THREE.Box3().setFromObject(item),
      name: item.name,
      id: item.assetId,
    }))
    let intersectObj = {}
    boundingBoxArr.forEach(({ box, name, id }) => {
      if (box.intersectsBox(selectBB)) {
        let intersect = box.intersect(selectBB)
        let { x: minX, y: minY } = intersect.min
        let { x: maxX, y: maxY } = intersect.max
        let diffX = Math.abs(Number(maxX.toFixed(4)) - Number(minX.toFixed(4)))
        let diffY = Math.abs(Number(maxY.toFixed(4)) - Number(minY.toFixed(4)))

        let isValidIntersect = false
        if (direction === 'left' || direction === 'right') {
          isValidIntersect = diffX > DIFF_FACTOR ? true : false
        } else if (direction === 'top' || direction === 'bottom') {
          isValidIntersect = diffY > DIFF_FACTOR ? true : false
        }

        if (isValidIntersect) {
          intersectObj[id] = name
        }
      }
    })
    if (Object.keys(intersectObj).length) {
      return {
        collided: true,
        assetName: asset.name,
        intersects: intersectObj,
      }
    } else {
      return { collided: false, assetName: asset.name }
    }
  }

  tileAssetToDirection = (direction, duplicateAsset, asset, newposition) => {
    let plane = this.findTilePlane(asset)
    if (direction === 'left') {
      duplicateAsset.position.set(
        plane === 'XY'
          ? asset.position.x - newposition - 0.001
          : asset.position.x,
        asset.position.y,
        plane === 'YZ'
          ? asset.position.z + newposition + 0.001
          : asset.position.z
      )
    }
    if (direction === 'right') {
      duplicateAsset.position.set(
        plane === 'XY'
          ? asset.position.x + newposition + 0.001
          : asset.position.x,
        asset.position.y,
        plane === 'YZ'
          ? asset.position.z - newposition - 0.001
          : asset.position.z
      )
    }
    if (direction === 'top') {
      duplicateAsset.position.set(
        asset.position.x,
        asset.position.y + newposition + 0.001,
        asset.position.z
      )
    }
    if (direction === 'bottom') {
      duplicateAsset.position.set(
        asset.position.x,
        asset.position.y - newposition - 0.001,
        asset.position.z
      )
    }

    return this.checkCollision(duplicateAsset, direction)
  }

  findTilePlane = (asset) => {
    const assetBox = new THREE.Box3().setFromObject(asset)

    const { x: sizeX, z: sizeZ } = assetBox.getSize(new THREE.Vector3())
    let minVal = Math.min(sizeX, sizeZ)
    let plane = minVal === sizeZ ? 'XY' : 'YZ'
    return plane
  }
}
