import React from 'react'
import { styled, alpha } from '@mui/material/styles'

import { withStyles } from '@mui/styles'
import { Menu, MenuItem, Drawer } from '@mui/material'
import { Typography, CustomButton } from 'cgi-ui-components'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    width: 1120,
    padding: 15,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  left: { alignItems: 'center', paddingTop: 5 },
  right: {
    marginLeft: 'auto',
  },
})

const StyledMenu = styled((props) => (
  <Menu
    elevation={3}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

class ExplorerFooter extends React.Component {
  state = {
    anchorEl: null,
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  render() {
    const {
      classes,

      selectedItems = {},
      footerOptions: {
        buttonType = 'simple',
        buttonLabel = 'Add',
        buttonAction = () => {},
        options = [],
      } = {
        buttonType: 'simple',
        buttonLabel: 'Add',
        buttonAction: () => {},
        options: [{ name: '', action: () => {} }],
      },
      deSelectAssets = () => {},
    } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <Drawer
        anchor={'bottom'}
        variant="permanent"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={true}
      >
        <div className={classes.root}>
          <div className={classes.left}>
            <Typography
              variant={'body2'}
              label={Object.keys(selectedItems).length + ' Assets selected'}
            ></Typography>
          </div>
          <div className={classes.right}>
            <CustomButton
              label="Deselect All"
              variant="text"
              color={'tertiary'}
              onClick={deSelectAssets}
            />
            &nbsp; &nbsp; &nbsp;
            {buttonType === 'simple' ? (
              <CustomButton
                label={buttonLabel}
                onClick={() => {
                  buttonAction(selectedItems)
                }}
              />
            ) : (
              <>
                <CustomButton
                  label={buttonLabel}
                  onClick={this.handleClick}
                  endIcon={<KeyboardArrowUpIcon />}
                />
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={this.handleClose}
                >
                  {options.map(({ name, action, disabled }, key) => {
                    return (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          this.handleClose()
                          action(Object.values(selectedItems))
                        }}
                        disableRipple
                        disabled={disabled}
                      >
                        {name}
                      </MenuItem>
                    )
                  })}
                </StyledMenu>
              </>
            )}
          </div>
        </div>
      </Drawer>
    )
  }
}

export default withStyles(styles)(ExplorerFooter)
