import React from 'react'
import { withStyles } from '@mui/styles'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import SpaceController from '../SpaceController'
import EditScene from './EditScene'
import { kFormatter } from '../../helpers/utils'
import config from '../../config/config'

const styles = (theme) => ({
  root: {
    height: 40,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.tertiary.contrastText,
    zIndex: 10,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    color: theme.palette.primary.darkFont,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  cordBar: {
    height: 21,

    minWidth: 450,
    marginTop: 8,
    flex: 1,
  },
  textBox: {
    height: 21,
    width: 40,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    textAlign: 'center',
    borderRadius: 3,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  text: {
    display: 'inline',
    margin: '10px 2px  10px 24px',
    fontSize: 14,

    color: 'rgba(0, 0, 0, 0.87)',
  },
  axisContainer: {
    flex: 1,
  },
  editSceneContainer: {
    flex: 1,
  },
  polyCount: {
    minWidth: 100,
    margin: 11,
    color: `rgba(0, 0, 0, 0.6)`,
  },
})

export class StatusBar extends React.Component {
  onElementChange = (x, y, z) => {
    const { updateCoords } = this.props
    updateCoords(x, y, z)
  }

  render() {
    const {
      classes,
      position: { x, y, z },
      disabled = false,
      handle3DSpaceChange,
      current3DAxis,
      viewMode = '3D',
      sceneMode = 'translate',
      scenePolyCount = 0,
    } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.axisContainer}>
          <SpaceController
            handle3DSpaceChange={handle3DSpaceChange}
            current3DAxis={current3DAxis}
          />
        </div>

        <div className={classes.cordBar}>
          {!disabled && (
            <>
              <div className={classes.text}>
                <label htmlFor="xCord">X </label>
                <input
                  className={classes.textBox}
                  type="number"
                  name="xCord"
                  value={parseInt(x)}
                  onChange={(e) => {
                    this.onElementChange(
                      parseInt(e.currentTarget.value || ''),
                      y,
                      z
                    )
                  }}
                  disabled={
                    disabled || (viewMode === '2D' && sceneMode === 'rotate')
                  }
                />
              </div>
              <div className={classes.text}>
                <label htmlFor="yCord">Y </label>
                <input
                  className={classes.textBox}
                  type="number"
                  name="yCord"
                  value={parseInt(y)}
                  onChange={(e) => {
                    this.onElementChange(
                      x,
                      parseInt(e.currentTarget.value || ''),
                      z
                    )
                  }}
                  disabled={
                    disabled || (viewMode === '2D' && sceneMode === 'translate')
                  }
                />
              </div>
              <div className={classes.text}>
                <label htmlFor="zCord">Z </label>
                <input
                  className={classes.textBox}
                  type="number"
                  name="zCord"
                  value={parseInt(z)}
                  onChange={(e) => {
                    this.onElementChange(
                      x,
                      y,
                      parseInt(e.currentTarget.value || '')
                    )
                  }}
                  disabled={
                    disabled || (viewMode === '2D' && sceneMode === 'rotate')
                  }
                />
              </div>
            </>
          )}
        </div>
        <div className={classes.editSceneContainer}>
          <EditScene {...this.props} />
        </div>

        {/* // Scene polygon count indicator to be enabled when BE completes
        {config.env !== 'prod' && (
          <div className={classes.polyCount}>
            Polygon Count: {kFormatter(scenePolyCount)}
          </div>
        )} */}
      </div>
    )
  }
}

StatusBar.propTypes = {
  classes: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
}
export default withStyles(styles)(StatusBar)
