import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { loadCSS } from 'fg-loadcss'
import Notifications from '../../components/Notifications/Notifications'
import { showNotification } from '../../store/notification/actionCreator'
import TemplateEditorHeader from '../../components/editorHeader/templateEditorHeader'
import SideMenu from '../../components/drawer/SideMenu'

import { OverlayLoader } from '../../components/Loaders'
import ErrorOverlay from '../../components/Overlay/ErrorOverlay'
import ReportAnIssueModal from '../../components/reportAnIssue/reportAnIssueModal'
import PageNotFound from '../../components/PageNotFound/PageNotFound'

import DesignSpace, {
  addTemplate,
  performActionOnScene,
  setSceneCanEdit,
  getCameraPositions,
  getZoomFactor,
  setZoomFactor,
  updateAssetCoords,
  setCameraClipNear,
  setCameraClipFar,
  getCameraClipNear,
  getCameraClipFar,
  setCameraFocalLength,
  getCameraFocalLength,
  getUndoRedoButtonStatus,
  loadScene,
  updateAssetScale,
  applyCameraSettings,
  updateCameraPosInScene,
  reloadFailedData,
  changeLocalWorldSpace,
  saveTemplate,
} from '../../components/designSpace'
import {
  toggleTemplateModal,
  fetchTemplates,
  fetchTemplateDetails,
  clearTemplateThumbnailData,
  setTemplateEditMode,
} from '../../store/template/actionCreator'
import {
  fetchExperienceDetail,
  clearExperience,
} from '../../store/experience/ActionCreator'

import DrawerRight from '../../components/drawerRight'
import apiConfig from '../../config/config'
import { clearCartData, addCartDataRedux } from '../../store/cart/ActionCreator'
import {
  clearBoardData,
  getTemplateAssets,
  addTcinToBoard,
} from '../../store/board/actionCreator'
import { getActionByKey } from '../../helpers/keyboard'
import {
  SCENE_SAVE,
  SCENE_SAVE_AS,
  SCENE_ESCAPE,
  SCENE_MOVE,
  SCENE_ROTATE,
  SCENE_DELETE,
  SCENE_DUPLICATE,
  SCENE_HIDE,
  SCENE_SHOW,
  SCENE_SHOW_ALL,
  SCENE_GROUP,
  SCENE_UNGROUP,
  SCENE_SELECT,
  SCENE_UPDATE_GROUP,
  SCENE_GROUP_HIDE_SHOW,
  SCENE_NEW,
  SCENE_OPEN,
  SCENE_VIEW_2D,
  SCENE_VIEW_3D,
  RENDER_RIGHT_DRAWER,
  RENDER_HEADER_HEIGHT,
  SCENE_UNDO,
  SCENE_REDO,
  REPLACE_ASSET,
  SCALE_ASSET,
  FOCUS_ASSET,
  LOCK_ASSET,
  GROUP_RENAME,
  FLIP_HORIZONTAL,
} from '../../constants/scene'

import {
  LOADING_TEMPLATE_TO_SCENE,
  ERROR_MSG_FOR_LOADING_TEMPLATE_TO_SCENE,
} from '../../constants/displayMessages'
import {
  canUserWrite,
  radToAng,
  isMemberOfAppArtAdmin,
} from '../../helpers/utils'
import StatusBar from '../../components/statusBar/'

import TemplateErrorConfirmationPopUp from '../../components/modal/TemplateErrorConfirmationPopUp'
import SpaceController from '../../components/SpaceController'
import { DEFAULT_NOISE, DEFAULT_TIME } from '../../constants/common'
import withRouter from '../../components/HOC/withRouterHOC'

export class TemplateEditor extends React.Component {
  constructor(props) {
    super(props)
    const {
      match: { params: { id = 'new' } = {} } = {},
      location: { search } = {},
    } = props
    const qParams = new URLSearchParams(search)

    const expId = qParams.get('expId')

    const isRenderMode = qParams.get('rendermode')

    this.state = {
      sceneId: '',
      templateId: id,
      showOverlayLoader: false,
      saveStatus: '',
      userHaveWriteAccess: true,
      viewMode: '3D',
      currentCameraPosition: {},
      currentCameraTarget: {},
      currentAssetPosition: { x: 0, y: 0, z: 0 },
      currentAssetScale: { x: 0, y: 0, z: 0 },
      statusBarDisabled: true,
      isRenderMode: isRenderMode,

      loaderErrorType: '',
      loaderDisplayText: '',
      currentCameraAngles: {},
      assetsLoading: false,
      saveSceneFile: false,
      undoRedoButtonStatus: {
        activateUndo: false,
        activateRedo: false,
      },
      copyScene: expId !== null ? expId : '',
      settingsDisabled: true,
      isHighLowAvail: true,
      showWarningDialog: false,
      sceneDataReport: {},
      downloadableReport: { headers: [], data: [] },
      firstRender: 0,
      isRightCartOpen: false,
      isPreviewPage: false,
      sceneCamera: {},
      addCameraSettings: false,
      sceneLoadingFailed: false,
      retryCount: 0,
      openSupportPage: false,
      current3DAxis: 'world',
    }
    // Bind keydown and keyup event to class function
    document.addEventListener('keydown', this.handleMultiKeyPress, false)
    document.addEventListener('keyup', this.handleMultiKeyPress, false)
    document.addEventListener('addedAsset', this.onAddorDelete, false)
    window.addEventListener('resize', this.onWindowResize)
    if (id === 'new') {
      props.clearExperience()
    }
    this.resizeElem = React.createRef()
    this.interactable = null
  }

  onWindowResize = () => {}
  onAddorDelete = (data) => {
    this.saveTemplateScene(data)
  }

  updateCoordState = (x, y, z, update = true, disabled = false) => {
    this.setState({
      currentAssetPosition: { x, y, z },
      statusBarDisabled: disabled,
    })
    if (update) {
      updateAssetCoords(x || 0, y || 0, z || 0)
    }
  }
  updateScaleState = (
    scale = {},
    update = true,
    disabled = false,
    addToStack = false
  ) => {
    const { x = 1, y = 1, z = 1 } = scale
    this.setState(
      {
        currentAssetScale: { x, y, z },
      },
      () => {
        if (update) {
          updateAssetScale(x, y, z, addToStack)
        }
      }
    )
  }
  updateCameraPos = (position, update = false) => {
    const { isRenderMode } = this.state
    if (isRenderMode) {
      this.setState(
        {
          currentCameraPosition: position,
        },
        () => {
          if (update) {
            updateCameraPosInScene(position)
          }
        }
      )
    }
  }

  updateCameraTarget = (position, update) => {
    const { isRenderMode } = this.state
    if (isRenderMode) {
      this.setState({
        currentCameraTarget: position,
      })
    }
  }
  updateZoomFactor = () => {}
  updateCameraAngles = (value) => {
    const { isRenderMode } = this.state
    if (isRenderMode) {
      this.setState({
        currentCameraAngles: {
          _x: radToAng(value._x),
          _y: radToAng(value._y),
          _z: radToAng(value._z),
        },
      })
    }
  }

  updateCameraFocalLength = (value, update) => {}

  update3DAxis = (axis) => {
    this.setState({ current3DAxis: axis })
  }

  componentDidUpdate = (prevProps) => {
    const {
      match: { params: { id = 'new' } = {} } = {},
      experienceDetail: { initiator, members, template_id },
      fetchTemplateDetails = () => {},
    } = this.props
    if (template_id !== prevProps.experienceDetail.template_id) {
      fetchTemplateDetails(template_id)
    }

    if (id !== prevProps.match.params.id) {
      this.setState({ templateId: id })
      this.setWriteAccess(initiator, members)
    }
  }

  setWriteAccess = (initiator, members) => {
    const userHaveWriteAccess = canUserWrite(
      this.props.auth.lanId,
      initiator,
      members
    )
    setSceneCanEdit(userHaveWriteAccess)
    this.setState({ userHaveWriteAccess })
  }

  componentWillUnmount() {
    const {
      clearCartData,
      clearBoardData,
      clearTemplateThumbnailData,
      setTemplateEditMode,
    } = this.props
    // Unbind keydown and keyup event to class function
    document.removeEventListener('keydown', this.handleMultiKeyPress, false)
    document.removeEventListener('keyup', this.handleMultiKeyPress, false)
    document.removeEventListener('addedAsset', this.onAddorDelete, false)
    clearCartData()
    clearBoardData() //clear board reducer
    clearTemplateThumbnailData()
    setTemplateEditMode(false)
  }

  componentDidMount() {
    const { templateId } = this.state

    this.props.setTemplateEditMode(true, templateId)
    this.setState({ zoomFactor: getZoomFactor() })

    loadCSS(
      apiConfig.fontAwesomeUrl,
      document.querySelector('#font-awesome-css')
    )

    if (templateId !== ':id') {
      this.loadTemplateData(templateId)
    } else {
      window.location.href = '/'
    }

    document.addEventListener(
      'onSceneChange',
      (event) => {
        this.props.addCartDataRedux(event.detail)
        this.setState({ undoRedoButtonStatus: getUndoRedoButtonStatus() })
      },
      false
    )
  }

  loadTemplateData = (templateId) => {
    const { fetchTemplateDetails, getTemplateAssets } = this.props
    if (templateId) {
      getTemplateAssets(templateId)
      fetchTemplateDetails(
        templateId,
        ({
          template_draco_loc: templateUrl,
          scene_data: sceneData,
          is_updated,
        }) => {
          this.loadTemplate(
            templateId,
            templateUrl,
            { scene_data: sceneData },
            undefined,
            undefined,
            true,
            is_updated
          )
        }
      )
    }
  }

  loadTemplate = (
    templateId,
    templateDracoLoc,
    scene,
    replaceTemplate = false,
    isHighLowAvailable = true,
    applyWorldRotationOnAssets = true,
    is_updated = false
  ) => {
    if (templateId) {
      this.setState({
        showOverlayLoader: true,
        loaderDisplayText: LOADING_TEMPLATE_TO_SCENE,
        assetsLoading: false,
        sceneDataReport: scene.scene_data,
      })
      addTemplate(templateDracoLoc, templateId, replaceTemplate)
        .then(() => {
          this.setState({
            showOverlayLoader: false,
          })

          if (scene.scene_data && scene.scene_data.assets) {
            const { sceneId } = this.state
            let payload = {
              experience_id: sceneId,
              template_id: templateId,
              assets: scene.scene_data.assets.map(
                ({ assetType = 'TCIN', assetId = '', name }) => ({
                  asset_type: assetType === 'custom' ? 'TCIN' : assetType,
                  value: assetId !== '' ? assetId : name,
                })
              ),
            }
            this.props.addTcinToBoard(payload)
          }
          this.setState({
            isHighLowAvail: isHighLowAvailable,
          })
          loadScene(scene, applyWorldRotationOnAssets, is_updated)
            .then(() => {
              //this.performAction(SCENE_SAVE)
              this.setState({ showWarningDialog: true })
            })
            .catch(({ type }) => {
              if (type == 'assetError') {
                this.setState({ sceneLoadingFailed: true })
                // showNotification(
                //   true,
                //   'Error in fetching assets into scene, Please refresh the page',
                //   'error'
                // )
              }
            })
        })
        .catch(() => {
          this.setState({
            showOverlayLoader: true,
            loaderErrorType: 'failed',
            loaderDisplayText: ERROR_MSG_FOR_LOADING_TEMPLATE_TO_SCENE,
          })
        })
    }
  }

  handleMultiKeyPress = (event) => {
    let canPerformAction =
      (event.target || {}).type !== 'text' &&
      (event.target || {}).type !== 'textarea'
    if ((event.target || {}).type === 'number') {
      var letters = /^[a-z]$/i
      let inputtxt = event.key || ''
      canPerformAction = inputtxt.match(letters) ? true : false
    }
    if (canPerformAction) {
      const sceneAction = getActionByKey(event)
      if (sceneAction) {
        event.preventDefault()
        this.performAction(sceneAction)
      }
    }
  }

  saveTemplateScene = () => {
    saveTemplate()
      .then((resp) => {
        //Refresh board
        const scene_data = resp?.data?.scene_data
        const assets = scene_data?.assets

        if (assets) {
          const { templateId } = this.state
          let payload = {
            template_id: templateId,
            assets: assets.map(
              ({ assetType = 'TCIN', assetId = '', name, subAssetType }) => {
                const boardAsset = {
                  asset_type: assetType === 'custom' ? 'TCIN' : assetType,
                  value: assetId !== '' ? assetId : name,
                }
                if (subAssetType) {
                  boardAsset.sub_asset_type = subAssetType
                }
                return boardAsset
              }
            ),
          }
          this.props.addTcinToBoard(payload)
        }
      })
      .catch((e) => {
        console.log('Error in saving template')
      })
  }

  performAction = (
    sceneAction,
    callback = () => {},
    currentCameraPosition = {},
    assets,
    groupObj
  ) => {
    const {} = this.state
    const {
      cartData: {},
    } = this.props
    const {
      auth: { accessToken = '', email = '', lanId = '' },
    } = this.props
    switch (sceneAction) {
      case REPLACE_ASSET:
        performActionOnScene({ sceneAction: sceneAction }, assets, true)
        break
      case SCENE_MOVE:
      case SCENE_ROTATE:
      case SCENE_SELECT:
      case SCENE_ESCAPE:
      case SCENE_DELETE:
      case SCENE_GROUP:
      case SCENE_UNGROUP:
      case SCENE_UPDATE_GROUP:
      case SCENE_DUPLICATE:
      case SCENE_HIDE:
      case SCENE_SHOW:
      case FOCUS_ASSET:
      case LOCK_ASSET:
      case SCENE_GROUP_HIDE_SHOW:
      case SCALE_ASSET:
      case FLIP_HORIZONTAL:
      case GROUP_RENAME:
        performActionOnScene(
          { sceneAction: sceneAction },
          assets,
          () => {},
          groupObj
        )
        break
      case SCENE_SHOW_ALL:
      case SCENE_UNDO:
      case SCENE_REDO:
        performActionOnScene({ sceneAction: sceneAction })
        break
      case SCENE_SAVE:
        this.saveTemplateScene()
        break
      case SCENE_VIEW_3D:
      case SCENE_VIEW_2D:
        performActionOnScene({
          sceneAction: sceneAction,
          cameraPosition: currentCameraPosition,
        })
        break

      default:
        break
    }
    callback()
  }

  navigate = (url) => {
    this.props.history.push(url)
  }

  toggleView =
    (view = '3D') =>
    () => {
      const currentCameraPosition = getCameraPositions()
      this.setState((state) => {
        this.performAction(
          view === '3D' ? SCENE_VIEW_3D : SCENE_VIEW_2D,
          () => {},
          state.currentCameraPosition
        )
        return {
          viewMode: view,
          currentCameraPosition: currentCameraPosition,
        }
      })
    }

  closeDeleteDialog = () => {
    this.setState({
      isHighLowAvail: true,
      sceneDataReport: {},
    })
  }

  handleDowloadReport = (e) => {
    const { sceneDataReport } = this.state
    let data = []
    let headers
    let assetsList =
      sceneDataReport &&
      sceneDataReport !== null &&
      sceneDataReport.assets &&
      sceneDataReport.assets !== null
        ? sceneDataReport.assets
        : []
    if (assetsList.length) {
      headers = [
        { label: 'TCIN / Purchased Asset', key: 'assetId' },
        { label: 'Asset Type', key: 'assetType' },
        { label: 'High Poly Available', key: 'hp' },
        { label: 'Low Poly Available', key: 'lp' },
      ]
      assetsList.forEach((element) => {
        data.push({
          assetId: element.assetId,
          assetType: element.assetType,
          hp: element.hpAvailable ? 'TRUE' : 'FALSE',
          lp: element.lpAvailable ? 'TRUE' : 'FALSE',
        })
      })
    }

    const downloadableReport = { headers, data }

    this.setState({ downloadableReport })

    return downloadableReport
  }
  setIsRightCartOpen = (value) => {
    this.setState({ isRightCartOpen: value })
  }

  resetCameraSettings = (templateDefaultValue = true) => {
    const {
      currentTemplateDetails: {
        scene_data: { camera: templateCamera = {} } = {},
      },
    } = this.props
    applyCameraSettings(templateCamera)
  }
  manageSceneError = () => {
    const { retryCount, isHighLowAvail } = this.state
    if (retryCount < 3) {
      this.setState({
        retryCount: retryCount + 1,
        assetsLoading: true,
        sceneLoadingFailed: false,
      })
      reloadFailedData()
        .then(() => {
          this.setState({
            assetsLoading: false,
            showWarningDialog: !isHighLowAvail,
          })
        })
        .catch(({ type }) => {
          if (type === 'assetError') {
            this.setState({ sceneLoadingFailed: true })
          }
        })
    } else {
      this.setState({ openSupportPage: true, sceneLoadingFailed: false })
    }
  }

  closeSupportForm = () => {
    this.setState({ openSupportPage: false, sceneLoadingFailed: true })
  }

  handle3DSpaceChange = (space) => {
    changeLocalWorldSpace(space)
  }

  render() {
    const {
      auth,
      experienceDetail,
      experienceDetail: { experience_name: sceneName },
      match,
      history,
      isRightDrawerOpen,
      activeOperations,
      cartData: { toggleReportIssueModal = false },
    } = this.props
    const {
      sceneId,
      showOverlayLoader,
      saveStatus,
      viewMode,
      currentAssetPosition,
      statusBarDisabled,
      userHaveWriteAccess,

      loaderErrorType,
      loaderDisplayText = LOADING_TEMPLATE_TO_SCENE,
      assetsLoading,
      undoRedoButtonStatus,
      copyScene,
      currentAssetScale,
      isHighLowAvail,
      showWarningDialog,
      downloadableReport,
      isRightCartOpen,

      sceneLoadingFailed,
      retryCount,

      current3DAxis,
      templateId,
    } = this.state
    const isAppArtAdmin = isMemberOfAppArtAdmin(auth.memberOf)
    if (isAppArtAdmin) {
      return (
        <>
          <Helmet defaultTitle="StyleHub" titleTemplate="%s - StyleHub" />
          <Notifications />

          <>
            <TemplateEditorHeader
              auth={auth}
              match={match}
              history={history}
              saveStatus={saveStatus}
              experienceDetail={experienceDetail}
              saveScene={() => this.performAction(SCENE_SAVE)}
              viewMode={viewMode}
              toggleView={this.toggleView}
              setRenderMode={() => {}}
              assetsLoading={assetsLoading}
              undoAction={() => this.performAction(SCENE_UNDO)}
              redoAction={() => this.performAction(SCENE_REDO)}
              handleAssetReplacement={() => this.performAction(REPLACE_ASSET)}
              undoRedoButtonStatus={undoRedoButtonStatus}
              loadTemplate={this.loadTemplate}
              navigate={this.navigate}
              copyScene={copyScene}
              resetCamera={this.resetCameraSettings}
              saveTemplate={this.saveTemplateScene}
            />

            <DrawerRight
              sceneId={sceneId}
              handleSelectedTcins={this.handleSelectedTcins}
              handleCartActions={this.performAction}
              userHaveWriteAccess={true}
              isRightDrawerOpen={isRightDrawerOpen}
              activeOperations={activeOperations}
              initialScale={{ ...currentAssetScale }}
              updateScaleState={this.updateScaleState}
              setIsRightCartOpen={this.setIsRightCartOpen}
              templatePage
            />
            <SideMenu
              navigate={this.navigate}
              userHaveWriteAccess={userHaveWriteAccess}
              isTemplateEdit={true}
              templateId={templateId}
            />

            <StatusBar
              position={currentAssetPosition}
              updateCoords={this.updateCoordState}
              disabled={statusBarDisabled}
              handle3DSpaceChange={this.handle3DSpaceChange}
              current3DAxis={current3DAxis}
              viewMode={viewMode}
              assetsLoading={assetsLoading}
              saveStatus={saveStatus}
              isTemplateEdit={true}
            />

            {showOverlayLoader ? (
              <OverlayLoader
                displayText={loaderDisplayText}
                loaderErrorType={loaderErrorType}
              />
            ) : null}
            <ErrorOverlay
              isOpen={sceneLoadingFailed}
              retryCount={retryCount}
              navigate={this.navigate}
              sceneName={sceneName}
              buttonAction={this.manageSceneError}
            />
          </>

          <DesignSpace
            sceneId={sceneId}
            handleSceneAction={this.performAction}
            updateCoords={this.updateCoordState}
            updateScale={this.updateScaleState}
            updateCameraPos={this.updateCameraPos}
            updateCameraTarget={this.updateCameraTarget}
            updateZoomFactor={this.updateZoomFactor}
            updateCameraAngles={this.updateCameraAngles}
            updateCameraFocalLength={this.updateCameraFocalLength}
            isRenderMode={false}
            isRightCartOpen={isRightCartOpen}
            isPreviewPage={false}
            update3DAxis={this.update3DAxis}
          />
          {!isHighLowAvail && showWarningDialog && (
            <TemplateErrorConfirmationPopUp
              handleDownload={this.handleDowloadReport}
              handleClose={this.closeDeleteDialog}
              handleConfirm={this.closeDeleteDialog}
              open={!isHighLowAvail}
              descriptionText="One or more assets in the selected template have high or low poly missing and will not be available for styling"
              helpingText="Download report to know more"
              confirmText="CONTINUE"
              cancelText="DOWNLOAD REPORT"
              headers={downloadableReport.headers || []}
              data={downloadableReport.data || []}
            />
          )}
          <ReportAnIssueModal show={toggleReportIssueModal} />
        </>
      )
    } else {
      return <PageNotFound />
    }
  }
}

TemplateEditor.propTypes = {
  auth: PropTypes.object,
  experienceDetail: PropTypes.object,
  templateList: PropTypes.object,
  cartData: PropTypes.object,
  clearCartData: PropTypes.func,
  clearExperience: PropTypes.func,
  fetchExperienceDetail: PropTypes.func,
  toggleTemplateModal: PropTypes.func,
  fetchTemplates: PropTypes.func,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  experienceDetail: state.experiences.experience,
  templateList: state.templates.templateList,
  cartData: state.cart,
  isRightDrawerOpen: state.Board.isRightDrawerOpen,
  activeOperations: state.activeOperations,
  currentTemplateDetails: state.templates.currentTemplateDetails,
})

export default connect(mapStateToProps, {
  fetchExperienceDetail,
  toggleTemplateModal,
  fetchTemplates,
  showNotification,
  clearCartData,
  clearBoardData,
  clearExperience,
  addCartDataRedux,
  getTemplateAssets,
  addTcinToBoard,
  fetchTemplateDetails,
  clearTemplateThumbnailData,
  setTemplateEditMode,
})(withRouter(TemplateEditor))
