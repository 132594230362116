import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { withStyles } from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  Chip,
  CircularProgress,
} from '@mui/material'

import dropDownIcon from '../../images/assetIcons/default/dropdown_arrow.svg?url'
import { getDisplayDate } from '../../helpers/dataFormats'
import { mentionsParser } from '../../helpers/utils'
import { fetchRenditionImage } from '../../store/customCard/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import { saveCommentId } from '../../store/comments/ActionCreator'
import { FILE_DOWNLOAD_FAILED } from '../../constants/displayMessages'
import {
  POST_TO_RENDER_COMMENTS,
  ALL,
  ART_DIRECTOR,
} from '../../constants/common'

import CustomAvatar from '../avatar'
import DownloadableLink from '../DownloadLink/downloadLink'
import NewComment from '../ImagePreview/newComment'
import config from '../../config/config'

import { CustomButton, CustomIconButton } from 'cgi-ui-components'

const styles = (theme) => ({
  customClasses: {
    height: 40,
    width: 40,
    backgroundColor: theme.palette.primary.targetRed,
    color: theme.palette.primary.contrastText,
    float: 'left',
    fontSize: 15,
    marginLeft: 'unset',
  },
  customClassesReply: {
    height: '30px !important',
    width: '30px !important',
    backgroundColor: theme.palette.primary.targetRed,
    color: theme.palette.primary.contrastText,
    float: 'left',
    fontSize: '15px !important',
    marginLeft: 'unset',
  },
  textPadding: {
    padding: '5px 0px',
  },
  fontsize: {
    fontSize: '0.85rem',
  },
  loader: {
    paddingTop: 50,
    width: 40,
    margin: 'auto',
  },
  noComments: {
    width: '80%',
    margin: 'auto',
    padding: 50,
    textAlign: 'center',
  },
  noCommentsMsg: {
    fontWeight: '400',
    color: theme.palette.primary.gray,
  },
  editedTag: {
    marginTop: 12,
  },
  listItemRoot: {
    paddingBottom: '0px !important',
    paddingRight: '0px !important',
  },
  listItemRootReply: {
    width: '85% !important',
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
    paddingRight: '0 !important',
    marginLeft: 55,
  },
  commentText: {
    padding: '0px 5px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.lightBlueBackground} !important`,
    },
  },
  avtarRoot: {
    minWidth: '40px !important',
  },
  avtarRootReply: {
    minWidth: '56px !important',
  },
  hideShowReply: {
    marginLeft: 70,
    cursor: 'pointer',
  },
  hideShowText: {
    marginLeft: 10,
    color: theme.palette.primary.linkColor,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
  },
  arrow: {
    filter:
      'invert(33%) sepia(85%) saturate(1115%) hue-rotate(198deg) brightness(93%) contrast(90%)',
    width: 10,
    height: 10,
  },
  editWrapper: {
    width: '83% !important',
    float: 'right',
  },
  postContainer: {
    margin: '0 20px',
    paddingBottom: 10,
  },
  commentListWrapper: {
    paddingRight: '20px !important',
    paddingTop: 0,
    overflowY: 'auto',
    // maxHeight: `calc(100vh -  400px)`, // RENDER_HEADER_HEIGHT}
  },
})

class CommentList extends React.Component {
  state = {
    anchorEl: null,
    filterBy: [ART_DIRECTOR],
    repliesIndex: [],
  }
  scrollReferences = {}

  downloadImage = (file) =>
    new Promise((resolve, reject) => {
      const { showNotification, setFileDownloading } = this.props
      const axiosSource = axios.CancelToken.source()

      fetchRenditionImage(
        file.download_attachment_url
          ? `${config.renditionsUrl}${file.download_attachment_url}`
          : '',
        (response) => {
          const element = document.createElement('a')
          element.href = URL.createObjectURL(response)
          element.download = `${file.file_name}`
          element.click()
          resolve()
        },
        (error) => {
          showNotification(true, FILE_DOWNLOAD_FAILED, 'error')
          reject()
        },
        axiosSource.token
      )
    })

  getDisplayNameInAvatar = (item) => {
    let displayName = item.initiator_details
      ? item.initiator_details.first_name +
        ' ' +
        item.initiator_details.last_name
      : 'N A'
    return displayName
  }

  getLoginId = (item) => {
    const {
      initiator_details: { login_id },
    } = item
    return login_id
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleMenuClick = (e, value) => {
    const { filterBy } = this.state
    const shouldAddToChip = filterBy.indexOf(value) < 0

    this.setState({
      anchorEl: !shouldAddToChip ? null : e.currentTarget,
    })
    if (value && shouldAddToChip) {
      let newfilterByData = [...filterBy, value]
      this.setState({
        anchorEl: null,
        filterBy: newfilterByData,
      })
    }
  }

  refineComments = () => {
    const { commentsList = [] } = this.props
    const { filterBy } = this.state
    if (commentsList.length) {
      if (filterBy.includes(ALL)) {
        return commentsList
      } else {
        let newList = commentsList.filter(
          (item) =>
            item.send_to.filter((user) => filterBy.indexOf(user) > -1).length
        )
        return newList
      }
    } else {
      return commentsList
    }
  }

  handleChipDelete = (data) => {
    let { filterBy } = this.state
    let newfilterByData = filterBy.filter((value) => value !== data)
    if (newfilterByData.length == 0) {
      newfilterByData.push(ALL)
    }
    this.setState({
      filterBy: newfilterByData,
    })
  }

  onMouseOverAnnotation = (data) => {
    this.props.onMouseOverAnnotation(data)
  }

  onMouseOutAnnotation = (data) => {
    this.props.onMouseOutAnnotation(data)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.hoveredCommentId !== this.props.hoveredCommentId &&
      this.props.hoveredCommentId !== ''
    ) {
      //scroll to ref scrollReferences['ref-'+this.props.hoverCommentId]
      this.scrollReferences['ref-' + this.props.hoveredCommentId] &&
        this.scrollReferences[
          'ref-' + this.props.hoveredCommentId
        ].current.scrollIntoView()
    }
  }

  CommentItem = (props) => {
    const [showEdit, setShowEdit] = useState(false)
    const [showEditor, setShowEditor] = useState(false)
    const { classes = {}, imageDetails, lanId } = this.props
    const { hoverCommentId, render_comment_id, annotation_comment } = props
    const userCanEditMessage = props.initiator_details.login_id === lanId
    const myRef = useRef(hoverCommentId)

    this.scrollReferences['ref-' + render_comment_id] = myRef
    let output = mentionsParser(props.comments)

    return !showEditor ? (
      <>
        <ListItem
          alignItems="flex-start"
          onMouseEnter={() => {
            setShowEdit(true)
            !props.replyItems && this.onMouseOverAnnotation(props)
          }}
          onMouseLeave={() => {
            setShowEdit(false)
            !props.replyItems && this.onMouseOutAnnotation(props)
          }}
          classes={{
            root: props.replyItems
              ? classes.listItemRootReply
              : classes.listItemRoot,
          }}
          ref={myRef}
          data-testid={
            !props.replyItems ? 'listItem-comment' : 'listItem-reply'
          }
        >
          <>
            <ListItemAvatar
              classes={{
                root: props.replyItems
                  ? classes.avtarRoot
                  : classes.avtarRootReply,
              }}
            >
              <CustomAvatar
                isCard={false}
                customClasses={
                  props.replyItems
                    ? classes.customClassesReply
                    : classes.customClasses
                }
                data={{
                  display_name: this.getDisplayNameInAvatar(props),
                  login_id: this.getLoginId(props),
                }}
              />
            </ListItemAvatar>
            <ListItemText
              style={{
                backgroundColor:
                  showEdit ||
                  (annotation_comment && hoverCommentId === render_comment_id)
                    ? '#E7EDFA'
                    : '',
              }}
              className={classes.commentText}
              primary={this.getDisplayNameInAvatar(props)}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textSecondary"
                  >
                    {getDisplayDate(props.time_stamp)}
                  </Typography>
                  {props.edited && (
                    <div className={classes.editedTag}>(Edited)</div>
                  )}
                  <Typography
                    classes={{ body2: classes.textPadding }}
                    color="textPrimary"
                    variant="body2"
                  />
                  {output}
                  <Typography
                    classes={{ body1: classes.fontsize }}
                    color="primary"
                  >
                    {Object.values(props.attached_files).map((file) => {
                      if (file.file_name) {
                        return (
                          <DownloadableLink
                            key={file.fileId}
                            file={file}
                            downloadImage={this.downloadImage}
                            data-testid="downloadLink"
                          />
                        )
                      }
                    })}
                  </Typography>
                </React.Fragment>
              }
            />
            {showEdit && userCanEditMessage && (
              <CustomIconButton
                onClick={() => setShowEditor(true)}
                size="small"
                color="primary"
                label="Edit comment"
              >
                <ModeEditOutlineOutlinedIcon fontSize="small" />
              </CustomIconButton>
            )}
          </>
        </ListItem>
      </>
    ) : (
      <div className={props.replyItems ? classes.editWrapper : ''}>
        <NewComment
          isEditor={true}
          closeEditor={() => setShowEditor(false)}
          imageDetails={imageDetails}
          commentsData={props}
        />
      </div>
    )
  }
  handleMoreReplies = (index) => {
    const { repliesIndex } = this.state
    this.setState({
      repliesIndex: repliesIndex.includes(index)
        ? repliesIndex.filter((item) => item !== index)
        : [...repliesIndex, index],
    })
  }

  render() {
    const {
      classes,
      loadingComments,
      isAnnotationMode,
      hoveredCommentId,
      showAddCommentOptions,
    } = this.props
    const { anchorEl, filterBy, repliesIndex } = this.state
    const { CommentItem } = this

    const filteredList = (list) =>
      list &&
      list
        .map((item) => item)
        .sort((a, b) => new Date(a.time_stamp) - new Date(b.time_stamp))
        .slice(Math.max(list.length - 2, 0))

    const unfilteredList = (list) =>
      list &&
      list
        .map((item) => item)
        .sort((a, b) => new Date(a.time_stamp) - new Date(b.time_stamp))

    let refinedCommentList = this.refineComments()
    refinedCommentList = refinedCommentList.sort(
      (a, b) => new Date(b.time_stamp) - new Date(a.time_stamp)
    )
    refinedCommentList = refinedCommentList.filter((item) =>
      isAnnotationMode
        ? item.annotation_comment === true
        : item.annotation_comment === false
    )
    return (
      <>
        <div className={classes.postContainer}>
          <CustomButton
            endIcon={<ArrowDropDownIcon />}
            label="Filter"
            onClick={this.handleMenuClick}
            variant="outlined"
            color="inherit"
            sx={{ marginRight: '20px', fontWeight: 400 }}
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            onChange={this.handleChnage}
          >
            {POST_TO_RENDER_COMMENTS.map((to) => (
              <MenuItem
                value={to.value}
                onClick={(e) => this.handleMenuClick(e, to.value)}
                data-testid="postTo-options"
              >
                {to.name}
              </MenuItem>
            ))}
          </Menu>
          {filterBy.map((data) => (
            <Chip
              label={data}
              className={classes.chipStyle}
              onDelete={(e) => this.handleChipDelete(data)}
              data-testid={`chip-${data}`}
            />
          ))}
        </div>
        {!loadingComments && refinedCommentList.length > 0 ? (
          <List
            className={classes.commentListWrapper}
            style={{
              maxHeight: showAddCommentOptions
                ? 'calc(100vh - 380px)'
                : 'calc(100vh - 250px)',
            }}
          >
            {refinedCommentList.map((item, index) => {
              let replyCommentLength =
                item.reply_comments !== undefined
                  ? item.reply_comments.length
                  : 0
              let replyList = !repliesIndex.includes(index)
                ? filteredList(item.reply_comments)
                : unfilteredList(item.reply_comments)
              const { imageDetails } = this.props
              return (
                <>
                  <CommentItem
                    {...item}
                    index={index}
                    hoverCommentId={hoveredCommentId}
                  />
                  {replyCommentLength > 2 && (
                    <div
                      className={classes.hideShowReply}
                      onClick={() => this.handleMoreReplies(index)}
                      data-testid="moreIcon"
                    >
                      <img
                        style={{
                          transform: repliesIndex.includes(index)
                            ? 'rotate(0deg)'
                            : 'rotate(270deg)',
                        }}
                        className={classes.arrow}
                        src={dropDownIcon}
                      />
                      <span className={classes.hideShowText}>
                        {replyCommentLength === replyList.length
                          ? null
                          : replyCommentLength - replyList.length}
                        {replyCommentLength === replyList.length
                          ? 'HIDE REPLIES'
                          : ' MORE REPLIES'}
                      </span>
                    </div>
                  )}
                  {replyList &&
                    replyList.map((it) => (
                      <CommentItem {...it} replyItems={true} />
                    ))}
                  <NewComment
                    isEditor={false}
                    isReply={true}
                    imageDetails={imageDetails}
                    commentsData={item}
                  />
                </>
              )
            })}
          </List>
        ) : refinedCommentList.length === 0 && !loadingComments ? (
          <div className={classes.noComments}>
            <Typography className={classes.noCommentsMsg} variant="h6">
              No Comments Available
            </Typography>
          </div>
        ) : (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  const {
    comments: {
      commentsList,
      loadingComments,
      isAnnotationMode,
      hoveredCommentId,
    },
    auth: { lanId = '' } = {},
  } = state
  return {
    commentsList,
    loadingComments,
    lanId,
    isAnnotationMode,
    hoveredCommentId,
  }
}

const mapDispatchToProps = { showNotification, saveCommentId }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CommentList))
