import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import { showNotification } from '../../store/notification/actionCreator'
import {
  toggleReportIssueModal,
  selectCurrentTcin,
  saveReportAnIssue,
} from '../../store/cart/ActionCreator'

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '3px',
  },
  Modalbody: {
    padding: '15px 0px 5px 0px',
    backgroundColor: theme.palette.tertiary.contrastText,
  },
  heading: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 5,
  },
  subHeading: {
    fontSize: 12,
    color: theme.palette.primary.mediumGrey,
  },
  issue: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    marginBottom: 10,
    padding: '5px 15px',
    '&:hover': {
      background: theme.palette.primary.hoverGrey,
    },
  },
})

class ReportAnIssue extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTcin: {},
      issues: [
        {
          heading: 'Red Asset',
          subHeading: 'Assets dragged into scene editor appearing in red',
          value: 'RED_ASSET',
        },
        {
          heading: 'Missing thumbnail',
          subHeading: 'Missing thumbnail or any other isssus with thumbnail',
          value: 'MISSING_THUMBNAIL',
        },
        // {
        //   heading: 'Other issues',
        //   subHeading: 'Asset not appearing on 3D editor, pivot issues, etc.',
        //   value: 'others',
        // },
      ],
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      const { cart } = this.props
      let currentTcin = []
      if (cart?.scene_data?.assets) {
        currentTcin = cart.scene_data.assets.find((item) => item.selected)
      }
      if (!currentTcin && cart?.scene_data?.groups) {
        cart.scene_data.groups.map((item) => {
          currentTcin = item.assets.find((tcin) => tcin.selected)
        })
      }
      this.setState({
        currentTcin: cart.currentTcin || currentTcin,
      })
    }
  }
  handleClose = () => {
    const { toggleReportIssueModal, selectCurrentTcin } = this.props
    toggleReportIssueModal(false)
    selectCurrentTcin({})
    this.setState({
      currentTcin: {},
    })
  }
  handleClick = (value) => {
    const { showNotification, saveReportAnIssue } = this.props
    const { currentTcin } = this.state
    let assetType = currentTcin.assetType || currentTcin.asset_type
    let payload = {
      asset_id: currentTcin.assetId || currentTcin.tcin,
      asset_type: assetType === 'custom' ? 'TCIN' : assetType,
      sub_asset_type: currentTcin.subAssetType || currentTcin.sub_asset_type,
      issue_type: value,
    }
    saveReportAnIssue(payload, (status) => {
      if (status) {
        showNotification(
          'true',
          <span>
            &#127881; Incident reported to the StyleHub's Team. We're sorry you
            ran into an Issue!
          </span>,
          'success'
        )
      } else {
        showNotification(
          'true',
          <span>&#128532; Failed to report the Incident</span>,
          'error'
        )
      }
    })

    this.handleClose()
  }

  render() {
    const { show, classes } = this.props
    const { issues = [] } = this.state
    return (
      <Dialog
        open={show}
        onClose={this.handleClose}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.Modalbody}>
          {issues.map((item, key) => (
            <div
              key={key}
              className={classes.issue}
              onClick={() => this.handleClick(item.value)}
            >
              <span className={classes.heading}>{item.heading}</span>
              <span className={classes.subHeading}>{item.subHeading}</span>
            </div>
          ))}
        </div>
      </Dialog>
    )
  }
}
const mapStateToProps = (state) => ({
  cart: state.cart,
})

export default connect(mapStateToProps, {
  toggleReportIssueModal,
  selectCurrentTcin,
  showNotification,
  saveReportAnIssue,
})(withStyles(styles)(ReportAnIssue))
