import React from 'react'
import { makeStyles } from '@mui/styles'
import { Select, CustomButton, Typography } from 'cgi-ui-components'
import { SwatchButton } from './swatchButton'
import classNames from 'classnames'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  itemPadding: {
    padding: `7px 0px`,
  },
  lowerRightSection: {
    flexGrow: 1,
    padding: 10,
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  lrRoot: { height: '100%', overflow: 'hidden' },
  lrAction: {
    zIndex: 1,
    borderTop: '1px solid #E0E0E0',
    textAlign: 'center',
    padding: `10px 0px`,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    marginLeft: -10,
  },
  swatch: { height: 'calc(100% - 140px)', overflow: 'scroll' },
  loader: {
    paddingTop: 50,
    width: 40,
    margin: 'auto',
  },
}))

const ITEM_HEIGHT = 30
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

export const EditorLowerRightPanel = ({
  libraryList = [],
  variant,
  selectedLibrary,
  handleLibraryChange,
  handleSwatchButtonClick,
  selected,
  isLoadingLibDetails = false,
  handleReplace = () => {},
}) => {
  const classes = useStyles()

  const { library_details = [] } = selectedLibrary

  return (
    <div className={classes.lowerRightSection}>
      <div className={classes.lrRoot}>
        <div className={classes.lrRoot}>
          <Typography
            variant="caption"
            label={
              variant === 'color'
                ? '(Or) Select from the color libraries'
                : 'Select from the texture libraries'
            }
            style={{ margin: '5px 0px', fontWeight: 500 }}
          />

          <div className={classNames(classes.itemPadding, classes.lrRoot)}>
            <div>
              <Select
                color={'tertiary'}
                size="small"
                inputLabel="Select Library"
                value={selectedLibrary?.library_name}
                name={selectedLibrary?.library_name}
                onChange={(e) => {
                  handleLibraryChange(e.target.value)
                }}
                variant="filled"
                MenuProps={MenuProps}
                options={libraryList.map(({ library_name, library_id }) => ({
                  name: library_name,
                  value: library_id,
                  id: library_id,
                }))}
              />
            </div>

            <div className={classNames(classes.itemPadding, classes.swatch)}>
              {isLoadingLibDetails ? (
                <div className={classes.loader}>
                  <CircularProgress size={25} />
                </div>
              ) : (
                <div>
                  {library_details.map((item) => (
                    <SwatchButton
                      value={
                        variant === 'color'
                          ? item.color_hexcode
                          : item.texture_url
                      }
                      selected={
                        variant === 'color' ? selected : selected?.texture_url
                      }
                      handleClick={(value, data) =>
                        handleSwatchButtonClick(value, data)
                      }
                      variant={variant}
                      data={item}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.lrAction}>
        <CustomButton
          label="Confirm Replace"
          onClick={handleReplace}
          color="primary"
          variant="outlined"
          disabled={variant === 'texture' && selected === ''}
        />
      </div>
    </div>
  )
}

export default EditorLowerRightPanel
