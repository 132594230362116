import React from 'react'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'

import {
  Dialog,
  PageTitleBar,
  CustomIconButton,
  LinkComponent,
} from 'cgi-ui-components'
import { DialogContent, DialogActions, DialogTitle } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import classNames from 'classnames'

const styles = (theme) => {
  return {
    root: {
      margin: 0,
      padding: 16,
    },
    dialogAct: {
      margin: '0 8px 12px 0',
      padding: '40px !important',
    },
    dialogPaperDefault: {
      width: 'auto !important',
      minWidth: '500px !important',
      position: 'absolute',
      top: '10%',
    },
    formTitle: {
      display: 'flex',
      marginBottom: 10,
      justifyContent: 'space-between',
    },
    headerSecondaryAction: {
      display: 'flex',
      alignItems: 'center',
    },
  }
}

const DialogActionsWrapper = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 40,
  },
}))(DialogActions)

class DialogForm extends React.Component {
  render() {
    return <div>{this.renderingDialog()}</div>
  }

  renderingDialog() {
    const {
      classes,
      handleClose,
      openDialog,
      maxWidth = 'md',
      fullWidth = true,
      headerTitle,
      headerSubTitle,
      linkText,
      dialogFooterButtons,
    } = this.props

    return (
      <Grid>
        <Dialog
          onClose={() => handleClose(true)}
          open={openDialog}
          classes={{
            paper: classNames(classes.dialogPaperDefault),
          }}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle
            style={{
              lineHeight: 0,
              padding: 30,
            }}
          >
            <div style={{}} className={classes.formTitle}>
              <div>
                <PageTitleBar title={headerTitle} subTitle={headerSubTitle} />
                {linkText && !headerSubTitle && (
                  <LinkComponent
                    color="#3F51B5"
                    name={linkText}
                    underline="hover"
                    variant="body2"
                  />
                )}
              </div>
              <div className={classes.headerSecondaryAction}>
                {linkText && headerSubTitle && (
                  <LinkComponent
                    sx={{ padding: '20px' }}
                    color="#3F51B5"
                    name={linkText}
                    underline="hover"
                    variant="body2"
                  />
                )}
                <CustomIconButton
                  aria-label="close"
                  label="close"
                  onClick={() => handleClose(true)}
                >
                  <CloseIcon />
                </CustomIconButton>
              </div>
            </div>
          </DialogTitle>

          <DialogContent>{this.props.children}</DialogContent>
          <DialogActionsWrapper
            classes={{
              root: classes.dialogAct,
            }}
          >
            {dialogFooterButtons}
          </DialogActionsWrapper>
        </Dialog>
      </Grid>
    )
  }
}

export default withStyles(styles)(DialogForm)
