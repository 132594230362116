import React, { useState, useEffect } from 'react'
import { Typography } from 'cgi-ui-components'
import { makeStyles } from '@mui/styles'
import EditorLowerRightPanel from './EditorLowerRightPanel'
import EditorUpperRightPanel from './EditorUpperRightPanel'
import EditorLeftPanel from './EditorLeftPanel'
import { connect } from 'react-redux'
import { MAX_FAV_LIMIT_MESSAGE } from '../../constants/displayMessages'

import {
  fetchLibraryList,
  fetchLibraryMetadata,
  updateSrcMaterial,
  updateSelectedTexture,
  updateFavouritesLibrary,
} from '../../store/materialEditor/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - 315px)`,
  },
  spacing: {
    margin: '3px 0px 5px',
    padding: '0px 8px',
  },
  editor: { display: 'flex', flexDirection: 'column', height: '100%' },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  contentMain: { flexGrow: 1, overflow: 'hidden' },
  rightSection: { width: 0, flexGrow: 1 },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
}))

const TextureEditor = ({
  activeOperations,
  variant,
  fetchLibraryList,
  libraryList,
  srcMaterialList,
  selectedLibrary,
  updateSrcMaterial = () => {},
  fetchLibraryMetadata = () => {},
  favouriteId = '',
  favouriteList = [],
  selectedTexture = '',
  updateSelectedTexture = () => {},
  selectedLibraryName = '',
  updateFavouritesLibrary = () => {},
  enableActions = () => {},
  showNotification,
  isLoadingLibDetails = false,
  isLoadingMaterialList = false,
}) => {
  const classes = useStyles()

  const [showRightSection, setShowRightSection] = useState(false)

  useEffect(() => {
    fetchLibraryList(variant)
  }, [fetchLibraryList])

  useEffect(() => {
    if (favouriteId !== '') {
      fetchLibraryMetadata(favouriteId, variant, true)
    }
  }, [favouriteId])

  useEffect(() => {
    let isSelected = srcMaterialList.filter((item) => item.selected)
    setShowRightSection(Boolean(isSelected.length))
  }, [srcMaterialList])

  const handleLibraryChange = (value) => {
    fetchLibraryMetadata(value, variant)
  }

  const handleSwatchButtonClick = (value, data) => {
    updateSelectedTexture(data)
  }

  const handleReplace = () => {
    const srcListForUpdate = srcMaterialList
      .filter((item) => item.selected)
      .map((item) => ({
        ...item,
        swatch_thumbnail_url: selectedTexture.texture_url,
        color: '',
        updated: true,
      }))

    updateSrcMaterial(srcListForUpdate)
    enableActions()
  }
  const handleFavsUpdate = ({ action, value }) => {
    let newList = []
    if (action === 'add') {
      let listCpy = [...favouriteList]
      newList = [selectedTexture, ...listCpy]
    }
    if (action === 'delete') {
      let listCpy = [...favouriteList]
      newList = listCpy.filter(
        (item) => item.texture_url !== selectedTexture.texture_url
      )
    }
    updateFavouritesLibrary({
      isFavLibSelected: selectedLibraryName === 'Favourites',
      list: newList,
      listId: favouriteId,
      type: 'texture',
    })
  }

  const handleError = (error) => {
    console.log('error', error)
    if (error === 'max-limit-error') {
      showNotification(true, MAX_FAV_LIMIT_MESSAGE, 'error')
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.editor}>
        <div className={classes.spacing}>
          <Typography
            variant="subtitle2"
            label={`Edit ${variant} for selected asset (${activeOperations?.value?.name})`}
          />
        </div>

        <div className={classes.contentMain}>
          <div className={classes.content}>
            <EditorLeftPanel
              variant={variant}
              srcMaterialList={srcMaterialList}
              onMaterialSelected={updateSrcMaterial}
              isLoadingMaterialList={isLoadingMaterialList}
            />
            <div className={classes.rightSection}>
              {showRightSection && (
                <div className={classes.rightContent}>
                  <EditorUpperRightPanel
                    variant={variant}
                    selected={selectedTexture}
                    favouriteList={favouriteList}
                    handleFavsUpdate={(list) => handleFavsUpdate(list)}
                    handleError={(error) => handleError(error)}
                  />
                  <EditorLowerRightPanel
                    variant={variant}
                    libraryList={libraryList}
                    selectedLibrary={selectedLibrary}
                    handleLibraryChange={(data) => handleLibraryChange(data)}
                    handleSwatchButtonClick={(value, data) =>
                      handleSwatchButtonClick(value, data)
                    }
                    selected={selectedTexture}
                    handleReplace={handleReplace}
                    isLoadingLibDetails={isLoadingLibDetails}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  materialEditor: {
    srcMaterialList,
    libraryList,
    selectedLibrary,
    favouriteId,
    favouriteList,
    selectedTexture,
    selectedLibrary: { library_name: selectedLibraryName },
    isLoadingLibDetails,
    isLoadingMaterialList,
  },
}) => ({
  srcMaterialList,
  libraryList,
  selectedLibrary,
  favouriteId,
  favouriteList,
  selectedTexture,
  selectedLibraryName,
  isLoadingLibDetails,
  isLoadingMaterialList,
})
export default connect(mapStateToProps, {
  fetchLibraryList,
  updateSrcMaterial,
  fetchLibraryMetadata,
  updateSelectedTexture,
  updateFavouritesLibrary,
  showNotification,
})(TextureEditor)
