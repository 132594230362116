import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DefaultImage from '../../images/ic_default_board_img.png'
import { fetchRenditionImage } from '../../store/customCard/actionCreator'
import config from '../../config/config'

const useStyles = makeStyles(() => ({
  roundButton: {
    width: '30px !important',
    height: '30px !important',
    margin: '5px !important',
    padding: '0px !important',
    minWidth: '30px !important',
    borderRadius: '50% !important',
  },
  squareButton: {
    width: '35px !important',
    height: '35px !important',
    margin: '5px !important',
    padding: '0px !important',
    minWidth: '35px !important',
  },
}))

export const SwatchButton = ({
  value,
  selected = false,
  handleClick = () => {},
  variant = 'color',
  data = {},
}) => {
  const classes = useStyles()
  const [src, setSrc] = useState(value)
  useEffect(() => {
    if (variant !== 'color') fetchImage()
  }, [])

  useEffect(() => {
    if (variant !== 'color') fetchImage()
  }, [value])

  const fetchImage = () => {
    if (value === null) {
      setSrc(DefaultImage)
      return
    }
    let renditionsUrl = `${config.renditionsUrl}/${value}`

    fetchRenditionImage(
      renditionsUrl,
      (response) => {
        setSrc(URL.createObjectURL(response))
      },
      () => {
        setSrc(DefaultImage)
      }
    )
  }
  return (
    <>
      <Button
        className={
          variant === 'color' ? classes.roundButton : classes.squareButton
        }
        style={{
          outline: `${
            selected === value ? '#CC0000 solid 2px' : '#FFFFFF solid 2px'
          }`,
          ...(variant === 'color' && { background: value }),
          ...(variant !== 'color' && {
            backgroundImage: `url(${src})`,
            backgroundSize: 'cover',
          }),
        }}
        onClick={() => handleClick(value, data)}
      ></Button>
    </>
  )
}
