import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = (theme) => ({
  popoverPaperDefault: {
    padding: '6px',
    cursor: 'pointer',
  },
  defaultClass: {
    color: theme.palette.primary.darkFont,
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '16px',
  },
  disabledStyle: {
    color: theme.palette.primary.lightFont,
  },
  outerContainer: {
    margin: '8px 8px 12px 8px',
  },
  iconGap: {
    marginRight: '8px',
    width: '12px',
    textAlign: 'left',
  },
  textGap: {
    marginRight: '16px',
    width: '118px',
    textAlign: 'left',
  },
})

const NOOP = () => {}
class MenuPopover extends React.Component {
  render() {
    const {
      classes,
      openMenu = true,
      handleCloseMenu = () => {},
      anchorEl = null,
      designMenuItemArray = [],
      closeAfterAction = false,
      anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin = {
        vertical: 'top',
        horizontal: 'center',
      },
      objectBundle = {},
    } = this.props

    return (
      <div>
        <Popover
          id="MenuPopover"
          anchorReference="anchorEl"
          open={openMenu}
          anchorEl={anchorEl}
          classes={{
            paper: classes.popoverPaperDefault,
          }}
          onClose={handleCloseMenu}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {designMenuItemArray.map(
            (item, i) =>
              !item.hide && (
                <Grid
                  container
                  className={classes.outerContainer}
                  key={i}
                  onClick={
                    item.disabled
                      ? NOOP
                      : (e) => {
                          item.onClickItem(e, objectBundle)
                          closeAfterAction && handleCloseMenu(e)
                        }
                  }
                >
                  {item.icon ? (
                    <Grid
                      className={classNames(
                        classes.defaultClass,
                        classes.iconGap
                      )}
                    >
                      {item.icon ? (
                        <FontAwesomeIcon
                          icon={item.icon}
                          size="1x"
                          color="black"
                          className={classNames({
                            [classes.disabledStyle]: item.disabled,
                          })}
                        />
                      ) : null}
                    </Grid>
                  ) : null}

                  <Grid
                    className={classNames(
                      classes.defaultClass,
                      classes.textGap,
                      item.textStyle || null,
                      { [classes.disabledStyle]: item.disabled }
                    )}
                  >
                    {item.nameOfMenuItem}
                  </Grid>
                  {item.keyboardCmd ? (
                    <Grid
                      className={classes.defaultClass}
                      dangerouslySetInnerHTML={{ __html: item.keyboardCmd }}
                    />
                  ) : null}
                </Grid>
              )
          )}
        </Popover>
      </div>
    )
  }
}
MenuPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  openMenu: PropTypes.bool,
  handleCloseMenu: PropTypes.func,
  anchorEl: PropTypes.object,
  designMenuItemArray: PropTypes.array,
  hide: PropTypes.bool,
  closeAfterAction: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
}

export default withStyles(styles)(MenuPopover)
