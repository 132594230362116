import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { Grid, Paper } from '@mui/material'
import { CustomIconButton, AssetCard } from 'cgi-ui-components'
import LinkIcon from '@mui/icons-material/Link'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import config from '../../config/config'
import {
  fetchStyledAssets,
  clearStyledAssets,
} from '../../store/explorer/actionCreator'
import DataList from '../DataList'
import DraggableAssetCard from '../assetExplorerCard/DraggableAssetCard'

const styles = (theme) => ({
  card: {
    margin: '0px 10px',
    marginBottom: '20px',
  },

  noResultsFound: {
    padding: 20,
    textAlign: 'center',
    width: '100%',
    marginTop: '10px',
  },
  dataListContainer: {
    height: 'Calc(100vh - 150px) !important',
    marginTop: '10px',
    padding: '0 10px',
  },
})

class SubAssets extends React.Component {
  state = {
    loading: false,
  }
  componentDidMount() {
    this.fetchAssets()
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.data.tcin != prevProps.data.tcin ||
      this.props.data.variation != prevProps.data.variation
    ) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.fetchAssets()
    }
  }

  fetchAssets() {
    const {
      fetchStyledAssets,

      data,
      clearStyledAssets,
    } = this.props

    clearStyledAssets()
    this.setState({ loading: true })

    const req = {
      recordsPerPage: 100,
      pageNum: 1,
      searchVal: '',
      subAssetType: data.variant,
      assetType: data.asset_type,
      masterTcin: data.tcin,
    }

    fetchStyledAssets(req)
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }
  close = () => {
    const { onClose, clearStyledAssets } = this.props
    clearStyledAssets()
    onClose()
  }
  interSectionCallback = () => {}
  render() {
    const { classes, styledAssets, handleCartActions } = this.props
    const { loading } = this.state
    return (
      <DataList
        showProgressBar={loading}
        interSectionCallback={this.interSectionCallback}
        containerClass={classes.dataListContainer}
      >
        <>
          {styledAssets &&
            styledAssets.map((item) => {
              return (
                <div className={classes.card}>
                  <DraggableAssetCard
                    data={{
                      ...item,
                      tcin: item.value || item.tcin,
                      short_description: item.short_description,
                    }}
                    maxWidth={130}
                    minWidth={130}
                    preventDefault
                    handleCartActions={handleCartActions}
                  />
                </div>
              )
            })}

          {!loading && !styledAssets.length ? (
            <div className={classes.noResultsFound}>No Results found</div>
          ) : null}
        </>
      </DataList>
    )
  }
}

const mapStateToProps = ({ explorer: { styledAssets, totalStyledAssets } }) => {
  return {
    styledAssets,
    totalStyledAssets,
  }
}
export default connect(mapStateToProps, {
  fetchStyledAssets,
  clearStyledAssets,
})(withStyles(styles)(SubAssets))
