
 

import React, { useEffect } from 'react'
import { useAnalytics } from '@praxis/component-analytics'
import { TRACK_SCENE_EVENT } from '../../constants/scene'

export default function EventTracker() {
  const { trackCustomEvent } = useAnalytics()

  const EventListener = ({ detail: { type, assetId, e } }) => {
    trackCustomEvent(type, assetId, e, `assetId: ${assetId}`)
  }

  useEffect(() => {
    document.addEventListener(TRACK_SCENE_EVENT, EventListener)
    return () => document.removeEventListener(TRACK_SCENE_EVENT, EventListener)
  }, [])

  return <></>
}