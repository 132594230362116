import axios from 'axios'
import {
  GET_EXPERIENCE_LIST,
  API_CALL_INITIATED,
  ADD_LIST_PAGE_META,
  API_CALL_FAIL,
  CLEAR_EXPERIENCES,
  NO_PROJECTS_FOUND,
  GET_UPDATED_EXPERIENCE_ITEM,
  REMOVE_EXPERIENCE,
  SET_INITIATOR_LIST,
  START_UPDATE_EXPERIENCE,
  SUCCESS_UPDATE_EXPERIENCE,
  FAILURE_UPDATE_EXPERIENCE,
  ADD_EXPERIENCE,
  FETCH_EXPERIENCE_DETAIL_SUCCESS,
  CLEAR_EXPERIENCE_DETAIL,
  SELECT_TEMPLATE,
} from './ActionType'
import api from '../../config/config'
// import { dummyProjectList } from '../../store/experience/__mock__/dummyJson'

let axiosCancelToken = axios.CancelToken
let axiosSource = axiosCancelToken.source()

const KEY = api.apiKey
export function addListPageMeta(data) {
  return {
    type: ADD_LIST_PAGE_META,
    data,
  }
}

export function apiCallInitiated(data) {
  return {
    type: API_CALL_INITIATED,
    data,
  }
}

export function fetchRecordsSuccess(data) {
  return {
    type: GET_EXPERIENCE_LIST,
    data,
  }
}

export function apiCallFail(error) {
  return {
    type: API_CALL_FAIL,
    error,
    data: [],
  }
}

export function clearExperiences() {
  return {
    type: CLEAR_EXPERIENCES,
    data: '',
  }
}

export function arrangeExperienceListAfterDelete(expId) {
  return {
    type: REMOVE_EXPERIENCE,
    data: expId,
  }
}

export function noProjectsFound() {
  return {
    type: NO_PROJECTS_FOUND,
  }
}

export function getUpdatedExperienceItem(data) {
  return {
    type: GET_UPDATED_EXPERIENCE_ITEM,
    data,
  }
}

export function updateExperienceDetailsSuccess(data) {
  return {
    type: FETCH_EXPERIENCE_DETAIL_SUCCESS,
    data,
  }
}

export function fetchExperience(
  recordsPerPage = 15,
  pageNum = 1,
  searchVal = '',
  sortBy = 'created_date',
  sortOrder = 'DESC',
  isShowSearch,
  projectId = '',
  filterType = '',
  onFetchExperienceSuccess = () => {},
  onFetchExperienceFail = () => {}
) {
  return function (dispatch) {
    dispatch(
      apiCallInitiated({
        type: API_CALL_INITIATED,
        data: [],
      })
    )

    dispatch(
      addListPageMeta({ recordsPerPage, pageNum, searchVal, sortBy, sortOrder })
    )

    if (pageNum === 1 && axiosSource) {
      axiosSource.cancel('manual')
    }
    axiosCancelToken = axios.CancelToken
    axiosSource = axiosCancelToken.source()

    if (filterType === 'DEFAULT' || filterType === 'ALL') filterType = ''

    const urlWithParam =
      api.experience.experienceList +
      '?page=' +
      pageNum +
      '&count=' +
      recordsPerPage +
      '&sort_by=' +
      sortBy +
      '&sort_order=' +
      sortOrder +
      '&experience_name=' +
      searchVal +
      '&project_id=' +
      projectId +
      '&initiator=' +
      filterType
    return axios
      .get(urlWithParam, {
        cancelToken: axiosSource.token,
      })
      .then((response) => {
        if (isShowSearch) {
          dispatch(setInitiatorList(response.data.facets.initiator))
        }
        if (response.status === 204) {
          dispatch(noProjectsFound())
        } else {
          dispatch(
            fetchRecordsSuccess({ ...response.data, ...{ isShowSearch } })
          )
          onFetchExperienceSuccess()
        }
      })
      .catch((error) => {
        if (!error.Cancel) {
          dispatch(apiCallFail(error))
          onFetchExperienceFail(error)
        }
      })
  }
}

export function setInitiatorList(data) {
  return {
    type: SET_INITIATOR_LIST,
    data,
  }
}

export function fetchProjects(success, failure) {
  axios
    .get(`${api.projectList.listOfProjects}?sort_order=DESC`)
    .then((response) => {
      success(response.data.search_response)
    })
    .catch((e) => {
      failure(e)
    })
}

export function deleteScene(
  experienceId,
  callbackSuccess = () => {},
  callbackError = () => {}
) {
  return function (dispatch) {
    const url = api.experience.deleteScene + '' + experienceId
    return axios
      .delete(url)
      .then((response) => {
        dispatch(arrangeExperienceListAfterDelete(experienceId))
        callbackSuccess()
      })
      .catch((err) => {
        callbackError()
      })
  }
}

export function fetchProjectTypes(success, failure) {
  axios
    .get(`${api.experience.projectTypes}`)
    .then((response) => {
      success(response.data)
    })
    .catch((e) => {
      failure(e)
    })
}

export function experienceSaveChanges(
  requestBody,
  successCallBack = () => {},
  errorCallBack = () => {}
) {
  return function (dispatch) {
    const SUBMIT_SHARING = api.experience.shareExperience
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios
      .post(SUBMIT_SHARING, requestBody, {
        headers: headers,
        cancelToken: axiosSource.token,
      })
      .then((response) => {
        successCallBack(response)
        dispatch(getUpdatedExperienceItem(response.data))
        dispatch(updateExperienceDetailsSuccess(response.data))
      })
      .catch((error) => {
        errorCallBack(error)
      })
  }
}

export function updateProject(projectData, successCallback, errorCallback) {
  return axios
    .put(api.projectList.editProject + '?key=' + KEY, projectData, {
      cancelToken: axiosSource.token,
    })
    .then((response) => {
      successCallback(response.data)
    })
    .catch((error) => {
      errorCallback(error)
    })
}

export function uploadProjectThumbnail(
  projectData,
  successCallback,
  errorCallback
) {
  const formData = new window.FormData()
  formData.append('file', projectData.file)
  return axios
    .put(
      `${api.projectList.uploadProjectThumbnail}/${projectData.project_id}?key=${KEY}`,
      formData,
      { cancelToken: axiosSource.token }
    )
    .then((response) => {
      successCallback(response.data)
    })
    .catch((error) => {
      errorCallback()
    })
}

export function cancelExperienceCalls() {
  return function (dispatch) {
    axiosSource.cancel('Operation canceled by User')
  }
}

export function startUpdateExperience() {
  return {
    type: START_UPDATE_EXPERIENCE,
  }
}

export function updateExperieceSuccess(res) {
  return {
    type: SUCCESS_UPDATE_EXPERIENCE,
    payload: res,
  }
}

export function updateExperienceFailed(error) {
  return {
    type: FAILURE_UPDATE_EXPERIENCE,
    payload: error,
  }
}

export function createExperience(
  payload,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    return axios
      .post(api.experience.createExperience, payload)
      .then((res) => {
        dispatch(addExperienceRedux({ ...res.data, team: payload.team }))

        // dispatch(fetchTemplates())
        successCallback(res.data)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }
}

export function copyExperience(
  experienceData,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    dispatch(startUpdateExperience())
    return axios
      .post(api.experience.copyScene + '?key=' + KEY, experienceData)
      .then((response) => {
        // dispatch(
        //   addExperienceRedux({ ...response.data, team: experienceData.team })
        // )
        successCallback(response.data)
      })
      .catch((error) => {
        dispatch(updateExperienceFailed(error))
        errorCallback(error)
      })
  }
}

export function updateExperience(
  experienceData,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    dispatch(startUpdateExperience())
    return axios
      .put(api.template.updateExperience + '?key=' + KEY, experienceData)
      .then((response) => {
        dispatch(
          addExperienceRedux({ ...response.data, team: experienceData.team })
        )
        successCallback(response.data)
      })
      .catch((error) => {
        dispatch(updateExperienceFailed(error))
        errorCallback(error)
      })
  }
}

export function addExperienceRedux(data) {
  return {
    type: ADD_EXPERIENCE,
    payload: data,
  }
}

export function fetchExperienceDetailFail(error) {
  return {
    type: API_CALL_FAIL,
    error,
    data: [],
  }
}

export function fetchExperienceDetailSuccess(data) {
  return {
    type: FETCH_EXPERIENCE_DETAIL_SUCCESS,
    data,
  }
}

export function clearExperience() {
  return {
    type: CLEAR_EXPERIENCE_DETAIL,
  }
}

export function fetchExperienceDetail(itemId, onSuccess = () => {}) {
  return function (dispatch) {
    return axios
      .get(api.experience.createdExperience + '/' + itemId)
      .then((response) => {
        dispatch(fetchExperienceDetailSuccess(response.data))
        onSuccess(response.data)
      })
      .catch((error) => {
        dispatch(fetchExperienceDetailFail(error))
      })
  }
}
