import {
  GET_EXPERIENCE_LIST,
  API_CALL_INITIATED,
  ADD_LIST_PAGE_META,
  API_CALL_FAIL,
  CLEAR_EXPERIENCES,
  NO_PROJECTS_FOUND,
  GET_UPDATED_EXPERIENCE_ITEM,
  REMOVE_EXPERIENCE,
  SET_INITIATOR_LIST,
  START_UPDATE_EXPERIENCE,
  SUCCESS_UPDATE_EXPERIENCE,
  FAILURE_UPDATE_EXPERIENCE,
  ADD_EXPERIENCE,
  FETCH_EXPERIENCE_DETAIL_SUCCESS,
  CLEAR_EXPERIENCE_DETAIL,
} from './ActionType'

export const experienceState = {
  experienceList: [],
  totalExperienceCount: 0,
  responseExperienceCount: 0,
  filters: [],
  experienceListloading: false,
  listPageMeta: {},
  noResultsFound: false,
  initiatorList: [],
  experience: { template_id: '', template_default: {} },
}

export default function experiences(state = experienceState, action = {}) {
  switch (action.type) {
    case API_CALL_INITIATED: {
      return {
        ...state,
        experienceListloading: true,
      }
    }

    case SET_INITIATOR_LIST: {
      return { ...state, initiatorList: [...action.data] }
    }
    case ADD_LIST_PAGE_META: {
      return { ...state, listPageMeta: { ...action.data } }
    }

    case GET_EXPERIENCE_LIST: {
      const { data } = action
      const {
        total_count: totalRecords,
        response_count: responseRecords,
        facets,
        isShowSearch,
      } = data

      const { initiator: filters } = facets
      const resultObj = {
        apiError: action.success,
        experienceList: !isShowSearch
          ? [...state.experienceList, ...data.search_response]
          : data.search_response,
        experienceListloading: false,
        noResultsFound: false,
        totalExperienceCount: totalRecords,
        responseExperienceCount: responseRecords,
        filters,
      }
      if (isShowSearch && totalRecords > 0) {
        const obj = { ...resultObj, ...{ isShow: true } }
        return { ...state, ...obj }
      }
      return { ...state, ...resultObj }
    }

    case REMOVE_EXPERIENCE: {
      const { data } = action
      const { experienceList } = state
      const newExpList = experienceList.filter(
        (item) => item.experience_id !== data
      )
      return { ...state, ...{ experienceList: newExpList } }
    }

    case GET_UPDATED_EXPERIENCE_ITEM: {
      const { data } = action
      const { experienceList } = state

      const updatedList = experienceList.map((item) => {
        if (item.experience_id === data.experience_id) {
          item = data
        }
        return item
      })
      return { ...state, ...{ experienceList: updatedList } }
    }

    case API_CALL_FAIL: {
      return {
        ...state,
        apiError: action.error,
        experienceListloading: false,
      }
    }

    case CLEAR_EXPERIENCES: {
      const emptyList = {
        experienceList: [],
        noResultsFound: false,
        totalExperienceCount: 0,
        responseExperienceCount: 0,
      }
      return { ...state, ...emptyList }
    }

    case NO_PROJECTS_FOUND: {
      return {
        ...state,
        noResultsFound: true,
        experienceListloading: false,
        experienceList: [],
      }
    }
    case START_UPDATE_EXPERIENCE: {
      const newState = {
        ...state,
        selectStatus: true,
      }
      return newState
    }
    case SUCCESS_UPDATE_EXPERIENCE: {
      const newState = {
        ...state,
        selectStatus: false,
        statusMessage: 'experience updated successfully!',
      }
      return newState
    }
    case FAILURE_UPDATE_EXPERIENCE: {
      const newState = {
        ...state,
        selectStatus: false,
        statusMessage: 'something went wrong when updating experience.',
      }
      return newState
    }
    case ADD_EXPERIENCE: {
      const newState = {
        ...state,
        experience: { ...action.payload },
      }
      return newState
    }
    case FETCH_EXPERIENCE_DETAIL_SUCCESS: {
      return { ...state, experience: action.data }
    }
    case CLEAR_EXPERIENCE_DETAIL: {
      return { ...state, experience: {} }
    }
    default:
      return state
  }
}
