//import * as THREE from 'three'
import { RGBAFormat, RGBFormat, Texture, DefaultLoadingManager } from 'three'
import { ImageLoader } from './ImageLoader'

/**
 * @author mrdoob / http://mrdoob.com/
 */

function TextureLoader(manager) {
  this.manager = manager !== undefined ? manager : DefaultLoadingManager
}

Object.assign(TextureLoader.prototype, {
  load: function (url, onLoad, onProgress, onError) {
    var texture = new Texture()

    var loader = new ImageLoader(this.manager)
    loader.setCrossOrigin(undefined)
    loader.setWithCredentials(this.withCredentials)
    loader.setPath(this.path)
    loader.setRequestHeaders(this.requestHeaders)
    loader.load(
      url,
      function (image) {
        // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
        var isJPEG =
          url.search(/\.(jpg|jpeg)$/) > 0 ||
          url.search(/^data\:image\/jpeg/) === 0

        texture.format = isJPEG ? RGBFormat : RGBAFormat
        texture.image = image
        texture.needsUpdate = true

        if (onLoad !== undefined) {
          onLoad(texture)
        }
      },
      onProgress,
      onError
    )

    return texture
  },

  setCrossOrigin: function (value) {
    this.crossOrigin = value
    return this
  },

  setWithCredentials: function (value) {
    this.withCredentials = value
    return this
  },

  setPath: function (value) {
    this.path = value
    return this
  },

  setRequestHeaders:function(headers){
    this.requestHeaders=headers
    return this
  }
})

export default TextureLoader
