import axios from 'axios'
import {
  GET_COLLECTION_LIST,
  CREATE_COLLECTION,
  ADD_LIST_PAGE_META,
  NO_COLLECTION_FOUND,
  API_CALL_FAIL_COLLECTION_LIST,
  CLEAR_COLLECTION_LIST,
  UPDATE_COLLECTION_ITEM,
  UPDATE_COLLECTION_NAME,
  SAVE_MISCELLANEOUS_TO_COLLECTION_LIST,
  API_CALL_INITIATED,
  SAVE_COLLECTION_LIST,
  CLEAR_FAVOURITE_ASSET_LIST,
  DELETE_COLLECTION,
  COLLECTION_TYPE,
} from './ActionType'

import api from '../../config/config'
import { mapGalleryData } from '../explorer/reducer'
import { getAssetType } from '../../helpers/utils'

const axiosCancelToken = axios.CancelToken
const axiosSource = axiosCancelToken.source()
const KEY = api.apiKey

export function updateCollectionItem(request, data) {
  return {
    type: UPDATE_COLLECTION_ITEM,
    reqData: request,
    resData: data,
  }
}

export function updateCollectionName(data) {
  return {
    type: UPDATE_COLLECTION_NAME,
    data,
  }
}

export function shareCollection(
  requestBody,
  successCallBack = () => {},
  errorCallBack = () => {}
) {
  return function (dispatch) {
    const URL = api.collection.shareCollection
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios
      .put(URL, requestBody, {
        headers: headers,
        cancelToken: axiosSource.token,
      })
      .then((response) => {
        successCallBack(response)
        dispatch(updateCollectionItem(requestBody, response.data)) // Getting item data from request body as API is not returning full response after update
      })
      .catch((error) => {
        errorCallBack(error)
      })
  }
}

export function addAssetsToCollection(
  requestBody,
  successCallBack = () => {},
  errorCallBack = () => {},
  favouriteType,
  collectionName
) {
  return function (dispatch) {
    const galleryUrl = api.collection.updateGalleryCollection
    if (favouriteType === 'Gallery') {
      const addTcinList = requestBody.add_tcin_list.filter(
        (value) => getAssetType(value) === 'tcin'
      )
      const addPidList = requestBody.add_tcin_list.filter(
        (value) => getAssetType(value) === 'pid'
      )
      const removeTcinList = requestBody.remove_tcin_list.filter(
        (value) => getAssetType(value) === 'tcin'
      )
      const removePidList = requestBody.remove_tcin_list.filter(
        (value) => getAssetType(value) === 'pid'
      )

      const postData = {
        add_tcin_list: [...addTcinList],
        remove_tcin_list: [...removeTcinList],
        add_pid_list: [...addPidList],
        remove_pid_list: [...removePidList],
        id: requestBody.id,
      }

      return axios
        .put(galleryUrl, postData, {
          cancelToken: axiosSource.token,
        })
        .then((response) => {
          if (response.data.status === 'FAILED') {
            dispatch(apiCallFail({ errorMessages: 'Failed to add product' }))
            errorCallBack({ errorMessages: 'Failed to add product' })
          } else {
            let formattedData = []
            response?.data?.favourite?.gallery_favourite?.assets?.forEach(
              (item) => {
                formattedData.push(mapGalleryData(item, true))
              }
            )
            const tcin =
              response &&
              response.data &&
              response.data.favourite &&
              response.data.favourite.tcins &&
              response.data.favourite.tcins.length
                ? [...response.data.favourite.tcins]
                : []
            let tcinList = []
            formattedData.map((item) => tcinList.push(item.asset_id))
            tcin.map((item) => tcinList.push(item.tcin))
            const searchResponse = {
              created_date: '',
              favourite_visibility: 'Private',
              group: null,
              groups: [],
              hp_available: false,
              id: '',
              lp_available: false,
              master_collection: 'true',
              name: collectionName,
              owner: {},
              removed_groups: [],
              shared_users: [],
              tcin_count: 1,
              tcin_details: null,
              tcin_document_list: [...formattedData, ...tcin],
              tcin_list: [...tcinList],
              thumbnail_path: '',
              updated_date: '',
            }
            dispatch(
              assetListSave({
                search_response: [searchResponse],
                total_items:
                  response?.data?.favourite?.gallery_favourite?.assets?.length +
                  response?.data?.favourite?.tcins?.length,
                ...{ isAddedAssest: false },
              })
            )
            successCallBack(formattedData)
          }
        })
        .catch((error) => {
          dispatch(apiCallFail(error))
          errorCallBack(error?.response?.data)
        })
    }
    const URL = api.collection.addAssetsCollection
    var headers = {
      'Content-Type': 'application/json',
    }
    return axios
      .put(URL, requestBody, {
        headers: headers,
        cancelToken: axiosSource.token,
      })
      .then((response) => {
        dispatch(
          assetListSave({ ...response.data, ...{ isAddedAssest: false } })
        )
        successCallBack(response.data)
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
        errorCallBack(error.response.data)
      })
  }
}

export function addListPageMeta(data) {
  return {
    type: ADD_LIST_PAGE_META,
    data,
  }
}

export function createCollectionSave(data) {
  return {
    type: CREATE_COLLECTION,
    data,
  }
}

export function apiCallInitiatedFav(data) {
  return {
    type: API_CALL_INITIATED,
    data,
  }
}

export function collectionListSave(data) {
  return {
    type: GET_COLLECTION_LIST,
    data,
  }
}

export function assetListSave(data) {
  return {
    type: SAVE_COLLECTION_LIST,
    data,
  }
}

export function noCollectionFound() {
  return {
    type: NO_COLLECTION_FOUND,
  }
}

export function apiCallFail(error) {
  return {
    type: API_CALL_FAIL_COLLECTION_LIST,
    error,
    data: [],
  }
}

export function clearCollection() {
  return {
    type: CLEAR_COLLECTION_LIST,
    data: '',
  }
}

export function saveMiscellaneousCollection(data) {
  return {
    type: SAVE_MISCELLANEOUS_TO_COLLECTION_LIST,
    data: data,
  }
}

export function fetchAssetsForFavorite(
  recordsPerPage = 15,
  pageNum = 1,
  id = '',
  searchVal = '',
  isAddedAssest,
  onSuccessAssetsForFavoriteApi = () => {},
  onErrorAssetsForFavorite = () => {},
  assetType,
  assetName
) {
  return function (dispatch) {
    dispatch(
      apiCallInitiatedFav({
        type: API_CALL_INITIATED,
        data: [],
      })
    )
    if (assetType === 'Gallery') {
      let urlWithParam = `${
        api.collection.galleryCollectionInternal
      }?q=${searchVal}&page=${
        pageNum - 1
      }&size=${350}&collection_id=${id}&access=VIEW`
      return axios
        .get(urlWithParam, {
          cancelToken: axiosSource.token,
        })
        .then((response) => {
          let formattedData = []
          response.data?.gallery_favourite?.assets?.forEach((item) => {
            formattedData.push(mapGalleryData(item, true))
          })

          const tcin =
            response &&
            response.data &&
            response.data.tcins &&
            response.data.tcins.length
              ? [...response.data.tcins]
              : []
          let tcinList = []
          formattedData.map((item) => tcinList.push(item.asset_id))
          tcin.map((item) => tcinList.push(item.tcin))
          const searchResponse = {
            created_date: '',
            favourite_visibility: 'Private',
            group: null,
            groups: [],
            hp_available: false,
            id: '',
            lp_available: false,
            master_collection: 'true',
            name: assetName,
            owner: {},
            removed_groups: [],
            shared_users: [],
            tcin_count: 1,
            tcin_details: null,
            tcin_document_list: [...formattedData, ...tcin],
            tcin_list: [...tcinList],
            thumbnail_path: '',
            updated_date: '',
          }
          dispatch(
            assetListSave({
              search_response: [searchResponse],
              total_items:
                response?.data?.gallery_favourite?.assets?.length +
                response?.data?.tcins?.length,
              ...{ isAddedAssest },
            })
          )
          onSuccessAssetsForFavoriteApi(formattedData)
        })
        .catch((error) => {
          dispatch(apiCallFail(error))
          onErrorAssetsForFavorite(error)
        })
    }
    const urlWithParam =
      api.collection.tcinsInFavorite +
      'page=' +
      pageNum +
      '&sort_by=created_date' +
      '&sort_order=DESC' +
      '&count=' +
      recordsPerPage +
      '&id=' +
      id +
      '&query=' +
      searchVal

    return axios
      .get(urlWithParam, {
        cancelToken: axiosSource.token,
      })
      .then((response) => {
        dispatch(assetListSave({ ...response.data, ...{ isAddedAssest } }))
        onSuccessAssetsForFavoriteApi(response.data)
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
        onErrorAssetsForFavorite(error)
      })
  }
}

export function clearFavouriteAssets() {
  return {
    type: CLEAR_FAVOURITE_ASSET_LIST,
    data: '',
  }
}

export function setCollectionType(type) {
  return function (dispatch) {
    dispatch({
      type: COLLECTION_TYPE,
      payload: type,
    })
  }
}

export function fetchCollection(
  recordsPerPage = 15,
  pageNum = 1,
  sortBy = 'created_date',
  sortOrder = 'DESC',
  isShared = false,
  searchVal = '',
  isMiscellaneous = false,
  isAdded = true,
  payloadForMiscellaneous = {},
  onSuccessCollectionApi = () => {},
  onFetchCollectionFail = () => {},
  assetType
) {
  return function (dispatch) {
    dispatch(
      apiCallInitiatedFav({
        type: API_CALL_INITIATED,
        data: [],
      })
    )
    dispatch(
      addListPageMeta({
        recordsPerPage,
        pageNum,
        sortBy,
        sortOrder,
        isShared,
        searchVal,
      })
    )
    if (assetType === 'Gallery') {
      let urlWithParam = `${
        api.collection.galleryCollectionList
      }?q=${searchVal}&access=VIEW&page=${pageNum - 1}&size=${recordsPerPage}`
      return axios
        .get(urlWithParam)
        .then((response) => {
          if (response.status === 204) {
            dispatch(noCollectionFound())
            onSuccessCollectionApi([])
          }
          const mappedData = response.data.data.map((item) => ({
            ...item.gallery_favourite,
            removed_pid_list: item.removed_pid_list,
            tcin_list: item.tcin_list,
            collection_id: item.collection_id,
            id: item._id,
            added_pid_list: item.added_pid_list,
          }))
          const data = mapAssetData({
            data: { data: mappedData, total_items: response.data.total_items },
          })
          dispatch(
            collectionListSave({
              ...data,
              ...{ isAdded },
            })
          )
          onSuccessCollectionApi(data.search_response)
        })
        .catch((error) => {
          dispatch(apiCallFail(error))
          onFetchCollectionFail(error)
        })
    }

    const urlWithParam =
      api.collection.favouriteList +
      'page=' +
      pageNum +
      '&count=' +
      recordsPerPage +
      '&sort_by=' +
      sortBy +
      '&sort_order=' +
      sortOrder +
      '&shared=' +
      isShared +
      '&query=' +
      searchVal
    return axios
      .get(urlWithParam, {
        cancelToken: axiosSource.token,
      })
      .then((response) => {
        if (response.status === 204) {
          dispatch(noCollectionFound())
          onSuccessCollectionApi([])
        } else {
          if (isMiscellaneous && response.data.search_response.length === 0) {
            dispatch(
              createCollection(
                payloadForMiscellaneous,
                (data) => {
                  dispatch(saveMiscellaneousCollection(data))
                },
                () => {}
              )
            )
          } else {
            //dispatch(clearCollection())
            dispatch(collectionListSave({ ...response.data, ...{ isAdded } }))
            onSuccessCollectionApi(response.data.search_response)
          }
        }
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
        onFetchCollectionFail(error)
      })
  }
}

export function createCollection(
  payload,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    return axios
      .post(api.collection.create, payload)
      .then((res) => {
        successCallback(res.data)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }
}
/**
 * Service to update collection details such as Name.
 * @param {object} collectionInfo Collection details object
 * @param {function} successCallback Success callback
 * @param {function} errorCallback Error callback
 */
export function updateCollectionDetails(
  collectionInfo,
  successCallback,
  errorCallback
) {
  return axios
    .put(api.collection.update + '?key=' + KEY, collectionInfo, {
      cancelToken: axiosSource.token,
    })
    .then((response) => {
      successCallback(response.data)
    })
    .catch((error) => {
      errorCallback(error)
    })
}

/**
 * Rearrange the collection list by removing the item having id equal to passed id.
 * @param {string} id Collection id to be deleted
 */
export function arrangeCollectionListAfterDelete(id) {
  return {
    type: DELETE_COLLECTION,
    id,
  }
}
/**
 * Delete handler for collection
 * @param {string} collectionId Collection id to be deleted
 * @param {function} callbackSuccess Callback on successfully deleting the collection
 * @param {function} callbackError Callback when failed to delete the collection
 */
export function deleteCollection(
  collectionId,
  callbackSuccess = () => {},
  callbackError = () => {}
) {
  return function (dispatch) {
    const url = `${api.collection.delete}/${collectionId}`
    return axios
      .delete(url)
      .then((response) => {
        callbackSuccess(response)
        dispatch(arrangeCollectionListAfterDelete(collectionId))
      })
      .catch((err) => {
        callbackError(err)
      })
  }
}

const mapAssetData = (response) => {
  let formattedData = []
  response?.data?.data.forEach((item) => {
    const assetsLength = item.assets ? item.assets.length : 0
    const totalCount =
      assetsLength + (item.tcin_list ? item.tcin_list.length : 0)
    const collectionObj = {
      assetType: 'gallery',
      created_date: item.created_at,
      favourite_visibility: 'Private',
      group: null,
      groups: [],
      hp_available: false,
      id: item.id,
      lp_available: false,
      master_collection: true,
      name: item.name,
      owner: {
        display_name:
          item.gallery_users && item.gallery_users.length
            ? item.gallery_users[0]?.user?.first_name
            : '' + item.gallery_users && item.gallery_users.length
            ? item.gallery_users[0]?.user?.last_name
            : '',
        email_address:
          item.gallery_users && item.gallery_users.length
            ? item.gallery_users[0]?.user?.email
            : '',
        first_name:
          item.gallery_users && item.gallery_users.length
            ? item.gallery_users[0]?.user?.first_name
            : '',
        last_name:
          item.gallery_users && item.gallery_users.length
            ? item.gallery_users[0]?.user?.last_name
            : '',
        login_id:
          item.gallery_users && item.gallery_users.length
            ? item.gallery_users[0]?.user?.lan_id
            : '',
      },
      removed_groups: [],
      shared_users: null,
      tcin_count: totalCount,
      tcin_details: [],
      tcin_document_list: null,
      tcin_list: [],
      thumbnail_path:
        item.assets && item.assets.length
          ? item.assets[0].files && item.assets[0].files.length
            ? item.assets[0].files[0].file_sources &&
              item.assets[0].files[0].file_sources.length
              ? item.assets[0].files[0].file_sources[0].access_url
              : ''
            : ''
          : '',
      updated_date: item.updated_at,
    }
    formattedData.push(collectionObj)
  })
  return {
    search_response: formattedData,
    total_count: response.data.total_items,
  }
}
