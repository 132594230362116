import axios from 'axios'

import {
  API_CALL_INITIATED,
  API_CALL_FAIL,
  FETCH_RECORDS_SUCCESS,
  CLEAR_ASSETS,
  ADD_LIST_PAGE_META,
  NO_ASSETS_FOUND,
  ADD_COMMENTS,
  COMMENTS_SUCCESS,
  COMMENTS_FAILURE,
  SHOW_COMMENTS,
  SHOW_COMMENTS_FAILURE,
  SHOW_COMMENTS_SUCCESS,
  FETCH_RECORDS_SUCCESS_UNGROUP,
  CLEAR_ASSETS_UNGROUP,
  FETCH_STYLED_ASSETS_SUCCESS,
  CLEAR_STYLED_ASSETS,
  FILTER_LISTS_REQUEST,
  FILTER_LISTS_SUCCESS,
  FILTER_LISTS_FAILURE,
  FILTER_LISTS_CHANGE,
  FILTER_COUNT_CHANGE,
  CLEAR_ALL_FILTERS,
  TOTAL_COUNT_SUCCESS,
  FETCH_SIMILAR_ASSETS_SUCCESS,
  CLEAR_SIMILAR_ASSETS,
  SHOW_SIMILAR_ASSETS,
  FETCH_GALLERY_RECORD_SUCCESS,
  CLOSE_EXPLORER,
  OPEN_EXPLORER,
  SHOW_VARIATIONS,
  CLEAR_SUB_VIEW,
  SET_SORTBY,
  SET_EXCLUDE_HP,
  SET_EXCLUDE_RTA,
  SET_MINI_EXPLORER_MODE,
} from './actionType'

import apiConfig from '../../config/config'
import { ASSET_MODE_ARCH, ASSET_MODE_TCIN } from '../../constants/scene'
// import { getData } from './__mocks__/mockProjectList'
const KEY = apiConfig.apiKey

export function apiCallInitiated(data) {
  return {
    type: API_CALL_INITIATED,
    data,
  }
}

export function apiCallFail(error) {
  return {
    type: API_CALL_FAIL,
    error,
    data: [],
  }
}

export function fetchRecordsSuccess(data) {
  return {
    type: FETCH_RECORDS_SUCCESS,
    data,
  }
}

export function fetchRecordsUngroupSuccess(data) {
  return {
    type: FETCH_RECORDS_SUCCESS_UNGROUP,
    data,
  }
}

export function fetchStyledAssetsSuccess(data) {
  return {
    type: FETCH_STYLED_ASSETS_SUCCESS,
    data,
  }
}

export function noAssetsFound() {
  return {
    type: NO_ASSETS_FOUND,
  }
}

export function clearAssets() {
  return {
    type: CLEAR_ASSETS,
    data: '',
  }
}

export function clearAssetsUngroup() {
  return {
    type: CLEAR_ASSETS_UNGROUP,
    data: '',
  }
}
export function clearStyledAssets() {
  return {
    type: CLEAR_STYLED_ASSETS,
    data: '',
  }
}

export function addListPageMeta(data) {
  return {
    type: ADD_LIST_PAGE_META,
    data,
  }
}

export function fetchGalleryRecordsSuccess(data) {
  return {
    type: FETCH_GALLERY_RECORD_SUCCESS,
    data,
  }
}

export function setMiniExplorerMode(mode) {
  return {
    type: SET_MINI_EXPLORER_MODE,
    payload: mode,
  }
}

export function fetchAssets(
  recordsPerPage = 20,
  pageNum = 1,
  searchVal = '',
  assetType = 'ALL',
  filters = {},
  fetchFilters = false,
  excludeHighPolyUnavailable,
  search_similar_asset = {},
  cancelController,
  sort_fields,
  excludeRTAavailable,
  isArchAssets
) {
  return function (dispatch) {
    dispatch(
      apiCallInitiated({
        type: API_CALL_INITIATED,
        data: [],
      })
    )
    dispatch(
      setMiniExplorerMode(isArchAssets ? ASSET_MODE_ARCH : ASSET_MODE_TCIN)
    )
    dispatch(
      addListPageMeta({
        recordsPerPage,
        pageNum,
        searchVal,
        searchBy: assetType,
      })
    )
    dispatch(
      setSortByPref(
        fetchFilters ? JSON.stringify({ time_stamp: 'DESC' }) : sort_fields
      )
    )
    let assetContains = {}
    if (filters.Asset_Contains) {
      filters.Asset_Contains.forEach((item) => {
        if (item === 'Styled Variations') {
          assetContains['has_styled_assets'] = true
        }
        if (item === 'UnBundled Version') {
          assetContains['has_unbundled_assets'] = true
        }
      })
      delete filters.Asset_Contains
    }

    let params = {
      query: searchVal,
      page: pageNum,
      count: recordsPerPage,
      search_by: '',
      strict_search: true,
      search_type: assetType,
      sort_fields: fetchFilters
        ? { time_stamp: 'DESC' }
        : JSON.parse(sort_fields),
      master_tcin: '',
      filters,
      sub_asset_type: '',
      exculde_high_poly_unavailable: excludeHighPolyUnavailable,
      ...assetContains,
      search_similar_asset,
      exclude_rta_available: excludeRTAavailable,
    }
    let controller = cancelController
    if (!cancelController) {
      controller = new AbortController()
    }
    if (assetType === 'GALLERY') {
      let query = ''

      const galleryFilters = [
        {
          filter_chip_key: 'lod_profile_type',
          filter_chip_value: ['LOD3', 'LOD4'],
        },
      ]
      for (let filter_chip_key in filters) {
        galleryFilters.push({
          filter_chip_key,
          filter_chip_value: filters[filter_chip_key],
        })
      }
      const params = {
        query: searchVal,
        filters: galleryFilters,
      }
      return axios
        .post(
          `${apiConfig.gallery.search}?page=${
            pageNum - 1
          }&per_page=${recordsPerPage}&pre_sign_all_files=false`,
          params,
          {
            signal: controller?.signal,
          }
        )
        .then(({ data: assetsResponse }) => {
          if (searchVal && pageNum === 1) {
            dispatch(clearAssets())
          }

          dispatch(fetchGalleryRecordsSuccess(assetsResponse))

          if (pageNum === 1) {
            return axios
              .post(`${apiConfig.gallery.search}/filters`, {
                query: '',
                filters: [
                  {
                    filter_chip_key: 'lod_profile_type',
                    filter_chip_value: ['LOD3', 'LOD4'],
                  },
                ],
              })
              .then(({ data: response }) => {
                const {
                  data: { filters: galleryFilterData = {} } = { filters: {} },
                } = response
                const outputdata = {}

                for (let filterkey in galleryFilterData) {
                  outputdata[filterkey] = galleryFilterData[filterkey].map(
                    (i) => {
                      return { name: i, count: 1 }
                    }
                  )
                }
                dispatch(filterListsSuccess(outputdata, assetType))
              })
          }
        })
        .catch((error) => {
          dispatch(apiCallFail(error))
          return error
        })
    }
    let urlWithParam = `${apiConfig.explorer.filterLists}`
    if (isArchAssets) {
      params = {
        query: searchVal,
        page: pageNum,
        count: 45,
        strict_search: true,
        search_by: 'asset',
      }
      urlWithParam = apiConfig.assets.fetchArchitecturalAssets
    }

    return axios
      .post(urlWithParam, params, {
        signal: controller?.signal,
      })
      .then((response) => {
        if (fetchFilters) {
          dispatch({
            type: TOTAL_COUNT_SUCCESS,
            data: response.data.total_count,
          })
        }
        if (pageNum === 1) {
          dispatch(filterListsSuccess(response.data.facets || {}, assetType))
        }
        if (searchVal && pageNum === 1) {
          dispatch(clearAssets())
        }
        if (response.status === 204) {
          dispatch(noAssetsFound())
        } else {
          dispatch(fetchRecordsSuccess({ ...response.data, assetType }))
        }
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
        return error
      })
  }
}

export function addComments() {
  return {
    type: ADD_COMMENTS,
  }
}

export function addCommentSuccess(payload) {
  return {
    type: COMMENTS_SUCCESS,
    payload: payload,
  }
}

export function addCommentFailure(error) {
  return {
    type: COMMENTS_FAILURE,
    payload: error,
  }
}

export function addCommentsToExperince(comments, experienceId, lanId) {
  return function (dispatch) {
    dispatch(addComments())
    return axios
      .post(apiConfig.comments.addComments, {
        comment: comments,
        experience_id: experienceId,
        comment_by: lanId,
      })
      .then((response) => {
        var tempData = {
          comment_by: response.data.comment_by,
          comment: response.data.comment,
          comment_time: response.data.comment_time,
        }
        dispatch(addCommentSuccess(tempData))
      })
      .catch((error) => {
        // console.log('error')
        dispatch(addCommentFailure(error))
      })
  }
}

export function commentsSuccess(payload) {
  return {
    type: SHOW_COMMENTS_SUCCESS,
    payload: payload,
  }
}

export function commentsFailure(error) {
  return {
    type: SHOW_COMMENTS_FAILURE,
    payload: error,
  }
}

export function showCommentsAll() {
  return {
    type: SHOW_COMMENTS,
  }
}

export function showComments(experienceId) {
  return function (dispatch) {
    dispatch(showCommentsAll())
    return axios
      .get(apiConfig.comments.displayComments + experienceId, {
        experience_id: experienceId,
      })
      .then((response) => {
        dispatch(commentsSuccess(response.data.comment))
      })
      .catch((e) => {
        dispatch(commentsFailure(e))
      })
  }
}

export function fetchAssetsCollectionUngrouping(
  recordsPerPage = 20,
  pageNum = 1,
  searchVal = '',
  assetType = 'ALL',
  parentTcin
) {
  return function (dispatch) {
    dispatch(
      apiCallInitiated({
        type: API_CALL_INITIATED,
        data: [],
      })
    )
    dispatch(addListPageMeta({ recordsPerPage, pageNum, searchVal }))
    let params = {
      key: KEY,
      query: searchVal,
      search_type: assetType,
      page: pageNum,
      count: recordsPerPage,
      parent_tcin: parentTcin,
    }
    const urlWithParam = `${apiConfig.explorer.getAssets}`
    return axios
      .post(urlWithParam, params)
      .then((response) => {
        dispatch(fetchRecordsUngroupSuccess(response.data))
      })
      .catch((error) => {})
  }
}

export function fetchStyledAssets({
  recordsPerPage = 20,
  pageNum = 1,
  searchVal = '',
  subAssetType = 'STYLED',
  assetType = 'TCIN',
  masterTcin = '',
}) {
  return function (dispatch) {
    dispatch(
      apiCallInitiated({
        type: API_CALL_INITIATED,
        data: [],
      })
    )
    //dispatch(addListPageMeta({ recordsPerPage, pageNum, searchVal }))
    let params = {
      key: KEY,
      query: searchVal,
      search_type: assetType,
      page: pageNum,
      count: recordsPerPage,
      master_tcin: masterTcin,
      sub_asset_type: subAssetType,
    }
    const urlWithParam = `${apiConfig.explorer.getAssets}`
    return axios
      .post(urlWithParam, params)
      .then((response) => {
        dispatch(fetchStyledAssetsSuccess(response.data))
      })
      .catch((error) => {})
  }
}

export function filterListsRequest() {
  return {
    type: FILTER_LISTS_REQUEST,
  }
}

export function filterListsSuccess(payload, filterType) {
  return {
    type: FILTER_LISTS_SUCCESS,
    payload: payload,
    filterType,
  }
}

export function filterListsFailure(error) {
  return {
    type: FILTER_LISTS_FAILURE,
    payload: error,
  }
}

export function fetchFilterListsData() {
  return function (dispatch) {
    dispatch(filterListsRequest())
    const urlWithParam = `${apiConfig.explorer.filterLists}`
    let params = {
      query: '*',
      page: 0,
      count: 24,
      search_by: '',
      strict_search: true,
      search_type: 'ALL',
      sort_fields: { time_stamp: 'DESC' },
      master_tcin: '',
      sub_asset_type: '',
    }
    return axios
      .post(urlWithParam, params)
      .then((response) => {
        dispatch(filterListsSuccess(response.data.facets, 'ALL'))
        dispatch({ type: TOTAL_COUNT_SUCCESS, data: response.data.total_count })
      })
      .catch((e) => {
        dispatch(filterListsFailure(e))
      })
  }
}
export function handlefilterChange(filterkey, id, selected = true) {
  const change = selected ? 1 : -1
  return function (dispatch) {
    dispatch({ type: FILTER_LISTS_CHANGE, payload: { filterkey, id } })
    dispatch({ type: FILTER_COUNT_CHANGE, payload: change })
    return Promise.resolve()
  }
}
export function clearAllFilters() {
  return function (dispatch) {
    dispatch({ type: CLEAR_ALL_FILTERS, data: {} })
    return Promise.resolve()
  }
}

export function fetchSimilarAssetsSuccess(data) {
  return {
    type: FETCH_SIMILAR_ASSETS_SUCCESS,
    data,
  }
}

export function clearSimilarAssets() {
  return {
    type: CLEAR_SIMILAR_ASSETS,
    data: '',
  }
}

export function fetchSimilarAssets(
  recordsPerPage = 100,
  pageNum = 1,
  searchVal = '',
  assetType = 'ALL',
  filters = {},
  excludeHighPolyUnavailable,
  search_similar_asset = {}
) {
  return function (dispatch) {
    dispatch(
      apiCallInitiated({
        type: API_CALL_INITIATED,
        data: [],
      })
    )
    const { tcin, asset_type } = search_similar_asset
    if (asset_type === 'PROP') {
      const urlWithParam = `${apiConfig.explorer.similarSearch}?asset_id=${tcin}&asset_type=${asset_type}&size=${recordsPerPage}`
      return axios
        .get(urlWithParam)
        .then((response) => {
          dispatch(
            fetchSimilarAssetsSuccess({
              ...response.data,
              primary_tcin_detail: search_similar_asset,
            })
          )
        })
        .catch((error) => {})
    } else if (asset_type === 'ARCHITECTURE') {
      //payload will change once API is ready
      let params = {
        query: searchVal,
        page: pageNum,
        count: 45,
        strict_search: true,
        search_by: 'asset',
      }
      let urlWithParam = apiConfig.assets.fetchArchitecturalAssets
      return axios
        .post(urlWithParam, params)
        .then((response) => {
          dispatch(
            fetchSimilarAssetsSuccess({
              ...response.data,
              primary_tcin_detail: search_similar_asset,
            })
          )
        })
        .catch((error) => {})
    } else {
      let assetContains = {}
      if (filters.Asset_Contains) {
        filters.Asset_Contains.forEach((item) => {
          if (item === 'Styled Variations') {
            assetContains['has_styled_assets'] = true
          }
          if (item === 'UnBundled Version') {
            assetContains['has_unbundled_assets'] = true
          }
        })
        delete filters.Asset_Contains
      }

      let params = {
        query: searchVal,
        page: pageNum,
        count: recordsPerPage,
        search_by: '',
        strict_search: true,
        search_type: assetType,
        sort_fields: { time_stamp: 'DESC' },
        master_tcin: '',
        filters,
        sub_asset_type: '',
        exculde_high_poly_unavailable: true,
        search_similar_asset: { tcin },
        ...assetContains,
      }
      const urlWithParam = `${apiConfig.explorer.filterLists}`
      return axios
        .post(urlWithParam, params)
        .then((response) => {
          dispatch(fetchSimilarAssetsSuccess(response.data))
        })
        .catch((error) => {})
    }
  }
}

export function showSimilarSearchResults(show, data) {
  return {
    type: SHOW_SIMILAR_ASSETS,
    payload: { show, data },
  }
}

export function showVariationAssets(show, asset = {}) {
  return {
    type: SHOW_VARIATIONS,

    data: { asset, show },
  }
}

export function handleCloseExplorer() {
  return {
    type: CLOSE_EXPLORER,
  }
}

export function handleOpenExplorer() {
  return {
    type: OPEN_EXPLORER,
  }
}

export function clearSubAssetsView() {
  return {
    type: CLEAR_SUB_VIEW,
  }
}

export function setSortByPref(data) {
  return {
    type: SET_SORTBY,
    payload: data,
  }
}

export function setExcludedHP(data) {
  return {
    type: SET_EXCLUDE_HP,
    payload: data,
  }
}

export function setExcludedRTA(data) {
  return {
    type: SET_EXCLUDE_RTA,
    payload: data,
  }
}
