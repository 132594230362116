import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { DialogContentText, Backdrop } from '@mui/material'
import {
  fetchExperience,
  clearExperiences,
  deleteScene,
} from '../../../store/experience/ActionCreator'
import {
  TemplateCard,
  PageTitleBar,
  Select,
  Typography,
  SearchComponent,
  CustomButton,
} from 'cgi-ui-components'
import DownloadIcon from '@mui/icons-material/Download'
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined'
import LoopIcon from '@mui/icons-material/Loop'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { readableDate } from '../../../helpers/dataFormats'
import noThumbnail from '../../../images/ic_no-thumbnail.svg?url'
import config from '../../../config/config'
import LinearProgress from '@mui/material/LinearProgress'
import { showNotification } from '../../../store/notification/actionCreator'
import withRouter from '../../../components/HOC/withRouterHOC'
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'
import _ from 'lodash'

import searchLogo from '../../../images/enterprise/searchLogo.svg?url'

import {
  fetchTemplates,
  clearTemplateList,
  downloadTemplate,
  fetchBusinessDepartments,
} from '../../../store/template/actionCreator'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import TableView from '../../../components/TemplateUpload/MyUploads'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { createPortal } from 'react-dom'
import NewTemplate from '../../../components/TemplateUpload/NewTemplate'
import TemplateRefresh from '../../../components/TemplateUpload/TemplateRefresh'

import ContentEnd from '../../../components/IntersectionDetector'
import NewTemplateCard from '../../../components/TemplateUpload/NewTemplateCard'
import ErrorSummary from '../../../components/TemplateUpload/Summary'
import { formatErrorTable } from '../../../components/TemplateUpload/ErrorTable'
import { isMemberOfAppArtAdmin } from '../../../helpers/utils'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    textDecoration: 'none',
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'Calc(100vh - 206px)',
    overflow: 'scroll',
  },
  card: {
    margin: '0px 15px',
    marginBottom: '30px',
  },
  headerWrapper: {
    padding: '15px 0px 30px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentEnd: {
    height: 10,
    flex: '0 0 100%',
  },
  noSearchResultContext: {
    padding: 20,
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 300,
    margin: '220px auto !important',
  },
  resultKeyword: {
    fontWeight: 1000,
  },
  deletePopver: {
    borderRadius: 5,
    padding: 20,
  },
  popoverButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginLeft: 50,
  },
  sideBar: {
    width: 450,
    height: `calc(100vh - 60px)`,
    background: '#FFFFFF',
    top: 0,
    right: 0,
    position: 'fixed',
    zIndex: 1211,
    padding: 30,
    overflow: 'scroll',
  },
  errorSideBar: {
    width: '70%',
    height: `calc(100vh - 60px)`,
    background: '#FFFFFF',
    top: 0,
    right: 0,
    position: 'fixed',
    zIndex: 1211,
    padding: '30px 0',
    overflowY: 'auto',
  },
})
const DEFAULT_PER_PAGE = 15

const RenderInfoPopup = ({ errorText }) => {
  return (
    <>
      <p
        style={{
          color: '#000',
          fontSize: 12,
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: 15,
          fontWeight: '500',
        }}
      >
        Error Details
      </p>
      {errorText.map((error) => {
        return (
          <p
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: 12,
              paddingLeft: 10,
              paddingRight: 10,
              marginBottom: 5,
            }}
          >
            {error}
          </p>
        )
      })}
      <p style={{ padding: 0, marginBottom: 0 }}>&nbsp;</p>
    </>
  )
}

class LayoutsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageNum: 1,
      perPage: DEFAULT_PER_PAGE,
      searchVal: '',
      sortBy: '',
      sortOrder: 'DESC',
      renderTypeList: [
        { name: 'All Layouts', value: 'All' },
        { name: 'V-Ray', value: 'V-Ray' },
        { name: 'Corona', value: 'Corona' },
      ],
      team: [],
      renderer: '',
      alignment: 'grid',
      isAllUpload: true,
      showNewTemplateForm: false,
      showTempaleRefreshForm: false,
      backDropOpen: false,
      currentTemplateData: {},
      openError: false,
    }
  }

  componentDidMount() {
    const {
      templates: { businessDepts = [] },
      fetchBusinessDepartments,
    } = this.props
    if (!businessDepts.length) {
      fetchBusinessDepartments()
    }
    this.setState(
      { pageNum: 1, searchVal: '', sortBy: '', sortOrder: 'DESC' },
      () => {
        this.fetchLayouts()
      }
    )
  }

  componentWillUnmount() {
    const { clearTemplateList } = this.props
    clearTemplateList()
  }

  interSectionCallback = (entries, observer) => {
    const { perPage, pageNum, searchVal, team, renderer, sort } = this.state
    const { fetchTemplates, templates } = this.props

    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (
          !templates.loading &&
          templates.total_count > templates.templateList.length
        ) {
          fetchTemplates(
            pageNum + 1,
            perPage,
            searchVal,
            team,
            renderer,
            sort,
            () => {
              this.setState({
                pageNum: this.state.pageNum + 1,
              })
            }
          )
        }
      }
    })
  }

  fetchLayouts() {
    const { perPage, pageNum, searchVal, renderer, sort, team, isAllUpload } =
      this.state
    const { fetchTemplates } = this.props
    fetchTemplates(
      pageNum,
      perPage,
      searchVal,
      team,
      renderer,
      sort,
      () => {},
      () => {},
      isAllUpload
    )
  }

  noSearchContent = () => {
    const { classes, templates } = this.props
    return (
      <DialogContentText className={classes.noSearchResultContext}>
        <SearchOffRoundedIcon
          sx={{
            width: '120px',
            height: '120px',
            margin: 'auto',
            display: 'block',
          }}
        />
        <Typography variant={'h6'} label={<span>No results found</span>} />
        <Typography
          variant={'body2'}
          label={`Please make sure your words are spelled correctly or use different keywords.`}
        />
      </DialogContentText>
    )
  }

  handleSelectChangeTeam = (e) => {
    let value = e.target.value === 'All' ? '' : e.target.value
    const { clearTemplateList } = this.props
    clearTemplateList()
    this.setState({ team: value, pageNum: 1 }, () => this.fetchLayouts())
  }

  handleTypeChange = (e) => {
    let value = e.target.value === 'All' ? '' : e.target.value
    const { clearTemplateList } = this.props
    clearTemplateList()
    this.setState({ renderer: value, pageNum: 1 }, () => this.fetchLayouts())
  }

  onCross = () => {
    this.props.clearTemplateList()
    this.setState({ pageNum: 1, searchVal: '' }, () => {
      this.fetchLayouts()
    })
  }

  searchLayouts = _.debounce((searchVal) => {
    this.props.clearTemplateList()
    this.setState({ pageNum: 1, searchVal }, () => {
      this.fetchLayouts()
    })
  }, 300)

  handleAlignment = (event, value) => {
    this.setState({
      alignment: value,
      ...(value === 'grid' && { perPage: DEFAULT_PER_PAGE }),
    })
  }
  handleAllUploads = (e) => {
    const { clearTemplateList } = this.props
    clearTemplateList()
    this.setState(
      {
        isAllUpload: !(e.target.value === 'myUploads'),
        pageNum: 1,
      },
      () => {
        this.fetchLayouts()
      }
    )
  }
  openNewTemplateForm = () => {
    this.setState({ showNewTemplateForm: true })
  }
  closeForm = () => {
    this.setState({ showNewTemplateForm: false, showTempaleRefreshForm: false })
  }

  handleUpdateFromListView = ({ type, value }) => {
    const { clearTemplateList } = this.props
    const { pageNum } = this.state

    if (type === 'perPageCount') {
      clearTemplateList()
      this.setState({ perPage: value, pageNum: 1 }, () => {
        this.fetchLayouts()
      })
    }
    if (type === 'pageUpdate') {
      this.setState({ pageNum: value }, () => this.fetchLayouts())
    }
  }

  openErrorSummary = (template) => {
    this.setState({
      currentTemplateData: template,
      openError: true,
    })
  }

  openTemplateRefresh = (template) => {
    this.setState({
      showTempaleRefreshForm: true,
      currentTemplateData: template,
    })
  }
  closeTemplateRefresh = () => {
    this.setState({ currentTemplateData: {}, showTempaleRefreshForm: false })
  }

  render() {
    const { history } = this.props
    const {
      classes,
      auth,
      auth: { lanId, fullName, memberOf },
      templates: {
        templateList,
        loading,
        total_count,
        savedQuery,
        businessDepts,
      },
      downloadTemplate,
    } = this.props

    const {
      renderTypeList,
      searchVal,
      alignment,
      team,
      showNewTemplateForm,
      showTempaleRefreshForm,
      pageNum,
      perPage,
      currentTemplateData,
      openError,
    } = this.state

    const isAppArtAdmin = isMemberOfAppArtAdmin(memberOf)

    const layoutsList = businessDepts.map(({ team, team_display_value }) => {
      return { name: team_display_value, value: team }
    })

    return (
      <>
        <div className={classes.root}>
          <div className={classes.headerWrapper}>
            <div style={{ marginRight: 15 }}>
              <PageTitleBar
                title={'Templates'}
                subTitle={`Browse from ${total_count || 0} templates`}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '150px', marginRight: 15, marginLeft: 20 }}>
                <Select
                  color={'tertiary'}
                  size="small"
                  inputLabel="Uploads"
                  value="All"
                  options={[
                    { name: 'All Uploads', value: 'All' },
                    { name: 'My Uploads', value: 'myUploads' },
                  ]}
                  onChange={this.handleAllUploads}
                />
              </div>
              <div style={{ width: '150px', marginRight: 15 }}>
                <Select
                  color="primary"
                  size={'small'}
                  multiple
                  value={team && Array.isArray(team) ? team : []}
                  displayCheckBox
                  renderValue={(selected) => selected.join(', ')}
                  inputLabel="Department"
                  onChange={this.handleSelectChangeTeam}
                  options={layoutsList}
                />
              </div>
              <div style={{ width: '150px', marginRight: 15 }}>
                <Select
                  color={'tertiary'}
                  size="small"
                  inputLabel="Type"
                  value="All"
                  onChange={this.handleTypeChange}
                  options={renderTypeList}
                />
              </div>
            </div>

            <div style={{ marginLeft: 50, marginRight: 'auto' }}>
              <SearchComponent
                height={40}
                onSearch={this.searchLayouts}
                onSearchButtonClick={this.onCross}
                placeholder={`Search template name...`}
                searchVal={searchVal}
                width={320}
                brandLogo={searchLogo}
              />
            </div>
            <div style={{ marginRight: 'auto' }}>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={this.handleAlignment}
                aria-label="text alignment"
                size="small"
              >
                <ToggleButton
                  value="grid"
                  aria-label="grid aligned"
                  color="primary"
                >
                  <GridViewRoundedIcon />
                </ToggleButton>
                <ToggleButton
                  value="list"
                  aria-label="list aligned"
                  color="primary"
                >
                  <FormatListBulletedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          {alignment === 'grid' ? (
            <div className={classes.innerContainer}>
              <NewTemplateCard onClick={this.openNewTemplateForm} />
              {!templateList.length && !loading && this.noSearchContent()}
              {templateList &&
                templateList.map((item, i) => {
                  let errorText = []
                  if (
                    item.asset_error_details &&
                    item.asset_error_details != null &&
                    Object.keys(item.asset_error_details).length
                  ) {
                    const errordata = formatErrorTable(item.asset_error_details)

                    Object.keys(errordata).forEach((key) => {
                      if (errordata[key].length) {
                        errorText.push(
                          `${errordata[key].length} ${key}(s) with errors`
                        )
                      }
                    })
                  }
                  const footerItems = [
                    {
                      icon: DescriptionIcon,
                      onClick: () => {
                        this.openErrorSummary(item)
                      },
                      color: '#CC0000',
                      title: 'Upload Summary',
                    },

                    {
                      icon: LoopIcon,
                      onClick: () => {
                        this.openTemplateRefresh(item)
                      },
                      title: 'Refresh',
                    },
                    {
                      icon: DownloadIcon,
                      onClick: () => {
                        downloadTemplate(
                          item.template_name,
                          item.template_high_res_url
                        )
                      },
                      title: 'Download',
                    },
                  ]

                  if (isAppArtAdmin) {
                    footerItems.push({
                      icon: EditOutlinedIcon,
                      onClick: () => {
                        history.push('/template/' + item.template_id)
                      },
                      title: 'Edit layout',
                    })
                  }
                  return (
                    <div className={classes.card} key={i}>
                      <TemplateCard
                        date={readableDate(item.created_date)}
                        imgHeight={250}
                        loginId={lanId}
                        maxWidth={250}
                        minWidth={250}
                        config={config}
                        isUrlAppendedWithImg={true}
                        noThumbnail={noThumbnail}
                        name={item.template_name}
                        thumbnail={item.template_thumbnail_url}
                        size={'medium'}
                        subTitle={item.template_tags.join(', ')}
                        assetError={
                          item.low_poly_template_available &&
                          !item.high_low_poly_available
                        }
                        templateError={!item.low_poly_template_available}
                        infoDetails={{
                          icon: InfoOutlinedIcon,
                          color: '#AE570F',
                          title: 'info',
                          data:
                            errorText && errorText.length ? (
                              <RenderInfoPopup errorText={errorText} />
                            ) : (
                              ''
                            ),
                        }}
                        footerItems={footerItems}
                      />
                    </div>
                  )
                })}
              <ContentEnd
                className={classes.contentEnd}
                callback={this.interSectionCallback}
              />
            </div>
          ) : (
            <>
              <div style={{ paddingBottom: 15 }}>
                <CustomButton
                  label="New Template"
                  startIcon={<AddRoundedIcon size="small" />}
                  onClick={this.openNewTemplateForm}
                ></CustomButton>
              </div>
              <div
                className={classes.innerContainer}
                style={{ height: 'calc(100vh - 248px)' }}
              >
                <TableView
                  tableList={templateList}
                  totalCount={total_count}
                  currentPage={pageNum}
                  perPage={perPage}
                  handleUpdateFromListView={this.handleUpdateFromListView}
                  downloadTemplate={downloadTemplate}
                  openTemplateRefresh={this.openTemplateRefresh}
                  openErrorSummary={this.openErrorSummary}
                />
              </div>
            </>
          )}
          <div>{loading && <LinearProgress />}</div>
          {(showNewTemplateForm || showTempaleRefreshForm) &&
            createPortal(
              <>
                <div className={classes.sideBar}>
                  {showNewTemplateForm ? (
                    <NewTemplate
                      onClose={this.closeForm}
                      onNewAdd={this.onCross}
                    />
                  ) : (
                    <TemplateRefresh
                      templateData={currentTemplateData}
                      onClose={this.closeForm}
                      onRefresh={this.onCross}
                    />
                  )}
                </div>
                <Backdrop
                  sx={{ zIndex: (theme) => theme.zIndex.drawer + 10 }}
                  open={showNewTemplateForm || showTempaleRefreshForm}
                  onClick={this.closeForm}
                ></Backdrop>
              </>,
              document.body
            )}
        </div>
        {openError &&
          createPortal(
            <>
              <div className={classes.errorSideBar}>
                <ErrorSummary
                  templateDetails={currentTemplateData}
                  toggleError={(open) => this.setState({ openError: open })}
                  open={openError}
                  userName={fullName}
                />
              </div>
              <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={openError}
                onClick={() => {
                  this.setState({ openError: false })
                }}
              ></Backdrop>
            </>,
            document.body
          )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { experiences, auth, templates } = state
  const {
    experienceList,
    totalExperienceCount,
    initiatorList,
    isShow,
    noResultsFound,
    listPageMeta,
    experienceListloading,
  } = experiences
  return {
    experienceList,
    totalExperienceCount,
    initiatorList,
    isShow,
    noResultsFound,
    listPageMeta,
    auth,
    experienceListloading,
    templates,
  }
}

export default connect(mapStateToProps, {
  fetchExperience,
  clearExperiences,
  deleteScene,
  showNotification,
  fetchTemplates,
  clearTemplateList,
  downloadTemplate,
  fetchBusinessDepartments,
})(withStyles(styles)(withRouter(LayoutsPage)))
