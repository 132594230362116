import { createStore, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import reduxThunk from 'redux-thunk'
import rootReducer from './index'

export const middleware = [reduxThunk]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant())
}

export default function configureStore() {
  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  )
}
