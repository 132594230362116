import React from 'react'
import { styled, makeStyles } from '@mui/styles'
import {
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Collapse,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { CustomIconButton, CustomButton } from 'cgi-ui-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { openSlackChannel } from '../../helpers/utils'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: '#FCDEC5',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2B2D31',
    color: '#E6E7EA',
    borderBottom: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    background: 'none',
    borderBottom: 0,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme, groupheader }) => ({
  background: groupheader ? '#F6F7F8' : '#FCDEC5',
  borderBottom: `1px solid ${groupheader ? '#2B2D31' : '#AE570F'}`,
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const useStyles = makeStyles((theme) => ({
  errorTableContainer: {
    marginTop: 20,
  },
}))

export const formatErrorTable = (data) => {
  const response = {}
  if (data && data !== null && Object.keys(data).length) {
    Object.keys(data).forEach((key) => {
      const item = data[key]
      if (response[item.asset_type] && response[item.asset_type].length) {
        response[item.asset_type].push({ ...item, name: key })
      } else {
        response[item.asset_type] = [{ ...item, name: key }]
      }
    })
  }
  return response
}

const TemplateBaseErrorTable = ({ data, ...props }) => {
  const classes = useStyles()
  return (
    <TableContainer component={Paper} className={classes.errorTableContainer}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Error</StyledTableCell>
            <StyledTableCell align="right">Next Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => {
            return (
              <StyledTableRow key={i}>
                <StyledTableCell>{item.description}</StyledTableCell>
                <StyledTableCell align="right">--</StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const CollapsibleRows = ({ name, data, ...props }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <StyledTableRow key={1} groupheader>
        <StyledTableCell>{name}(s)</StyledTableCell>
        <StyledTableCell>
          <Chip label={`Failed for ${data.length} ${name}(s)`} />
        </StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell align="right">
          <CustomIconButton onClick={() => setOpen(!open)} label={'close'}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </CustomIconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ padding: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="customized table">
              {data.map((item, key) => {
                return (
                  <StyledTableRow key={key}>
                    <StyledTableCell
                      style={{
                        width: 200,
                        maxWidth: 200,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                      title={item.name}
                    >
                      {item.name}
                    </StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>
                      {item.type.includes('business')
                        ? 'Business Error'
                        : 'Tech Error'}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <CustomButton
                        label="Post to Channel"
                        variant="text"
                        color={'primary'}
                        onClick={() => {
                          openSlackChannel(item.type.includes('business'))
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </Table>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </>
  )
}

const AssetsErrorTable = ({ data, ...props }) => {
  const classes = useStyles()
  const tabledata = formatErrorTable(data)
  console.log(tabledata)
  return (
    <TableContainer component={Paper} className={classes.errorTableContainer}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Error(s)</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Error type</StyledTableCell>
            <StyledTableCell>Next Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(tabledata).map((item) => {
            return <CollapsibleRows data={tabledata[item]} name={item} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { TemplateBaseErrorTable, AssetsErrorTable }
