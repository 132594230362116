import React from 'react'

import { styled } from '@mui/material/styles'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined'
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined'

import { Tooltip } from 'cgi-ui-components'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, barPlacement }) => ({
    backgroundColor: '#FFF',

    width: '100%',
    justifyContent: 'space-evenly',
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
      },
    },
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
  })
)

export default function ModeSelectionButtons({
  toggleView = () => {},
  viewMode = '3D',
}) {
  const [view, setView] = React.useState(viewMode)

  const handleChange = (event, nextView) => {
    setView(nextView)
    toggleView(nextView)()
  }

  return (
    <StyledToggleButtonGroup
      orientation="horizontal"
      value={view}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="3D" aria-label="list" disabled={view === '3D'}>
        <Tooltip title={'View in 3D'}>
          <ViewInArOutlinedIcon />
        </Tooltip>
      </ToggleButton>

      <ToggleButton value="2D" aria-label="list" disabled={view === '2D'}>
        <Tooltip title={'View in 2D'}>
          <ViewQuiltOutlinedIcon />
        </Tooltip>
      </ToggleButton>
    </StyledToggleButtonGroup>
  )
}
