import axios from 'axios'
import {
  FETCH_BUSINESS_DEPARTMENTS,
  FETCH_BUSINESS_DEPARTMENTS_SUCCESS,
  FETCH_BUSINESS_DEPARTMENTS_FAIL,
  FETCH_FILE_PATH_VALIDATION,
  FETCH_FILE_PATH_VALIDATION_SUCCESS,
  FETCH_FILE_PATH_VALIDATION_FAIL,
  SAVE_TEMPLATE_FAILED,
  SAVE_TEMPLATE_SUCCESS,
  FETCH_PROJECT_TYPE,
  FETCH_PROJECT_TYPE_FAIL,
  FETCH_PROJECT_TYPE_SUCCESS,
  FETCH_MY_UPLOADS,
  FETCH_MY_UPLOADS_SUCCESS,
  SAVE_TEMPLATE_INITIATE,
  INGEST_TEMPLATE_INITIATE,
  INGEST_TEMPLATE_SUCCESS,
  INGEST_TEMPLATE_FAILED,
} from './ActionType'

import api from '../../config/config'

export function apiCallInitiated({ type, data }) {
  return {
    type,
    data,
  }
}

export function getBusinessDepartments() {
  return function (dispatch) {
    let url = api.uploadTemplate.getBusinessDepartment
    dispatch(
      apiCallInitiated({
        type: FETCH_BUSINESS_DEPARTMENTS,
        data: [],
      })
    )

    return axios
      .get(url)
      .then((res) => dispatch(getBusinessDepartmentsSuccess(res.data)))
      .catch((error) => {
        dispatch(getBusinessDepartmentsFail(error))
      })
  }
}

export function getFilePathValidation(query) {
  return function (dispatch) {
    let url = `${api.uploadTemplate.validatePath}?${query}`
    dispatch(
      apiCallInitiated({
        type: FETCH_FILE_PATH_VALIDATION,
        data: [],
      })
    )

    return axios
      .get(url)
      .then((res) => dispatch(getFilePathValidationSuccess(res.data)))
      .catch((error) => {
        dispatch(getFilePathValidationFail(error))
      })
  }
}

export function getQaPluginStatus(templateName) {
  let url = `${api.uploadTemplate.getQaPluginStatus}/${templateName}`
  return axios.get(url)
}

export function saveTemplate(postData) {
  return function (dispatch) {
    let url = `${api.uploadTemplate.saveTemplate}`
    dispatch(
      apiCallInitiated({
        type: SAVE_TEMPLATE_INITIATE,
        data: [],
      })
    )
    return axios
      .post(url, postData)
      .then((res) => dispatch(saveTemplateSuccess(res.data)))
      .catch((error) => {
        dispatch(saveTemplateFail(error.response.data))
      })
  }
}

export function getProjectTypes() {
  return function (dispatch) {
    let url = api.uploadTemplate.getProjectTypes
    dispatch(
      apiCallInitiated({
        type: FETCH_PROJECT_TYPE,
        data: [],
      })
    )

    return axios
      .get(url)
      .then((res) => dispatch(getProjectTypesSuccess(res.data)))
      .catch((error) => {
        dispatch(getProjectTypesFail(error))
      })
  }
}

export function ingestTemplate(postData) {
  return function (dispatch) {
    let url = `${api.uploadTemplate.ingestTemplate}`
    dispatch(
      apiCallInitiated({
        type: INGEST_TEMPLATE_INITIATE,
        data: [],
      })
    )

    return axios
      .post(url, postData)
      .then((res) =>
        dispatch({ type: INGEST_TEMPLATE_SUCCESS, data: res.data })
      )
      .catch((error) => {
        dispatch({
          type: INGEST_TEMPLATE_FAILED,
          error: error.response.data,
          data: {},
        })
      })
  }
}

export function getBusinessDepartmentsSuccess(data) {
  return {
    type: FETCH_BUSINESS_DEPARTMENTS_SUCCESS,
    data,
  }
}

export function getBusinessDepartmentsFail(error) {
  return {
    type: FETCH_BUSINESS_DEPARTMENTS_FAIL,
    error,
    data: [],
  }
}

export function getFilePathValidationSuccess(data) {
  return {
    type: FETCH_FILE_PATH_VALIDATION_SUCCESS,
    data,
  }
}

export function getFilePathValidationFail(error) {
  return {
    type: FETCH_FILE_PATH_VALIDATION_FAIL,
    error,
    data: [],
  }
}

export function saveTemplateFail(error) {
  return {
    type: SAVE_TEMPLATE_FAILED,
    error,
    data: [],
  }
}

export function saveTemplateSuccess(data) {
  return {
    type: SAVE_TEMPLATE_SUCCESS,
    data,
  }
}

export function getProjectTypesFail(error) {
  return {
    type: FETCH_PROJECT_TYPE_FAIL,
    error,
    data: [],
  }
}

export function getProjectTypesSuccess(data) {
  return {
    type: FETCH_PROJECT_TYPE_SUCCESS,
    data,
  }
}
