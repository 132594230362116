export const SET_RENDER_DATA = 'SET_RENDER_DATA'
export const SET_CONTROLS_POSITION = 'SET_CONTROLS_POSITION'
export const SET_SCENE_DATA = 'SET_SCENE_DATA'
export const GET_USER_CAMERA = 'GET_USER_CAMERA'
export const RESET_SCENE_DATA = 'RESET_SCENE_DATA'
export const SET_RENDER_DETAILS = 'SET_RENDER_DETAILS'
export const SET_RENDER_FORM_DATA = 'SET_RENDER_FORM_DATA'
export const SHOW_SCENE_EDIT_FORM = 'SHOW_SCENE_EDIT_FORM'
export const SET_DEFAULT_NOISE_BASED_ON_RENDERER =
  'SET_DEFAULT_NOISE_BASED_ON_RENDERER'
export const SET_SCENE_MODE = 'SET_SCENE_MODE'
export const SET_NEW_CAMERA = 'SET_NEW_CAMERA'
export const SET_CAMERA_SNAPSHOT = 'SET_CAMERA_SNAPSHOT'
export const DELETE_CAMERA_VIEW = 'DELETE_CAMERA_VIEW'
export const SAVE_SCENE_INITIATED = 'SAVE_SCENE_INITIATED'
export const SAVE_SCENE_COMPLETE = 'SAVE_SCENE_COMPLETE'
