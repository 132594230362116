import React from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { PageTitleBar, AssetCard } from 'cgi-ui-components'

import { pluralise } from '../../helpers/utils'
import DraggableAssetCard from '../assetExplorerCard/DraggableAssetCard'

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  assetContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 20,
    flexWrap: 'wrap',
    rowGap: 20,
  },
}))

const previewBoard = (props) => {
  const styles = useStyles()
  const { assets } = useSelector(({ Board }) => {
    return { assets: Board.assets }
  })
  return (
    <div>
      {assets.length ? (
        <div>
          <div className={styles.title}>
            <PageTitleBar
              title="Styling board"
              subTitle={`Total ${pluralise('product', assets.length)}`}
            />
          </div>
          <div className={styles.assetContainer}>
            {assets.map((element, index) => {
              return (
                <DraggableAssetCard
                  data={{
                    ...element,
                    tcin: element.value,
                    short_description:
                      element.product_description &&
                      element.product_description.short_description,
                  }}
                />
              )
            })}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default previewBoard
