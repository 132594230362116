import {
  DETECT_OBJECT_INITIATED,
  DETECT_OBJECT_SUCCESS,
  DETECT_OBJECT_FAIL,
  FETCH_IMAGE_LABELS_INITIATED,
  FETCH_IMAGE_LABELS_SUCCESS,
  FETCH_IMAGE_LABELS_FAIL,
  UPLOAD_ASSETS_INITIATED,
  UPLOAD_ASSETS_SUCCESS,
  UPLOAD_ASSETS_FAIL,
} from './ActionType'

export const initialStateObjectDetection = {
  objectDetectionList: [],
  loadingObjectDetection: false,
  imageLabelList: [],
  loadingImageLabel: false,
  uploadAssetsData: {},
  loadingUploadAssets: false,
  loadingStatusUpload: false,
  getStatusUploadData: {},
}

export default function uploadAssets(
  state = initialStateObjectDetection,
  action
) {
  switch (action.type) {
    case DETECT_OBJECT_INITIATED: {
      return {
        ...state,
        loadingObjectDetection: true,
        objectDetectionList: [],
      }
    }
    case DETECT_OBJECT_SUCCESS: {
      return {
        ...state,
        loadingObjectDetection: false,
        objectDetectionList: action.data.annotations,
      }
    }
    case DETECT_OBJECT_FAIL: {
      return {
        ...state,
        loadingObjectDetection: false,
      }
    }
    case FETCH_IMAGE_LABELS_INITIATED: {
      return {
        ...state,
        loadingImageLabel: true,
        imageLabelList: [],
      }
    }
    case FETCH_IMAGE_LABELS_SUCCESS: {
      return {
        ...state,
        loadingImageLabel: false,
        imageLabelList: action.data.annotations,
      }
    }
    case FETCH_IMAGE_LABELS_FAIL: {
      return {
        ...state,
        loadingImageLabel: false,
      }
    }
    case UPLOAD_ASSETS_INITIATED: {
      return {
        ...state,
        loadingUploadAssets: true,
        uploadAssetsData: {},
      }
    }
    case UPLOAD_ASSETS_SUCCESS: {
      return {
        ...state,
        loadingUploadAssets: false,
        uploadAssetsData: action.data,
      }
    }
    case UPLOAD_ASSETS_FAIL: {
      return {
        ...state,
        loadingUploadAssets: false,
      }
    }

    default:
      return state
  }
}
