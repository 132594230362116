export const FETCH_PURCHASED_ASSETS_INITIATED =
  'FETCH_PURCHASED_ASSETS_INITIATED'
export const FETCH_PURCHASED_ASSETS_SUCCESS = 'FETCH_PURCHASED_ASSETS_SUCCESS'
export const FETCH_PURCHASED_ASSETS_FAILED = 'FETCH_PURCHASED_ASSETS_FAILED'
export const CLEAR_RESULTS = 'CLEAR_RESULTS'
export const CLEAR_PURCHASED_ASSET_RESULTS = 'CLEAR_PURCHASED_ASSET_RESULTS'

export const DELETE_PURCHASE_ASSETS_INITIATED =
  'DELETE_PURCHASE_ASSETS_INITIATED'
export const DELETE_PURCHASE_ASSETS_SUCCESS = 'DELETE_PURCHASE_ASSETS_SUCCESS'
export const DELETE_PURCHASE_ASSETS_FAIL = 'DELETE_PURCHASE_ASSETS_FAIL'

export const EDIT_PURCHASE_ASSETS_INITIATED = 'EDIT_PURCHASE_ASSETS_INITIATED'
export const EDIT_PURCHASE_ASSETS_SUCCESS = 'EDIT_PURCHASE_ASSETS_SUCCESS'
export const EDIT_PURCHASE_ASSETS_FAIL = 'EDIT_PURCHASE_ASSETS_FAIL'

export const SAVE_PURCHASE_ASSETS_INITIATED = 'SAVE_PURCHASE_ASSETS_INITIATED'
export const SAVE_PURCHASE_ASSETS_SUCCESS = 'SAVE_PURCHASE_ASSETS_SUCCESS'
export const SAVE_PURCHASE_ASSETS_FAIL = 'SAVE_PURCHASE_ASSETS_FAIL'

export const FETCH_PURCHASE_UPLOAD_STATUS_INITIATED =
  'FETCH_PURCHASE_UPLOAD_STATUS_INITIATED'
export const FETCH_PURCHASE_UPLOAD_STATUS_FAILED =
  'FETCH_PURCHASE_UPLOAD_STATUS_FAILED'
export const FETCH_PURCHASE_UPLOAD_STATUS_SUCCESS =
  'FETCH_PURCHASE_UPLOAD_STATUS_SUCCESS'
export const PURCHASE_UPLOAD_COMPLETED = 'PURCHASE_UPLOAD_COMPLETED'
export const SET_TOTAL_ASSET_COUNT = 'SET_TOTAL_ASSET_COUNT'
export const FILTER_LISTS_SUCCESS = 'TAB_FILTER_LISTS_SUCCESS'
export const FILTER_LISTS_CHANGE = 'TAB_FILTER_LISTS_CHANGE'
export const FILTER_COUNT_CHANGE = 'TAB_FILTER_COUNT_CHANGE'
export const CLEAR_ALL_FILTERS = 'TAB_CLEAR_ALL_FILTERS'

export const HANDLE_SELECT_PURCHASED_ASSET = 'HANDLE_SELECT_PURCHASED_ASSET'
export const FETCH_STYLED_ASSETS_INITIATED = 'TAB_FETCH_STYLED_ASSETS_INITIATED'
export const FETCH_STYLED_ASSETS_SUCCESS = 'TAB_FETCH_STYLED_ASSETS_SUCCESS'
export const CLEAR_STYLED_ASSETS = 'TAB_CLEAR_STYLED_ASSETS'
export const FETCH_SIMILAR_ASSETS_ASSETTAB_SUCCESS =
  'FETCH_SIMILAR_ASSETS_ASSETTAB_SUCCESS'
export const CLEAR_SIMILAR_ASSETS_ASSETTAB = 'CLEAR_SIMILAR_ASSETS_ASSETTAB'
