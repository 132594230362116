import axios from 'axios'
import config from '../../config/config'
import {
  SET_LIBRARY_LIST,
  SET_FAV_LIST_ID,
  SET_SRC_MATERIAL_LIST,
  SET_SELECTED_LIBRARY,
  SET_FAV_LIST,
  UPDATE_SRC_MATERIAL_DATA,
  UPDATE_SELECTED_COLOR,
  UPDATE_SELECTED_TEXTURE,
  UPDATE_FAVS_LIBRARY,
  CLEAR_DATA,
  RESET_TO_ORIGINAL_MATERIAL_LIST,
  FETCH_METADATA_INITIATED,
  FETCH_METADATA_SUCCESS,
  FETCH_MATERIAL_LIST_INITIATED,
} from './actionType'

export function fetchLibraryList(type) {
  let url =
    type === 'color'
      ? config.materialEditor.fetchColorLibraryList
      : config.materialEditor.fetchTextureLibraryList

  return function (dispatch) {
    axios.get(url).then(({ data }) => {
      const favLibrary = data.libraries.filter(
        (item) => item.library_name === 'Favourites'
      )[0]

      dispatch(setFavouriteListId(favLibrary.library_id))
      dispatch(setLibraryList(data.libraries))
    })
  }
}

export function fetchLibraryMetadata(listId, type, isFav) {
  return function (dispatch) {
    dispatch({ type: FETCH_METADATA_INITIATED })
    let url =
      type === 'color'
        ? `${config.materialEditor.getColorLibraryMetadata}${listId}`
        : `${config.materialEditor.getTextureLibraryMetadata}${listId}`

    axios.get(url).then(({ data }) => {
      dispatch(
        setSelectedLibraryDetails({
          library_id: data.library_id,
          library_name: data.library_name,
          library_details: data.library_details,
        })
      )

      if (isFav) {
        dispatch(
          setFavouriteList({
            library_id: data.library_id,
            library_name: data.library_name,
            library_details: data.library_details,
          })
        )
      }
      dispatch({ type: FETCH_METADATA_SUCCESS })
    })
  }
}

export const setLibraryList = (library) => {
  return { type: SET_LIBRARY_LIST, data: library }
}

export const setFavouriteListId = (data) => {
  return { type: SET_FAV_LIST_ID, data }
}

export function fetchSrcMaterialList(assetname, sceneId, assetId) {
  return function (dispatch) {
    dispatch({ type: FETCH_MATERIAL_LIST_INITIATED })
    const url = `${config.materialEditor.getSrcMaterialList}?asset_name=${assetname}&scene_id=${sceneId}&asset_id=${assetId}`
    axios
      .get(url)
      .then((response) => dispatch(setSrcMaterialList(response.data)))
  }
}

export const setSrcMaterialList = (data) => {
  return {
    type: SET_SRC_MATERIAL_LIST,
    data,
  }
}

export function setSelectedLibraryDetails(data) {
  return {
    type: SET_SELECTED_LIBRARY,
    data,
  }
}

export function setFavouriteList(data) {
  return {
    type: SET_FAV_LIST,
    data,
  }
}

export const updateSrcMaterial = (data) => {
  return { type: UPDATE_SRC_MATERIAL_DATA, data }
}

export const updateSelectedColor = (data) => {
  return { type: UPDATE_SELECTED_COLOR, data }
}

export const updateSelectedTexture = (data) => {
  return {
    type: UPDATE_SELECTED_TEXTURE,
    data,
  }
}

export function updateFavouritesLibrary({
  isFavLibSelected,
  list,
  listId,
  type,
}) {
  let url =
    type === 'color'
      ? config.materialEditor.updateColorLibrary
      : config.materialEditor.updateTextureLibrary
  return function (dispatch) {
    axios
      .put(url, {
        library_id: listId,
        library_details: list,
      })
      .then((response) => {
        console.log(response)
      })
    dispatch(updateFavouritesLibrarySuccess({ isFavLibSelected, list }))
  }
}

export const updateFavouritesLibrarySuccess = (data) => {
  return {
    type: UPDATE_FAVS_LIBRARY,
    data,
  }
}

export function clearData(shouldClearAllData) {
  return function (dispatch) {
    dispatch({ type: CLEAR_DATA, data: { shouldClearAllData } })
  }
}

export function resetToOriginalMaterialList() {
  return function (dispatch) {
    dispatch({ type: RESET_TO_ORIGINAL_MATERIAL_LIST })
  }
}
