import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { uniqBy, debounce, isEmpty } from 'lodash'
import {
  DialogContentText,
  DialogContent,
  Box,
  CircularProgress,
} from '@mui/material'
import { Routes, Route } from 'react-router-dom'

import {
  fetchCollection,
  clearCollection,
  fetchAssetsForFavorite,
  updateCollectionName,
  deleteCollection,
  addAssetsToCollection,
  createCollectionSave,
} from '../../../store/favorites/ActionCreator'

import {
  PageTitleBar,
  Select,
  Typography,
  FavouriteCard,
  BreadcrumbComponent,
  CustomButton,
  AssetCard,
  Dialog,
  SearchComponent,
} from 'cgi-ui-components'
import { Typography as TypoText } from '@mui/material'
import {
  FAVOURITE_DROPDOWN_VALUES,
  getCurrentOS,
  OS,
} from '../../../helpers/utils'
import { copyToClipboard } from '../../../helpers/copyToClipboard'
import noThumbnail from '../../../images/ic_no-thumbnail.svg?url'
import config from '../../../config/config'
import { showNotification } from '../../../store/notification/actionCreator'
import withRouter from '../../../components/HOC/withRouterHOC'
import searchLogo from '../../../images/enterprise/searchLogo.svg?url'
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import LinkIcon from '@mui/icons-material/Link'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddIcon from '@mui/icons-material/Add'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CollectionEditForm from '../../../components/collection/collectionDetailsEditForm'
import DataList from '../../../components/DataList'
import ShareExperienceDialog from '../../Components/ShareExperiencePopup'
import Popover from '@mui/material/Popover'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import AssetExplorer from '../../../components/AssetExplorer'
import ExplorerFooter from '../../../components/AssetExplorer/Footer'
import { mapInfoForCard } from '../../../helpers/utils'

const styles = (theme) => ({
  card: {
    margin: '0px 15px',
    marginBottom: '30px',
  },
  headerWrapper: {
    padding: '15px 0px 30px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  noSearchResultContext: {
    padding: 20,
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 300,
    margin: '220px auto !important',
  },
  resultKeyword: {
    fontWeight: 1000,
  },
  deletePopover: {
    borderRadius: 5,
    padding: 20,
  },
  popoverButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginLeft: 50,
  },
  secondaryBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },
  dialogPaperDefault: {
    width: '80% !important',
    padding: 20,
  },
  link: {
    color: '#3F51B5',
    textDecoration: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    paddingTop: 20,
    marginLeft: 'auto',
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  loaderStyle: {
    marginLeft: '10px',
  },
})

class Favourites extends React.Component {
  constructor(props) {
    super(props)
    this.contentEndRef = React.createRef()
    this.state = {
      pageNum: 1,
      perPage: 15,
      searchVal: '',
      sortBy: '',
      sortOrder: 'DESC',
      openShareDialog: false,
      openEditDetailsDialog: false,
      popoverPostion: {},
      deletePopover: null,
      dropDownArray: FAVOURITE_DROPDOWN_VALUES,
      isInsideCollection: false,
      collectionId: '',
      collectionName: '',
      currentCollectionItem: {},
      selectedItems: [],
      isExplorerOpen: false,
      dropDownSelectedIndex: 'My Favourites',
    }
  }

  componentDidMount() {
    const { clearCollection = () => {} } = this.props
    clearCollection()
    this.setState(
      {
        pageNum: 1,
        isShared: false,
        sortBy: 'created_date',
        sortOrder: 'DESC',
        searchVal: '',
      },
      () => {
        this.fetchCollectionList(true, true, false)
      }
    )
  }

  interSectionCallback = (entries, observer) => {
    const {
      collectionList,
      collectionListloading,
      totalCollectionCount,
      totalAssetsCount,
      assetListSpecificCollection,
    } = this.props
    const { pageNum, collectionId } = this.state
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (
          collectionId === '' &&
          !collectionListloading &&
          totalCollectionCount > collectionList.length
        ) {
          this.setState({ pageNum: pageNum + 1 }, () =>
            this.fetchCollectionList(false, false, true)
          )
        } else if (
          collectionId !== '' &&
          !collectionListloading &&
          totalAssetsCount > assetListSpecificCollection.length
        ) {
          this.setState({ pageNum: pageNum + 1 }, () => this.fetchAssets(true))
        }
      }
    })
  }

  handleCloseSharingCollectionDialog = (bool) => {
    this.setState({ openShareDialog: false, openEditDetailsDialog: false })
  }

  handleEditCollectionClose = () => {
    this.setState({
      openShareDialog: false,
      openEditDetailsDialog: false,
    })
  }

  handleEditCollectionSuccess = (res) => {
    const { updateCollectionName } = this.props
    updateCollectionName({
      id: res.id,
      name: res.name,
    })
    this.handleEditCollectionClose()
  }

  handleDeleteCollectionClick = (e, item = {}) => {
    this.setState({
      openDeleteDialog: true,
      currentCollectionItem: item,
      deletePopover: e.currentTarget,
    })
  }

  handleDeleteCollection = (e) => {
    const { deleteCollection, showNotification } = this.props
    const { currentCollectionItem = {} } = this.state
    deleteCollection(
      currentCollectionItem.id,
      () => {
        this.closeDeleteDialog()
        showNotification(
          true,
          'Collection has been deleted successfully!',
          'success'
        )
      },
      () => {
        this.closeDeleteDialog()
        showNotification(true, 'Deletion of collection failed!', 'error')
      }
    )
  }

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      currentCollectionItem: {},
    })
  }

  fetchCollectionList(
    isShowSearchOptionCheck = false,
    isMiscellaneous = false,
    isAdded = false
  ) {
    const {
      perPage,
      pageNum,
      sortBy,
      sortOrder,
      isShared,
      searchVal,
      dropDownSelectedIndex,
    } = this.state
    const { fetchCollection, auth } = this.props
    const { email, firstName, lastName, fullName, lanId } = auth

    const payloadForMiscellaneous = {
      name: 'Miscellaneous',
      owner: {
        first_name: firstName,
        last_name: lastName,
        display_name: fullName,
        email_address: email,
        login_id: lanId,
      },
    }
    fetchCollection(
      perPage,
      pageNum,
      sortBy,
      sortOrder,
      isShared,
      searchVal,
      isMiscellaneous,
      isAdded,
      payloadForMiscellaneous,
      (collectionArray) => {
        if (isShowSearchOptionCheck) {
          this.setState({
            isShowSearchBar: collectionArray.length > 0 ? true : false,
          })
        }
      },
      this.onFetchCollectionFail,
      dropDownSelectedIndex
    )
  }

  handleCreateCollection = (data) => {
    const { history, createCollectionSave } = this.props
    createCollectionSave()
    this.setState(
      {
        collectionId: data.id,
        collectionName: data.name,
        pageNum: 1,
        searchVal: '',
        sortOrder: 'DESC',
        sortBy: 'created_date',
        isInsideCollection: true,
      },
      () => {
        history.push('/home/favourites')
        this.setState({ isInsideCollection: true })
      }
    )

    setTimeout(() => {
      this.fetchCollectionList(true, false, false)
    }, 1000)
  }

  onFetchCollectionFail = (err) => {
    this.props.showNotification(
      true,
      'Getting some error, try after sometime !!!',
      'error'
    )
  }

  noSearchContent = () => {
    const {
      classes,
      listPageMeta: { searchVal },
    } = this.props
    return (
      <DialogContentText className={classes.noSearchResultContext}>
        <SearchOffRoundedIcon
          sx={{
            width: '120px',
            height: '120px',
            margin: 'auto',
            display: 'block',
          }}
        />
        <Typography
          variant={'h6'}
          label={
            <span>
              No results found for{' '}
              <span className={classes.resultKeyword}>{`"${
                searchVal || ''
              }"`}</span>
            </span>
          }
        />
        <Typography
          variant={'body2'}
          label={`Please make sure your words are spelled correctly or use different keywords.`}
        />
      </DialogContentText>
    )
  }

  switchToCollectionList = () => {
    const { clearCollection } = this.props

    clearCollection()
    this.setState(
      {
        isInsideCollection: false,
        collectionId: '',
        pageNum: 1,
        searchVal: '',
        sortOrder: 'DESC',
        sortBy: 'created_date',
        collectionName: '',
        favAssetsList: [],
        selectedItems: [],
        searchValAsset: '',
      },
      () => {
        this.fetchCollectionList(true, false, false)
      }
    )
  }

  browseFavourites = (obj) => {
    const { history } = this.props

    this.setState(
      {
        pageNum: 1,
        searchVal: '',
        sortOrder: 'DESC',
        sortBy: 'created_date',
        collectionId: obj.id,
        collectionName: obj.name,
        isInsideCollection: true,
      },
      () => {
        this.fetchAssets(false)
      }
    )
  }

  fetchAssets = (isAddedAsset = false) => {
    const { fetchAssetsForFavorite } = this.props
    const {
      perPage,
      pageNum,
      collectionId,
      searchValAsset,
      dropDownSelectedIndex,
      collectionName,
    } = this.state

    fetchAssetsForFavorite(
      perPage,
      pageNum,
      collectionId,
      searchValAsset,
      isAddedAsset,
      () => {},
      () => {
        this.setState({ pageNum: pageNum - 1 })
      },
      dropDownSelectedIndex,
      collectionName
    )
  }

  searchAsset = (searchValAsset) => {
    this.setState({ pageNum: 1, searchValAsset }, () => {
      this.fetchAssets(false)
    })
  }

  handleCollectionType = (e) => {
    const { clearCollection } = this.props
    let shared = false
    const value = e.target.value

    clearCollection()

    if (value === 'Shared with me') {
      shared = true
    }

    this.setState(
      {
        pageNum: 1,
        searchVal: '',
        sortBy: 'created_date',
        sortOrder: 'DESC',
        isShared: shared,
        dropDownSelectedIndex: value,
      },
      () => {
        this.fetchCollectionList(true, false, false)
      }
    )
  }

  handleSelectOnAsset = (e, isChecked, item) => {
    const { tcin: itemId } = item
    const { selectedItems = [] } = this.state

    if (isChecked) {
      this.setState({
        selectedItems: [...selectedItems, itemId],
      })
    } else {
      let newTcinItems = selectedItems.filter((i) => i !== itemId)
      this.setState({
        selectedItems: [...newTcinItems],
      })
    }
  }

  extractTcinHandler = () => {
    const { completeAssetListSpecificCollection = [] } = this.props
    let newList = {}
    completeAssetListSpecificCollection.forEach((item) => {
      let key = item
      newList[key] = item
    })
    return newList
  }

  handleSingleAssetRemove = (obj, e) => {
    const { tcin: itemId } = obj
    this.setState({ currentProduct: itemId })
    this.handleAddingAssetsToCollection([itemId], true)
  }
  handleSingleAssetAdd = (obj) => {
    const { tcin: itemId } = obj
    this.setState({ currentProduct: itemId })
    this.handleAddingAssetsToCollection([itemId], false)
  }
  handleGroupAsset = (e, obj) => {
    this.setState({
      openDeleteDialog: true,
      currentCollectionItem: obj,
      isInsideCollection: true,
      deletePopover: e.currentTarget,
    })
  }

  onCross = () => {
    const { fetchCollection } = this.props
    const { isInsideCollection, dropDownSelectedIndex } = this.state

    if (isInsideCollection) {
      this.setState({ pageNum: 1, searchValAsset: '' }, () => {
        this.fetchAssets(false)
      })
    } else {
      fetchCollection(
        15,
        1,
        'created_date',
        'DESC',
        this.state.isShared,
        '',
        '',
        '',
        {},
        () => {},
        () => {},
        dropDownSelectedIndex
      )
    }
  }

  searchFavs = debounce((searchVal) => {
    const { isInsideCollection, dropDownSelectedIndex } = this.state
    this.setState({ searchVal, pageNum: 1 })
    const { clearCollection, fetchCollection } = this.props

    if (isInsideCollection) {
      this.searchAsset(searchVal)
      this.fetchAssets(false)
    } else {
      clearCollection()
      fetchCollection(
        15,
        1,
        'created_date',
        'DESC',
        this.state.isShared,
        searchVal,
        '',
        '',
        {},
        () => {},
        () => {},
        dropDownSelectedIndex
      )
    }
  }, 300)

  handleAddingFromExplorer = (selectedItems) => {
    this.handleAddingAssetsToCollection(Object.keys(selectedItems), false)
  }

  handleRemoveFromCollection = (selectedItems) => {
    this.handleAddingAssetsToCollection(Object.keys(selectedItems), true)
  }
  handleAddingAssetsToCollection = (
    itemList,
    isRemove = false,
    removedGroup
  ) => {
    const { addAssetsToCollection, auth, collectionList = [] } = this.props
    const { email, firstName, lastName, fullName, lanId } = auth
    const { collectionName, collectionId, dropDownSelectedIndex } = this.state
    let modifiedREmovedGroups =
      removedGroup &&
      removedGroup.map((item) => ({
        ...item,
        name: item.short_description,
      }))
    let groupsList = collectionList.find((item) => item.id === collectionId)
    const payload = {
      id: collectionId,
      name: collectionName,
      add_tcin_list: !isRemove ? itemList : [],
      remove_tcin_list: isRemove ? itemList : [],
      added_groups: [],
      groups: groupsList && groupsList.groups,
      removed_groups: modifiedREmovedGroups,
      owner: {
        first_name: firstName,
        last_name: lastName,
        display_name: fullName,
        email_address: email,
        login_id: lanId,
      },
    }
    const msgShow = isRemove
      ? 'Assets are deleted successfully'
      : 'Assets are added  successfully'
    this.setState({ isSubmitting: true })
    addAssetsToCollection(
      payload,
      (data) => {
        this.setState({
          isExplorerOpen: false,
          isSubmitting: false,
          selectedItems: [],
          pageNum: 1,
        })
        this.props.showNotification(true, msgShow, 'success')
        this.closeDeleteDialog()
      },
      (error) => {
        this.setState({
          isExplorerOpen: false,
          isSubmitting: false,
          selectedItems: [],
          pageNum: 1,
        })
        this.props.showNotification(true, error.errorMessages, 'error')
        this.closeDeleteDialog()
      },
      dropDownSelectedIndex,
      this.state.collectionName
    )
  }
  copyPath = (option) => {
    const os = getCurrentOS()
    if (os === OS.WINDOWS) {
      copyToClipboard(option.windows_hp_path)
    } else if (os === OS.MACOS) {
      copyToClipboard(option.mac_hp_path)
    } else {
      copyToClipboard(option.mac_hp_path)
    }
    this.setState({ isCopied: true, anchorEl: null })
    setTimeout(() => {
      this.setState({ isCopied: false })
    }, 10000)
  }

  computeListGroups = () => {
    const { collectionList } = this.props
    const { collectionId } = this.state
    let groupsList = collectionList.find((item) => item.id === collectionId)
    let listGroups =
      groupsList && groupsList.groups
        ? uniqBy(groupsList && groupsList.groups, 'name')
        : []
    listGroups = listGroups.filter((item) => item !== null)
    return listGroups
  }

  renderButtonLabel = (text) => {
    const { isSubmitting } = this.state
    const {
      classes: { buttonLabel, loaderStyle },
    } = this.props
    return (
      <div className={buttonLabel}>
        <>{text}</>
        {isSubmitting ? (
          <CircularProgress
            color="inherit"
            size="20px"
            className={loaderStyle}
          />
        ) : (
          ''
        )}
      </div>
    )
  }

  restructureData = ({
    showCategoryAssets,
    category,
    asset_name,
    asset_description,
    thumbnail_path,
    thumbnail_url,
    asset_type,
    asset_id,
    name,
    assetId,
    assetType,
    ...rest
  }) => ({
    short_description: asset_name || name || '',
    tcin: asset_name || assetId || '',
    product_desc: asset_description || '',
    thumbnail_url: thumbnail_path || thumbnail_url || '',
    asset_id,
    asset_type,
    product_type: asset_type || assetType || '',
    category: category,
    is_hp_available: false,
    is_lp_available: false,
    ...rest,
  })
  renderAssetsOfCollectionUI = () => {
    const { selectedItems, isSubmitting } = this.state
    let selectedAssetsObj = {}
    selectedItems.length &&
      selectedItems.forEach((item) => {
        selectedAssetsObj[item] = item
      })

    return (
      <>
        {this.innerlayoutPageHeader()}
        {this.innerlayoutPageSubHeader()}
        {this.innerlayoutPageContent()}
        {selectedItems.length > 0 && (
          <ExplorerFooter
            footerOptions={{
              buttonType: 'simple',
              buttonLabel: this.renderButtonLabel('Remove from collection'),
              buttonAction: this.handleRemoveFromCollection,
            }}
            deSelectAssets={() => {
              this.setState({ selectedItems: [] })
            }}
            selectedItems={selectedAssetsObj}
          />
        )}
      </>
    )
  }

  renderCollectionUI = () => {
    return (
      <>
        {this.layoutPageHeader()}
        {this.layoutPageContent()}
      </>
    )
  }

  renderAssetExplorer = () => {
    this.setState({ isExplorerOpen: true })
  }

  innerlayoutPageHeader = () => {
    const { classes, totalAssetsCount = 0 } = this.props
    const { collectionName, searchVal, dropDownSelectedIndex } = this.state

    let listGroups = this.computeListGroups()
    return (
      <div className={classes.headerWrapper}>
        <div style={{ marginRight: 50 }}>
          <PageTitleBar
            title={collectionName}
            subTitle={`${totalAssetsCount + listGroups.length} Products`}
          />
        </div>
        <div>
          <SearchComponent
            height={40}
            onSearch={this.searchFavs}
            onSearchButtonClick={this.onCross}
            placeholder={`Search in ${collectionName}...`}
            searchVal={searchVal}
            width={320}
            brandLogo={searchLogo}
            disabled={dropDownSelectedIndex === 'Gallery'}
          />
        </div>
      </div>
    )
  }

  innerlayoutPageSubHeader = () => {
    const { collectionName } = this.state
    const { classes, history } = this.props

    return (
      <div className={classes.secondaryBar}>
        <BreadcrumbComponent
          activeLast
          items={[
            {
              link: '#',
              name: 'Home',
              onClick: (e) => {
                e.preventDefault()
                history.push('/home')
              },
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },
            {
              link: '#',
              onClick: (e) => {
                e.preventDefault()
                history.push('/home/favourites')
                this.switchToCollectionList()
              },
              name: 'Favourites',
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },
            {
              name: collectionName,
              props: {
                color: 'inherit',
                underline: 'hover',
              },
            },
          ]}
        />

        <CustomButton
          label="Add Products"
          startIcon={<AddIcon fontSize="15px" />}
          color="inherit"
          onClick={this.renderAssetExplorer}
          variant="outlined"
          disableElevation
        />
      </div>
    )
  }

  innerlayoutPageContent = () => {
    const {
      selectedItems,
      collectionId,
      isInsideCollection,
      isSubmitting,
      currentProduct,
    } = this.state
    const {
      classes,
      assetListSpecificCollection,
      collectionListloading,
      collectionList,
    } = this.props

    let listGroups = this.computeListGroups()
    let sortedList = [...listGroups, ...assetListSpecificCollection].map(
      (item) => ({
        ...item,
        is_hp_available: item.is_hp_available || item.hp_available,
        is_lp_available: item.is_lp_available || item.lp_available,
      })
    )
    return (
      <Box
        sx={{
          ...(isInsideCollection && {
            '& .datalistContainer': {
              height: 'Calc(100vh - 250px) !important',
            },
          }),
        }}
      >
        <DataList
          showProgressBar={collectionListloading}
          interSectionCallback={this.interSectionCallback}
        >
          {sortedList &&
            sortedList.map((item) => {
              const infoDetails = {
                icon: InfoOutlinedIcon,
                title: 'info',
                data: mapInfoForCard(item),
              }
              const isGroup = item.hasOwnProperty('id')

              let multiInfoData = []
              let thumbnailList = []
              if (isGroup) {
                item.assets.forEach((data) => {
                  multiInfoData.push(mapInfoForCard(data))
                  thumbnailList.push(data?.thumbnail_url)
                })
                infoDetails.multiInfoData = multiInfoData
                infoDetails.thumbnailList = thumbnailList
              }
              const isGallery = item.asset_type === 'PID'
              return (
                <div className={classes.card}>
                  <AssetCard
                    thumbnail={item.thumbnail_url}
                    config={config}
                    name={
                      !isEmpty(item.name)
                        ? item.name.replace(/_/g, ' ')
                        : !item.short_description && !item.tcin
                        ? ''
                        : item.short_description !== '' &&
                          item.short_description !== null
                        ? item.short_description.replace(/_/g, ' ')
                        : item.tcin.replace(/_/g, ' ')
                    }
                    subTitle={item.product_desc ? item.product_desc : ''}
                    maxWidth={250}
                    minWidth={250}
                    assetData={item}
                    infoDetails={infoDetails}
                    noThumbnail={noThumbnail}
                    footerItems={[
                      {
                        icon:
                          isSubmitting && currentProduct === item.tcin
                            ? CircularProgress
                            : RemoveCircleOutlineIcon,
                        onClick: (e) => {
                          item.hasOwnProperty('id')
                            ? this.handleGroupAsset(
                                e,
                                this.restructureData(item)
                              )
                            : this.handleSingleAssetRemove(item)
                        },
                        title: 'Remove from collection',
                      },
                      ...(!isGroup && !item.is_pure_rta_asset && !isGallery
                        ? [
                            {
                              icon: LinkIcon,
                              onClick: () => this.copyPath(item),
                              title: 'Copy path',
                              flag: true,
                            },
                          ]
                        : []),
                    ]}
                    errorDetails={{
                      high: item.is_hp_available || true,
                      low: item.is_lp_available || true,
                      lodProfile: item.lod_available,
                    }}
                    selectable={item.hasOwnProperty('id') ? false : true}
                    onSelect={(e) =>
                      item.hasOwnProperty('id')
                        ? null
                        : this.handleSelectOnAsset(e, e.target.checked, item)
                    }
                    selected={
                      item.hasOwnProperty('id')
                        ? false
                        : selectedItems.includes(item.tcin)
                    }
                    isGroup={item.hasOwnProperty('id') ? true : false}
                    isUrlAppendedWithImg={!isGallery}
                    isPropAsset={item.asset_type == 'PROP'}
                    isRtaAsset={item.is_pure_rta_asset}
                    isHighPolyCountAsset={item.is_high_triangle_count}
                  />
                </div>
              )
            })}
        </DataList>
      </Box>
    )
  }

  layoutPageHeader = () => {
    const { classes, totalCollectionCount = 0, history } = this.props
    const { dropDownArray, searchVal, isShared, dropDownSelectedIndex } =
      this.state
    let list = dropDownArray.map((item) => {
      return {
        name: item,
        value: item,
      }
    })

    return (
      <div className={classes.headerWrapper}>
        <div style={{ width: '150px' }}>
          <Select
            inputLabel="All"
            size="small"
            color={'tertiary'}
            onChange={this.handleCollectionType}
            options={list}
            style={{ width: '150px' }}
            value={isShared ? 'Shared with me' : dropDownSelectedIndex}
          />
        </div>
        <div style={{ marginRight: 50, marginLeft: 20 }}>
          <PageTitleBar
            title="Browse favourites"
            subTitle={`Browse from ${totalCollectionCount} favourites`}
          />
        </div>
        <div>
          <SearchComponent
            height={40}
            onSearch={this.searchFavs}
            onSearchButtonClick={this.onCross}
            placeholder={`Search in Favourites...`}
            searchVal={searchVal}
            width={320}
            brandLogo={searchLogo}
          />
        </div>

        {dropDownSelectedIndex !== 'Gallery' && (
          <div className={classes.buttonWrapper}>
            <CustomButton
              label={'New Collection'}
              startIcon={<AddIcon fontSize="15px" />}
              color="tertiary"
              onClick={() => {
                history.push('/home/favourites/new')
              }}
              variant="outlined"
              disableElevation
            />
            {/* <a href="#" className={classes.link}>
            <TypoText variant="caption">See how it works?</TypoText>
          </a> */}
          </div>
        )}
      </div>
    )
  }

  layoutPageContent = () => {
    const { classes, collectionList, collectionListloading, lanId } = this.props
    return (
      <DataList
        showProgressBar={collectionListloading}
        interSectionCallback={this.interSectionCallback}
      >
        {!collectionList.length &&
          !collectionListloading &&
          this.noSearchContent()}
        {collectionList &&
          collectionList.map((item, i) => {
            const footerItems = [
              {
                icon: FolderOpenIcon,
                onClick: () => {
                  this.browseFavourites(item)
                },
                title: 'View Favourites',
              },
            ]
            const isGallery = item.assetType === 'gallery'
            if (
              ((item.owner && item.owner.login_id === lanId) ||
                item.name !== 'Miscellaneous') &&
              !isGallery
            ) {
              footerItems.push({
                icon: DeleteOutlinedIcon,
                onClick: (e) => {
                  this.handleDeleteCollectionClick(e, item)
                },
                title: 'Delete',
                alignRight: true,
              })
            }
            return (
              <div className={classes.card} key={i}>
                <FavouriteCard
                  name={item.name}
                  assetCount={item.tcin_count}
                  thumbnail={item.thumbnail_path}
                  loginId={item?.owner?.login_id}
                  displayName={item?.owner?.display_name}
                  imgHeight={250}
                  maxWidth={250}
                  minWidth={250}
                  noThumbnail={noThumbnail}
                  config={config}
                  isUrlAppendedWithImg={!isGallery}
                  avatarBackgroundColor={'#E0E0E0'}
                  avtarTextColor={'#333'}
                  footerItems={footerItems}
                  moreMenuItems={
                    isGallery
                      ? []
                      : [
                          {
                            onClick: (e) => {
                              this.setState({
                                openEditDetailsDialog: true,
                                collectionName: item.name,
                                owner: item.owner.first_name,
                                currentCollectionItem: item,
                              })
                            },
                            title: 'Edit details',
                          },
                          {
                            onClick: (e) => {
                              this.setState({
                                openShareDialog: true,
                                currentCollectionItem: item,
                                popoverPostion: {
                                  left:
                                    e.currentTarget.getBoundingClientRect()
                                      .right + 20,
                                  top: e.currentTarget.getBoundingClientRect()
                                    .top,
                                },
                              })
                            },
                            title: 'Share this collection',
                          },
                        ]
                  }
                />
              </div>
            )
          })}
      </DataList>
    )
  }

  renderingFormDialog() {
    const { auth, history } = this.props
    const item = {
      owner: {
        first_name: auth.firstName,
        email_address: auth.email,
        login_id: auth.lanId,
      },
    }
    return (
      <CollectionEditForm
        openDialog={true}
        handleClose={() => {
          history.push('/home/favourites')
        }}
        collectionItem={item}
        auth={auth}
        onSuccess={this.handleCreateCollection}
        onError={() => {
          history.push('/home/favourites')
        }}
        onCancel={() => {
          history.push('/home/favourites')
        }}
        variant={'create'}
      />
    )
  }

  render() {
    const { classes, auth, showDialog = false } = this.props
    const {
      openEditDetailsDialog,
      collectionId,
      openShareDialog,
      popoverPostion,
      currentCollectionItem,
      openDeleteDialog,
      isExplorerOpen = false,
      isInsideCollection,
      dropDownSelectedIndex,
      isSubmitting = false,
      currentProduct = '',
    } = this.state
    const cardWidth = 224

    const isDialog = false
    return (
      <>
        <div className={classes.root}>
          {collectionId === ''
            ? this.renderCollectionUI()
            : this.renderAssetsOfCollectionUI()}
        </div>

        {openEditDetailsDialog && (
          <CollectionEditForm
            openDialog={openEditDetailsDialog}
            handleClose={this.handleEditCollectionClose}
            onCancel={this.handleEditCollectionClose}
            onError={this.handleEditCollectionClose}
            onSuccess={this.handleEditCollectionSuccess}
            collectionItem={currentCollectionItem}
            config={config}
            auth={auth}
          ></CollectionEditForm>
        )}
        {openShareDialog && (
          <ShareExperienceDialog
            openDialog={openShareDialog}
            handleClose={this.handleCloseSharingCollectionDialog}
            experienceItem={currentCollectionItem}
            type="favorites"
            popoverPostion={popoverPostion} // Only for Popover
            isDialog={isDialog} // Here open as popover, so isDialog will be false
            widthOfPivotElement={cardWidth} // optional, only for card and use it as Popover
            lanId={auth.lanId}
            collectionItem={currentCollectionItem}
          />
        )}
        {openDeleteDialog && (
          <Popover
            open={Boolean(this.state.deletePopover)}
            anchorEl={this.state.deletePopover}
            onClose={() => {
              this.setState({
                deletePopover: null,
              })
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'top',
            }}
          >
            <div className={classes.deletePopover}>
              <Typography variant="subtitle2" label={'Confirm Delete?'} />
              <Typography
                sx={{
                  marginTop: '10px',
                  marginBottom: '20px',
                  color: '#000000',
                  opacity: '0.6',
                }}
                variant="body2"
                label={'Action can’t be undone'}
              />
              <div className={classes.popoverButtons}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    this.setState({
                      deletePopover: null,
                    })
                  }}
                  startIcon={<CloseIcon />}
                  variant="text"
                  color={'tertiary'}
                />
                <CustomButton
                  sx={{ marginLeft: '10px' }}
                  label="Delete"
                  onClick={
                    isInsideCollection
                      ? (e) =>
                          this.handleAddingAssetsToCollection(
                            currentCollectionItem.hasOwnProperty('id')
                              ? []
                              : [currentCollectionItem.tcin],
                            true,
                            currentCollectionItem.hasOwnProperty('id')
                              ? [currentCollectionItem]
                              : []
                          )
                      : this.handleDeleteCollection
                  }
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  color={'error'}
                />
              </div>
            </div>
          </Popover>
        )}
        {isExplorerOpen && (
          <Dialog
            onClose={() => {
              this.setState({ isExplorerOpen: false })
            }}
            open={isExplorerOpen}
            classes={{
              paper: classes.dialogPaperDefault,
            }}
            fullWidth={true}
            maxWidth={'xl'}
          >
            <DialogContent>
              <AssetExplorer
                showAddRemoveIcon={true}
                showCopyPath={false}
                showSimilarSearch={false}
                showVariations={false}
                footerOptions={{
                  buttonType: 'simple',
                  buttonLabel: this.renderButtonLabel('Add to collection'),
                  buttonAction: this.handleAddingFromExplorer,
                }}
                addIconLabel={'Add to collection'}
                addedAssets={this.extractTcinHandler()}
                addCallback={this.handleSingleAssetAdd}
                removeCallback={this.handleSingleAssetRemove}
                onModalClose={() => {
                  this.setState({ isExplorerOpen: false })
                }}
                isDialogDisplay={true}
                hideGalleryOption={dropDownSelectedIndex !== 'Gallery'}
                isSubmitting={isSubmitting}
                currentProduct={currentProduct}
              />
            </DialogContent>
          </Dialog>
        )}
        {/* <Routes>
          <Route
            path="/home/favourites/new"
            element={this.renderingFormDialog()}
          />
        </Routes> */}
        {showDialog && this.renderingFormDialog()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { collectionData, auth } = state
  const {
    collectionList,
    totalCollectionCount,
    noResultsFound,
    listPageMeta,
    collectionListloading,
    assetListSpecificCollection,
    completeAssetListSpecificCollection,
    totalAssetsCount,
  } = collectionData
  return {
    collectionList,
    totalCollectionCount,
    noResultsFound,
    listPageMeta,
    collectionListloading,
    assetListSpecificCollection,
    completeAssetListSpecificCollection,
    totalAssetsCount,
    auth,
  }
}

export default connect(mapStateToProps, {
  showNotification,
  fetchCollection,
  clearCollection,
  fetchAssetsForFavorite,
  updateCollectionName,
  deleteCollection,
  addAssetsToCollection,
  createCollectionSave,
})(withStyles(styles)(withRouter(Favourites)))
