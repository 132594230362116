import React from 'react'

import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import Icon from '@mui/material/Icon'

import { TemplateCard } from 'cgi-ui-components'

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'

import CircularProgress from '@mui/material/CircularProgress'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'

import { showNotification } from '../../store/notification/actionCreator'

import coronaIcon from '../../images/Corona.svg?url'
import vrayIcon from '../../images/vray.svg?url'
import {
  toggleTemplateModal,
  downloadTemplate,
} from '../../store/template/actionCreator'
import { styled } from '@mui/material/styles'

import config from '../../config/config'

const styles = (theme) => ({})
const CoronaIcon = styled((props) => (
  <Icon {...props}>
    <img src={coronaIcon} alt="renderer icon" />
  </Icon>
))(({}) => ({}))

const VrayIcon = styled((props) => (
  <Icon {...props}>
    <img src={vrayIcon} alt="renderer icon" />
  </Icon>
))(({}) => ({}))

class TemplateDetails extends React.Component {
  downloadTemplate = () => {
    const {
      currentTemplateDetails: {
        template_name = '',
        template_high_res_url = '',
      },
      downloadTemplate,
      templateDownloadedStarted,
    } = this.props
    if (!templateDownloadedStarted) {
      downloadTemplate(template_name, template_high_res_url)
    }
  }

  openTemplate = () => {
    const { toggleTemplateModal } = this.props
    toggleTemplateModal(true, true)
  }
  handleBrowseTemplateAssets = () => {
    this.setState({ showTemplateVersionAssets: true })
  }

  render() {
    const {
      editTemplateMode,
      currentTemplateDetails: {
        template_name = '',
        template_thumbnail_url = '',
        high_low_poly_available = true,
        template_high_res_url,
        template_tags = [],
        renderer_type,
      },
      templateDownloadedStarted,
      handleShowTemplateAssets = () => {},
    } = this.props
    return (
      <>
        {template_thumbnail_url && (
          <div unmountOnExit>
            <TemplateCard
              size="medium"
              maxWidth={270}
              imgHeight={200}
              thumbnail={template_thumbnail_url}
              config={config}
              assetError={!high_low_poly_available}
              footerItems={[
                {
                  icon: CachedOutlinedIcon,
                  onClick: () => {
                    if (!editTemplateMode) {
                      this.openTemplate()
                    }
                  },
                  title: 'Change layout',
                },
                {
                  icon: FileDownloadOutlinedIcon,
                  onClick: () => {
                    if (!templateDownloadedStarted) {
                      this.downloadTemplate(
                        template_name,
                        template_high_res_url
                      )
                    }
                  },
                  title: templateDownloadedStarted
                    ? 'Download in progress'
                    : 'Download Thumbnail',
                },
                {
                  icon: FolderOpenIcon,
                  onClick: () => {
                    handleShowTemplateAssets(true)
                  },
                  title: 'View template products',
                },
                {
                  icon: renderer_type === 'V-Ray' ? VrayIcon : CoronaIcon,
                  title: renderer_type,
                  alignRight: true,
                  noAction: true,
                },
              ]}
              name={template_name}
              subTitle={template_tags.join(', ')}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = ({
  templates: {
    currentTemplateDetails,
    templateDownloadedStarted,
    editTemplateMode,
  },
}) => ({
  templateDownloadedStarted,
  currentTemplateDetails,
  editTemplateMode,
})
export default connect(mapStateToProps, {
  toggleTemplateModal,
  downloadTemplate,
  showNotification,
})(withStyles(styles)(TemplateDetails))
