import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'

import {
  PageTitleBar,
  CustomButton,
  BreadcrumbComponent,
  Typography,
} from 'cgi-ui-components'

import { Box, Badge } from '@mui/material'
import CropFreeIcon from '@mui/icons-material/CropFree'
import PushPinIcon from '@mui/icons-material/PushPin'
import LinearProgress from '@mui/material/LinearProgress'
import { FilterAltRounded } from '@mui/icons-material'
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'

import { debounce } from 'lodash'

import { fetchArchAssets, clearResults } from '../../store/assets/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import {
  addTcinToBoard,
  removeTcinsFromBoard,
} from '../../store/board/actionCreator'
import {
  clearAssets,
  handlefilterChange,
  fetchAssets,
  showSimilarSearchResults,
  showVariationAssets,
  setExcludedHP,
  setExcludedRTA,
} from '../../store/explorer/actionCreator'
import { kFormatter } from '../../helpers/utils'

import ProductsAndPropsFilters from '../ProductsAndPropsFilters'
import SummaryList from '../Filter/SummaryList'
import DraggableAssetCard from '../assetExplorerCard/DraggableAssetCard'
import { ASSET_TYPES } from '../ProductsAndPropsFilters/FilterHeaderWrapper'
import AssetExplorer from '../AssetExplorer'
import SubAssets from './SubAssets'
import SimilarAssets from './SimilarAssets'
import ExplorerSearch from './ExplorerSearch'
import { createCustomEvent } from '../../helpers/utils'
import { TRACK_SCENE_EVENT } from '../../constants/scene'
const styles = (theme) => ({
  miniExplorerWrapper: {
    position: 'fixed',
    zIndex: 101,
    width: 480,
    backgroundColor: '#FFFFFF',
    top: 75,
    bottom: 50,
    left: 85,
    borderRadius: 4,
  },
  explorerContainer: {
    height: 'Calc(100vh - 125px)',
    display: 'flex',
    flexDirection: 'column',
  },
  explorerHeader: {
    padding: '20px 20px 0px 20px',

    backgroundColor: '#FFF',
    zIndex: 99,
  },

  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSelect-select': { padding: '5px' },
    '& .MuiFormControl-root': {
      marginRight: '0px',
    },
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: 'none',
      borderRadius: '0px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
  },
  searchComponent: {
    borderRadius: '0px !important',
    borderLeft: 'none !important',
    borderTopRightRadius: '4px !important',
    borderBottomRightRadius: '4px !important',
    marginTop: '-3px',
    padding: '10px 10px 10px 0px',
    '& .MuiInputBase-colorPrimary': { marginLeft: '-5px' },
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '10px 5px 5px 0',
  },
  totalCount: {
    fontWeight: 400,
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.6) !important',
    marginLeft: '10px !important',
  },
  listWrapperContainer: {
    margin: 0,
    padding: ' 0 0 0 25px',
    overflow: 'hidden',
    flexGrow: 1,
  },
  listWrapper: {
    display: 'flex',
    height: 'Calc(100% - 20px)',
    alignItems: 'stretch',

    flexDirection: 'row',
  },
  listContainer: {
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingRight: '20px',
    width: '100%',
    overflowY: 'auto',
  },
  list: {
    marginBottom: 15,
  },
  maximizedView: {
    position: 'fixed',
    zIndex: 1200,
    backgroundColor: '#FFFFFF',
    top: 75,
    bottom: 50,
    left: 85,
    borderRadius: 4,
    right: 10,
    padding: '20px 20px 0px 20px',
    '& .exclude-highpoly': {
      marginLeft: 'none',
    },
    '& .datalistContainer': {
      height: 'Calc(100vh - 265px)',
    },
  },
  contentEnd: {
    height: 10,
    width: '100%',
  },
  emptyResult: {
    textAlign: 'center',
  },
  noResultText: {
    fontSize: '22px',
    color: '#333333',
  },
  noSearchResultContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  noSearchResultContent: {
    width: 'auto',
    textAlign: 'center',
  },
})

class AssetExplorerMin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageNum: 1,
      searchBy: props.isArchAssets ? 'ARCHITECTURE' : 'All',
      searchVal: '',
      loading: false,
      excludeHighPoly: props.excludeHP,
      excludeRTA: props.excludeRTA,
      openFilter: false,
      maximizedView: false,
      filterApplied: 0,
      variationQuery: {},
      inSubAssetView: false,
      shortDescription: '',
      isPinned: false,
      perPage: 40,
      searched: false,
    }
    this.contentEndRef = React.createRef()
    this.node = React.createRef()
    this.clearSelection = () => {}
    this.cancelController = new AbortController()
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.sceneMode !== this.props.sceneMode &&
      !this.state.maximizedView
    ) {
      this.props.clearAssets()
      this.cancelController.abort()
      this.setState(
        {
          pageNum: 1,
          loading: false,
          searchBy:
            this.props.sceneMode == 'ARCHITECTURE' ? 'ARCHITECTURE' : 'All',
        },
        () => {
          this.getAssets(true)
        }
      )
    }
  }

  componentDidMount() {
    const {
      clearAssets,
      assetList,
      listPageMeta: {
        recordsPerPage: perPage,
        pageNum,
        searchVal = '',
        searchBy = this.state.searchBy,
      } = {},
      miniExplorerMode,
      sceneMode,
    } = this.props
    if (assetList.length === 0 || miniExplorerMode !== sceneMode) {
      if (miniExplorerMode !== sceneMode) {
        this.setState({
          pageNum: 1,
        })
      }
      clearAssets()
      this.getAssets(true)
    } else {
      if (searchBy == 'GALLERY') {
        clearAssets()
        this.setState(
          {
            perPage,
            pageNum: 1,
            searchVal,
            searchBy,
          },
          () => {
            this.getAssets()
          }
        )
      } else {
        this.setState({
          perPage,
          pageNum,
          searchVal,
          searchBy,
        })
      }
    }
    if (!this.contentEndRef.current) return
    this.observer = new IntersectionObserver(this.interSectionCallback, {
      threshold: [0.5],
    })
    this.observer.observe(this.contentEndRef.current)
    document.addEventListener('click', this.closeExplorer)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.closeExplorer)
  }
  closeExplorer = (e) => {
    const { isPinned, maximizedView } = this.state
    const isTooltip = e.target.closest('.MuiTooltip-popper')

    if (
      e.defaultPrevented ||
      this.node.current.contains(e.target) ||
      isPinned ||
      maximizedView ||
      isTooltip !== null
    ) {
      return false
    }
    const { closeExplorer } = this.props
    closeExplorer()
  }
  interSectionCallback = () => {
    const { loading, pageNum } = this.state
    const { assetList, totalAssetsCount } = this.props
    if (!loading && assetList.length < totalAssetsCount) {
      this.setState({ pageNum: pageNum + 1 }, () => {
        this.getAssets()
      })
    }
  }

  getAssets = (getFilters = false) => {
    const {
      fetchAssets,
      filterLists,
      clearAssets,
      filterType,
      sortBy,
      isArchAssets,
    } = this.props
    const {
      loading,
      perPage,
      pageNum,
      searchVal,
      searchBy,
      excludeHighPoly,
      excludeRTA,
    } = this.state
    if (!loading) {
      this.setState({ loading: true })
      let filters = {}
      if (filterType === searchBy) {
        Object.keys(filterLists).map((filterKey) => {
          const filter = filterLists[filterKey]
          for (let index = 0; index < filter.length; index++) {
            const facet = filter[index]
            if (facet.isSelected) {
              const existingValues = filters[filterKey]
                ? filters[filterKey]
                : []
              filters[filterKey] = [...existingValues, facet.name]
            }
          }
        })
      }

      if (parseInt(pageNum) === 1) {
        clearAssets()
      }
      this.cancelController = new AbortController()

      // Re compute sortBy field when PROPs are displayed
      let reComputedSortBy =
        searchBy === 'PROP' &&
        sortBy !== JSON.stringify({ time_stamp: 'DESC' }) &&
        sortBy !== JSON.stringify({ time_stamp: 'ASC' })
          ? JSON.stringify({ time_stamp: 'DESC' })
          : sortBy

      fetchAssets(
        perPage,
        pageNum,
        searchVal,
        searchBy,
        filters,
        getFilters,
        excludeHighPoly,
        {},
        this.cancelController,
        reComputedSortBy,
        excludeRTA,
        isArchAssets
      )
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          setTimeout(() => {
            this.setState({
              loading: false,
              searched: searchVal ? true : false,
            })
          }, 200)
        })
    }
  }

  handleAssetTypeChange = (e) => {
    this.cancelController.abort()
    this.setState(
      { pageNum: 1, searchBy: e.target.value, loading: false },
      () => {
        this.getAssets()
      }
    )
  }

  handleFilters = (filterkey, index, name, checked) => {
    this.cancelController.abort()
    this.setState(
      {
        pageNum: 1,
        loading: false,
        filterApplied: checked
          ? this.state.filterApplied + 1
          : this.state.filterApplied - 1,
      },
      () => {
        this.getAssets()
      }
    )
  }

  removeFilter = (filterKey, id) => {
    this.props.handlefilterChange(filterKey, id, false).then(() => {
      this.handleChangeFilters()
    })
  }

  handleChangeFilters = () => {
    this.cancelController.abort()

    this.setState({ pageNum: 1, loading: false }, () => {
      this.getAssets()
    })
  }
  handleExcluded = (e, type) => {
    this.cancelController.abort()
    if (type === 'HP') {
      this.props.setExcludedHP(!this.state.excludeHighPoly)
      this.setState(
        {
          pageNum: 1,
          excludeHighPoly: !this.state.excludeHighPoly,
          loading: false,
        },
        () => {
          this.getAssets()
        }
      )
    } else if (type === 'RTA') {
      this.props.setExcludedRTA(!this.state.excludeRTA)
      this.setState(
        { pageNum: 1, excludeRTA: !this.state.excludeRTA, loading: false },
        () => {
          this.getAssets()
        }
      )
    }
  }

  searchAsset = debounce((searchVal) => {
    this.cancelController.abort()
    this.setState({ searchVal, pageNum: 1, loading: false }, () => {
      this.getAssets()
    })
  }, 500)

  clearSearch = () => {
    this.cancelController.abort()
    this.setState({ searchVal: '', pageNum: 1, loading: false }, this.getAssets)
  }

  openVariations = (asset, variant) => {
    this.setState({
      variationQuery: {},

      shortDescription: '',
    })
    this.props.showVariationAssets(true, { ...asset, variant })
    this.props.showSimilarSearchResults(false, {})
  }

  openSimilarAssets = (data) => {
    createCustomEvent(TRACK_SCENE_EVENT, {
      type: 'SimilarSearch',
      assetId: data.tcin,
      e: 'Action from Explorer',
    })
    this.props.showSimilarSearchResults(true, data)
    this.props.showVariationAssets(false, {})
  }

  closeSubAssets = (e) => {
    e.preventDefault()
    this.setState({
      variationQuery: {},
      shortDescription: '',
    })
    this.props.showVariationAssets(false, {})
    this.props.showSimilarSearchResults(false, {})
  }

  addAssetsToBoard = (data) => {
    // console.log(e, rest)
    const assets = []
    for (let i of Object.keys(data)) {
      const asset = data[i]
      assets.push({ asset_type: asset.asset_type, value: i })
    }
    const { sceneid, addTcinToBoard, showNotification } = this.props

    if (assets.length) {
      let payload = {
        experience_id: sceneid,
        assets: assets,
      }
      addTcinToBoard(
        payload,
        (data) => {
          this.clearSelection()
          showNotification(true, 'Added Successfully', 'success')
        },
        (error) => {
          showNotification(
            true,
            'Getting some error, try after sometimes !!!',
            'error'
          )
        }
      )
    }
  }
  setClearMethod = (method) => {
    this.clearSelection = method
  }

  displayNoResultsFound = () => {
    const { loading, searchVal } = this.state
    const { assetList, classes } = this.props

    return !loading && !assetList.length && searchVal != '' ? (
      <>
        <div className={classes.noSearchResultContainer}>
          <div className={classes.noSearchResultContent}>
            <SearchOffRoundedIcon
              sx={{
                width: '120px',
                height: '120px',
                margin: 'auto',
                display: 'block',
              }}
            />
            <Typography
              variant={'h6'}
              label={
                <span>
                  No results found
                  {searchVal !== '' && (
                    <>
                      &nbsp;for &nbsp;
                      <span className={classes.resultKeyword}>
                        "{searchVal}"
                      </span>
                    </>
                  )}
                </span>
              }
            />
            {searchVal !== '' ? (
              <Typography
                variant={'body2'}
                label={`Please make sure your words are spelled correctly or use different keywords.`}
              />
            ) : null}
          </div>
        </div>
      </>
    ) : (
      ''
    )
  }

  render() {
    const {
      classes: styles,
      assetList,
      totalAssetsCount,
      libraryAssetsCount,
      filterLists,
      selectedFilterCount,
      showSimilarAsset,
      similarAssetParent,
      showVariations,
      variationTcin,
      handleCartActions,
      filterType,
      isArchAssets,
    } = this.props
    const {
      maximizedView,
      isPinned,
      searchBy,
      searchVal,
      openFilter,
      excludeHighPoly,
      excludeRTA,
      shortDescription,
      loading,
      filterApplied,
      pageNum,
      searched,
    } = this.state
    let breadCrumbLabel = shortDescription
    if (showSimilarAsset && similarAssetParent) {
      breadCrumbLabel =
        similarAssetParent.short_description ||
        similarAssetParent.tcin ||
        similarAssetParent.value
    }
    if (showVariations && variationTcin) {
      breadCrumbLabel =
        variationTcin.short_description ||
        variationTcin.tcin ||
        variationTcin.value
    }
    return (
      <>
        <>
          <Box
            className={styles.miniExplorerWrapper}
            style={{ display: maximizedView ? 'none' : 'block' }}
            ref={this.node}
          >
            <div className={styles.explorerContainer}>
              <Box className={styles.explorerHeader}>
                <div className={styles.headerTitle}>
                  <Box>
                    <PageTitleBar
                      title={
                        isArchAssets
                          ? 'Browse Architectural Assets'
                          : 'Product and Props'
                      }
                      subTitle={`Browse from over ${kFormatter(
                        libraryAssetsCount
                      )} assets`}
                    />
                  </Box>
                  <Box>
                    <CropFreeIcon
                      sx={{ marginRight: '23px', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({ maximizedView: true })
                      }}
                    />
                    <PushPinIcon
                      sx={{
                        cursor: 'pointer',
                        transform: isPinned ? 'rotate(30deg)' : 'none',
                      }}
                      onClick={() => {
                        this.setState({ isPinned: !isPinned })
                      }}
                    />
                  </Box>
                </div>
                {!showVariations && !showSimilarAsset ? (
                  <>
                    <ExplorerSearch
                      type={searchBy}
                      handleTypeChange={this.handleAssetTypeChange}
                      options={ASSET_TYPES()}
                      searchVal={searchVal}
                      placeholder={`Search ${
                        isArchAssets ? 'name' : 'product and props'
                      }...`}
                      handleSearchChange={this.searchAsset}
                      onClose={this.clearSearch}
                      showTypeDropDown={!isArchAssets}
                    />

                    <Box className={styles.filterWrapper}>
                      <Box className={styles.filterButton}>
                        <Badge
                          badgeContent={selectedFilterCount}
                          color="primary"
                        >
                          <CustomButton
                            label="Filter"
                            startIcon={<FilterAltRounded />}
                            variant={'none'}
                            onClick={() => {
                              this.setState({
                                openFilter: !this.state.openFilter,
                              })
                            }}
                          />
                        </Badge>

                        {(searched || selectedFilterCount > 0) && (
                          <Typography
                            varaint="secondary"
                            className={styles.totalCount}
                            label={`${totalAssetsCount} result${
                              totalAssetsCount > 1 ? 's' : ''
                            }`}
                          >
                            {totalAssetsCount}{' '}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <SummaryList
                        filterLists={filterLists}
                        handleFilters={this.removeFilter}
                      />
                    </Box>
                  </>
                ) : (
                  <BreadcrumbComponent
                    items={[
                      {
                        link: '#',
                        name: isArchAssets
                          ? 'Architectural Assets'
                          : 'Product and Props',
                        onClick: this.closeSubAssets,
                        props: {
                          color: '#000000',
                          underline: 'hover',
                        },
                      },
                      {
                        name: breadCrumbLabel,
                        props: {
                          color: 'inherit',
                          underline: 'none',
                        },
                      },
                    ]}
                    sx={{ marginTop: '10px' }}
                  />
                )}
              </Box>

              <Box
                className={styles.listWrapperContainer}
                style={{
                  display:
                    showVariations || showSimilarAsset ? 'none' : 'block',
                }}
              >
                <Box className={styles.listWrapper}>
                  {openFilter && (
                    <div
                      style={{
                        overflowY: 'auto',
                        minWidth: '160px',
                      }}
                    >
                      {filterType === searchBy && (
                        <ProductsAndPropsFilters
                          handleFilters={this.handleFilters}
                          isMinified={true}
                          handleExcluded={this.handleExcluded}
                          excludeHighPoly={excludeHighPoly}
                          excludeRTA={excludeRTA}
                          searchBy={searchBy}
                        />
                      )}
                    </div>
                  )}
                  <Box className={styles.listContainer}>
                    {this.displayNoResultsFound()}
                    {assetList &&
                      assetList.map((item) => {
                        return (
                          <Box className={styles.list}>
                            <DraggableAssetCard
                              data={{
                                ...item,
                                tcin: item.value || item.tcin,
                                short_description: item.short_description,
                              }}
                              maxWidth={130}
                              minWidth={130}
                              openSubAssets={this.openVariations}
                              openSimilarAssets={this.openSimilarAssets}
                              showSubAsset
                              showSimilarAsset
                              preventDefault
                              handleCartActions={handleCartActions}
                              isRtaAsset={item.is_pure_rta_asset}
                            />
                          </Box>
                        )
                      })}
                    <Box
                      className={styles.list}
                      style={{ width: '130px', height: '130px' }}
                    ></Box>
                    <div
                      ref={this.contentEndRef}
                      className={styles.contentEnd}
                    ></div>
                  </Box>
                </Box>
              </Box>

              {showVariations && (
                <SubAssets
                  data={variationTcin}
                  handleCartActions={handleCartActions}
                />
              )}
              {showSimilarAsset && (
                <SimilarAssets
                  data={similarAssetParent}
                  closeSimilarSearch={this.closeSubAssets}
                  handleCartActions={handleCartActions}
                  isArchAssets={isArchAssets}
                />
              )}
            </div>

            {loading && <LinearProgress />}
          </Box>
        </>

        {maximizedView && (
          <Box className={styles.maximizedView}>
            <AssetExplorer
              showAddRemoveIcon={true}
              onModalClose={() => {
                this.props.closeExplorer()
                // this.setState({ maximizedView: false })
              }}
              onMinimize={(e) => {
                e.preventDefault()
                this.setState({ maximizedView: false })
              }}
              isSceneEditor
              excludeHighPoly={excludeHighPoly}
              excludeRTA={excludeRTA}
              setExcludeHighPoly={(excludeHighPoly) =>
                this.setState({ excludeHighPoly })
              }
              setExcludeRTA={(excludeRTA) => this.setState({ excludeRTA })}
              filterApplied={filterApplied}
              searchVal={searchVal}
              search_by={searchBy}
              pageNum={pageNum}
              setSearchBy={(searchBy) => {
                this.setState({ searchBy })
              }}
              setSearchVal={(searchVal) => {
                this.setState({ searchVal })
              }}
              setDeselectAssets={this.setClearMethod}
              footerOptions={{
                buttonType: 'simple',
                buttonLabel: 'Add To Board',
                buttonAction: this.addAssetsToBoard,
              }}
              handleCartActions={handleCartActions}
              isArchAssets={isArchAssets}
            />
          </Box>
        )}
      </>
    )
  }
}

const minExplorer = withStyles(styles)(AssetExplorerMin)

const mapStateToProps = (state) => {
  const {
    auth,
    explorer,
    experiences: {
      experience: { experience_id: sceneid },
    },
    scene,
  } = state
  const { fullName, firstName, lanId, email } = auth
  const {
    categories,
    loading,
    totalAssetsCount,
    assetList = [],
    libraryAssetsCount,
    filterLists = {},
    selectedFilterCount,
    showSimilarAsset,
    search_similar_asset: similarAssetParent,
    showVariations,
    variationTcin,
    listPageMeta,
    filterType,
    sortBy,
    excludeHighPoly: excludeHP,
    excludeRTA,
    miniExplorerMode,
  } = explorer
  const { mode } = scene
  return {
    fullName,
    firstName,
    lanId,
    email,
    assetList,
    categories,
    totalAssetsCount,
    libraryAssetsCount,
    loading,
    filterLists,
    selectedFilterCount,
    sceneid,
    showSimilarAsset,
    similarAssetParent,
    showVariations,
    variationTcin,
    listPageMeta,
    filterType,
    sortBy,
    excludeHP,
    excludeRTA,
    miniExplorerMode,
    sceneMode: mode,
  }
}
export default connect(mapStateToProps, {
  showNotification,
  fetchArchAssets,
  clearResults,
  addTcinToBoard,
  removeTcinsFromBoard,
  clearAssets,
  handlefilterChange,
  fetchAssets,
  showSimilarSearchResults,
  showVariationAssets,
  setExcludedHP,
  setExcludedRTA,
})(minExplorer)
