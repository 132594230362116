import React from 'react'
import { ButtonBase } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = (theme) => ({
  root: {
    display: 'block',
    color: theme.palette.primary.black,
  },
  icon: { display: 'block' },
  secondaryIconAndText: {
    display: 'flex',
  },
  text: {
    fontSize: 10,
    marginTop: 3,
    textAlign: 'center',
    fontWeight: 500,
  },
  secondaryIconStyle: {
    margin: '3px 0 0 5px',
  },
})

const IconButton = ({
  classes,
  customClassName = '',
  customTextClass = '',
  text,
  icon,
  secondaryIcon = '',
  ...rest
}) => (
  <ButtonBase
    disableRipple
    className={classes.root + ' ' + customClassName}
    {...rest}
  >
    <div>
      <div className={classes.icon}>{icon}</div>
      <div className={secondaryIcon !== '' ? classes.secondaryIconAndText : ''}>
        <div className={classes.text + ' ' + customTextClass}>{text}</div>
        <span className={classes.secondaryIconStyle}>{secondaryIcon}</span>
      </div>
    </div>
  </ButtonBase>
)

export default withStyles(styles)(IconButton)
