import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Typography, CustomIconButton, CustomButton } from 'cgi-ui-components'
import Refresh from '@mui/icons-material/Refresh'
import Close from '@mui/icons-material/Close'

const styles = (theme) => ({
  header: {},
})

export class AssetScaleHeader extends Component {
  render() {
    const { classes, onReset, onClose } = this.props
    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography
            variant="body1"
            label="Scale asset"
            className={classes.header}
          >
            Scale Asset
          </Typography>
        </Grid>
        <Grid item>
          <CustomButton
            onClick={onReset}
            variant="text"
            color="tertiary"
            label="Reset"
            startIcon={<Refresh />}
          />
          <CustomIconButton onClick={onClose} label="Close">
            <Close />
          </CustomIconButton>
        </Grid>
      </Grid>
    )
  }
}

AssetScaleHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onReset: PropTypes.func.isRequired,
}

AssetScaleHeader.defaultProp = {
  onReset: () => {},
}

export default withStyles(styles)(AssetScaleHeader)
