import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { debounce, uniqBy } from 'lodash'
import { MentionsInput, Mention } from 'react-mentions'
import { withStyles } from '@mui/styles'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

import { Button, Chip, Typography, Menu, MenuItem } from '@mui/material'
import apiConfig from '../../config/config'

import {
  ART_DIRECTOR,
  SBA,
  ALL,
  POST_TO_RENDER_COMMENTS,
} from '../../constants/common'
import { saveRenderComment } from '../../store/comments/ActionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import { experienceSaveChanges } from '../../store/experience/ActionCreator'
import { mentionsParser } from '../../helpers/utils'

import { CustomButton, CustomIconButton } from 'cgi-ui-components'
import AttachmentIcon from '@mui/icons-material/Attachment'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const styles = (theme) => ({
  attachment: {
    display: 'flex',
    flexDirection: 'column',
  },
  replyAttachment: {
    float: 'right',
    marginBottom: 25,
    marginLeft: 73,
  },
  input: {
    display: 'none',
  },
  fileText: {
    padding: '3px 10px',
    display: 'inline',
    color: '#2196F3',
  },
  postContainer: {
    padding: 10,
  },
  action: {
    textAlign: 'right',
  },
  chipStyle: {
    backgroundColor: theme.palette.primary.lightBlueBackground,
  },
  mention_mention: {
    position: 'relative',
    zIndex: 1,
    color: theme.palette.primary.mentionColor,
    pointerEvents: 'none',
  },
  mentions: {
    '& .mentions__suggestions': {
      '& .mentions__suggestions__list': {
        backgroundColor: theme.palette.primary.white,
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.12), 0 3px 6px 0 rgba(0,0,0,0.24)',
        borderRadius: 5,
        overflow: 'auto',
        maxHeight: 200,
        '& .mentions__suggestions__item': {
          padding: '10px 15px',
          color: theme.palette.primary.darkFont,
          fontFamily: 'Helvetica Neue',
          fontSize: 14,
        },
        '& .mentions__suggestions__item--focused': {
          backgroundColor: theme.palette.primary.lightBlueBackground,
        },
      },
    },
    '& .mentions__control': {
      '& .mentions__highlighter': {
        padding: 10,
      },
      '& .mentions__input': {
        borderRadius: '5px !important',
        padding: 10,
      },
    },
  },
})

class NewComment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddCommentOptions: false,
      anchorEl: null,
      files: [],
      postToData: [ART_DIRECTOR],
      commentText: '',
      deletedFiles: [],
      isSaveButtonActive: false,
    }
    this.fetchedData = []
    this.usersEditAccess = []
  }

  componentDidMount() {
    const { commentsData, isEditor } = this.props

    if (isEditor) {
      let files =
        commentsData &&
        Object.entries(commentsData.attached_files).map((data, index) => ({
          file_id: data[0],
          filename: data[1].file_name,
          file: data[1],
        }))
      this.setState({
        commentText: commentsData.comments,
        files,
        postToData: commentsData.send_to,
      })
    }
  }

  handleTextFieldClick = () => {
    const { handleshowAddCommentOptions = () => {} } = this.props
    this.setState({
      showAddCommentOptions: true,
    })
    handleshowAddCommentOptions(true)
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleMenuClick = (e, value) => {
    const { postToData = [] } = this.state
    const { isEditor = false } = this.props
    const shouldAddToChip = postToData.indexOf(value) < 0

    this.setState({
      anchorEl: !shouldAddToChip ? null : e.currentTarget,
    })
    if (value && shouldAddToChip) {
      let newPostToData = [...postToData, value]
      this.setState({
        anchorEl: null,
        postToData: newPostToData,
        isSaveButtonActive: isEditor,
      })
    }
  }

  addFiles = (e) => {
    const { showNotification = () => {}, isEditor = false } = this.props
    let filesAdded = Object.values(e.currentTarget.files).map((file) => ({
      filename: file.name,
      file,
    }))
    let newList = [...this.state.files, ...filesAdded]
    if (newList.length <= 5) {
      this.setState({
        files: newList,
        isSaveButtonActive: isEditor,
      })
    } else {
      showNotification(true, 'Upto 5 attachments allowed', 'error')
    }
  }

  deleteFile = (filename, fileData) => {
    const { files = [], deletedFiles } = this.state
    const { isEditor = false } = this.props

    let newList = files.filter((file) => filename !== file.filename)
    let deletedFileId = fileData.file_id && fileData.file_id
    this.setState({
      files: newList,
      deletedFiles: deletedFileId
        ? [...deletedFiles, deletedFileId]
        : deletedFiles,
      isSaveButtonActive: isEditor,
    })
  }

  handleTextInputChange = (event, newValue, newPlainTextValue, mentions) => {
    const { isEditor = false } = this.props
    this.setState({
      commentText: newValue,
      mentionData: { newValue, newPlainTextValue, mentions },
      isSaveButtonActive: isEditor,
    })
  }

  loadOptions = debounce(async (searchKeyWord, cb) => {
    const urlWithParam = `${apiConfig.searchUser}?user=${searchKeyWord}&key=${apiConfig.apiKey}`
    if ((searchKeyWord || '').length > 1) {
      let response = await axios.get(urlWithParam)
      this.fetchedData = response.data
      cb(response.data)
    }
  }, 400)

  search = (key, callback) => {
    this.loadOptions(key, (data) => {
      let searchArrayList =
        data &&
        data.map((item, index) => ({
          id: index + 1,
          display: `${item.display_name}`,
          value: `${item.email_address}`,
        }))
      callback(searchArrayList)
    })
  }

  handleOnAdd = (id, display) => {
    const {
      auth: { lanId },
      members = [],
    } = this.props
    let selectedUser = this.fetchedData.filter(
      (item) => item.display_name === display
    )
    let find =
      members &&
      members.findIndex(
        (item) => item.user.login_id === selectedUser[0].login_id
      )
    if (find === -1 || find === null) {
      this.usersEditAccess = [
        ...this.usersEditAccess,
        {
          login_id: selectedUser && selectedUser[0].login_id,
          permission: 'Read',
        },
      ].filter((item) => item.login_id !== lanId)
    }
  }

  handleChipDelete = (data) => {
    let { postToData = [] } = this.state
    const { isEditor = false } = this.props
    let newPostToData = postToData.filter((postTo) => postTo !== data)
    if (newPostToData.length === 0) {
      newPostToData.push(ALL)
    }
    this.setState({
      postToData: newPostToData,
      isSaveButtonActive: isEditor,
    })
  }

  cancelAdd = () => {
    const { handleshowAddCommentOptions = () => {} } = this.props
    this.setState({
      showAddCommentOptions: false,
      anchorEl: null,
      files: [],
      postToData: [ART_DIRECTOR],
      commentText: '',
    })
    handleshowAddCommentOptions(false)
  }

  uploadComment = () => {
    const {
      postToData = [],
      files = [],
      commentText = '',
      deletedFiles,
    } = this.state

    const {
      imageDetails: { render_job_id = '' } = {},
      saveRenderComment = () => {},
      auth = {},
      commentsData = {},
      isEditor,
      isReply,
      experienceSaveChanges = () => {},
      experience_id,
      handleShow = () => {},
      isAnnotationComment = false,
      count = 0,
      members = [],
    } = this.props
    let payload
    let refinedSendList = postToData

    let taggedUsers = mentionsParser(commentText, true)
    //Filter out files which are newly added from UI
    let filesTosend = files.filter((file) => !file.file_id)

    if (postToData.indexOf(ALL) > -1) {
      refinedSendList = [ART_DIRECTOR, SBA]
    }
    if (isReply || isEditor) {
      payload = {
        render_job_id,
        send_to: refinedSendList,
        comments: commentText,
        attached_files: commentsData.attached_files,
        deleted_files: deletedFiles,
        annotation_comment: commentsData.annotation_comment,
        mentioned_users: taggedUsers,
      }
      if (commentsData.annotation_comment) {
        payload.annotation = {
          geometry: commentsData.annotation.geometry,
          data: { text: commentText, id: commentsData.annotation.data.id },
        }
      }
    } else {
      payload = {
        render_job_id,
        send_to: refinedSendList,
        comments: commentText,
        mentioned_users: taggedUsers,
      }
    }
    payload[isReply ? 'master_comment_id' : 'render_comment_id'] =
      commentsData.render_comment_id

    let memberData =
      members &&
      members.map((item) => ({
        permission: item.access_level,
        login_id: item.user.login_id,
      }))
    memberData = memberData === null || undefined ? [] : memberData
    let requestBody = {
      experience_id: experience_id,
      visibility: 'Shared',
      shared_users: uniqBy(
        [...memberData, ...this.usersEditAccess],
        'login_id'
      ),
    }
    if (this.usersEditAccess.length > 0) {
      experienceSaveChanges(requestBody, () => {
        handleShow()
        this.usersEditAccess = []
      })
    }
    if (isAnnotationComment && !isEditor) {
      payload.annotation_comment = isAnnotationComment
      const id = String(Math.random())
      payload.annotation = {
        geometry: this.props.annotation.geometry,
        data: { text: commentText, id },
      }
    }
    const onSuccess = (savedComment) => {
      const { onSubmit = () => {} } = this.props
      if (savedComment.annotation && savedComment.annotation.data) {
        onSubmit({
          geometry: savedComment.annotation.geometry,
          data: { text: commentText, id: savedComment.annotation.data.id },
          count,
          commentId: savedComment.render_comment_id,
        })
      }
    }
    saveRenderComment(
      render_job_id,
      payload,
      filesTosend,
      auth,
      isEditor,
      isReply,
      onSuccess
    )
  }

  componentWillReceiveProps(nextProps) {
    const { isEditor = false, handleshowAddCommentOptions = () => {} } =
      this.props

    if (
      this.props.savingComment === true &&
      nextProps.savingComment === false
    ) {
      this.setState(
        {
          commentText: '',
          files: [],
          postToData: [ART_DIRECTOR],
          showAddCommentOptions: false,
        },
        () => {
          isEditor && this.closeEditor()
        }
      )
      handleshowAddCommentOptions(false)
    }
  }
  renderFiles = (files) => {
    const { classes = {}, savingComment = false } = this.props
    let filesContainer =
      files !== null &&
      files.map((file) => (
        <div>
          <Typography className={classes.fileText} variant="body2">
            {file.filename}

            <CustomIconButton
              size="small"
              onClick={(e) => this.deleteFile(file.filename, file)}
              disabled={savingComment}
              color="info"
            >
              <ClearRoundedIcon fontSize="small" color="info" />
            </CustomIconButton>
          </Typography>
        </div>
      ))
    return filesContainer
  }

  closeEditor = () => {
    const { closeEditor } = this.props
    closeEditor()
  }

  render() {
    const {
      classes,
      savingComment = false,
      isEditor = false,
      isReply = false,
      isAnnotationComment = false,
    } = this.props
    const {
      showAddCommentOptions = false,
      anchorEl = null,
      files = [],
      postToData = [],
      isSaveButtonActive = false,
      commentText,
    } = this.state

    const renderSuggestion = (entry) => entry.value

    const isAttachfileEnabled = files.length > 4 ? true : false
    return (
      <div
        style={{
          padding: isAnnotationComment ? (showAddCommentOptions ? 10 : 0) : 0,
          width: isAnnotationComment ? 'unset' : isReply ? '78%' : '95%',
          float: isEditor ? 'right' : '',
        }}
        className={isReply ? classes.replyAttachment : classes.attachment}
      >
        <MentionsInput
          value={commentText}
          onChange={this.handleTextInputChange}
          onClick={this.handleTextFieldClick}
          markup="@[__display__](__id__)"
          className={`mentions ${classes.mentions}`}
          allowSpaceInQuery={true}
          disabled={savingComment}
          placeholder={
            isReply
              ? 'Leave a reply. Use @ to mention'
              : 'Add a comment. Use @ to mention'
          }
          data-testid="mention-input"
        >
          <Mention
            type="user"
            trigger="@"
            data={(key, callback) => this.search(key, (data) => callback(data))}
            onAdd={this.handleOnAdd}
            className={classes.mention_mention}
            renderSuggestion={renderSuggestion}
            data-testid="mentions-add"
          />
        </MentionsInput>
        {(showAddCommentOptions || isEditor) && (
          <>
            <div className={classes.attachment}>
              <div className={classes.filesList}>
                <input
                  accept="image/*, application/pdf"
                  className={classes.input}
                  id="uploadBtn"
                  multiple
                  type="file"
                  onChange={this.addFiles}
                  disabled={isAttachfileEnabled || savingComment}
                  data-testid="attach-files-btn"
                />
                <label htmlFor="uploadBtn">
                  <CustomButton
                    disabled={isAttachfileEnabled || savingComment}
                    disableRipple
                    label={!files.length ? 'Attach files' : 'Attach more files'}
                    startIcon={<AttachmentIcon />}
                    component="span"
                    variant="text"
                    color={'inherit'}
                    sx={{
                      justifyContent: 'left',
                      width: '100%',
                      fontWeight: 400,
                    }}
                    data-testid="add-files"
                  />
                </label>
                {this.renderFiles(files)}
              </div>
              {(!isReply || isEditor) && (
                <div
                  className={classes.postContainer}
                  data-testid="post-to-container"
                >
                  <CustomButton
                    endIcon={<ArrowDropDownIcon />}
                    label="Post comment to"
                    onClick={this.handleMenuClick}
                    variant="text"
                    color="inherit"
                    disabled={savingComment}
                    sx={{ marginRight: '20px', fontWeight: 400 }}
                  />
                  <Menu
                    id="simple-menu"
                    data-testid="simple-options-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    onChange={this.handleChnage}
                  >
                    {POST_TO_RENDER_COMMENTS.map((to) => (
                      <MenuItem
                        value={to.value}
                        onClick={(e) => this.handleMenuClick(e, to.value)}
                        data-testid="postTo-option"
                      >
                        {to.name}
                      </MenuItem>
                    ))}
                  </Menu>
                  {postToData.map((data) => (
                    <Chip
                      label={data}
                      className={classes.chipStyle}
                      onDelete={(e) => this.handleChipDelete(data)}
                    />
                  ))}
                </div>
              )}
              <div className={classes.action}>
                <Button
                  style={{ color: '#AC0000' }}
                  onClick={!isEditor ? this.cancelAdd : this.closeEditor}
                  disabled={savingComment}
                >
                  Cancel
                </Button>
                <Button
                  color="info"
                  onClick={this.uploadComment}
                  disabled={
                    savingComment ||
                    !this.state.commentText ||
                    (isEditor && !isSaveButtonActive)
                  }
                  data-testid="post-message"
                >
                  {savingComment
                    ? 'Saving...'
                    : isEditor
                    ? 'Save Changes'
                    : 'Post'}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({
  comments: { savingComment = false },
  auth,
  experiences: {
    experience: { experience_id, members },
  },
}) => ({
  savingComment,
  auth,
  experience_id,
  members,
})
const mapDispatchToProps = {
  showNotification,
  saveRenderComment,
  experienceSaveChanges,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewComment))
