import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Box, Chip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import {
  LabelAndHelperSelect,
  PageTitleBar,
  SearchComponent,
} from 'cgi-ui-components'
import searchLogo from '../../images/enterprise/searchLogo.svg?url'

import { kFormatter } from '../../helpers/utils'
import { handlefilterChange } from '../../store/explorer/actionCreator'
import { Close } from '@mui/icons-material'
import { CustomIconButton, Select } from 'cgi-ui-components'
import { Minimize } from '@mui/icons-material'

const useFilterHeaderStyles = makeStyles(() => ({
  wrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  selectorWapper: {
    '& .MuiInputLabel-root': { top: -6, left: -4 },
    '& .MuiFormControl-root': { margin: '8px 8px 8px 0' },
  },
  archPadding: {
    paddingBottom: '20px',
  },
  selector: { width: '140px', height: '44px' },
  title: {
    marginLeft: '25px',
  },
  archTitle: {
    marginLeft: '0',
  },
  close: {
    display: 'flex',
    marginLeft: 'auto',
    '& div:hover': {
      cursor: 'pointer',
      // marginRight: 5,
    },
  },
}))

export const ASSET_TYPES = (hideGallery = false) => [
  {
    name: 'All Assets',
    value: 'All',
  },
  {
    name: 'Custom Build',
    value: 'TCIN',
  },
  {
    name: 'Purchased',
    value: 'PROP',
  },
  !hideGallery && {
    name: 'Gallery',
    value: 'GALLERY',
  },
]

const FilterHeaderWrapper = (props) => {
  const [selectedAsset, setSelectedAsset] = useState(
    props.selectedAsset ? props.selectedAsset : 'All'
  )

  useEffect(() => {
    setSelectedAsset(props.selectedAsset)
  }, [props.selectedAsset])

  const {
    onModalClose,
    onMinimize,
    handlefilterChange,
    handleFilters,
    searchVal = '',
    onSearch = () => {},
    clearSearch = () => {},
    hideGalleryOption,
    sortByArray = [],
    handleSort = () => {},
    sortBy,
    isArchAssets = false,
  } = props

  const styles = useFilterHeaderStyles()

  const handleAssetTypeChange = (e) => {
    setSelectedAsset(e.target.value)
    props.handelAssetChange(e.target.value)
  }

  const removeFilter = (filterKey, id) => {
    handlefilterChange(filterKey, id, false).then(() => {
      handleFilters()
    })
  }

  const sortOptions =
    selectedAsset === 'PROP'
      ? sortByArray.filter(
          (item) =>
            item.value !== JSON.stringify({ launch_time: 'DESC' }) &&
            item.value !== JSON.stringify({ launch_time: 'ASC' })
        )
      : sortByArray

  const sortValue =
    sortOptions.find((item) => item.value === sortBy).name ??
    'Recently ingested assets'

  return (
    <Grid
      className={[styles.wrapper, isArchAssets ? styles.archPadding : ''].join(
        ' '
      )}
    >
      <Box className={styles.selectorWapper}>
        {!isArchAssets && (
          <LabelAndHelperSelect
            className={styles.selector}
            onChange={handleAssetTypeChange}
            options={ASSET_TYPES(hideGalleryOption)}
            value={selectedAsset}
          />
        )}
      </Box>
      <Box className={styles[isArchAssets ? 'archTitle' : 'title']}>
        <PageTitleBar
          title={isArchAssets ? 'Architectural Assets' : 'Product and Props'}
          subTitle={`Browse from over ${kFormatter(props.totalAssets)} assets`}
        />
      </Box>
      <Box className={styles.title}>
        <SearchComponent
          height={40}
          onSearch={onSearch}
          onSearchButtonClick={clearSearch}
          placeholder={`Search ${
            isArchAssets ? 'name' : 'in Product and Props'
          }...`}
          searchVal={searchVal}
          width={320}
          brandLogo={searchLogo}
        />
      </Box>

      <Box className={styles.close}>
        {selectedAsset !== 'GALLERY' && !isArchAssets && (
          <Select
            style={{ width: 250, marginRight: 40 }}
            color={'tertiary'}
            size="small"
            inputLabel="Sort"
            onChange={handleSort}
            options={sortOptions}
            value={sortValue}
          />
        )}
        {onMinimize && (
          <CustomIconButton label="Minimize" onClick={onMinimize}>
            <Minimize />
          </CustomIconButton>
        )}
        {onModalClose && (
          <CustomIconButton label="Close" onClick={onModalClose}>
            <Close />
          </CustomIconButton>
        )}
      </Box>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  const {
    explorer: { filterLists },
  } = state
  return {
    filterLists,
  }
}

export default connect(mapStateToProps, { handlefilterChange })(
  FilterHeaderWrapper
)
