import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ProtectedElement, useAuth } from '@praxis/component-auth'

import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import { AnalyticsProvider } from '@praxis/component-analytics'

// import jwt from 'jsonwebtoken'
import axios from 'axios'
import { signInSuccess, NUIDSignInSuccess } from '../store/auth/index'
import navRoutes from '../routes/index'
import Interceptor from './Interceptor'
import BrowserIncompatible from '../components/BrowserMessage/incompatibleBrowser'
import apiConfig from '../config/config'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  logout: {
    position: 'absolute',
    top: '44px',
    width: '135px',
    height: '36px',
    background: '#fff',
    right: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 45%)',
    zIndex: 99999,
    cursor: 'pointer',
  },
}))

const eventManagerConfiguration = {
  // Unique name of your application.
  appName: apiConfig.analytics.appName,
  apiKey: apiConfig.apiKey,
  url: apiConfig.analytics.url,
}

const hasTokenExpired = () => {
  const accessToken = window.localStorage.getItem('access_token')
  if (accessToken && accessToken !== '') {
    var newToken = accessToken.substring(7, accessToken.length) // used to remove the Bearer string and space from the token so that it consists of only header,payload and signature.
    // var decoded = jwt.decode(newToken)
    // const exp = jwt.decode(newToken).exp
    // const now = Date.now() / 1000
    // return exp - now < 0
  }
  return true
}

const AuthComponent = (props) => {
  const auth = useAuth()
  const { signInSuccess, NUIDSignInSuccess } = props
  const { login, isAuthorized, session } = auth
  const isChrome = navigator.userAgent.indexOf('Chrome') > -1
  // if (hasTokenExpired()) {
  //   window.localStorage.removeItem('access_token')
  //   window.localStorage.removeItem('id_token')
  //   clearPreferences()
  // }
  if (!isAuthorized()) {
    login()
  }
  const userInfo = session?.userInfo
  useEffect(() => {
    if (
      isAuthorized &&
      window.localStorage.getItem('access_token') !== null &&
      userInfo &&
      userInfo !== null &&
      userInfo.email
    ) {
      userInfo.xIdToken = window.localStorage.getItem('id_token')
      signInSuccess(userInfo)
      axios.get(apiConfig.getnuidToken).then((resp) => {
        NUIDSignInSuccess(resp.data)
      })
    }
  }, [userInfo])

  return (
    <>
      <Interceptor />
      {isChrome ? (
        <Router>
          <AnalyticsProvider
            eventManagerConfiguration={eventManagerConfiguration}
            trackLocations
          >
            <Routes>
              {navRoutes.map((prop, key) => (
                <Route
                  exact={prop.exact}
                  path={prop.path}
                  key={key}
                  element={<ProtectedElement component={prop.component} />}
                />
              ))}
            </Routes>
          </AnalyticsProvider>
        </Router>
      ) : (
        <BrowserIncompatible />
      )}
    </>
  )
}

const clearPreferences = () => {
  document.cookie =
    'styleSaveWarnAccepted=yes;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
}

export const LogoutButton = () => {
  const classes = useStyles()
  const auth = useAuth()
  const { logout } = auth

  const logoutUser = () => {
    clearPreferences()
    logout()
  }
  return (
    <div className={classes.logout} onClick={logoutUser}>
      <Typography variant="subtitle">Logout</Typography>
    </div>
  )
}

export default connect((state) => state, { signInSuccess, NUIDSignInSuccess })(
  AuthComponent
)
