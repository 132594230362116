import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import config from '../../config/config'

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
  },
  container: {
    backgroundColor: '#f6f6f5',
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
  },
  details: {
    display: 'flex',
    marginLeft: 5,
    width: 380,
  },
  title: {
    fontSize: 15,
    marginBottom: 10,
  },
  overview: {
    fontSize: 10,
  },

  image: {
    height: 180,
    width: 180,
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: 150,
    alignItems: 'center',
    marginBottom: 12,
  },
  type_text: {
    fontSize: 10,
  },
  overviewContainer: {
    marginTop: 5,
    minHeight: 90,
  },
})

export function PdfDocument(props) {
  const thumbnailSource = (a) => {
    if (props.isArchAsset) {
      return a.thumbnail_path
        ? a.thumbnail_path.includes('texturing/') ||
          a.thumbnail_path.includes('inputs/')
          ? {
              uri: `${config.ggaRenditions}${a.thumbnail_path}`,
              method: 'GET',
            }
          : a.thumbnail_path.includes('scene7')
          ? a.thumbnail_path
          : {
              uri: `${config.renditionsUrl}${a.thumbnail_path}`,
              method: 'GET',
            }
        : noThumbnail
    } else {
      return a.thumbnail_url && a.thumbnail_url !== null
        ? a.thumbnail_url.includes('texturing/') ||
          a.thumbnail_url.includes('inputs/')
          ? {
              uri: `${config.ggaRenditions}${a.thumbnail_url}`,
              method: 'GET',
            }
          : a.thumbnail_url.includes('scene7')
          ? a.thumbnail_url
          : {
              uri: `${config.renditionsUrl}${a.thumbnail_url}`,
              method: 'GET',
            }
        : noThumbnail
    }
  }

  return (
    <Document>
      <Page style={styles.page}>
        {props.data && props.data.assets ? (
          props.data.assets.map((a, index) => (
            <View key={index} style={styles.container}>
              <Image style={styles.image} source={thumbnailSource(a)} />
              <View style={styles.details}>
                <Text style={styles.title}>{a.value || a.tcin}</Text>
                <View style={styles.subtitle}>
                  <Text style={styles.type_text}>{a.asset_type}</Text>
                </View>
                <View style={styles.overviewContainer}>
                  <Text style={styles.overview}>
                    {' '}
                    Brand : {a.brand ? a.brand : a.brand_name}
                  </Text>
                  <Text style={styles.overview}>
                    Dimensions : {a.dimensions}
                  </Text>
                  <Text style={styles.overview}> Dpci : {a.dpci}</Text>
                  <Text style={styles.overview}>
                    Item Status : {a.item_status}
                  </Text>
                  <Text style={styles.overview}>Materials : {a.materials}</Text>
                  <Text style={styles.overview}>
                    high poly :{' '}
                    {a.is_pure_rta_asset
                      ? 'unavailable'
                      : a.is_hp_available.toString() === 'true'
                      ? 'available'
                      : 'unavailable'}
                  </Text>
                  <Text style={styles.overview}>
                    low poly :{' '}
                    {a.is_lp_available.toString() === 'true'
                      ? 'available'
                      : 'unavailable'}
                  </Text>
                </View>
                <View style={styles.overview}>
                  {props.data.externalUser ? (
                    <Text style={styles.overview}>
                      {'Path is not disabled for External Users'}
                    </Text>
                  ) : (
                    <Text style={styles.overview}>
                      {a.business_high_poly_path
                        ? a.business_high_poly_path
                        : a.windows_hp_path}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          ))
        ) : (
          <View style={styles.container} />
        )}
        {props.data && props.data.comments && props.data.comments.length > 0 ? (
          <View style={styles.container}>
            <Text style={styles.title}>Comments</Text>
          </View>
        ) : (
          <View style={styles.container} />
        )}
        {props.data && props.data.comments ? (
          props.data.comments.map((a, index) => (
            <View key={index} style={styles.container}>
              <View style={styles.details}>
                <View style={styles.overviewContainer}>
                  <Text style={styles.overview}> {a.display_name}</Text>
                  <Text style={styles.overview}>{a.comment}</Text>
                  <Text style={styles.overview}> {a.comment_time}</Text>
                </View>
              </View>
            </View>
          ))
        ) : (
          <View style={styles.container} />
        )}
      </Page>
    </Document>
  )
}

export default PdfDocument
