import React from 'react'
import { withStyles } from '@mui/styles'
import Proptypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Tooltip } from 'cgi-ui-components'

const styles = (theme) => ({
  root: {
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '12px',
    marginLeft: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  labelmax: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkBox: {
    padding: '1px',
  },
})
class ExcludeHighPoly extends React.Component {
  render() {
    const {
      classes = {},
      maximizedView,
      isChecked = true,
      handleExcluded,
      isMinified,
      displayText,
    } = this.props
    return (
      <>
        <div
          className={classes.root}
          style={{ marginTop: isMinified ? '9px' : '' }}
        >
          <Checkbox
            color="primary"
            onChange={handleExcluded}
            checkedIcon={
              <CheckBoxIcon style={{ fontSize: maximizedView ? 20 : 17 }} />
            }
            icon={
              <CheckBoxOutlineBlankIcon
                style={{ fontSize: maximizedView ? 20 : 17 }}
              />
            }
            classes={{
              root: classes.checkBox,
            }}
            checked={isChecked}
          />
          <Tooltip title={displayText} disableInteractive placement="top">
            <span className={maximizedView ? classes.labelmax : classes.label}>
              {displayText}
            </span>
          </Tooltip>
        </div>
      </>
    )
  }
}

ExcludeHighPoly.propTypes = {
  classes: Proptypes.object.isRequired,
}

export default withStyles(styles)(ExcludeHighPoly)
