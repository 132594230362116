import { cloneDeep, forEach } from 'lodash'
import {
  ADD_CART_DATA_REDUX,
  MULTI_ASSET_SELECTION,
  DOWNLOAD_CSV,
  TOGGLE_REPORT_ISSUE_MODAL,
  SELECT_CURRENT_TCIN,
} from './ActionType'

export const experienceState = {
  cart_id: '',
  experience_id: '',
  assets: [],
  groups: [],
  allTcinMap: {},
  groupSelected: null,
  toggleReportIssueModal: false,
  currentTcin: {},
}

function cartReduxDataFromSceneJSON(sceneJSONData) {
  const allTcins = {}

  sceneJSONData.scene_data.assets.forEach((asset) => {
    allTcins[asset.name] = {
      originalAssetId: asset.assetId,
      group: null,
      hidden: !asset.visible,
      selected: asset.selected,
      assetType: asset.assetType,
    }
  })
  sceneJSONData.scene_data.groups.forEach((group) => {
    group.assets.forEach((asset) => {
      allTcins[asset.name] = {
        originalAssetId: asset.assetId,
        group: {
          name: group.name,
          selected: group.selected,
          hidden: !group.visible,
        },
        hidden: !asset.visible,
        selected: asset.selected,
        assetType: asset.assetType,
      }
    })
  })
  return allTcins
}

export default function experiences(state = experienceState, action = {}) {
  switch (action.type) {
    case ADD_CART_DATA_REDUX: {
      const payload = cloneDeep(action.payload)
      const allTcins = cartReduxDataFromSceneJSON(action.payload)
      return {
        ...payload,
        allTcinMap: allTcins,
      }
    }

    case TOGGLE_REPORT_ISSUE_MODAL: {
      const { payload } = action
      return { ...state, toggleReportIssueModal: payload }
    }
    case SELECT_CURRENT_TCIN: {
      const { payload } = action
      return { ...state, currentTcin: payload }
    }

    case MULTI_ASSET_SELECTION: {
      const { tcins } = action.payload
      const newAllTcinMap = cloneDeep(state.allTcinMap)
      forEach(newAllTcinMap, (tcinObj, tcinKey) => {
        if (tcins.includes(tcinKey)) {
          tcinObj.selected = true
        } else {
          tcinObj.selected = false
        }
      })
      return { ...state, allTcinMap: newAllTcinMap }
    }

    case DOWNLOAD_CSV: {
      const { payload } = action
      const csvData = []
      csvData.push(['TCIN', 'Windows Path', 'Mac Path'])
      for (let k in payload) {
        for (let j in payload[k]) {
          let tcin = payload[k][j].tcin
          let mac_path = payload[k][j].tcin_mac_path
          let windows_path = payload[k][j].tcin_window_path
          csvData.push([tcin, mac_path, windows_path])
        }
      }
      return { ...state, download_data: csvData }
    }

    default:
      return state
  }
}
