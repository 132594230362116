import Editor from '../layouts/editor'
import Home from '../layouts/home'
import TemplateEditor from '../layouts/templateEditor'
import RenderLayout from '../layouts/renderLayouts'
import PageNotFound from '../components/PageNotFound/PageNotFound'

const navRoutes = [
  {
    path: '/*',
    name: 'Home',
    tooltip: 'View your projects',
    iconClass: 'fa fa-list-alt',
    component: Home,
    exact: true,
  },
  //OLD V5 Route
  // {
  //   path: '/',
  //   name: 'Home',
  //   tooltip: 'View your projects',
  //   iconClass: 'fa fa-list-alt',
  //   component: Home,
  //   exact: true,
  // },
  {
    path: '/experience/:id',
    name: 'Editor',
    tooltip: 'View your projects',
    iconClass: 'fa fa-list-alt',
    component: Editor,
    exact: true,
  },
  {
    path: '/experience/:id/:preSelectedProjectId',
    name: 'Editor',
    tooltip: 'View your projects',
    iconClass: 'fa fa-list-alt',
    component: Editor,
    exact: true,
  },
  //Old V5 Route
  // {
  //   path: '/experience/:id/:preSelectedProjectId?/:currentView?',
  //   name: 'Editor',
  //   tooltip: 'View your projects',
  //   iconClass: 'fa fa-list-alt',
  //   component: Editor,
  //   exact: true,
  // },
  //Old V5 Route
  // {
  //   path: '/home/:exp?/:projectId?',
  //   name: 'Home',
  //   tooltip: 'View your projects',
  //   iconClass: 'fa fa-list-alt',
  //   component: Home,
  //   exact: true,
  // },
  {
    path: '/template/:id',
    name: 'Template Editor',
    tooltip: 'Edit Template',
    iconClass: 'fa fa-list-alt',
    component: TemplateEditor,
    exact: true,
  },
  //Old V5 Route
  // {
  //   path: '/render/:expId/:renderId?',
  //   name: 'Render Preview',
  //   tooltip: 'View all renders',
  //   iconClass: 'fa fa-list-alt',
  //   component: RenderLayout,
  //   exact: true,
  // },
  {
    path: '/render/:expId/',
    name: 'Render Preview',
    tooltip: 'View all renders',
    iconClass: 'fa fa-list-alt',
    component: RenderLayout,
    exact: true,
  },
  {
    path: '/render/:expId/:renderId',
    name: 'Render Preview',
    tooltip: 'View all renders',
    iconClass: 'fa fa-list-alt',
    component: RenderLayout,
    exact: true,
  },
  { component: PageNotFound },
]

export default navRoutes
