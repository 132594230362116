import react, { useState, useEffect } from 'react'

import { makeStyles } from '@mui/styles'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'

import { CustomButton, Typography, FormTextField } from 'cgi-ui-components'
import { Backdrop, ToggleButtonGroup, ToggleButton } from '@mui/material'
import axios from 'axios'
import config from '../../config/config'

const useStyles = makeStyles((theme) => ({
  widget: {
    position: 'fixed',
    top: 230,
    zIndex: theme.zIndex.drawer + 10,
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    width: 500,
    maxHeight: `calc(100vh - 230px)`,
  },
  open: {
    right: 0,
    transition: `right 0.4s ease-in-out`,
    zIndex: theme.zIndex.drawer + 11,
  },
  close: {
    right: -388,
    transition: `right 0.4s ease-in-out`,
  },
  feedbackButton: {
    '&:hover': { textDecoration: 'none !important' },
    transform: 'translate(35px, 35px) rotate(-90deg)',
  },
  content: {
    background: '#FFF',
    borderRadius: 4,
    padding: '10px 10px 0 10px',
  },
  section: {
    borderRadius: 4,
    marginBottom: 10,
    padding: '6px 10px 6px 10px',
  },
  header: {
    background: '#CC0000',
    padding: '40px 6px',
    display: 'flex',
  },
  buttonAlign: { width: '80%', margin: 'auto' },
}))

const Feedback = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')
  const [feedbackType, setFeedbackType] = useState('')

  const handleClick = (e) => {
    setOpen(!open)
    setFeedbackText('')
    setFeedbackType('')
  }

  const RotatedButton = () => {
    return (
      <div>
        <CustomButton
          label={'Feedback'}
          color="primary"
          onClick={handleClick}
          variant="contained"
          disableElevation
          size="large"
          className={classes.feedbackButton}
        />
      </div>
    )
  }

  const content = () => {
    const iconList = [
      {
        name: 'Bad',
        type: 'BAD',
        icon: <SentimentVeryDissatisfiedIcon color="primary" />,
      },
      {
        name: 'Good',
        type: 'GOOD',
        icon: <SentimentSatisfiedAltOutlinedIcon color="success" />,
      },
    ]
    return (
      <div className={classes.content}>
        <div className={classes.section}>
          <Typography
            label="Please rate your experience of using the tool."
            variant="body2"
            color="tertiary"
            style={{ fontWeight: 600 }}
          ></Typography>
          <div>
            <ToggleButtonGroup
              value={feedbackType}
              exclusive
              onChange={handleChange}
              aria-label="feedback"
              style={{ padding: '30px 0px' }}
              size="large"
            >
              {iconList.map(({ name, type, icon }) => (
                <ToggleButton value={type} aria-label={name}>
                  {icon}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            {feedbackType !== '' && <div>{displayFeedbackTextArea()}</div>}
          </div>
        </div>
      </div>
    )
  }

  const Header = () => (
    <div className={classes.header}>
      <Typography label="Give Feedback" variant="h6" color="secondary" />
    </div>
  )

  const displayFeedbackTextArea = () => {
    return (
      <>
        <Typography
          label={
            feedbackType !== '' &&
            `Please share your thoughts on why you think the experience of the tool is ${
              feedbackType === 'BAD' ? 'bad' : 'great'
            }.`
          }
          variant="body2"
          color="tertiary"
          style={{ fontWeight: 600 }}
        />
        <FormTextField
          multiline
          onChange={handleTextChange}
          defaultValue={feedbackText}
          value={feedbackText}
          rows={7}
          maxRows={10}
          style={{ margin: '20px 0px' }}
        />
        <div className={classes.buttonAlign}>
          <CustomButton
            label="Submit"
            onClick={() => {
              onSubmitFeedback()
            }}
            style={{ width: '100%', margin: '10px 0px 20px' }}
          />
        </div>
      </>
    )
  }

  const onSubmitFeedback = () => {
    let payload = {
      feedback: feedbackType,
      feedback_message: feedbackText,
    }

    axios
      .post(config.feedback, payload)
      .then(() => {
        setOpen(!open)
        setFeedbackType('')
      })
      .catch((err) => {
        setOpen(!open)
        setFeedbackType('')
      })
  }

  const handleChange = (event, type) => {
    if (type !== null) {
      setFeedbackType(type)
      setFeedbackText('')
    }
  }

  const handleTextChange = (event) => {
    setFeedbackText(event.target.value)
  }

  return (
    <>
      <div
        className={`${classes.widget} ${open ? classes.open : classes.close}`}
        style={{ height: feedbackType !== '' && `calc(100vh - 230px)` }}
      >
        <RotatedButton />
        <div className={classes.container}>
          <Header />
          {content()}
        </div>
      </div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={open}
        onClick={handleClick}
      ></Backdrop>
    </>
  )
}

export default Feedback
