import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import CircularProgress from '@mui/material/CircularProgress'

import ReplayIcon from '@mui/icons-material/Replay'
import RefreshIcon from '@mui/icons-material/Refresh'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import IconButton from '../button/iconButton'
import { showNotification } from '../../store/notification/actionCreator'
import { NotificationToast } from '../../components/Notifications/Notifications'
import { showBulkTcinDialog } from '../../store/board/actionCreator'
import { canUserWrite, isExternalUser } from '../../helpers/utils'
import HelpContent from '../HelpContent/HelpContent'

import config from '../../config/config'

const styles = (theme) => ({
  root: {
    position: 'fixed',
    top: 5,
    backgroundColor: '#FFF',
    zIndex: 101,
    width: 350,
    left: 'Calc((100vw - 350px)/2)',
    borderRadius: 50,
  },
  barWidth: {
    height: 101,
  },
  logo: {
    height: 45,
    width: 45,
    marginTop: 27,
    marginLeft: 23,
  },
  text: {
    marginTop: 37,
    marginLeft: 23,
    fontSize: 16,
  },
  toolBar: {
    textAlign: 'center',
  },
  icon: {
    display: 'inline-block',
    padding: '0px 15px',
    '&.clearProjectName': {
      top: 16,
      right: 0,
      position: 'absolute',
      padding: '16px 12px',
      height: 24,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.sideNavHover,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.sideNavHover,
    },
  },
  activeIcon: {
    display: 'inline-block',
    padding: '0px 15px',
    backgroundColor: theme.palette.primary.sideNavHover,
  },
  separatorWrapper: {
    display: 'inline-block',
    padding: '0px 15px',
  },
  iconBox: {
    width: '10%',
    float: 'left',
  },
  title: {
    fontSize: 14,
  },
  nameEdit: {
    paddingTop: 16,
    color: theme.palette.primary.gray,
    '& button': {
      fontWeight: 500,
    },
  },
  iconsBar: {
    paddingTop: '14px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menu: {
    '& ul': {
      padding: 0,
    },
  },
  separator: {
    height: '42px',
    borderLeft: '1px solid ' + theme.palette.primary.grayLight,
  },
  img: {
    width: 60,
    top: 20,
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
    left: 0,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  exportText: {
    whiteSpace: 'nowrap',
  },
})
class TemplateEditorHeader extends Component {
  state = {
    expFormSubmitLoading: false,
    saveExpDisabled: false,
    isEdit: false,
    openDialog: false,
    openDesignMenu: false,
    anchorEl: null,
    isOpenHelp: null,
    isIconSelected: false,
    isEditFromTemplate: false,
    isCreateMode: false,
    changeTemplate: false,
    loadedPercentage: 0,
    loadingMessage: '',
    showLoaderToast: false,
    isNotificationHidden: false,
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval)
    document.removeEventListener('onAssetLoaded', this.showLoader)
  }

  componentDidMount = () => {
    this.saveInterval = setInterval(() => {
      if (!this.props.assetsLoading) {
        this.props.saveTemplate()
      }
    }, config.templateAutoSaveTime * (60 * 1000))
    document.addEventListener('onAssetLoaded', this.showLoader)
    //this.props.showNotification(true, "loading scene... 0%", 'info')
  }

  showLoader = (e) => {
    const {
      detail: { total, loaded, failed },
    } = e
    let loadedPercentage = parseInt((loaded / total) * 100)
    loadedPercentage = isNaN(loadedPercentage) ? 0 : loadedPercentage
    let showLoaderToast = false
    if (loaded + failed !== total) {
      showLoaderToast = true
    }
    this.setState({
      loadedPercentage,
      loadingMessage: `loading scene... ${loadedPercentage}%`,
      showLoaderToast,
    })

    if (loadedPercentage === 100) {
      setTimeout(() => {
        this.setState({ showLoaderToast: false })
      }, 7000)
    }
  }

  hideNotification = () => {
    this.setState({ isNotificationHidden: true })
  }

  openHelp = (event) => {
    this.setState({
      isOpenHelp: event.currentTarget,
      isIconSelected: true,
    })
  }

  showAddTcinDialog = (value) => {
    this.props.showBulkTcinDialog(value)
  }

  closeHelp = () => {
    this.setState({ isOpenHelp: null, isIconSelected: false })
  }

  handleTemplateBack = () => {
    this.toggleExperienceFormModal(true)
    this.setState({ isEdit: true, isEditFromTemplate: true })
  }

  loadTemplateToScene = (replaceTemplate = false) => {
    const {
      experience: { template_id: tempId, template_default: tempDef } = {},
      templateList,
      loadTemplate,
    } = this.props
    this.setState({ isCreateMode: false })
    const templateData = templateList.filter(
      ({ template_id }) => template_id === tempId
    )[0]
    if (templateData !== undefined) {
      const {
        template_draco_loc: templateUrl,
        scene_data: sceneData,
        high_low_poly_available: isHighLowAvailable,
      } = templateData
      const applyWorldRotationOnAssets = true
      if (templateUrl && templateUrl !== '') {
        loadTemplate(
          tempId,
          templateUrl,
          { scene_data: sceneData, template_default: tempDef },
          replaceTemplate,
          isHighLowAvailable,
          applyWorldRotationOnAssets
        )
      }
    }
  }

  render() {
    const {
      classes,
      auth = {},
      experienceDetail = {},
      saveStatus,
      assetsLoading = false,
      undoAction = () => {},
      redoAction = () => {},
      Board,
      //Board = { assets: [] },
      navigate = () => {},
      undoRedoButtonStatus: { activateUndo = false, activateRedo = false } = {},
      experience: { template_id: sceneTemplate } = {},
      resetCamera,
      currentTemplateDetails,
      saveTemplate,
    } = this.props
    const {
      saveExpDisabled,
      isEdit,
      isCreateMode,
      isOpenHelp,
      changeTemplate,
      loadedPercentage,
      showLoaderToast,
      loadingMessage,
      isNotificationHidden,
    } = this.state

    const hasWriteAccess = canUserWrite(
      auth.lanId,
      experienceDetail.initiator,
      experienceDetail.members
    )
    const externalUser = isExternalUser(auth.memberOf)

    return (
      <div>
        <div className={classes.root} color="default">
          <div className={classes.headerWrapper}>
            <div className={classes.toolBar}>
              {/* <div className={classes.nameEdit}>
                <ButtonBase
                  className={classes.title}
                  style={{
                    cursor: isEdit && hasWriteAccess ? 'pointer' : 'auto',
                    fontWeight: assetsLoading ? 'bold' : 'normal',
                  }}
                >
                  {saveStatus + ' '}
                  {assetsLoading ? (
                    <>
                      {' '}
                      <CircularProgress size={16} thickness={6} />
                      &nbsp;Loading{' '}
                    </>
                  ) : (
                    ''
                  )}
                  {currentTemplateDetails.template_name || 'Untitled'}&nbsp;
                  {assetsLoading ? ` ...${loadedPercentage}%` : ''}
                </ButtonBase>
              </div> */}
              <div className={classes.iconsBar}>
                <div className={classes.icon}>
                  <IconButton
                    disabled={!activateUndo}
                    icon={<ReplayIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                    text="UNDO"
                    onClick={undoAction}
                  />
                </div>
                <div className={classes.icon}>
                  <IconButton
                    disabled={!activateRedo}
                    icon={<RefreshIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                    text="REDO"
                    onClick={redoAction}
                  />
                </div>

                <div
                  className={
                    saveExpDisabled ? classes.activeIcon : classes.icon
                  }
                >
                  <IconButton
                    disabled={saveExpDisabled || assetsLoading}
                    icon={<SaveOutlinedIcon htmlColor="rgba(0, 0, 0, 0.6)" />}
                    text="SAVE"
                    onClick={saveTemplate}
                  />
                </div>

                <div className={classes.icon} tabIndex="4">
                  <IconButton
                    icon={
                      <CameraAltOutlinedIcon htmlColor="rgba(0, 0, 0, 0.6)" />
                    }
                    text="RESET"
                    onClick={resetCamera}
                  />
                </div>

                <div className={classes.icon} tabIndex="5">
                  <IconButton
                    icon={
                      <HelpOutlineOutlinedIcon htmlColor="rgba(0, 0, 0, 0.6)" />
                    }
                    text="HELP"
                    onClick={this.openHelp}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <HelpContent anchorEl={isOpenHelp} closeHelp={this.closeHelp} />
        {!isNotificationHidden && (
          <NotificationToast
            notificationIsShown={showLoaderToast}
            notificationVariant="info"
            showNotification={this.hideNotification}
            notificationMessage={loadingMessage}
          />
        )}
      </div>
    )
  }
}
const mapStateToProps = ({
  auth,
  Board,
  experiences: { experience },
  templates: { templateList, shouldChangeTemplate, currentTemplateDetails },
}) => ({
  lanId: auth.lanId,
  email: auth.email,
  accessToken: auth.accessToken,
  Board,
  experience,
  templateList,
  shouldChangeTemplate,
  currentTemplateDetails,
})

export const TemplateEditorHeaderWithStyles =
  withStyles(styles)(TemplateEditorHeader)
export default connect(mapStateToProps, {
  showNotification,
  showBulkTcinDialog,
})(TemplateEditorHeaderWithStyles)
