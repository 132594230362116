import Moment from 'moment'
import MomentTM from 'moment-timezone'

export const formatMembers = (membersObj = []) => {
  if (!membersObj) return []
  let membersArr = []
  membersObj.forEach((entry) => {
    if (entry.user) {
      membersArr.push({
        login_id: entry.user.login_id,
        permission: entry.access_level,
      })
    }
  })
  return membersArr
}

export const formatDate = function (date = '', delimiter = '-') {
  if (!date) {
    return date || ''
  }
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join(delimiter)
}

export const millisToMinutesAndSeconds = function (millis) {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + '.' + (seconds < 10 ? '0' : '') + seconds
}

export const getDisplayDate = function (
  dateParam,
  isAlreadyCst = false,
  format = 'DD/MM/YYYY HH:mm:ss z',
  currentTimeZone = 'IST' // options: IST or CDT'
) {
  if (Moment(dateParam).isValid()) {
    if (isAlreadyCst) {
      return Moment(dateParam).utc().format(format)
    }
    if (currentTimeZone === 'IST') {
      return MomentTM.tz(dateParam, 'Asia/Kolkata').format(format)
    } else {
      return MomentTM.tz(dateParam, 'America/chicago').format(format)
    }
  }
  return ''
}
export const readableDate = (dateString = '') => {
  if (dateString === null || dateString === '') {
    return 'N/A'
  }
  const year = dateString.split('-')[0]
  const month = findMonth(dateString.split('-')[1])
  const day = dateString.split('-')[2].substring(0, 2)
  return month + ' ' + day + ', ' + year
}

export const readableDateAndTime = (date = '') => {
  if (date === null || date === '') {
    return 'N/A'
  }

  return new Date(date).toLocaleString('en-US', {
    timeZone: 'America/Mexico_City',
    hour12: false,
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  })
}

export const findMonth = (month) => {
  switch (month) {
    case '01':
      return 'JAN'
    case '02':
      return 'FEB'
    case '03':
      return 'MAR'
    case '04':
      return 'APR'
    case '05':
      return 'MAY'
    case '06':
      return 'JUN'
    case '07':
      return 'JUL'
    case '08':
      return 'AUG'
    case '09':
      return 'SEP'
    case '10':
      return 'OCT'
    case '11':
      return 'NOV'
    case '12':
      return 'DEC'
    default:
      return ''
  }
}
export const getHourMinute = (dateStr) => {
  const currentTimeZone =
    new Date().toString().indexOf('India Standard Time') > -1 ? 'IST' : 'CDT'
  const dispDate = getDisplayDate(
    dateStr,
    false,
    'DD/MM/YYYY HH:mm:ss z',
    currentTimeZone
  )
  const time = dispDate.substring(11, dispDate.length)
  return `${time.substr(0, 5)} ${time.substr(9, 13)}`
}

export const readableDateAndTimeDigitFormat = (date = '') => {
  if (date === null || date === '') {
    return ' '
  }

  return new Date(date)
    .toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'long',
    })
    .replace('India Standard Time', 'IST')
}
