import React from 'react'

import { styled } from '@mui/material/styles'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { Tooltip, CustomIconButton } from 'cgi-ui-components'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, barPlacement }) => ({
    top: 130,
    position: 'fixed',
    backgroundColor: '#FFF',
    zIndex: 11,
    right: 20,
    borderRadius: 25,

    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0.5),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
      },
      '&:not(:first-of-type)': {
        borderRadius: '50%',
      },
      '&:first-of-type': {
        borderRadius: '50%',
      },
    },
  })
)

export default function IconsCapsuleBar({
  icons = [],
  barPlacement = {},
  onChange = () => {},
  simpleButton = [],
  disabled = false,
}) {
  const [view, setView] = React.useState('')

  const handleChange = (event, nextView) => {
    setView(nextView)
    onChange(event, nextView)
  }

  return (
    <StyledToggleButtonGroup
      orientation="vertical"
      value={view}
      exclusive
      onChange={handleChange}
      style={barPlacement}
      disabled={disabled}
    >
      {icons.map((item, key) => {
        return (
          <ToggleButton key={key} value={item.name} aria-label="list">
            <Tooltip title={item.toolTip}>{item.icon}</Tooltip>
          </ToggleButton>
        )
      })}
      {simpleButton &&
        simpleButton.map(({ action, toolTip, icon, color }, i) => (
          <CustomIconButton
            key={i}
            onClick={() => action()}
            color={color}
            label={toolTip}
          >
            {icon}
          </CustomIconButton>
        ))}
    </StyledToggleButtonGroup>
  )
}
