import React from 'react'
import { withStyles } from '@mui/styles'
import Proptypes from 'prop-types'
import { connect } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Tooltip from '@mui/material/Tooltip'
import { Typography } from '@mui/material'

import SearchInput from '../searchInput/SearchInput'
import {
  fetchFilterListsData,
  handlefilterChange,
} from '../../store/explorer/actionCreator'

const styles = (theme) => ({
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px',
    boxSizing: 'border-box',
    height: '30px',
    borderRadius: '4px',
    width: '166px',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  searchWrapperMax: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px',
    boxSizing: 'border-box',
    height: '24px',
    borderRadius: '4px',
    width: '400px',
  },
  facetTitle: {
    margin: '15px 15px 15px 15px !important',
    height: '21px',
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21.98px',
    display: 'block',
    fontWeight: '500',
  },
  itemCheck: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0px 10px 0px 10px',
    overFlow: 'hidden',
  },
  itemCheckMax: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0px 10px 0 10px',
    overFlow: 'hidden',
  },
  label: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '12px',
    // marginLeft: '15px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  labelmax: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkBox: {
    '&.MuiCheckbox-root': {
      padding: 5,
    },
  },
  iconButton: {
    color: theme.palette.primary.secondaryText,
    fontSize: 14,
    margin: '11px 4px 11px -12px',
    borderLeft: `0px solid ${theme.palette.primary.veryLightGray}`,
    '&:hover': {
      background: 'none',
    },
  },
  sticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 10,
  },
  noResultMin: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: 12,
    marginLeft: 15,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noResultMax: {
    color: '#333333',
    fontFamily: 'Roboto',
    fontSize: 14,
    marginLeft: 15,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: 10,
    lineHeight: '20px',
    width: 'Calc(100% - 52px)',
    '& ::placeholder': {
      color: '#333',
      opacity: '0.7',
      fontFamily: 'Roboto',
      fontSize: '12px',
      marginLeft: '3px',
    },
  },
})
class FilterDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterItems: [],
      searchFilterText: '',
    }
  }
  handleCheck = (filterkey, index, name) => (e) => {
    e.nativeEvent.preventDefault()
    this.props
      .handlefilterChange(filterkey, index, e.target.checked)
      .then(() => {
        this.props.handleFilters(filterkey, index, name, e.target.checked)
      })
  }
  handleChange = (value) => {
    this.setState({
      searchFilterText: value,
    })
  }
  clearText = () => {
    this.setState({ searchFilterText: '' })
  }
  transformToPascel(text) {
    return text.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase()
    })
  }
  render() {
    const {
      classes = {},
      title = '',
      filterLists = [],
      filterKey = '',
      maximizedView,
      isMinified,
    } = this.props
    const { searchFilterText = '' } = this.state
    let searchedFilterItems = filterLists.filter(
      (item) =>
        item.name &&
        item.name.toLowerCase().includes(searchFilterText.toLowerCase())
    )
    return (
      <>
        <div className={classes.sticky}>
          <Typography variant="subtitle2" className={classes.facetTitle}>
            {title}
          </Typography>
          {filterLists.length >= 10 && (
            <SearchInput
              placeholder={`filter ${title.toLowerCase()}...`}
              searchVal={searchFilterText}
              classes={{ input: classes.input, iconButton: classes.iconButton }}
              onSearch={this.handleChange}
              onSearchButtonClick={this.clearText}
              customStyles={{
                searchWrapper: maximizedView
                  ? classes.searchWrapperMax
                  : classes.searchWrapper,
              }}
              width={isMinified ? '125px' : '166px'}
            />
          )}
        </div>

        {searchedFilterItems.length !== 0 ? (
          searchedFilterItems.map(({ name, isSelected, id }) => (
            <div
              className={
                maximizedView ? classes.itemCheckMax : classes.itemCheck
              }
              key={name}
            >
              <Checkbox
                color="primary"
                onChange={this.handleCheck(filterKey, id, name)}
                checkedIcon={
                  <CheckBoxIcon style={{ fontSize: maximizedView ? 20 : 17 }} />
                }
                icon={
                  <CheckBoxOutlineBlankIcon
                    style={{ fontSize: maximizedView ? 20 : 17 }}
                  />
                }
                classes={{
                  root: classes.checkBox,
                }}
                checked={isSelected}
              />
              <Tooltip
                title={
                  filterKey == 'department_name'
                    ? this.transformToPascel(name)
                    : name
                }
                placement="top"
                disableInteractive
              >
                <Typography
                  variant="body2"
                  className={maximizedView ? classes.labelmax : classes.label}
                >
                  {filterKey == 'department_name'
                    ? this.transformToPascel(name)
                    : name}
                </Typography>
              </Tooltip>
            </div>
          ))
        ) : (
          <span
            className={
              maximizedView ? classes.noResultMax : classes.noResultMin
            }
          >
            No results
          </span>
        )}
      </>
    )
  }
}

FilterDrawer.propTypes = {
  classes: Proptypes.object.isRequired,
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps, {
  fetchFilterListsData,
  handlefilterChange,
})(withStyles(styles)(FilterDrawer))
