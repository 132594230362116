import {
  FETCH_PURCHASED_ASSETS_INITIATED,
  FETCH_PURCHASED_ASSETS_SUCCESS,
  FETCH_PURCHASED_ASSETS_FAILED,
  CLEAR_PURCHASED_ASSET_RESULTS,
  DELETE_PURCHASE_ASSETS_SUCCESS,
  EDIT_PURCHASE_ASSETS_SUCCESS,
  SAVE_PURCHASE_ASSETS_SUCCESS,
  SAVE_PURCHASE_ASSETS_FAIL,
  SAVE_PURCHASE_ASSETS_INITIATED,
  FETCH_PURCHASE_UPLOAD_STATUS_INITIATED,
  FETCH_PURCHASE_UPLOAD_STATUS_FAILED,
  FETCH_PURCHASE_UPLOAD_STATUS_SUCCESS,
  PURCHASE_UPLOAD_COMPLETED,
  SET_TOTAL_ASSET_COUNT,
  EDIT_PURCHASE_ASSETS_INITIATED,
  EDIT_PURCHASE_ASSETS_FAIL,
  FILTER_LISTS_SUCCESS,
  CLEAR_ALL_FILTERS,
  FILTER_COUNT_CHANGE,
  FILTER_LISTS_CHANGE,
  HANDLE_SELECT_PURCHASED_ASSET,
  FETCH_STYLED_ASSETS_SUCCESS,
  FETCH_STYLED_ASSETS_INITIATED,
  CLEAR_STYLED_ASSETS,
  FETCH_SIMILAR_ASSETS_ASSETTAB_SUCCESS,
  CLEAR_SIMILAR_ASSETS_ASSETTAB,
} from './actionType'
import { getUploadStatus } from '../../helpers/utils'
import { SUCCESS } from '../../constants/common'

export const initialState = {
  loading: false,
  total_count: 0,
  purchasedAssets: [],
  status_response_purchase: [],
  loadingPurchasedSavedAsset: false,
  savedPurchasedAssetStatusCode: 0,
  totalAssetsAvailable: 0,
  loadingPurchasedEditAsset: false,
  filterLists: {},
  selectedFilterCount: 0,
  selectedAssets: [],
  selectedAssetsData: [],
  countOfPurchasedAssetSelected: 0,
  styledAssets: [],
  totalStyledAssets: 0,
  similarAssets: [],
  similarAssetstotalRecords: 0,
  primary_tcin_detail: {},
}

export default function purchasedAssets(state = initialState, action) {
  switch (action.type) {
    case FETCH_PURCHASED_ASSETS_INITIATED:
      return { ...state, loading: true }

    case FETCH_PURCHASED_ASSETS_SUCCESS: {
      const { data: result } = action
      const { search_response: purchasedAssets, total_count } = result
      const status = {
        InProgress: 1,
        Failed: 2,
        Success: 3,
      }
      let modifiedPurchasedAsset = purchasedAssets.map((item) => {
        item.derivedStatus = getUploadStatus(
          item.hpa_upload_status,
          item.thumbnail_upload_status
        ).status
        return item
      })
      modifiedPurchasedAsset = modifiedPurchasedAsset
        .map((item) => ({
          ...item,
          isSelected: false,
        }))
        .sort((a, b) => status[a.derivedStatus] - status[b.derivedStatus])
      let modifiedSearchResponse = purchasedAssets.filter(
        (item) =>
          getUploadStatus(item.hpa_upload_status, item.thumbnail_upload_status)
            .status !== 'Success'
      )
      modifiedSearchResponse = modifiedSearchResponse
        .map((item) => {
          item.derivedStatus = getUploadStatus(
            item.hpa_upload_status,
            item.thumbnail_upload_status
          ).status
          return item
        })
        .sort((a, b) => status[a.derivedStatus] - status[b.derivedStatus])

      const resultObj = {
        purchasedAssets: [...state.purchasedAssets, ...modifiedPurchasedAsset],
        total_count,
        loading: false,
        status_response_purchase: modifiedSearchResponse,
        noResultsFound: false,
      }
      return { ...state, ...resultObj }
    }

    case FETCH_PURCHASED_ASSETS_FAILED:
      return { ...state, loading: false }

    case HANDLE_SELECT_PURCHASED_ASSET: {
      const { isSelected, data, clearData } = action.payload
      let selectedAssets = []
      let selectedAssetsData = []
      if (!clearData) {
        if (isSelected) {
          selectedAssets = [...state.selectedAssets, data.tcin]
          selectedAssetsData = [
            ...state.selectedAssetsData,
            {
              ...data,
              asset_type:
                data.asset_type === 'SANDBOX' || data.asset_type === 'TCIN'
                  ? 'TCIN'
                  : 'PROP',
              value: data.tcin,
            },
          ]
        } else {
          selectedAssets = state.selectedAssets.filter((i) => i !== data.tcin)
          selectedAssetsData = state.selectedAssetsData.filter(
            ({ value }) => value !== data.tcin
          )
        }
      }

      return { ...state, selectedAssets, selectedAssetsData }
    }

    case CLEAR_PURCHASED_ASSET_RESULTS:
      return { ...state, total_count: 0, purchasedAssets: [] }

    case SAVE_PURCHASE_ASSETS_SUCCESS: {
      return {
        ...state,
        loadingPurchasedSavedAsset: false,
        status_response_purchase: [
          { ...action.data },
          ...state.status_response_purchase,
        ],
        savedPurchasedAssetStatusCode: 200,
      }
    }
    case SAVE_PURCHASE_ASSETS_FAIL: {
      return {
        ...state,
        loadingPurchasedSavedAsset: false,
        savedPurchasedAssetStatusCode: action.error,
      }
    }
    case SAVE_PURCHASE_ASSETS_INITIATED: {
      return {
        ...state,
        loadingPurchasedSavedAsset: true,
      }
    }
    case EDIT_PURCHASE_ASSETS_SUCCESS: {
      return {
        ...state,
        loadingPurchasedEditAsset: false,
      }
    }
    case EDIT_PURCHASE_ASSETS_INITIATED: {
      return {
        ...state,
        loadingPurchasedEditAsset: true,
      }
    }
    case EDIT_PURCHASE_ASSETS_FAIL: {
      return {
        ...state,
        loadingPurchasedEditAsset: false,
      }
    }

    case DELETE_PURCHASE_ASSETS_SUCCESS: {
      return {
        ...state,
        purchasedAssets: state.purchasedAssets.filter(
          (item) => item.tcin !== action.data.tcin
        ),
        status_response_purchase: state.status_response_purchase.filter(
          (item) => item.tcin !== action.data.tcin
        ),
        total_count: state.total_count - 1,
      }
    }
    case FETCH_PURCHASE_UPLOAD_STATUS_INITIATED:
      return { ...state }

    case FETCH_PURCHASE_UPLOAD_STATUS_SUCCESS: {
      const status = {
        InProgress: 1,
        Failed: 2,
        Success: 3,
      }
      let responseMap = {}
      action.data.forEach((item) => {
        responseMap[item.tcin] = item
      })

      let responseArray = state.status_response_purchase.map((item) => {
        let newAttributes = {}

        if (
          item.hpa_upload_status !== SUCCESS &&
          item.thumbnail_upload_status !== SUCCESS &&
          responseMap[item.tcin] &&
          responseMap[item.tcin].hpa_upload_status === SUCCESS &&
          responseMap[item.tcin].thumbnail_upload_status === SUCCESS
        ) {
          newAttributes.showSuccessTick = true
        }

        newAttributes.derivedStatus = responseMap[item.tcin]
          ? getUploadStatus(
              responseMap[item.tcin].hpa_upload_status,
              responseMap[item.tcin].thumbnail_upload_status
            ).status
          : getUploadStatus(
              item.hpa_upload_status,
              item.thumbnail_upload_status
            ).status

        if (responseMap[item.tcin]) {
          newAttributes.hpa_upload_status =
            responseMap[item.tcin].hpa_upload_status
          newAttributes.thumbnail_upload_status =
            responseMap[item.tcin].thumbnail_upload_status
          newAttributes.thumbnail_path = responseMap[item.tcin].thumbnail_path
        }

        return { ...item, ...newAttributes }
      })

      responseArray.sort(
        (a, b) => status[a.derivedStatus] - status[b.derivedStatus]
      )

      return {
        ...state,
        status_response_purchase: responseArray,
        loading: false,
      }
    }
    case PURCHASE_UPLOAD_COMPLETED: {
      const status = {
        InProgress: 1,
        Failed: 2,
        Success: 3,
      }
      let responseArray = state.status_response_purchase.map((item) => {
        let newAttributes = {}
        if (
          item.hpa_upload_status === SUCCESS &&
          item.thumbnail_upload_status === SUCCESS
        ) {
          newAttributes.showSuccessTick = false
        }
        newAttributes.derivedStatus = getUploadStatus(
          item.hpa_upload_status,
          item.thumbnail_upload_status
        ).status
        newAttributes.thumbnail_path = item.thumbnail_path
        return { ...item, ...newAttributes }
      })
      responseArray.sort(
        (a, b) => status[a.derivedStatus] - status[b.derivedStatus]
      )
      return {
        ...state,
        status_response_purchase: responseArray,
      }
    }
    case FETCH_PURCHASE_UPLOAD_STATUS_FAILED:
      return {
        ...state,
      }
    case SET_TOTAL_ASSET_COUNT: {
      return { ...state, totalAssetsAvailable: action.data }
    }

    case FILTER_LISTS_SUCCESS: {
      let filters = {}
      let filterid = 1
      Object.keys(action.payload).forEach((item) => {
        filters[item] = action.payload[item].map((it) => ({
          ...it,
          isSelected: false,
          id: filterid++,
        }))
      })
      filters = {
        ...{
          Asset_Contains: [
            { name: 'Styled Variations', isSelected: false, id: 98 },
            { name: 'UnBundled Version', isSelected: false, id: 99 },
          ],
        },
        ...filters,
      }
      return {
        ...state,
        filterLists: filters,
        loading: false,
        selectedFilterCount: 0,
      }
    }

    case FILTER_LISTS_CHANGE: {
      const { filterkey, id } = action.payload
      let newfilterValue = [...state.filterLists[filterkey]].map(
        ({ ...item }) => {
          if (id === item.id) {
            item.isSelected = !item.isSelected
          }
          return { ...item }
        }
      )

      return {
        ...state,
        filterLists: {
          ...state.filterLists,
          ...{ [filterkey]: [...newfilterValue] },
        },
      }
    }

    case FILTER_COUNT_CHANGE:
      return {
        ...state,
        selectedFilterCount: state.selectedFilterCount + action.payload,
      }

    case CLEAR_ALL_FILTERS: {
      let filters = {}
      const { filterLists } = state
      Object.keys(filterLists).forEach((item) => {
        filters[item] = filterLists[item].map((it) => ({
          ...it,
          isSelected: false,
        }))
      })
      return {
        ...state,
        filterLists: filters,
        loading: false,
        selectedFilterCount: 0,
      }
    }

    case FETCH_STYLED_ASSETS_INITIATED: {
      return { ...state, loading: true }
    }

    case CLEAR_STYLED_ASSETS: {
      const emptyList = {
        styledAssets: [],
        totalStyledAssets: 0,
      }
      return { ...state, ...emptyList }
    }

    case FETCH_STYLED_ASSETS_SUCCESS: {
      const { data: result } = action
      const { search_response: styledAssets, total_count: totalRecords } =
        result
      const resultObj = {
        styledAssets: [...state.styledAssets, ...styledAssets],
        totalStyledAssets: totalRecords,
        loading: false,
      }
      return { ...state, ...resultObj }
    }
    case FETCH_SIMILAR_ASSETS_ASSETTAB_SUCCESS: {
      const { data: result } = action
      const {
        search_response: similarAssets,
        total_count: similarAssetstotalRecords,
        primary_tcin_detail,
      } = result
      const resultObj = {
        similarAssets: [...state.similarAssets, ...similarAssets],
        similarAssetstotalRecords: similarAssetstotalRecords,
        loading: false,
        primary_tcin_detail,
      }
      return { ...state, ...resultObj }
    }
    case CLEAR_SIMILAR_ASSETS_ASSETTAB: {
      const emptyList = {
        similarAssets: [],
        totalSimilarAssets: 0,
        noResultsFound: false,
        primary_tcin_detail: {},
      }
      return { ...state, ...emptyList }
    }
    default:
      return state
  }
}
