import React, { useEffect } from 'react'
import { makeStyles, styled } from '@mui/styles'
import Card from '@mui/material/Card'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturnOutlined'
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { CustomIconButton } from 'cgi-ui-components'
import IconButton from '@mui/material/IconButton'
import ModeSelectionButtons from '../designSpace/ModeSelector'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderBottom: '1px solid lightgrey',
  },
  content: {
    padding: 0,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    // paddingLeft: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },
  tabRoot: {
    '&.MuiTab-root': {
      minWidth: 'unset',
      minHeight: 'unset',
      padding: 0,
      margin: '0 7px',
    },
  },
  tabsRoot: {
    '&.MuiTabs-root': {
      minHeight: 'unset',
      minWidth: 'unset',
      padding: 0,
      margin: 10,
    },
  },
  tabPanelRoot: {
    '& .MuiBox-root': {
      padding: '0 10px 10px ',
    },
  },

  tabsFlexContainer: {
    height: '1.6rem',
  },

  closeIcon: {
    position: 'relative',
    left: 22,
    top: 4,
    width: '1.2rem',
    height: '1.2rem',
    paddingTop: 4,
  },
  leftArrow: {
    transform: `scaleX(-1) rotate(-90deg)`,
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    fontSize: '1.5rem',
  },
  downTilt: {
    transform: `scaleX(1) rotate(-90deg)`,
    gridColumn: '2 / 3',
    gridRow: '3 / 4',
    fontSize: '1.5rem',
  },
  rightArrow: {
    transform: `rotate(-90deg)`,
    gridColumn: '3 / 4',
    gridRow: '2 / 3',
    fontSize: '1.5rem',
  },
  rightTilt: {
    transform: `rotateY(180deg)`,
    gridColumn: '3 / 4',
    gridRow: '2 / 3',
    fontSize: '1.5rem',
  },
  upArrow: {
    transform: `scaleY(-1)`,
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
    fontSize: '1.5rem',
  },
  upTilt: {
    transform: `scaleY(-1) rotate(270deg)`,
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
    fontSize: '1.5rem',
  },
  downArrow: {
    gridColumn: '2 / 3',
    gridRow: '3 / 4',
    fontSize: '1.5rem',
  },
  leftTilt: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    fontSize: '1.5rem',
  },
  iconsContainer: {
    border: `1px solid #333333`,
    height: '6rem',
    width: '6rem',
    display: 'grid',
    gridTemplateColumns: '33% 33% 33%',
    gridTemplateRows: '33% 33% 33%',
    borderRadius: '50%',
  },
  boxStyle: {
    padding: 5,
  },
  zoomPanelContainer: {
    textAlign: 'right',
  },
  zoomPanel: {
    border: '1px solid #333333',
    padding: '9px 3px',
    display: 'flex',
    flexDirection: 'column',

    borderRadius: 45,
    marginRight: 10,
  },
  incIcon: {
    gridColumn: ' 1 / 2',
    gridRow: '1 / 2',
    color: '#333333',
  },
  decIcon: {
    gridColumn: ' 2 / 2',
    gridRow: '2 / 2',
    color: '#333333',
  },
  iconButton: {
    padding: 'unset',

    '&.MuiIconButton-root': {
      color: '#333333',
    },
    '&:hover': { backgroundColor: 'unset' },
  },
  cardContentRoot: {
    '&:last-child': { paddingBottom: 'unset' },
  },
  closeButton: {
    '&.MuiIconButton-root': {
      color: '#333333',
      margin: 4,
    },
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  const classes = useStyles()

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box classes={{ root: classes.boxStyle }} p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
export default function MediaControlCard(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const {
    toggleControlsPanel,
    handleCameraControls,
    toggleView,
    viewMode,
    showViewButtons = false,
  } = props

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Card>
      <div className={classes.root}>
        <div>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            classes={{
              root: classes.tabsRoot,
              flexContainer: classes.tabsFlexContainer,
            }}
          >
            <Tab
              disableFocusRipple
              classes={{ root: classes.tabRoot }}
              label="Pan"
              data-testid="pan_tab"
            />
            <Tab
              disableFocusRipple
              classes={{ root: classes.tabRoot }}
              label="Tilt"
              data-testid="tilt_tab"
            />
          </Tabs>

          <TabPanel value={value} className={classes.tabPanelRoot} index={0}>
            <div className={classes.iconsContainer}>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.leftArrow}
                onMouseDown={(e) => handleCameraControls(e, 'PAN_LEFT', true)}
                onMouseUp={(e) => handleCameraControls(e, 'PAN_LEFT', false)}
                onMouseOut={(e) => handleCameraControls(e, 'PAN_LEFT', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="pan_left"
              >
                <ArrowDropDownCircleIcon />
              </IconButton>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.upArrow}
                onMouseDown={(e) => handleCameraControls(e, 'PAN_UP', true)}
                onMouseUp={(e) => handleCameraControls(e, 'PAN_UP', false)}
                onMouseOut={(e) => handleCameraControls(e, 'PAN_UP', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="pan_up"
              >
                <ArrowDropDownCircleIcon />
              </IconButton>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.rightArrow}
                onMouseDown={(e) => handleCameraControls(e, 'PAN_RIGHT', true)}
                onMouseUp={(e) => handleCameraControls(e, 'PAN_RIGHT', false)}
                onMouseOut={(e) => handleCameraControls(e, 'PAN_RIGHT', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="pan_right"
              >
                <ArrowDropDownCircleIcon />
              </IconButton>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.downArrow}
                onMouseDown={(e) => handleCameraControls(e, 'PAN_DOWN', true)}
                onMouseUp={(e) => handleCameraControls(e, 'PAN_DOWN', false)}
                onMouseOut={(e) => handleCameraControls(e, 'PAN_DOWN', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="pan_down"
              >
                <ArrowDropDownCircleIcon />
              </IconButton>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanelRoot}>
            <div className={classes.iconsContainer}>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.leftTilt}
                onMouseDown={(e) => handleCameraControls(e, 'TILT_LEFT', true)}
                onMouseUp={(e) => handleCameraControls(e, 'TILT_LEFT', false)}
                onMouseOut={(e) => handleCameraControls(e, 'TILT_LEFT', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="tilt_left"
              >
                <KeyboardReturnIcon />
              </IconButton>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.upTilt}
                onMouseDown={(e) => handleCameraControls(e, 'TILT_UP', true)}
                onMouseUp={(e) => handleCameraControls(e, 'TILT_UP', false)}
                onMouseOut={(e) => handleCameraControls(e, 'TILT_UP', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="tilt_up"
              >
                <KeyboardReturnIcon />
              </IconButton>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.rightTilt}
                onMouseDown={(e) => handleCameraControls(e, 'TILT_RIGHT', true)}
                onMouseUp={(e) => handleCameraControls(e, 'TILT_RIGHT', false)}
                onMouseOut={(e) => handleCameraControls(e, 'TILT_RIGHT', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="tilt_right"
              >
                <KeyboardReturnIcon />
              </IconButton>
              <IconButton
                disableRipple
                classes={{ root: classes.iconButton }}
                className={classes.downTilt}
                onMouseDown={(e) => handleCameraControls(e, 'TILT_DOWN', true)}
                onMouseUp={(e) => handleCameraControls(e, 'TILT_DOWN', false)}
                onMouseOut={(e) => handleCameraControls(e, 'TILT_DOWN', false)}
                onContextMenu={(e) => handleCameraControls(e)}
                data-testid="tilt_down"
              >
                <KeyboardReturnIcon />
              </IconButton>
            </div>
          </TabPanel>
        </div>
        <div className={classes.zoomPanelContainer}>
          <CustomIconButton
            label="Collapse"
            classes={{ root: classes.closeButton }}
            size="small"
            onClick={toggleControlsPanel}
          >
            {' '}
            <CloseIcon />
          </CustomIconButton>

          <div className={classes.zoomPanel}>
            <IconButton
              disableRipple
              classes={{ root: classes.iconButton }}
              onMouseDown={(e) => handleCameraControls(e, 'ZOOM_IN', true)}
              onMouseUp={(e) => handleCameraControls(e, 'ZOOM_IN', false)}
              onMouseOut={(e) => handleCameraControls(e, 'ZOOM_IN', false)}
              onContextMenu={(e) => handleCameraControls(e)}
              data-testid="zoom_in"
            >
              <AddCircleIcon className={classes.incIcon} />
            </IconButton>
            <IconButton
              disableRipple
              classes={{ root: classes.iconButton }}
              onMouseDown={(e) => handleCameraControls(e, 'ZOOM_OUT', true)}
              onMouseUp={(e) => handleCameraControls(e, 'ZOOM_OUT', false)}
              onMouseOut={(e) => handleCameraControls(e, 'ZOOM_OUT', false)}
              onContextMenu={(e) => handleCameraControls(e)}
              data-testid="zoom_out"
            >
              <RemoveCircleIcon className={classes.decIcon} />
            </IconButton>
          </div>
        </div>
      </div>
      {showViewButtons && (
        <ModeSelectionButtons viewMode={viewMode} toggleView={toggleView} />
      )}
    </Card>
  )
}
