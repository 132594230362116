import React from 'react'
import { makeStyles } from '@mui/styles'

import { Button } from '@mui/material'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'

const useStyles = makeStyles((theme) => ({
  cleartext: {
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: '400',
  },
  clearallbutton: {
    '&:hover': {
      background: '#FFFFFF',
      boxShadow: 'none',
    },
  },
}))
export default function ClearAllFilterButton({ clearFilters }) {
  const classes = useStyles()
  const clearAllFilterAction = (e) => {
    e.nativeEvent.preventDefault()
    clearFilters()
  }
  return (
    <div>
      <Button className={classes.clearallbutton} onClick={clearAllFilterAction}>
        <span className={classes.cleartext}>Clear all</span>
        <CloseOutlinedIcon style={{ fontSize: 14, marginLeft: '3px' }} />
      </Button>
    </div>
  )
}
