import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'Calc(100vh - 200px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  contentEnd: {
    height: 30,
    width: '100%',
  },
})

class ListDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.contentEndRef = React.createRef()
  }
  componentDidMount() {
    if (!this.contentEndRef.current) return
    this.observer = new IntersectionObserver(this.props.interSectionCallback, {
      threshold: [0.1],
    })
    this.observer.observe(this.contentEndRef.current)
  }

  render() {
    const { showProgressBar = false, classes, containerClass } = this.props
    return (
      <>
        <div
          className={classNames(
            'datalistContainer',
            containerClass,
            classes.innerContainer
          )}
        >
          {this.props.children}
          <div ref={this.contentEndRef} className={classes.contentEnd}></div>
        </div>

        {showProgressBar && <LinearProgress />}
      </>
    )
  }
}
export default withStyles(styles)(ListDisplay)
