import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

import {
  Select,
  Dialog,
  AutoCompleteComponent,
  CustomButton,
  Typography,
  LinkComponent,
  PageTitleBar,
  CustomIconButton,
  FormTextField,
} from 'cgi-ui-components'
import { DialogContent, DialogActions, Popover } from '@mui/material'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep, uniqBy } from 'lodash'
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar'
import { experienceSaveChanges } from '../../store/experience/ActionCreator'
import { shareCollection } from '../../store/favorites/ActionCreator'
import api from '../../config/config'
import { showNotification } from '../../store/notification/actionCreator'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import axios from 'axios'
import {
  ERROR_MSG_FOR_UPDATE_PROJECT,
  ERROR_MSG_FOR_PROJECT_EXIST,
  ERROR_MSG_FOR_PROJECT_CYCLE_EXIST,
  ERROR_MSG_FOR_UPLOAD_THUMBNAIL_PROJECT,
} from '../../constants/displayMessages'
import {
  fetchProjectTypes,
  updateProject,
  uploadProjectThumbnail,
} from '../../store/experience/ActionCreator'

const styles = (theme) => {
  return {
    root: {
      margin: 0,
      padding: 16,
    },
    expName: {
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
    },

    dialogAct: {
      margin: '0 8px 12px 0',
      padding: '40px !important',
    },

    dialogPaperDefault: {
      height: '470px',
      width: '700px !important',
      position: 'absolute',
      top: '10%',
    },
    leftSection: {
      width: '360px',
      marginRight: 40,
    },
    thumbnailSec: {
      boxShadow:
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
      height: 200,
      width: 200,
    },
    main: {
      display: 'flex',
    },
  }
}

const DialogContentWrapper = withStyles((theme) => ({
  root: {
    padding: '40px !important',
  },
}))(DialogContent)

const DialogActionsWrapper = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 40,
  },
}))(DialogActions)

class ManageThumbnailDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isImageChanged: true,
      isSubmitting: false,
    }
    this.fileInput = React.createRef()
  }

  componentDidMount = () => {
    const { projectType, cycleName, thumbnail, projectName } = this.props
    this.fetchImage(thumbnail)
    this.setState({
      projectType,
      cycleName,
      projectName,
    })
  }

  fetchRenditionImage = (
    imgUrl,
    onSuccess = () => {},
    onError = () => {},
    sourceToken
  ) => {
    return axios
      .get(imgUrl, { responseType: 'arraybuffer', cancelToken: sourceToken })
      .then((response) => {
        onSuccess(response.data)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          onError(error)
        }
      })
  }

  fetchImage = (thumbnail) => {
    const { config, isUrlAppendedWithImg = true } = this.props
    if (!thumbnail) {
      this.setState({ renditionImage: null })
      return
    }
    const isggaUrlForThumbnail =
      thumbnail !== null &&
      (thumbnail.indexOf('inputs/') >= 0 ||
        thumbnail.indexOf('texturing/') >= 0)
    if (!thumbnail || thumbnail === '') return
    if (isUrlAppendedWithImg || isggaUrlForThumbnail) {
      const renditionsUrl = config.renditionsUrl + thumbnail
      this.fetchRenditionImage(
        renditionsUrl,
        (response) => {
          this.setState({
            renditionImage: URL.createObjectURL(response),
          })
        },
        () => {
          this.setState({
            renditionImage: null,
          })
        }
      )
    } else {
      this.setState({ renditionImage: thumbnail })
    }
  }

  render() {
    console.log(this.state)
    return <div>{this.renderingDialog()}</div>
  }

  handleProjectType = (e) => {
    this.setState({
      projectType: e.target.value,
    })
  }

  renderingButtons() {
    const { classes, handleClose } = this.props
    const { isSaveChange, isSubmitting } = this.state
    return (
      <div>
        <CustomButton
          label="Close"
          onClick={handleClose}
          variant="text"
          color={'tertiary'}
          sx={{
            marginRight: '20px',
          }}
        />
        <CustomButton
          label={
            isSubmitting ? <span>Saving......</span> : <span>Save changes</span>
          }
          onClick={this.updateProjectInfo}
          variant="contained"
          disabled={
            isSubmitting || (this.state.isImageChanged && this.compare())
          }
        />
      </div>
    )
  }

  updateProjectInfo = () => {
    const {
      projectName = '',
      projectType = 'CYCLIC',
      cycleName = '',
      isImageChanged,
    } = this.state
    const payLoad = {
      project_id: this.props.projectId,
      project_name: projectName,
      project_type: projectType,
      cycle_name: cycleName,
    }
    this.setState({
      isSubmitting: true,
    })

    if (!isImageChanged) {
      const { projectId } = this.props
      const { file } = this.state
      uploadProjectThumbnail(
        {
          project_id: projectId,
          file: file,
        },
        (response) => {
          this.props.showNotification(
            true,
            'Success! New project details saved.',
            'success'
          )
          this.setState({
            isSubmitting: true,
          })
          this.props.handleClose()

          // this.setRenditionThumbnail(response.project_thumbnail.image_path)
        },
        (error) => {
          this.props.showNotification(
            true,
            'Failed',
            'error',
            'There was an error updating the details. Try again! If continues to fail, reach out to StyleHub support on Slack.'
          )
        }
      )
    }
    if (!this.compare()) {
      updateProject(
        payLoad,
        (response) => {
          this.props.showNotification(
            true,
            'Success! New project details saved.',
            'success'
          )
          const {
            project_name = '',
            project_type = 'CYCLIC',
            cycle_name = '',
          } = response
          this.setState({
            projectName: project_name,
            projectType: project_type,
            cycleName: cycle_name,
            dispProjectName: project_name,
            isProjectExist: false,
            isProjectCycleExist: false,
            isSubmitting: true,
          })
          this.props.handleClose()
        },
        (error) => {
          const { errorCode = '', errorMessages = [] } =
            error?.response?.data || {}
          let isProjectExist = false
          let isProjectCycleExist = false
          if (errorCode && errorCode === '409') {
            isProjectExist = errorMessages[0] === ERROR_MSG_FOR_PROJECT_EXIST
            isProjectCycleExist =
              errorMessages[0] === ERROR_MSG_FOR_PROJECT_CYCLE_EXIST
          }
          if (!isProjectExist && !isProjectCycleExist) {
            this.props.showNotification(
              true,
              'Failed',
              'error',
              'There was an error updating the details. Try again! /n If continues to fail, reach out to StyleHub support on Slack.'
            )
          }
          this.setState({
            isProjectExist: isProjectExist,
            isProjectCycleExist: isProjectCycleExist,
          })
        }
      )
    }
  }

  handleUpload = async (e) => {
    const fileType = ['image/png', 'image/jpeg', 'image/jpg']
    var file = e.target.files[0]
    let fileuploaded = e.target.files[0]
    if (!fileuploaded) return
    if (!fileType.includes(fileuploaded.type)) {
      this.props.showNotification(
        true,
        `Invalid file type!. Upload only JPG or PNG`,
        'error'
      )
      return
    }
    if (fileuploaded.size > 200000) {
      this.props.showNotification(
        true,
        `Invalid file size!. Maximum 200kb allowed.`,
        'error'
      )
      return
    }
    if (file) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.setState({
          renditionImage: reader.result,
          file,
          isImageChanged: false,
        })
      }
    }
  }

  compare = () => {
    if (this.state.projectName !== this.props.projectName) return false
    if (this.state.projectType !== this.props.projectType) return false
    if (this.state.cycleName !== this.props.cycleName) return false
    return true
  }

  renderingDialog() {
    const { classes, handleClose, openDialog, thumbnail } = this.props
    const {
      projectType,
      cycleName,
      renditionImage,
      projectName,
      isProjectExist,
      isProjectCycleExist,
    } = this.state
    let projectList = [
      { name: 'Cyclic', value: 'CYCLIC' },
      { name: 'Non Cyclic', value: 'Non Cyclic' },
      { name: 'Others', value: 'OTHERS' },
    ]

    return (
      <Grid>
        <Dialog
          onClose={() => handleClose(true)}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          classes={{
            paper: classes.dialogPaperDefault,
          }}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogContentWrapper>
            <div className={classes.expName}>
              <div>
                <PageTitleBar
                  title="Edit project details"
                  subTitle={'Manage name, project type and others'}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CustomIconButton
                  aria-label="close"
                  label="close"
                  onClick={() => handleClose(true)}
                >
                  <CloseIcon />
                </CustomIconButton>
              </div>
            </div>
            <div className={classes.main}>
              <div className={classes.leftSection}>
                <div style={{ marginBottom: '0px' }}>
                  <FormTextField
                    value={projectName}
                    label="Project name"
                    onChange={(e) =>
                      this.setState({ projectName: e.target.value })
                    }
                    variant="filled"
                    helperText={
                      isProjectExist ? ERROR_MSG_FOR_PROJECT_EXIST : ''
                    }
                    error={isProjectExist}
                    color={'tertiary'}
                  />
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <Select
                    color={'tertiary'}
                    inputLabel="Project Type"
                    variant="filled"
                    value={projectType}
                    onChange={this.handleProjectType}
                    options={projectList}
                  />
                </div>
                {projectType === 'CYCLIC' && (
                  <div style={{ marginBottom: '10px' }}>
                    <FormTextField
                      value={cycleName}
                      label="TCIN Cycle"
                      onChange={(e) =>
                        this.setState({ cycleName: e.target.value })
                      }
                      variant="filled"
                      helperText={
                        isProjectCycleExist
                          ? ERROR_MSG_FOR_PROJECT_CYCLE_EXIST
                          : ''
                      }
                      error={isProjectCycleExist}
                      color={'tertiary'}
                    />
                  </div>
                )}

                <CustomButton
                  label={
                    thumbnail || renditionImage
                      ? 'Change thumbnail image'
                      : 'Upload thumbnail image'
                  }
                  color="tertiary"
                  onClick={() => this.fileInput.current.click()}
                  startIcon={<CloudUploadIcon />}
                  variant="outlined"
                  sx={{ width: '100%' }}
                />
                <input
                  accept=".jpg,.jpeg,.png,.tiff,.tif,.bmp"
                  ref={this.fileInput}
                  type="file"
                  onChange={this.handleUpload}
                  style={{ display: 'none' }}
                />
              </div>
              <div className={classes.rightSection}>
                <Typography
                  variant="subtitle2"
                  sx={{ opacity: '0.6', marginBottom: '5px' }}
                  label="Thumbnail preview"
                />
                <div className={classes.thumbnailSec}>
                  {(thumbnail || renditionImage) && (
                    <img height="220" width="220" src={renditionImage} />
                  )}
                </div>
              </div>
            </div>
          </DialogContentWrapper>
          <DialogActionsWrapper
            classes={{
              root: classes.dialogAct,
            }}
          >
            {this.renderingButtons()}
          </DialogActionsWrapper>
        </Dialog>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const { auth } = state
  return {
    auth,
  }
}

export default connect(mapStateToProps, {
  updateProject,
  uploadProjectThumbnail,
  showNotification,
})(withStyles(styles)(ManageThumbnailDialog))
