import React from 'react'

import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

import { AssetCard } from 'cgi-ui-components'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'
import { removeMultipleAssets } from '../designSpace'

import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import config from '../../config/config'
import { REPLACE_ASSET } from '../../constants/scene'
import { showNotification } from '../../store/notification/actionCreator'

import { setActiveOperation } from '../../store/operations/actionCreator'
import {
  removeTcinsFromBoard,
  addTcinToBoard,
} from '../../store/board/actionCreator'

import { handleCloseExplorer } from '../../store/explorer/actionCreator'
import {
  toggleReportIssueModal,
  selectCurrentTcin,
} from '../../store/cart/ActionCreator'
import ACTIONS from '../../constants/actions'
import ImageSearchRounded from '@mui/icons-material/ImageSearchRounded'
import { mapInfoForCard } from '../../helpers/utils'

class MaxAssetCard extends React.Component {
  /**
   * Function called when swap icon is clicked
   * @param {object} assignObj Data object of item whose swap icon is clicked
   */

  state = {
    deleteRef: null,
    assetToDelete: {},
    loading: false,
  }
  addToBoard = (e, assetObj) => {
    const { loading } = this.state
    if (loading) {
      return false
    }
    const {
      data: {
        asset_type: assetType,
        tcin,
        sub_asset_type: subAssetType,
        assets = [],
      },
      experience: { experience_id: sceneId },
      addTcinToBoard,
      isGroup,
    } = this.props

    this.setState({ loading: true })
    let payload = {
      experience_id: sceneId,
      assets: [
        {
          asset_type: assetType,
          value: tcin,
          sub_asset_type: subAssetType,
        },
      ],
    }
    if (isGroup && assets.length) {
      payload.assets = assets.map((asset) => ({
        asset_type: asset.assetType,
        value: asset.assetId,
        sub_asset_type: asset.subAssetType,
      }))
    }

    addTcinToBoard(
      payload,
      () => {
        this.setState({ loading: false })
      },
      () => {
        this.setState({ loading: false })
      }
    )
  }

  removeFromBoard = (e, assetObj) => {
    const { tcin, assetId, gga_status: ggaStatus } = assetObj
    if (ggaStatus && ggaStatus !== '' && ggaStatus !== null) {
      this.setState({
        deleteRef: e.currentTarget,
        assetToDelete: { tcin, assetId },
      })
    } else {
      this.deleteBoardAsset(tcin, assetId)
    }
  }

  deleteBoardAsset = (tcin) => {
    const { loading } = this.state
    if (loading) {
      return false
    }
    const { removeTcinsFromBoard, boardId, showNotification } = this.props
    const deleteData = {
      board_id: boardId,
      assets: [tcin],
    }
    this.setState({ loading: true })

    removeTcinsFromBoard(deleteData)
      .then(() => {
        removeMultipleAssets([tcin])
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
        showNotification(
          true,
          "Sorry! we couldn't remove tcin from board. Try again!",
          'error'
        )
      })
  }

  swapAsset = (assetObj) => {
    const {
      handleCartActions,
      cart: { scene_data: { assets = [] } = {} },
      setActiveOperation,
      handleCloseExplorer,
    } = this.props
    const selectedAsset = assets.find((item) => item.selected)
    //Replace asset
    handleCartActions(REPLACE_ASSET, () => {}, {}, [
      selectedAsset,
      {
        ...assetObj,
        assetType: assetObj.asset_type,
        subAssetType: assetObj.subAssetType,
      },
    ])
    // Set replace mode to false
    setActiveOperation(REPLACE_ASSET, { status: false })
    // Close the explorer
    this.addToBoard()
    handleCloseExplorer()
  }

  handleReport = () => {
    const {
      toggleReportIssueModal = () => {},
      selectCurrentTcin = () => {},
      data: { tcin },
    } = this.props
    toggleReportIssueModal(true)
    selectCurrentTcin(tcin)
  }

  render() {
    const {
      classes,
      selectable,
      data: item,
      selected = false,
      onSelect = () => {},
      activeOperations,
      boardAssetObjects,
      maxWidth = 250,
      minWidth = 250,
      showSubAsset = false,
      showSimilarAsset = false,
      isGroup = false,
      highLight = false,
      editTemplateMode,
    } = this.props
    const { loading } = this.state
    const { gga_status: inGGAProcess = '', asset_type: assetType = '' } = item
    let isAssetDeletedinGGA =
      inGGAProcess === 'DELETED' ||
      inGGAProcess === 'ON_HOLD' ||
      inGGAProcess === 'NA'
        ? true
        : false

    const infoDetails = {
      icon: InfoOutlinedIcon,
      title: 'info',
      data: mapInfoForCard(item),
    }
    let multiInfoData = []
    let thumbnailList = []
    if (isGroup) {
      item.assets.forEach((data) => {
        multiInfoData.push(mapInfoForCard(data))
        thumbnailList.push(data?.thumbnail_url)
      })
      infoDetails.multiInfoData = multiInfoData
      infoDetails.thumbnailList = thumbnailList
    }

    const footerItems = []

    if (activeOperations[ACTIONS.REPLACE_ASSET]) {
      footerItems.push({
        icon: SwapHorizOutlinedIcon,
        onClick: () => {
          if (item.is_hp_available && item.is_lp_available) {
            this.swapAsset(item)
          }
        },
        title:
          item.is_hp_available && item.is_lp_available
            ? 'Replace this asset'
            : 'Asset not available',
      })
    } else if (!editTemplateMode) {
      if (boardAssetObjects[item.tcin || item.value || item.pid]) {
        footerItems.push({
          icon: loading ? CircularProgress : RemoveCircleOutlineIcon,
          onClick: (e) => this.removeFromBoard(e, item),
          title: 'Remove from board',
        })
      } else if (!(!item.lodProfile && assetType === 'PID')) {
        footerItems.push({
          icon: loading ? CircularProgress : AddCircleOutlineRoundedIcon,
          onClick: () => this.addToBoard(item),
          title: 'Add to board',
        })
      }
    }

    if (item.has_styled_assets && showSubAsset) {
      footerItems.push({
        icon: StyleOutlinedIcon,
        onClick: () => {
          this.props.openSubAssets(item, 'STYLED')
        },
        title: 'Show styled versions',
      })
    }
    if (item.has_unbundled_assets && showSubAsset) {
      footerItems.push({
        icon: LayersOutlinedIcon,
        onClick: () => {
          this.props.openSubAssets(item, 'UNBUNDLED')
        },

        title: 'Show unbundled versions',
      })
    }
    if (showSimilarAsset && assetType !== 'PID') {
      footerItems.push({
        icon: ImageSearchRounded,
        onClick: () => {
          this.props.openSimilarAssets(item)
        },
        title: 'Search similar assets',
      })
    }
    return (
      <>
        <AssetCard
          thumbnail={item.thumbnail_url}
          config={config}
          isUrlAppendedWithImg={assetType !== 'PID'}
          assetData={item}
          name={
            item.short_description || item.tcin || item.name || item.asset_name
          }
          subTitle={item.product_desc ? item.product_desc : '&nbsp;'}
          maxWidth={maxWidth}
          minWidth={minWidth}
          infoDetails={infoDetails}
          noThumbnail={noThumbnail}
          checkboxColor={'tertiary'}
          footerItems={footerItems}
          isPropAsset={item.asset_type == 'PROP'}
          errorDetails={{
            high: item.is_hp_available || isGroup || false,
            low: item.is_lp_available || isGroup || false,
            lodProfile: item.lod_available,
          }}
          selectable={
            !boardAssetObjects[item.tcin || item.value || item.pid] &&
            !editTemplateMode &&
            selectable
          }
          selected={selected}
          isGroup={isGroup}
          onSelect={(e) => {
            onSelect(e, e.target.checked, item)
          }}
          highLight={highLight}
          onReport={assetType !== 'PID' ? this.handleReport : false}
          isRtaAsset={item.is_pure_rta_asset}
          isHighPolyCountAsset={item.is_high_triangle_count}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  explorer: { similarAssets, totalSimilarAssets },
  activeOperations,
  Board: {
    assetObjects: boardAssetObjects,
    board_id: boardId,
    assets: boardAssets,
  },
  cart,
  experiences: { experience },
  templates: { editTemplateMode },
}) => {
  return {
    similarAssets,
    totalSimilarAssets,
    activeOperations,
    boardAssetObjects,
    boardId,
    boardAssets,
    cart,
    experience,
    editTemplateMode,
  }
}
export default connect(mapStateToProps, {
  setActiveOperation,
  handleCloseExplorer,
  showNotification,
  removeTcinsFromBoard,
  addTcinToBoard,
  toggleReportIssueModal,
  selectCurrentTcin,
})(MaxAssetCard)
