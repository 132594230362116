import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Dialog } from '@mui/material'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogActions from '@mui/material/DialogActions'
import ButtonIcon from '@mui/material/IconButton'
import EscButton from '../../images/ic_esc_button.svg?url'

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: '32px',
    top: '32px',
    color: theme.palette.grey[500],
  },
  closeIcon: {
    width: '1.4em',
    height: '1.0em',
    marginTop: '0.3em',
  },
})

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions)

class FullScreenDialog extends React.Component {
  handleClose = () => {
    const { onCloseHandler = () => {} } = this.props
    onCloseHandler()
  }

  render() {
    const {
      children,
      openForm = false,
      modalHeading = '',
      modalActions = '',
      classes,
      onCloseHandler,
      fullScreen = true,
    } = this.props
    return (
      <Dialog
        fullScreen={fullScreen}
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={openForm}
      >
        <MuiDialogTitle disableTypography>
          {modalHeading}
          {onCloseHandler ? (
            <ButtonIcon
              aria-label="Close"
              className={classes.closeButton}
              onClick={this.handleClose}
            >
              <img
                src={EscButton}
                className={classes.closeIcon}
                alt="close-icon"
              />
            </ButtonIcon>
          ) : null}
        </MuiDialogTitle>
        <MuiDialogContent>{children}</MuiDialogContent>
        {modalActions && <DialogActions>{modalActions}</DialogActions>}
      </Dialog>
    )
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  openForm: PropTypes.bool,
  modalHeading: PropTypes.string,
  modalActions: PropTypes.string,
  onCloseHandler: PropTypes.func,
  children: PropTypes.node,
}
export default withStyles(styles)(FullScreenDialog)
