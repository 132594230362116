export const UPDATE_COLLECTION_ITEM = 'UPDATE_COLLECTION_ITEM'
export const UPDATE_COLLECTION_NAME = 'UPDATE_COLLECTION_NAME'
export const GET_COLLECTION_LIST = 'GET_COLLECTION_LIST'
export const CREATE_COLLECTION = 'CREATE_COLLECTION'
export const ADD_LIST_PAGE_META = 'ADD_LIST_PAGE_META_COLLECTION'
export const NO_COLLECTION_FOUND = 'NO_COLLECTION_FOUND'
export const API_CALL_FAIL_COLLECTION_LIST = 'API_CALL_FAIL_COLLECTION_LIST'
export const CLEAR_COLLECTION_LIST = 'CLEAR_COLLECTION_LIST'
export const SAVE_MISCELLANEOUS_TO_COLLECTION_LIST =
  'SAVE_MISCELLANEOUS_TO_COLLECTION_LIST'
export const API_CALL_INITIATED = 'API_CALL_INITIATED_FAV'
export const SAVE_COLLECTION_LIST = 'SAVE_COLLECTION_LIST'
export const CLEAR_FAVOURITE_ASSET_LIST = 'CLEAR_FAVOURITE_ASSET_LIST'
export const DELETE_COLLECTION = 'DELETE_COLLECTION'
export const COLLECTION_TYPE = 'COLLECTION_TYPE'
