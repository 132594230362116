import { SHOW_NOTIFICATION } from './actionType'

export function showNotification(isShown, message, variant, description) {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      isShown,
      message,
      variant,
      description,
    },
  }
}
