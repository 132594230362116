export const SCENE_NEW = 'SCENE_NEW'
export const SCENE_OPEN = 'SCENE_OPEN'
export const SCENE_IMPORT = 'SCENE_IMPORT'
export const SCENE_SAVE = 'SCENE_SAVE'
export const SCENE_SAVE_AS = 'SCENE_SAVE_AS'
export const SCENE_ESCAPE = 'SCENE_ESCAPE'
export const SCENE_MOVE = 'SCENE_MOVE'
export const SCENE_ROTATE = 'SCENE_ROTATE'
export const SCENE_DELETE = 'SCENE_DELETE'
export const SCENE_DUPLICATE = 'SCENE_DUPLICATE'
export const SCENE_HIDE = 'SCENE_HIDE'
export const SCENE_SHOW = 'SCENE_SHOW'
export const SCENE_SHOW_ALL = 'SCENE_SHOW_ALL'
export const SCENE_GROUP = 'SCENE_GROUP'
export const SCENE_UNGROUP = 'SCENE_UNGROUP'
export const SCENE_SELECT = 'SCENE_SELECT'
export const SCENE_UPDATE_GROUP = 'SCENE_UPDATE_GROUP'
export const SCENE_GROUP_HIDE_SHOW = 'SCENE_GROUP_HIDE_SHOW'
export const SCENE_VIEW_3D = 'SCENE_VIEW_3D'
export const SCENE_VIEW_2D = 'SCENE_VIEW_2D'
export const RENDER_RIGHT_DRAWER = 0
export const RENDER_HEADER_HEIGHT = 0
export const SCENE_UNDO = 'SCENE_UNDO'
export const SCENE_REDO = 'SCENE_REDO'
export const REPLACE_ASSET = 'REPLACE_ASSET'
export const SCALE_ASSET = 'SCALE_ASSET'
export const LOCK_ASSET = 'LOCK_ASSET'
export const FOCUS_ASSET = 'FOCUS_ASSET'
export const PAN_LEFT = 'PAN_LEFT'
export const PAN_RIGHT = 'PAN_RIGHT'
export const PAN_UP = 'PAN_UP'
export const PAN_DOWN = 'PAN_DOWN'
export const TILT_LEFT = 'TILT_LEFT'
export const TILT_RIGHT = 'TILT_RIGHT'
export const TILT_UP = 'TILT_UP'
export const TILT_DOWN = 'TILT_DOWN'
export const ZOOM_IN = 'ZOOM_IN'
export const ZOOM_OUT = 'ZOOM_OUT'
export const GROUP_RENAME = 'GROUP_RENAME'
export const EDIT_MATERIAL = 'EDIT_MATERIAL'
// export const ZF_STEP = Math.pow(0.95, 1)
export const ZF_STEP = 0.01
export const FLIP_HORIZONTAL = 'FLIP_HORIZONTAL'
export const SCENE_GROUP_ADD_ASSETS = 'SCENE_GROUP_ADD_ASSETS'

export const ASSET_MODE_ARCH = 'ARCHITECTURE'
export const ASSET_MODE_INFRA = 'INFRASTRUCTURE'
export const ASSET_MODE_GALLERY = 'PID'
export const ASSET_MODE_PROP = 'PROP'
export const ASSET_MODE_TCIN = 'TCIN'
export const TRACK_SCENE_EVENT = 'TRACK_SCENE_EVENT'
