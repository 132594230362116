import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

import {
  Select,
  Dialog,
  AutoCompleteComponent,
  CustomButton,
  Typography,
  LinkComponent,
  PageTitleBar,
  CustomIconButton,
} from 'cgi-ui-components'
import { DialogContent, DialogActions, Popover } from '@mui/material'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep, uniqBy } from 'lodash'
import CustomAvatar from '../../components/CustomAvatar/CustomAvatar'
import { experienceSaveChanges } from '../../store/experience/ActionCreator'
import { shareCollection } from '../../store/favorites/ActionCreator'
import api from '../../config/config'
import { showNotification } from '../../store/notification/actionCreator'
import CloseIcon from '@mui/icons-material/Close'

const styles = (theme) => {
  return {
    root: {
      margin: 0,
      padding: 16,
    },
    expName: {
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: 'grey',
    },
    formControl: {
      margin: 16,
    },
    formControlDialog: {
      marginTop: '16px',
      marginLeft: '16px',
      width: '315px',
    },
    userAccess: {
      marginLeft: '45px',
      paddingLeft: '30px !important',
      borderLeft: '1px solid #d6d6d6',
    },
    userAccessInner: {
      display: 'inline-block',
      height: '296px',
      overflowY: 'auto',
      width: '100%',
      paddingRight: '8px',
    },
    extraPaddingForDialog: {
      paddingRight: '16px',
    },
    userFirst: {
      marginBottom: '20px',
    },
    userOther: {
      marginBottom: '24px',
    },
    rootRadio: {
      display: 'flex',
    },
    radioLabel: {
      marginBottom: 0,
      width: 270,
    },
    autoCompleteDiv: {
      width: '270px',
      marginTop: '35px',
    },
    popoverPaper: {
      padding: '40px',
      height: '442px',
      width: '640px',
      // paddingBottom:60
    },
    popoverPaperDefault: {
      height: '270px',
      width: '420px',
      padding: '35px',
    },
    popoverPaperType: {
      height: 170,
    },
    customClasses: {
      height: 60,
      width: 60,
      backgroundColor: theme.palette.primary.dark,
      color: '#ffffff',
      float: 'left',
      fontSize: 15,
    },
    dropDownUpper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '-10px',
      marginRight: '15px',
    },
    fullName: {
      color: '#333333',
      fontFamily: 'Roboto',
      lineHeight: '20px',
      width: '165px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    sideContainer: {
      marginTop: '10px',
      marginLeft: '-12px',
    },
    emailAdd: {
      fontSize: '12px',
      color: '#666666',
      fontFamily: 'Roboto',
      lineHeight: '20px',
      width: '190px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    errorMsg: {
      color: '#ffffff',
      marginTop: '8px',
      background: theme.palette.primary.dark,
      padding: '4px 14px',
      marginRight: '8px',
      borderRadius: '12px',
      fontSize: '12px',
    },
    successMsg: {
      color: '#ffffff',
      marginTop: '8px',
      background: 'green',
      padding: '4px 14px',
      marginRight: '8px',
      borderRadius: '12px',
      fontSize: '12px',
    },
    removeUser: {
      height: '21px',
      width: '80px',
      color: '#ed6c02',
      // color: 'red',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      marginTop: '19px',
      cursor: 'pointer',
      marginLeft: '10px',
    },
    labelAddManage: {
      color: '#666666',
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '16px',
    },
    manage: {
      color: '#666666',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '8px',
    },
    dialogAct: {
      margin: '0 8px 12px 0',
    },
    bottomDiv: {
      marginRight: '12px',
      position: 'absolute',
      bottom: '35px',
      right: '35px',
    },
    userDetails: {
      marginLeft: '-12px',
    },

    // css for Dialog
    dialogPaper: {
      width: '700px',
      position: 'absolute',
      top: '10%',
    },
    dialogPaperDefault: {
      height: '265px',
      width: '315px',
      position: 'absolute',
      top: '10%',
    },

    // css for Button
    closeButtonStyle: {
      color: '#333333',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      marginRight: '20px',
    },

    saveButtonStyle: {
      color: '#ffffff',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'center',
      height: '43px',
      borderRadius: '3px',
      backgroundColor: '#366CD9',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#366CD9',
      },
    },

    saveButtonDisabled: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'center',
      height: '43px',
      borderRadius: '3px',
      backgroundColor: '#D6D6D6',
    },
  }
}

const DialogContentWrapper = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(DialogContent)

const DialogActionsWrapper = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 8,
  },
}))(DialogActions)

const TYPES = {
  EXPERIENCE: 'experience',
  FAVORITES: 'favorites',
}

class ShareExperienceDialog extends React.Component {
  state = {
    isSpecific: false,
    isToastShow: false,
    isSuccess: false,
    isSaveChange: false,
    openPopover: false,
    choosedUser: '',
    experienceType: 'Private',
    dropDownArray: [
      { name: 'Edit', value: 'Edit' },
      { name: 'View', value: 'View' },
    ],
    usersArrayForExperienceItem: [],
    usersArrayForComparing: [], // use for comparing from "usersArrayForExperienceItem" to able or disable the "Save Changes" button
    anchorEl: null,
    fetchedAsyncData: [],
    isSubmitting: false,
  }

  componentDidMount = () => {
    const {
      experienceItem = {},
      collectionItem = {},
      openDialog = true,
      type = TYPES.EXPERIENCE, // Would be default type for backward compatibility
    } = this.props
    var tempArr = []
    let boo = false
    if (type === TYPES.EXPERIENCE) {
      if (experienceItem && experienceItem.members !== null) {
        experienceItem.members.forEach(function (member) {
          var item = {
            email_address: member.user ? member.user.email_address : 'NA',
            login_id: member.user ? member.user.login_id : 'NA',
            display_name: member.user ? member.user.display_name : 'NA',
            first_name: member.user ? member.user.first_name : 'NA',
            last_name: member.user ? member.user.last_name : 'NA',
            readOrWrite: member.access_level,
            // indexEditOrView: member.access_level === 'Write' ? 0 : 1,
          }
          tempArr.push(item)
        })
      }
      boo = experienceItem.experience_visibility === 'Shared' ? true : false
    } else if (type === TYPES.FAVORITES) {
      if (
        collectionItem &&
        collectionItem.shared_users &&
        collectionItem.shared_users.length
      ) {
        collectionItem.shared_users.forEach(function (shUsers) {
          var item = {
            email_address: shUsers.user ? shUsers.user.email_address : 'NA',
            login_id: shUsers.user ? shUsers.user.login_id : 'NA',
            display_name: shUsers.user ? shUsers.user.display_name : 'NA',
            first_name: shUsers.user ? shUsers.user.first_name : 'NA',
            last_name: shUsers.user ? shUsers.user.last_name : 'NA',
            readOrWrite: shUsers.access_level,
            // indexEditOrView: shUsers.access_level === 'Write' ? 0 : 1,
          }
          tempArr.push(item)
        })
      }
      boo = !(collectionItem.favourite_visibility === 'Private')
    }

    this.setState({
      openPopover: openDialog,
      isSpecific: boo,
      usersArrayForExperienceItem: tempArr,
      usersArrayForComparing: cloneDeep(tempArr),
      experienceType:
        type === TYPES.FAVORITES
          ? boo
            ? 'Shared'
            : 'Private'
          : experienceItem.experience_visibility,
    })
  }

  handleChangeRadio = (event) => {
    const {
      experienceItem = {},
      type = TYPES.EXPERIENCE,
      collectionItem = {},
    } = this.props
    const { experience_visibility: expVisibility } = experienceItem
    let isVisibilityChanged = false
    if (type === TYPES.FAVORITES) {
      isVisibilityChanged = !(
        collectionItem.favourite_visibility === event.target.value
      )
    } else {
      isVisibilityChanged = expVisibility === event.target.value ? false : true
    }
    const boo = event.target.value === 'Shared' ? true : false
    this.setState({
      isSpecific: boo,
      isSaveChange: isVisibilityChanged,
      experienceType: event.target.value,
      openPopover: false,
    })
    setTimeout(
      function () {
        this.setState({ openPopover: true })
      }.bind(this),
      0
    )
  }

  updateAsynData = (fetchedData) => {
    this.setState({ fetchedAsyncData: fetchedData })
  }

  handleAddMembers = (members = []) => {
    const { email_address } = this.props.auth
    let values = members.filter((item) => item.loginId !== undefined)
    let value = values[values.length - 1]
    if (value === undefined) return
    const { usersArrayForExperienceItem, usersArrayForComparing } = this.state
    const {
      experienceItem = {},
      collectionItem = {},
      type = TYPES.EXPERIENCE,
    } = this.props

    var boo = false
    var name = ''
    if (
      type === TYPES.EXPERIENCE &&
      experienceItem.initiator_details &&
      experienceItem.initiator_details.login_id === value.loginId
    ) {
      boo = true
      name =
        experienceItem.initiator_details.first_name +
        ' ' +
        experienceItem.initiator_details.last_name
    }
    if (
      type === TYPES.FAVORITES &&
      collectionItem.owner &&
      collectionItem.owner.login_id === value.loginId
    ) {
      boo = true
      name = `${collectionItem.owner.first_name} 
        ${collectionItem.owner.last_name}`
    }
    if (!boo && usersArrayForExperienceItem.length > 0 && value.loginId) {
      usersArrayForExperienceItem.forEach(function (member, index) {
        if (member.login_id === value.loginId) {
          boo = true
          name = member.first_name + ' ' + member.last_name
        }
      })
    }
    if (boo === true) {
      this.setState({ isToastShow: boo, choosedUser: name })
      setTimeout(
        function () {
          this.setState({ isToastShow: false, choosedUser: '' })
        }.bind(this),
        2500
      )
    } else {
      var tempArr = usersArrayForExperienceItem
      var item = {
        email_address: value.value,
        login_id: value.loginId,
        display_name: value.displayName,
        first_name: value.displayName.split('.')[0],
        last_name: value.displayName.split('.')[1],
        readOrWrite: 'Read',
        // indexEditOrView: 1,
      }
      tempArr.push(item)
      this.setState({
        usersArrayForExperienceItem: tempArr,
      })
      this.setState({ isToastShow: true, isSuccess: true })
      setTimeout(
        function () {
          this.setState({ isToastShow: false, isSuccess: false })
        }.bind(this),
        2500
      )
    }
    const isUsersArrayChanged = this.compareHelperFunction(
      usersArrayForExperienceItem,
      usersArrayForComparing
    )
    this.setState({ isSaveChange: isUsersArrayChanged })
  }

  handleProjectType = (e, index) => {
    let value = e.target.value
    const { usersArrayForExperienceItem, usersArrayForComparing } = this.state
    let arrCopy = cloneDeep(usersArrayForExperienceItem)
    arrCopy[index].readOrWrite = value === 'Edit' ? 'Write' : 'Read'

    const isUsersArrayChanged = this.compareHelperFunction(
      arrCopy,
      usersArrayForComparing
    )
    this.setState({
      usersArrayForExperienceItem: arrCopy,
      isSaveChange: isUsersArrayChanged,
    })
  }

  handleRemoveUser = (index) => {
    const { usersArrayForExperienceItem, usersArrayForComparing } = this.state
    var tempArr = usersArrayForExperienceItem
    tempArr.splice(index, 1)
    const isUsersArrayChanged = this.compareHelperFunction(
      tempArr,
      usersArrayForComparing
    )
    this.setState({
      usersArrayForExperienceItem: tempArr,
      isSaveChange: isUsersArrayChanged,
    })
  }

  loadMoreUsers = () => {
    const { classes, customClassesAvatar = '' } = this.props
    const { dropDownArray, usersArrayForExperienceItem } = this.state
    let uniqueArrayforExperience = uniqBy(
      usersArrayForExperienceItem,
      'email_address'
    )
    const width = 68
    return (
      <Grid>
        {uniqueArrayforExperience.map((user, index) => (
          <Grid
            container
            // spacing={16}
            className={classes.userOther}
            key={index}
          >
            <Grid item xs={3}>
              <CustomAvatar
                customClasses={classNames(
                  classes.customClasses,
                  customClassesAvatar
                )}
                data={{
                  display_name: user.display_name,
                  login_id: user.login_id,
                }}
              />
            </Grid>
            <Grid item xs={9} className={classes.userDetails}>
              <div className={classes.fullName}>
                {user.first_name + ' ' + user.last_name}
              </div>
              <div className={classes.emailAdd}>{user.email_address}</div>
              <div className={classes.dropDownUpper}>
                <Select
                  size="small"
                  sx={{ width: 'unset' }}
                  onChange={(e) => this.handleProjectType(e, index)}
                  options={dropDownArray}
                  variant={'standard'}
                  color={'tertiary'}
                  value={user.readOrWrite === 'Write' ? 'Edit' : 'View'}
                />
                <div
                  className={classes.removeUser}
                  onClick={() => {
                    this.handleRemoveUser(index)
                  }}
                >
                  REMOVE
                </div>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }

  renderFavoriteOwnerAvatar = () => {
    const {
      classes,
      customClassesAvatar = '',
      collectionItem = {},
    } = this.props
    return (
      <>
        <Grid item xs={3}>
          <CustomAvatar
            customClasses={classNames(
              classes.customClasses,
              customClassesAvatar
            )}
            data={{
              display_name: collectionItem.owner
                ? collectionItem.owner.display_name
                : 'N A',
              login_id: collectionItem.owner
                ? collectionItem.owner.login_id
                : 'NA',
            }}
          />
        </Grid>
        <Grid item xs={9} className={classes.sideContainer}>
          <div className={classes.fullName}>
            {collectionItem.owner
              ? collectionItem.owner.first_name +
                ' ' +
                collectionItem.owner.last_name
              : 'N A'}
          </div>
          <div className={classes.emailAdd}>
            {collectionItem.owner ? collectionItem.owner.email_address : 'NA'}
          </div>
        </Grid>
      </>
    )
  }

  loadRadioGroupUI = () => {
    const {
      classes,
      customClassesAvatar = '',
      experienceItem = {},
      isDialog = true,
      type = TYPES.EXPERIENCE,
      lanId,
      collectionItem = {},
    } = this.props
    const { fullName = '', email, firstName } = this.props.auth
    const { experienceType, isSpecific, isToastShow, isSuccess, choosedUser } =
      this.state

    let defaultMember = {
      displayName: fullName.replace(' ', '.'),
      label: `${firstName} - ${email}`,
      loginId: lanId,
      value: email,
    }

    return (
      <Grid>
        <Grid className={classes.rootRadio}>
          <FormControl
            component="fieldset"
            className={
              isDialog ? classes.formControlDialog : classes.formControl
            }
          >
            <RadioGroup
              aria-label="ExperienceType"
              name="ExperienceType"
              value={experienceType}
              onChange={this.handleChangeRadio}
            >
              <FormControlLabel
                value="Private"
                control={<Radio color={'tertiary'} />}
                label="Private"
                className={classes.radioLabel}
                disabled={
                  type === TYPES.FAVORITES &&
                  collectionItem.owner &&
                  lanId !== collectionItem.owner.login_id
                }
              />
              {type !== TYPES.FAVORITES && (
                <FormControlLabel
                  value="Public"
                  control={<Radio color={'tertiary'} />}
                  label="Everyone can view"
                  className={classes.radioLabel}
                />
              )}
              <FormControlLabel
                value="Shared"
                control={<Radio color={'tertiary'} />}
                label="Share with specific people only"
                className={classes.radioLabel}
              />
            </RadioGroup>
            {isSpecific ? (
              <Grid className={classes.autoCompleteDiv}>
                <div className={classes.labelAddManage}>Add Peer</div>
                <AutoCompleteComponent
                  showChips={false}
                  apiUrl={api.searchUser}
                  defaultMember={defaultMember}
                  handleAddMember={this.handleAddMembers}
                  values={[]}
                  label=""
                  searchParam="user"
                  size="small"
                  key="e8791ab338a5569cb12e1de7511ca44202380cd2"
                  color={'tertiary'}
                />
              </Grid>
            ) : (
              ''
            )}
          </FormControl>
          {isSpecific ? (
            <Grid className={classes.userAccess}>
              <Typography
                label={`Peers with access to this ${
                  type === TYPES.FAVORITES ? 'collection' : 'scene'
                }`}
                sx={{ marginTop: '10px', opacity: '0.6', marginBottom: '16px' }}
                variant="subtitle2"
              />
              <Grid
                className={
                  isDialog
                    ? classNames(
                        classes.userAccessInner,
                        classes.extraPaddingForDialog
                      )
                    : classes.userAccessInner
                }
              >
                <Grid container className={classes.userFirst}>
                  {type === TYPES.EXPERIENCE && (
                    <Grid item xs={3}>
                      <CustomAvatar
                        customClasses={classNames(
                          classes.customClasses,
                          customClassesAvatar
                        )}
                        data={{
                          display_name: experienceItem.initiator_details
                            ? experienceItem.initiator_details.display_name
                            : 'N A',
                          login_id: experienceItem.initiator_details
                            ? experienceItem.initiator_details.login_id
                            : 'NA',
                        }}
                      />
                    </Grid>
                  )}
                  {type === TYPES.EXPERIENCE && (
                    <Grid item xs={9} className={classes.sideContainer}>
                      <div className={classes.fullName}>
                        {experienceItem.initiator_details
                          ? experienceItem.initiator_details.first_name +
                            ' ' +
                            experienceItem.initiator_details.last_name
                          : 'N A'}
                      </div>
                      <div className={classes.emailAdd}>
                        {experienceItem.initiator_details
                          ? experienceItem.initiator_details.email_address
                          : 'NA'}
                      </div>
                    </Grid>
                  )}
                  {type === TYPES.FAVORITES && this.renderFavoriteOwnerAvatar()}
                </Grid>
                {this.loadMoreUsers()}
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    )
  }

  handleSaveChanges = () => {
    const { usersArrayForExperienceItem, experienceType } = this.state
    const {
      experienceItem = {},
      experienceSaveChanges = () => {},
      handleClose = () => {},
    } = this.props
    var tempArr = []
    if (experienceType === 'Shared' && usersArrayForExperienceItem.length > 0) {
      usersArrayForExperienceItem.forEach(function (member) {
        let item = {
          login_id: member.login_id,
          permission: member.readOrWrite,
        }
        tempArr.push(item)
      })
    }
    // NOTE : if tempArr is empty and experienceType is shared, type is considered as private
    var type =
      tempArr.length === 0 && experienceType !== 'Public'
        ? 'Private'
        : experienceType
    var requestBody = {
      experience_id: experienceItem.experience_id,
      visibility: type,
      shared_users: tempArr,
    }
    this.setState({ isSubmitting: true })
    experienceSaveChanges(
      requestBody,
      (response) => {
        if (experienceType === 'Shared') {
          this.props.showNotification(true, 'Success! Scene Shared', 'success')
          handleClose()
        } else {
          this.props.showNotification(
            true,
            'Success!',
            'success',
            experienceType === 'Public'
              ? 'Scene is now public. Anyone with a link will be able to access the scene and view it.'
              : 'Scene is now Private.'
          )
          handleClose()
        }
      },
      (error) => {
        this.props.showNotification(
          true,
          'Failed!',
          'error',
          'There was an error sharing the Scene. Try again! If continues to fail, reach out to StyleHub support on Slack.'
        )
        handleClose()
      }
    )
  }

  handleCollectionSharing = () => {
    const { usersArrayForExperienceItem, experienceType } = this.state
    const {
      collectionItem = {},
      handleClose = () => {},
      shareCollection,
      type,
    } = this.props
    let sharedUsersList = []
    if (experienceType === 'Shared' && usersArrayForExperienceItem.length > 0) {
      // Construct the Array to be sent to service
      usersArrayForExperienceItem.forEach(function (member) {
        let item = {
          access_level: member.readOrWrite,
          user: {
            first_name: member.first_name,
            last_name: member.last_name,
            display_name: member.display_name,
            email_address: member.email_address,
            login_id: member.login_id,
          },
        }
        sharedUsersList.push(item)
      })
    }
    // NOTE : If sharedUsersList is empty collection is is considered as private
    const accessType = sharedUsersList.length === 0 ? 'Private' : experienceType
    var requestBody = {
      id: collectionItem.id,
      name: collectionItem.name,
      owner: collectionItem.owner,
      is_shared_favourite: 'true', // Always true as per the API contract as It is a shred collection
      shared_users: sharedUsersList,
      groups: collectionItem.groups,
    }
    accessType === 'Private' &&
      (requestBody['favourite_visibility'] = 'Private')
    this.setState({ isSubmitting: true })
    shareCollection(
      requestBody,
      (response) => {
        this.props.showNotification(
          true,
          'Success!',
          'success',
          `${
            type === TYPES.FAVORITES
              ? 'Success! Collection shared'
              : 'Scene is now public. Anyone with a link will be able to access the scene and view it.'
          }`
        )
        handleClose()
      },
      (error) => {
        this.props.showNotification(
          true,
          'Failed!',
          'error',
          `There was an error sharing the ${
            type === TYPES.FAVORITES ? 'Collection' : 'Scene'
          }. Try again! If continues to fail, reach out to StyleHub support on Slack.`
        )
        handleClose()
      }
    )
  }

  render() {
    const { isDialog = true } = this.props
    console.log('isDIal', isDialog)
    return (
      <div>{isDialog ? this.renderingDialog() : this.renderingPopover()}</div>
    )
  }

  renderingPopover() {
    const {
      classes,
      handleClose = () => {},
      popoverPostion = {},
      experienceItem = {},
      widthOfPivotElement = 0,
      type = TYPES.EXPERIENCE,
    } = this.props
    const { left, top } = popoverPostion
    const { isSpecific, experienceType, openPopover } = this.state

    const screenWidth = window.innerWidth
    // const screenHeight = window.innerHeight
    const dialogWidth = experienceType === 'Shared' ? 640 : 280
    // const dialogHeight = experienceType === 'Shared' ? 442 : 245
    const availWidth = screenWidth - left
    // const availHeight = screenHeight - top
    let dialogPositionX
    let dialogPositionY
    const MAX_DIALOG_WIDTH = 640

    if (availWidth >= MAX_DIALOG_WIDTH) {
      dialogPositionX = left
    } else {
      dialogPositionX = left - dialogWidth - widthOfPivotElement - 40
    }

    dialogPositionY = top
    const dialogPosition = {
      top: dialogPositionY,
      left: dialogPositionX,
    }

    return (
      <Grid>
        <Popover
          id="ExpPopOver"
          anchorReference="anchorPosition"
          anchorPosition={dialogPosition}
          classes={{
            paper: isSpecific
              ? classes.popoverPaper
              : classes.popoverPaperDefault,
          }}
          open={openPopover}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          <div className={classes.expName}>
            <div>
              <PageTitleBar
                title={
                  type === TYPES.FAVORITES ? 'Share collection' : 'Share Scene'
                }
                subTitle={
                  type === TYPES.FAVORITES
                    ? 'Share and manage this collection with peers'
                    : 'Share and manage the scene with peers'
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <LinkComponent
                sx={{ marginLeft: 'auto', marginRight: '18px' }}
                color="#3F51B5"
                name="See how it works"
                onMenuClick={() => {}}
                underline="hover"
                variant="body2"
              /> */}
              <CustomIconButton
                aria-label="close"
                label="close"
                onClick={handleClose}
              >
                <CloseIcon />
              </CustomIconButton>
            </div>
          </div>

          <div
            className={classNames({
              [classes.popoverPaperType]:
                type === TYPES.FAVORITES && !isSpecific,
            })}
          >
            {this.loadRadioGroupUI()}
          </div>
          <div className={classes.bottomDiv}>{this.renderingButtons()}</div>
        </Popover>
      </Grid>
    )
  }

  renderingDialog() {
    const { classes, handleClose } = this.props
    const { isSpecific, openPopover } = this.state
    return (
      <Grid>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openPopover}
          classes={{
            paper: isSpecific
              ? classes.dialogPaper
              : classes.dialogPaperDefault,
          }}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogContentWrapper>{this.loadRadioGroupUI()}</DialogContentWrapper>
          <DialogActionsWrapper
            classes={{
              root: classes.dialogAct,
            }}
          >
            {this.renderingButtons()}
          </DialogActionsWrapper>
        </Dialog>
      </Grid>
    )
  }

  renderingButtons() {
    const { classes, handleClose, type = TYPES.EXPERIENCE } = this.props
    const { isSaveChange, isSubmitting } = this.state
    return (
      <div>
        <CustomButton
          label="Close"
          onClick={handleClose}
          variant="text"
          color={'tertiary'}
          sx={{
            // color: '#333333',
            // fontFamily: 'Roboto',
            // fontSize: '14px',
            // fontWeight: 500,
            // lineHeight: '20px',
            marginRight: '20px',
          }}
        />
        <CustomButton
          label={
            isSubmitting ? (
              <span>
                {' '}
                Saving......
                <FontAwesomeIcon icon={faCircleNotch} size="1x" color="grey" />
              </span>
            ) : (
              <span>Save changes</span>
            )
          }
          onClick={
            type === TYPES.FAVORITES
              ? this.handleCollectionSharing
              : this.handleSaveChanges
          }
          variant="contained"
          sx={
            isSaveChange && !isSubmitting
              ? classes.saveButtonStyle
              : classes.saveButtonDisabled
          }
          disabled={!isSaveChange || isSubmitting}
        />
      </div>
    )
  }

  compareHelperFunction(firstUserArray, secondUserArray) {
    if (firstUserArray.length !== secondUserArray.length) {
      return true
    } else {
      for (let i = 0; i < firstUserArray.length; i++) {
        var noChange = true
        for (let j = 0; j < secondUserArray.length; j++) {
          if (firstUserArray[i].login_id === secondUserArray[j].login_id) {
            noChange = false
            if (
              firstUserArray[i].readOrWrite !== secondUserArray[j].readOrWrite
            ) {
              return true
            }
          }
        }
        if (noChange) {
          //means some change is detected
          return true
        }
      }
      return false
    }
  }
}

ShareExperienceDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  experienceItem: PropTypes.object,
  openDialog: PropTypes.bool,
  customClassesAvatar: PropTypes.string,
  experienceSaveChanges: PropTypes.func,
  handleClose: PropTypes.func,
  isDialog: PropTypes.bool,
  popoverPostion: PropTypes.object,
  widthOfPivotElement: PropTypes.number,
}
ShareExperienceDialog.defaultProps = {
  type: TYPES.EXPERIENCE,
}

const mapStateToProps = (state) => {
  const { auth } = state
  return {
    auth,
  }
}

export default connect(mapStateToProps, {
  experienceSaveChanges,
  showNotification,
  shareCollection,
})(withStyles(styles)(ShareExperienceDialog))
