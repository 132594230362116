import { useEffect, useState } from 'react'
import DefaultImage from '../../images/ic_default_board_img.png'
import { Typography, CustomIconButton } from 'cgi-ui-components'
import { Card, CardActions, CardMedia, CardActionArea } from '@mui/material'
import { fetchRenditionImage } from '../../store/customCard/actionCreator'

import config from '../../config/config'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  cardActions: { justifyContent: 'space-between' },
  root: { marginBottom: 15 },
}))

function CameraThumbnails({
  item = {},
  handleCardActionClk = () => {},
  selected = false,
  title = '',
  actionIcons = [],
}) {
  const [imgSrc, setImgSrc] = useState('')

  useEffect(() => {
    fetchImage()
  }, [])

  useEffect(() => {
    fetchImage()
  }, [item.imgUrl])

  const fetchImage = () => {
    const { imgUrl = '' } = item

    if (imgUrl === null) {
      setImgSrc(DefaultImage)
      return
    }
    let renditionsUrl = config.renditionsUrl + imgUrl

    fetchRenditionImage(
      renditionsUrl,
      (response) => {
        setImgSrc(URL.createObjectURL(response))
      },
      () => {
        setImgSrc(DefaultImage)
      }
    )
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card
        style={{
          border: `${selected ? '3px solid #CC0000' : '3px solid white'}`,
        }}
      >
        <CardActionArea onClick={() => handleCardActionClk(item.key)}>
          <CardMedia sx={{ height: 100 }} image={imgSrc} />
        </CardActionArea>
        <CardActions classes={{ root: classes.cardActions }}>
          <Typography variant="caption" label={title}></Typography>
          <div>
            {actionIcons.map(({ show, label, icon, disabled, onClick }) =>
              show ? (
                <CustomIconButton
                  size="small"
                  label={label}
                  disabled={disabled}
                  onClick={onClick}
                >
                  {icon}
                </CustomIconButton>
              ) : null
            )}
          </div>
        </CardActions>
      </Card>
    </div>
  )
}

export default CameraThumbnails
