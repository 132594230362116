import axios from 'axios'
import {
  ADD_CART_DATA_REDUX,
  CLEAR_CART_DATA,
  MULTI_ASSET_SELECTION,
  DOWNLOAD_CSV,
  TOGGLE_REPORT_ISSUE_MODAL,
  SELECT_CURRENT_TCIN,
  SAVE_REPORT_ISSUE,
} from './ActionType'
import api from '../../config/config'

export function addCartDataRedux(payload) {
  return function (dispatch) {
    dispatch({
      type: ADD_CART_DATA_REDUX,
      payload,
    })
  }
}
export function clearCartData() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_CART_DATA,
    })
  }
}

export function toggleReportIssueModal(payload) {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_REPORT_ISSUE_MODAL,
      payload,
    })
  }
}

export function selectCurrentTcin(payload) {
  return function (dispatch) {
    dispatch({
      type: SELECT_CURRENT_TCIN,
      payload,
    })
  }
}

export function multiAssetSelection(payload) {
  return function (dispatch) {
    dispatch({
      type: MULTI_ASSET_SELECTION,
      payload,
    })
  }
}

export function downlaodCallCSV(payload) {
  return function (dispatch) {
    dispatch({
      type: DOWNLOAD_CSV,
      payload: payload,
    })
  }
}

export function saveReportAnIssue(payload, callback) {
  return function (dispatch) {
    let url = `${api.reportAnIssue.saveIssue}`
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch({
          type: SAVE_REPORT_ISSUE,
          payload: response,
        })
        callback(true)
      })
      .catch((error) => {
        console.log('error', error)
        callback(false)
      })
  }
}

export function downloadCSVAction(sceneId, callback = () => {}) {
  return function (dispatch) {
    return axios
      .get(api.cart.export + sceneId)
      .then((response) => {
        dispatch(downlaodCallCSV(response.data))
        callback(response.data)
      })
      .catch((error) => error)
  }
}
