import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import DialogContentText from '@mui/material/DialogContentText'
import {
  fetchExperience,
  clearExperiences,
  deleteScene,
} from '../../../store/experience/ActionCreator'
import {
  SceneCard,
  PageTitleBar,
  Select,
  Typography,
  CustomButton,
  BreadcrumbComponent,
  SearchComponent,
} from 'cgi-ui-components'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { readableDate } from '../../../helpers/dataFormats'
import noThumbnail from '../../../images/ic_no-thumbnail.svg?url'
import config from '../../../config/config'
import LinearProgress from '@mui/material/LinearProgress'
import { showNotification } from '../../../store/notification/actionCreator'
import withRouter from '../../../components/HOC/withRouterHOC'
import ShareExperienceDialog from '../../Components/ShareExperiencePopup'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'
import Popover from '@mui/material/Popover'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import _ from 'lodash'
import { canUserWrite } from '../../../helpers/utils'

import searchLogo from '../../../images/enterprise/searchLogo.svg?url'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    textDecoration: 'none',
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'Calc(100vh - 165px)',
    overflow: 'scroll',
  },
  card: {
    margin: '0px 15px',
    marginBottom: '30px',
  },
  headerWrapper: {
    padding: '15px 0px 30px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentEnd: {
    height: 10,
    flex: '0 0 100%',
  },
  noSearchResultContext: {
    padding: 20,
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 300,
    margin: '220px auto !important',
  },
  resultKeyword: {
    fontWeight: 1000,
  },
  deletePopver: {
    borderRadius: 5,
    padding: 20,
  },
  popoverButtons: {
    display: 'flex',
    justifyContent: 'end',
    marginLeft: 50,
  },
})

class ScenePage extends React.Component {
  constructor(props) {
    super(props)
    this.contentEndRef = React.createRef()
    this.state = {
      pageNum: 1,
      perPage: 15,
      searchVal: '',
      sortBy: '',
      filterType: 'DEFAULT',
      sortOrder: 'DESC',
      openDialog: false,
      experienceItem: '',
      popoverAnchorElem: null,
      popoverPostion: {},
      openConfirm: false,
      experienceName: '',
      experienceId: '',
      filterList: [],
      deletePopover: null,
    }
  }
  componentDidMount() {
    const {
      projectId = '',
      clearExperiences = () => {},
      initiatorList,
    } = this.props
    if (projectId !== '') {
      clearExperiences()
    }
    this.setState(
      { pageNum: 1, searchVal: '', sortBy: '', sortOrder: 'DESC' },
      () => {
        this.fetchScenesList(true)
        if (!this.contentEndRef.current) return
        this.observer = new IntersectionObserver(this.interSectionCallback, {
          threshold: [0.1],
        })
        this.observer.observe(this.contentEndRef.current)
      }
    )
    const list = initiatorList.map((item) => {
      return {
        name: item.name.replace(/\./g, ' '),
        value: item.name,
      }
    })
    const sortList = [
      {
        name: 'None',
        value: 'None',
      },
      {
        name: 'Date Created',
        value: 'created_date',
      },
    ]

    this.setState({
      filterList: list,
      sortList,
    })
  }

  interSectionCallback = (entries, observer) => {
    const {
      experienceListloading,
      totalExperienceCount,
      experienceList,
      fetchExperience,
      projectId = '',
    } = this.props
    const { perPage, searchVal, sortBy, pageNum, sortOrder, filterType } =
      this.state
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (
          !experienceListloading &&
          totalExperienceCount > experienceList.length
        ) {
          fetchExperience(
            perPage,
            pageNum + 1,
            searchVal,
            sortBy,
            sortOrder,
            false,
            projectId,
            filterType,
            () => {
              this.setState({
                pageNum: this.state.pageNum + 1,
              })
            }
          )
        }
      }
    })
  }

  closeConfirmDialog = () => {
    this.setState({ openConfirm: false, experienceName: '', experienceId: '' })
  }

  deleteSceneHandler = () => {
    const { deleteScene, showNotification, clearExperiences } = this.props
    const { experienceId } = this.state
    deleteScene(
      experienceId,
      () => {
        showNotification(
          true,
          'Success! Scene deleted from the system',
          'success'
        )
        this.setState(
          {
            openConfirm: false,
            experienceName: '',
            experienceId: '',
            pageNum: 1,
          },
          () => {
            clearExperiences()
            this.fetchScenesList(false)
          }
        )
      },
      () => {
        showNotification(
          true,
          'Failed!',
          'error',
          'There was an error deleteing the Scene. Try again! If continues to fail, reach out to StyleHub support on Slack.'
        )
        this.setState({
          openConfirm: false,
          experienceName: '',
          experienceId: '',
        })
      }
    )
  }

  handleFilter = (e) => {
    const { clearExperiences = () => {} } = this.props
    const filterBy = e.target.value
    clearExperiences()
    this.setState(
      {
        filterType: filterBy === 'None' ? '' : filterBy,
        pageNum: 1,
        searchVal: '',
      },
      () => {
        this.fetchScenesList(false)
      }
    )
  }
  handleSort = (e) => {
    const sortBy = e.target.value === 'None' ? '' : e.target.value
    const { clearExperiences = () => {} } = this.props
    if (sortBy === 'None' || sortBy === '') {
      const sortList = this.state.sortList
      sortList[0].name = 'Sort Scene'
      this.setState({
        sortList,
        pageNum: 1,
        searchVal: '',
      })
    }
    clearExperiences()
    this.setState({ pageNum: 1, searchVal: '', sortBy }, () => {
      this.fetchScenesList(false)
    })
  }
  handleCloseSharingExperienceDialog = () => {
    this.setState({ openDialog: false })
  }
  fetchScenesList(isShowSearch) {
    const { perPage, pageNum, searchVal, sortBy, sortOrder, filterType } =
      this.state
    const { fetchExperience = () => {}, projectId = '' } = this.props
    fetchExperience(
      perPage,
      pageNum,
      searchVal,
      sortBy,
      sortOrder,
      isShowSearch,
      projectId,
      filterType
    )
  }

  noSearchContent = () => {
    const {
      classes,
      listPageMeta: { searchVal },
    } = this.props
    return (
      <DialogContentText className={classes.noSearchResultContext}>
        <SearchOffRoundedIcon
          sx={{
            width: '120px',
            height: '120px',
            margin: 'auto',
            display: 'block',
          }}
        />
        <Typography
          variant={'h6'}
          label={
            <span>
              No results found for{' '}
              <span className={classes.resultKeyword}>{`"${
                searchVal || ''
              }"`}</span>
            </span>
          }
        />
        <Typography
          variant={'body2'}
          label={`Please make sure your words are spelled correctly or use different keywords.`}
        />
      </DialogContentText>
    )
  }

  onCross = () => {
    this.props.clearExperiences()
    this.setState({ pageNum: 1, searchVal: '' }, () => {
      this.fetchScenesList(false)
    })
  }

  searchScenes = _.debounce((searchVal) => {
    this.props.clearExperiences()
    this.setState({ pageNum: 1, searchVal }, () => {
      this.fetchScenesList(false)
    })
  }, 300)

  render() {
    const { history } = this.props
    const {
      classes,
      experienceList,
      totalExperienceCount,
      auth: { lanId },
      initiatorList,
      experienceListloading,
      listPageMeta: { searchVal },
      isProject = false,
      projectName = '',
      updateProjectList = () => {},
    } = this.props
    const {
      openConfirm,
      experienceName,
      openDialog,
      popoverPostion,
      experienceItem,
    } = this.state
    const list = initiatorList.map((item) => {
      return {
        name: item.name.replace(/\./g, ' '),
        value: item.name,
      }
    })

    list.unshift({ name: 'All', value: 'None' })
    const sortList = [
      {
        name: 'None',
        value: 'None',
      },
      {
        name: 'Date Created',
        value: 'created_date',
      },
    ]
    const cardWidth = 224
    const isDialog = false
    return (
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <div>
            <PageTitleBar
              title={isProject ? projectName : 'All Scenes'}
              subTitle={`Browse from ${totalExperienceCount} scenes`}
            />
          </div>
          <div style={{ marginLeft: 50 }}>
            {/* {searchVal && totalExperienceCount > 0 && (
              <Typography
                label={`${totalExperienceCount} results found for “${searchVal}”`}
              />
            )} */}
            <SearchComponent
              height={40}
              onSearch={this.searchScenes}
              onSearchButtonClick={this.onCross}
              placeholder={`Search in Scenes...`}
              searchVal={searchVal}
              width={320}
              brandLogo={searchLogo}
            />
          </div>
          <div style={{ width: 150, marginLeft: 'auto' }}>
            <Select
              inputLabel="Filter"
              size="small"
              color="tertiary"
              onChange={this.handleFilter}
              options={list}
            />
          </div>
          <div style={{ width: 150, marginLeft: 20 }}>
            <Select
              color={'tertiary'}
              size="small"
              inputLabel="Sort"
              onChange={this.handleSort}
              options={sortList}
            />
          </div>
        </div>
        {isProject && (
          <div style={{ marginBottom: '30px' }}>
            <BreadcrumbComponent
              activeLast
              items={[
                {
                  link: '#',
                  name: 'Home',
                  onClick: (e) => {
                    e.preventDefault()
                    history.push('/home')
                  },
                  props: {
                    color: 'inherit',
                    underline: 'hover',
                  },
                },
                {
                  link: '#',
                  onClick: (e) => {
                    e.preventDefault()
                    history.push('/home/projects')
                    updateProjectList()
                  },
                  name: 'Projects',
                  props: {
                    color: 'inherit',
                    underline: 'hover',
                  },
                },
                {
                  name: projectName,
                  props: {
                    color: 'inherit',
                    underline: 'hover',
                  },
                },
              ]}
            />
          </div>
        )}

        <div className={classes.innerContainer}>
          {!experienceList.length &&
            !experienceListloading &&
            searchVal &&
            this.noSearchContent()}
          {experienceList &&
            experienceList.map((item, i) => {
              const hasWriteAccess = canUserWrite(
                lanId,
                item.initiator,
                item.members
              )

              let footerItems = [
                {
                  icon: EditOutlinedIcon,
                  onClick: () => {
                    const { projectId = '' } = this.props
                    if (projectId !== '') {
                      history.push(
                        '/experience/' + item.experience_id + '/' + projectId
                      )
                    } else {
                      history.push('/experience/' + item.experience_id)
                    }
                  },
                  title: 'Launch',
                },
                {
                  icon: ImageOutlinedIcon,
                  onClick: () => {
                    history.push('/render/' + item.experience_id)
                  },
                  title: 'View Shot',
                },
              ]
              if (item.initiator === lanId) {
                footerItems.push({
                  icon: DeleteOutlinedIcon,
                  onClick: (e) => {
                    this.setState({
                      openConfirm: true,
                      experienceName: item.experience_name,
                      experienceId: item.experience_id,
                      deletePopover: e.currentTarget,
                    })
                  },
                  title: 'Delete',
                  alignRight: true,
                })
              }
              let moreMenu = []
              if (hasWriteAccess) {
                moreMenu.push({
                  onClick: (e) => {
                    this.setState({
                      openDialog: true,
                      experienceItem: item,
                      popoverPostion: {
                        left:
                          e.currentTarget.getBoundingClientRect().right + 20,
                        top: e.currentTarget.getBoundingClientRect().top,
                      },
                    })
                  },
                  title: hasWriteAccess ? 'Share' : 'View Only',
                })
              }

              return (
                <div className={classes.card} key={i}>
                  <SceneCard
                    date={readableDate(item.created_date)}
                    imgHeight={250}
                    loginId={item.initiator}
                    displayName={item?.initiator_details?.display_name}
                    maxWidth={250}
                    minWidth={250}
                    config={config}
                    isUrlAppendedWithImg={true}
                    avatarBackgroundColor={'#E0E0E0'}
                    avtarTextColor={'#333'}
                    noThumbnail={noThumbnail}
                    footerItems={footerItems}
                    moreMenuItems={moreMenu}
                    name={item.experience_name}
                    thumbnail={item.exp_thumbnail_loc}
                  />
                </div>
              )
            })}
          <div ref={this.contentEndRef} className={classes.contentEnd} />
        </div>
        <div>{experienceListloading && <LinearProgress />}</div>
        {openDialog ? (
          <ShareExperienceDialog
            openDialog={openDialog}
            handleClose={this.handleCloseSharingExperienceDialog}
            experienceItem={experienceItem}
            popoverPostion={popoverPostion} // Only for Popover
            isDialog={isDialog} // Here open as popover, so isDialog will be false
            widthOfPivotElement={cardWidth} // optional, only for card and use it as Popover
          />
        ) : (
          ''
        )}
        {openConfirm && (
          <Popover
            open={Boolean(this.state.deletePopover)}
            anchorEl={this.state.deletePopover}
            onClose={() => {
              this.setState({
                deletePopover: null,
              })
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'top',
            }}
          >
            <div className={classes.deletePopver}>
              <Typography variant="subtitle2" label={'Confirm Delete?'} />
              <Typography
                sx={{
                  marginTop: '10px',
                  marginBottom: '20px',
                  color: '#000000',
                  opacity: '0.6',
                }}
                variant="body2"
                label={'Action can’t be undone'}
              />
              <div className={classes.popoverButtons}>
                <CustomButton
                  label="Cancel"
                  onClick={() => {
                    this.setState({
                      deletePopover: null,
                    })
                  }}
                  startIcon={<CloseIcon />}
                  variant="text"
                  color={'tertiary'}
                />
                <CustomButton
                  sx={{ marginLeft: '10px' }}
                  label="Delete"
                  onClick={this.deleteSceneHandler}
                  startIcon={<DeleteIcon />}
                  variant="contained"
                  color={'error'}
                />
              </div>
            </div>
          </Popover>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { experiences, auth } = state
  const {
    experienceList,
    totalExperienceCount,
    initiatorList,
    isShow,
    noResultsFound,
    listPageMeta,
    experienceListloading,
  } = experiences
  return {
    experienceList,
    totalExperienceCount,
    initiatorList,
    isShow,
    noResultsFound,
    listPageMeta,
    auth,
    experienceListloading,
  }
}

export default connect(mapStateToProps, {
  fetchExperience,
  clearExperiences,
  deleteScene,
  showNotification,
})(withStyles(styles)(withRouter(ScenePage)))
