import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { Paper } from '@mui/material'
import { PageTitleBar, CustomIconButton, AssetCard } from 'cgi-ui-components'
import LinkIcon from '@mui/icons-material/Link'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import config from '../../config/config'
import {
  fetchStyledAssets,
  clearStyledAssets,
} from '../../store/explorer/actionCreator'
import MaxAssetCard from '../assetExplorerCard/MaxAssetCard'
import DataList from '../DataList'
import { mapInfoForCard } from '../../helpers/utils'

const styles = (theme) => ({
  card: {
    margin: '0px 10px',
    marginBottom: '20px',
  },
  paper: {
    padding: '20px 10px',
  },
  header: { display: 'flex', flexDirection: 'row' },
  closeButton: { textAlign: 'right', flex: 1 },
  titleBar: { flex: 4 },
  noResultsFound: {
    padding: 20,
    textAlign: 'center',

    width: '100%',
  },
  dataListContainer: { height: 'Calc(100vh - 250px) !important' },
})

class StyledAssets extends React.Component {
  state = {
    loading: false,
  }
  componentDidMount() {
    this.fetchAssets()
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.data.tcin !== prevProps.data.tcin ||
      this.props.data.variation !== prevProps.data.variation
    ) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.fetchAssets()
    }
  }

  fetchAssets() {
    const { fetchStyledAssets, data, clearStyledAssets } = this.props
    const req = {
      recordsPerPage: 100,
      pageNum: 1,
      searchVal: '',
      subAssetType: data.variant,
      assetType: data.asset_type,
      masterTcin: data.tcin,
    }
    clearStyledAssets()
    this.setState({ loading: true })
    fetchStyledAssets(req)
      .then(() => {
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }
  close = () => {
    const { onClose, clearStyledAssets } = this.props
    clearStyledAssets()
    onClose()
  }
  interSectionCallback = () => {}
  render() {
    const {
      classes,
      data: { tcin },
      totalStyledAssets,
      styledAssets,
      size,
      isSceneEditor = false,
      data,
      handleCartActions,
    } = this.props
    const { loading } = this.state
    const subAssetType = data.variant

    return (
      <Paper className={classes.paper} elevation={2}>
        <div className={classes.header}>
          <div className={classes.titleBar}>
            <PageTitleBar
              title={`Browse ${subAssetType.toLowerCase()} version for ${tcin}`}
              subTitle={`${totalStyledAssets} results`}
            />
          </div>
          <div className={classes.closeButton}>
            <CustomIconButton onClick={this.close} label={'close'}>
              <CloseRoundedIcon />
            </CustomIconButton>
          </div>
        </div>
        <DataList
          showProgressBar={loading}
          interSectionCallback={this.interSectionCallback}
          containerClass={classes.dataListContainer}
        >
          <>
            {styledAssets &&
              styledAssets.map((item) => {
                const infoDetails = {
                  icon: InfoOutlinedIcon,
                  title: 'info',
                  data: mapInfoForCard(item),
                }

                const footerItems = !item.is_pure_rta_asset
                  ? [
                      {
                        icon: LinkIcon,
                        onClick: () => this.props.copyPath(item),
                        title: 'Copy path',
                      },
                    ]
                  : []

                return (
                  <div className={classes.card}>
                    {isSceneEditor ? (
                      <MaxAssetCard
                        data={item}
                        selectable
                        onSelect={(e) => {
                          this.props.selectAsset(
                            item.tcin || item.value || item.pid,
                            item,
                            e.target.checked
                          )
                        }}
                        selected={
                          this.props.selectedItems[
                            item.tcin || item.value || item.pid
                          ] !== undefined
                        }
                        handleCartActions={handleCartActions}
                      />
                    ) : (
                      <AssetCard
                        size={size}
                        thumbnail={item.thumbnail_url}
                        config={config}
                        isUrlAppendedWithImg
                        assetData={item}
                        name={
                          item.short_description ||
                          item.tcin ||
                          item.value ||
                          item.pid
                        }
                        maxWidth={230}
                        minWidth={230}
                        infoDetails={infoDetails}
                        noThumbnail={noThumbnail}
                        selectable
                        checkboxColor={'tertiary'}
                        footerItems={footerItems}
                        errorDetails={{
                          high: item.is_hp_available || true,
                          low: item.is_lp_available || true,
                        }}
                        subTitle={item.product_desc || item.tcin || ``}
                        selected={
                          this.props.selectedItems[
                            item.tcin || item.value || item.pid
                          ] !== undefined
                        }
                        onSelect={(e) => {
                          this.props.selectAsset(
                            item.tcin || item.value || item.pid,
                            item,
                            e.target.checked
                          )
                        }}
                        isRtaAsset={item.is_pure_rta_asset}
                        isHighPolyCountAsset={item.is_high_triangle_count}
                      />
                    )}
                  </div>
                )
              })}

            {!loading && !styledAssets.length ? (
              <div className={classes.noResultsFound}>No Results found</div>
            ) : null}
          </>
        </DataList>
      </Paper>
    )
  }
}

const mapStateToProps = ({ explorer: { styledAssets, totalStyledAssets } }) => {
  return {
    styledAssets,
    totalStyledAssets,
  }
}
export default connect(mapStateToProps, {
  fetchStyledAssets,
  clearStyledAssets,
})(withStyles(styles)(StyledAssets))
