import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  FormTextField,
  ProjectInput,
  CustomButton,
  ChipSelect,
  CustomIconButton,
  AutoCompleteComponent,
  PageTitleBar,
  LinkComponent,
} from 'cgi-ui-components'
import { makeStyles } from '@mui/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import axios from 'axios'
import config from '../../config/config'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { showNotification } from '../../store/notification/actionCreator'

import {
  ERROR_MSG_FOR_CREATE_EXPERIENCE,
  ERROR_MSG_FOR_EXPERIENCE_EXIST,
  ERROR_MSG_FOR_PROJECT_EXIST,
  ERROR_MSG_FOR_PROJECT_CYCLE_EXIST,
  ERROR_MSG_FOR_FETCHING_PROJECTS_DROPDOWN,
  MSG_FOR_LOADER_FETCHING_PROJECTS_DROPDOWN,
} from '../../constants/displayMessages'

import {
  createExperience,
  updateExperience,
  fetchProjects,
  fetchProjectTypes,
} from '../../store/experience/ActionCreator'

import {
  toggleTemplateModal,
  fetchBusinessDepartments,
} from '../../store/template/actionCreator'

import { SaveAsNewScene } from '../designSpace/index'

const currentDate = () => {
  const date = new Date()
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

const styles = makeStyles((theme) => ({
  linkq: {
    fontSize: 16,
    display: 'block',
    fontWeight: 400,
    color: '#000000',
    textDecoration: 'none',
    verticalAlign: 'middle',
    '&:hover': {
      color: '#3F51B5',
      textDecoration: 'underline',
    },
  },
}))

const ExperienceForm = ({
  open: initialOpen = true,
  toggleForm = () => {},
  onClose = () => {},
  isEditFromTemplate = false,
}) => {
  const classes = styles()
  const dispatch = useDispatch()
  const { id, preSelectedProjectId } = useParams()

  const [name, setName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [skipping, setSkipping] = useState(false)
  const history = useNavigate()
  const [projectList, setProjectList] = useState([])
  const [projectTypes, setProjectTypes] = useState([])
  const [notiMessage, setNotiMessage] = useState('')
  const [teams, setTeams] = useState([])

  const {
    auth,
    experience,
    businessDepts,
    copyScene = false,
  } = useSelector(
    ({
      auth,
      templates: { businessDepts = [] },
      experiences: { experience },
      scene: { copyScene = false },
    }) => {
      return { auth, experience, businessDepts, copyScene }
    }
  )

  const { lanId, fullName = '', email, firstName } = auth

  const [projectName, setProjectName] = useState('')
  const [projectId, setProjectId] = useState('')
  const [cycleName, setCycleName] = useState('')
  const [projectType, setProjectType] = useState('')
  const [projectError, setProjectError] = useState('')
  const [expError, setExpError] = useState('')
  const [saveDisabled, setSaveDisabled] = useState(id !== 'new')
  const [members, setMembers] = useState([])
  const handleSelectMembers = (value) => {
    setMembers(value)
    setSaveDisabled(false)
  }
  let defaultMember = {
    displayName: fullName.replace(' ', '.'),
    label: `${firstName} - ${email}`,
    loginId: lanId,
    value: email,
  }
  let initiatorDetails = {}
  if (experience?.initiator_details) {
    let { display_name, email_address, login_id } =
      experience?.initiator_details || {}
    initiatorDetails = {
      displayName: display_name,
      label: display_name,
      loginId: login_id,
      value: email_address,
    }
  }

  const submitExperienceForm = (event) => {
    event.preventDefault()

    setSkipping(true)
    setSubmitting(true)

    let membersData =
      members &&
      members.map((m) => ({
        login_id: m.loginId,
        permission:
          m.permission === undefined || m.permission === null
            ? 'Read'
            : m.permission,
      }))

    const requestData = {
      ...experience,
      initiator: lanId,
      experience_name: name,
      members: membersData,
      teams: teams,
    }
    const projectInfo =
      projectId == 'new'
        ? {
            project_name: projectName,
            project_type: projectType,
            cycle_name: cycleName,
            project_id: null,
            teams: teams,
          }
        : {
            project_id: projectId,
            project_name: '',
            project_type: '',
            cycle_name: '',
            teams: teams,
          }

    if (copyScene) {
      SaveAsNewScene(
        {
          ...requestData,
          ...projectInfo,
          source_experience_id: experience?.experience_id,
          initiator: lanId,
          teams: teams,
        },
        ({ experience_id }) => {
          toggleForm(false)
          window.open(`/experience/${experience_id}`)
        },
        handleResponseError
      )

      return
    }

    if (id !== 'new') {
      dispatch(
        updateExperience(
          { ...requestData, ...projectInfo },
          (response) => {
            if (isEditFromTemplate) {
              dispatch(toggleTemplateModal(true))
            }
            toggleForm(false)
            if (notiMessage !== '') {
              dispatch(
                showNotification(
                  true,
                  <div>
                    Success! Scene name has been updated as per primary TCIN in
                    the styling board
                  </div>,
                  'success'
                )
              )
            }
          },
          (error) => {
            handleResponseError(error)
          }
        )
      )
    } else {
      dispatch(
        createExperience(
          { ...requestData, ...projectInfo },
          (response) => {
            setTimeout(() => {
              history('/experience/' + response.experience_id)
            }, 500)
            toggleForm(false)
            dispatch(toggleTemplateModal(true))
          },
          (error) => {
            handleResponseError(error)
          }
        )
      )
    }
  }

  const handleSkipLater = (event) => {
    event.preventDefault()
    setSkipping(true)
    setSubmitting(true)
    const payload = {
      initiator: lanId,
      experience_name: `Untitled${+new Date()}`,
      teams: teams,
    }
    dispatch(
      createExperience(
        payload,
        (response) => {
          setTimeout(() => {
            history('/experience/' + response.experience_id)
          }, 500)
          toggleForm(false)
          dispatch(toggleTemplateModal(true))
          setSkipping(false)
          setSubmitting(false)
        },
        (error) => {
          handleResponseError(error)
        }
      )
    )
  }

  const handleResponseError = (error) => {
    const { errorCode = '', errorMessages = [] } = error?.response?.data || {}

    if (errorCode && errorCode === '409') {
      if (errorMessages[0] === ERROR_MSG_FOR_EXPERIENCE_EXIST) {
        setExpError(ERROR_MSG_FOR_EXPERIENCE_EXIST)
      }
      if (errorMessages[0] === ERROR_MSG_FOR_PROJECT_EXIST) {
        setProjectError(ERROR_MSG_FOR_PROJECT_EXIST)
      }
      if (errorMessages[0] === ERROR_MSG_FOR_PROJECT_CYCLE_EXIST) {
        setProjectError(ERROR_MSG_FOR_PROJECT_CYCLE_EXIST)
      }
    } else {
      dispatch(showNotification(true, ERROR_MSG_FOR_CREATE_EXPERIENCE, 'error'))
    }
    setSkipping(false)
    setSubmitting(false)
  }

  useEffect(() => {
    setProjectName('')
    setCycleName('')
    setProjectType('')
  }, [projectId])
  useEffect(() => {
    setCycleName('')
  }, [projectType])

  useEffect(() => {
    if (experience?.experience_id && !copyScene) {
      setName(experience.experience_name)
      setProjectId(experience.project_id)
      setTeams(experience.teams)
      if (experience?.members) {
        setMembers(
          experience.members.map(({ access_level: permission, user }) => {
            return {
              label: user.first_name,
              value: user.email_address,
              displayName: user.display_name,
              loginId: user.login_id,
              permission: permission,
            }
          })
        )
      }
    }
    if (copyScene) {
      setName('')
      setProjectName('')
      setCycleName('')
      setProjectType('')
      setTeams([])
      setMembers([])
    }
  }, [experience?.experience_id, copyScene])

  const [timer, setTimer] = useState(null)
  useEffect(() => {
    if ((id === 'new' && !isEditFromTemplate) || copyScene) {
      if (timer) {
        clearTimeout(timer)
      }
      setProjectError('')
      setExpError('')

      setSaveDisabled(false)

      let temp = setTimeout(() => {
        const url = `${
          config.experience.validate
        }?experience_name=${encodeURIComponent(
          name
        )}&project_name=${encodeURIComponent(
          projectName
        )}&cycle_name=${encodeURIComponent(cycleName)}`
        axios.get(url).then(({ data }) => {
          const isExpValid = data?.experience_error_message
          const isProjectValid = data?.project_error_message
          setProjectError(isProjectValid ? isProjectValid : '')
          setExpError(isExpValid ? isExpValid : '')
          setSaveDisabled(isProjectValid || isExpValid)
        })
      }, 500)
      setTimer(temp)
    } else {
      setProjectError('')
      setExpError('')
      if (
        experience.experience_name !== name ||
        experience.project_id !== projectId
      ) {
        setSaveDisabled(false)
      }
    }
  }, [projectName, cycleName, name, projectId, id, isEditFromTemplate])

  const showEditExperience = (e) => {
    const {
      detail: { name: expname = name },
    } = e
    setName(expname)
    setNotiMessage('Scene name will be updated as per selected TCIN')
  }

  useEffect(() => {
    fetchProjects(
      (projects) => {
        setProjectList(projects)
        if (preSelectedProjectId && preSelectedProjectId !== '') {
          setProjectId(preSelectedProjectId)
        }
      },
      () => {
        dispatch(
          showNotification(
            true,
            ERROR_MSG_FOR_FETCHING_PROJECTS_DROPDOWN,
            'error'
          )
        )
      }
    )
    fetchProjectTypes((projectTypes) => {
      setProjectTypes(projectTypes)
    })
    if (!businessDepts.length) {
      dispatch(fetchBusinessDepartments())
    }
    document.addEventListener('onGgaPrimeTcinChange', showEditExperience)
    return () =>
      document.removeEventListener('onGgaPrimeTcinChange', showEditExperience)
  }, [])

  const handleTeam = (value) => {
    let teamval = []
    businessDepts.forEach(({ team: name, team_display_value }) => {
      if (value.includes(team_display_value)) {
        teamval.push(name)
      }
    })
    setTeams(teamval)
  }
  const submitButtonDisabled =
    saveDisabled ||
    expError ||
    projectError ||
    skipping ||
    !name ||
    submitting ||
    (projectId === 'new' && !projectName) ||
    (projectName && projectType === 'CYCLIC' && !cycleName)
  const disableProjectEdit =
    !copyScene && id !== 'new' && lanId !== experience?.initiator

  let teamval = []
  if (teams !== null) {
    businessDepts.forEach(({ team: name, team_display_value }) => {
      if (teams.includes(name)) {
        teamval.push(team_display_value)
      }
    })
  }
  let titleText = `New Scene`
  if (id !== 'new' && !isEditFromTemplate) {
    titleText = `Edit Scene`
  }
  if (copyScene) {
    titleText = `Save Scene as`
  }

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={initialOpen}>
      <DialogTitle style={{ lineHeight: 0, padding: 30 }}>
        <PageTitleBar title={titleText} />

        {/* <LinkComponent
          color="#3F51B5"
          name="See how it works"
          onMenuClick={() => {}}
          underline="hover"
          variant="body2"
        /> */}

        <CustomIconButton
          aria-label="close"
          label="close"
          onClick={(e) => {
            onClose(e, 'escapeKeyDown')
          }}
          sx={{
            position: 'absolute',
            right: 20,
            top: 30,
          }}
        >
          <CloseIcon />
        </CustomIconButton>
      </DialogTitle>
      <DialogContent sx={{ width: 400, paddingBottom: 0, padding: '30px' }}>
        <div style={{ marginBottom: 8 }}>
          <FormTextField
            value={name}
            label="Scene name"
            onChange={(e) => setName(e.target.value)}
            variant="filled"
            error={expError !== '' || notiMessage !== ''}
            helperText={expError || notiMessage}
            size="small"
            color={'tertiary'}
          />
        </div>
        <ProjectInput
          data={projectList}
          projectTypes={projectTypes}
          loginId={lanId}
          date={currentDate()}
          projectId={projectId}
          projectNameError={projectError !== ''}
          projectErrorString={projectError}
          onCycleNameChange={(e, name) => setCycleName(name)}
          onProjectNameChange={(e, name) => setProjectName(name)}
          onProjectSelect={(e, id) => setProjectId(id)}
          onProjectTypeChange={(e, type) => setProjectType(type)}
          disabled={disableProjectEdit}
          color={'tertiary'}
        />
        <div style={{ marginBottom: 16 }}>
          <AutoCompleteComponent
            apiUrl={config.searchUser}
            defaultMember={id == 'new' ? defaultMember : initiatorDetails}
            handleAddMember={handleSelectMembers}
            values={members}
            label="Team members"
            searchParam="user"
            size="small"
            key="e8791ab338a5569cb12e1de7511ca44202380cd2"
            color={'tertiary'}
            showChips={true}
          />
        </div>

        <ChipSelect
          variant="filled"
          data={businessDepts.map(
            ({ team, team_display_value }) => team_display_value
          )}
          label="Business department"
          onChange={handleTeam}
          size="small"
          value={teamval}
          color={'tertiary'}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '30px', paddingTop: 0 }}>
        {!isEditFromTemplate && id == 'new' && (
          <>
            <CustomButton
              label={skipping ? 'Skipping' : 'Skip for later'}
              variant="text"
              color="tertiary"
              onClick={handleSkipLater}
              disableElevation
              disabled={copyScene}
            />
            <CustomButton
              label={submitting ? 'Creating' : 'Create'}
              disabled={submitButtonDisabled || submitting ? true : false}
              onClick={submitExperienceForm}
              disableElevation
            />
          </>
        )}

        {!isEditFromTemplate && id !== 'new' && (
          <CustomButton
            label={submitting ? 'Saving' : 'Save'}
            disabled={submitButtonDisabled || submitting ? true : false}
            onClick={submitExperienceForm}
            disableElevation
          />
        )}

        {isEditFromTemplate && (
          <CustomButton
            label={submitting ? 'Saving' : 'Select layout'}
            disabled={submitting ? true : false}
            onClick={submitExperienceForm}
            disableElevation
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ExperienceForm
