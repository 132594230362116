import React, { Component } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { connect } from 'react-redux'
import {
  FormTextField,
  InputTags,
  CustomButton,
  AlertComponent,
  Typography,
  CustomIconButton,
} from 'cgi-ui-components'
import _ from 'lodash'
import EditIcon from '@mui/icons-material/Edit'

import {
  getFilePathValidation,
  saveTemplate,
  saveTemplateSuccess,
  ingestTemplate,
} from '../../store/UploadTemplate/ActionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import { withStyles } from '@mui/styles'
import QaPluginStatusDisplay from './QaPluginStatusDisplay'
import Close from '@mui/icons-material/Close'
import { readableDateAndTimeDigitFormat } from '../../helpers/dataFormats'

const styles = () => ({
  boxStyle: { display: 'flex', alignItems: 'center' },
  formContainer: { height: `calc(100vh - 100px)`, overflow: 'scroll' },
  actionBar: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    height: 70,
    display: 'flex',
    justifyContent: 'right',
    boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)`,
  },
  tagsHeight: { height: 'auto !important' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
})

const FormWrapper = (props) => {
  return (
    <Box
      className={props.styles}
      sx={{
        height: '70px',
        width: '100%',
        '& .MuiFormHelperText-root': {
          marginLeft: '0px',
        },
        form: {
          width: '340px',
        },
      }}
    >
      <Box sx={{ width: '300px', marginTop: '15px' }}>
        <label>{props.label}</label>
      </Box>
      {props.children}
    </Box>
  )
}

export class TemplateRefresh extends Component {
  state = {
    formValues: {
      team: this.props.templateData?.team,
      tags: this.props.templateData?.template_tags,
      template_name: this.props.templateData?.template_name,
    },
    isFormSubmitted: false,
    isMaxPathValidated: false,
    isThumbPathVaildated: false,
    isFbxPathValidated: false,
    isArchPathValidated: false,
    hasError: false,
    selectedTemplate: {},
    isMaxPathDisabled: true,
    isThumbPathDisabled: true,
    isFbxPathDisabled: true,
    isArchPathDisabled: true,
    maxPath: this.props.templateData?.max_file_path,
    thumbPath: this.props.templateData?.thumbnail_file_path,
    fbxPath: this.props.templateData?.fbx_file_path,
    archPath: this.props.templateData?.architecture_file_path,
    maxPathError: null,
    thumbnailPathError: null,
    fbxPathError: null,
    archPathError: null,
  }

  componentDidMount() {
    this.fromRef.onkeypress = function (e) {
      var key = e.charCode || e.keyCode || 0
      if (key == 13) {
        e.preventDefault()
      }
    }
    this.maxPathApiCount = 0
    this.successMaxApiCount = 0
    this.fbxPathApiCount = 0
    this.successFbxApiCount = 0
    this.archPathApiCount = 0
    this.successArchApiCount = 0
    this.thumbPathApiCount = 0
    this.successThumbApiCount = 0
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      formValues: { team },
    } = this.state
    const {
      lanId,
      saveTemplate,
      saveTemplateSuccess,
      showNotification,
      ingestTemplate,
      onClose = () => {},
      onRefresh = () => {},
    } = this.props
    this.setState(
      {
        isFormSubmitted: true,
        hasError: false,
      },
      () => {
        const {
          isMaxPathValidated,
          isThumbPathVaildated,
          isFbxPathValidated,
          isArchPathValidated,
          isMaxPathDisabled,
          isThumbPathDisabled,
          isFbxPathDisabled,
          isArchPathDisabled,
          maxPath,
          thumbPath,
          fbxPath,
          archPath,
        } = this.state
        if (
          (isMaxPathValidated || isMaxPathDisabled) &&
          (isThumbPathVaildated || isThumbPathDisabled) &&
          (isFbxPathValidated || isFbxPathDisabled || !fbxPath) &&
          (team === 'Marketing_Non_Cyclic'
            ? isArchPathValidated || isArchPathDisabled || !archPath
            : true)
        ) {
          const postData = {
            ...this.state.formValues,
            initiated_by: lanId,
            new_ingestion: false,
            max_file_path: maxPath,
            thumbnail_file_path: thumbPath,
            architecture_file_path: archPath,
            fbx_file_path: fbxPath,
          }
          saveTemplate(postData)
            .then((res) => {
              if (res && res.data && res.data.status === 'success') {
                ingestTemplate(postData)
                saveTemplateSuccess({})
                this.setState({
                  formValues: {
                    team: '',
                    max_file_path: '',
                    thumbnail_file_path: '',
                    tags: [],
                    template_name: '',
                  },
                  isFormSubmitted: false,
                  isMaxPathValidated: false,
                  isThumbPathVaildated: false,
                  hasError: false,
                  maxPath: '',
                  thumbPath: '',
                  fbxPath: '',
                  archPath: '',
                })
                showNotification(
                  true,
                  'Template Ingestion has been initiated succesfully',
                  'success'
                )
                setTimeout(() => {
                  showNotification(false, '', '')
                }, 2000)
                onClose()
                onRefresh()
              } else {
                this.setState({
                  hasError: true,
                })
              }
            })
            .catch((err) => {
              this.setState({
                hasError: true,
              })
            })
        }
      }
    )
  }

  handleMaxFilePath = (e) => {
    const value = e.target.value
    if (value === '') {
      this.setState({
        isMaxPathValidated: false,
        isMaxPathLoading: false,
        maxPathError: 'Please provide max file path',
      })
    }
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          max_file_path: value,
        },
        maxPath: value,
        isMaxPathLoading: value !== '',
      },
      () => {
        if (value !== '') this.handleDebounceMaxPath(value)
      }
    )
  }

  handleDebounceMaxPath = _.debounce((value) => {
    this.maxPathApiCount = this.maxPathApiCount + 1
    this.props
      .getFilePathValidation(`max_file_path=${value}`)
      .then((res) => {
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        this.successMaxApiCount = this.successMaxApiCount + 1
        if (this.successMaxApiCount === this.maxPathApiCount) {
          this.setState({
            isMaxPathValidated: isValid,
            maxPathError: error,
            isMaxPathLoading: false,
          })
          this.maxPathApiCount = 0
          this.successMaxApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isMaxPathValidated: false,
          maxPathError: 'Something went wrong, please contact support!',
          isMaxPathLoading: false,
        })
        this.maxPathApiCount = 0
        this.successMaxApiCount = 0
      })
  }, 700)

  handleFbxPath = (e) => {
    const value = e.target.value
    if (value === '') {
      this.setState({
        isFbxPathValidated: false,
        isFbxPathLoading: false,
        fbxPathError: 'Please provide fbx path',
      })
    }
    this.setState(
      {
        fbxPath: value,
        isFbxPathLoading: value !== '',
      },
      () => {
        if (value !== '') this.handleDebounceFbxPath(value)
      }
    )
  }

  handleDebounceFbxPath = _.debounce((value) => {
    this.fbxPathApiCount = this.fbxPathApiCount + 1
    this.props
      .getFilePathValidation(`fbx_file_path=${value}`)
      .then((res) => {
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        this.successFbxApiCount = this.successFbxApiCount + 1
        if (this.fbxPathApiCount === this.successFbxApiCount) {
          this.setState({
            isFbxPathValidated: isValid,
            fbxPathError: error,
            isFbxPathLoading: false,
          })
          this.fbxPathApiCount = 0
          this.successFbxApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isFbxPathValidated: false,
          fbxPathError: 'Something went wrong please contact support!',
          isFbxPathLoading: false,
        })
        this.fbxPathApiCount = 0
        this.successFbxApiCount = 0
      })
  }, 700)

  handleArchPath = (e) => {
    const value = e.target.value
    if (value === '') {
      this.setState({
        isArchPathLoading: false,
        isArchPathValidated: false,
        archPathError: 'Please provide architecture file path',
      })
    }
    this.setState(
      {
        archPath: value,
        isArchPathLoading: value !== '',
      },
      () => {
        if (value !== '') this.handleDebounceArchPath(value)
      }
    )
  }

  handleDebounceArchPath = _.debounce((value) => {
    this.archPathApiCount = this.archPathApiCount + 1
    this.props
      .getFilePathValidation(`architecture_file_path=${value}`)
      .then((res) => {
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        this.successArchApiCount = this.successArchApiCount + 1
        if (this.successArchApiCount === this.archPathApiCount) {
          this.setState({
            isArchPathValidated: isValid,
            archPathError: error,
            isArchPathLoading: false,
          })
          this.successArchApiCount = 0
          this.archPathApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isArchPathValidated: false,
          archPathError: 'Something went wrong please contact support!',
          isArchPathLoading: false,
        })
        this.successArchApiCount = 0
        this.archPathApiCount = 0
      })
  }, 700)

  handleThumbPath = (e) => {
    const value = e.target.value
    if (value === '') {
      this.setState({
        isThumbPathLoading: false,
        isThumbPathVaildated: false,
        thumbnailPathError: 'Please provide thumbnail path',
      })
    }
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          thumbnail_file_path: value,
        },
        thumbPath: value,
        isThumbPathLoading: value !== '',
      },
      () => {
        if (value !== '') this.handleDebounceThumbPath(value)
      }
    )
  }

  handleDebounceThumbPath = _.debounce((value) => {
    this.thumbPathApiCount = this.thumbPathApiCount + 1
    this.props
      .getFilePathValidation(`thumbnail_file_path=${value}`)
      .then((res) => {
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        this.successThumbApiCount = this.successThumbApiCount + 1
        if (this.thumbPathApiCount === this.successThumbApiCount) {
          this.setState({
            isThumbPathVaildated: isValid,
            thumbnailPathError: error,
            isThumbPathLoading: false,
          })
          this.thumbPathApiCount = 0
          this.successThumbApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isThumbPathVaildated: false,
          thumbnailPathError: 'Something went wrong please contact support!',
          isThumbPathLoading: false,
        })
        this.thumbPathApiCount = 0
        this.successThumbApiCount = 0
      })
  }, 700)

  render() {
    const {
      formValues,
      isMaxPathValidated,
      isThumbPathVaildated,
      isFbxPathValidated,
      isArchPathValidated,
      isFormSubmitted,
      hasError,
      isThumbPathDisabled,
      isMaxPathDisabled,
      isFbxPathDisabled,
      isArchPathDisabled,
      thumbPath,
      maxPath,
      fbxPath,
      archPath,
      maxPathError,
      fbxPathError,
      thumbnailPathError,
      archPathError,
      isMaxPathLoading,
      isThumbPathLoading,
      isFbxPathLoading,
      isArchPathLoading,
      formValues: { team },
    } = this.state
    const {
      fullName,
      saveTemplateDetails,
      classes,
      templateData = {},
      onClose = () => {},
    } = this.props

    return (
      <Box>
        {hasError && (
          <AlertComponent
            severity="error"
            title="Error"
            sx={{ width: '50%', marginBottom: '20px' }}
          >
            <ui>
              {saveTemplateDetails &&
              saveTemplateDetails.error &&
              saveTemplateDetails.error.errorMessages &&
              saveTemplateDetails.error.errorMessages.length ? (
                saveTemplateDetails.error.errorMessages.map((err, i) => {
                  return <li key={i}>{err}</li>
                })
              ) : (
                <li>Couldn't save the template, Please contact support</li>
              )}
            </ui>
          </AlertComponent>
        )}
        <form
          onSubmit={this.handleSubmit}
          id="refresh-template"
          ref={(elem) => (this.fromRef = elem)}
        >
          <div className={classes.formContainer}>
            <div className={classes.header}>
              <Typography label="Template Refresh" variant="h6" />
              <CustomIconButton
                className={classes.closePlacement}
                onClick={onClose}
                label={'Close'}
              >
                <Close />
              </CustomIconButton>
            </div>
            <FormWrapper label="Created By :">
              <FormTextField value={fullName} disabled fullWidth size="small" />
            </FormWrapper>
            <FormWrapper label="Business Department * :">
              <Box>
                <FormTextField
                  value={templateData?.team}
                  disabled
                  fullWidth
                  size="small"
                />
              </Box>
            </FormWrapper>
            <FormWrapper label="Name of template * :">
              <FormTextField
                value={templateData?.template_name}
                disabled
                fullWidth
                size="small"
              />
            </FormWrapper>
            <FormWrapper label="Max file Path * :">
              <Box
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isMaxPathValidated
                      ? 'green !important'
                      : 'auto',
                  },
                }}
                className={classes.boxStyle}
              >
                <FormTextField
                  value={maxPath}
                  onChange={this.handleMaxFilePath}
                  required
                  fullWidth
                  disabled={isMaxPathDisabled}
                  error={
                    (maxPath !== '' &&
                      !isMaxPathValidated &&
                      !isMaxPathDisabled) ||
                    (maxPath === '' && isFormSubmitted && !isMaxPathDisabled)
                  }
                  size="small"
                  helperText={
                    maxPath !== '' && !isMaxPathValidated && !isMaxPathDisabled
                      ? maxPathError
                      : maxPath === '' && isFormSubmitted && !isMaxPathDisabled
                      ? 'Please provide max file path'
                      : ''
                  }
                />
                {isMaxPathDisabled && (
                  <CustomButton
                    startIcon={<EditIcon />}
                    label="Edit"
                    sx={{ marginLeft: '10px', marginBottom: '10px' }}
                    onClick={() => {
                      this.setState({
                        isMaxPathDisabled: false,
                        isMaxPathValidated: true,
                      })
                    }}
                  />
                )}
                {isMaxPathLoading && (
                  <Box sx={{ marginLeft: '10px' }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
            </FormWrapper>
            <FormWrapper label="Low poly file Path :">
              <Box
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isFbxPathValidated
                      ? 'green !important'
                      : 'auto',
                  },
                }}
                className={classes.boxStyle}
              >
                <FormTextField
                  value={fbxPath}
                  onChange={this.handleFbxPath}
                  required
                  fullWidth
                  disabled={isFbxPathDisabled}
                  error={
                    fbxPath !== '' && !isFbxPathValidated && !isFbxPathDisabled
                  }
                  size="small"
                  helperText={
                    fbxPath !== '' && !isFbxPathValidated && !isFbxPathDisabled
                      ? fbxPathError
                      : ''
                  }
                />
                {isFbxPathDisabled && (
                  <CustomButton
                    startIcon={<EditIcon />}
                    label="Edit"
                    sx={{ marginLeft: '10px', marginBottom: '10px' }}
                    onClick={() => {
                      this.setState({
                        isFbxPathDisabled: false,
                        isFbxPathValidated: true,
                      })
                    }}
                  />
                )}
                {isFbxPathLoading && (
                  <Box sx={{ marginLeft: '10px' }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
            </FormWrapper>
            {templateData?.team === 'Marketing_Non_Cyclic' && (
              <FormWrapper label="Template architecture max file path :">
                <Box
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isArchPathValidated
                        ? 'green !important'
                        : 'auto',
                    },
                  }}
                  className={classes.boxStyle}
                >
                  <FormTextField
                    value={archPath}
                    onChange={this.handleArchPath}
                    required
                    fullWidth
                    disabled={isArchPathDisabled}
                    error={
                      archPath !== '' &&
                      !isArchPathValidated &&
                      !isArchPathDisabled
                    }
                    size="small"
                    helperText={
                      archPath !== '' &&
                      !isArchPathValidated &&
                      !isArchPathDisabled
                        ? archPathError
                        : ''
                    }
                  />

                  {isArchPathDisabled && (
                    <CustomButton
                      startIcon={<EditIcon />}
                      label="Edit"
                      sx={{ marginLeft: '10px', marginBottom: '10px' }}
                      onClick={() => {
                        this.setState({
                          isArchPathDisabled: false,
                          isArchPathValidated: true,
                        })
                      }}
                    />
                  )}
                  {isArchPathLoading && (
                    <Box sx={{ marginLeft: '10px' }}>
                      <CircularProgress size={25} />
                    </Box>
                  )}
                </Box>
              </FormWrapper>
            )}
            <FormWrapper label="Thumbnail file Path * :">
              <Box
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isThumbPathVaildated
                      ? 'green !important'
                      : 'auto',
                  },
                }}
                className={classes.boxStyle}
              >
                <FormTextField
                  value={thumbPath}
                  onChange={this.handleThumbPath}
                  required
                  fullWidth
                  disabled={isThumbPathDisabled}
                  error={
                    (thumbPath !== '' &&
                      !isThumbPathVaildated &&
                      !isThumbPathDisabled) ||
                    (thumbPath === '' &&
                      isFormSubmitted &&
                      !isThumbPathDisabled)
                  }
                  size="small"
                  helperText={
                    thumbPath !== '' &&
                    !isThumbPathVaildated &&
                    !isThumbPathDisabled
                      ? thumbnailPathError
                      : thumbPath === '' &&
                        isFormSubmitted &&
                        !isThumbPathDisabled
                      ? 'Please provide thumbnail path'
                      : ''
                  }
                />

                {isThumbPathDisabled && (
                  <CustomButton
                    startIcon={<EditIcon />}
                    label="Edit"
                    onClick={() => {
                      this.setState({
                        isThumbPathDisabled: false,
                        isThumbPathVaildated: true,
                      })
                    }}
                    sx={{ marginLeft: '10px', marginBottom: '10px' }}
                  />
                )}
                {isThumbPathLoading && (
                  <Box sx={{ marginLeft: '10px' }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
            </FormWrapper>
            <FormWrapper label="Tags :" styles={classes.tagsHeight}>
              <InputTags
                selectedTags={(tags) => {
                  this.setState({
                    formValues: { ...this.state.formValues, tags },
                  })
                }}
                id="tags"
                name="tags"
                variant="outlined"
                size="small"
                sx={{
                  width: '340px',
                  '& .MuiInputBase-root': {
                    display: 'flex !important',
                    flexWrap: 'wrap !important',
                  },
                }}
                tags={templateData?.template_tags}
              />
            </FormWrapper>
            <FormWrapper label="">
              <QaPluginStatusDisplay
                qaRunStatus={templateData?.qa_plugin_status}
                lastRunDate={readableDateAndTimeDigitFormat(
                  templateData?.qa_plugin_last_run_date
                )}
                errors={templateData?.plugin_qa_report?.error_list?.map(
                  (error) => error.description
                )}
              />
            </FormWrapper>
          </div>
          <div className={classes.actionBar}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '14px 40px',
              }}
            >
              <CustomButton
                type="submit"
                color="primary"
                label="Refresh Template"
                size="large"
                disabled={
                  !(
                    (isMaxPathValidated || isMaxPathDisabled) &&
                    (isThumbPathVaildated || isThumbPathDisabled) &&
                    (isFbxPathValidated || isFbxPathDisabled || !fbxPath) &&
                    (team === 'Marketing_Non_Cyclic'
                      ? isArchPathValidated || isArchPathDisabled || !archPath
                      : true)
                  )
                }
              />
            </Box>
          </div>
        </form>
      </Box>
    )
  }
}

const mapStateToProps = ({
  auth: { fullName = '', lanId = '', firstName = '' },
  uploadTemplate: {
    fetchingFileValidation,
    isFilePathValid,
    saveTemplateDetails,
  },
} = {}) => {
  return {
    fullName,
    lanId,
    firstName,
    fetchingFileValidation,
    isFilePathValid,
    saveTemplateDetails,
  }
}

export default connect(mapStateToProps, {
  getFilePathValidation,
  saveTemplate,
  saveTemplateSuccess,
  showNotification,
  ingestTemplate,
})(withStyles(styles)(TemplateRefresh))
