import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AssetScaleHeader from './AssetScaleHeader'
import AssetScaleContent from './AssetScaleContent'
import AssetScaleFooter from './AssetScaleFooter'
import { setActiveOperation } from '../../store/operations/actionCreator'
import ACTIONS from '../../constants/actions'
import { SCALE_CONFIG } from '../../constants/common'

const styles = (theme) => ({
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    padding: 15,
    borderRadius: 4,
  },
  paper: {
    height: '100%',
  },
})

export class AssetScaleBox extends Component {
  state = {
    scale: {
      x: 1,
      y: 1,
      z: 1,
    },
    isUniformityChecked: true,
  }

  componentDidMount() {
    const { initialScale = {} } = this.props
    this.setState({
      scale: {
        x: initialScale.x || 1,
        y: initialScale.y || 1,
        z: initialScale.z || 1,
      },
    })
  }

  handleUniformScale = (axis, type) => (event, newRawValue) => {
    event && event.nativeEvent && event.nativeEvent.stopImmediatePropagation()
    const { scale } = this.state
    const newValue =
      type === 'slider'
        ? newRawValue
        : event.target.value === ''
        ? ''
        : Number(event.target.value)
    const diff = Number(Number(scale[axis] - newValue).toFixed(2))
    if (newValue < scale[axis]) {
      // decrease
      const newScale = {}
      const newX = Number(Number(scale.x - diff).toFixed(2))
      const newY = Number(Number(scale.y - diff).toFixed(2))
      const newZ = Number(Number(scale.z - diff).toFixed(2))
      const ifAnyMin =
        scale.x <= SCALE_CONFIG.MIN_SCALE ||
        scale.y <= SCALE_CONFIG.MIN_SCALE ||
        scale.z <= SCALE_CONFIG.MIN_SCALE
      if (ifAnyMin) {
        return
      }
      newScale.x =
        newX <= SCALE_CONFIG.MIN_SCALE ? SCALE_CONFIG.MIN_SCALE : newX
      newScale.y =
        newY <= SCALE_CONFIG.MIN_SCALE ? SCALE_CONFIG.MIN_SCALE : newY
      newScale.z =
        newZ <= SCALE_CONFIG.MIN_SCALE ? SCALE_CONFIG.MIN_SCALE : newZ

      this.setState(
        {
          scale: newScale,
        },
        this.handleChange
      )
    } else if (newValue > scale[axis]) {
      // Increase
      const newScale = {}
      const newX = Number(Number(scale.x - diff).toFixed(2))
      const newY = Number(Number(scale.y - diff).toFixed(2))
      const newZ = Number(Number(scale.z - diff).toFixed(2))
      const ifAnyMax =
        scale.x >= SCALE_CONFIG.MAX_SCALE ||
        scale.y >= SCALE_CONFIG.MAX_SCALE ||
        scale.z >= SCALE_CONFIG.MAX_SCALE
      if (ifAnyMax) {
        return
      }
      newScale.x =
        newX >= SCALE_CONFIG.MAX_SCALE ? SCALE_CONFIG.MAX_SCALE : newX
      newScale.y =
        newY >= SCALE_CONFIG.MAX_SCALE ? SCALE_CONFIG.MAX_SCALE : newY
      newScale.z =
        newZ >= SCALE_CONFIG.MAX_SCALE ? SCALE_CONFIG.MAX_SCALE : newZ
      this.setState(
        {
          scale: newScale,
        },
        this.handleChange
      )
    }
  }

  handleSliderChange = (axis) => (event, newValue) => {
    event && event.nativeEvent && event.nativeEvent.stopImmediatePropagation()
    const { scale, isUniformityChecked } = this.state
    if (!isUniformityChecked) {
      this.setState(
        {
          scale: { ...scale, [axis]: newValue },
        },
        this.handleChange
      )
    }
  }

  handleInputChange = (axis) => (event) => {
    event && event.nativeEvent && event.nativeEvent.stopImmediatePropagation()
    const { scale, isUniformityChecked } = this.state
    const newValue = event.target.value === '' ? '' : Number(event.target.value)

    if (!isUniformityChecked) {
      this.setState(
        {
          scale: {
            ...scale,
            [axis]: newValue,
          },
        },
        this.handleChange
      )
    }
  }

  handleBlur = (axis) => (e) => {
    e && e.nativeEvent && e.nativeEvent.stopImmediatePropagation()
    const { scale } = this.state
    if (scale[axis] < SCALE_CONFIG.MIN_SCALE) {
      this.setState(
        {
          scale: { ...scale, [axis]: SCALE_CONFIG.MIN_SCALE },
        },
        this.handleChange
      )
    } else if (scale[axis] > SCALE_CONFIG.MAX_SCALE) {
      this.setState(
        {
          scale: { ...scale, [axis]: SCALE_CONFIG.MAX_SCALE },
        },
        this.handleChange
      )
    }
  }

  handleReset = () => {
    this.setState(
      {
        scale: {
          x: 1,
          y: 1,
          z: 1,
        },
      },
      this.handleChange
    )
  }

  handleUniformityChange = (event) => {
    event && event.nativeEvent && event.nativeEvent.stopImmediatePropagation()
    this.setState({
      isUniformityChecked: event.target.checked,
    })
  }

  handleClose = (event) => {
    event && event.nativeEvent && event.nativeEvent.stopImmediatePropagation()
    const { setActiveOperation, onChange } = this.props
    const { scale } = this.state
    setActiveOperation(ACTIONS.SCALE_ASSET, {
      status: false,
      value: { x: 1, y: 1, z: 1 },
    })
    onChange(scale, true, false, true) // AddToStack true
    this.setState({
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
    })
  }

  handleChange = () => {
    const { onChange } = this.props
    const { scale } = this.state
    onChange(scale, true)
  }

  render() {
    const { classes } = this.props
    const { scale, isUniformityChecked } = this.state
    return (
      <Paper
        variant="outlined"
        classes={{
          root: classes.paper,
        }}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <AssetScaleHeader
              onReset={this.handleReset}
              onClose={this.handleClose}
            />
          </Grid>
          <Grid item xs={12}>
            <AssetScaleContent
              scale={{ ...scale }}
              handleSliderChange={
                isUniformityChecked
                  ? this.handleUniformScale
                  : this.handleSliderChange
              }
              handleInputChange={
                isUniformityChecked
                  ? this.handleUniformScale
                  : this.handleInputChange
              }
              handleBlur={this.handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <AssetScaleFooter
              isUniformityChecked={isUniformityChecked}
              handleUniformityChange={this.handleUniformityChange}
              onClose={this.handleClose}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

AssetScaleBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
const NOOP = () => {}
AssetScaleBox.defaultProps = {
  onChange: NOOP,
  onClose: NOOP,
}
const mapStateToProps = (state) => ({
  cartData: state.cart,
  isRightDrawerOpen: state.Board.isRightDrawerOpen,
  activeOperations: state.activeOperations,
  cart: state.cart,
})
export default connect(mapStateToProps, { setActiveOperation })(
  withStyles(styles)(AssetScaleBox)
)
