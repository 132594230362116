import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import {
  fetchSimilarAssets,
  clearSimilarAssets,
} from '../../store/explorer/actionCreator'
import DataList from '../DataList'
import DraggableAssetCard from '../assetExplorerCard/DraggableAssetCard'
import SearchAssetInfo from '../assetInfo/SearchAssetInfo'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  card: {
    margin: '0px 10px',
    marginBottom: '20px',
  },
  paper: {
    padding: 20,
  },
  header: { display: 'flex', flexDirection: 'row' },
  closeButton: { textAlign: 'right', flex: 1 },
  titleBar: { flex: 4 },
  noResultsFound: {
    padding: 20,
    textAlign: 'center',

    width: '100%',
  },
  dataListContainer: {
    height: 'Calc(100vh - 150px) !important',
    marginTop: '10px',
    padding: '0 10px',
  },
  masterWrapper: {
    width: '100%',
    display: 'flex',

    position: 'sticky',
    top: 0,
    backgroundColor: '#F9F9F9',
    padding: '5px 5px 10px',
    zIndex: 9,
    marginBottom: 20,
    maxHeight: 190,
    alignContent: 'stretch',
    zIndex: 13,
  },
  content: {
    width: '100%',
  },
})

class SimilarAssets extends React.Component {
  state = {
    loading: false,
  }
  componentDidMount() {
    this.fetchAssets()
  }
  componentDidUpdate(prevProps) {
    if (this.props.data.tcin != prevProps.data.tcin) {
      this.props.clearSimilarAssets()
      this.fetchAssets()
    }
  }

  componentWillUnmount() {
    this.props.clearSimilarAssets()
  }

  fetchAssets() {
    const { fetchSimilarAssets, data } = this.props
    const tcin = data.tcin || data.value
    this.setState({ loading: true })
    if (tcin && tcin !== '') {
      fetchSimilarAssets(100, 1, '', 'ALL', {}, false, {
        ...data,
        tcin: tcin,
        asset_type: data.asset_type,
      })
        .then(() => {
          this.setState({ loading: false })
        })
        .catch(() => {
          this.setState({ loading: false })
        })
    }
  }

  close = (e) => {
    const { clearSimilarAssets, closeSimilarSearch = () => {} } = this.props
    clearSimilarAssets()
    closeSimilarSearch(e)
  }
  interSectionCallback = () => {}
  render() {
    const { classes, similarAssets, data, handleCartActions, isArchAssets } =
      this.props
    const { loading } = this.state
    const assetInfoData = [
      { key: isArchAssets ? 'Asset' : 'TCIN', value: data.tcin },
      {
        key: 'Product Type',
        value: data.product_type,
      },
      { key: 'Dimension', value: data.dimensions },
      { key: 'Brand', value: data.brand_name },
      { key: 'Material', value: data.materials },
    ]

    return (
      <DataList
        showProgressBar={loading}
        interSectionCallback={this.interSectionCallback}
        containerClass={classes.dataListContainer}
      >
        <>
          <div className={classes.masterWrapper}>
            <DraggableAssetCard
              data={{
                ...data,
                tcin: data.value || data.tcin,
                short_description: data.short_description,
              }}
              maxWidth={130}
              minWidth={130}
              preventDefault
            />
            <div className={classes.content}>
              <SearchAssetInfo
                title={data.short_description}
                data={assetInfoData}
                handleClickAction={this.close}
                specifyHeight={28}
              />
            </div>
          </div>

          {similarAssets &&
            similarAssets.map((item) => {
              return (
                <div className={classes.card}>
                  <DraggableAssetCard
                    data={{
                      ...item,
                      tcin: item.value || item.tcin,
                      short_description: item.short_description,
                    }}
                    maxWidth={130}
                    minWidth={130}
                    preventDefault
                    handleCartActions={handleCartActions}
                  />
                </div>
              )
            })}

          {!loading && !similarAssets.length ? (
            <div className={classes.noResultsFound}>No Results found</div>
          ) : null}
        </>
      </DataList>
    )
  }
}

const mapStateToProps = ({
  explorer: { similarAssets, totalSimilarAssets },
}) => {
  return {
    similarAssets,
    totalSimilarAssets,
  }
}
export default connect(mapStateToProps, {
  fetchSimilarAssets,
  clearSimilarAssets,
})(withStyles(styles)(SimilarAssets))
