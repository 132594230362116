import { isEmpty } from 'lodash'
import { DEFAULT_NOISE, DEFAULT_TIME } from '../../constants/common'
import {
  SET_RENDER_DATA,
  SET_CONTROLS_POSITION,
  SET_SCENE_DATA,
  RESET_SCENE_DATA,
  SET_RENDER_FORM_DATA,
  SHOW_SCENE_EDIT_FORM,
  SET_DEFAULT_NOISE_BASED_ON_RENDERER,
  SET_SCENE_MODE,
  SET_NEW_CAMERA,
  SET_CAMERA_SNAPSHOT,
  DELETE_CAMERA_VIEW,
  SAVE_SCENE_INITIATED,
  SAVE_SCENE_COMPLETE,
} from './ActionType'

export function setRenderDetails(data) {
  return {
    type: SET_RENDER_DATA,
    data: { ...data },
  }
}

export function setRenderFormData(data) {
  return {
    type: SET_RENDER_FORM_DATA,
    data,
  }
}

export function setControlsPosition(data = 30) {
  return {
    type: SET_CONTROLS_POSITION,
    data,
  }
}
export function setSceneData(data) {
  return {
    type: SET_SCENE_DATA,
    data,
  }
}

export function setDefaultPrefNoise(data) {
  return {
    type: SET_DEFAULT_NOISE_BASED_ON_RENDERER,
    data,
  }
}

export function resetSceneData() {
  return {
    type: RESET_SCENE_DATA,
  }
}

export function getRenderPageCamera(data) {
  const { selected_camera: selectedKey, templateDefaultCamera, camera } = data
  let selectedCameraSettings = { ...camera }
  if (
    !isEmpty(data.preferred_cameras) &&
    !isEmpty(data.preferred_cameras[selectedKey])
  ) {
    selectedCameraSettings = { ...data.preferred_cameras[selectedKey] }
  } else if (!isEmpty(data.render_camera) && selectedKey === 'default') {
    selectedCameraSettings = { ...data.render_camera }
  } else if (!isEmpty(templateDefaultCamera)) {
    selectedCameraSettings = { ...templateDefaultCamera }
  }
  return selectedCameraSettings
}

export function getRenderDetails(preferredRenderDetails, selectedCamera) {
  let key = selectedCamera
  if (!preferredRenderDetails) {
    return {
      passes: 0,
      noise: DEFAULT_NOISE,
      x: 0,
      y: 0,
      width: null,
      height: null,
      resizeRatio: null,
      screenWidth: 100,
      screenHeight: 100,
      resWidth: 3001,
      resHeight: 3001,
      locked: false,
      time: DEFAULT_TIME,
    }
  }
  if (
    isEmpty(preferredRenderDetails[key]) ||
    !preferredRenderDetails[key].resize_ratio
  ) {
    key = 'default'
  }

  if (
    !isEmpty(preferredRenderDetails[key]) &&
    preferredRenderDetails[key].resize_ratio
  ) {
    const data = preferredRenderDetails[key]

    return {
      passes: data.pass_limit,
      noise: data.noise_level,
      x: data.crop_x,
      y: data.crop_y,
      resizeRatio: data.resize_ratio,
      resWidth: data.scene_width,
      resHeight: data.scene_height,
      sceneWidth: data.scene_width,
      sceneHeight: data.scene_height,
      width: data.image_width,
      height: data.image_height,
      screenHeight: data.screen_height,
      screenWidth: data.screen_width,
      locked: data.locked,
      time: data.time_limit_minutes,
    }
  }
}
export function showExperienceForm(show = true, copyScene = false) {
  return {
    type: SHOW_SCENE_EDIT_FORM,
    data: { show, copyScene },
  }
}
export function setSceneMode(value) {
  return {
    type: SET_SCENE_MODE,
    data: value,
  }
}
export function setNewCamera(idx) {
  return { type: SET_NEW_CAMERA, data: idx }
}

export function setCameraSnapshot(url) {
  return {
    type: SET_CAMERA_SNAPSHOT,
    data: url,
  }
}

export function deleteCameraView(camera) {
  return {
    type: DELETE_CAMERA_VIEW,
    data: camera,
  }
}

export function saveSceneInitiated() {
  return {
    type: SAVE_SCENE_INITIATED,
  }
}
export function saveSceneComplete() {
  return {
    type: SAVE_SCENE_COMPLETE,
  }
}
