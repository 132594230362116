import React, { useRef, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@mui/styles'

import Chip from '@mui/material/Chip'
import CloseIcon from '@material-ui/icons/Close'
import leftbutton from '../../images/filter/caret_left.svg?url'
import rightbutton from '../../images/filter/caret_right.svg?url'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    marginLeft: '5px',
  },
  parent: {
    width: 'Calc(100% - 74px)',
    overflow: 'hidden',
    margin: 'auto',
  },

  sliderContainer: {
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'flex-start',
    scrollBehavior: 'smooth',
  },
  sliderWrapper: {
    display: 'flex',
    alignItems: 'left',
    width: '100%',
  },
  btn: {
    position: 'absolute',
    padding: 5,
    cursor: 'pointer',
    width: 18,
    height: 18,
    border: '1px solid #333',
    textAlign: 'center',
    color: 'grey',
    borderRadius: '50%',
    '& img': {
      verticalAlign: 'middle',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  prev: {
    left: 0,
    top: -1,
  },
  image: {
    height: 10,
  },
  next: {
    right: 0,
    top: -1,
  },
  disable: {
    opacity: '0.5',
    pointerEvents: 'none',
    display: 'none',
  },
  childMin: {
    textAlign: 'center',
    width: 'auto',
    marginRight: 5,
  },
  childMax: {
    textAlign: 'center',
    width: 'auto',
    marginRight: 15,
  },
}))

const CustomChip = withStyles((theme) => ({
  root: { background: '#D6D6D6', height: '28px' },
  label: {
    fontSize: '12px',
    color: '#333333',
    lineHeight: '16px',
    // width: 45,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline',
  },
  deleteIcon: { fontSize: '12px', color: '#333333' },
}))(Chip)

function Slider(props) {
  const refElem = useRef(null)
  let offsetWidthValue = 0
  let scrollWidthValue = null
  const [prevDisable, setPrevDisable] = useState(true)
  const [nextDisable, setNextDisable] = useState(true)
  const checkButtons = () => {
    setPrevDisable(refElem.current.scrollLeft <= 0 ? true : false)
    setNextDisable(
      refElem.current.scrollLeft + offsetWidthValue >= scrollWidthValue
        ? true
        : false
    )
  }
  useEffect(function () {
    offsetWidthValue = refElem.current.offsetWidth
    scrollWidthValue = refElem.current.scrollWidth
    checkButtons()
  })
  const classes = useStyles()
  const arrowWidth = (prevDisable ? 0 : 37) + (nextDisable ? 0 : 37)
  return (
    <div
      className={classes.parent}
      style={{
        width: `Calc(100% - ${arrowWidth}px)`,
        paddingRight: prevDisable ? 37 : 0,
        paddingLeft: nextDisable && !prevDisable ? 37 : 0,
      }}
    >
      <div className={classes.sliderContainer} ref={refElem}>
        <div className={classes.sliderWrapper}>{props.children}</div>
        <div
          className={`${classes.btn} ${classes.prev} ${
            prevDisable ? classes.disable : ''
          }`}
          disabled={prevDisable}
          onClick={() => {
            refElem.current.scrollLeft -= 83
            setTimeout(checkButtons, 250)
          }}
        >
          <img src={leftbutton} alt="leftButton" />
        </div>
        <div
          className={`${classes.btn} ${classes.next} ${
            nextDisable ? classes.disable : ''
          }`}
          disabled={nextDisable}
          onClick={() => {
            refElem.current.scrollLeft += 83
            setTimeout(checkButtons, 250)
          }}
        >
          <img src={rightbutton} className={classes.image} alt="rightButton" />
        </div>
      </div>
    </div>
  )
}

export function SummaryList({
  filterLists = {},
  handleFilters,
  maximizedView,
  ...rest
}) {
  const classes = useStyles()
  const chips = []
  const renderChips = []
  const onChipClick = (key, id) => (e) => {
    e.nativeEvent.preventDefault()
    handleFilters(key, id, false)
  }

  Object.keys(filterLists).map((item) => {
    filterLists[item].map(({ name, isSelected, id }) => {
      if (isSelected) {
        renderChips.push(
          <div
            key={name}
            className={maximizedView ? classes.childMax : classes.childMin}
          >
            <CustomChip
              className={classes.chipContainer}
              label={name}
              onClick={onChipClick(item, id)}
              onDelete={onChipClick(item, id)}
              deleteIcon={<CloseIcon fontSize="small" />}
            />
          </div>
        )
      }
    })
  })
  return (
    <>
      <div className={classes.root}>
        <Slider>{renderChips}</Slider>
      </div>
    </>
  )
}

export default SummaryList
