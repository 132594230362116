import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'

import FilterListsWrapper from '../Filter/FilterListsWrapper'
import FilterAssets from '../Filter/FilterAssets'
import ExcludeHighPoly from '../drawer/ExcludeHighPoly'

const useStyles = makeStyles(() => ({
  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}))

const ProductsAndPropsFilters = (props) => {
  const styles = useStyles()
  const {
    filterLists,
    handleFilters,
    selectedFilters,
    totalAssetsCount,
    isMinified,
    handleExcluded,
    excludeRTA,
    excludeHighPoly,
    searchBy,
  } = props
  return (
    <Box>
      <Box className={styles.filterWrapper}>
        {!isMinified && (
          <Box marginTop={2}>
            <FilterAssets
              filterLists={filterLists}
              totalAssetsCount={totalAssetsCount}
            />
          </Box>
        )}
        <Box marginTop={isMinified ? 0 : 2}>
          {searchBy !== 'GALLERY' && (
            <>
              <ExcludeHighPoly
                isChecked={excludeHighPoly}
                handleExcluded={(e) => handleExcluded(e, 'HP')}
                isMinified={isMinified}
                displayText={'Exclude high poly Unavailable'}
              />
              {searchBy !== 'PROP' && (
                <ExcludeHighPoly
                  isChecked={excludeRTA}
                  handleExcluded={(e) => handleExcluded(e, 'RTA')}
                  isMinified={isMinified}
                  displayText={'Exclude Pure RTA available'}
                />
              )}
            </>
          )}
          <FilterListsWrapper
            filterLists={filterLists}
            handleFilters={handleFilters}
            selectedFilters={selectedFilters}
            isMinified={isMinified}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = ({
  explorer: { filterLists = [], libraryAssetsCount = 0, totalAssetsCount },
}) => {
  return {
    filterLists,
    libraryAssetsCount,
    totalAssetsCount,
  }
}

export default connect(mapStateToProps)(ProductsAndPropsFilters)
