import React, { Component } from 'react'
import { Box } from '@mui/material'
import { connect } from 'react-redux'
import {
  FormTextField,
  LabelAndHelperSelect,
  InputTags,
  CustomButton,
  AlertComponent,
  Typography,
  CustomIconButton,
} from 'cgi-ui-components'
import _ from 'lodash'
import { withStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'

import {
  getBusinessDepartments,
  getFilePathValidation,
  saveTemplate,
  saveTemplateSuccess,
  getProjectTypes,
  ingestTemplate,
  getQaPluginStatus,
} from '../../store/UploadTemplate/ActionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import QaPluginStatusDisplay from './QaPluginStatusDisplay'
import Close from '@mui/icons-material/Close'
import { readableDateAndTimeDigitFormat } from '../../helpers/dataFormats'

const styles = () => ({
  formWrapper: {
    height: '70px',
    width: '100%',
    '& .MuiFormHelperText-root': {
      marginLeft: '0px',
    },
    form: {
      width: '340px',
    },
  },
  alert: { width: '50%', marginBottom: '20px' },
  boxStyle: { display: 'flex', alignItems: 'center' },
  tagsHeight: { height: 'auto !important' },
  actionBar: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    height: 70,
    display: 'flex',
    justifyContent: 'right',
    boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)`,
  },
  formContainer: { height: `calc(100vh - 100px)`, overflow: 'scroll' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
})

const FormWrapper = (props) => {
  return (
    <Box
      className={props.styles}
      sx={{
        height: '70px',
        width: '100%',
        '& .MuiFormHelperText-root': {
          marginLeft: '0px',
        },
        form: {
          width: '340px',
        },
      }}
    >
      <Box sx={{ width: '300px', marginTop: '15px' }}>
        <Typography variant="body2" label={props.label}></Typography>
      </Box>
      {props.children}
    </Box>
  )
}

export class NewTemplate extends Component {
  state = {
    formValues: {
      team: '',
      max_file_path: '',
      thumbnail_file_path: '',
      tags: [],
      template_name: '',
      fbx_file_path: '',
      architecture_file_path: '',
    },
    isFormSubmitted: false,
    isMaxPathValidated: false,
    isLowPolyValidated: false,
    isThumbPathVaildated: false,
    isArchValidated: false,
    isTemplateNameValid: false,
    isBusinessDepartmentValid: false,
    hasError: false,
    thumbnailPathError: null,
    maxPathError: null,
    lowPolyPathError: null,
    archPathError: null,
    showTags: true,
    assetType: 'max',
    qaRunStatus: '',
    qaLastRunDate: '',
    errorList: [],
    isQaStatusValidating: false,
  }

  componentDidMount() {
    this.props.getBusinessDepartments()
    this.props.getProjectTypes()
    this.fromRef.onkeypress = function (e) {
      var key = e.charCode || e.keyCode || 0
      if (key === 13) {
        e.preventDefault()
      }
    }
    this.maxPathApiCount = 0
    this.successMaxApiCount = 0
    this.fbxPathApiCount = 0
    this.successFbxApiCount = 0
    this.archPathApiCount = 0
    this.successArchApiCount = 0
    this.thumbPathApiCount = 0
    this.successThumbApiCount = 0
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      formValues: { team, template_name },
    } = this.state
    const {
      lanId,
      saveTemplate,
      saveTemplateSuccess,
      showNotification,
      ingestTemplate,
      onClose = () => {},
      onNewAdd = () => {},
    } = this.props
    this.setState(
      {
        isBusinessDepartmentValid: team.trim() !== '',
        isTemplateNameValid: template_name.trim() !== '',
        isFormSubmitted: true,
        hasError: false,
        loading: true,
      },
      () => {
        const {
          isTemplateNameValid,
          isBusinessDepartmentValid,
          isMaxPathValidated,
          isThumbPathVaildated,
          isLowPolyValidated,
          isArchValidated,
        } = this.state
        if (
          isTemplateNameValid &&
          isBusinessDepartmentValid &&
          isMaxPathValidated &&
          (isLowPolyValidated || !this.state.formValues.fbx_file_path) &&
          (team === 'Marketing_Non_Cyclic'
            ? isArchValidated || !this.state.formValues.architecture_file_path
            : true) &&
          isThumbPathVaildated
        ) {
          let postData = {
            ...this.state.formValues,
            initiated_by: lanId,
            new_ingestion: true,
          }
          saveTemplate(postData)
            .then((res) => {
              if (res && res.data && res.data.status === 'success') {
                saveTemplateSuccess({})
                this.setState(
                  {
                    formValues: {
                      team: '',
                      max_file_path: '',
                      thumbnail_file_path: '',
                      tags: [],
                      template_name: '',
                      fbx_file_path: '',
                      architecture_file_path: '',
                    },
                    isFormSubmitted: false,
                    isMaxPathValidated: false,
                    isThumbPathVaildated: false,
                    isTemplateNameValid: false,
                    isBusinessDepartmentValid: false,
                    isLowPolyValidated: false,
                    isArchValidated: false,
                    hasError: false,
                    loading: false,
                    showTags: false,
                  },
                  () => {
                    ingestTemplate(postData)
                    showNotification(
                      true,
                      'Template Ingestion has been initiated succesfully',
                      'success'
                    )
                    this.setState({ showTags: true }, () => {
                      onClose()
                      onNewAdd()
                    })
                  }
                )
              } else {
                this.setState(
                  {
                    loading: false,
                    hasError: true,
                  },
                  () => {
                    onClose()
                    onNewAdd()
                  }
                )
              }
            })
            .catch((err) => {
              this.setState({
                loading: false,
                hasError: true,
              })
            })
        }
      }
    )
  }

  handleMaxFilePath = (e) => {
    const value = e.target.value

    if (value === '') {
      this.setState({
        isMaxPathValidated: false,
        maxPathError: '',
        isMaxPathLoading: false,
      })
    }

    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          max_file_path: value,
        },
        isMaxPathLoading: value !== '',
      },
      () => {
        if (value !== '') this.handleDebounceMaxPath(value)
      }
    )
  }

  handleDebounceMaxPath = _.debounce((value) => {
    this.maxPathApiCount = this.maxPathApiCount + 1
    this.props
      .getFilePathValidation(`max_file_path=${value}`)
      .then((res) => {
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        this.successMaxApiCount = this.successMaxApiCount + 1
        if (this.successMaxApiCount === this.maxPathApiCount) {
          this.setState({
            isMaxPathLoading: false,
            isMaxPathValidated: isValid,
            maxPathError: error,
          })
          this.maxPathApiCount = 0
          this.successMaxApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isMaxPathValidated: false,
          maxPathError: 'Something went wrong, please contact support!',
          isMaxPathLoading: false,
        })
        this.maxPathApiCount = 0
        this.successMaxApiCount = 0
      })
  }, 700)

  handleLowPolyPath = (e) => {
    const value = e.target.value
    if (value === '') {
      this.setState({
        isLowPolyValidated: false,
        lowPolyPathError: 'Please provide fbx file path',
        isFbxPathLoading: false,
      })
    }

    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          fbx_file_path: value,
        },
        isFbxPathLoading: value !== '',
      },
      () => {
        if (value !== '') this.handleDebounceFbxPath(value)
      }
    )
  }

  handleDebounceFbxPath = _.debounce((value) => {
    this.fbxPathApiCount = this.fbxPathApiCount + 1
    this.props
      .getFilePathValidation(`fbx_file_path=${value}`)
      .then((res) => {
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        this.successFbxApiCount = this.successFbxApiCount + 1
        if (this.fbxPathApiCount === this.successFbxApiCount) {
          this.setState({
            isLowPolyValidated: isValid,
            lowPolyPathError: error,
            isFbxPathLoading: false,
          })
          this.fbxPathApiCount = 0
          this.successFbxApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isLowPolyValidated: false,
          lowPolyPathError: 'Something went wrong, please contact support!',
          isFbxPathLoading: false,
        })
        this.fbxPathApiCount = 0
        this.successFbxApiCount = 0
      })
  }, 700)

  handleArchPath = (e) => {
    const value = e.target.value
    this.archPathApiCount = this.archPathApiCount + 1
    this.setState({
      formValues: {
        ...this.state.formValues,
        architecture_file_path: value,
      },
      isArchPathLoading: true,
    })
    if (value === '') {
      this.setState({
        isArchValidated: false,
        archPathError: 'Please provide architecture file path',
        isArchPathLoading: false,
      })
      return
    }
    this.props
      .getFilePathValidation(`architecture_file_path=${value}`)
      .then((res) => {
        this.successArchApiCount = this.successArchApiCount + 1
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        if (this.successArchApiCount === this.archPathApiCount) {
          this.setState({
            isArchValidated: isValid,
            archPathError: error,
            isArchPathLoading: false,
          })
          this.successArchApiCount = 0
          this.archPathApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isLowPolyValidated: false,
          archPathError: 'Something went wrong, please contact support!',
          isArchPathLoading: false,
        })
        this.successArchApiCount = 0
        this.archPathApiCount = 0
      })
  }

  handleThumbPath = (e) => {
    const value = e.target.value
    if (value === '') {
      this.setState({
        isThumbPathVaildated: false,
        thumbnailPathError: 'Please provide thumbnail path',
        isThumbPathLoading: false,
      })
    }
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          thumbnail_file_path: value,
        },
        isThumbPathLoading: value !== '',
      },
      () => {
        if (value !== '') this.handleDebounceThumbPath(value)
      }
    )
  }

  handleDebounceThumbPath = _.debounce((value) => {
    this.thumbPathApiCount = this.thumbPathApiCount + 1
    this.props
      .getFilePathValidation(`thumbnail_file_path=${value}`)
      .then((res) => {
        const isValid = res && res.data && res.data.valid
        const error = res && res.data && res.data.validation_error
        this.successThumbApiCount = this.successThumbApiCount + 1
        if (this.thumbPathApiCount === this.successThumbApiCount) {
          this.setState({
            isThumbPathVaildated: isValid,
            isThumbPathLoading: false,
            thumbnailPathError: error,
          })
          this.thumbPathApiCount = 0
          this.successThumbApiCount = 0
        }
      })
      .catch(() => {
        this.setState({
          isThumbPathVaildated: false,
          isThumbPathLoading: false,
          thumbnailPathError: 'Something went wrong please contact support!',
        })
        this.thumbPathApiCount = 0
        this.successThumbApiCount = 0
      })
  }, 700)

  handleInputChange = (e) => {
    const { value, name } = e.target
    if (name === 'team') {
      this.setState({
        formValues: {
          ...this.state.formValues,
          [name]: value,
        },
        isBusinessDepartmentValid: value.trim() !== '',
      })
    } else {
      this.setState({
        formValues: {
          ...this.state.formValues,
          [name]: value,
        },
      })
    }
  }

  onAssetTypeChange = ({ target: { value = 'max' } }) => {
    this.setState({ assetType: value })
  }

  handleInputValidation = () => {
    const { formValues } = this.state
    if (formValues.template_name.length) {
      this.setState({
        isQaStatusValidating: true,
        isTemplateNameValid: formValues.template_name.trim() !== '',
      })
      getQaPluginStatus(formValues.template_name)
        .then(
          ({
            data: { qa_plugin_last_run_date, qa_plugin_status, error_list },
          }) => {
            this.setState({
              isQaStatusValidating: false,
              qaLastRunDate: qa_plugin_last_run_date,
              qaRunStatus: qa_plugin_status,
              errorList: error_list,
            })
          }
        )
        .catch((e) => {
          this.setState({
            isQaStatusValidating: false,
          })
        })
    }
  }

  render() {
    const {
      formValues,
      isMaxPathValidated,
      isThumbPathVaildated,
      isBusinessDepartmentValid,
      isFormSubmitted,
      isTemplateNameValid,
      isLowPolyValidated,
      isArchValidated,
      hasError,
      thumbnailPathError,
      maxPathError,
      archPathError,
      lowPolyPathError,
      isMaxPathLoading,
      isThumbPathLoading,
      isFbxPathLoading,
      isArchPathLoading,
      showTags,
      isQaStatusValidating,
      qaRunStatus,
      qaLastRunDate,
      errorList,
      formValues: { team, template_name },
    } = this.state
    const {
      fullName,
      businessDepartments,
      saveTemplateDetails,
      classes,
      onClose = () => {},
    } = this.props
    return (
      <Box>
        {hasError && (
          <AlertComponent
            severity="error"
            title="Error"
            className={classes.alert}
          >
            <ui>
              {saveTemplateDetails &&
              saveTemplateDetails.error &&
              saveTemplateDetails.error.errorMessages &&
              saveTemplateDetails.error.errorMessages.length ? (
                saveTemplateDetails.error.errorMessages.map((err, i) => {
                  return <li key={i}>{err}</li>
                })
              ) : (
                <li>Couldn't save the template, Please contact support</li>
              )}
            </ui>
          </AlertComponent>
        )}
        <form
          onSubmit={this.handleSubmit}
          id="new-template"
          ref={(elem) => (this.fromRef = elem)}
        >
          <div className={classes.formContainer}>
            <div className={classes.header}>
              <Typography label="New Template" variant="h6" />
              <CustomIconButton
                className={classes.closePlacement}
                onClick={onClose}
                label={'Close'}
              >
                <Close />
              </CustomIconButton>
            </div>
            <FormWrapper label="Created By :">
              <FormTextField value={fullName} disabled fullWidth size="small" />
            </FormWrapper>
            <FormWrapper label="Name of template * :">
              <Box className={classes.boxStyle}>
                <FormTextField
                  value={template_name}
                  onChange={this.handleInputChange}
                  onBlur={this.handleInputValidation}
                  required
                  fullWidth
                  size="small"
                  name="template_name"
                  error={isFormSubmitted && !isTemplateNameValid}
                  helperText={
                    isFormSubmitted && !isTemplateNameValid
                      ? 'Please provide template name'
                      : ''
                  }
                />
                {isQaStatusValidating && (
                  <Box sx={{ marginLeft: '10px' }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
            </FormWrapper>
            <FormWrapper label="Business Department * :">
              <Box
                sx={{
                  '& .MuiFormControl-root': {
                    margin: '0',
                    minWidth: '340px !important',
                  },
                }}
              >
                <LabelAndHelperSelect
                  onChange={this.handleInputChange}
                  value={formValues.team}
                  size="small"
                  options={businessDepartments}
                  name="team"
                  error={isFormSubmitted && !isBusinessDepartmentValid}
                  helperLabel={
                    isFormSubmitted && !isBusinessDepartmentValid ? (
                      <font color="#d32f2f">
                        Please provide business department
                      </font>
                    ) : (
                      ''
                    )
                  }
                />
              </Box>
            </FormWrapper>

            <FormWrapper label="Max file Path * :">
              <Box
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isMaxPathValidated
                      ? 'green !important'
                      : 'auto',
                  },
                }}
                className={classes.boxStyle}
              >
                <FormTextField
                  value={formValues.max_file_path}
                  onChange={this.handleMaxFilePath}
                  required
                  fullWidth
                  error={
                    (formValues.max_file_path !== '' && !isMaxPathValidated) ||
                    (formValues.max_file_path === '' && isFormSubmitted)
                  }
                  size="small"
                  helperText={
                    formValues.max_file_path !== '' && !isMaxPathValidated
                      ? maxPathError
                      : formValues.max_file_path === '' && isFormSubmitted
                      ? 'Please provide max file path'
                      : ''
                  }
                />

                {isMaxPathLoading && (
                  <Box sx={{ marginLeft: '10px' }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
            </FormWrapper>

            <FormWrapper label="Low poly Path :">
              <Box
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isLowPolyValidated
                      ? 'green !important'
                      : 'auto',
                  },
                }}
                className={classes.boxStyle}
              >
                <FormTextField
                  value={formValues.fbx_file_path}
                  onChange={this.handleLowPolyPath}
                  required
                  fullWidth
                  error={formValues.fbx_file_path !== '' && !isLowPolyValidated}
                  size="small"
                  helperText={
                    formValues.fbx_file_path !== '' && !isLowPolyValidated
                      ? lowPolyPathError
                      : ''
                  }
                />

                {isFbxPathLoading && (
                  <Box sx={{ marginLeft: '10px' }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
            </FormWrapper>

            {formValues.team === 'Marketing_Non_Cyclic' && (
              <FormWrapper label="Template architecture max file path :">
                <Box
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isArchValidated
                        ? 'green !important'
                        : 'auto',
                    },
                  }}
                  className={classes.boxStyle}
                >
                  <FormTextField
                    defaultValue={formValues.architecture_file_path}
                    onChange={_.debounce(this.handleArchPath, 700)}
                    required
                    fullWidth
                    error={
                      formValues.architecture_file_path !== '' &&
                      !isArchValidated
                    }
                    size="small"
                    helperText={
                      formValues.architecture_file_path !== '' &&
                      !isArchValidated
                        ? archPathError
                        : ''
                    }
                  />

                  {isArchPathLoading && (
                    <Box sx={{ marginLeft: '10px' }}>
                      <CircularProgress size={25} />
                    </Box>
                  )}
                </Box>
              </FormWrapper>
            )}

            <FormWrapper label="Thumbnail file Path * :">
              <Box
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isThumbPathVaildated
                      ? 'green !important'
                      : 'auto',
                  },
                }}
                className={classes.boxStyle}
              >
                <FormTextField
                  value={formValues.thumbnail_file_path}
                  onChange={this.handleThumbPath}
                  required
                  fullWidth
                  error={
                    (formValues.thumbnail_file_path !== '' &&
                      !isThumbPathVaildated) ||
                    (formValues.thumbnail_file_path === '' && isFormSubmitted)
                  }
                  size="small"
                  helperText={
                    formValues.thumbnail_file_path !== '' &&
                    !isThumbPathVaildated
                      ? thumbnailPathError
                      : formValues.thumbnail_file_path === '' && isFormSubmitted
                      ? 'Please provide thumbnail path'
                      : ''
                  }
                />

                {isThumbPathLoading && (
                  <Box sx={{ marginLeft: '10px' }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
            </FormWrapper>
            {showTags && (
              <FormWrapper label="Tags :" styles={classes.tagsHeight}>
                <InputTags
                  selectedTags={(tags) => {
                    this.setState({
                      formValues: { ...this.state.formValues, tags },
                    })
                  }}
                  id="tags"
                  name="tags"
                  variant="outlined"
                  size="small"
                  sx={{
                    width: '340px',
                    '& .MuiInputBase-root': {
                      display: 'flex !important',
                      flexWrap: 'wrap !important',
                    },
                  }}
                />
              </FormWrapper>
            )}
            {qaRunStatus !== '' && (
              <FormWrapper label="">
                <QaPluginStatusDisplay
                  qaRunStatus={qaRunStatus}
                  lastRunDate={readableDateAndTimeDigitFormat(qaLastRunDate)}
                  errors={errorList?.map((error) => error.description)}
                />
              </FormWrapper>
            )}
          </div>
          <div className={classes.actionBar}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '14px 40px',
              }}
            >
              <CustomButton
                type="submit"
                color="primary"
                label="Upload Template"
                size="large"
                disabled={
                  !(
                    isTemplateNameValid &&
                    isBusinessDepartmentValid &&
                    isMaxPathValidated &&
                    (isLowPolyValidated ||
                      !this.state.formValues.fbx_file_path) &&
                    (team === 'Marketing_Non_Cyclic'
                      ? isArchValidated ||
                        !this.state.formValues.architecture_file_path
                      : true) &&
                    isThumbPathVaildated
                  )
                }
              />
            </Box>
          </div>
        </form>
      </Box>
    )
  }
}

const mapStateToProps = ({
  auth: { fullName = '', lanId = '', firstName = '' },
  uploadTemplate: {
    businessDepartments,
    fetchingFileValidation,
    isFilePathValid,
    saveTemplateDetails,
    projectTypesDetails: { projectTypes },
  },
} = {}) => {
  return {
    fullName,
    lanId,
    firstName,
    businessDepartments,
    fetchingFileValidation,
    isFilePathValid,
    saveTemplateDetails,
    projectTypes,
  }
}

export default connect(mapStateToProps, {
  getBusinessDepartments,
  getFilePathValidation,
  saveTemplate,
  saveTemplateSuccess,
  getProjectTypes,
  showNotification,
  ingestTemplate,
})(withStyles(styles)(NewTemplate))
