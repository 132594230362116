export const FETCH_ARCH_ASSETS_INITIATED = 'FETCH_ARCH_ASSETS_INITIATED'
export const FETCH_ARCH_ASSETS_SUCCESS = 'FETCH_ARCH_ASSETS_SUCCESS'
export const FETCH_ARCH_ASSETS_FAILED = 'FETCH_ARCH_ASSETS_FAILED'
export const CLEAR_RESULTS = 'CLEAR_RESULTS'
export const FETCH_UPLOAD_STATUS_INITIATED = 'FETCH_UPLOAD_STATUS_INITIATED'
export const FETCH_UPLOAD_STATUS_FAILED = 'FETCH_UPLOAD_STATUS_FAILED'
export const FETCH_UPLOAD_STATUS_SUCCESS = 'FETCH_UPLOAD_STATUS_SUCCESS'
export const SET_STATUS_WINDOW_DISPLAY = 'SET_STATUS_WINDOW_DISPLAY'

export const SAVE_ASSETS_INITIATED = 'SAVE_ASSETS_INITIATED'
export const SAVE_ASSETS_SUCCESS = 'SAVE_ASSETS_SUCCESS'
export const SAVE_ASSETS_FAIL = 'SAVE_ASSETS_FAIL'

export const DELETE_ASSETS_INITIATED = 'DELETE_ASSETS_INITIATED'
export const DELETE_ASSETS_SUCCESS = 'DELETE_ASSETS_SUCCESS'
export const DELETE_ASSETS_FAIL = 'DELETE_ASSETS_FAIL'

export const EDIT_ASSETS_INITIATED = 'EDIT_ASSETS_INITIATED'
export const EDIT_ASSETS_SUCCESS = 'EDIT_ASSETS_SUCCESS'
export const EDIT_ASSETS_FAIL = 'EDIT_ASSETS_FAIL'

export const UPLOAD_STATUS_INITIATED = 'UPLOAD_STATUS_INITIATED'
export const UPLOAD_STATUS_SUCCESS = 'UPLOAD_STATUS_SUCCESS'
export const UPLOAD_STATUS_FAIL = 'UPLOAD_STATUS_FAIL'
export const ASSET_UPLOAD_COMPLETED = 'ASSET_UPLOAD_COMPLETED'

export const HANDLE_SELECT_ARCHITECTURE_ASSET =
  'HANDLE_SELECT_ARCHITECTURE_ASSET'
