import axios from 'axios'
import {
  ADD_TCIN_TO_BOARD,
  CLEAR_BOARD_DATA,
  SHOW_BULK_TCIN_DIALOG,
  OPEN_RIGHT_DRAWER,
  CLOSE_RIGHT_DRAWER,
  ADD_BOARD_ERROR_LIST,
} from './actionType'
import { showNotification } from '../notification/actionCreator'

import apiConfig from '../../config/config'
import { mapGalleryData } from '../explorer/reducer'

export function validateTcins(payload) {
  return axios
    .post(apiConfig.addTcin.verifyTcin, payload)
    .then((response) => response.data)
}

export async function validatePids({ pidEntry, galleryTcinEntry }) {
  let pids = pidEntry.map((item) => item)
  let tcins = galleryTcinEntry.map((item) => item)

  let validPids = []
  let values = [...pids, ...tcins]
  const requestPromises = []

  const tcinQuery = {
    query: '',
    filters: [
      {
        filter_chip_key: 'TCIN',
        filter_chip_value: tcins,
      },
      {
        filter_chip_key: 'lod_profile_type',
        filter_chip_value: ['LOD3', 'LOD4'],
      },
    ],
  }

  const PIDQuery = {
    query: '',
    filters: [
      {
        filter_chip_key: 'Spark Resource Id',
        filter_chip_value: pids.map((item) =>
          item.toUpperCase().replace('PID-', '')
        ),
      },
      {
        filter_chip_key: 'lod_profile_type',
        filter_chip_value: ['LOD3', 'LOD4'],
      },
    ],
  }
  if (tcins.length) {
    requestPromises.push(
      new Promise((resolve, reject) => {
        axios
          .post(`${apiConfig.gallery.search}?&page=0`, tcinQuery)
          .then(({ data }) => {
            resolve({
              type: 'tcin',
              data,
            })
          })
          .catch(reject)
      })
    )
  }
  if (pids.length) {
    requestPromises.push(
      new Promise((resolve, reject) => {
        axios
          .post(`${apiConfig.gallery.search}?&page=0`, PIDQuery)
          .then(({ data }) => {
            resolve({
              type: 'pid',
              data,
            })
          })
          .catch(reject)
      })
    )
  }

  return Promise.allSettled(requestPromises)
    .then((responses) => {
      responses.forEach(({ status, value: resp, reason }) => {
        if (status == 'fulfilled') {
          const respData = resp.data.data
          if (resp.type == 'tcin') {
            respData.forEach((asset) => {
              validPids.push({
                thumbnail_url: '',
                name: asset.name,
                value: asset.id,
                asset_id: asset.id,
              })
              const assetTags = mapAssetTags(asset)
              const index = values.indexOf(assetTags['tcin'])
              if (index > -1) {
                values.splice(index, 1)
              }
            })
          }
          if (resp.type == 'pid') {
            respData.forEach((asset) => {
              validPids.push({
                thumbnail_url: '',
                name: asset.name,
                value: asset.id,
                asset_id: asset.id,
              })
              const assetTags = mapAssetTags(asset)

              const assetPid = assetTags['Spark Resource Id']
              if (assetPid && assetPid !== '') {
                values = values.filter(
                  (pid) => pid.toUpperCase() !== assetPid.toUpperCase()
                )
              }
            })
          }
        }
      })
      return {
        valid: [...validPids],
        invalid: values.map((item) => item),
      }
    })
    .catch((e) => {
      console.log(e)
    })
}

const mapAssetTags = (data) => {
  const tags = {}
  ;(data.asset_tags || [{ key: '' }]).forEach(({ key, value }) => {
    tags[key] = value
  })
  return tags
}

export function addDataTcinBoard(res) {
  return {
    type: ADD_TCIN_TO_BOARD,
    payload: res,
  }
}

export function clearBoardData() {
  return {
    type: CLEAR_BOARD_DATA,
  }
}

export function addBoardErrorDataList(data) {
  return {
    type: ADD_BOARD_ERROR_LIST,
    payload: data,
  }
}

const getGalleryAssets = async (data) => {
  const pids = []
  const tcins = []
  const galleryassets = []
  data.forEach((item) => {
    const { asset_type: type, pid, value } = item
    if (type == 'PID') {
      pids.push(pid)
    } else if (type == 'GALLERY') {
      galleryassets.push(value)
    } else {
      tcins.push(item)
    }
  })
  if (pids.length) {
    const promises = pids.map(
      (assetId) =>
        new Promise((resolve, reject) =>
          axios
            .get(`${apiConfig.gallery.assetDetails}/${assetId}`)
            .then(resolve)
            .catch((e) => {
              if (e.response) {
                const status = e.response.status
                if (status == '401') {
                  reject({ status, error: `Unauthorized access`, id: assetId })
                }
                if (status == '400') {
                  reject({ status, error: `Invalid asset id`, id: assetId })
                }
                if (status == '404') {
                  reject({ status, error: `Asset not found`, id: assetId })
                }
                if (status == '503') {
                  reject({ status, error: `Permission denied`, id: assetId })
                }
                if (status == '501') {
                  reject({ status, error: `Unauthorized access`, id: assetId })
                }
              }
              reject({ status: '', error: `Issue with asset`, id: assetId })
            })
        )
    )

    const PIDData = []
    const ErrorData = []
    try {
      const responses = await Promise.allSettled(promises)
      responses.forEach(({ status, value: resp, reason }) => {
        if (status == 'fulfilled' && resp?.data?.data) {
          const respData = resp.data.data
          PIDData.push({
            ...mapGalleryData(respData, true),
            asset_id: respData.id,
            value: respData.id,
          })
        } else {
          ErrorData.push(reason)
        }
      })
    } catch (e) {
      console.log(e)
    }

    return { assetData: [...tcins, ...PIDData], failedData: ErrorData }

    let query = `asset=where=(tag=where=(key==\"Spark\\ Resource\\ Id\";${pids
      .map((searchkey) => `value=="${searchkey}"`)
      .join(',')});tag=where=(key==\"asset_stage\",value==\"lod_final\"))`
    query = encodeURIComponent(query)

    const pidResponse = await axios
      .get(`${apiConfig.gallery.search}?query=${query}&page=0&size=20`)
      .then((response) => {
        const PIDData = []
        response.data.data.forEach((item) => {
          const pidTags = item.asset_tags.filter(
            ({ tag: { key } }) => key == 'Spark Resource Id'
          )[0]

          const pid = pidTags ? pidTags.tag.value : undefined

          PIDData.push({
            thumbnail_url: item.hero_image_file.file_sources[0].access_url,
            name: item.name,
            asset_id: item.id,
            value: item.id,
            asset_type: 'PID',

            is_hp_available: true,
            is_lp_available: true,
            is_primary_tcin: false,
            brand: '',
            category: '',
            dimensions: '',
            dpci: pid,
            launch_time: '',
            materials: '',
            product_type: '',
          })
        })
        return PIDData
      })
    return [...tcins, ...pidResponse]
  } else {
    return { assetData: data, failedData: [] }
  }
}

export function addTcinToBoard(
  payload,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    return axios
      .post(apiConfig.addTcin.addTcinToBoard, payload)
      .then(async (res) => {
        let response = res.data
        let { assetData: assetsdata = [], failedData = [] } =
          await getGalleryAssets(response.assets)
        response.assets = assetsdata
        dispatch(addDataTcinBoard(response))
        dispatch(addBoardErrorDataList(failedData))
        successCallback(response)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }
}

export function validatePrimaryTcins(
  payload,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    return axios
      .post(apiConfig.board.validatePrimaryTcins, payload)
      .then((res) => {
        dispatch(addDataTcinBoard(res.data))
        successCallback(res.data)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }
}

export function removeTcinsFromBoard(
  payload,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    return axios
      .delete(apiConfig.board.remove, { data: payload })
      .then(async (res) => {
        let response = res.data
        let { assetData: assetsdata = [], failedData = [] } =
          await getGalleryAssets(response.assets)
        response.assets = assetsdata
        dispatch(addDataTcinBoard(response))
        dispatch(addBoardErrorDataList(failedData))
        successCallback(res.data)
      })
      .catch((error) => {
        errorCallback(error)
      })
  }
}

export function getBoard(
  boardId,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    return axios
      .get(apiConfig.board.get(boardId))
      .then(async (res) => {
        let response = res.data
        let { assetData: assetsdata = [], failedData = [] } =
          await getGalleryAssets(response.assets)

        response.assets = assetsdata
        dispatch(addDataTcinBoard(response))
        dispatch(addBoardErrorDataList(failedData))
        successCallback(response)
      })
      .catch((error) => {
        errorCallback(error)
        dispatch(
          showNotification(
            true,
            'Failed to fetch board, Please try after refreshing the page',
            'error'
          )
        )
      })
  }
}

export function getTemplateAssets(
  templateId,
  successCallback = () => {},
  errorCallback = () => {}
) {
  return function (dispatch) {
    return axios
      .get(apiConfig.template.getTemplateAssets(templateId))
      .then((res) => {
        dispatch(
          addDataTcinBoard({
            assets: [...res.data],
            board_id: templateId,
            experience_id: templateId,
          })
        )
        successCallback(res.data)
      })
      .catch((error) => {
        errorCallback(error)
        dispatch(
          showNotification(
            true,
            'Failed to fetch board, Please try after refreshing the page',
            'error'
          )
        )
      })
  }
}

export function showBulkTcinDialog(payload) {
  return {
    type: SHOW_BULK_TCIN_DIALOG,
    payload,
  }
}

export function openRightDrawer() {
  return {
    type: OPEN_RIGHT_DRAWER,
  }
}
export function closeRightDrawer() {
  return {
    type: CLOSE_RIGHT_DRAWER,
  }
}
