export const API_CALL_INITIATED = 'API_CALL_INITIATED'
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS'
export const API_CALL_FAIL = 'API_CALL_FAIL'
export const FETCH_RECORDS_SUCCESS = 'FETCH_RECORDS_SUCCESS'
export const CLEAR_ASSETS = 'CLEAR_ASSETS'
export const ADD_LIST_PAGE_META = 'ADD_LIST_PAGE_META'
export const NO_ASSETS_FOUND = 'NO_ASSETS_FOUND'
export const COMMENTS_FAILURE = 'COMMENTS_FAILURE'
export const COMMENTS_SUCCESS = 'COMMENTS_SUCCESS'
export const ADD_COMMENTS = 'ADD_COMMENTS'
export const SHOW_COMMENTS = 'SHOW_COMMENT'
export const SHOW_COMMENTS_SUCCESS = 'SHOW_COMMENTS_SUCCESS'
export const SHOW_COMMENTS_FAILURE = 'SHOW_COMMENTS_FAILURE'
export const FETCH_RECORDS_SUCCESS_UNGROUP = 'FETCH_RECORDS_SUCCESS_UNGROUP'
export const CLEAR_ASSETS_UNGROUP = 'CLEAR_ASSETS_UNGROUP'
export const FETCH_STYLED_ASSETS_SUCCESS = 'FETCH_STYLED_ASSETS_SUCCESS'
export const CLEAR_STYLED_ASSETS = 'CLEAR_STYLED_ASSETS'
export const FILTER_LISTS_REQUEST = 'FILTER_LISTS_REQUEST'
export const FILTER_LISTS_SUCCESS = 'FILTER_LISTS_SUCCESS'
export const FILTER_LISTS_FAILURE = 'FILTER_LISTS_FAILURE'
export const FILTER_LISTS_CHANGE = 'FILTER_LISTS_CHANGE'
export const FILTER_COUNT_CHANGE = 'FILTER_COUNT_CHANGE'
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'
export const TOTAL_COUNT_SUCCESS = 'TOTAL_COUNT_SUCCESS'
export const FETCH_SIMILAR_ASSETS_SUCCESS = 'FETCH_SIMILAR_ASSETS_SUCCESS'
export const CLEAR_SIMILAR_ASSETS = 'CLEAR_SIMILAR_ASSETS'
export const SHOW_SIMILAR_ASSETS = 'SHOW_SIMILAR_ASSETS'
export const FETCH_GALLERY_RECORD_SUCCESS = 'FETCH_GALLERY_RECORD_SUCCESS'
export const OPEN_EXPLORER = 'OPEN_EXPLORER'
export const CLOSE_EXPLORER = 'CLOSE_EXPLORER'
export const SHOW_VARIATIONS = 'SHOW_VARIATIONS'
export const CLEAR_SUB_VIEW = 'CLEAR_SUB_VIEW'
export const SET_SORTBY = 'SET_SORTBY'
export const SET_EXCLUDE_HP = 'SET_EXCLUDE_HP'
export const SET_EXCLUDE_RTA = 'SET_EXCLUDE_RTA'
export const SET_MINI_EXPLORER_MODE = 'SET_MINI_EXPLORER_MODE'
