import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { isEmpty } from 'lodash'
import DraggableAssetCard from '../assetExplorerCard/DraggableAssetCard'

const styles = (theme) => ({
  cardWrapper: {
    width: '120px',
    borderRadius: '7px',
    marginBottom: 15,
  },
  primeCardWrapper: {
    zIndex: 5,
    overflow: 'auto',
    marginTop: 10,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },

  assetSearchContainer: {
    marginTop: 40,
    marginLeft: '1em',
  },
})

class TemplateAssets extends React.Component {
  state = {
    anchorEl: null,
    secondaryAnchorEl: null,
    selectedItems: [],
    boardAssetsList: [],
    openConfirm: false,
    deleteRef: null,
    assetsToDelete: {},
  }

  render() {
    const {
      classes,
      currentTemplateDetails: { template_assets = [] },

      handleShowTemplateAssets,
      handleCartActions,
    } = this.props

    return (
      <>
        {isEmpty(template_assets) ? (
          <div className={classes.assetSearchContainer}>
            <div style={{ textAlign: 'center', padding: '10px 0' }}>
              <i
                className="fab fa-trello fa-5x"
                aria-hidden="true"
                style={{
                  color: '#D3D3D3',
                  margin: '0 auto',
                }}
              />
              <div className={classes.textAddAsset}>
                No assets available in this template
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.primeCardWrapper}>
            {template_assets.map((element, i) => (
              <div className={classes.cardWrapper} key={i}>
                <DraggableAssetCard
                  data={{
                    ...element,
                    tcin: element.value,
                    short_description:
                      element.product_description &&
                      element.product_description.short_description,
                  }}
                  handleCartActions={handleCartActions}
                />
              </div>
            ))}
            <div className={classes.cardWrapper}></div>
          </div>
        )}
      </>
    )
  }
}

TemplateAssets.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = ({
  templates: { currentTemplateDetails },
  activeOperations,
  experiences: { experience },
}) => ({
  activeOperations,
  currentTemplateDetails,
  experience,
})
export default connect(mapStateToProps, {})(withStyles(styles)(TemplateAssets))
