import axios from 'axios'
import {
  FETCH_COMMENTS_INITIATED,
  FETCH_COMMENTS_FAIL,
  FETCH_COMMENTS_SUCCESS,
  ADD_COMMENT_INITIATED,
  ADD_COMMENT_SUCCESSFUL,
  EDIT_COMMENT_SUCCESSFULL,
  SAVE_ANNOTATION_MODE,
  SELECTED_COMMENT_ID,
  HIGHLIGHT_COMMENT_ID,
} from './ActionType'

import WebWorker from '../../lib/WebWorker'
import FileUploadWorker from '../../lib/FileUploadWorker'

import api from '../../config/config'

let fileWorker = undefined

export function fetchRenderComments(renderJobId) {
  return function (dispatch) {
    let url = `${api.comments.renderComments}${renderJobId}`
    dispatch(
      apiCallInitiated({
        type: FETCH_COMMENTS_INITIATED,
        data: [],
      })
    )
    return axios
      .get(url)
      .then((response) => {
        dispatch(fetchCommentsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
      })
  }
}

export function saveRenderComment(
  renderJobId,
  payload,
  files,
  auth,
  isEditor,
  isReply,
  onCommentSucess = () => {}
) {
  return function (dispatch) {
    let successActionType =
      isEditor || isReply ? EDIT_COMMENT_SUCCESSFULL : ADD_COMMENT_SUCCESSFUL
    let url = `${api.comments.saveComment}`
    dispatch(
      apiCallInitiated({
        type: ADD_COMMENT_INITIATED,
        data: [],
      })
    )
    return axios
      .post(url, { ...payload })
      .then((response) => {
        let savedComment = response.data
        terminateWorker()
        if (fileWorker === undefined) {
          initiateWorker()
        }
        let renderCommentId
        if (isReply) {
          renderCommentId = response.data.reply_comments[0].render_comment_id
        } else if (payload.render_comment_id) {
          renderCommentId = payload.render_comment_id
        } else {
          renderCommentId = response.data.render_comment_id
        }
        //Perform chunked upload attached files exist
        if (files.length) {
          let fileUploadTracker = []
          let uploadParams = {
            chunkSize: 1 * 1024 * 1024,
            action: 'init',
            startChunkUrl: api.chunk.fileUploadStart,
            endChunkUrl: api.chunk.fileUploadEnd,
            chunkUploadUrl: api.chunk.fileUploadProcess,
            apiKey: api.apiKey,
            xIdToken: auth.xIdToken,
            render_job_id: renderJobId,
            render_comment_id: renderCommentId,
            accessToken: auth.accessToken,
          }

          for (let i = 0; i < files.length; i++) {
            setTimeout(() => {
              uploadParams.fileName = files[i].filename
              fileWorker.postMessage(JSON.parse(JSON.stringify(uploadParams)))
              fileWorker.postMessage({ action: 'process', file: files[i].file })
            }, 500)
          }
          fileWorker.onmessage = (res) => {
            let index
            try {
              index = savedComment.reply_comments.findIndex(
                (item) => item.render_comment_id === renderCommentId
              )
            } catch (e) {
              index = -1
            }

            if (isReply) {
              savedComment.reply_comments[0].attached_files = {
                ...savedComment.reply_comments[0].attached_files,
                ...res.data.attached_files,
              }
            } else if (isEditor && index !== -1) {
              let index = savedComment.reply_comments.findIndex(
                (item) => item.render_comment_id === renderCommentId
              )
              savedComment.reply_comments[index].attached_files = {
                ...savedComment.reply_comments[index].attached_files,
                ...res.data.attached_files,
              }
            } else {
              savedComment.attached_files = {
                ...savedComment.attached_files,
                ...res.data.attached_files,
              }
            }

            fileUploadTracker.push(res.data.fileName)
            if (fileUploadTracker.length === files.length) {
              onCommentSucess(savedComment)
              dispatch(
                apiCallSuccess({
                  type: successActionType,
                  response: savedComment,
                })
              )
            }
          }
        }

        if (files.length === 0) {
          onCommentSucess(savedComment)
          dispatch(
            apiCallSuccess({
              type: successActionType,
              response: savedComment,
            })
          )
        }
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
      })
  }
}

export function initiateWorker() {
  fileWorker = new WebWorker(FileUploadWorker)
}

export function terminateWorker() {
  if (fileWorker) {
    fileWorker.terminate()
    fileWorker = undefined
  }
}

export function apiCallInitiated({ type, data }) {
  return {
    type,
    data,
  }
}

export function apiCallFail(error) {
  return {
    type: FETCH_COMMENTS_FAIL,
    error,
    data: [],
  }
}

export function fetchCommentsSuccess(data) {
  return {
    type: FETCH_COMMENTS_SUCCESS,
    data,
  }
}

export function apiCallSuccess(data) {
  return {
    type: data.type,
    payload: data.response,
  }
}

export function saveAnnotationMode(isAnnotationMode) {
  return {
    type: SAVE_ANNOTATION_MODE,
    payload: isAnnotationMode,
  }
}
export function saveCommentId(index) {
  return {
    type: SELECTED_COMMENT_ID,
    payload: index,
  }
}

export function highlightComment(index) {
  return {
    type: HIGHLIGHT_COMMENT_ID,
    payload: index,
  }
}
