import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'

import {
  Grid,
  Button,
  Menu,
  MenuItem,
  Popover,
  Box,
  Card,
  CardContent,
} from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import { debounce, isEmpty } from 'lodash'
import {
  SearchComponent,
  PageTitleBar,
  Typography,
  CustomIconButton,
} from 'cgi-ui-components'
import { Close } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { CSVLink } from 'react-csv'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import CircularProgress from '@mui/material/CircularProgress'
import ListItemText from '@mui/material/ListItemText'
import axios from 'axios'

import PdfDocument from './PdfDocument'
import {
  showBulkTcinDialog,
  removeTcinsFromBoard,
  addTcinToBoard,
} from '../../store/board/actionCreator'

import { removeMultipleAssets } from '../designSpace'
import { showNotification } from '../../store/notification/actionCreator'
import ConfirmationPopUp from '../modal/ConfirmationPopUp'
import { setActiveOperation } from '../../store/operations/actionCreator'
import { getCurrentOS, OS, isExternalUser } from '../../helpers/utils'
import { CLEAR_BOARD_POPUP_MESSAGE } from '../../constants/displayMessages'
import config from '../../config/config'
import TemplateDetails from './TemplateDetails'
import BoardAssets from './BoardAssets'
import TemplateAssets from './TemplateAssets'
import { filterAssetsByType } from '../../helpers/utils'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor ? backgroundColor : '#ffffff',
    maxWidth: '100%',
    padding: 0,
    border: '1px solid #dadde9',
  },
}))
const styles = (theme) => ({
  rootContainer: {
    position: 'relative',
    height: 'Calc(100vh - 10px)',
    padding: '10px 10px 0',
  },
  paddingContainer: {
    paddingBottom: 60,
  },
  assetButtonContainer: {
    marginTop: '4em',
    marginLeft: '1em',
  },
  textAddAsset: {
    fontSize: '10px',
    marginLeft: '21px',
    color: 'grey',
  },
  csvLink: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  horizontalDots: {
    fontSize: '1em',
    border: '1px solid ' + theme.palette.primary.lightGray,
    borderRadius: '11px',
    backgroundColor: 'white',
  },
  confirmBtn: {
    width: '100%',
    marginBottom: 5,
  },
  boardHeader: {
    zIndex: 1000,
    paddingBottom: 1,
    backgroundColor: `rgba(255, 255, 255)`,
    padding: '0 10px',
  },
  threeDot: {
    marginTop: '0',
  },
  disbledLink: {
    color: theme.palette.primary.lightFont,
    '& i.fa-stop-circle': {
      color: theme.palette.primary.pitchBlack,
    },
  },
  exportMenuTitle: {
    padding: '10px 2px 0px 14px',
    fontSize: 12,
    color: theme.palette.primary.mediumGrey,
  },
  errorIconLarge: {
    float: 'right',
    color: theme.palette.warning.main,
  },
  errorTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#444',
  },
  errorText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#444',
  },
  errorContainer: {
    paddingTop: 5,
    paddingLeft: 5,
  },
})

class AssestBoard extends React.Component {
  state = {
    anchorEl: null,
    secondaryAnchorEl: null,
    selectedItems: [],
    report: { headers: [], data: [] },
    searchVal: '',
    openConfirm: false,
    exporting: false,
    downloading: false,
    downloadJobId: '',
    downloadFolderPath: '',
    windowsFolderPath: '',
    macFolderPath: '',
    downloadReportData: [],
    downloadStatus: '',
    jobId: '',
    copyTooltip: 'Copy folder path',
    popupText: CLEAR_BOARD_POPUP_MESSAGE,
    showTemplateAssets: false,
  }

  handleClickThreedot = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleCloseMenu = () => {
    this.setState({ anchorEl: null })
  }
  handleExportMenu = (event) => {
    const { exporting } = this.state
    if (exporting) {
      this.setState({ exporting: false })
      if (this.axiosSource) {
        this.axiosSource.cancel('Operation canceled by User')
      }
    } else {
      this.setState({ secondaryAnchorEl: event.currentTarget })
    }
  }
  handlecloseExportMenu = (event) => {
    this.setState({ secondaryAnchorEl: null })
  }

  downloadExcel = () => {
    const {
      boardId,
      experience: { experience_name: expName },
    } = this.props

    this.setState({ exporting: true, secondaryAnchorEl: null, anchorEl: null })
    this.axiosSource = axios.CancelToken.source()

    axios
      .get(config.board.exportXls(boardId), {
        cancelToken: this.axiosSource.token,
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {
          type: type,
          encoding: 'UTF-8',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${expName}.xlsx`
        link.click()

        this.setState({ exporting: false })
      })
      .catch((error) => {
        this.setState({ exporting: false })
        this.props.showNotification(
          true,
          'Error in exporting xls file, please try after sometime',
          'error'
        )
      })
  }

  generatePdfDocument = async () => {
    const {
      memberOf,
      experience: { experience_name: expName },
      tcin_comments,
      show_comments,
      allBoardAssets: assets,
    } = this.props

    const externalUser = isExternalUser(memberOf)

    const pdfReport = {
      comments: tcin_comments.length ? tcin_comments : show_comments,
      assets,
      externalUser,
    }
    this.setState({ exporting: true, secondaryAnchorEl: null, anchorEl: null })
    try {
      await pdf(<PdfDocument data={pdfReport} />)
        .toBlob()
        .then((response) => {
          saveAs(response, `${expName}.pdf`)
          this.setState({ exporting: false })
        })
    } catch (e) {
      console.log(e)
    }
  }

  syncAndDownload = () => {
    const { downloading } = this.state

    if (!downloading) {
      this.setState({ anchorEl: null, downloading: true })
      this.props.showNotification(
        true,
        'Downloading! 3D content is getting downloaded into a server location',
        'info'
      )
      this.initiateSyncDownload()
    } else {
      // this.cancelDownload()
    }
  }
  /**
   * Copy folder path to clipboard based on the current OS
   */
  copyFilePath =
    (macPath = '', windowsPath = '') =>
    (e) => {
      //Check for OS
      const os = getCurrentOS()
      let path = ''
      if (os === OS.WINDOWS) {
        path = windowsPath
      } else if (os === OS.MACOS) {
        path = macPath
      } else {
        path = macPath
      }
      // Create a textarea to select text
      const textarea = document.createElement('textarea')
      textarea.value = path
      textarea.style.position = 'fixed'
      document.body.appendChild(textarea)

      // Clear any pre existing selections
      window.getSelection().removeAllRanges()

      // Select the text inside the textarea
      let range = document.createRange()
      range.selectNode(textarea)
      window.getSelection().addRange(range)

      try {
        let successful = document.execCommand('copy')
        let msg = successful ? 'Path was copied' : 'Unable to copy'

        console.log(msg)
      } catch (err) {
        // console.log('Unable to copy');
      } finally {
        document.body.removeChild(textarea)
      }

      this.setState({ copyTooltip: 'Path copied' })
      setTimeout(() => {
        this.setState({ copyTooltip: 'Copy folder path' })
      }, 10000)
    }

  renderCsvLink = (renderIcon = false) => {
    const { classes } = this.props

    const { reportData = [], headers = [] } = this.prepareReportData()
    return (
      <CSVLink
        filename={'Board-Report.csv'}
        headers={headers}
        data={reportData}
        className={classes.csvLink}
      >
        {renderIcon ? <i className="fas fa-download" /> : 'Download report'}
      </CSVLink>
    )
  }

  cancelDownload = () => {
    const { boardId } = this.props
    const { jobId } = this.state
    axios
      .post(config.board.download, {
        event_type: 'cancel', //Initiate/getStatus/cancel
        board_id: boardId,
        jobId: jobId,
      })
      .then(() => {
        this.props.showNotification(
          'true',
          <>succesfully stopped download process</>,
          'success'
        )
      })
      .catch(() => {
        this.props.showNotification(
          'true',
          <>Error in aborting download process</>,
          'error'
        )
      })
      .finally(() => {
        this.setState({ downloading: false })
      })
  }

  initiateSyncDownload = () => {
    const { boardId, showNotification } = this.props
    const { jobId } = this.state
    axios
      .post(config.board.download, {
        event_type: jobId !== '' ? 'getStatus' : 'initiate', //initiate/getStatus/cancel
        board_id: boardId,
        jobId: jobId,
      })
      .then(({ data }) => {
        const {
          job_id: responseJobId = jobId,
          status: jobStatus = 'InProgress',
          download_report: report = [],
          copy_folder_path: folderPath = '',
          windows_folder_path: windowsFolderPath = '',
          mac_folder_path: macFolderPath = '',
        } = data

        this.setState({
          jobId: responseJobId,
          downloadStatus: jobStatus,
          downloadReportData: report,
          downloadFolderPath: folderPath,
          windowsFolderPath: windowsFolderPath,
          macFolderPath: macFolderPath,
        })
        if (jobStatus === 'InProgress') {
          setTimeout(() => {
            this.initiateSyncDownload()
          }, 15000)
        }
        if (jobStatus === 'Success' || jobStatus === 'Failure') {
          this.setState({ jobId: '', downloading: false })
          showNotification(
            true,
            <div>
              Download Complete! Copy link for the folder path under board "..."
              more option.{' '}
              <Button color="primary"> {this.renderCsvLink()}</Button>
            </div>,
            'success'
          )
        }
      })
      .catch(() => {
        if (jobId === '') {
          showNotification(
            'true',
            'Error in initiating download process',
            'error'
          )
        } else {
          showNotification(
            'true',
            'Error in getting status of download process',
            'error'
          )
        }
        this.setState({ jobId: '', downloading: false })
      })
  }

  openBulkTcinDialog = () => {
    const { showBulkTcinDialog } = this.props
    this.handleCloseMenu()
    showBulkTcinDialog(true)
  }

  openConfirmDialog = () => {
    this.setState({ openConfirm: true, anchorEl: null })
  }
  closeConfirmDialog = () => {
    this.setState({
      openConfirm: false,
      popupText: CLEAR_BOARD_POPUP_MESSAGE,
    })
  }
  clearBoard = () => {
    const {
      removeTcinsFromBoard,
      boardAssetsbyMode,
      boardId,
      showNotification,
    } = this.props
    const assets = boardAssetsbyMode.map((item) => item.value)
    const assetids = boardAssetsbyMode.map((item) =>
      item.asset_id ? item.asset_id : item.value
    )

    const deleteData = {
      board_id: boardId,
      assets: [...assets],
    }

    removeTcinsFromBoard(deleteData)
      .then(() => {
        removeMultipleAssets([...assetids])
        this.setState({ openConfirm: false })
      })
      .catch(() => {
        this.setState({ openConfirm: false })
        showNotification(
          true,
          "Sorry! we couldn't remove tcins from board. Try again!",
          'error'
        )
      })
  }

  removeAssetsFromBoard = () => {
    const { removeTcinsFromBoard, boardId, showNotification } = this.props
    const { selectedItems } = this.state
    const deleteData = {
      board_id: boardId,
      assets: [...selectedItems],
    }
    this.setState({ removing: true })
    removeTcinsFromBoard(deleteData)
      .then(() => {
        removeMultipleAssets([...selectedItems])
        this.setState({ selectedItems: [], removing: false })
      })
      .catch(() => {
        this.setState({ removing: false })
        showNotification(
          true,
          "Sorry! we couldn't remove tcin from board. Try again!",
          'error'
        )
      })
  }

  prepareReportData() {
    const { downloadReportData } = this.state

    const headers = [
      { label: 'Asset Details ', key: 'asset_details' },
      { label: 'Asset Type', key: 'asset_type' },
      { label: 'Thumbnail Download Status', key: 'thumbnail_download_status' },
      {
        label: '3dsMax File Download Status',
        key: 'max_file_download_status',
      },
      { label: 'Comments', key: 'comments' },
    ]

    return { headers, reportData: downloadReportData }
  }

  clearSearch = () => {
    this.setState({
      searchVal: '',
    })
  }

  setSearch = (val = '') => {
    this.setState({
      searchVal: val,
    })
  }

  handleShowTemplateAssets = (showTemplateAssets) => {
    this.setState({ showTemplateAssets })
  }

  showBoardError = () => {
    const { boardError = [], classes } = this.props
    if (boardError.length) {
      return (
        <div style={{ background: '#FEF7B6', padding: 5 }}>
          <Grid container>
            <Grid item className={classes.errorContainer} xs={10}>
              {boardError.length}&nbsp;Asset(s) could not be loaded
            </Grid>
            <Grid item xs={2}>
              <HtmlTooltip
                interactive
                placement="left"
                disableTouchListener
                title={
                  <Card style={{ width: 300 }}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={6} className={classes.errorTitle}>
                          Asset Id
                        </Grid>
                        <Grid item xs={6} className={classes.errorTitle}>
                          Error
                        </Grid>
                        {boardError.map((item) => (
                          <>
                            <Grid item xs={6} className={classes.errorText}>
                              {item.id}
                            </Grid>
                            <Grid item xs={6} className={classes.errorText}>
                              {item.error}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                }
              >
                <InfoIcon className={classes.errorIconLarge} />
              </HtmlTooltip>
            </Grid>
          </Grid>
        </div>
      )
    }
    return null
  }

  render() {
    const {
      classes,
      fullName,
      experience: { experience_name: sceneName },
      boardAssetsbyMode: boardAssets,
      allBoardAssets,
      templatePage = false,
      handleCartActions,
      boardError,
    } = this.props
    const {
      anchorEl,
      selectedItems,
      searchVal,
      openConfirm,
      secondaryAnchorEl,
      exporting,
      downloading,
      windowsFolderPath,
      macFolderPath,
      downloadReportData,
      copyTooltip,
      popupText,
      showTemplateAssets,
    } = this.state
    const containerClass =
      selectedItems.length > 0 ? classes.paddingContainer : ''
    let subtitle = ''
    if (isEmpty(boardAssets)) {
      subtitle = 'The styling board is empty'
    } else if (exporting) {
      subtitle = 'Exporting in progress'
    } else if (downloading) {
      subtitle = `Downloading in progress`
    } else {
      subtitle = `${boardAssets.length} asset${
        boardAssets.length > 1 ? 's' : ''
      } in here`
    }
    return (
      <>
        <div>
          <div className={classes.rootContainer + ' ' + containerClass}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <PageTitleBar subTitle={subtitle} title="Styling Board" />
              </Grid>
              <Grid alignContent="flex-end" alignSelf="center" item xs={3}>
                <CustomIconButton
                  label="more"
                  onClick={this.handleClickThreedot}
                  className={classes.threeDot}
                >
                  <MoreHorizIcon />
                </CustomIconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={this.handleCloseMenu}
                >
                  {!templatePage && (
                    <MenuItem onClick={this.openBulkTcinDialog}>
                      Add bulk TCIN and PID
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={this.handleExportMenu}
                    disabled={!allBoardAssets.length}
                    className={exporting ? classes.disbledLink : ''}
                  >
                    <ListItemText primary="Export Board Report" /> &nbsp;
                    {exporting ? (
                      <>
                        <i className="fas fa-stop-circle" />
                        &nbsp;
                        <CircularProgress size={'0.8rem'} />
                      </>
                    ) : (
                      <i className="fas fa-caret-right" />
                    )}
                  </MenuItem>
                  {!templatePage && (
                    <>
                      <MenuItem>
                        <a onClick={this.syncAndDownload}>
                          {downloading ? (
                            <>
                              <CircularProgress size={'0.8rem'} /> &nbsp;
                              Downloading 3D content... &nbsp;
                              {/* <i className="fas fa-stop-circle" /> */}
                            </>
                          ) : (
                            <> Sync &amp; download 3D content</>
                          )}
                        </a>

                        {macFolderPath !== '' &&
                          windowsFolderPath !== '' &&
                          !downloading && (
                            <>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Tooltip placement="top" title={copyTooltip}>
                                <a
                                  onClick={this.copyFilePath(
                                    macFolderPath,
                                    windowsFolderPath
                                  )}
                                >
                                  <i className="fas fa-copy"> </i>
                                </a>
                              </Tooltip>
                            </>
                          )}
                        {downloadReportData.length > 0 && !downloading && (
                          <>
                            &nbsp;&nbsp;
                            {this.renderCsvLink(true)}
                          </>
                        )}
                      </MenuItem>
                      <MenuItem
                        onClick={this.openConfirmDialog}
                        disabled={!boardAssets.length}
                      >
                        Clear Board
                      </MenuItem>
                    </>
                  )}
                </Menu>
                <Popover
                  open={Boolean(secondaryAnchorEl)}
                  anchorEl={secondaryAnchorEl}
                  onClose={this.handlecloseExportMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div className={classes.exportMenuTitle}>EXPORT AS...</div>
                  <MenuItem onClick={this.generatePdfDocument}>
                    {
                      <>
                        <i className="fas fa-file-pdf" /> &nbsp; &nbsp;
                        {sceneName} Board.pdf
                      </>
                    }
                  </MenuItem>
                  <MenuItem onClick={this.downloadExcel}>
                    <i className="fas fa-file-excel" />
                    &nbsp; &nbsp;{sceneName} Board.xlsx
                  </MenuItem>
                </Popover>
              </Grid>
            </Grid>
            <TemplateDetails
              handleShowTemplateAssets={this.handleShowTemplateAssets}
            />
            {!showTemplateAssets ? (
              <div
                style={{
                  padding: '10px 0 10px',
                  position: 'sticky',
                  top: '-10px',
                  zIndex: 1000,
                  background: '#FFF',
                  marginTop: 2,
                }}
              >
                <Box style={{ padding: '15px 0 10px' }}>
                  <Typography label="Product and Props" variant="subtitle" />
                </Box>
                {this.showBoardError()}
                {boardAssets.length ? (
                  <SearchComponent
                    onSearch={debounce(this.setSearch, 500)}
                    placeholder="Filter product and props"
                    onSearchButtonClick={this.clearSearch}
                    width="100%"
                    searchVal={searchVal}
                    transaparentBg
                    displayBrandLogo={false}
                  />
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  padding: '15px 10px ',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography label="Template Products" variant="subtitle" />
                </Box>

                <CustomIconButton
                  color="tertiary"
                  label="Exit template products"
                  onClick={() => {
                    this.handleShowTemplateAssets(false)
                  }}
                >
                  <Close />
                </CustomIconButton>
              </div>
            )}

            {showTemplateAssets ? (
              <TemplateAssets
                handleShowTemplateAssets={this.handleShowTemplateAssets}
                handleCartActions={handleCartActions}
              />
            ) : (
              <>
                <BoardAssets
                  searchVal={searchVal}
                  handleCartActions={handleCartActions}
                />
              </>
            )}

            <ConfirmationPopUp
              handleCancel={this.closeConfirmDialog}
              handleClose={this.closeConfirmDialog}
              handleConfirm={this.clearBoard}
              open={openConfirm}
              descriptionText={popupText}
              confirmText="Yes, Clear"
              cancelText="No, Cancel"
            />
          </div>
        </div>
      </>
    )
  }
}

AssestBoard.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = ({
  Board: { assets: allBoardAssets, board_id: boardId, boardError },
  auth: { fullName, memberOf },
  explorer: { tcin_comments, show_comments },
  cart,
  experiences: { experience },
  scene: { mode },
}) => ({
  boardId,
  boardAssetsbyMode: filterAssetsByType(allBoardAssets, mode),
  allBoardAssets,
  fullName,
  memberOf,
  cart,
  experience,
  tcin_comments,
  show_comments,
  boardError,
})
export default connect(mapStateToProps, {
  showBulkTcinDialog,
  removeTcinsFromBoard,
  showNotification,
  addTcinToBoard,
  setActiveOperation,
  showNotification,
})(withStyles(styles)(AssestBoard))
