import React, { memo } from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from 'cgi-ui-components'
import { CircularProgress } from '@mui/material'

import MaterialCard from './Card'

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - 315px)`,
  },
  leftSection: {
    borderRight: `1px solid #E0E0E0`,
    width: 0,
    flexGrow: 0.9,
    overflow: 'auto',
  },
  spacing: {
    margin: '3px 0px 5px',
    padding: '0px 8px',
  },
  cardStyle: { margin: 5 },
  cardWrapper: { display: 'flex', flexFlow: 'wrap' },
  loader: {
    paddingTop: 50,
    width: 40,
    margin: 'auto',
  },
}))

const EditorLeftPanel = memo(function ({
  srcMaterialList,
  onMaterialSelected,
  isLoadingMaterialList,
}) {
  const classes = useStyles()

  return (
    <div className={classes.leftSection}>
      <div className={classes.spacing}>
        <div>
          <Typography
            variant="caption"
            label={'Source material swatches'}
            style={{ margin: '5px 0px', fontWeight: 500 }}
          />
          <div className={classes.cardWrapper}>
            {isLoadingMaterialList ? (
              <div className={classes.loader}>
                <CircularProgress size={25} />
              </div>
            ) : (
              srcMaterialList.map((item) => (
                <div className={classes.cardStyle}>
                  <MaterialCard
                    key={item.material_id}
                    details={item}
                    isColor={item.color && true}
                    url={item.color ? item.color : item.swatch_thumbnail_url}
                    setSelected={(details) => onMaterialSelected(details)}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default EditorLeftPanel
