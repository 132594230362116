import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import DialogContentText from '@mui/material/DialogContentText'

import {
  fetchProject,
  clearProject,
} from '../../../store/projectList/ActionCreatorProjectList'
import {
  ProjectCard,
  PageTitleBar,
  Select,
  Typography,
  SearchComponent,
} from 'cgi-ui-components'
import ScenePage from './scene'
import { readableDate } from '../../../helpers/dataFormats'
import noThumbnail from '../../../images/ic_no-thumbnail.svg?url'
import config from '../../../config/config'
import LinearProgress from '@mui/material/LinearProgress'
import { showNotification } from '../../../store/notification/actionCreator'
import withRouter from '../../../components/HOC/withRouterHOC'
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import ManageThumbnailDialog from '../../Components/ManageThumbnailDialog'
import _ from 'lodash'

import searchLogo from '../../../images/enterprise/searchLogo.svg?url'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    textDecoration: 'none',
  },
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'Calc(100vh - 165px)',
    overflow: 'scroll',
  },
  card: {
    margin: '0px 15px',
    marginBottom: '30px',
  },
  headerWrapper: {
    padding: '15px 0px 30px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentEnd: {
    height: 10,
    flex: '0 0 100%',
  },
  noSearchResultContext: {
    padding: 20,
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 300,
    margin: '220px auto !important',
  },
  resultKeyword: {
    fontWeight: 1000,
  },
  deletePopver: {
    borderRadius: 5,
    padding: 20,
  },
})

class ProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.contentEndRef = React.createRef()
    this.state = {
      pageNum: 1,
      perPage: 15,
      searchVal: '',
      sortBy: '',
      filterType: 'DEFAULT',
      sortOrder: 'DESC',
      openDialog: false,
      experienceItem: '',
      popoverAnchorElem: null,
      popoverPostion: {},
      openConfirm: false,
      experienceName: '',
      experienceId: '',
      filterList: [],
      deletePopover: null,
      projectType: '',
      prevProjectType: '',
      projectListSelect: [
        'All Projects',
        'Cyclic Projects',
        'Non Cyclic Projects',
        'Others',
      ],
    }
  }
  componentDidMount() {
    const { projectId = '', clearProject } = this.props
    if (projectId !== '') {
      clearProject()
    }
    this.setState(
      { pageNum: 1, searchVal: '', sortBy: '', sortOrder: 'DESC' },
      () => {
        this.fetchProjects(true)
        if (!this.contentEndRef.current) return
        this.observer = new IntersectionObserver(this.interSectionCallback, {
          threshold: [0.1],
        })
        this.observer.observe(this.contentEndRef.current)
      }
    )
    const sortList = [
      {
        name: 'None',
        value: '',
      },
      {
        name: 'Newest First',
        value: 'DESC',
      },
      {
        name: 'Oldest First',
        value: 'ASC',
      },
    ]

    this.setState({
      sortList,
    })
  }

  interSectionCallback = (entries, observer) => {
    const { fetchProject, projectList, projectListloading, totalProjectCount } =
      this.props
    const { perPage, searchVal, sortBy, pageNum, sortOrder } = this.state
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (!projectListloading && totalProjectCount > projectList.length) {
          fetchProject(
            perPage,
            pageNum + 1,
            searchVal,
            sortBy,
            sortOrder,
            false,
            '',
            '',
            () => {
              this.setState({
                pageNum: this.state.pageNum + 1,
              })
            }
          )
        }
      }
    })
  }

  handleProjectType = (e) => {
    const value = e.target.value
    const { clearProject } = this.props
    var projectTypeVal = ''
    clearProject()
    if (value === 'Cyclic Projects') {
      projectTypeVal = 'CYCLIC'
    } else if (value === 'Non Cyclic Projects') {
      projectTypeVal = 'NON_CYCLIC'
    } else if (value === 'Others') {
      projectTypeVal = 'OTHERS'
    }
    this.setState(
      {
        pageNum: 1,
        searchVal: '',
        sortBy: '',
        sortOrder: '',
        projectType: projectTypeVal,
        prevProjectType: projectTypeVal,
        dropDownSelectedIndex: value,
      },
      () => {
        this.fetchProjects(false)
      }
    )
  }
  handleSort = (e) => {
    const sortBy = e.target.value === 'None' ? '' : 'created_date'
    const { clearProject } = this.props
    if (sortBy === 'None' || sortBy === '') {
      const sortList = this.state.sortList
      sortList[0].name = 'Sort Scene'
      this.setState({
        sortList,
        pageNum: 1,
        searchVal: '',
      })
    }
    clearProject()
    this.setState(
      { pageNum: 1, searchVal: '', sortBy, sortOrder: e.target.value },
      () => {
        this.fetchProjects(false)
      }
    )
  }
  handleCloseSharingExperienceDialog = (bool) => {
    const { clearProject } = this.props
    if (!bool) {
      setTimeout(() => {
        clearProject()
        this.fetchProjects(false)
      }, 1000)
    }
    this.setState({ openDialog: false })
  }
  fetchProjects(isShowSearch) {
    const {
      perPage,
      pageNum,
      searchVal,
      sortBy,
      sortOrder,
      filterType,
      prevProjectType,
    } = this.state
    const { fetchProject } = this.props
    fetchProject(
      perPage,
      pageNum,
      searchVal,
      sortBy,
      sortOrder,
      isShowSearch,
      prevProjectType
    )
  }

  noSearchContent = () => {
    const {
      classes,
      listPageMeta: { searchVal },
    } = this.props
    return (
      <DialogContentText className={classes.noSearchResultContext}>
        <SearchOffRoundedIcon
          sx={{
            width: '120px',
            height: '120px',
            margin: 'auto',
            display: 'block',
          }}
        />
        <Typography
          variant={'h6'}
          label={
            <span>
              No results found for{' '}
              <span className={classes.resultKeyword}>{`"${
                searchVal || ''
              }"`}</span>
            </span>
          }
        />
        <Typography
          variant={'body2'}
          label={`Please make sure your words are spelled correctly or use different keywords.`}
        />
      </DialogContentText>
    )
  }

  updateProjectList = () => {
    this.setState({
      isShowProjectList: false,
    })
  }

  onCross = () => {
    this.props.clearProject()
    this.setState({ pageNum: 1, searchVal: '' }, () => {
      this.fetchProjects(false)
    })
  }

  searchProjects = _.debounce((searchVal) => {
    this.props.clearProject()
    this.setState({ pageNum: 1, searchVal }, () => {
      this.fetchProjects(false)
    })
  }, 300)

  render() {
    const { history } = this.props
    const {
      classes,
      auth: { lanId },
      listPageMeta: { searchVal },
      projectList,
      totalProjectCount,
      projectListloading,
    } = this.props
    const {
      openDialog,
      projectListSelect,
      sortList,
      isShowProjectList,
      projectType,
    } = this.state

    let list = projectListSelect.map((item) => {
      return {
        name: item,
        value: item,
      }
    })

    const isDialog = false
    return (
      <>
        {!isShowProjectList ? (
          <div className={classes.root}>
            <div className={classes.headerWrapper}>
              <div style={{ width: '150px' }}>
                <Select
                  inputLabel="Type"
                  size="small"
                  color={'tertiary'}
                  onChange={this.handleProjectType}
                  options={list}
                  value="All Projects"
                />
              </div>
              <div style={{ marginLeft: 20 }}>
                <PageTitleBar
                  title="All Projects"
                  subTitle={`Browse from ${totalProjectCount} projects`}
                />
              </div>
              <div style={{ marginLeft: 50, marginRight: 'auto' }}>
                {/* {searchVal && totalProjectCount > 0 && (
                  <Typography
                    label={`${totalProjectCount} results found for “${searchVal}”`}
                  />
                )} */}
                <SearchComponent
                  height={40}
                  onSearch={this.searchProjects}
                  onSearchButtonClick={this.onCross}
                  placeholder={`Search in Projects...`}
                  searchVal={searchVal}
                  width={320}
                  brandLogo={searchLogo}
                />
              </div>
              <div style={{ width: 150, marginLeft: 20 }}>
                <Select
                  color={'tertiary'}
                  size="small"
                  inputLabel="Sort"
                  onChange={this.handleSort}
                  options={sortList}
                />
              </div>
            </div>
            <div className={classes.innerContainer}>
              {!projectList ||
                (!projectList.length &&
                  !projectListloading &&
                  this.noSearchContent())}
              {projectList &&
                projectList.map((item, i) => {
                  let experienceCount = item.experience.filter(
                    (item) => item !== null
                  ).length
                  return (
                    <div className={classes.card} key={i}>
                      <ProjectCard
                        date={readableDate(item.created_date)}
                        imgHeight={250}
                        loginId={item.initiator}
                        displayName={item?.initiator_details?.display_name}
                        maxWidth={250}
                        minWidth={250}
                        config={config}
                        isUrlAppendedWithImg={true}
                        avatarBackgroundColor={'#E0E0E0'}
                        avtarTextColor={'#333'}
                        noThumbnail={noThumbnail}
                        sceneCount={experienceCount}
                        footerItems={[
                          {
                            icon: FolderOpenIcon,
                            onClick: () => {
                              this.setState({
                                isShowProjectList: true,
                                projectId: item.project_id,
                                projectName: item.project_name,
                              })
                              history.push('/home/projects/' + item.project_id)
                            },
                            title: 'View Project',
                          },
                        ]}
                        moreMenuItems={[
                          {
                            onClick: (e) => {
                              this.setState({
                                openDialog: true,
                                projectName: item.project_name,
                                prevProjectType: projectType,
                                projectType: item.project_type,
                                cycleName: item.cycle_name,
                                thumbnail: item.project_thumbnail?.image_path,
                                projectId: item.project_id,
                              })
                            },
                            title: 'Edit details',
                          },
                          {
                            onClick: (e) => {
                              this.setState({
                                openDialog: true,
                                prevProjectType: projectType,
                                projectName: item.project_name,
                                projectType: item.project_type,
                                cycleName: item.cycle_name,
                                thumbnail: item.project_thumbnail?.image_path,
                                projectId: item.project_id,
                              })
                            },
                            title: 'Manage thumbnail',
                          },
                        ]}
                        name={item.project_name}
                        thumbnail={item.project_thumbnail?.image_path}
                      />
                    </div>
                  )
                })}
              <div ref={this.contentEndRef} className={classes.contentEnd} />
            </div>
            <div>{projectListloading && <LinearProgress />}</div>
          </div>
        ) : (
          <ScenePage
            isProject={true}
            projectName={this.state.projectName}
            updateProjectList={this.updateProjectList}
            projectId={this.state.projectId}
          />
        )}
        {openDialog && (
          <ManageThumbnailDialog
            openDialog={openDialog}
            handleClose={this.handleCloseSharingExperienceDialog}
            isDialog={isDialog} // Here open as popover, so isDialog will be false
            projectName={this.state.projectName}
            projectType={this.state.projectType}
            cycleName={this.state.cycleName}
            thumbnail={this.state.thumbnail}
            projectId={this.state.projectId}
            config={config}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { projectListData, auth } = state
  const {
    projectList,
    totalProjectCount,
    isShow,
    noResultsFound,
    listPageMeta,
    projectListloading,
  } = projectListData
  return {
    projectList,
    totalProjectCount,
    isShow,
    noResultsFound,
    listPageMeta,
    auth,
    projectListloading,
  }
}

export default connect(mapStateToProps, {
  fetchProject,
  clearProject,
  showNotification,
})(withStyles(styles)(withRouter(ProjectPage)))
