import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import Refresh from '@mui/icons-material/Refresh'
import SaveIcon from '@mui/icons-material/Save'
import DoneIcon from '@mui/icons-material/Done'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import { withStyles } from '@mui/styles'

import { Typography, CustomButton } from 'cgi-ui-components'
import { showExperienceForm } from '../../store/scene/ActionCreator'

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '40px 16px 10px',
    fontSize: 14,
    width: 500,
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    fontSize: 14,
    backgroundColor: theme.palette.primary.black,
  },
}))(MuiDialogActions)

const style = (theme) => ({
  root: {
    backgroundColor: 'rgba(243,243,243,0.8)',
  },
  paper: {},
  MuiButtonBase: {
    backgroundColor: 'inherit',
    width: '50%',
    color: '#FFF',
    padding: '20px 0',
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& i': {
      marginRight: '5px',
    },
  },
  cancelButtonCustomClass: {
    backgroundColor: '#FFFFFF',
    color: '#D0021B',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  helpinText: {
    fontSize: '12px',
    color: '#777',
  },
  primaryTcinText: {
    fontSize: '15px',
    color: '#777',
  },
})
const decodeHtml = (text) => ({ __html: text })

const OverrideSceneConfirmation = ({
  handleClose,
  open,
  handleOverWrite = () => {},
  classes,
  user = {},
  showExperienceForm,
  scene_diff = [],
}) => (
  <Dialog
    open={open}
    PaperProps={{
      square: true,
      elevation: 3,
      classes: { root: classes.paper },
    }}
    BackdropProps={{ classes: { root: classes.root } }}
  >
    <DialogContent>
      <Typography
        variant="subtitle2"
        label={`Scene has been updated by ${user?.display_name} from another tab or window, kindly review these changes`}
      />

      {scene_diff.length > 0 ? (
        <>
          <br />
          {scene_diff.map((i) => (
            <>
              {' '}
              <strong>{i.name}</strong> has been {i.type}
              <br />{' '}
            </>
          ))}
          <br />
        </>
      ) : null}

      <Typography
        variant="p"
        label={
          'please refresh the browser to see the latest changes or click on overwrite to overwrite the changes or save this as new scene with your changes'
        }
      ></Typography>
    </DialogContent>
    <DialogActions>
      <div className={classes.popoverButtons}>
        <CustomButton
          label="Refresh"
          onClick={() => {
            window.location.reload()
          }}
          startIcon={<Refresh />}
          variant="text"
          color={'tertiary'}
        />
        <CustomButton
          sx={{ marginLeft: '10px' }}
          label="Overwrite"
          onClick={handleOverWrite}
          startIcon={<DoneIcon />}
          variant="contained"
          color={'error'}
        />
        <CustomButton
          sx={{ marginLeft: '10px' }}
          label="Save as new "
          onClick={() => {
            showExperienceForm(true, true)
            handleClose()
          }}
          startIcon={<SaveIcon />}
          variant="contained"
          color={'error'}
        />
      </div>
    </DialogActions>
  </Dialog>
)

export const OverrideConfirmationWithStyles = withStyles(style)(
  OverrideSceneConfirmation
)
export default connect((state) => state, { showExperienceForm })(
  OverrideConfirmationWithStyles
)
