import React, { Component } from 'react'
import { shape, bool, string, object } from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles } from '@mui/styles'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

const styles = (theme) => ({
  icon: {
    color: '#ebc204', // Color as per UX
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(4),
    fontSize: 40, // default size
  },
  text: {
    color: '#FFF',
  },
  container: {
    flexGrow: 1,
  },
  appBar: {
    '&.MuiPaper-root': {
      backgroundColor: '#ed6c02',
    },
  },
})
/**
 * Banner to show some actions info
 */
export class Banner extends Component {
  render() {
    const {
      bannerIsShown = false,
      bannerMessage = '',
      classes,
      customStyles = {},
    } = this.props
    return (
      <>
        {bannerIsShown && (
          <div
            className={classNames(classes.container, customStyles.container)}
          >
            <AppBar
              position="static"
              className={classNames(classes.appBar, customStyles.appBar)}
            >
              <Toolbar>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className={classNames(classes.icon, customStyles.icon)}
                />
                <Typography variant="body1" className={classes.text}>
                  {bannerMessage}
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
        )}
      </>
    )
  }
}
Banner.propTypes = {
  bannerIsShown: bool.isRequired,
  bannerMessage: string,
  classes: object,
  customStyles: shape({
    container: object,
    appBar: object,
    icon: object,
    text: object,
  }),
}
const mapStateToProps = (state) => {
  const { banner } = state
  const { isShown, message } = banner
  return {
    bannerIsShown: isShown,
    bannerMessage: message,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Banner))
