import {
  SET_LIBRARY_LIST,
  SET_FAV_LIST_ID,
  SET_SRC_MATERIAL_LIST,
  SET_SELECTED_LIBRARY,
  SET_FAV_LIST,
  UPDATE_SRC_MATERIAL_DATA,
  UPDATE_SELECTED_COLOR,
  UPDATE_SELECTED_TEXTURE,
  UPDATE_FAVS_LIBRARY,
  CLEAR_DATA,
  RESET_TO_ORIGINAL_MATERIAL_LIST,
  FETCH_METADATA_INITIATED,
  FETCH_METADATA_SUCCESS,
  FETCH_MATERIAL_LIST_INITIATED,
} from './actionType'
export const initialState = {
  libraryList: [],
  selectedLibrary: { library_name: 'Favourites' },
  favouriteList: [],
  selectedColor: '#FFFFFF',
  selectedTexture: '',
  favouriteId: '',
  srcMaterialList: [],
  srcMaterialListBackUp: [],
  isLoadingLibDetails: false,
  isLoadingMaterialList: false,
}

export default function materialEditor(state = initialState, action) {
  switch (action.type) {
    case SET_LIBRARY_LIST:
      return {
        ...state,
        libraryList: action.data,
      }
    case SET_FAV_LIST_ID:
      return { ...state, favouriteId: action.data }

    case SET_SRC_MATERIAL_LIST: {
      let { data } = action
      let srcListforStore = data.original_material_metadata_list.map(
        (item) => ({
          ...item,
          selected: false,
        })
      )
      let updatedSrcListForStore = data.updated_material_metadata_list.map(
        (item) => ({
          ...item,
          selected: false,
        })
      )
      return {
        ...state,
        srcMaterialList: updatedSrcListForStore.length
          ? updatedSrcListForStore
          : srcListforStore,
        srcMaterialListBackUp: srcListforStore,
        isLoadingMaterialList: false,
      }
    }
    case SET_SELECTED_LIBRARY: {
      return {
        ...state,
        selectedLibrary: action.data,
      }
    }
    case SET_FAV_LIST: {
      const { data = {} } = action

      return {
        ...state,
        favouriteList: data.library_details,
      }
    }

    case UPDATE_SRC_MATERIAL_DATA: {
      const { data } = action

      let newSrcList = state.srcMaterialList.map((item) => {
        let entry = data.find((dItem) => dItem.material_id === item.material_id)
        if (entry) {
          return entry
        } else {
          return item
        }
      })

      return {
        ...state,
        srcMaterialList: newSrcList,
      }
    }
    case UPDATE_SELECTED_COLOR: {
      return {
        ...state,
        selectedColor: action.data,
      }
    }

    case UPDATE_SELECTED_TEXTURE: {
      return {
        ...state,
        selectedTexture: action.data,
      }
    }

    case UPDATE_FAVS_LIBRARY: {
      const {
        data: { isFavLibSelected, list },
      } = action
      return {
        ...state,
        favouriteList: list,
        selectedLibrary: isFavLibSelected
          ? { ...state.selectedLibrary, library_details: list }
          : state.selectedLibrary,
      }
    }
    case RESET_TO_ORIGINAL_MATERIAL_LIST: {
      return {
        ...state,
        srcMaterialList: [...state.srcMaterialListBackUp],
      }
    }
    case FETCH_METADATA_INITIATED: {
      return { ...state, isLoadingLibDetails: true }
    }
    case FETCH_METADATA_SUCCESS: {
      return { ...state, isLoadingLibDetails: false }
    }
    case CLEAR_DATA: {
      const { shouldClearAllData = false } = action.data
      return {
        ...state,
        ...{
          libraryList: [],
          selectedLibrary: { library_name: 'Favourites' },
          favouriteList: [],
          selectedColor: '#FFFFFF',
          selectedTexture: '',
          favouriteId: '',
          ...(shouldClearAllData && { srcMaterialList: [] }),
          ...(shouldClearAllData && { srcMaterialListBackUp: [] }),
        },
      }
    }
    case FETCH_MATERIAL_LIST_INITIATED: {
      return { ...state, isLoadingMaterialList: true }
    }

    default:
      return state
  }
}
