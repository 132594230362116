import React from 'react'
import { withStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'

const styles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.contrastText,
    width: '26px',
    height: '26px',
    fontSize: '14px',
    color: theme.palette.primary.darkFont,
    marginLeft: '4px',
    '& img': {
      width: '100%',
    },
  },
})

class CustomAvatar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  render() {
    const {
      classes,
      data: { display_name: displayName = 'N A', login_id: loginId = '' } = {},
      customClasses,
    } = this.props
    const imageUrl =
      'http://itgtsites.target.com/User%20Photos/Profile%20Pictures/DHC_' +
      loginId +
      '_MThumb.jpg'
    const arr = displayName.split(' ')

    return (
      <Avatar
        aria-label="Profile"
        className={classes.avatar + ' ' + (customClasses || '')}
      >
        <img
          alt=""
          style={this.state.loaded ? {} : { display: 'none' }}
          src={imageUrl}
          onLoad={() => this.setState({ loaded: true })}
        />
        {
          <span style={this.state.loaded ? { display: 'none' } : {}}>
            {arr.length === 1
              ? arr[0].charAt(0).toUpperCase()
              : arr[0].charAt(0).toUpperCase() +
                '' +
                arr[1].charAt(0).toUpperCase()}
          </span>
        }
      </Avatar>
    )
  }
}

export default withStyles(styles)(CustomAvatar)
