import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import LinearProgress from '@mui/material/LinearProgress'

import { IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Popover from '@mui/material/Popover'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'
import CollectionEditForm from '../collection/collectionDetailsEditForm'
import {
  Select,
  Typography,
  FavouriteCard,
  CustomButton,
} from 'cgi-ui-components'
import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import escIcon from '../../images/assetIcons/default/esc_cta.svg?url'
import { showNotification } from '../../store/notification/actionCreator'
import config from '../../config/config'
import {
  fetchCollection,
  clearCollection,
  fetchAssetsForFavorite,
  addAssetsToCollection,
  setCollectionType,
} from '../../store/favorites/ActionCreator'
import DataList from '../DataList'

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    position: 'absolute',
    bottom: 0,
    margin: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  Modalbody: {
    height: 835,
    width: 1110,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    top: 0,
    background: '#FFF',
    zIndex: 99,
    alignItems: 'center',
    position: 'sticky',
  },
  popOverButtons: {
    marginTop: 10,
    float: 'right',
    marginRight: 15,
  },
  addCancelButton: {
    cursor: 'pointer',
    marginLeft: 20,
    fontSize: 12,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 600,
    textOverflow: 'ellipsis',
  },
  textSectionPopover: {
    margin: 10,
  },
  dropDownRoot: {
    border: '1px solid gray',
    borderRadius: '5px',
  },
  createNewButton: {
    height: 42,
    width: 180,
  },
  crossWrap: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  escButton: {
    padding: 8,
  },
  iconWrapper: {
    width: 15,
    height: 15,
    verticalAlign: 'middle',
    marginRight: 10,
    marginBottom: 6,
  },
  collectionCardItem: {
    width: 180,
  },

  popover: {
    height: 65,
    width: 210,
  },
  paperPopover: {
    borderRadius: 12,
    backgroundColor: theme.palette.primary.popperBlue,
  },
  popOverText: {
    fontSize: 12,
  },
  dialogRoot: {
    zIndex: '1300 !important',
  },
  dialogRootOnError: {
    zIndex: '99 !important',
  },
  newCollectionPopup: {
    borderRadius: 20,
  },
  noResultSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '15%',
  },
  collectionList: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
    minHeight: 500,
  },
  cardWrapperMax: {
    margin: 10,
  },
  collectionListContainer: {
    height: '670px !important',
  },
})

class AddToCollection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropDownArray: ['My Favorites', 'Shared with me'],
      dropDownSelectedIndex: 0,
      anchorEl: null,
      currentSelectedIndex: -1,
      collectionId: '',
      collectionName: '',
      loading: false,
      pageNum: 1,
      perPage: 20,
      searchVal: '',
      sortBy: 'created_date',
      sortOrder: 'DESC',
      assetListPagination: {
        pageNum: 1,
        perPage: 20,
        searchVal: '',
        sortBy: 'created_date',
        sortOrder: 'DESC',
      },
      isCreateCollectionDialogOpened: false,
    }
    this.node = React.createRef()
    this.limit = 0
  }

  componentDidMount() {
    const {
      collectionList: { collectionIndex },
    } = this.props
    const { clearCollection } = this.props
    clearCollection()
    this.setState(
      {
        pageNum: 1,
        searchVal: '',
        sortBy: 'created_date',
        sortOrder: 'ASC',
        isShared: false,
      },
      () => {
        this.fetchCollectionList(true, false, false)
      }
    )
  }
  componentDidUpdate(prevProps) {
    const {
      collectionList: { collectionIndex },
    } = this.props
    if (prevProps.collectionList.collectionIndex !== collectionIndex) {
      const { clearCollection } = this.props
      clearCollection()
      this.setState(
        {
          pageNum: 1,
          searchVal: '',
          sortBy: 'created_date',
          sortOrder: 'ASC',
          isShared: collectionIndex === 1,
        },
        () => {
          this.fetchCollectionList(true, false, false)
        }
      )
    }
  }

  handleCollectionType = (event) => {
    const { clearCollection, setCollectionType } = this.props
    const value = event.target.value
    setCollectionType(value === 'Shared with me' ? 1 : 0)
    clearCollection()

    this.setState(
      {
        pageNum: 1,
        searchVal: '',
        sortBy: 'created_date',
        sortOrder: 'ASC',
        isShared: value === 'Shared with me',
        dropDownSelectedIndex: value,
      },
      () => {
        this.fetchCollectionList(false, false)
      }
    )
  }

  fetchCollectionList(isMiscellaneous = false, isAdded = true) {
    const { email, firstName, lastName, fullName, lanId } = this.props.auth
    const { perPage, pageNum, sortBy, sortOrder, isShared, searchVal } =
      this.state
    const { fetchCollection } = this.props

    const payloadForMiscellaneous = {
      name: 'Miscellaneous',
      owner: {
        first_name: firstName,
        last_name: lastName,
        display_name: fullName,
        email_address: email,
        login_id: lanId,
      },
    }
    console.log(pageNum)
    this.setState({ loading: true })
    fetchCollection(
      perPage,
      pageNum,
      sortBy,
      sortOrder,
      isShared,
      '',
      isMiscellaneous,
      isAdded,
      payloadForMiscellaneous,
      () => {
        this.setState({ loading: false })
      },
      this.onFetchCollectionFail
    )
  }

  selectCollection = (e, data, index) => {
    const { assetListPagination } = this.state
    e.nativeEvent.stopImmediatePropagation()
    this.setState({
      anchorEl: e.currentTarget,
      currentSelectedIndex: index,
      collectionId: data.id,
      collectionName: data.name,
      assetListPagination: {
        ...assetListPagination,
        pageNum: 1,
      },
      loading: false,
    })
  }

  createNewCollection = () => {
    const { handleNewCollection } = this.props
    handleNewCollection()
  }
  handleCreateCollection = () => {
    const { handleNewCollection } = this.props
    handleNewCollection()
    setTimeout(() => {
      this.fetchCollectionList(true, false, false)
    }, 1000)
  }
  handleClosePopOver = () => {
    this.setState({
      anchorEl: null,
      currentSelectedIndex: -1,
      collectionName: '',
      collectionId: '',
    })
  }

  handleAdd = () => {
    this.fetchCollectionAssets(false, () => {
      const {
        collectionList: { assetListSpecificCollection = [], collectionList },
        groupName,
        handleAddToCollection,
        data,
      } = this.props
      const { collectionName } = this.state
      let findIndex = assetListSpecificCollection.findIndex(
        (item) => item.short_description === groupName
      )
      let groupList = collectionList.find(
        (item) => item.name === collectionName
      )
      let found =
        groupList.groups === null
          ? -1
          : groupList.groups.findIndex((item) => item.name === groupName)
      if (findIndex > -1 || found > -1) {
        this.setState({
          isError: true,
        })
        this.handleClosePopOver()
        handleAddToCollection()
        this.props.showNotification(
          true,
          `Failed! ${groupName} already exists. Try another name`,
          'error'
        )
      } else {
        let groupFind = data.find((item) => item.name === groupName)
        let existingGroup = collectionList.find(
          (item) => item.name === collectionName
        )
        this.handleAddingAssetsToCollection(groupFind, existingGroup.groups)
        this.handleClosePopOver()
      }
    })
  }

  handleAddingAssetsToCollection = (data, groups) => {
    const { addAssetsToCollection, auth, groupName, handleClose } = this.props
    const { email, firstName, lastName, fullName, lanId } = auth
    const { collectionName, collectionId } = this.state
    const payload = {
      id: collectionId,
      name: collectionName,
      add_tcin_list: [],
      remove_tcin_list: [],
      groups: groups,
      added_groups: [data],
      removed_groups: [],
      owner: {
        first_name: firstName,
        last_name: lastName,
        display_name: fullName,
        email_address: email,
        login_id: lanId,
      },
    }
    const msgShow = `Success! ${groupName} added to ${collectionName}`
    addAssetsToCollection(
      payload,
      () => {
        this.props.showNotification(true, msgShow, 'success')
        handleClose()
        setTimeout(() => {
          this.fetchCollectionList(true, true, false)
        }, 1000)
        this.limit = 0
        if (!this.interval) {
          this.interval = setInterval(() => {
            if (this.limit < 2) {
              this.fetchCollectionList(true, true, false)
              this.limit++
            }
          }, 30 * 1000)
        }
      },
      () => {
        this.props.showNotification(
          true,
          `Failed! Unable to Save asset`,
          'error'
        )
      }
    )
  }

  fetchCollectionAssets = (addAsset = false, callBack) => {
    const { fetchAssetsForFavorite } = this.props
    const {
      assetListPagination: { perPage, pageNum, searchVal },
      collectionId,
    } = this.state
    this.setState({ loading: true })
    fetchAssetsForFavorite(
      perPage,
      pageNum,
      collectionId,
      searchVal,
      addAsset,
      () => {
        this.setState({ loading: false })
        callBack()
      },
      () => {
        this.setState({ loading: false })
        this.handleClosePopOver()
        this.props.showNotification(
          true,
          `Failed! Unable to Save asset`,
          'error'
        )
      }
    )
  }

  interSectionCallback = (entries, observer) => {
    const {
      collectionList: { collectionList = [], totalCollectionCount },
    } = this.props
    const { pageNum, loading } = this.state
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (totalCollectionCount > collectionList.length && !loading) {
          this.setState({ pageNum: pageNum + 1 }, () =>
            this.fetchCollectionList(false, true, true)
          )
        }
      }
    })
  }

  render() {
    const {
      isOpen,
      handleClose,
      classes,
      groupName,
      isCreateCollectionDialogOpened,
      collectionList: { collectionList = [] },
      auth,
      handleNewCollection,
      isError,
    } = this.props
    const {
      dropDownArray,

      anchorEl,
      loading,
    } = this.state
    const authItem = {
      owner: {
        first_name: auth.firstName,
        email_address: auth.email,
        login_id: auth.lanId,
      },
    }
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    let list = dropDownArray.map((item) => {
      return {
        name: item,
        value: item,
      }
    })

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            root: isError ? classes.dialogRootOnError : classes.dialogRoot,
          }}
          fullWidth={false}
          maxWidth={1110}
          disableEnforceFocus={true}
          ref={this.node}
        >
          <div className={classes.Modalbody}>
            <header className={classes.modalHeader}>
              <div style={{ width: '150px' }}>
                <Select
                  inputLabel="All"
                  size="small"
                  color={'tertiary'}
                  onChange={this.handleCollectionType}
                  options={list}
                  style={{ width: '150px' }}
                  value="My Favorites"
                />
              </div>

              <Typography
                className={classes.headerText}
                label={`Select a Collection to Add - "${groupName}"`}
              ></Typography>

              <CustomButton
                label={'New Collection'}
                startIcon={<AddIcon fontSize="15px" />}
                color="tertiary"
                onClick={handleNewCollection}
                variant="outlined"
                disableElevation
              />

              <div className={classes.crossWrap} onClick={handleClose}>
                <IconButton classes={{ root: classes.escButton }}>
                  <img
                    src={escIcon}
                    alt="close icon"
                    width="40px"
                    height="40px"
                  />
                </IconButton>
              </div>
            </header>

            <DataList
              containerClass={classes.collectionListContainer}
              showProgressBar={loading}
              interSectionCallback={this.interSectionCallback}
            >
              {collectionList &&
                collectionList.map((item, i) => (
                  <div className={classes.cardWrapperMax}>
                    <FavouriteCard
                      name={item.name}
                      assetCount={item.tcin_count}
                      thumbnail={item.thumbnail_path}
                      loginId={item.owner?.login_id}
                      imgHeight={250}
                      maxWidth={250}
                      minWidth={250}
                      noThumbnail={noThumbnail}
                      config={config}
                      isUrlAppendedWithImg={true}
                      avatarBackgroundColor={'#E0E0E0'}
                      avtarTextColor={'#333'}
                      footerItems={[
                        {
                          icon: AddCircleOutlineOutlinedIcon,
                          onClick: (e) => {
                            this.selectCollection(e, item, i)
                          },
                          title: 'Add to this collection',
                        },
                      ]}
                    />
                  </div>
                ))}
              {collectionList.length === 0 && !loading ? (
                <div className={classes.noResultSection}>
                  <i
                    className="fas fa-heart fa-7x"
                    style={{ color: '#CC0000' }}
                  />
                  <p className={classes.noresultcontent}>
                    No collection has been shared with you yet!
                  </p>
                </div>
              ) : null}
            </DataList>
          </div>
        </Dialog>

        <Popover
          id={id}
          open={open}
          className={classes.popoverRoot}
          classes={{
            paper: classes.paperPopover,
          }}
          anchorEl={anchorEl}
          onClose={this.handleClosePopOver}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.popover}>
            <div className={classes.textSectionPopover}>
              <span className={classes.popOverText}>
                Confirm adding into this collection?
              </span>
            </div>
            <div className={classes.popOverButtons}>
              <span
                onClick={this.handleAdd}
                className={classes.addCancelButton}
                style={{ color: 'darkblue' }}
              >
                Add
              </span>
              <span
                onClick={this.handleClosePopOver}
                className={classes.addCancelButton}
                style={{ color: 'maroon' }}
              >
                Cancel
              </span>
            </div>
          </div>
        </Popover>
        {isCreateCollectionDialogOpened && (
          <CollectionEditForm
            openDialog
            handleClose={handleNewCollection}
            collectionItem={authItem}
            onSuccess={this.handleCreateCollection}
            onError={handleNewCollection}
            onCancel={handleNewCollection}
            variant={'create'}
            auth={auth}
          />
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  collectionList: state.collectionData,
  auth: state.auth,
})

export default connect(mapStateToProps, {
  clearCollection,
  fetchCollection,
  fetchAssetsForFavorite,
  addAssetsToCollection,
  showNotification,
  setCollectionType,
})(withStyles(styles)(AddToCollection))
