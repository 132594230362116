import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'

import {
  ERROR_MSG_FOR_COLLECTION_EXIST,
  ERROR_MSG_FOR_UPDATE_COLLECTION,
  ERROR_MSG_FOR_CREATE_COLLECTION,
} from '../../constants/displayMessages'
import {
  updateCollectionDetails,
  createCollection,
} from '../../store/favorites/ActionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import DialogForm from '../DialogForm'
import { CustomButton, FormTextField, ChipSelect } from 'cgi-ui-components'

const styles = (theme) => ({})

const defaultLabels = {
  header: 'Edit details',
  primaryBtn: 'SAVE',
  inprogressBtn: 'SAVING......',
  cancelBtn: 'CANCEL',
}
/**
 * Common Component with its own state for updating the details of a collection for example such as Name.
 * This component have own state as it will be used at different screens
 */
export class CollectionEditForm extends Component {
  state = {
    collectionName: this.props.collectionItem.name, // Setting it from prop as once set it won't rely on prop update
    isCollectionNameExist: false, // flag to show error message at text field in case the updating name already exist
    isSubmitting: false,
  }

  handleChange = (name) => (e) => {
    this.setState({
      [name]: e.target.value,
      isCollectionNameExist: false, // Setting it false on every name change
    })
  }
  handleSuccess = (res) => {
    const { onSuccess, showNotification, variant } = this.props
    //Success
    this.setState({
      isSubmitting: false,
    })
    showNotification(
      true,
      variant === 'create'
        ? `Success! You've created a new collection.`
        : 'Success! New details saved',
      'success'
    )
    onSuccess(res)
  }
  handleError = (error) => {
    const { onError, showNotification, variant } = this.props

    // Error occurred
    this.setState({
      isSubmitting: false,
    })
    const { errorCode = '' } =
      (error && error.response && error.response.data) || {}
    // First check if API call failed because the of name conflict if it is show the error message on the field
    if (errorCode && errorCode === '409') {
      this.setState({ isCollectionNameExist: true })
    } else {
      // else for any other error show error notification and call onError callback
      onError(error)
      showNotification(
        true,
        variant === 'edit'
          ? ERROR_MSG_FOR_UPDATE_COLLECTION
          : ERROR_MSG_FOR_CREATE_COLLECTION,
        'error'
      )
    }
  }
  handleSubmit = () => {
    const { variant, auth, createCollection } = this.props
    const { collectionName } = this.state
    const { collectionItem } = this.props
    this.setState({
      isSubmitting: true,
    })

    if (variant === 'edit') {
      //Make a call first
      const payload = {
        name: collectionName,
        id: collectionItem.id,
      }
      // Call update collection details service
      updateCollectionDetails(payload, this.handleSuccess, this.handleError)
    } else if (variant === 'create') {
      const payload = {
        name: collectionName,
        owner: {
          first_name: auth.firstName,
          last_name: auth.lastName,
          display_name: auth.fullName,
          email_address: auth.email,
          login_id: auth.lanId,
        },
      }
      createCollection(payload, this.handleSuccess, this.handleError)
    }
  }
  handleClearField = (name) => (e) => {
    this.setState({
      [name]: '',
    })
  }

  renderFooterButtons = () => {
    const { handleClose, collectionItem } = this.props
    const { isSubmitting, collectionName, isCollectionNameExist } = this.state
    return (
      <div>
        <CustomButton
          label="Close"
          onClick={handleClose}
          variant="text"
          color={'tertiary'}
          sx={{
            marginRight: '20px',
          }}
        />
        <CustomButton
          label={
            isSubmitting ? <span>Saving......</span> : <span>Save changes</span>
          }
          onClick={this.handleSubmit}
          variant="contained"
          disabled={
            isSubmitting ||
            !collectionName ||
            collectionName === collectionItem.name ||
            isCollectionNameExist
          }
        />
      </div>
    )
  }

  editDetailsDialogContent = () => {
    const { collectionItem, config, auth } = this.props
    const { lanId, fullName = '', email, firstName } = auth
    const { name, owner } = collectionItem
    const { collectionName, isCollectionNameExist } = this.state
    return (
      <div>
        <FormTextField
          value={collectionName}
          label="Collection name"
          onChange={this.handleChange('collectionName')}
          variant="filled"
          helperText={
            isCollectionNameExist ? ERROR_MSG_FOR_COLLECTION_EXIST : ''
          }
          error={isCollectionNameExist}
        ></FormTextField>
        <ChipSelect
          variant="filled"
          disabled={true}
          data={[]}
          label="Owner"
          value={[owner.first_name]}
          size="small"
          color={'tertiary'}
        />
      </div>
    )
  }

  render() {
    const {
      classes,
      onCancel,
      collectionItem,
      marginCustom = 'normal',
      openDialog,
      isDialog = false,
      handleClose = () => {},
      variant = 'edit',
    } = this.props
    const labels = { ...defaultLabels, ...this.props.labels }
    const { owner = {} } = collectionItem || {}
    return (
      <>
        <DialogForm
          openDialog={openDialog}
          handleClose={handleClose}
          isDialog={isDialog} // Here open as popover, so isDialog will be false
          //   config={config}
          headerTitle={variant === 'edit' ? 'Edit details' : 'New collection'}
          headerSubTitle={'Manage collection name'}
          dialogFooterButtons={this.renderFooterButtons()}
        >
          {this.editDetailsDialogContent()}
        </DialogForm>
      </>
    )
  }
}

CollectionEditForm.propTypes = {
  classes: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  collectionItem: PropTypes.object.isRequired,
  customStyles: PropTypes.object,
  headerText: PropTypes.string,
  labels: PropTypes.shape({
    header: PropTypes.string,
    primaryBtn: PropTypes.string,
    inprogressBtn: PropTypes.string,
    cancelBtn: PropTypes.string,
  }),
  auth: PropTypes.object,
  variant: PropTypes.oneOfType(['create', 'edit']),
}
const NOOP = () => {}
CollectionEditForm.defaultProps = {
  onSuccess: NOOP,
  onError: NOOP,
  onCancel: NOOP,
  collectionItem: {},
  customStyles: {},
  headerText: '',
  labels: defaultLabels,
  clearable: false,
  auth: {},
  variant: 'edit',
}

const mapDispatchToProps = {
  showNotification,
  createCollection,
}
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CollectionEditForm))
