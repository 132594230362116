export const SET_LIBRARY_LIST = 'SET_LIBRARY_LIST'
export const SET_FAV_LIST_ID = 'SET_FAV_LIST_ID'
export const SET_SRC_MATERIAL_LIST = 'SET_SRC_MATERIAL_LIST'
export const SET_SELECTED_LIBRARY = 'SET_SELECTED_LIBRARY'
export const SET_FAV_LIST = 'SET_FAV_LIST'
export const UPDATE_SRC_MATERIAL_DATA = 'UPDATE_SRC_MATERIAL_DATA'
export const UPDATE_SELECTED_COLOR = 'UPDATE_SELECTED_COLOR'
export const UPDATE_SELECTED_TEXTURE = 'UPDATE_SELECTED_TEXTURE'
export const UPDATE_FAVS_LIBRARY = 'UPDATE_FAVS_LIBRARY'
export const CLEAR_DATA = 'CLEAR_DATA'
export const RESET_TO_ORIGINAL_MATERIAL_LIST = 'RESET_TO_ORIGINAL_MATERIAL_LIST'
export const FETCH_METADATA_INITIATED = 'FETCH_METADATA_INITIATED'
export const FETCH_METADATA_SUCCESS = 'FETCH_METADATA_SUCCESS'
export const FETCH_MATERIAL_LIST_INITIATED = 'FETCH_MATERIAL_LIST_INITIATED'
