import React, { useState, useEffect } from 'react'
import { fetchRenditionImage } from '../../store/customCard/actionCreator'
import imgDefault from '../../images/ic_default_board_img.png'
import config from '../../config/config'

const CartThumbnail = ({ className, data = {} }) => {
  const [src, setSrc] = useState(imgDefault)
  const fetchImage = () => {
    if (
      data.thumbnail_url &&
      (data.thumbnail_url.includes('scene7') ||
        data.thumbnail_url.includes('gfc-io'))
    ) {
      setSrc(data.thumbnail_url)
    }

    let renditionsUrl = data.thumbnail_url
    if (data.thumbnail_url && !data.thumbnail_url.includes('http')) {
      let isggaUrl =
        data.thumbnail_url.includes('inputs/') ||
        data.thumbnail_url.includes('texturing/')
      renditionsUrl = isggaUrl
        ? config.ggaRenditions + data.thumbnail_url
        : config.renditionsUrl + data.thumbnail_url
    }
    fetchRenditionImage(renditionsUrl, (res) => {
      setSrc(URL.createObjectURL(res))
    })
  }
  useEffect(fetchImage, [data.thumbnail_url])

  return <img src={src} className={className} alt="image" />
}

export default CartThumbnail
