import React from 'react'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Grid } from '@mui/material'
import { fetchExperienceImages } from '../../store/experienceImages/actionCreator'
import { fetchExperienceDetail } from '../../store/experience/ActionCreator'
import RenderImage from '../renderImage'
import RenderCardDisplay from '../renderList/renderCard'
import config from '../../config/config'
import { PlaceHolders } from '../../helpers/placeHolder'
import {
  MSG_FOR_NO_RENDER_IMAGE_AVAILABLE,
  ERROR_MSG_FOR_RENDER_IMAGE_LIST,
  ERROR_MSG_FOR_PREVIEW_RENDER_IMAGE_FAILED,
  ERROR_MSG_FOR_EXPORT_RENDER_IMAGE_FAILED,
  INFO_MSG_FOR_EXPORT_FAILED,
  ERROR_MSG_FOR_DELETE_RENDER_IMAGE_FAILED,
  SUCCESS_MSG_DELETE_RENDER_IMAGE,
} from '../../constants/displayMessages'
import { EXPORTSTS } from '../../constants/status'
import { showNotification } from '../../store/notification/actionCreator'
import { showBanner } from '../../store/banner/actionCreator'
import {
  fetchRenditionImage,
  exportScene,
  deleteRenderImage,
} from '../../store/customCard/actionCreator'
import { saveAnnotationMode } from '../../store/comments/ActionCreator'
import { isExternalUser, canUserWrite } from '../../helpers/utils'
import withRouter from '../HOC/withRouterHOC'
import Notifications from '../../components/Notifications/Notifications'

import {
  PageTitleBar,
  BreadcrumbComponent,
  CustomIconButton,
  Typography,
  CustomButton,
} from 'cgi-ui-components'
import ArrowBack from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import Popover from '@mui/material/Popover'
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,

    height: `calc(100vh - 20px)`,
    overflowY: 'auto',
    margin: '20px 0px',
  },
  renderShotContainer: {
    margin: '25px 0px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardPlaceHolder: {
    height: 370,
    width: 250,
    marginRight: 15,
    marginBottom: 30,
    backgroundColor: theme.palette.tertiary.light,
    display: 'inline-block',
  },
  errorWrapper: {
    marginTop: 220,
    marginLeft: '0%',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    textAlign: 'center',
  },
  noresultcontent: {
    margin: '5px 0',
    fontSize: 34,
    lineHeight: '40px',
    color: theme.palette.primary.darkFont,
    width: '100%',
  },
  infoHeader: {
    margin: '25px 0px 20px',
  },
  mainHeader: {
    display: 'flex',
    margin: '20px 0px',
  },
  renderCard: {
    margin: '0px 15px',
    marginBottom: '30px',
  },
  deletePopover: {
    borderRadius: 5,
    padding: 20,
  },
})
class RenderList extends React.Component {
  state = {
    imageListFlagged: [],
    imageListUnflagged: [],
    imageListLoading: false,
    noDataMsg: '',
    imageObj: {
      show: false,
      img: '',
      imageDetails: {},
    },
    oldItemStatus: [],
    currentExportingJobId: '', // Internal state to track if export is clicked
    exportStatus: {},
    renderIdToDelete: '',
    deletePopover: null,
    experience_name: '',
    initiator: '',
    members: [],
    userHaveWriteAccess: false,
  }
  componentDidMount() {
    this.setState({ imageListLoading: true })
    fetchExperienceImages(
      this.props.sceneId,
      this.fetchImageSuccess,
      this.fetchImageFails
    )
    this.props.fetchExperienceDetail(this.props.sceneId, (data) => {
      const userHaveWriteAccess = canUserWrite(
        this.props.auth.lanId,
        data.initiator,
        data.members
      )
      this.setState({
        experience_name: data.experience_name,
        members: data.members,
        initiator: data.initiator,
        userHaveWriteAccess: userHaveWriteAccess,
      })
    })
    this.triggerInterval = setInterval(() => {
      fetchExperienceImages(
        this.props.sceneId,
        this.fetchImageSuccess,
        this.fetchImageFails
      )
    }, config.renderConfig.autoRefreshTime * (60 * 1000))
  }
  componentWillUnmount() {
    clearInterval(this.triggerInterval)
    this.props.showBanner(false) // Set banner to false
  }
  fetchImageSuccess = (images) => {
    const {
      match: {
        params: { renderId = '' },
      },
    } = this.props

    const flaggedList = images.flagged_renders.map((item) => ({
      ...item,
      flagged: true,
    }))
    this.setState(
      {
        imageListFlagged: flaggedList,
        imageListUnflagged: images.unflagged_renders,
        imageListLoading: false,
        noDataMsg: !(
          images.flagged_renders.length + images.unflagged_renders.length
        )
          ? MSG_FOR_NO_RENDER_IMAGE_AVAILABLE
          : '',
      },
      () => {
        if (renderId) {
          this.state.imageListFlagged.filter((item) => {
            if (item.render_job_id === renderId) {
              this.openPreview(
                item,
                `${config.renditionsUrl}${item.render_jpg_filepath}`,
                item.render_job_id,
                false
              )
            }
          })
          this.state.imageListUnflagged.filter((item) => {
            if (item.render_job_id === renderId) {
              this.openPreview(
                item,
                `${config.renditionsUrl}${item.render_jpg_filepath}`,
                item.render_job_id,
                false
              )
            }
          })
        }
      }
    )
    const { oldItemStatus } = this.state
    let setNewStatus = []
    let currentExportingJobId = ''
    let exportStatus = { ...this.state.exportStatus }
    let anyFailedExport = false //Variable to know if export failed banner needs to be show or not
    images.unflagged_renders.forEach((item) => {
      if (
        item.render_status === 'COMPLETED' &&
        oldItemStatus[item.render_job_id] === 'RENDERING'
      ) {
        setNewStatus[item.render_job_id] = 'RENDERED_NOW'
      } else {
        setNewStatus[item.render_job_id] = item.render_status
      }
      // CHeck if any image exporting is in progress
      if (
        item.render_status === 'COMPLETED' &&
        item['export_status'] === EXPORTSTS.INPROGRESS
      ) {
        currentExportingJobId = item.render_job_id // NOTE: Assumption is only one exporting at time. In case of multiple exports this logic needs to be changed
        exportStatus[item.render_job_id] = EXPORTSTS.INPROGRESS
      }
      //If export status is IN_PROGRESS and API export status is Completed, which means the exporting is now completed
      // NOTE: Only one render can be in complete status at a time from API
      if (
        item.render_status === 'COMPLETED' &&
        item['export_status'] === EXPORTSTS.COMPLETED &&
        exportStatus[item.render_job_id] === EXPORTSTS.INPROGRESS
      ) {
        currentExportingJobId = item.render_job_id
        exportStatus[item.render_job_id] = EXPORTSTS.COMPLETED
      }
      if (
        item.render_status === 'COMPLETED' &&
        item['export_status'] === EXPORTSTS.FAILED
      ) {
        exportStatus[item.render_job_id] = EXPORTSTS.FAILED
        anyFailedExport = true
      }
    })
    this.setState({
      oldItemStatus: setNewStatus,
      currentExportingJobId,
      exportStatus,
    })
    if (exportStatus[currentExportingJobId] === EXPORTSTS.COMPLETED) {
      setTimeout(() => {
        this.setState({
          currentExportingJobId: '',
          exportStatus: {
            ...this.state.exportStatus,
            [currentExportingJobId]: EXPORTSTS.COPY_READY,
          },
        })
      }, 1000)
    }
    //Check if there is any failed export. If it is there then show banner
    if (anyFailedExport) {
      this.props.showBanner(true, INFO_MSG_FOR_EXPORT_FAILED)
    }
  }
  fetchImageFails = (error) => {
    this.setState({
      imageListFlagged: [],
      imageListUnflagged: [],
      imageListLoading: false,
      noDataMsg: ERROR_MSG_FOR_RENDER_IMAGE_LIST,
    })
    this.props.showNotification(true, ERROR_MSG_FOR_RENDER_IMAGE_LIST, 'error')
  }
  openPreview = (item = {}, obj = '', jobId = '', routeToPreview = true) => {
    const { history = {} } = this.props
    if (obj) {
      this.setState(
        {
          imageObj: {
            show: true,
            img: obj,
            imageDetails: item,
          },
        },
        () => {
          if (routeToPreview) {
            history.push(`${item.render_job_id}`)
          }
        }
      )
    }
  }
  closePreview = () => {
    const {
      history = {},
      setPreviewMode = false,
      saveAnnotationMode = () => {},
      match: {
        params: { expId = '' },
      },
    } = this.props

    this.setState(
      {
        imageObj: {
          show: false,
          img: '',
          imageDetails: {},
        },
      },
      () => {
        history.push(`/render/${expId}`)
      }
    )
    fetchExperienceImages(
      this.props.sceneId,
      this.fetchImageSuccess,
      this.fetchImageFails
    )
    saveAnnotationMode(false)
    // setPreviewMode(false)
  }
  openConfirmDialog = (e, obj) => {
    this.setState({
      openConfirm: true,
      deletePopover: e.currentTarget,
      renderIdToDelete: obj.render_job_id,
    })
  }
  closeConfirmDialog = () => {
    this.setState({ openConfirm: false, renderIdToDelete: '' })
  }
  startDownLoad = (renderData, callback = () => {}) => {
    const axiosSource = axios.CancelToken.source()

    fetchRenditionImage(
      renderData.render_jpg_filepath
        ? `${config.renditionsUrl}${renderData.render_jpg_filepath}`
        : '',
      (response) => {
        const element = document.createElement('a')
        element.href = URL.createObjectURL(response)
        element.download = `${this.state.experience_name}.jpg`

        element.click()
        callback()
      },
      (error) => {
        this.props.showNotification(
          true,
          ERROR_MSG_FOR_PREVIEW_RENDER_IMAGE_FAILED,
          'error'
        )
      },
      axiosSource.token
    )
  }

  /**
   * Export Render to max file
   * Obj - CustomCards Props
   */
  exportRender = ({ render_job_id }) => {
    this.setState({
      currentExportingJobId: render_job_id,
      //Replacing the whole object with only one item id, Since only one export is allowed at a time. In case of failed export we do not want to highlight other failed export.
      exportStatus: {
        [render_job_id]: EXPORTSTS.INPROGRESS,
      },
    })
    this.props.showBanner(false) // Show banner as false
    const axiosSource = axios.CancelToken.source()
    exportScene(
      render_job_id, // i.e item.render_job_id
      (res) => {
        // Success Callback
        if (res && res['export_status'] === EXPORTSTS.COMPLETED) {
          this.setState({
            currentExportingJobId: '',
            exportStatus: {
              ...this.state.exportStatus,
              [render_job_id]: EXPORTSTS.COMPLETED,
            },
          })
          setTimeout(() => {
            this.setState({
              exportStatus: {
                ...this.state.exportStatus,
                [render_job_id]: EXPORTSTS.COPY_READY,
              },
            })
          }, 1000)
        }
        if (res && res['export_status'] === EXPORTSTS.INPROGRESS) {
          this.setState({
            exportStatus: {
              ...this.state.exportStatus,
              [render_job_id]: EXPORTSTS.INPROGRESS,
            },
          })
        }
      },
      (error) => {
        // Error Callback
        this.props.showNotification(
          true,
          ERROR_MSG_FOR_EXPORT_RENDER_IMAGE_FAILED,
          'error'
        )
        this.setState({
          currentExportingJobId: '',
          exportStatus: {
            ...this.state.exportStatus,
            [render_job_id]: '',
          },
        })
      },
      axiosSource.token
    )
  }

  /**
   * Delete the render image
   */
  deleteRenderHandler = () => {
    const { renderIdToDelete } = this.state
    const {
      history,
      match: {
        params: { expId = '' },
      },
    } = this.props

    const axiosSource = axios.CancelToken.source()
    deleteRenderImage(
      renderIdToDelete,
      (response) => {
        this.closeConfirmDialog()
        //Show Successful deletion notification
        this.props.showNotification(
          true,
          SUCCESS_MSG_DELETE_RENDER_IMAGE,
          'success'
        )
        //After successful delete re fetch the list
        this.setState(
          {
            imageListLoading: true,
            imageObj: {
              show: false,
              img: '',
              imageDetails: {},
            },
          },
          () => {
            history.push(`/render/${expId}`)
          }
        )
        fetchExperienceImages(
          this.props.sceneId,
          this.fetchImageSuccess,
          this.fetchImageFails
        )
      },
      (error) => {
        this.props.showNotification(
          true,
          ERROR_MSG_FOR_DELETE_RENDER_IMAGE_FAILED,
          'error'
        )
      },
      axiosSource.token
    )
  }

  renderShots = (list, flagged = false) => {
    const { classes, auth } = this.props
    const {
      oldItemStatus,
      openConfirm = false,
      currentExportingJobId,
      exportStatus,
      userHaveWriteAccess,
      experience_name,
    } = this.state
    const length = list.length
    const externalUser = isExternalUser(auth.memberOf)
    return (
      <>
        <div className={classes.infoHeader}>
          <PageTitleBar
            title={flagged ? `Shots Flagged for Review` : 'All renders'}
            subTitle={
              flagged
                ? length === 1
                  ? `1 shot is being reviewed`
                  : `${length} shots are being reviewed`
                : `${length} Previews available`
            }
          />
        </div>
        <Grid
          xs={12}
          md={12}
          lg={12}
          xl={12}
          justify="flex-start"
          className={classes.renderShotContainer}
        >
          {list.map((item) => (
            <div className={classes.renderCard}>
              <RenderCardDisplay
                flagged={flagged}
                item={item}
                isRenderedNow={
                  oldItemStatus[item.render_job_id] === 'RENDERED_NOW'
                }
                openConfirmDialog={this.openConfirmDialog}
                startDownLoad={this.startDownLoad}
                openPreview={this.openPreview}
                userHaveWriteAccess={userHaveWriteAccess}
                experience_name={experience_name}
                currentExportingJobId={currentExportingJobId}
                exportStatus={exportStatus}
                externalUser={externalUser}
                exportRender={this.exportRender}
              />
            </div>
          ))}
        </Grid>
      </>
    )
  }

  renderMainHeader = () => {
    const {
      imageListFlagged = [],
      imageListUnflagged = [],
      experience_name,
    } = this.state

    const {
      history,
      classes,
      match: {
        params: { expId = '' },
      },
    } = this.props

    const totalLength = imageListFlagged.length + imageListUnflagged.length
    const title = experience_name ? `${experience_name} - StyleHub` : 'StyleHub'
    return (
      <>
        <Helmet defaultTitle={title} titleTemplate="%s - StyleHub" />

        <div>
          <div className={classes.mainHeader}>
            <div>
              <CustomIconButton onClick={history.goBack} label={'Back'}>
                <ArrowBack />
              </CustomIconButton>
            </div>
            <div style={{ marginLeft: 20 }}>
              <PageTitleBar
                title={`All render shots & export - ${experience_name}`}
                subTitle={
                  totalLength === 1
                    ? `1 Preview available`
                    : `${totalLength} Previews available`
                }
              />
            </div>
          </div>
          <BreadcrumbComponent
            activeLast
            items={[
              {
                link: '#',
                name: 'Home',
                onClick: (e) => {
                  e.preventDefault()
                  history.push('/home')
                },
                props: {
                  color: 'inherit',
                  underline: 'hover',
                },
              },
              {
                link: '#',
                onClick: (e) => {
                  e.preventDefault()
                  history.push(`/experience/${expId}`)
                },
                name: `${experience_name}`,
                props: {
                  color: 'inherit',
                  underline: 'hover',
                },
              },
              {
                link: '#',
                onClick: (e) => {
                  e.preventDefault()
                  history.push(`/experience/${expId}?rendermode=true`)
                },
                name: `Render scene`,
                props: {
                  color: 'inherit',
                  underline: 'hover',
                },
              },

              {
                name: 'Preview render shots and exports',
                props: {
                  color: 'inherit',
                  underline: 'hover',
                },
              },
            ]}
          />
        </div>
      </>
    )
  }

  renderDeletePopUp = () => {
    const { classes } = this.props

    return (
      <Popover
        open={Boolean(this.state.deletePopover)}
        anchorEl={this.state.deletePopover}
        onClose={() => {
          this.setState({
            deletePopover: null,
          })
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
      >
        <div className={classes.deletePopover}>
          <Typography variant="subtitle2" label={'Confirm Delete?'} />
          <Typography
            sx={{
              marginTop: '10px',
              marginBottom: '20px',
              color: '#000000',
              opacity: '0.6',
            }}
            variant="body2"
            label={'Action can’t be undone'}
          />
          <div className={classes.popoverButtons}>
            <CustomButton
              label="Cancel"
              onClick={() => {
                this.setState({
                  deletePopover: null,
                })
              }}
              startIcon={<CloseIcon />}
              variant="text"
              color={'tertiary'}
            />
            <CustomButton
              sx={{ marginLeft: '10px' }}
              label="Delete"
              onClick={this.deleteRenderHandler}
              startIcon={<DeleteIcon />}
              variant="contained"
              color={'error'}
            />
          </div>
        </div>
      </Popover>
    )
  }
  render() {
    const { classes, auth, match, history } = this.props
    const {
      imageListFlagged = [],
      imageListUnflagged = [],
      imageObj = {},
      imageListLoading,
      noDataMsg,
      openConfirm = false,
      currentExportingJobId,
      exportStatus,
      userHaveWriteAccess,
      experience_name,
    } = this.state
    const flaggedList = imageListFlagged
    const totalLength = imageListFlagged.length + imageListUnflagged.length
    const externalUser = isExternalUser(auth.memberOf)
    return (
      <div className={classes.root}>
        <Notifications />
        {this.renderMainHeader()}
        {!imageListLoading && !totalLength ? (
          <div className={classes.errorWrapper}>
            <p className={classes.noresultcontent}>{noDataMsg}</p>
          </div>
        ) : null}
        {imageObj.show && (
          <RenderImage
            imageData={imageObj}
            openConfirmDialog={this.openConfirmDialog}
            closePreview={this.closePreview}
            currentExportingJobId={currentExportingJobId}
            exportStatus={exportStatus}
            userHaveWriteAccess={userHaveWriteAccess}
            externalUser={externalUser}
            exportRender={this.exportRender}
            startDownLoad={this.startDownLoad}
            history={history}
            match={match}
            experience_name={experience_name}
          />
        )}
        {totalLength > 0 ? (
          <>
            {flaggedList.length > 0 && (
              <div>{this.renderShots(flaggedList, true)}</div>
            )}
            <div>{this.renderShots(imageListUnflagged)}</div>
          </>
        ) : null}

        {imageListLoading ? (
          <PlaceHolders
            placeholderCount={5}
            customClass={classes.cardPlaceHolder}
          />
        ) : null}
        {openConfirm && this.renderDeletePopUp()}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps, {
  showNotification,
  showBanner,
  saveAnnotationMode,
  fetchExperienceDetail,
})(withStyles(styles)(withRouter(RenderList)))
