import { cloneDeep } from 'lodash'

import {
  UPDATE_COLLECTION_ITEM,
  UPDATE_COLLECTION_NAME,
  GET_COLLECTION_LIST,
  CREATE_COLLECTION,
  ADD_LIST_PAGE_META,
  NO_COLLECTION_FOUND,
  API_CALL_FAIL_COLLECTION_LIST,
  CLEAR_COLLECTION_LIST,
  SAVE_MISCELLANEOUS_TO_COLLECTION_LIST,
  API_CALL_INITIATED,
  SAVE_COLLECTION_LIST,
  CLEAR_FAVOURITE_ASSET_LIST,
  DELETE_COLLECTION,
  COLLECTION_TYPE,
} from './ActionType'

export const collectionState = {
  collectionList: [],
  totalCollectionCount: 0,
  responseCollectionCount: 0,
  collectionListloading: false,
  listPageMeta: {},
  noResultsFound: false,
  totalAssetsCount: 0,
  assetListSpecificCollection: [],
  collectionIndex: 0,
  completeAssetListSpecificCollection: [],
}

export default function favoritesReducer(state = collectionState, action = {}) {
  switch (action.type) {
    case CREATE_COLLECTION: {
      return {
        ...state,
        totalAssetsCount: 0,
      }
    }
    case API_CALL_INITIATED: {
      return {
        ...state,
        collectionListloading: true,
      }
    }
    case COLLECTION_TYPE: {
      return {
        ...state,
        collectionIndex: action.payload,
      }
    }

    case ADD_LIST_PAGE_META: {
      return { ...state, listPageMeta: { ...action.data } }
    }

    case SAVE_MISCELLANEOUS_TO_COLLECTION_LIST: {
      const { data } = action
      const { id, name, owner, created_date } = data
      const list = [
        {
          id: id,
          name: name,
          owner: owner,
          tcin_list: null,
          tcin_details: null,
          updated_date: null,
          shared_users: null,
          created_date:
            created_date && created_date != null ? created_date : null,
        },
      ]
      const resultObj = {
        apiError: action.success,
        collectionList: list,
        collectionListloading: false,
        noResultsFound: false,
        totalCollectionCount: 1,
        responseCollectionCount: 1,
      }
      return { ...state, ...resultObj }
    }

    case GET_COLLECTION_LIST: {
      const { data } = action
      const {
        total_count: totalRecords,
        response_count: responseRecords,
        search_response: list,
        isAdded,
      } = data

      const resultObj = {
        apiError: action.success,
        collectionList: isAdded ? [...state.collectionList, ...list] : list,
        collectionListloading: false,
        noResultsFound: false,
        totalCollectionCount: totalRecords,
        responseCollectionCount: responseRecords,
      }
      return { ...state, ...resultObj }
    }

    case SAVE_COLLECTION_LIST: {
      const { data } = action
      const { search_response: searchResponse, isAddedAssest } = data
      const {
        tcin_document_list,
        tcin_details,
        tcin_list,
        name,
        groups = [],
      } = searchResponse[0]
      const collectionIndex = state.collectionList.findIndex(
        (item) => item.name === name
      )
      let collectionListData = cloneDeep(state.collectionList)
      collectionListData[collectionIndex].groups = groups
      let list =
        tcin_details && tcin_details !== null
          ? tcin_details
          : tcin_document_list && tcin_document_list !== null
          ? tcin_document_list
          : []
      const totalCount = searchResponse[0].tcin_count
      const resultObj = {
        apiError: action.success,
        assetListSpecificCollection: isAddedAssest
          ? [...state.assetListSpecificCollection, ...list]
          : list,
        completeAssetListSpecificCollection:
          tcin_list && tcin_list !== null ? tcin_list : [],
        collectionListloading: false,
        noResultsFound: false,
        totalAssetsCount: totalCount,
        collectionList: collectionListData,
      }
      return { ...state, ...resultObj }
    }

    case NO_COLLECTION_FOUND: {
      return {
        ...state,
        noResultsFound: true,
        collectionListloading: false,
        totalCollectionCount: 0,
        collectionList: [],
      }
    }

    case CLEAR_COLLECTION_LIST: {
      const emptyList = {
        collectionList: [],
        noResultsFound: false,
        assetListSpecificCollection: [],
        completeAssetListSpecificCollection: [],
      }
      return { ...state, ...emptyList }
    }

    case API_CALL_FAIL_COLLECTION_LIST: {
      return {
        ...state,
        apiError: action.error,
        collectionListloading: false,
      }
    }

    case CLEAR_FAVOURITE_ASSET_LIST: {
      return {
        ...state,
        assetListSpecificCollection: [],
        completeAssetListSpecificCollection: [],
      }
    }
    case UPDATE_COLLECTION_ITEM: {
      const { reqData, resData } = action
      const { collectionList } = state

      const updatedList = collectionList.map((item) => {
        if (item.id === resData.id) {
          item = {
            ...item,
            ...{
              owner: resData.owner,
              shared_users: reqData.shared_users,
              favourite_visibility: reqData['favourite_visibility'] || null,
            },
          }
        }
        return item
      })
      return { ...state, ...{ collectionList: updatedList } }
    }

    case UPDATE_COLLECTION_NAME: {
      const { data = {} } = action
      const { collectionList } = state
      const { id, name } = data
      if (!(id && name)) {
        return { ...state }
      }
      const updatedList = collectionList.map((item) => {
        if (item.id === id) {
          item = {
            ...item,
            ...{
              name,
            },
          }
        }
        return item
      })
      return { ...state, ...{ collectionList: updatedList } }
    }

    case DELETE_COLLECTION: {
      const { id = '' } = action
      const { collectionList } = state
      const updatedList = collectionList.filter((item) => item.id !== id)
      return {
        ...state,
        ...{
          collectionList: updatedList,
          totalCollectionCount: state.totalCollectionCount - 1,
        },
      }
    }
    default:
      return state
  }
}
