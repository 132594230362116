import axios from 'axios'
import {
  START_TEMPLATE_LIST,
  SUCCESS_TEMPLATE_LIST,
  FAILURE_TEMPLATE_LIST,
  TOGGLE_TEMPLATE_MODAL,
  SEARCH_TEMPLATE,
  SUCCESS_TEMPLATE_DETAILS,
  FAILURE_TEMPLATE_DETAILS,
  DOWNLOAD_TEMPLATE_FAIL,
  DOWNLOAD_TEMPLATE_SUCCESS,
  CLEAR_TEMPLATE_THUMBNAIL_DATA,
  TEMPLATE_THUMBNAIL_IMG_SUCCESS,
  TEMPLATE_DOWNLOAD_STARTED,
  SET_TEMPLATE_EDIT_MODE,
  SELECT_TEMPLATE,
  CLEAR_TEMPLATE_LIST,
  SAVE_SEARCH_QUERY,
  ADD_BUSINESS_DEPARTMENT_LIST,
} from './actionType'
import apiConfig from '../../config/config'
import { fetchRenditionImage } from '../customCard/actionCreator'

const KEY = apiConfig.apiKey

export function startTemplateList(templateListOnly) {
  return {
    type: START_TEMPLATE_LIST,
    payload: { templateListOnly },
  }
}
export function saveSearchquery(query) {
  return {
    type: SAVE_SEARCH_QUERY,
    payload: { query },
  }
}
export function templateListSuccess(res) {
  return {
    type: SUCCESS_TEMPLATE_LIST,
    payload: res,
  }
}
export function templateListFailed(error) {
  return {
    type: FAILURE_TEMPLATE_LIST,
    payload: error,
  }
}

export function templateDetailsSuccess(res) {
  return {
    type: SUCCESS_TEMPLATE_DETAILS,
    payload: res,
  }
}
export function templateDetailsFailed(error) {
  return {
    type: FAILURE_TEMPLATE_DETAILS,
    payload: error,
  }
}

export function toggleTemplateModal(bool, shouldChangeTemplate = false) {
  return {
    type: TOGGLE_TEMPLATE_MODAL,
    payload: { bool, shouldChangeTemplate },
  }
}

export function searchTemplate(searchQuery) {
  return {
    type: SEARCH_TEMPLATE,
    payload: searchQuery,
  }
}

export function fetchTemplates(
  pageNum = 1,
  size = 20,
  search = '',
  team = '',
  renderer = 'V-Ray',
  sort = 'DESC',
  onSuccessCallBack = () => {},
  onErrorCallBack = () => {},
  isAllUpload = true
) {
  return function (dispatch) {
    dispatch(startTemplateList())
    return axios
      .get(
        `${apiConfig.template.listTemplate}?page_size=${size}&page=${pageNum}&search_term=${search}&team=${team}&renderer=${renderer}&sort_by_date=${sort}&all_uploads=${isAllUpload}`
      )
      .then((response) => {
        dispatch(templateListSuccess({ data: response.data }))
        onSuccessCallBack()
      })
      .catch((error) => {
        dispatch(templateListFailed(error))
        onErrorCallBack()
      })
  }
}

export function fetchTemplateDetails(
  templateId,
  onSuccess = () => {},
  onFailure = () => {}
) {
  return function (dispatch) {
    return axios
      .get(apiConfig.template.templateList + '/' + templateId)
      .then((response) => {
        dispatch(templateDetailsSuccess(response.data))
        onSuccess(response.data)
        fetchRenditionImage(
          `${apiConfig.renditionsUrl}${response.data.template_thumbnail_url}`,
          (response) => {
            dispatch(templateThumbnailImgSuccess(URL.createObjectURL(response)))
          },
          () => {
            dispatch(templateThumbnailImgSuccess(''))
          }
        )
      })
      .catch((error) => {
        dispatch(templateDetailsFailed(error))
        onFailure(error)
      })
  }
}

export function downloadTemplate(templateName, templateImageUrl) {
  return function (dispatch) {
    dispatch(templateDownloadStarted(true))
    return axios
      .get(`${apiConfig.renditionsUrl}${templateImageUrl}`)
      .then((res) => {
        const element = document.createElement('a')
        element.href = URL.createObjectURL(res.data)
        element.download = `${templateName}.jpg`
        element.click()
        dispatch(templateDownloadSuccess())
      })
      .catch((error) => {
        dispatch(templateDownloadFailure())
      })
  }
}

export function templateDownloadSuccess() {
  return {
    type: DOWNLOAD_TEMPLATE_SUCCESS,
  }
}

export function clearTemplateList() {
  return {
    type: CLEAR_TEMPLATE_LIST,
  }
}

export function templateDownloadFailure() {
  return {
    type: DOWNLOAD_TEMPLATE_FAIL,
  }
}
export function clearTemplateThumbnailData() {
  return {
    type: CLEAR_TEMPLATE_THUMBNAIL_DATA,
  }
}

export function templateThumbnailImgSuccess(response) {
  return {
    type: TEMPLATE_THUMBNAIL_IMG_SUCCESS,
    payload: response,
  }
}

export function templateDownloadStarted(data) {
  return {
    type: TEMPLATE_DOWNLOAD_STARTED,
    payload: data,
  }
}

export function setTemplateEditMode(editTemplateMode = false, templateId = '') {
  return {
    type: SET_TEMPLATE_EDIT_MODE,
    payload: { editTemplateMode, templateId },
  }
}
export function selectTemplate(data) {
  return {
    type: SELECT_TEMPLATE,
    data,
  }
}
function onSuccessBusinesDepartments(data) {
  return {
    type: ADD_BUSINESS_DEPARTMENT_LIST,
    data,
  }
}
export function fetchBusinessDepartments() {
  return function (dispatch) {
    return axios
      .get(apiConfig.template.getBusinessDepts)
      .then((response) => {
        dispatch(onSuccessBusinesDepartments(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
