import axios from 'axios'
import { connect } from 'react-redux'
import React from 'react'
import apiConfig from '../config/config'
import { showNotification } from '../store/notification/actionCreator'

/*

Interceptor adds useful handlers to axios requests and responses.

If the user has an accessToken, it is used as the value for the
Authorization header. This applies to all requests to .target.com and
.tgt domains. You can disable this per request by passing in this config:
{headers: {Authorization: false}}

If an axios response is an error, it is handled based on the error code:
- 401: prompt the user to sign in
- 403: notify the user that they don't have permission to do that
- Other: show the axios error message to the user

When the component unmounts, the interceptors are removed.

*/
export class Interceptor extends React.Component {
  static state = {
    authInterceptor: undefined,
    errorInterceptor: undefined,
  }

  componentDidMount() {
    this.addAuthInterceptor()
    this.addErrorInterceptor()
  }

  componentWillUnmount() {
    this.removeAuthInterceptor()
    this.removeErrorInterceptor()
  }

  addAuthInterceptor = () => {
    const authInterceptor = axios.interceptors.request.use(
      (config) => {
        if (
          this.props.nuidAccessToken &&
          config.url.includes &&
          config.url.includes('gallery')
        ) {
          config.headers.Authorization = this.props.nuidAccessToken
          if (!config.url.includes('gallery2')) {
            config.headers['x-api-key'] = this.props.galleryApiKey
          }
        } else if (config.url.includes && !config.url.includes('gallery')) {
          if (!config.headers.hasOwnProperty('Authorization')) {
            if (this.props.accessToken && this.isTargetDomain(config.url)) {
              config.headers.Authorization = this.props.accessToken
            }
          } else if (!config.headers.Authorization) {
            delete config.headers.Authorization
          }
          if (!config.headers.hasOwnProperty('login_id')) {
            if (this.props.lanId && this.isTargetDomain(config.url)) {
              config.headers.login_id = this.props.lanId
            }
          } else if (!config.headers.login_id) {
            delete config.headers.login_id
          }
          if (!config.headers.hasOwnProperty('x-api-key')) {
            if (this.props.xApiKey && this.isTargetDomain(config.url)) {
              config.headers['x-api-key'] = this.props.xApiKey
            }
          } else if (!config.headers['x-api-key']) {
            delete config.headers['x-api-key']
          }
          if (!config.headers.hasOwnProperty('x-id-token')) {
            if (this.props.xIdToken && this.isTargetDomain(config.url)) {
              config.headers['x-id-token'] = this.props.xIdToken
            }
          } else if (!config.headers['x-id-token']) {
            delete config.headers['x-id-token']
          }

          if (config.url.includes('renditions')) {
            config.responseType = 'blob'
          }
          if (config.url.includes('chunk_uploads')) {
            delete config.headers['login_id']
            config.headers['x-api-key'] = apiConfig.fileUploadApiKey
            delete config.headers['x-id-token']
            config.headers.post['Content-Type'] = 'application/json'
          }
        }
        config.headers['Content-Type'] = 'application/json'

        // xIdToken

        return config
      },
      (error) => Promise.reject(error)
    )
    this.setState({ authInterceptor })
  }

  removeAuthInterceptor = () => {
    axios.interceptors.request.eject(this.state.authInterceptor)
    this.setState({ authInterceptor: undefined })
  }

  addErrorInterceptor = () => {
    const errorInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const code = error.response.status
          const url = error.response?.config?.url
          if (
            url &&
            url.includes &&
            (url.includes('get_token') || url.includes('gallery'))
          ) {
            if (code === 401 || code === 403) {
              this.props.showNotification(
                true,
                'Error while accessing data from gallery',
                'error'
              )
              return Promise.reject(error)
            }
          }
          if (code === 401) {
            this.signIn()
          } else {
            //let message = 'Something went wrong.'
            if (code === 403) {
              //message = 'You’re not authorized to do that.'
              // this.props.showNotification(true, message)
            }
          }
        }
        return Promise.reject(error)
      }
    )
    this.setState({ errorInterceptor })
  }

  removeErrorInterceptor = () => {
    axios.interceptors.request.eject(this.state.errorInterceptor)
    this.setState({ errorInterceptor: undefined })
  }

  // hostname ends with .target.com or .tgt
  isTargetDomain = (url) =>
    /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i.test(url)
  signIn() {
    window.location.reload()
  }

  render() {
    return null
  }
}

const mapStateToProps = ({ auth }) => ({
  accessToken: auth.accessToken,
  lanId: auth.lanId,
  xApiKey: apiConfig.apiKey,
  xIdToken: auth.xIdToken,
  nuidAccessToken: auth.nuidTokens.accessToken,
  galleryApiKey: apiConfig.galleryApiKey,
})

export default connect(mapStateToProps, { showNotification })(Interceptor)
