import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { InputBase, Grid, MenuItem, Select } from '@mui/material'
import { withStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'

import { debounce } from 'lodash'

import {
  PageTitleBar,
  AssetCard,
  Select as SelectComponent,
  SearchComponent,
  FavouriteCard,
  CustomIconButton,
} from 'cgi-ui-components'

import {
  fetchCollection,
  clearCollection,
  clearFavouriteAssets,
  fetchAssetsForFavorite,
  setCollectionType,
} from '../../../store/favorites/ActionCreator'
import DataList from '../../DataList'
import FavouriteAssets from './FavouriteAssets'
import { showNotification } from '../../../store/notification/actionCreator'

import { Minimize } from '@mui/icons-material'

import CropFreeIcon from '@mui/icons-material/CropFree'
import PushPinIcon from '@mui/icons-material/PushPin'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'

import { IconButton } from '@mui/material'

import config from '../../../config/config'
import noThumbnail from '../../../images/ic_no-thumbnail.svg?url'
import searchLogo from '../../../images/enterprise/searchLogo.svg?url'
import Close from '@mui/icons-material/Close'
import ExplorerSearch from '../ExplorerSearch'
import { FAVOURITE_DROPDOWN_VALUES } from '../../../helpers/utils'

const styles = (theme) => ({
  FavPanel: {
    position: 'fixed',
    width: 480,
    top: 75,
    bottom: 50,
    left: 85,
    backgroundColor: '#FFF',
    zIndex: 99,
  },
  maxFavPanel: {
    position: 'fixed',
    top: 75,
    bottom: 50,
    left: 85,
    right: 10,
    backgroundColor: '#FFF',
    zIndex: 1200,
  },

  datalistContainer: {
    height: 'Calc(100vh - 125px) !important',
    alignContent: 'start',
  },
  explorerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  explorerTitle: {
    color: theme.palette.primary.mediumGrey,
    fontSize: '16px',
  },

  explorerAction: {
    position: 'absolute',
    right: '0',
  },
  explorerSearch: {
    clear: 'both',
    margin: '15px 12px 15px 0',
    borderRadius: '4px',
    border: `1px solid #000`,
  },
  maxExplorerTitle: {
    color: theme.palette.primary.mediumGrey,
    fontSize: '16px',
    float: 'left',
    marginRight: 15,
    maxWidth: 400,
  },
  maxExplorerSearch: {
    borderRadius: '2px',
    width: 512,
    float: 'left',
  },
  button: {
    fontSize: 16,
  },
  searchBtn: {
    fontSize: 16,
    padding: 10,
    right: 0,
    position: 'absolute',
  },
  btnThumbtack: {
    margin: '0 0 0 2px',
  },
  pinThumbtack: {
    margin: '0 0 0 2px',
    color: theme.palette.primary.main,
  },
  externalLinkAlt: {
    fontSize: 16,
    margin: '-8px 0px 0 -222px',
    transform: 'rotate(180deg)',
  },
  explorerTemplate: {
    padding: '0 20px 0px 20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  maxExplorerTemplate: {
    padding: '10px 20px 50px 20px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },

  inputWrapperMin: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 5px',
  },

  input: {
    width: '100%',
  },

  noResultText: {
    color: theme.palette.primary.darkFont,
    fontSize: '14px',
    textAlign: 'center',
  },
  subText: {
    color: theme.palette.primary.darkFont,
    fontSize: '12px',
    textAlign: 'center',
  },
  emptyResult: {
    margin: '140px auto',
  },
  emptySearchIcon: {
    margin: '0 150px',
    color: theme.palette.primary.contentLight,
  },
  searchWidth: {
    width: '80%',
  },
  inputText: {
    fontSize: 14,
    padding: '8px 0',
  },
  headerSection: {
    position: 'sticky',
    zIndex: 77,
    background: '#FFF',
    top: 0,
    width: '100%',
    padding: '20px 20px 0 20px',
  },
  maxHeaderSection: {
    position: 'sticky',
    zIndex: 77,
    background: '#FFF',
    width: '100%',
    top: 0,
    padding: 20,
  },

  anchorText: {
    cursor: 'pointer',
    color: theme.palette.primary.linkColor,
    fontWeight: 500,
  },
  subAnchorText: {
    color: `rgba(0, 0, 0, 0.6)`,
  },
  favText: {
    fontSize: 14,
    margin: '0px 10px 25px 5px',
    paddingTop: 6,
  },
  favTextMax: {
    fontSize: 14,
    margin: '10px 10px 0px 0px',
    paddingTop: 6,
  },
  noresultcontent: {
    textAlign: 'center',
  },
  searchResult: {
    marginTop: 0,
    paddingLeft: 3,
    clear: 'both',
  },
  wrapper: {
    borderRadius: '2px',
    display: 'flex',
    border: `1px solid ${theme.palette.primary.light}`,
  },
  inputWrapper: {
    width: 510,
    border: '1px solid ' + theme.palette.primary.explorerBorder,
    background: theme.palette.primary.light,
    position: 'relative',
    borderRadius: 2,
    display: 'flex',
  },
})
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
    fontSize: 14,
    marginRight: 10,
  },
  input: {
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    borderRadius: 2,
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 2,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    width: 105,
    height: 18,
  },
}))(InputBase)

const IconBtn = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.secondaryText,
  fontSize: 14,
  borderRadius: 0,
  '&:hover': {
    background: 'none',
  },
}))

class Favourites extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      maxExplorer: false,
      pageNum: 1,
      perPage: 45,
      searchVal: '',
      sortBy: 'created_date',
      sortOrder: 'DESC',
      focussed: false,
      loading: false,
      isPinned: false,
      openSupportForm: false,
      selectedIndex: false,
      isAdding: false,
      openFavDrawer: false,
      dropDownArray: FAVOURITE_DROPDOWN_VALUES,
      dropDownSelectedIndex: 0,
      collectionId: '',
      collectionName: '',
      isSelectorOpen: false,
      selectedType: 0,
      isShared: false,
      assetListPagination: {
        pageNum: 1,
        perPage: 45,
        searchVal: '',
        sortBy: 'created_date',
        sortOrder: 'DESC',
      },
    }
    this.node = React.createRef()
  }

  componentDidMount() {
    const {
      clearCollection = () => {},
      collectionList = [],
      collectionPagination: {
        recordsPerPage: perPage,
        pageNum,
        isShared,
        searchVal = '',
        sortOrder,
      } = {},
      isMaxExplorer = false,
    } = this.props

    if (isShared || collectionList.length === 0) {
      clearCollection()
      this.fetchCollectionList(true, true, false)
      this.setState({ maxExplorer: isMaxExplorer })
    } else {
      this.setState({
        perPage,
        pageNum,
        sortOrder,
        maxExplorer: isMaxExplorer,
        searchVal,
      })
    }
    document.addEventListener('click', this.closeFavourites)
  }
  componentDidUpdate(prevProps) {
    const { clearFavouriteAssets, collectionIndex } = this.props
    if (prevProps.collectionIndex != collectionIndex) {
      clearFavouriteAssets()
      this.setState(
        {
          selectedType: collectionIndex,
          isShared: collectionIndex == '1',
          pageNum: 1,
          searchVal: '',
          sortBy: 'created_date',
          sortOrder: 'ASC',
          collectionId: '',
          collectionName: '',
        },
        () => {
          const { clearCollection } = this.props
          clearCollection()
          this.fetchCollectionList(true, false, false)
        }
      )
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.closeFavourites)
  }

  expandFavourites = (e) => {
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ maxExplorer: true })
  }

  closeFavourites = (e) => {
    const { isPinned, maxExplorer } = this.state
    const isTooltip = e.target.closest('.MuiTooltip-popper')
    if (
      e.defaultPrevented ||
      this.node.current.contains(e.target) ||
      isPinned ||
      maxExplorer ||
      isTooltip
    ) {
      return false
    }

    const { closeFavDrawer, setCollectionType } = this.props
    closeFavDrawer()
    setCollectionType(0)
  }

  handleClose = (event) => {
    event && event.nativeEvent && event.nativeEvent.preventDefault()
  }

  minimizeExplorer = (e) => {
    e.nativeEvent.stopImmediatePropagation()
    this.setState({ maxExplorer: false })
    // this.props.handleCheckMax(false)
  }

  handleSearchChange = (e) => {
    const { collectionId, assetListPagination } = this.state
    const searchVal = e?.target?.value || e
    const pageNum = 1
    if (collectionId !== '') {
      this.setState(
        {
          loading: true,
          assetListPagination: {
            ...assetListPagination,
            searchVal: searchVal,
            pageNum,
          },
        },
        () => {
          this.searchFavouriteAssets(searchVal)
        }
      )
    } else {
      this.setState({ loading: true, searchVal: searchVal, pageNum }, () => {
        this.searchCollection(searchVal)
      })
    }
  }

  handleOnFocus(status) {
    this.setState({ focussed: status })
  }

  clearSearchField = () => {
    const { collectionId, assetListPagination } = this.state
    if (collectionId !== '') {
      this.setState(
        {
          loading: true,
          assetListPagination: {
            ...assetListPagination,
            searchVal: '',
            pageNum: 1,
          },
        },
        () => {
          this.searchFavouriteAssets('')
        }
      )
    } else {
      this.clearCollectionSearch()
    }
  }

  pinExplorer = () => {
    const { isPinned } = this.state
    this.setState({ isPinned: !isPinned })
  }

  fetchCollectionList(
    isShowSearchOptionCheck = false,
    isMiscellaneous = false,
    isAdded = false
  ) {
    const {
      perPage,
      pageNum,
      sortBy,
      sortOrder,
      isShared,
      searchVal,
      selectedType,
    } = this.state
    const { fetchCollection, email, firstName, lastName, fullName, lanId } =
      this.props

    const payloadForMiscellaneous = {
      name: 'Miscellaneous',
      owner: {
        first_name: firstName,
        last_name: lastName,
        display_name: fullName,
        email_address: email,
        login_id: lanId,
      },
    }
    this.setState({ loading: true })
    fetchCollection(
      perPage,
      pageNum,
      sortBy,
      sortOrder,
      isShared,
      searchVal,
      isMiscellaneous,
      isAdded,
      payloadForMiscellaneous,
      (collectionArray) => {
        this.setState({ loading: false })
        if (isShowSearchOptionCheck) {
          this.setState({
            isShowSearchBar: collectionArray.length > 0 ? true : false,
          })
        }
      },
      this.onFetchCollectionFail,
      selectedType === 2 ? 'Gallery' : ''
    )
  }

  onFetchCollectionFail = (err) => {
    this.props.showNotification(
      true,
      'Getting some error, try after sometimes !!!',
      'error'
    )
  }

  searchCollection = debounce(() => {
    const { clearCollection } = this.props
    clearCollection()
    this.fetchCollectionList(false, false, false)
  }, 300)

  clearCollectionSearch = () => {
    const { clearCollection } = this.props
    clearCollection()
    this.setState({ pageNum: 1, searchVal: '' }, () => {
      this.fetchCollectionList(false, false, false)
    })
  }

  searchFavouriteAssets = debounce(() => {
    const { clearFavouriteAssets } = this.props
    clearFavouriteAssets()
    this.fetchCollectionAssets(false)
  }, 300)

  clickOnAnchor = (e) => {
    e.nativeEvent.stopImmediatePropagation()
    const { clearFavouriteAssets } = this.props
    clearFavouriteAssets()
    this.setState({
      collectionId: '',
      collectionName: '',
    })
  }

  renderFavBreadCrumb = () => {
    const { classes } = this.props
    const { collectionName, maxExplorer } = this.state

    return (
      <div className={!maxExplorer ? classes.favText : classes.favTextMax}>
        <span className={classes.anchorText} onClick={this.clickOnAnchor}>
          Favorites
        </span>
        &nbsp;/&nbsp;
        <span className={classes.subAnchorText}>{collectionName}</span>
      </div>
    )
  }
  onAssetTypeChange = () => {
    this.setState({
      isSelectorOpen: !this.state.isSelectorOpen,
    })
  }
  handleSelect = (e) => {
    const { clearFavouriteAssets, setCollectionType } = this.props
    let value = e.target.value
    clearFavouriteAssets()
    setCollectionType(value)
    this.setState(
      {
        selectedType: value,
        isShared: value == '1',
        pageNum: 1,
        searchVal: '',
        sortBy: 'created_date',
        sortOrder: 'ASC',
        collectionId: '',
        collectionName: '',
      },
      () => {
        const { clearCollection } = this.props
        clearCollection()
        this.fetchCollectionList(true, false, false)
      }
    )
  }
  renderSearchBar = () => {
    const { classes, collectionAssets, collectionList } = this.props
    const {
      searchVal: collectionSearchVal,
      collectionId,
      collectionName,
      assetListPagination: { searchVal: assetSearchVal },
      isSelectorOpen,
      selectedType,
    } = this.state
    let groupList = collectionList.find((item) => item.id === collectionId)
    let totalLength =
      groupList && groupList.groups ? groupList.groups.length : 0
    totalLength = totalLength + collectionAssets.length
    const searchVal = collectionId !== '' ? assetSearchVal : collectionSearchVal
    return (
      <>
        <ExplorerSearch
          type={selectedType}
          handleTypeChange={this.handleSelect}
          options={[
            { name: 'My Favourites', value: 0 },
            { name: 'Shared with me', value: 1 },
            // { name: 'Gallery', value: 2 },
          ]}
          searchVal={searchVal}
          placeholder={
            collectionId !== ''
              ? `Search in ${collectionName}`
              : 'Search in Favourites...'
          }
          handleSearchChange={this.handleSearchChange}
          disabled={
            searchVal === '' && collectionId !== '' && totalLength === 0
          }
          onClose={this.clearSearchField}
        />
      </>
    )
  }

  renderMaxHeader = () => {
    const { classes } = this.props
    const {
      collectionId,
      collectionName,
      searchVal,
      assetListPagination: { searchVal: assetSearchVal },
      selectedType,
    } = this.state
    const {
      collectionList,
      closeFavDrawer,
      totalAssetsCount,
      totalCollectionCount,
    } = this.props

    let groupList = collectionList.find((item) => item.id === collectionId)
    let totalGroupLength =
      groupList && groupList.groups ? groupList.groups.length : 0

    return (
      <div className={classes.maxHeaderSection}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {collectionId !== '' ? (
            <>
              <div style={{ marginRight: 50 }}>
                <PageTitleBar
                  title={`Browse ${collectionName}`}
                  subTitle={`${
                    totalAssetsCount + totalGroupLength
                  } inside this collection`}
                />
              </div>
              <SearchComponent
                height={40}
                onSearch={this.handleSearchChange}
                onSearchButtonClick={this.clearSearchField}
                placeholder={`Search in ${collectionName}...`}
                searchVal={assetSearchVal}
                width={320}
                brandLogo={searchLogo}
              />
            </>
          ) : (
            <>
              <div style={{ width: 180 }}>
                <SelectComponent
                  onChange={this.handleSelect}
                  inputLabel="All"
                  size="small"
                  color={'tertiary'}
                  options={[
                    { name: 'My Favourites', value: 0 },
                    { name: 'Shared with me', value: 1 },
                    //{ name: 'Gallery', value: 2 },
                  ]}
                  style={{ width: 180 }}
                  value={selectedType}
                />
              </div>
              <div style={{ marginRight: 50, marginLeft: 20 }}>
                <PageTitleBar
                  title="Browse Favorites"
                  subTitle={`${totalCollectionCount} collections`}
                />
              </div>
              <SearchComponent
                height={40}
                onSearch={this.handleSearchChange}
                onSearchButtonClick={this.clearSearchField}
                placeholder={`Search in Favorites...`}
                searchVal={searchVal}
                width={320}
                brandLogo={searchLogo}
              />
            </>
          )}

          <div
            className={classes.explorerAction}
            style={{ top: 15, right: 15 }}
          >
            <CustomIconButton label="Minimize" onClick={this.minimizeExplorer}>
              <Minimize />
            </CustomIconButton>

            <CustomIconButton label="Close Explorer" onClick={closeFavDrawer}>
              <Close />
            </CustomIconButton>
          </div>
        </div>
        {collectionId !== '' && this.renderFavBreadCrumb()}
      </div>
    )
  }

  renderMinHeader = () => {
    const { classes } = this.props
    const { focussed, isPinned, collectionId, collectionName } = this.state
    const { collectionList, totalAssetsCount, totalCollectionCount } =
      this.props
    const searchWrapperClass =
      classes.explorerSearch + ' ' + (focussed ? classes.focussed : '')
    let groupList = collectionList.find((item) => item.id === collectionId)
    let totalGroupLength =
      groupList && groupList.groups ? groupList.groups.length : 0

    return (
      <div className={classes.headerSection}>
        <div className={classes.explorerHeader}>
          <span className={classes.explorerTitle}>
            {collectionId !== '' ? (
              <PageTitleBar
                title={`Browse ${collectionName}`}
                subTitle={`${
                  totalAssetsCount + totalGroupLength
                } inside this collection`}
              />
            ) : (
              <PageTitleBar
                title="Browse Favorites"
                subTitle={`${totalCollectionCount} collections`}
              />
            )}
          </span>
          <div>
            <CustomIconButton
              className={classes.button}
              aria-label="Maximize"
              label="Maximize"
              onClick={this.expandFavourites}
            >
              <CropFreeIcon />
            </CustomIconButton>
            <CustomIconButton label="Pin Explorer" onClick={this.pinExplorer}>
              <PushPinIcon
                sx={{
                  cursor: 'pointer',
                  transform: isPinned ? 'rotate(30deg)' : 'none',
                }}
              />
            </CustomIconButton>
          </div>
        </div>

        {this.renderSearchBar()}

        {collectionId !== '' && this.renderFavBreadCrumb()}
      </div>
    )
  }

  handleCollectionScroll = (e) => {
    const { loading } = this.state
    const { collectionList, totalCollectionCount } = this.props
    if (totalCollectionCount > collectionList.length && !loading) {
      this.setState({ pageNum: this.state.pageNum + 1 }, () => {
        this.fetchCollectionList(false, false, true)
      })
    }
  }

  handleAssetScroll = (e) => {
    const { loading, assetListPagination } = this.state
    const { collectionAssets, totalAssetsCount } = this.props
    if (totalAssetsCount > collectionAssets.length && !loading) {
      this.setState(
        {
          assetListPagination: {
            ...assetListPagination,
            pageNum: assetListPagination.pageNum + 1,
          },
        },
        () => {
          this.fetchCollectionAssets(true)
        }
      )
    }
  }

  interSectionCallback = (entries, observer) => {
    const { collectionId } = this.state
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        if (collectionId === '') {
          this.handleCollectionScroll()
        } else {
          this.handleAssetScroll()
        }
      }
    })
  }

  selectCollection = (data) => (e) => {
    e.nativeEvent.stopImmediatePropagation()
    const { assetListPagination } = this.state
    this.setState(
      {
        collectionId: data.id,
        collectionName: data.name,
        assetListPagination: {
          ...assetListPagination,
          pageNum: 1,
          searchVal: '',
        },
        loading: false,
      },
      () => {
        this.fetchCollectionAssets(false)
      }
    )
  }

  fetchCollectionAssets = (addAsset = false) => {
    const { fetchAssetsForFavorite } = this.props
    const {
      assetListPagination: { perPage, pageNum, searchVal },
      collectionId,
      collectionName,
      selectedType,
    } = this.state
    this.setState({ loading: true })
    fetchAssetsForFavorite(
      perPage,
      pageNum,
      collectionId,
      searchVal,
      addAsset,
      () => {
        this.setState({ loading: false })
      },
      () => {
        this.setState({ loading: false })
      },
      selectedType === 2 ? 'Gallery' : '',
      collectionName
    )
  }

  renderCollections() {
    const {
      isShared,
      loading,
      searchVal,
      maxExplorer,
      collectionId,
      selectedType,
    } = this.state
    const { collectionList, classes, totalCollectionCount } = this.props

    return (
      <Grid
        container
        spacing={maxExplorer ? 3 : 2}
        alignItems={collectionList.length ? 'flex-start' : 'center'}
        justify={collectionList.length ? 'flex-start' : 'center'}
        className={classes.containerHeight}
      >
        {!loading && collectionList.length === 0 ? (
          <>
            {isShared && searchVal === '' && (
              <Grid item xs={12} className={classes.noresultsFoundContainer}>
                <p className={classes.noresultcontent}>
                  No collection has been shared with you yet!
                </p>
              </Grid>
            )}

            {searchVal !== '' && (
              <Grid item xs={12} className={classes.noresultsFoundContainer}>
                <div className={classes.noresultcontent}>
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="4x"
                    className={classes.emptySearchIcon}
                  />
                  <p className={classes.noResultText}>
                    Sorry we couldn't find any matches for "{searchVal}"
                    <br />
                    <span className={classes.subText}>
                      Maybe your search was too specific, please try searching
                      with another term{' '}
                    </span>
                  </p>
                </div>
              </Grid>
            )}
          </>
        ) : null}

        {!loading && searchVal !== '' && collectionList.length ? (
          <Grid item xs={12} className={classes.searchResult}>
            We only found {totalCollectionCount} favourite
            {totalCollectionCount > 1 ? 's' : ''} with your search keyword "
            {searchVal}"....
          </Grid>
        ) : (
          ''
        )}

        {collectionList.map((item, i) => {
          if (maxExplorer) {
            return (
              <Grid item>
                <FavouriteCard
                  name={item.name}
                  assetCount={item.tcin_count}
                  thumbnail={item.thumbnail_path}
                  loginId={item.owner.login_id}
                  imgHeight={250}
                  maxWidth={250}
                  minWidth={250}
                  noThumbnail={noThumbnail}
                  config={config}
                  isUrlAppendedWithImg={selectedType !== 2}
                  avatarBackgroundColor={'#E0E0E0'}
                  avtarTextColor={'#333'}
                  footerItems={[
                    {
                      icon: FolderOpenIcon,
                      onClick: this.selectCollection(item),
                      title: 'View Favourites',
                    },
                  ]}
                />
              </Grid>
            )
          } else {
            return (
              <Grid item>
                <AssetCard
                  name={item.name}
                  assetCount={item.tcin_count}
                  thumbnail={item.thumbnail_path}
                  imgHeight={130}
                  maxWidth={130}
                  minWidth={130}
                  noThumbnail={noThumbnail}
                  errorDetails={{
                    high: true,
                    low: true,
                  }}
                  size={'extraSmall'}
                  config={config}
                  isUrlAppendedWithImg={selectedType !== 2}
                  footerItems={[
                    {
                      icon: FolderOpenIcon,
                      onClick: this.selectCollection(item),
                      title: 'View Favourites',
                    },
                  ]}
                  infoDetails={{}}
                  isRtaAsset={item.is_pure_rta_asset}
                  isHighPolyCountAsset={item.is_high_triangle_count}
                />
              </Grid>
            )
          }
        })}
      </Grid>
    )
  }

  render() {
    const { classes, sceneId, handleCartActions } = this.props
    const {
      loading,
      maxExplorer,
      collectionId,
      assetListPagination: { searchVal = '' },
    } = this.state
    return (
      <div
        ref={this.node}
        className={maxExplorer ? classes.maxFavPanel : classes.FavPanel}
      >
        <DataList
          showProgressBar={loading}
          interSectionCallback={this.interSectionCallback}
          containerClass={classes.datalistContainer}
        >
          {maxExplorer ? (
            <>
              {this.renderMaxHeader()}
              <div className={classes.maxExplorerTemplate}>
                {collectionId === '' ? (
                  this.renderCollections()
                ) : (
                  <FavouriteAssets
                    maxExplorer={maxExplorer}
                    sceneId={sceneId}
                    collectionId={collectionId}
                    searchVal={searchVal}
                    loading={loading}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              {this.renderMinHeader()}
              <div className={classes.explorerTemplate}>
                {collectionId === '' ? (
                  this.renderCollections()
                ) : (
                  <FavouriteAssets
                    maxExplorer={maxExplorer}
                    sceneId={sceneId}
                    collectionId={collectionId}
                    searchVal={searchVal}
                    loading={loading}
                    handleCartActions={handleCartActions}
                  />
                )}
              </div>
            </>
          )}
        </DataList>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { auth, explorer, Board, collectionData } = state
  const {
    collectionList,
    totalCollectionCount,
    assetListSpecificCollection: collectionAssets,
    totalAssetsCount,
    listPageMeta,
    collectionIndex,
  } = collectionData
  const { fullName, firstName, lanId, email } = auth
  const { assetList } = explorer
  const { assets: boardAssets, board_id: boardId } = Board
  return {
    fullName,
    firstName,
    lanId,
    email,
    assetList,
    totalAssetsCount,
    boardAssets: boardAssets ? boardAssets.map((item) => item.value) : [],
    boardId,
    collectionList,
    collectionIndex,
    totalCollectionCount,
    collectionAssets,
    collectionPagination: listPageMeta,
  }
}

export default connect(mapStateToProps, {
  showNotification,
  fetchCollection,
  clearCollection,
  fetchAssetsForFavorite,
  clearFavouriteAssets,
  setCollectionType,
})(withStyles(styles)(Favourites))
