import React from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  QA_PLUGIN_NOT_RUN_MSG,
  QA_PLUGIN_LAST_RUN_WITH_ERR_SECOND_PART,
  QA_PLUGIN_LAST_RUN_WITH_ERR_FIRST_PART,
  QA_PLUGIN_RUN_MSG,
  NO_ERR_FOUND,
} from '../../constants/displayMessages'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  align: { padding: '2px 10px 2px 5px' },
  qaNotRunIconClr: { color: '#AE570F' },
  qaRunWithErrIconClr: { color: '#EDB508' },
  qaRunNoErr: { color: '#2951A3' },
  qaNotRunTxt: { color: '#532A09' },
  qaRunWithErrTxt: { color: '#936406' },

  container: { padding: 10 },
}))

const QaPluginStatusDisplay = ({ qaRunStatus, lastRunDate, errors }) => {
  const classes = useStyles()
  const isQaNotRun = qaRunStatus === 'Not Run'
  return (
    <div
      className={classes.container}
      style={{
        background: isQaNotRun
          ? '#FCDEC5'
          : errors?.length
          ? '#FEFCE6'
          : '#E8EEFC',
        borderBottom: `1px solid ${
          isQaNotRun ? '#AE570F' : errors?.length ? '#C38A04' : '#3D70D6'
        }`,
      }}
    >
      <div>
        <div style={{ display: 'flex' }}>
          <ErrorIcon
            fontSize="small"
            className={classNames(
              classes.align,
              isQaNotRun
                ? classes.qaNotRunIconClr
                : errors?.length
                ? classes.qaRunWithErrIconClr
                : classes.qaRunNoErr
            )}
          />
          <Typography
            variant="caption"
            style={{ padding: '0px 5px' }}
            className={
              isQaNotRun
                ? classes.qaNotRunTxt
                : errors?.length
                ? classes.qaRunWithErrTxt
                : classes.qaRunNoErr
            }
          >
            {isQaNotRun
              ? QA_PLUGIN_NOT_RUN_MSG
              : errors?.length
              ? `${QA_PLUGIN_LAST_RUN_WITH_ERR_FIRST_PART} - ${lastRunDate}. ${QA_PLUGIN_LAST_RUN_WITH_ERR_SECOND_PART}`
              : `${QA_PLUGIN_RUN_MSG} - ${lastRunDate}. `}
          </Typography>
        </div>
        <div style={{ margin: `10px 40px`, wordWrap: 'break-word' }}>
          {!isQaNotRun &&
            (errors?.length < 1 ? (
              <Typography
                className={classes.qaRunNoErr}
                variant="caption"
              >{`${NO_ERR_FOUND}`}</Typography>
            ) : (
              <>
                <Typography
                  variant="caption"
                  className={classes.qaRunWithErrTxt}
                >
                  Errors found:
                </Typography>

                {errors?.map((err, idx) => (
                  <Typography
                    variant="caption"
                    className={classes.qaRunWithErrTxt}
                  >
                    <div>{`${idx + 1}. ${err}`}</div>
                  </Typography>
                ))}
              </>
            ))}
        </div>
      </div>
    </div>
  )
}
export default QaPluginStatusDisplay
