import React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

import { AssetCard } from 'cgi-ui-components'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'
import { removeMultipleAssets } from '../designSpace'

import noThumbnail from '../../images/ic_no-thumbnail.svg?url'
import config from '../../config/config'
import { REPLACE_ASSET } from '../../constants/scene'
import { showNotification } from '../../store/notification/actionCreator'

import { setActiveOperation } from '../../store/operations/actionCreator'
import {
  removeTcinsFromBoard,
  addTcinToBoard,
} from '../../store/board/actionCreator'
import { handleCloseExplorer } from '../../store/explorer/actionCreator'
import {
  toggleReportIssueModal,
  selectCurrentTcin,
} from '../../store/cart/ActionCreator'
import { fetchGalleryPreSignedGLBLink } from '../../store/customCard/actionCreator'
import { addElementOnDragOver } from '../designSpace'
import ACTIONS from '../../constants/actions'
import { TRACK_SCENE_EVENT } from '../../constants/scene'
import ImageSearchRounded from '@mui/icons-material/ImageSearchRounded'

import { mapInfoForCard, createCustomEvent } from '../../helpers/utils'

class DraggableAssetCard extends React.Component {
  /**
   * Function called when swap icon is clicked
   * @param {object} assignObj Data object of item whose swap icon is clicked
   */

  state = {
    deleteRef: null,
    assetToDelete: {},
    loading: false,
  }
  addToBoard = () => {
    const { loading } = this.state
    if (loading) {
      return false
    }
    const {
      data: {
        asset_type: assetType,
        tcin,
        sub_asset_type: subAssetType,
        assets = [],
      },
      experience: { experience_id: sceneId },
      addTcinToBoard,
      isGroup,
      editTemplateMode,
      editTemplateId,
    } = this.props

    this.setState({ loading: true })
    let payload = {
      experience_id: sceneId,
      assets: [
        {
          asset_type: assetType,
          value: tcin,
          sub_asset_type: subAssetType,
        },
      ],
    }
    if (isGroup && assets.length) {
      payload.assets = assets.map((asset) => ({
        asset_type: asset.assetType,
        value: asset.assetId,
        sub_asset_type: asset.subAssetType,
      }))
    }
    if (editTemplateMode) {
      payload.template_id = editTemplateId
    }

    addTcinToBoard(
      payload,
      () => {
        this.setState({ loading: false })
      },
      () => {
        this.setState({ loading: false })
      }
    )
  }

  removeFromBoard = (e, assetObj) => {
    const { tcin, assetId, gga_status: ggaStatus } = assetObj
    if (ggaStatus && ggaStatus !== '' && ggaStatus !== null) {
      this.setState({
        deleteRef: e.currentTarget,
        assetToDelete: { tcin, assetId },
      })
    } else {
      this.deleteBoardAsset(tcin, assetId)
    }
  }

  deleteBoardAsset = (tcin) => {
    const { loading } = this.state
    if (loading) {
      return false
    }
    const { removeTcinsFromBoard, boardId, showNotification } = this.props
    const deleteData = {
      board_id: boardId,
      assets: [tcin],
    }
    this.setState({ loading: true })

    removeTcinsFromBoard(deleteData)
      .then(() => {
        removeMultipleAssets([tcin])
        this.setState({ loading: false })
      })
      .catch(() => {
        this.setState({ loading: false })
        showNotification(
          true,
          "Sorry! we couldn't remove tcin from board. Try again!",
          'error'
        )
      })
  }

  swapAsset = (assetObj) => {
    const {
      handleCartActions,
      cart: { scene_data: { assets = [] } = {} },
      setActiveOperation,
      handleCloseExplorer,
    } = this.props
    const selectedAsset = assets.find((item) => item.selected)
    //Replace asset
    handleCartActions(REPLACE_ASSET, () => {}, {}, [
      selectedAsset,
      {
        ...assetObj,
        assetType: assetObj.asset_type,
        subAssetType: assetObj.subAssetType,
      },
    ])
    // Set replace mode to false
    setActiveOperation(REPLACE_ASSET, { status: false })
    // Close the explorer
    this.addToBoard()
    handleCloseExplorer()
  }

  dragStartHandler = (ev, assetData) => {
    const { data, isGroup = false } = this.props
    ev.preventDefault()
    this.draging = true

    setTimeout(() => {
      if (this.draging) {
        const {
          data: {
            variations = {},
            tcin = '',
            asset_type: assetType,
            sub_asset_type: subAssetType,
            asset_id: assetID,
          } = {},
          sceneId = '',
          onAddSuccess = () => {},
        } = this.props
        let tcinUrl = ''
        if (assetType !== 'PID') {
          const response = this.getAssetUrl(variations, 'low')
          tcinUrl = response.tcinUrl
          addElementOnDragOver(
            ev,
            {
              url: tcinUrl,
              name: tcin,
              subAssetType,
              onAddSuccess: this.addToBoard,
              sceneId,
              assetType: assetType,
              isGroup,
              sceneData: data,
              assetId: tcin,
            },
            () => {
              this.props.showNotification(
                true,
                'Loading failed for ' +
                  (data.short_description ||
                    data.tcin ||
                    data.value ||
                    data.pid),
                'error'
              )
            }
          )
        } else {
          fetchGalleryPreSignedGLBLink(assetID, true)
            .then((preSignedurl) => {
              if (preSignedurl != '') {
                tcinUrl = preSignedurl
                addElementOnDragOver(
                  ev,
                  {
                    url: tcinUrl,
                    name: tcin,
                    subAssetType,
                    onAddSuccess: this.addToBoard,
                    sceneId,
                    assetType: assetType,
                    isGroup,
                    assetId: assetID,
                  },
                  () => {
                    this.props.showNotification(
                      true,
                      'Loading failed for ' + data.short_description,
                      'error'
                    )
                  }
                )
              } else {
                this.props.showNotification(
                  true,
                  'Lod profile is not available for ' + data.short_description,
                  'error'
                )

                createCustomEvent(TRACK_SCENE_EVENT, {
                  type: 'AssetLoadingFailure',
                  assetId: assetID,
                  e: 'LOD profile is not available',
                })
              }
            })
            .catch((e) => {
              this.props.showNotification(
                true,
                'Lod profile is not available for  ' + data.short_description,
                'error'
              )

              createCustomEvent(TRACK_SCENE_EVENT, {
                type: 'AssetLoadingFailure',
                assetId: assetID,
                e: 'LOD profile is not available',
              })
            })
        }
        if (tcinUrl == '') return
      }
    }, 500)
  }
  dragStopHandler = (ev) => {
    ev.preventDefault()
    this.draging = false
  }

  getAssetUrl(data, variation) {
    let urls = {}
    if (data[variation] !== undefined) {
      for (const key of Object.keys(data[variation])) {
        if (key.toLowerCase().endsWith('draco.gltf')) {
          urls.ktxUrl = data[variation][key]
        } else if (key.toLowerCase().endsWith('draco.glb')) {
          urls.tcinUrl = data[variation][key]
        }
      }
    }

    if (urls.ktxUrl !== undefined) {
      urls.tcinUrl = urls.ktxUrl
    }

    return urls
  }

  handleReport = (e) => {
    const {
      toggleReportIssueModal = () => {},
      selectCurrentTcin = () => {},
      data: tcin,
      preventDefault,
    } = this.props
    if (preventDefault) {
      e.preventDefault()
    }

    selectCurrentTcin(tcin)
    toggleReportIssueModal(true)
  }

  render() {
    const {
      classes,
      selectable,
      data: item,
      selected = false,
      onSelect = () => {},
      activeOperations,
      boardAssetObjects,
      maxWidth = 120,
      minWidth = 120,
      showSubAsset = false,
      showSimilarAsset = false,
      isGroup = false,
      preventDefault = false,
      editTemplateMode,
    } = this.props
    const { loading } = this.state
    const { gga_status: inGGAProcess = '', asset_type: assetType = '' } = item
    let isAssetDeletedinGGA =
      inGGAProcess === 'DELETED' ||
      inGGAProcess === 'ON_HOLD' ||
      inGGAProcess === 'NA'
        ? true
        : false

    const infoDetails = {
      icon: InfoOutlinedIcon,
      title: 'info',
      data: mapInfoForCard(item),
    }
    let multiInfoData = []
    let thumbnailList = []
    if (isGroup) {
      item.assets.forEach((data) => {
        multiInfoData.push(mapInfoForCard(data))
        thumbnailList.push(data?.thumbnail_url)
      })
      infoDetails.multiInfoData = multiInfoData
      infoDetails.thumbnailList = thumbnailList
    }

    const footerItems = []

    if (activeOperations[ACTIONS.REPLACE_ASSET]) {
      footerItems.push({
        icon: SwapHorizOutlinedIcon,
        onClick: (e) => {
          if (preventDefault) {
            e.preventDefault()
          }
          if (item.is_hp_available && item.is_lp_available) {
            this.swapAsset(item)
          }
        },
        title:
          item.is_hp_available && item.is_lp_available
            ? 'Replace this asset'
            : 'Asset not available',
      })
    } else if (!editTemplateMode) {
      if (boardAssetObjects[item.tcin || item.value || item.pid]) {
        footerItems.push({
          icon: loading ? CircularProgress : RemoveCircleOutlineIcon,
          onClick: (e) => {
            if (preventDefault) {
              e.preventDefault()
            }
            this.removeFromBoard(e, item)
          },
          title: 'Remove from board',
        })
      } else if (!(!item.lodProfile && assetType === 'PID')) {
        footerItems.push({
          icon: loading ? CircularProgress : AddCircleOutlineRoundedIcon,
          onClick: (e) => {
            if (preventDefault) {
              e.preventDefault()
            }
            this.addToBoard(item)
          },
          title: 'Add to board',
        })
      }
    }

    if (item.has_styled_assets && showSubAsset) {
      footerItems.push({
        icon: StyleOutlinedIcon,
        onClick: (e) => {
          if (preventDefault) {
            e.preventDefault()
          }
          this.props.openSubAssets(item, 'STYLED')
        },
        title: 'Show styled versions',
      })
    }
    if (item.has_unbundled_assets && showSubAsset) {
      footerItems.push({
        icon: LayersOutlinedIcon,
        onClick: (e) => {
          if (preventDefault) {
            e.preventDefault()
          }
          this.props.openSubAssets(item, 'UNBUNDLED')
        },

        title: 'Show unbundled versions',
      })
    }
    if (showSimilarAsset && assetType !== 'PID') {
      footerItems.push({
        icon: ImageSearchRounded,
        onClick: (e) => {
          if (preventDefault) {
            e.preventDefault()
          }
          this.props.openSimilarAssets(item)
        },
        title: 'Search similar assets',
      })
    }
    return (
      <>
        <AssetCard
          size={'extraSmall'}
          thumbnail={item.thumbnail_url}
          config={config}
          isUrlAppendedWithImg={
            assetType !== 'PID' || assetType !== 'ARCHITECTURE'
          }
          assetData={item}
          name={
            isAssetDeletedinGGA
              ? 'Asset not found'
              : item.short_description ||
                item.tcin ||
                item.value ||
                item.pid ||
                item.asset_name
          }
          maxWidth={maxWidth}
          minWidth={minWidth}
          infoDetails={infoDetails}
          noThumbnail={noThumbnail}
          checkboxColor={'tertiary'}
          footerItems={footerItems}
          isPropAsset={item.asset_type == 'PROP'}
          errorDetails={{
            high: item.is_hp_available || isGroup || false,
            low: item.is_lp_available || isGroup || false,
            lodProfile: item.lod_available,
          }}
          onDragStart={this.dragStartHandler}
          onDragEnd={this.dragStopHandler}
          selectable={selectable}
          selected={selected}
          isGroup={isGroup}
          onSelect={(e) => {
            onSelect(e, e.target.checked, item)
          }}
          onReport={assetType !== 'PID' ? this.handleReport : false}
          isRtaAsset={item.is_pure_rta_asset}
          isHighPolyCountAsset={item.is_high_triangle_count}
        />
      </>
    )
  }
}

const mapStateToProps = ({
  explorer: { similarAssets, totalSimilarAssets },
  activeOperations,
  Board: {
    assetObjects: boardAssetObjects,
    board_id: boardId,
    assets: boardAssets,
  },
  cart,
  experiences: { experience },
  templates: { editTemplateMode, editTemplateId },
}) => {
  return {
    similarAssets,
    totalSimilarAssets,
    activeOperations,
    boardAssetObjects,
    boardId,
    boardAssets,
    cart,
    experience,
    editTemplateMode,
    editTemplateId,
  }
}
export default connect(mapStateToProps, {
  setActiveOperation,
  fetchGalleryPreSignedGLBLink,
  handleCloseExplorer,
  showNotification,
  removeTcinsFromBoard,
  addTcinToBoard,
  toggleReportIssueModal,
  selectCurrentTcin,
})(DraggableAssetCard)
