import React, { Fragment } from 'react'

import { withStyles } from '@mui/styles'
import {
  RENDER_RIGHT_DRAWER,
  RENDER_HEADER_HEIGHT,
} from '../../constants/scene'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: RENDER_HEADER_HEIGHT,
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    zIndex: 10,
    flexDirection: 'row',
  },

  overLay: {
    position: 'fixed',
    backgroundColor: '#000',
    opacity: 0.5,
    zIndex: 10,
  },
  topOverlay: {
    left: 0,
  },
  bottomOverlay: { bottom: 0, left: 0 },
  leftOverlay: { left: 0, bottom: 0 },
  rightOverlay: {
    bottom: 0,
  },
  corner: {
    width: 50,
    height: 50,
    border: '3px solid red',
    position: 'fixed',
    zIndex: 10,
  },
  topleft: {
    borderRight: 0,
    borderBottom: 0,
  },
  topright: {
    borderLeft: 0,
    borderBottom: 0,
  },
  bottomleft: {
    borderRight: 0,
    borderTop: 0,
  },
  bottomright: {
    borderLeft: 0,
    borderTop: 0,
  },
})

class RenderFrame extends React.Component {
  render() {
    const {
      classes,
      renderData: {
        frameHeight: cHeight = window.innerHeight,
        frameWidth: cWidth = window.innerWidth,
        frameX = 0,
        frameY: top = 0,
        maxWidth,
        maxHeight,
        width: cropWidth,
        height: cropHeight,
        x: cropLeft,
        y: cropTop,
      },
      showCropFrame = true,
    } = this.props
    const frameY = top < 0 ? 0 : top
    let height =
      parseInt(cHeight) + frameY < maxHeight ? cHeight : maxHeight - frameY
    let width =
      parseInt(cWidth) + frameX < maxWidth ? cWidth : maxWidth - frameX

    return (
      <Fragment>
        <div
          className={classes.overLay + ' ' + classes.topOverlay}
          style={{
            height: frameY,
            left: frameX,
            width: width * 1,
            top: RENDER_HEADER_HEIGHT,
            right: 0,
          }}
        />
        <div
          className={classes.overLay + ' ' + classes.leftOverlay}
          style={{
            width: frameX,
            top: RENDER_HEADER_HEIGHT,
          }}
        />
        <div
          className={classes.overLay + ' ' + classes.bottomOverlay}
          style={{
            height: window.innerHeight - RENDER_HEADER_HEIGHT - height - frameY,
            left: frameX,
            width: width * 1,
            bottom: 0,
            right: 0,
          }}
        />
        <div
          className={classes.overLay + ' ' + classes.rightOverlay}
          style={{
            width: Math.ceil(window.innerWidth - width - frameX) + 0.5,
            right: 0,
            top: RENDER_HEADER_HEIGHT,
          }}
        />
        {showCropFrame && (
          <>
            <div
              className={classes.corner + ' ' + classes.topleft}
              style={{
                top: frameY + RENDER_HEADER_HEIGHT,
                left: frameX,
              }}
            />
            <div
              className={classes.corner + ' ' + classes.topright}
              style={{
                top: frameY + RENDER_HEADER_HEIGHT,
                left: frameX + width - 53,
              }}
            />
            <div
              className={classes.corner + ' ' + classes.bottomleft}
              style={{
                top: frameY + height + RENDER_HEADER_HEIGHT - 53,
                left: frameX,
              }}
            />
            <div
              className={classes.corner + ' ' + classes.bottomright}
              style={{
                top: frameY + height + RENDER_HEADER_HEIGHT - 53,
                left: frameX + width - 53,
              }}
            />
          </>
        )}
      </Fragment>
    )
  }
}

export default withStyles(styles)(RenderFrame)
