export const ERROR_MSG_GENERIC = 'Sorry!, something went wrong'

export const SUCCESS_MSG_SHOW_NOTIFICATION_SUPPORT_FORM =
  'Your request has been raised & successfully submitted, Our experts will reach back to you shortly!'

export const ERROR_MSG_SHOW_NOTIFICATION_SUPPORT_FORM =
  'Unfortunately we could not create the request :( Try again or Write us to gargantua-support@target.com'

export const ERROR_MSG_FOR_WRONG_FILE_UPLOAD_SUPPORT_FORM =
  'Please upload only JPG, JPEG, PNG and GIF file'

export const ERROR_MSG_FOR_DESCRIPTION_SUPPORT_FORM =
  'Please write the issue here'

export const SUCCESS_MSG_SHOW_COMPLETION_OF_TASK =
  'TCIN has been submitted for review. Goodluck!'

export const DESCRIPTION_TEXT_TASK =
  ' are you sure you have completed the task ?'

export const CONFIRM_TEXT_TASK = 'YES, SUBMIT FOR REVIEW'

export const CANCEL_TEXT_TASK = 'NO, I AM STILL WORKING'

export const DESCRIPTION_TEXT_REVIEW =
  ' are you sure you have completed reviewing the TCIN ?'

export const CONFIRM_TEXT_REVIEW = 'YES, I AM DONE REVIEWING'

export const ERROR_MSG_FOR_APPLICATION_MAINTENANCE_MAIN =
  "Sorry, we're upgrading the system at the moment..."

export const ERROR_MSG_FOR_APPLICATION_MAINTENANCE_SUB =
  'Gargantua is temporarily unavailable while we make upgrades to improve the service for you. Inconvenience is regretted.'

export const ERROR_MSG_FOR_API_DOWN =
  "Opps! We're sorry, but we're experiencing a problem on our end"

export const ERROR_MSG_FOR_ACCESS_DENIED =
  "Don't worry! We think you're lost. Incase you aren't <br /> contact Admin & get access to this project"

export const ADDING_TEMPLATE_TO_SCENE = 'Adding template...'

export const SKIPPING_TEMPLATE = 'Skipping...'

export const LOADING_TEMPLATE_TO_SCENE = 'Loading template...'

export const ERROR_MSG_FOR_SELECTING_TEMPLATE = 'Error in selecting template!'

export const ERROR_MSG_FOR_LOADING_TEMPLATE_TO_SCENE =
  'Error in loading template. Please try again.!'

export const ERROR_MSG_FOR_CREATE_EXPERIENCE = 'Error in creating experience!'

export const ERROR_MSG_FOR_EXPERIENCE_EXIST =
  'Scene name already exists. Try with a different name.'

export const ERROR_MSG_FOR_PROJECT_EXIST =
  'Name already taken. Try with a different name.'

export const ERROR_MSG_FOR_PROJECT_CYCLE_EXIST =
  'Cycle already exists. Try with a different name.'

export const ERROR_MSG_FOR_UPDATE_PROJECT = `Sorry! we couldn't save your changes. Try again!`

export const ERROR_MSG_FOR_UPLOAD_THUMBNAIL_PROJECT = `Sorry, Uploading thumbnail failed. Retry upload!`

export const NO_ASSETS_FOUND = `Looks like, we don’t have any purchased assets stored with us`

export const NO_PROJECTS_FOUND = `Sorry! we couldn't find any project in selected criteria.`

export const ADDED_TO_CART = `Added to the cart`

export const LOADING_PREVIEW_RENDER_IMAGE = `Loading preview...`

export const ERROR_MSG_FOR_PREVIEW_RENDER_IMAGE_FAILED = `Error in loading render image. Please try again.!`

export const ERROR_MSG_FOR_EXPORT_RENDER_IMAGE_FAILED = `Error in exporting render image. Please try again.!`

export const INFO_MSG_FOR_EXPORT_FAILED = `The scene export initiated has failed. Please try again.`

export const ERROR_MSG_FOR_DELETE_RENDER_IMAGE_FAILED = `Error in deleting the render image. Please try again.!`

export const SUCCESS_MSG_DELETE_RENDER_IMAGE = `Success! The render has been deleted.`

export const MSG_FOR_NO_RENDER_IMAGE_AVAILABLE = `No images rendered yet.!`

export const ERROR_MSG_FOR_RENDER_IMAGE_LIST = `Failed fetching images. Please try again!`

export const ERROR_MSG_FOR_RENDER_FAILED = `Rendering failed. Please try again!`

export const MSG_FOR_INITIATE_RENDER = `Initiating Render...`

export const CHECKING_MAX_RENDER = `Checking for maximum render...`

export const ERR_MSG_FOR_MAX_RENDER_INPROGRESS = `Maximum renders already in progress. Please try again after some time!`

export const ERR_MSG_FOR_CHECKING_MAX_RENDER_INPROGRESS = `Checking maximum renders failed. Please try again!`

export const ERROR_MSG_FOR_FETCHING_PROJECTS_DROPDOWN = `Failed fetching projects!`

export const MSG_FOR_LOADER_FETCHING_PROJECTS_DROPDOWN = `Loading projects`

export const ERROR_MSG_FOR_FETCHING_EXPERIENCE_FAILS = `Failed fetching experiences. Please try again!`

export const ERROR_MSG_FOR_FETCHING_PROJECTS_FAILS = `Failed fetching projects. Please try again!`

export const ERROR_ADDING_TCINS_TO_BOARD = `Sorry! we couldn't add tcins to board. Try again!`

export const ERROR_MSG_FOR_COLLECTION_EXIST =
  'A collection with this name already exists. Try a different name!'

export const ERROR_MSG_FOR_UPDATE_COLLECTION = `There was an error updating the details. Try again! If Continues to fail, reach out to StyleHub support on Slack`

export const ERROR_MSG_FOR_CREATE_COLLECTION = `Sorry! we couldn't create the collection. Try again!`

export const HIGH_LOW_POLY_AVAILABLE_ERROR =
  'Some assets in the template have missing high or low poly'

export const BROWSER_INCOMPATIBILITY_MSG =
  'For a seemless styling experience in StyleHub,\n we recommend you to use the latest version of Chrome Browser.'

export const LOW_POLY_TEMPLATE_VERSION_UNAVAILABLE =
  'Template unavailable for styling'

export const LOW_POLY_TEMPLATE_VERSION_UNAVAILABLE_FOR_EDIT =
  'Template unavailable for editing'

export const NO_TEMPLATES_AVAILABLE_MAIN_TEXT =
  'We do not have any brand template store with us!'

export const NO_TEMPLATES_AVAILABLE_SUB_TEXT =
  'View all published brand templates here'

export const FILE_DOWNLOAD_FAILED = 'Failed to download file'

export const CLEAR_BOARD_POPUP_MESSAGE =
  "Are you Sure you want to clear the board's content"

export const NO_ARCHITECTURAL_ASSETS_TEXT =
  'Architectural assets space is empty'

export const NO_PURCHASED_ASSETS_TEXT = 'Purchased assets space is empty'

export const SMART_RENDER_PRESET_LOW_MSG =
  'Low quality render usually takes 5 minutes to get rendered'

export const SMART_RENDER_PRESET_MED_MSG =
  'Medium quality with lesser details, usually takes 20 minutes to get rendered'

export const SMART_RENDER_PRESET_HG_MSG =
  'High quality with great details, usually takes 1 hour to get rendered'

export const SMART_RENDER_PRESET_DEFAULT_MSG = `Realistic renders, settings defaulted to template's rendering app, might take too long`

export const INITIATE_RENDER_SUCCESS_MSG =
  'Rendering started. You will be notified after its completion!'

export const COLOUR_TEXTURE_EDIT_NOTE = `<strong>Please note:</strong> All color & texture changes made to the
asset might not accurately reflect in the viewport. Please render the scene to view the changes made to the asset.`

export const MAX_FAV_LIMIT_MESSAGE =
  'You’ve reached the Favourites library limit, Please remove unused/older favourites to add more.'

export const QA_PLUGIN_NOT_RUN_MSG = `Template QA plugin was NOT run in 3DS max. Please run the plugin before uploading it 
to Stylehub to avoid any template creation related errors.`

export const QA_PLUGIN_LAST_RUN_WITH_ERR_FIRST_PART = `There are unresolved errors from the Template QA plugin that was last run`

export const QA_PLUGIN_LAST_RUN_WITH_ERR_SECOND_PART = `Please fix the errors in 3DS max before uploading it to Stylehub.`

export const QA_PLUGIN_RUN_MSG = `Template QA plugin was last run`
export const NO_ERR_FOUND = `No errors found`
