export const ADD_CART_DATA_REDUX = 'ADD_CART_DATA_REDUX'
export const CLEAR_CART_DATA = 'CLEAR_CART_DATA'
export const DELETE_ASSET = 'DELETE_ASSET'
export const DUPLICATE_ASSET = 'DUPLICATE_ASSET'
export const HIDE_SHOW_ASSET = 'HIDE_SHOW_ASSET'
export const SHOW_ASSET = 'SHOW_ASSET'
export const HIDE_GROUP = 'HIDE_GROUP'
export const SHOW_GROUP = 'SHOW_GROUP'
export const GROUP_ASSETS = 'GROUP_ASSETS'
export const UN_GROUP_ASSETS = 'UN_GROUP_ASSETS'
export const TOGGLE_ASSET_SELECTION = 'TOGGLE_ASSET_SELECTION'
export const RESET_ASSET_SELECTION = 'RESET_ASSET_SELECTION'
export const ADD_ASSET = 'ADD_ASSET'
export const MULTI_ASSET_SELECTION = 'MULTI_ASSET_SELECTION'
export const SHOW_ALL_ASSETS = 'SHOW_ALL_ASSETS'
export const UN_GROUP_ALL_ASSETS = 'UN_GROUP_ALL_ASSETS'
export const SELECT_GROUP = 'SELECT_GROUP'
export const DOWNLOAD_CSV = 'DOWNLOAD_CSV'
export const TOGGLE_REPORT_ISSUE_MODAL = 'TOGGLE_REPORT_ISSUE_MODAL'
export const SELECT_CURRENT_TCIN = 'SELECT_CURRENT_TCIN'
export const SAVE_REPORT_ISSUE = 'SAVE_REPORT_ISSUE'
