import merge from 'lodash/merge'

const commonAuthConfig = {
  auth: {
    redirectUri: `${window.location.origin}/`,
  },
  autoSaveTime: 5, //In Minutes
  jsonAutoSaveTime: 1, //In Minutes
  templateAutoSaveTime: 1, // In Minutes
  renderConfig: {
    maxRenderThreshold: 6,
    autoRefreshTime: 10, //In Minutes
  },
  env: process.env.REACT_APP_ENV,
  analytics: {
    appName: 'StylehubAnalytics',
  },
}

const authStg = 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize'
const logoutStg =
  'https://logonservices.iam.perf.target.com/login/responses/logoff.html'
const authPrd = 'https://oauth.iam.target.com/auth/oauth/v2/authorize'
const logoutPrd =
  'https://logonservices.iam.target.com/login/responses/logoff.html'

const fileUploadUrlStage = 'https://stage-api.target.com/'
const fileUploadUrlProd = 'https://api.target.com/'

const baseURLQA = 'https://stage-api.target.com/digital_assets/v1/cad/'
const baseURLStg = 'https://stage-api.target.com/digital_assets/v1/cad/'
const baseURLProd = 'https://api.target.com/digital_assets/v1/cad/'

const baseAuditURLQA = 'https://stage-api.target.com/digital_assets/v1/audits/'
const baseAuditURLProd = 'https://api.target.com/digital_assets/v1/audits/'

const baseGgaURLQA = 'https://stage-api.target.com/digital_assets/v1/gargantua/'
const baseGgaURLStg =
  'https://stage-api.target.com/digital_assets/v1/gargantua/'
const baseGgaURLProd = 'https://api.target.com/digital_assets/v1/gargantua/'

const visionSearchApi = 'https://stgapi-internal.target.com/vision_insights/v1/'

export const renditionImgUrl =
  'https://stage-api.target.com/digital_assets/v1/cad/renditions/'

const GalleryDevBaseURL =
  'https://stgapi-internal.target.com/gallery_digital_assets/v1/'
const GalleryStageApi = `https://stgapi-internal.target.com/gallery_searches/v1/`
const GalleryProdBaseURL =
  'https://api-internal.target.com/gallery_digital_assets/v1/'
const GalleryProdApi = `https://api-internal.target.com/gallery_searches/v1/`

const baseStorageGridUrlStage =
  'https://stgapi-internal.target.com/enterprise_cgi_assets/v1/get_storage_grid_rendition?folder_key=cgirtafiles/cad/prod'
const baseStorageGridUrlProd =
  'https://api-internal.target.com/enterprise_cgi_assets/v1/get_storage_grid_rendition?folder_key=cgirtafiles/cad/prod'

const envConfigs = {
  local: {
    auth: {
      clientId: 'ecgi_npe_im',
      authorizationUrl: authStg,
      logoutUrl: logoutStg,
    },
    analytics: {
      url: 'https://stgapi-internal.target.com/internal_app_analytics/v3',
    },

    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    apiKeyVisionAPI: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    fileUploadApiKey: '0a11d693cc76dfebf96c3a2958c2db72aae7f4a0',
    galleryApiKey: 'c55635c53bf3260c93d8a939e43dfa128f562c1c',
    baseS3Prefix: 's3://s3-ttc01-sg.target.com:11443/cgi-projects-nonprod/',
    renditionImgUrl: baseURLQA + 'renditions/',
    exportSceneUrl: baseURLQA + 'scene_export',
    getnuidToken: baseURLQA + 'get_token',
    addTcin: {
      verifyTcin: baseURLStg + 'verify_tcins',
      addTcinToBoard: baseURLStg + 'create_board',
      remove: baseURLStg + 'delete_assets',
    },
    board: {
      create: baseURLStg + 'create_board',
      remove: baseURLStg + 'delete_assets',
      get: (boardId) => `${baseURLStg}getBoard/${boardId}`,
      exportXls: (boardId) => `${baseURLStg}getBoardReport/${boardId}`,
      download: baseURLStg + 'board/bulk_download',
      validatePrimaryTcins: baseURLStg + 'validate_primary_tcins',
    },
    collection: {
      create: baseURLStg + 'create_favourite',
      favouriteList: baseURLStg + 'list_favourites?',
      shareCollection: baseURLStg + 'update_favourite',
      addAssetsCollection: baseURLStg + 'update_favourite',
      tcinsInFavorite: baseURLStg + 'list_favourite_tcins?',
      update: baseURLStg + 'update_favourite',
      delete: baseURLStg + 'favourite/deleteFavourite',
      galleryCollectionList: baseURLStg + 'get_gallery_favourite',
      galleryCollectionInternal:
        baseURLStg + 'get_gallery_favourite_by_collection',
      updateGalleryCollection: baseURLStg + 'update_gallery_favourite',
    },
    explorer: {
      getAssets: baseURLQA + 'facets_tcin_search',
      filterLists: baseURLQA + 'facets_tcin_search',
      downloadAssets: baseURLQA + 'download_assets',
      downloadExcel: baseURLQA + 'getExcelReport',
      similarSearch:
        fileUploadUrlStage +
        'digital_assets/v1/search_similar_asset/get_similar_assets',
    },
    template: {
      templateList: baseURLStg + 'templates',
      listTemplate: baseURLStg + 'list_templates',
      updateExperience: baseURLStg + 'edit_experience',
      getTemplateAssets: (templateId) =>
        `${baseURLStg}template_tcins_info/${templateId}`,
      saveTemplate: baseURLStg + 'edit_template',
      getBusinessDepts: baseURLStg + 'get_business_dept_details',
    },
    experience: {
      createdExperience: baseURLStg + 'experience',
      savedScene: baseURLStg + 'renditions',
      createExperience: baseURLStg + 'create_experience',
      experienceList: baseURLStg + 'list_exp',
      projectTypes: baseURLStg + 'project/types',
      shareExperience: baseURLStg + 'share_experience',
      saveScene: baseURLStg + 'scene',
      savedJSONScene: baseURLStg + 'scene/experience',
      renderList: baseURLStg + 'experience_renders',
      renderScene: baseURLStg + 'experience_render',
      uploadSnapShot: baseURLStg + 'experience_thumbnail/',
      deleteScene: baseURLStg + 'scene/experience/',
      copyScene: baseURLStg + 'copy_experience',
      validate: baseURLStg + 'validate_details',
    },
    chunk: {
      start: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads/start',
      upload: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads',
      end: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads/end',
      postUploadProcess:
        fileUploadUrlStage + 'digital_assets/v1/upload/job_status',

      fileUploadStart: baseURLStg + 'start_job',
      fileUploadProcess: baseURLStg + 'process_chunk',
      fileUploadEnd: baseURLStg + 'end_job',
    },
    renditionsUrl: baseURLStg + 'renditions',
    ggaRenditions: baseGgaURLStg + 'renditions/',
    projectList: {
      listOfProjects: baseURLStg + 'list_projects',
      editProject: baseURLStg + 'project',
      uploadProjectThumbnail: baseURLStg + 'project_thumbnail',
    },
    cart: {
      fetch: baseURLStg + 'cart/',
      post: baseURLStg + 'cart/',
      export: baseURLStg + 'export_assets/',
    },
    comments: {
      renderComments: baseURLStg + 'get_render_comment/',
      saveComment: baseURLStg + 'save_render_comment',
      getScribble: baseURLStg + 'scribble_data',
      saveScribble: baseURLStg + 'save_scribble',
    },
    uploadAssets: {
      objectDetection: visionSearchApi + 'object_detections?key=',
      imageLabel: visionSearchApi + 'image_labels?key=',
      saveAsset: baseURLStg + 'save_arch_asset',
      purchasedSaveAsset: baseURLStg + 'save_prop_asset',
      editAsset: baseURLStg + 'edit_arch_asset',
      deleteAsset: baseURLStg + 'delete_arch_asset/',
      purchasedEditAsset: baseURLStg + 'edit_prop_asset',
      purchasedDeleteAsset: baseURLStg + 'delete_prop_asset/',
      getUploadStatus: baseURLStg + 'get_arch_asset/',
      getPurchaseUploadStatus: baseURLStg + 'get_prop_asset/',
    },
    reportAnIssue: {
      saveIssue: baseURLStg + 'ingest_asset/',
    },
    searchUser: baseURLStg + 'search_user',
    support: {
      submitForm: fileUploadUrlStage + 'digital_assets/v1/user_issues',
      uploadSnapShot:
        fileUploadUrlStage + 'digital_assets/v1/user_issues/attachment/',
    },
    fontAwesomeUrl: 'https://use.fontawesome.com/releases/v5.1.0/css/all.css',

    //Flags for toggling feature availability
    enableFlags: {
      // templateListTab: true,
    },
    assets: {
      fetchArchitecturalAssets: baseURLStg + 'architecture_search',
    },
    gallery: {
      search: `${GalleryStageApi}search/assets`,
      filters: `${GalleryStageApi}search/assets-filters`,
      assetDetails: `${GalleryDevBaseURL}assets`,
    },
    storageGridUrl: baseStorageGridUrlStage,
    uploadTemplate: {
      getBusinessDepartment: baseURLQA + 'get_business_departments',
      validatePath: baseURLQA + 'validate_file',
      saveTemplate: baseURLQA + 'save_template',
      getProjectTypes: baseURLQA + 'project/types',
      ingestTemplate: baseURLQA + 'ingest_template',
      getQaPluginStatus: baseURLQA + 'templates/qa_report',
    },
    audit: {
      saveAuditReport: baseAuditURLQA + 'cad_event_track',
    },
    statistics: baseURLQA + 'get_scene_stats',
    feedback: baseURLQA + 'feedback',
    materialEditor: {
      getSrcMaterialList: baseURLQA + 'get_asset_material_metadata',
      fetchColorLibraryList: baseURLQA + 'color_library',
      getColorLibraryMetadata: baseURLQA + 'color_library/content?library_id=',
      getTextureLibraryMetadata:
        baseURLQA + 'texture_library/get_content?library_id=',
      updateColorLibrary: baseURLQA + '/color_library/update',
      updateTextureLibrary: baseURLQA + 'texture_library/update',
      fetchTextureLibraryList: baseURLQA + 'texture_library/get_list',
    },
  },
  qa: {
    auth: {
      clientId: 'ecgi_npe_im',
      authorizationUrl: authStg,
      logoutUrl: logoutStg,
    },
    analytics: {
      url: 'https://stgapi-internal.target.com/internal_app_analytics/v3',
    },
    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    apiKeyVisionAPI: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    fileUploadApiKey: '0a11d693cc76dfebf96c3a2958c2db72aae7f4a0',
    galleryApiKey: 'c55635c53bf3260c93d8a939e43dfa128f562c1c',
    getnuidToken: baseURLQA + 'get_token',
    renditionImgUrl: baseURLStg + 'renditions/',
    exportSceneUrl: baseURLStg + 'scene_export',
    addTcin: {
      verifyTcin: baseURLStg + 'verify_tcins',
      addTcinToBoard: baseURLStg + 'create_board',
      remove: baseURLStg + 'delete_assets',
    },
    board: {
      create: baseURLStg + 'create_board',
      remove: baseURLStg + 'delete_assets',
      get: (boardId) => `${baseURLStg}getBoard/${boardId}`,
      exportXls: (boardId) => `${baseURLStg}getBoardReport/${boardId}`,
      download: baseURLStg + 'board/bulk_download',
      validatePrimaryTcins: baseURLStg + 'validate_primary_tcins',
    },
    collection: {
      create: baseURLStg + 'create_favourite',
      favouriteList: baseURLStg + 'list_favourites?',
      shareCollection: baseURLStg + 'update_favourite',
      addAssetsCollection: baseURLStg + 'update_favourite',
      tcinsInFavorite: baseURLStg + 'list_favourite_tcins?',
      update: baseURLStg + 'update_favourite',
      delete: baseURLStg + 'favourite/deleteFavourite',
      galleryCollectionList: baseURLStg + 'get_gallery_favourite',
      galleryCollectionInternal:
        baseURLStg + 'get_gallery_favourite_by_collection',
      updateGalleryCollection: baseURLStg + 'update_gallery_favourite',
    },
    explorer: {
      getAssets: baseURLQA + 'facets_tcin_search',
      filterLists: baseURLQA + 'facets_tcin_search',
      downloadAssets: baseURLQA + 'download_assets',
      downloadExcel: baseURLQA + 'getExcelReport',
      similarSearch:
        fileUploadUrlStage +
        'digital_assets/v1/search_similar_asset/get_similar_assets',
    },
    template: {
      templateList: baseURLStg + 'templates',
      listTemplate: baseURLStg + 'list_templates',
      updateExperience: baseURLStg + 'edit_experience',
      getTemplateAssets: (templateId) =>
        `${baseURLStg}template_tcins_info/${templateId}`,
      saveTemplate: baseURLStg + 'edit_template',
      getBusinessDepts: baseURLStg + 'get_business_dept_details',
    },
    experience: {
      createdExperience: baseURLStg + 'experience',
      savedScene: baseURLStg + 'renditions',
      createExperience: baseURLStg + 'create_experience',
      experienceList: baseURLStg + 'list_exp',
      projectTypes: baseURLStg + 'project/types',
      shareExperience: baseURLStg + 'share_experience',
      saveScene: baseURLStg + 'scene',
      savedJSONScene: baseURLStg + 'scene/experience',
      renderList: baseURLStg + 'experience_renders',
      renderScene: baseURLStg + 'experience_render',
      uploadSnapShot: baseURLStg + 'experience_thumbnail/',
      deleteScene: baseURLStg + 'scene/experience/',
      copyScene: baseURLStg + 'copy_experience',
      validate: baseURLStg + 'validate_details',
    },
    chunk: {
      start: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads/start',
      upload: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads',
      end: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads/end',
      postUploadProcess:
        fileUploadUrlStage + 'digital_assets/v1/upload/job_status',

      fileUploadStart: baseURLStg + 'start_job',
      fileUploadProcess: baseURLStg + 'process_chunk',
      fileUploadEnd: baseURLStg + 'end_job',
    },
    renditionsUrl: baseURLStg + 'renditions',
    ggaRenditions: baseGgaURLStg + 'renditions/',
    projectList: {
      listOfProjects: baseURLStg + 'list_projects',
      editProject: baseURLStg + 'project',
      uploadProjectThumbnail: baseURLStg + 'project_thumbnail',
    },
    cart: {
      fetch: baseURLStg + 'cart/',
      post: baseURLStg + 'cart/',
      export: baseURLStg + 'export_assets/',
    },
    comments: {
      addComments: baseURLStg + 'add_comment',
      displayComments: baseURLStg + 'experience/comments/',
      renderComments: baseURLStg + 'get_render_comment/',
      saveComment: baseURLStg + 'save_render_comment',
      getScribble: baseURLStg + 'scribble_data',
      saveScribble: baseURLStg + 'save_scribble',
    },
    uploadAssets: {
      objectDetection: visionSearchApi + 'object_detections?key=',
      imageLabel: visionSearchApi + 'image_labels?key=',
      saveAsset: baseURLStg + 'save_arch_asset',
      purchasedSaveAsset: baseURLStg + 'save_prop_asset',
      editAsset: baseURLStg + 'edit_arch_asset',
      deleteAsset: baseURLStg + 'delete_arch_asset/',
      purchasedEditAsset: baseURLStg + 'edit_prop_asset',
      purchasedDeleteAsset: baseURLStg + 'delete_prop_asset/',
      getUploadStatus: baseURLStg + 'get_arch_asset/',
      getPurchaseUploadStatus: baseURLStg + 'get_prop_asset/',
    },
    reportAnIssue: {
      saveIssue: baseURLStg + 'ingest_asset/',
    },
    searchUser: baseURLStg + 'search_user',
    support: {
      submitForm: fileUploadUrlStage + 'digital_assets/v1/user_issues',
      uploadSnapShot:
        fileUploadUrlStage + 'digital_assets/v1/user_issues/attachment/',
    },
    fontAwesomeUrl: 'https://use.fontawesome.com/releases/v5.1.0/css/all.css',

    //Flags for toggling feature availability
    enableFlags: {
      // templateListTab: true,
    },
    assets: {
      fetchArchitecturalAssets: baseURLStg + 'architecture_search',
    },

    gallery: {
      search: `${GalleryStageApi}search/assets`,
      filters: `${GalleryStageApi}search/assets-filters`,
      assetDetails: `${GalleryDevBaseURL}assets`,
    },
    storageGridUrl: baseStorageGridUrlStage,
    uploadTemplate: {
      getBusinessDepartment: baseURLQA + 'get_business_departments',
      validatePath: baseURLQA + 'validate_file',
      saveTemplate: baseURLQA + 'save_template',
      getProjectTypes: baseURLQA + 'project/types',
      ingestTemplate: baseURLQA + 'ingest_template',
      getQaPluginStatus: baseURLQA + 'templates/qa_report',
    },
    audit: {
      saveAuditReport: baseAuditURLQA + 'cad_event_track',
    },
    statistics: baseURLQA + 'get_scene_stats',
    feedback: baseURLQA + 'feedback',
    materialEditor: {
      getSrcMaterialList: baseURLQA + 'get_asset_material_metadata',
      fetchColorLibraryList: baseURLQA + 'color_library',
      getColorLibraryMetadata: baseURLQA + 'color_library/content?library_id=',
      getTextureLibraryMetadata:
        baseURLQA + 'texture_library/get_content?library_id=',
      updateColorLibrary: baseURLQA + '/color_library/update',
      updateTextureLibrary: baseURLQA + 'texture_library/update',
      fetchTextureLibraryList: baseURLQA + 'texture_library/get_list',
    },
  },
  stg: {
    auth: {
      clientId: 'ecgi_npe_im',
      authorizationUrl: authStg,
      logoutUrl: logoutStg,
    },
    analytics: {
      url: 'https://stgapi-internal.target.com/internal_app_analytics/v3',
    },
    apiKey: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    apiKeyVisionAPI: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    fileUploadApiKey: '0a11d693cc76dfebf96c3a2958c2db72aae7f4a0',
    galleryApiKey: 'c55635c53bf3260c93d8a939e43dfa128f562c1c',
    getnuidToken: baseURLQA + 'get_token',
    renditionImgUrl: baseURLStg + 'renditions/',
    exportSceneUrl: baseURLStg + 'scene_export',
    addTcin: {
      verifyTcin: baseURLStg + 'verify_tcins',
      addTcinToBoard: baseURLStg + 'create_board',
      remove: baseURLStg + 'delete_assets',
    },
    board: {
      create: baseURLStg + 'create_board',
      remove: baseURLStg + 'delete_assets',
      get: (boardId) => `${baseURLStg}getBoard/${boardId}`,
      exportXls: (boardId) => `${baseURLStg}getBoardReport/${boardId}`,
      download: baseURLStg + 'board/bulk_download',
      validatePrimaryTcins: baseURLStg + 'validate_primary_tcins',
    },
    collection: {
      create: baseURLStg + 'create_favourite',
      favouriteList: baseURLStg + 'list_favourites?',
      shareCollection: baseURLStg + 'update_favourite',
      addAssetsCollection: baseURLStg + 'update_favourite',
      tcinsInFavorite: baseURLStg + 'list_favourite_tcins?',
      update: baseURLStg + 'update_favourite',
      delete: baseURLStg + 'favourite/deleteFavourite',
      galleryCollectionList: baseURLStg + 'get_gallery_favourite',
      galleryCollectionInternal:
        baseURLStg + 'get_gallery_favourite_by_collection',
      updateGalleryCollection: baseURLStg + 'update_gallery_favourite',
    },
    explorer: {
      getAssets: baseURLStg + 'facets_tcin_search',
      filterLists: baseURLStg + 'facets_tcin_search',
      downloadAssets: baseURLStg + 'download_assets',
      downloadExcel: baseURLStg + 'getExcelReport',
      similarSearch:
        fileUploadUrlStage +
        'digital_assets/v1/search_similar_asset/get_similar_assets',
    },
    template: {
      templateList: baseURLStg + 'templates',
      listTemplate: baseURLStg + 'list_templates',
      updateExperience: baseURLStg + 'edit_experience',
      getTemplateAssets: (templateId) =>
        `${baseURLStg}template_tcins_info/${templateId}`,
      saveTemplate: baseURLStg + 'edit_template',
      getBusinessDepts: baseURLStg + 'get_business_dept_details',
    },
    experience: {
      createdExperience: baseURLStg + 'experience',
      savedScene: baseURLStg + 'renditions',
      createExperience: baseURLStg + 'create_experience',
      experienceList: baseURLStg + 'list_exp',
      projectTypes: baseURLStg + 'project/types',
      shareExperience: baseURLStg + 'share_experience',
      saveScene: baseURLStg + 'scene',
      savedJSONScene: baseURLStg + 'scene/experience',
      renderList: baseURLStg + 'experience_renders',
      renderScene: baseURLStg + 'experience_render',
      uploadSnapShot: baseURLStg + 'experience_thumbnail/',
      deleteScene: baseURLStg + 'scene/experience/',
      copyScene: baseURLStg + 'copy_experience',
      validate: baseURLStg + 'validate_details',
    },
    chunk: {
      start: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads/start',
      upload: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads',
      end: fileUploadUrlStage + 'vault_digital_assets/v1/chunk_uploads/end',
      postUploadProcess:
        fileUploadUrlStage + 'digital_assets/v1/upload/job_status',

      fileUploadStart: baseURLStg + 'start_job',
      fileUploadProcess: baseURLStg + 'process_chunk',
      fileUploadEnd: baseURLStg + 'end_job',
    },
    renditionsUrl: baseURLStg + 'renditions',
    ggaRenditions: baseGgaURLStg + 'renditions/',
    projectList: {
      listOfProjects: baseURLStg + 'list_projects',
      editProject: baseURLStg + 'project',
      uploadProjectThumbnail: baseURLStg + 'project_thumbnail',
    },
    cart: {
      fetch: baseURLStg + 'cart/',
      post: baseURLStg + 'cart/',
      export: baseURLStg + 'export_assets/',
    },
    comments: {
      addComments: baseURLStg + 'add_comment',
      displayComments: baseURLStg + 'experience/comments/',
      renderComments: baseURLStg + 'get_render_comment/',
      saveComment: baseURLStg + 'save_render_comment',
      getScribble: baseURLStg + 'scribble_data',
      saveScribble: baseURLStg + 'save_scribble',
    },
    uploadAssets: {
      objectDetection: visionSearchApi + 'object_detections?key=',
      imageLabel: visionSearchApi + 'image_labels?key=',
      saveAsset: baseURLStg + 'save_arch_asset',
      purchasedSaveAsset: baseURLStg + 'save_prop_asset',
      editAsset: baseURLStg + 'edit_arch_asset',
      deleteAsset: baseURLStg + 'delete_arch_asset/',
      purchasedEditAsset: baseURLStg + 'edit_prop_asset',
      purchasedDeleteAsset: baseURLStg + 'delete_prop_asset/',
      getUploadStatus: baseURLStg + 'get_arch_asset/',
      getPurchaseUploadStatus: baseURLStg + 'get_prop_asset/',
    },
    reportAnIssue: {
      saveIssue: baseURLStg + 'ingest_asset/',
    },
    searchUser: baseURLStg + 'search_user',
    support: {
      submitForm: fileUploadUrlStage + 'digital_assets/v1/user_issues',
      uploadSnapShot:
        fileUploadUrlStage + 'digital_assets/v1/user_issues/attachment/',
    },
    fontAwesomeUrl: 'https://use.fontawesome.com/releases/v5.1.0/css/all.css',

    //Flags for toggling feature availability
    enableFlags: {
      // templateListTab: true,
    },
    assets: {
      fetchArchitecturalAssets: baseURLStg + 'architecture_search',
    },

    gallery: {
      search: `${GalleryStageApi}search/assets`,
      filters: `${GalleryStageApi}search/assets-filters`,
      assetDetails: `${GalleryDevBaseURL}assets`,
    },
    storageGridUrl: baseStorageGridUrlStage,
    uploadTemplate: {
      getBusinessDepartment: baseURLStg + 'get_business_departments',
      validatePath: baseURLStg + 'validate_file',
      saveTemplate: baseURLStg + 'save_template',
      getProjectTypes: baseURLStg + 'project/types',
      ingestTemplate: baseURLStg + 'ingest_template',
      getQaPluginStatus: baseURLStg + 'templates/qa_report',
    },
    audit: {
      saveAuditReport: baseAuditURLQA + 'cad_event_track',
    },
    statistics: baseURLQA + 'get_scene_stats',
    feedback: baseURLQA + 'feedback',
    materialEditor: {
      getSrcMaterialList: baseURLQA + 'get_asset_material_metadata',
      fetchColorLibraryList: baseURLQA + 'color_library',
      getColorLibraryMetadata: baseURLQA + 'color_library/content?library_id=',
      getTextureLibraryMetadata:
        baseURLQA + 'texture_library/get_content?library_id=',
      updateColorLibrary: baseURLQA + '/color_library/update',
      updateTextureLibrary: baseURLQA + 'texture_library/update',
      fetchTextureLibraryList: baseURLQA + 'texture_library/get_list',
    },
  },
  prod: {
    auth: {
      clientId: 'ecgi_prod_im',
      authorizationUrl: authPrd,
      logoutUrl: logoutPrd,
    },
    analytics: {
      url: 'https://api.target.com/internal_app_analytics/v3',
    },
    apiKey: '07323bd67d9a9a38c555a5ab00f68514b3d020b8',
    apiKeyVisionAPI: 'e8791ab338a5569cb12e1de7511ca44202380cd2',
    fileUploadApiKey: '07323bd67d9a9a38c555a5ab00f68514b3d020b8',
    galleryApiKey: 'b1da4c17267e19e673784f8456c35f4bd060ad28',
    getnuidToken: baseURLProd + 'get_token',
    renditionImgUrl: baseURLProd + 'renditions/',
    exportSceneUrl: baseURLProd + 'scene_export',
    addTcin: {
      verifyTcin: baseURLProd + 'verify_tcins',
      addTcinToBoard: baseURLProd + 'create_board',
      remove: baseURLProd + 'delete_assets',
    },
    board: {
      create: baseURLProd + 'create_board',
      remove: baseURLProd + 'delete_assets',
      get: (boardId) => `${baseURLProd}getBoard/${boardId}`,
      exportXls: (boardId) => `${baseURLProd}getBoardReport/${boardId}`,
      download: baseURLProd + 'board/bulk_download',
      validatePrimaryTcins: baseURLProd + 'validate_primary_tcins',
    },
    collection: {
      create: baseURLProd + 'create_favourite',
      favouriteList: baseURLProd + 'list_favourites?',
      shareCollection: baseURLProd + 'update_favourite',
      addAssetsCollection: baseURLProd + 'update_favourite',
      tcinsInFavorite: baseURLProd + 'list_favourite_tcins?',
      update: baseURLProd + 'update_favourite',
      delete: baseURLProd + 'favourite/deleteFavourite',
      galleryCollectionList: baseURLProd + 'get_gallery_favourite',
      galleryCollectionInternal:
        baseURLProd + 'get_gallery_favourite_by_collection',
      updateGalleryCollection: baseURLProd + 'update_gallery_favourite',
    },
    explorer: {
      getAssets: baseURLProd + 'facets_tcin_search',
      filterLists: baseURLProd + 'facets_tcin_search',
      downloadAssets: baseURLProd + 'download_assets',
      downloadExcel: baseURLProd + 'getExcelReport',
      similarSearch:
        fileUploadUrlProd +
        'digital_assets/v1/search_similar_asset/get_similar_assets',
    },
    template: {
      templateList: baseURLProd + 'templates',
      listTemplate: baseURLProd + 'list_templates',
      updateExperience: baseURLProd + 'edit_experience',
      getTemplateAssets: (templateId) =>
        `${baseURLProd}template_tcins_info/${templateId}`,
      saveTemplate: baseURLProd + 'edit_template',
      getBusinessDepts: baseURLProd + 'get_business_dept_details',
    },
    experience: {
      createdExperience: baseURLProd + 'experience',
      savedScene: baseURLProd + 'renditions',
      createExperience: baseURLProd + 'create_experience',
      experienceList: baseURLProd + 'list_exp',
      projectTypes: baseURLProd + 'project/types',
      shareExperience: baseURLProd + 'share_experience',
      saveScene: baseURLProd + 'scene',
      savedJSONScene: baseURLProd + 'scene/experience',
      renderList: baseURLProd + 'experience_renders',
      renderScene: baseURLProd + 'experience_render',
      uploadSnapShot: baseURLProd + 'experience_thumbnail/',
      deleteScene: baseURLProd + 'scene/experience/',
      copyScene: baseURLProd + 'copy_experience',
      validate: baseURLProd + 'validate_details',
    },
    chunk: {
      start: fileUploadUrlProd + 'vault_digital_assets/v1/chunk_uploads/start',
      upload: fileUploadUrlProd + 'vault_digital_assets/v1/chunk_uploads',
      end: fileUploadUrlProd + 'vault_digital_assets/v1/chunk_uploads/end',
      postUploadProcess:
        fileUploadUrlProd + 'digital_assets/v1/upload/job_status',

      fileUploadStart: baseURLProd + 'start_job',
      fileUploadProcess: baseURLProd + 'process_chunk',
      fileUploadEnd: baseURLProd + 'end_job',
    },
    renditionsUrl: baseURLProd + 'renditions',

    ggaRenditions: baseGgaURLProd + 'renditions/',
    projectList: {
      listOfProjects: baseURLProd + 'list_projects',
      editProject: baseURLProd + 'project',
      uploadProjectThumbnail: baseURLProd + 'project_thumbnail',
    },
    cart: {
      fetch: baseURLProd + 'cart/',
      post: baseURLProd + 'cart/',
      export: baseURLProd + 'export_assets/',
    },
    comments: {
      addComments: baseURLProd + 'add_comment',
      displayComments: baseURLProd + 'experience/comments/',
      renderComments: baseURLProd + 'get_render_comment/',
      saveComment: baseURLProd + 'save_render_comment',
      getScribble: baseURLProd + 'scribble_data',
      saveScribble: baseURLProd + 'save_scribble',
    },
    uploadAssets: {
      objectDetection: visionSearchApi + 'object_detections?key=',
      imageLabel: visionSearchApi + 'image_labels?key=',
      saveAsset: baseURLProd + 'save_arch_asset',
      purchasedSaveAsset: baseURLProd + 'save_prop_asset',
      editAsset: baseURLProd + 'edit_arch_asset',
      deleteAsset: baseURLProd + 'delete_arch_asset/',
      purchasedEditAsset: baseURLProd + 'edit_prop_asset',
      purchasedDeleteAsset: baseURLProd + 'delete_prop_asset/',
      getUploadStatus: baseURLProd + 'get_arch_asset/',
      getPurchaseUploadStatus: baseURLProd + 'get_prop_asset/',
    },
    reportAnIssue: {
      saveIssue: baseURLProd + 'ingest_asset/',
    },
    searchUser: baseURLProd + 'search_user',
    support: {
      submitForm: fileUploadUrlProd + 'digital_assets/v1/user_issues',
      uploadSnapShot:
        fileUploadUrlProd + 'digital_assets/v1/user_issues/attachment/',
    },
    fontAwesomeUrl: 'https://use.fontawesome.com/releases/v5.1.0/css/all.css',

    //Flags for toggling feature availability
    enableFlags: {
      // templateListTab: false,
    },
    assets: {
      fetchArchitecturalAssets: baseURLProd + 'architecture_search',
    },

    gallery: {
      search: `${GalleryProdApi}search/assets`,
      filters: `${GalleryProdApi}search/assets-filters`,
      assetDetails: `${GalleryProdBaseURL}assets`,
    },
    storageGridUrl: baseStorageGridUrlStage,
    uploadTemplate: {
      getBusinessDepartment: baseURLProd + 'get_business_departments',
      validatePath: baseURLProd + 'validate_file',
      saveTemplate: baseURLProd + 'save_template',
      getProjectTypes: baseURLProd + 'project/types',
      ingestTemplate: baseURLProd + 'ingest_template',
      getQaPluginStatus: baseURLProd + 'templates/qa_report',
    },
    audit: {
      saveAuditReport: baseAuditURLProd + 'cad_event_track',
    },
    statistics: baseURLProd + 'get_scene_stats',
    feedback: baseURLProd + 'feedback',
    materialEditor: {
      getSrcMaterialList: baseURLProd + 'get_asset_material_metadata',
      fetchColorLibraryList: baseURLProd + 'color_library',
      getColorLibraryMetadata:
        baseURLProd + 'color_library/content?library_id=',
      getTextureLibraryMetadata:
        baseURLProd + 'texture_library/get_content?library_id=',
      updateColorLibrary: baseURLProd + '/color_library/update',
      updateTextureLibrary: baseURLQA + 'texture_library/update',
      fetchTextureLibraryList: baseURLProd + 'texture_library/get_list',
    },
  },
}

// env.js sets APP_ENV
const appEnv = process.env.REACT_APP_ENV || 'qa'
const envConfig = envConfigs[appEnv]
const config = merge(commonAuthConfig, envConfig)

export default config
