import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import CommentList from '../Comments/CommentList'
import NewComment from './newComment'
import EditUserNotification from './EditUserNotification'

import { PageTitleBar, CustomIconButton } from 'cgi-ui-components'
import CloseIcon from '@mui/icons-material/Close'

const styles = (theme) => ({
  header: {
    '& .MuiFormControl-marginDense': {
      marginBottom: 0,
    },
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 20,
  },
  commentList: {
    zIndex: '199',
    backgroundColor: theme.palette.secondary.main,
  },
  headerContainer: {
    padding: '0 20px 20px 20px ',
    zIndex: 200,
    backgroundColor: theme.palette.secondary.main,
  },
})

class RenderComments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showNotification: false,
      showAddCommentOptions: false,
    }
  }

  handleShow = () => {
    const { showNotification } = this.state
    this.setState(
      {
        showNotification: !showNotification,
      },
      () => {
        const { showNotification } = this.state
        if (showNotification) {
          setTimeout(() => {
            this.setState({
              showNotification: false,
            })
          }, 10000)
        }
      }
    )
  }

  showAddCommentOptions = (value) => {
    this.setState({
      showAddCommentOptions: value,
    })
  }

  render() {
    const {
      classes,
      onCloseHandler = () => {},
      imageDetails,
      onMouseOverAnnotation = () => {},
      onMouseOutAnnotation = () => {},
    } = this.props
    const { showNotification, showAddCommentOptions } = this.state
    return (
      <>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <div
              style={{
                marginBottom: 40,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <PageTitleBar
                  title={`Comments`}
                  subTitle={'Manage conversation with peers'}
                />
              </div>

              <CustomIconButton
                aria-label="close"
                label="close"
                onClick={onCloseHandler}
              >
                <CloseIcon />
              </CustomIconButton>
            </div>
            <NewComment
              imageDetails={imageDetails}
              handleShow={this.handleShow}
              handleshowAddCommentOptions={this.showAddCommentOptions}
            />
            <EditUserNotification show={showNotification} />
          </div>
        </div>
        <CommentList
          className={classes.commentList}
          imageDetails={imageDetails}
          onMouseOverAnnotation={onMouseOverAnnotation}
          onMouseOutAnnotation={onMouseOutAnnotation}
          showAddCommentOptions={showAddCommentOptions}
        />
      </>
    )
  }
}

RenderComments.propTypes = {
  classes: PropTypes.object.isRequired,
  onCloseHandler: PropTypes.func,
}

export default withStyles(styles)(RenderComments)
