import React from 'react'
import { AuthProvider } from '@praxis/component-auth'
import AuthComponent from './auth/index'
import config from './config/config'
import { ThemeProvider } from '@mui/material/styles'

import { themeconfig } from 'cgi-ui-components'

const clientId = config.auth.clientId
const redirectUri = config.auth.redirectUri
const authorizationUrl = config.auth.authorizationUrl
const logoutUrl = config.auth.logoutUrl

const App = () => (
  <AuthProvider
    clientId={clientId}
    authorizationUrl={authorizationUrl}
    logoutUrl={logoutUrl}
    isFullPageAuth
    redirectUri={redirectUri}
  >
    <ThemeProvider theme={themeconfig}>
      <AuthComponent />
    </ThemeProvider>
  </AuthProvider>
)

export default App
