import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { PageTitleBar, CustomIconButton } from 'cgi-ui-components'
import { Grid, Popover } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
const styles = (theme) => ({
  lowerGrid: {
    paddingLeft: 20,
    paddingRight: 20,
    width: 350,
  },

  headerContent: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  command: {
    color: theme.palette.primary.darkFont,
    fontSize: '14px',
    lineHeight: '20px',

    marginBottom: 20,
  },
  commandName: {
    textTransform: 'uppercase',
  },

  commandKey: {
    textAlign: 'right',
  },
})

class HelpContent extends React.Component {
  renderingStaticData() {
    const { classes } = this.props
    return (
      <>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Save
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+S | Ctrl+S
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Duplicate
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+D | Ctrl+D
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Delete
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            DELETE
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Hide
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+H | Ctrl+H
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Show All
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+&#8679;+H | Ctrl+&#8679;+H
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Group
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+G | Ctrl+G
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Ungroup
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+&#8679;+G | Ctrl+&#8679;+G
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Undo
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+Z | Ctrl+Z
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Redo
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+&#8679;+Z | Ctrl+&#8679;+Z
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Pan Camera
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            A, S, D, W
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Render
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8679;+Q
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            New
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            N
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Open
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            O
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Move
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            T
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Rotate
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            R
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Lock
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+L | Ctrl+L
          </Grid>
        </Grid>

        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Unlock
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8984;+&#8679;+L | Ctrl+&#8679;+L
          </Grid>
        </Grid>
        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            Show render frame
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8679;+F
          </Grid>
        </Grid>

        <Grid container className={classes.command}>
          <Grid item xs={6} className={classes.commandName}>
            reset default camera
          </Grid>
          <Grid item xs={6} className={classes.commandKey}>
            &#8679;+R
          </Grid>
        </Grid>
      </>
    )
  }

  render() {
    const { classes, closeHelp = () => {}, anchorEl = null } = this.props

    return (
      <Popover
        id="help-popover"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={closeHelp}
        open={anchorEl !== null}
      >
        <div className={classes.headerContent}>
          <div>
            <PageTitleBar
              subTitle="Keyboard shortcuts to use the 3D Editor "
              title="Help"
            />
          </div>
          <div style={{}}>
            <CustomIconButton
              color="tertiary"
              onClick={closeHelp}
              label="Close"
            >
              <CloseIcon />
            </CustomIconButton>
          </div>
        </div>
        <div className={classes.lowerGrid}>{this.renderingStaticData()}</div>
      </Popover>
    )
  }
}

HelpContent.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpenHelp: PropTypes.bool,
  closeHelp: PropTypes.func,
}

export default withStyles(styles)(HelpContent)
