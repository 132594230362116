import filter from 'lodash/filter'
import {
  START_TEMPLATE_LIST,
  SUCCESS_TEMPLATE_LIST,
  FAILURE_TEMPLATE_LIST,
  TOGGLE_TEMPLATE_MODAL,
  SEARCH_TEMPLATE,
  SELECT_TEMPLATE,
  SUCCESS_TEMPLATE_DETAILS,
  CLEAR_TEMPLATE_THUMBNAIL_DATA,
  TEMPLATE_THUMBNAIL_IMG_SUCCESS,
  TEMPLATE_DOWNLOAD_STARTED,
  DOWNLOAD_TEMPLATE_SUCCESS,
  SET_TEMPLATE_EDIT_MODE,
  CLEAR_TEMPLATE_LIST,
  SAVE_SEARCH_QUERY,
  ADD_BUSINESS_DEPARTMENT_LIST,
} from './actionType'

export const initialState = {
  loading: false,
  statusMessage: '',
  templateList: [],
  selectStatus: false,
  isFiltered: false,
  filteredList: {},
  selectedTemplate: { templateId: '', templateUrl: '', sceneData: {} },
  currentTemplateDetails: { template_rendition_image_url: '' },
  shouldChangeTemplate: false,
  templateDownloadedStarted: false,
  editTemplateMode: false,
  editTemplateId: '',
  savedQuery: '',
  businessDepts: [],
}

export default function templateReducer(state = initialState, action) {
  switch (action.type) {
    case START_TEMPLATE_LIST: {
      const newState = {
        ...state,
        loading: true,
        statusMessage: '',
        // templateList: [],
        isFiltered: false,
        filteredList: {},
      }
      return newState
    }
    case CLEAR_TEMPLATE_LIST: {
      const newState = {
        ...state,
        templateList: [],
      }
      return newState
    }
    case SAVE_SEARCH_QUERY: {
      const newState = {
        ...state,
        savedQuery: action.payload.query,
      }
      return newState
    }
    case SUCCESS_TEMPLATE_LIST: {
      const newState = {
        ...state,
        loading: false,
        statusMessage: 'success',
        templateList: [
          ...state.templateList,
          ...action.payload.data.template_list,
        ],
        isFiltered: false,
        filteredList: {},
        total_count: action.payload.data.result_count,
      }
      return newState
    }
    case FAILURE_TEMPLATE_LIST: {
      const newState = {
        ...state,
        loading: false,
        statusMessage: action.payload,
        templateList: [],
        isFiltered: false,
        filteredList: {},
      }
      return newState
    }

    case TOGGLE_TEMPLATE_MODAL: {
      const newState = {
        ...state,
        templateModalOpen: action.payload.bool,
        isFiltered: false,
        filteredList: {},
        shouldChangeTemplate: action.payload.shouldChangeTemplate,
      }
      return newState
    }

    case SEARCH_TEMPLATE: {
      const searchTerm = action.payload.toLowerCase()
      const templateList = state.templateList
      let filteredList = {}
      if (searchTerm) {
        filteredList = filter(templateList, function (obj) {
          return (
            (obj.template_name || '').toLowerCase().indexOf(searchTerm) !==
              -1 ||
            (obj.template_tags || '')
              .toString()
              .toLowerCase()
              .indexOf(searchTerm) !== -1
          )
        })
      }
      const newState = {
        ...state,
        isFiltered: searchTerm ? true : false,
        filteredList: filteredList,
      }
      return newState
    }

    case SELECT_TEMPLATE: {
      return { ...state, selectedTemplate: { ...action.data } }
    }
    case SUCCESS_TEMPLATE_DETAILS: {
      return { ...state, currentTemplateDetails: { ...action.payload } }
    }
    case CLEAR_TEMPLATE_THUMBNAIL_DATA: {
      return {
        ...state,
        currentTemplateDetails: { template_rendition_image_url: '' },
      }
    }
    case TEMPLATE_THUMBNAIL_IMG_SUCCESS: {
      return {
        ...state,
        currentTemplateDetails: {
          ...state.currentTemplateDetails,
          template_rendition_image_url: action.payload,
        },
      }
    }
    case DOWNLOAD_TEMPLATE_SUCCESS: {
      return {
        ...state,
        templateDownloadedStarted: false,
      }
    }
    case TEMPLATE_DOWNLOAD_STARTED: {
      return {
        ...state,
        templateDownloadedStarted: action.payload,
      }
    }
    case SET_TEMPLATE_EDIT_MODE: {
      return {
        ...state,
        editTemplateMode: action.payload.editTemplateMode,
        editTemplateId: action.payload.templateId,
      }
    }
    case ADD_BUSINESS_DEPARTMENT_LIST: {
      return {
        ...state,
        businessDepts: action.data,
      }
    }
    default:
      return state
  }
}
