import {
  SCENE_NEW,
  SCENE_OPEN,
  SCENE_IMPORT,
  SCENE_SAVE,
  SCENE_SAVE_AS,
  SCENE_ESCAPE,
  SCENE_MOVE,
  SCENE_ROTATE,
  SCENE_DELETE,
  SCENE_DUPLICATE,
  SCENE_HIDE,
  SCENE_SHOW_ALL,
  SCENE_GROUP,
  SCENE_UNGROUP,
  SCENE_UNDO,
  SCENE_REDO,
  LOCK_ASSET,
  FOCUS_ASSET,
} from '../constants/scene'

export const getActionByKey = (event) => {
  const pressedKey = (event.key || '').toLowerCase()

  const CmdCtrlKey = event.ctrlKey || event.metaKey
  const ShiftKey = event.shiftKey

  if (CmdCtrlKey && ShiftKey && pressedKey === 'h') {
    return SCENE_SHOW_ALL
  } else if (CmdCtrlKey && ShiftKey && pressedKey === 'g') {
    return SCENE_UNGROUP
  } else if (CmdCtrlKey && ShiftKey && pressedKey === 's') {
    return SCENE_SAVE_AS
  } else if (CmdCtrlKey && !ShiftKey && pressedKey === 'd') {
    return SCENE_DUPLICATE
  } else if (CmdCtrlKey && !ShiftKey && pressedKey === 'h') {
    return SCENE_HIDE
  } else if (CmdCtrlKey && !ShiftKey && pressedKey === 'g') {
    return SCENE_GROUP
  } else if (CmdCtrlKey && !ShiftKey && pressedKey === 'i') {
    return SCENE_IMPORT
  } else if (CmdCtrlKey && !ShiftKey && pressedKey === 's') {
    return SCENE_SAVE
  } else if (!CmdCtrlKey && !ShiftKey && pressedKey === 'escape') {
    return SCENE_ESCAPE
  } else if (!CmdCtrlKey && !ShiftKey && pressedKey === 'n') {
    return SCENE_NEW
  } else if (!CmdCtrlKey && !ShiftKey && pressedKey === 'o') {
    return SCENE_OPEN
  } else if (!CmdCtrlKey && !ShiftKey && pressedKey === 't') {
    return SCENE_MOVE
  } else if (!CmdCtrlKey && !ShiftKey && pressedKey === 'r') {
    return SCENE_ROTATE
  } else if (
    !CmdCtrlKey &&
    !ShiftKey &&
    (pressedKey === 'backspace' || pressedKey === 'delete')
  ) {
    return SCENE_DELETE
  } else if (CmdCtrlKey && !ShiftKey && pressedKey === 'z') {
    return SCENE_UNDO
  } else if (CmdCtrlKey && ShiftKey && pressedKey === 'z') {
    return SCENE_REDO
  } else if (pressedKey === 'f') {
    return FOCUS_ASSET
  } else if (CmdCtrlKey && pressedKey === 'l') {
    return LOCK_ASSET
  } else {
    return false
  }
}
