import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Typography, CustomButton } from 'cgi-ui-components'

import { Close } from '@mui/icons-material'
const styles = (theme) => ({
  closeBtn: {
    color: '#b0120e',
  },
})

export class AssetScaleFooter extends Component {
  render() {
    const { classes, onClose, isUniformityChecked, handleUniformityChange } =
      this.props
    return (
      <Grid container justifyContent="space-between">
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={isUniformityChecked}
                onChange={handleUniformityChange}
                name="uniformFlag"
                color="tertiary"
                aria-checked={isUniformityChecked}
              />
            }
            label={
              <Typography variant="body2" label="Scale uniformly"></Typography>
            }
          />
        </Grid>
        <Grid item>
          <CustomButton
            label="Close"
            variant="text"
            startIcon={<Close />}
            size="medium"
            className={classes.closeBtn}
            onClick={onClose}
          />
        </Grid>
      </Grid>
    )
  }
}

AssetScaleFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleUniformityChange: PropTypes.func.isRequired,
}

AssetScaleFooter.defaultProp = {
  onClose: () => {},
  onUniformityChange: () => {},
  isUniformityChecked: false,
}

export default withStyles(styles)(AssetScaleFooter)
