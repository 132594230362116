import {
  GET_PROJECT_LIST,
  API_CALL_INITIATED_PROJECT_LIST,
  ADD_LIST_PAGE_META_PROJECT_LIST,
  API_CALL_FAIL_PROJECT_LIST,
  CLEAR_PROJECT_LIST,
  NO_PROJECTS_FOUND_PROJECT_LIST,
} from './ActionTypeProjectList'

export const projectListState = {
  projectList: [],
  totalProjectCount: 0,
  responseProjectCount: 0,
  projectListloading: false,
  listPageMeta: {},
  noResultsFound: false,
}

export default function projectListData(state = projectListState, action = {}) {
  switch (action.type) {
    case API_CALL_INITIATED_PROJECT_LIST: {
      return {
        ...state,
        projectListloading: true,
      }
    }

    case ADD_LIST_PAGE_META_PROJECT_LIST: {
      return { ...state, listPageMeta: { ...action.data } }
    }

    case GET_PROJECT_LIST: {
      const { data } = action
      const {
        total_count: totalRecords,
        response_count: responseRecords,
        isShowSearch,
      } = data

      const resultObj = {
        apiError: action.success,
        projectList: !isShowSearch
          ? [...state.projectList, ...data.search_response]
          : data.search_response,
        projectListloading: false,
        noResultsFound: false,
        totalProjectCount: totalRecords,
        responseProjectCount: responseRecords,
      }
      if (isShowSearch && totalRecords > 0) {
        const obj = { ...resultObj, ...{ isShow: true } }
        return { ...state, ...obj }
      }
      return { ...state, ...resultObj }
    }

    case API_CALL_FAIL_PROJECT_LIST: {
      return {
        ...state,
        apiError: action.error,
        projectListloading: false,
      }
    }

    case CLEAR_PROJECT_LIST: {
      const emptyList = {
        projectList: [],
        noResultsFound: false,
      }
      return { ...state, ...emptyList }
    }

    case NO_PROJECTS_FOUND_PROJECT_LIST: {
      return {
        ...state,
        noResultsFound: true,
        projectListloading: false,
        totalProjectCount: 0,
        projectList: [],
      }
    }

    default:
      return state
  }
}
