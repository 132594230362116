import react, { useState, useEffect } from 'react'

import { makeStyles } from '@mui/styles'
import { CustomIconButton, Typography } from 'cgi-ui-components'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Backdrop from '@mui/material/Backdrop'
import axios from 'axios'
import config from '../../config/config'
const useStyles = makeStyles((theme) => ({
  statWidget: {
    position: 'fixed',
    top: 150,
    display: 'flex',

    zIndex: theme.zIndex.drawer + 10,
  },
  open: {
    right: 0,
    transition: `right 0.4s ease-in-out`,
    zIndex: theme.zIndex.drawer + 11,
  },
  close: {
    right: -200,
    transition: `right 0.4s ease-in-out`,
  },
  statButton: {
    width: 44,
    height: 44,
    background: '#CC0000',
    marginTop: 20,
  },
  statContent: {
    width: 180,

    background: '#FFF',
    borderRadius: 4,
    padding: '10px 10px 0 10px',
  },
  section: {
    background: `rgba(172, 0, 0, 0.08)`,
    borderRadius: 4,
    marginBottom: 10,
    padding: '6px 10px 6px 10px',
  },
}))

const Statistics = ({}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [statData, setStatData] = useState({})
  const handleClick = (e) => {
    setOpen(!open)
  }

  useEffect(() => {
    axios
      .get(config.statistics)
      .then((resp) => {
        setStatData(resp.data)
      })
      .catch((e) => {
        // console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  const classes = useStyles()

  return (
    <>
      <div
        className={`${classes.statWidget} ${
          open ? classes.open : classes.close
        }`}
      >
        <div className={classes.statButton}>
          <CustomIconButton onClick={handleClick}>
            <TrendingUpIcon color="secondary" />
          </CustomIconButton>
        </div>

        <div className={classes.statContent}>
          <div className={classes.section}>
            <Typography label="Scenes Created" variant="caption" />
            <Typography
              label={statData?.scene_count || 'Counting...'}
              variant={loading ? 'body2' : 'h6'}
            />
          </div>
          <div className={classes.section}>
            <Typography label="Renders Generated" variant="caption" />
            <Typography
              label={statData?.rendered_count || 'Counting...'}
              variant={loading ? 'body2' : 'h6'}
            />
          </div>
          <div className={classes.section}>
            <Typography label="Renders In Progress" variant="caption" />
            <Typography
              label={statData?.rendering_count || 'Counting...'}
              variant={loading ? 'body2' : 'h6'}
            />
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClick}
      ></Backdrop>
    </>
  )
}
export default Statistics
