export const START_TEMPLATE_LIST = 'START_TEMPLATE_LIST'
export const SUCCESS_TEMPLATE_LIST = 'SUCCESS_TEMPLATE_LIST'
export const FAILURE_TEMPLATE_LIST = 'FAILURE_TEMPLATE_LIST'

export const TOGGLE_TEMPLATE_MODAL = 'TOGGLE_TEMPLATE_MODAL'
export const SEARCH_TEMPLATE = 'SEARCH_TEMPLATE'
export const API_CALL_FAIL = 'API_CALL_FAIL'

export const SELECT_TEMPLATE = 'SELECT_TEMPLATE'
export const SUCCESS_TEMPLATE_DETAILS = 'SUCCESS_TEMPLATE_DETAILS'
export const FAILURE_TEMPLATE_DETAILS = 'FAILURE_TEMPLATE_DETAILS'
export const DOWNLOAD_TEMPLATE_SUCCESS = 'DOWNLOAD_TEMPLATE_SUCCESS'
export const DOWNLOAD_TEMPLATE_FAIL = 'DOWNLOAD_TEMPLATE_FAIL'
export const CLEAR_TEMPLATE_THUMBNAIL_DATA = 'CLEAR_TEMPLATE_THUMBNAIL_DATA'
export const TEMPLATE_THUMBNAIL_IMG_FAIL = 'TEMPLATE_THUMBNAIL_IMG_FAIL'
export const TEMPLATE_THUMBNAIL_IMG_SUCCESS = 'TEMPLATE_THUMBNAIL_IMG_SUCCESS'
export const TEMPLATE_DOWNLOAD_STARTED = 'TEMPLATE_DOWNLOAD_STARTED'
export const SET_TEMPLATE_EDIT_MODE = 'SET_TEMPLATE_EDIT_MODE'
export const CLEAR_TEMPLATE_LIST = 'CLEAR_TEMPLATE_LIST'
export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY'
export const ADD_BUSINESS_DEPARTMENT_LIST = 'ADD_BUSINESS_DEPARTMENT_LIST'
