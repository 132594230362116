import { combineReducers } from 'redux'
import auth from './auth'
import templates from '../store/template/reducer'
import experiences from '../store/experience/Reducer'
import Board from './board/reducer'
import cart from '../store/cart/Reducer'
import explorer from '../store/explorer/reducer'
import projectListData from '../store/projectList/ReducerProjectList'
// import layout from '../store/layout/reducer'
import notification from './notification/reducer'
import banner from './banner/reducer'
// import projects from './projects/reducer'
// import tcinList from './tcinList/reducer'
// import itemDetailsReducer from './TcinDetails/Reducer'
import collectionData from '../store/favorites/Reducer'
import activeOperations from './operations/reducer'
import comments from '../store/comments/Reducer'
import assets from '../store/assets/reducer'
import uploadAssets from '../store/UploadAssets/Reducer'
import purchasedAssets from '../store/purchasedAssets/reducer'
import scene from '../store/scene/Reducer'
import uploadTemplate from '../store/UploadTemplate/Reducer'
import materialEditor from '../store/materialEditor/reducer'

const rootReducer = combineReducers({
  auth,
  templates,
  experiences,
  projectListData,
  explorer,
  notification,
  banner,
  cart,
  Board,
  collectionData,
  activeOperations,
  comments,
  assets,
  uploadAssets,
  purchasedAssets,
  scene,
  uploadTemplate,
  materialEditor,
})

export default rootReducer
