import React from 'react'
import { withStyles } from '@mui/styles'
import Proptypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { fetchFilterListsData } from '../../store/explorer/actionCreator'
import FilterDrawer from '../filterDrawer'

const styles = (theme) => ({
  checkBox: {
    padding: '1px',
  },
  rootMinimize: {
    margin: '10px 0 10px 0',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '365px',
    border: '1px solid rgba(51, 51, 51, 0.2)',
    overflow: 'auto',
    width: '200px',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '85px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D6D6D6',
      borderRadius: '1.5px',
    },
  },
  rootMaximize: {
    backgroundColor: theme.palette.primary.light,
    margin: '0 15px 37px 0',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '345px',
    border: '1px solid #D6D6D6',
    overflow: 'auto',
    borderRadius: '2px',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '85px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D6D6D6',
      borderRadius: '1.5px',
    },
  },
})

class FilterListsWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterItems: [],
      searchFilterText: '',
    }
  }

  componentDidMount() {
    const { filterLists = {}, fetchFilterListsData } = this.props
    if (isEmpty(filterLists)) {
      fetchFilterListsData()
    }
  }

  render() {
    const {
      filterLists = {},
      classes = {},
      maximizedView = false,
      handleFilters = () => {},
      disableAssetContains = false,
      isMinified = false,
    } = this.props

    const KEY_MAPPINGS = {
      tcin_cycle: 'Project Cycle',
      Asset_Contains: 'Asset Contains',
      renderer: 'Render Support',
      department_name: 'Department',
      brand_name: 'Brand',
      product_color: 'Color',
      CLASS_NAME: 'Class Name',
      BRAND: 'Brand',
      DEPARTMENT_NAME: 'Department Name',
      DESIGN_CYCLE_NAME: 'Design Cycle Name',
      DIVISION_NAME: 'Division Name',
      item_identifiers: 'Item Identifiers',
    }

    return (
      <>
        {Object.keys(KEY_MAPPINGS)
          .filter((item) =>
            disableAssetContains ? item !== 'Asset_Contains' : true
          )
          .map((item) => {
            if (filterLists[item] && filterLists[item].length > 0) {
              return (
                <div
                  className={
                    maximizedView ? classes.rootMaximize : classes.rootMinimize
                  }
                  style={{
                    height: 'auto',
                    maxHeight: 350,
                    width: isMinified ? '150px' : '190px',
                  }}
                >
                  <FilterDrawer
                    title={KEY_MAPPINGS[item] || item.replaceAll('_', ' ')}
                    filterKey={item}
                    filterLists={filterLists[item]}
                    maximizedView={maximizedView}
                    handleFilters={handleFilters}
                    isMinified={isMinified}
                  />
                </div>
              )
            }
          })}
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { fetchFilterListsData })(
  withStyles(styles)(FilterListsWrapper)
)
