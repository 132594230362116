import axios from 'axios'
import {
  GET_PROJECT_LIST,
  API_CALL_INITIATED_PROJECT_LIST,
  ADD_LIST_PAGE_META_PROJECT_LIST,
  API_CALL_FAIL_PROJECT_LIST,
  CLEAR_PROJECT_LIST,
  NO_PROJECTS_FOUND_PROJECT_LIST,
} from './ActionTypeProjectList'
import api from '../../config/config'

const axiosCancelToken = axios.CancelToken
const axiosSource = axiosCancelToken.source()

export function addListPageMeta(data) {
  return {
    type: ADD_LIST_PAGE_META_PROJECT_LIST,
    data,
  }
}

export function apiCallInitiated(data) {
  return {
    type: API_CALL_INITIATED_PROJECT_LIST,
    data,
  }
}

export function fetchRecordsSuccess(data) {
  return {
    type: GET_PROJECT_LIST,
    data,
  }
}

export function apiCallFail(error) {
  return {
    type: API_CALL_FAIL_PROJECT_LIST,
    error,
    data: [],
  }
}

export function clearProject() {
  return {
    type: CLEAR_PROJECT_LIST,
    data: '',
  }
}

export function noProjectsFound() {
  return {
    type: NO_PROJECTS_FOUND_PROJECT_LIST,
  }
}

export function fetchProject(
  recordsPerPage = 15,
  pageNum = 1,
  searchVal = '',
  sortBy = 'created_date',
  sortOrder = 'DESC',
  isShowSearch,
  projectType = '',
  onFetchProjectFail = () => {}
) {
  return function (dispatch) {
    dispatch(
      apiCallInitiated({
        type: API_CALL_INITIATED_PROJECT_LIST,
        data: [],
      })
    )
    dispatch(
      addListPageMeta({
        recordsPerPage,
        pageNum,
        searchVal,
        sortBy,
        sortOrder,
        projectType,
      })
    )
    const urlWithParam =
      api.projectList.listOfProjects +
      '?page=' +
      pageNum +
      '&count=' +
      recordsPerPage +
      '&sort_by=' +
      sortBy +
      '&sort_order=' +
      sortOrder +
      '&project_name=' +
      searchVal +
      '&project_type=' +
      projectType
    return axios
      .get(urlWithParam, { cancelToken: axiosSource.token })
      .then((response) => {
        if (response.status === 204) {
          dispatch(noProjectsFound())
        } else {
          if (
            response.data.search_response &&
            response.data.search_response.length === 0
          ) {
            dispatch(noProjectsFound())
          } else {
            dispatch(
              fetchRecordsSuccess({ ...response.data, ...{ isShowSearch } })
            )
          }
        }
      })
      .catch((error) => {
        dispatch(apiCallFail(error))
        onFetchProjectFail(error)
      })
  }
}

export function cancelProjectListCalls() {
  return function (dispatch) {
    axiosSource.cancel('Operation canceled by User')
  }
}
