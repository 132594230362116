import React from 'react'
import { BasicTabs } from 'cgi-ui-components'

import Assets from './assets'

const ProductAndProps = () => {
  return (
    <BasicTabs
      onTabSelect={() => {}}
      tabAria="basic tabs example"
      tabItems={[
        {
          label: 'Product and Props',
          content: <Assets />,
        },
        {
          label: 'Architectural Assets',
          content: <Assets isArchAssets={true} />,
        },
      ]}
    />
  )
}

export default ProductAndProps
