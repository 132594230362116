import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import Banner from '../../components/Banner'
import RenderList from '../../components/renderList'
import withRouter from '../../components/HOC/withRouterHOC'

const styles = (theme) => ({
  root: {},
  container: {
    width: '90%',
    maxWidth: 1560,
    margin: 'auto',
  },
  bannerContainer: {
    position: 'relative',
    top: 0, // Note this height depends upon the height of AppBar used in this components. This needs to be changed if height of AppBar changed
    zIndex: 10,
  },
})

class RenderLayout extends React.Component {
  constructor(props) {
    super(props)
    const { match: { params: { expId = 'new' } = {} } = {} } = props
    this.state = {
      sceneId: expId,
    }
  }
  render() {
    const { history, classes, auth } = this.props
    const { sceneId } = this.state
    // const userHaveWriteAccess = canUserWrite(
    //   this.props.auth.lanId,
    //   initiator,
    //   members
    // )
    return (
      <>
        <div className={classes.bannerContainer}>
          <Banner />
        </div>
        <div className={classes.container}>
          <RenderList
            sceneId={sceneId}
            userHaveWriteAccess={true}
            history={history}
          ></RenderList>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(withRouter(RenderLayout)))
