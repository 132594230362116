/* eslint-disable default-case */
import React, { Fragment } from 'react'
import { styled, withStyles } from '@mui/styles'

import { Chip, Typography, List, ListItem, Divider, Grid } from '@mui/material'
import { Box } from '@mui/material'
import { Close, CheckCircleOutline, HighlightOff } from '@material-ui/icons'

import ErrorStyles from './ErrorSummaryStyles'
import { TemplateBaseErrorTable, AssetsErrorTable } from './ErrorTable'
import { readableDateAndTimeDigitFormat } from '../../helpers/dataFormats'
import { INGESTION_STATUS_DISPLAY_MAP, VALID_STATUS } from './MyUploads'

const summaryColors = {
  info: { bg: '#E6E7EA', border: '#B3B6BC' },
  error: { bg: '#FCDEC5', border: '#AE570F' },
  success: { bg: '#D1F0DB', border: '#32864E' },
}

const SummaryContainer = styled('div')(({ theme, color, bg_color }) => ({
  background: bg_color && bg_color !== '' ? bg_color : summaryColors[color].bg,
  borderBottom: `1px solid ${summaryColors[color].border}`,
  padding: 12,
}))

const CustomAlert = ({
  color = 'success',
  message = '',
  Icon,
  icon_color,
  text_color,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          marginRight: 12,
          padding: '5px 0px',
          display: 'flex',
          fontSize: '22px',
          color: text_color ?? summaryColors[color].border,
        }}
      >
        {Icon ? (
          <Icon fontSize="small" sx={{ color: icon_color }} />
        ) : color == 'success' ? (
          <CheckCircleOutline />
        ) : (
          <HighlightOff />
        )}
      </div>

      <div
        style={{
          padding: '8px 0px',
          minWidth: '0px',
          overflow: 'auto',
        }}
      >
        {message !== null &&
        message !== '' &&
        message.trim() !== '' &&
        message !== 'null'
          ? message
          : 'NA'}
      </div>
    </div>
  )
}

const StyledChip = ({ data = '' }) => {
  let color = 'error'
  let label = 'Not usable'
  const message_str = data && data !== null ? data.toLowerCase().trim() : ''
  if (
    message_str == 'uploaded with errors' ||
    message_str == 'upload successful'
  ) {
    color = 'success'
    label = 'Usable'
  }

  return (
    <Chip
      style={{
        background: `${summaryColors[color].border}`,
        color: '#fff',
        fontWeight: '400',
        fontSize: '13px',
        height: 22,
      }}
      label={label}
    />
  )
}
class ErrorSummary extends React.Component {
  toggleDialog = () => (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.toggleError(false)
  }

  render() {
    const { classes, templateDetails } = this.props

    const { error_list = [], created_date: last_run_date } =
      templateDetails?.plugin_qa_report ?? { error_list: [], created_date: '' }

    const { template_error_details, ingestion_qa_report } = templateDetails

    let template_QA_Base_status = '',
      template_QA_Base_date = '',
      template_QA_Base_errors = []

    if (ingestion_qa_report?.qa_status) {
      template_QA_Base_status = ingestion_qa_report.qa_status ?? ''
      template_QA_Base_date = ingestion_qa_report.created_date
      template_QA_Base_errors = ingestion_qa_report.error_list
    } else if (
      template_error_details &&
      template_error_details !== null &&
      Object.keys(template_error_details).length
    ) {
      template_QA_Base_status =
        template_error_details[Object.keys(template_error_details)[0]]
          ?.description
      template_QA_Base_errors = Object.keys(template_error_details)
    }

    const template_base_color_status =
      template_QA_Base_status == 'QA_SUCCESS' ? 'success' : 'error'

    const asset_error_details_status =
      templateDetails.asset_error_details &&
      Object.keys(templateDetails.asset_error_details).length
        ? 'error'
        : templateDetails.ingestion_status &&
          templateDetails.ingestion_status.includes('success')
        ? 'success'
        : 'error'

    let { ingestion_status } = templateDetails

    let ingestion_status_list = VALID_STATUS.includes(ingestion_status)
      ? ingestion_status
      : 'other'
    let {
      backgroundColor: ingestion_backgroundColor,
      iconColor: ingestion_iconColor,
      Icon: ingestion_Icon,
      descTxtColor: ingestion_descTxtColor,
      colorCode: ingestionColorCode,
    } = INGESTION_STATUS_DISPLAY_MAP[ingestion_status_list]

    return (
      <Fragment>
        <Typography
          variant="h5"
          gutterBottom
          className={classes.headerTitle}
          component="div"
        >
          Upload Summary
        </Typography>
        <Close
          className={classes.closeIcon}
          onClick={this.toggleDialog(false)}
        />

        <Grid container spacing={0} className={classes.headerContent}>
          <Grid item xs={5}>
            <label> Template Name:</label>
            {templateDetails.template_name}
          </Grid>
          <Grid item xs={7}>
            <label> Upload initiated by:</label>
            {templateDetails.initiator_display_name}
          </Grid>
          <Grid item xs={5}>
            <label>New/Refresh Template:</label>
            {templateDetails.new_ingestion == true ? 'New' : 'Refresh'} Template
          </Grid>
          <Grid item xs={7}>
            <label> Template usablity status:</label>
            <StyledChip data={templateDetails.ingestion_status} />
          </Grid>
        </Grid>

        <Divider />

        <div className={classes.mainContainer}>
          <>
            <Grid container spacing={0}>
              {/** Left container */}
              <Grid item xs={6} className={classes.leftContainer}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2">
                    Status: 3Ds Max QA Plugin
                  </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="label">
                    Last run:{' '}
                    {last_run_date && last_run_date !== null
                      ? readableDateAndTimeDigitFormat(last_run_date)
                      : '-'}
                  </Typography>
                </Box>
                <SummaryContainer
                  color={
                    templateDetails.qa_plugin_status === 'Not Run'
                      ? 'error'
                      : 'info'
                  }
                >
                  {error_list &&
                  error_list !== null &&
                  error_list.length > 0 ? (
                    <>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body">Error(s) found</Typography>
                      </Box>
                      <List disablePadding>
                        {error_list.map((item, i) => {
                          return <ListItem key={i}>{item.description}</ListItem>
                        })}
                      </List>
                    </>
                  ) : (
                    <Typography variant="body">
                      {templateDetails.qa_plugin_status === 'Not Run'
                        ? 'Not Run'
                        : 'No error(s) found'}
                    </Typography>
                  )}
                </SummaryContainer>
              </Grid>
              {/** End of Left container */}

              {/** Right container */}
              <Grid item xs={6} className={classes.rightContainer}>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2">
                    Status: Template Base QA
                  </Typography>
                </Box>

                <SummaryContainer color={template_base_color_status}>
                  {
                    //   ingestion_qa_report.qa_status
                  }
                  <CustomAlert
                    color={template_base_color_status}
                    message={`${
                      template_QA_Base_status == 'QA_SUCCESS'
                        ? 'Upload Successfull'
                        : template_QA_Base_status
                    } ${
                      template_QA_Base_date != ''
                        ? readableDateAndTimeDigitFormat(template_QA_Base_date)
                        : ''
                    }`}
                  />
                </SummaryContainer>

                <TemplateBaseErrorTable data={template_QA_Base_errors} />

                <Divider sx={{ mt: 2, mb: 3 }} />
                <Box sx={{ mb: 1 }}>
                  <Typography variant="subtitle2">Status: Assets</Typography>
                </Box>

                {}

                <SummaryContainer
                  color={ingestionColorCode}
                  bg_color={ingestion_backgroundColor}
                >
                  <CustomAlert
                    Icon={ingestion_Icon}
                    icon_color={ingestion_iconColor}
                    text_color={ingestion_descTxtColor}
                    color={asset_error_details_status}
                    message={`${templateDetails.ingestion_status}`}
                  />
                </SummaryContainer>

                {templateDetails?.asset_error_details !== null ? (
                  <AssetsErrorTable
                    data={templateDetails.asset_error_details}
                  />
                ) : null}
              </Grid>
            </Grid>

            <Divider />
          </>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(ErrorStyles)(ErrorSummary)
